import { Paths } from '@constants';

import { Link } from '@components';

import { Container, NotFoundContainer } from './styled';

const NotFoundPage = () => {
  return (
    <Container>
      <NotFoundContainer>
        <div>Whoops.... Page not found 🔎🥺</div>
        <Link underline to={Paths.DASHBOARD} style={{ marginTop: '12px', fontSize: 14 }}>Dashboard</Link>
      </NotFoundContainer>
    </Container>
  );
};

export default NotFoundPage;
