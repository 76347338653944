import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div``;

export const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 7px 0 0;

    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    max-height: 50vh;
    overflow-y: scroll;
    min-width: 180px;
`;

export const FooterOption = styled.div`
    display: flex;
    align-items: center;
    padding: 9px 12px;
    cursor: pointer;
    border-top: 1px solid #DCDFE6;
    position: sticky;
    bottom: 0;
    background: #fff;
`;

export const Option = styled.div`
  padding: 7px 14px;
  cursor: pointer;
  transition: 280ms ease all;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &:hover {
    background: rgba(208, 207, 205, 0.43);
  }
`;

export const IconContainer = styled.div`
  transition: all 280ms ease;
  color: #000c17;
  ${styledProp('$opened', 'transform: rotate(180deg);')}
`;

export const Updated = styled.div`
  font-family: Manrope-Medium;
  font-size: 10px;
  line-height: 100%;
  color: #909399;
  margin-top: 2px;
`;

export const Status = styled.div`
  font-family: Manrope-Medium;
  font-size: 10px;
  line-height: 100%;
  margin-top: 2px;
  
  ${switchStyle('$status', {
    0: css`
      color: #909399;
    `,
    1: css`
      color: #24993E;
    `,
    2: css`
      color: #909399;
    `,
    3: css`
      color: #303133;
    `,
    4: css`
    color: #24993E;
  `,
  })}
`;

export const Label = styled.div`
  font-family: Manrope-Medium;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;
