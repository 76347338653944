import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";

import {
  getDashboardContentCardSummary,
  getDashboardEmailSummary,
  getDashboardMobilePushSummary,
  getDashboardSmsSummary,
  getDashboardViberSummary, getDashboardWebpushSummary
} from "@store/actions/creators";
import { dashboardAnalyticsOptionsSelector, userSelector } from "@store/selectors";
import {
  dashboardContentCardSummarySelector,
  dashboardEmailSummarySelector, dashboardMobilePushSummarySelector,
  dashboardSmsSummarySelector, dashboardSummaryLoadedSelector,
  dashboardViberSummarySelector, dashboardWebpushSummarySelector
} from "@store/selectors/dashboard";

import { useTranslation } from "@hooks";

import { defaultRanges, ChartColors } from "@constants";

import { identity, moment, testId } from "@utils";

import {
  SendingChartContainer,
  SendingContainer,
  SendingItemsContainer,
  SendingSection,
  SendingTitle
} from "../../styled";
import { BaseActivityLoader } from "../BaseActivitySection/components";
import { CardSendingInfo } from '../CardSendingInfo';
import { EmailSendingInfo } from "../EmailSendingInfo";
import { MobilePushSendingInfo } from "../MobilePushSendingInfo";
import { SendingChart } from "../SendingChart";
import { SmsSendingInfo } from "../SmsSendingInfo";
import { ViberSendingInfo } from "../ViberSendingInfo";
import { WebpushSendingInfo } from "../WebpushSendingInfo";

const roughCompareDate = (d1, d2) => {
  return moment(d1).format('YYYY-MM-DD') === moment(d2).format('YYYY-MM-DD');
};

const resolveRangeFromOptions = (options) => {
  if (Array.isArray(options?.range)) {
    return (options.range || defaultRanges['labels.today']).map(d => moment(d));
  }

  if (options?.range?.absolute) {
    return options.range.absolute.map(d => moment(d));
  }

  if (options?.range?.relative) {
    switch (options?.range.relate) {
      case 'today':
      default:
        return defaultRanges['labels.today'];
    }
  }

  return (options?.range || defaultRanges['labels.today']).map(d => moment(d));
}

const TemplateSendingSection = () => {
  const { p, t } = useTranslation('dashboard_page');
  const smsSummary = useSelector(dashboardSmsSummarySelector);
  const viberSummaryData = useSelector(dashboardViberSummarySelector);
  const emailSummary = useSelector(dashboardEmailSummarySelector);
  const user = useSelector(userSelector);
  const webpushSummary = useSelector(dashboardWebpushSummarySelector);
  const cardSummaryData = useSelector(dashboardContentCardSummarySelector);
  const mobilePushSummary = useSelector(dashboardMobilePushSummarySelector);
  const analyticsOptions = useSelector(dashboardAnalyticsOptionsSelector);
  const summaryLoaded = useSelector(dashboardSummaryLoadedSelector);

  const dispatch = useDispatch();

  const viberSummary = useMemo(() => {
    return {
      total: viberSummaryData?.total || 0,
      click: viberSummaryData?.data?.find(d => d.status === 'click')?.count || 0,
    }
  }, [viberSummaryData?.total, viberSummaryData?.data])

  const cardSummary = useMemo(() => {
    return {
      total: cardSummaryData?.total || 0,
      click: cardSummaryData?.click || 0,
      open: cardSummaryData?.open || 0
    }
  }, [cardSummaryData?.total, cardSummaryData?.click, cardSummaryData?.open])

  const shouldRender = useMemo(() => {
    return !!(emailSummary?.total || smsSummary?.total || webpushSummary?.total || viberSummary?.total || cardSummary?.total);
  }, [emailSummary?.total, smsSummary?.total, webpushSummary?.total, viberSummary?.total, cardSummary?.total]);

  const range = useMemo(() => {
    return resolveRangeFromOptions(analyticsOptions);
  }, [analyticsOptions])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!range.every(d => !!d) || !user?.app?.id) {
      return;
    }

    dispatch(getDashboardSmsSummary({ range }));
    dispatch(getDashboardEmailSummary({ range }));
    dispatch(getDashboardViberSummary({ range }));
    dispatch(getDashboardMobilePushSummary({ range }))
    dispatch(getDashboardWebpushSummary({ range }));
    dispatch(getDashboardContentCardSummary({ range }));
  }, [(range || []).map(d => moment(d).toISOString()).join('.'), user?.app?.id]);

  const resolveRangeTitle = ([r11, r12], t = identity) => {
    return t(Object.entries(defaultRanges).find(([_, [r21, r22]]) => roughCompareDate(r11, r21) && roughCompareDate(r12, r22))?.[0])
      || `${moment(r11).format('YYYY-MM-DD')} - ${moment(r12).format('YYYY-MM-DD')}`;
  };

  const sendingData = useMemo(() => [
    {
      name: 'emails',
      value: emailSummary?.click / emailSummary?.total * 100,
      background: ChartColors[0],
      color: ChartColors[0],
    },
    {
      name: 'sms',
      value: smsSummary?.click / smsSummary?.total * 100,
      background: ChartColors[1],
      color: ChartColors[1],
    },
    {
      name: 'viber',
      value: viberSummary?.click / viberSummary?.total * 100,
      background: ChartColors[2],
      color: ChartColors[2],
    },
    {
      name: 'web_push',
      value: webpushSummary?.click / webpushSummary?.total * 100,
      background: ChartColors[3],
      color: ChartColors[3],
    },
    {
      name: 'mobile_push',
      value: mobilePushSummary?.click / mobilePushSummary?.total * 100,
      background: ChartColors[4],
      color: ChartColors[4],
    },
    {
      name: 'card_feed',
      value: cardSummary?.click / cardSummary?.total * 100,
      background: ChartColors[5],
      color: ChartColors[5],
    },
  ], [mobilePushSummary?.click, mobilePushSummary?.total, webpushSummary?.click, webpushSummary?.total, viberSummary?.click, viberSummary?.total, smsSummary?.click, smsSummary?.total, emailSummary?.click, emailSummary?.total, cardSummary?.click, cardSummary?.total]);

  const tid = testId('dashboard');

  return shouldRender ? (
    <SendingSection data-testid="dashboard-sending-section">
      <SendingTitle>
        {p('sending')} {resolveRangeTitle(range, t).toLowerCase()}
      </SendingTitle>
      <SendingContainer>
        <BaseActivityLoader {...tid(`sending-loader`)} active={!summaryLoaded} />
        <SendingItemsContainer>
          <EmailSendingInfo />
          <SmsSendingInfo/>
          <ViberSendingInfo />
          <WebpushSendingInfo />
          <MobilePushSendingInfo />
          <CardSendingInfo/>
        </SendingItemsContainer>
        <SendingChartContainer>
          <SendingChart data={sendingData} />
        </SendingChartContainer>
      </SendingContainer>
    </SendingSection>
  ) : null;
}

export default React.memo(TemplateSendingSection);
