import { useSelector } from 'react-redux';

import { optionsAggregatesSelector } from '@store/selectors';

import { SegmentsRuleTypes } from '@constants';

import { NoData } from '@components';

import { Aggregate, Container } from './styled.js';

const AggregatesFilterPanel = ({ onSelect, hidePreview }) => {
  const aggregates = useSelector(optionsAggregatesSelector);

  if (!aggregates?.length) {
    return (
      <Container hidePreview={hidePreview}>
        <NoData />
      </Container>
    )
  }

  return (
    <Container hidePreview={hidePreview} data-testid={'store-segment-filter-aggregate-tab-content'}>
      {aggregates?.map((a, idx) => <Aggregate key={idx} onClick={() => onSelect(a, SegmentsRuleTypes.AGGREGATE)}>{a.label}</Aggregate>)}
      {/*<Aggregate onClick={() => onSelect(null, SegmentsRuleTypes.AGGREGATE)}>+ Create aggregate</Aggregate>*/}
    </Container>
  );
}

export default AggregatesFilterPanel;

