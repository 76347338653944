import { Container, Title, Description, InnerContainer } from './styled';

const TableEmpty = ({ search, title, description }) => {
  return (
    <Container>
      <InnerContainer>
        {search && (
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_7448_133" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="100"
              height="100">
              <path d="M0 7.62939e-06H100V100H0V7.62939e-06Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_7448_133)">
              <path fillRule="evenodd" clipRule="evenodd"
                d="M8.7865 22.8342C7.22067 22.8342 5.89334 21.5015 5.89334 19.9104C5.89334 18.3062 7.21292 16.9866 8.81736 16.9866H9.82537C10.7919 16.9866 11.5754 16.2031 11.5754 15.2366C11.5754 11.0404 14.9768 7.63892 19.173 7.63892C21.9385 7.63892 24.3608 9.11543 25.692 11.3318C26.0966 12.0054 26.9005 12.3267 27.658 12.1176C28.0203 12.0175 28.4029 11.9635 28.8008 11.9635C30.379 11.9635 31.7601 12.8156 32.5061 14.0932C32.9935 14.9278 34.0651 15.2094 34.8998 14.722C35.7344 14.2347 36.016 13.163 35.5286 12.3284C34.1812 10.0208 31.6741 8.46353 28.8008 8.46353C28.5293 8.46353 28.2609 8.47752 27.9962 8.50484C25.9703 5.85362 22.7727 4.13892 19.173 4.13892C13.6293 4.13892 9.0351 8.20357 8.20804 13.5153C4.95444 13.8241 2.39334 16.5786 2.39334 19.9104C2.39334 23.4002 5.25351 26.3342 8.7865 26.3342H26.4297C27.3962 26.3342 28.1797 25.5507 28.1797 24.5842C28.1797 23.6177 27.3962 22.8342 26.4297 22.8342H8.7865Z"
                fill="#31C447"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M8.7865 22.8342C7.22067 22.8342 5.89334 21.5015 5.89334 19.9104C5.89334 18.3062 7.21292 16.9866 8.81736 16.9866H9.82537C10.7919 16.9866 11.5754 16.2031 11.5754 15.2366C11.5754 11.0404 14.9768 7.63892 19.173 7.63892C21.9385 7.63892 24.3608 9.11543 25.692 11.3318C26.0966 12.0054 26.9005 12.3267 27.658 12.1176C28.0203 12.0175 28.4029 11.9635 28.8008 11.9635C30.379 11.9635 31.7601 12.8156 32.5061 14.0932C32.9935 14.9278 34.0651 15.2094 34.8998 14.722C35.7344 14.2347 36.016 13.163 35.5286 12.3284C34.1812 10.0208 31.6741 8.46353 28.8008 8.46353C28.5293 8.46353 28.2609 8.47752 27.9962 8.50484C25.9703 5.85362 22.7727 4.13892 19.173 4.13892C13.6293 4.13892 9.0351 8.20357 8.20804 13.5153C4.95444 13.8241 2.39334 16.5786 2.39334 19.9104C2.39334 23.4002 5.25351 26.3342 8.7865 26.3342H26.4297C27.3962 26.3342 28.1797 25.5507 28.1797 24.5842C28.1797 23.6177 27.3962 22.8342 26.4297 22.8342H8.7865Z"
                fill="#F8FAFE" fillOpacity="0.3"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M60.9376 74.4216C41.4092 74.4216 25.5782 58.5907 25.5782 39.0623C25.5782 19.5338 41.4092 3.7029 60.9376 3.7029C80.466 3.7029 96.297 19.5338 96.297 39.0623C96.297 46.2235 94.1695 52.8819 90.5132 58.4482C89.9826 59.256 90.2073 60.341 91.0151 60.8716C91.8229 61.4022 92.9079 61.1775 93.4386 60.3697C97.4581 54.2504 99.797 46.9272 99.797 39.0623C99.797 17.6009 82.399 0.202896 60.9376 0.202896C39.4762 0.202896 22.0782 17.6009 22.0782 39.0623C22.0782 60.5237 39.4762 77.9216 60.9376 77.9216C67.3298 77.9216 73.3657 76.3764 78.6884 73.6386C79.5479 73.1965 79.8863 72.1414 79.4442 71.2819C79.0021 70.4225 77.947 70.0841 77.0875 70.5262C72.2482 73.0153 66.7598 74.4216 60.9376 74.4216Z"
                fill="#D3DBE7"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M29.8908 39.0623C29.8908 56.2091 43.7909 70.1092 60.9377 70.1092C61.6693 70.1092 62.0937 70.1051 62.8545 70.0506C63.8185 69.9815 64.544 69.1441 64.475 68.18C64.4059 67.216 63.5685 66.4905 62.6044 66.5595C61.9636 66.6054 61.6333 66.6092 60.9377 66.6092C45.7239 66.6092 33.3908 54.2761 33.3908 39.0623C33.3908 34.4281 34.5354 30.0661 36.5542 26.2375C37.005 25.3826 36.6774 24.3241 35.8224 23.8733C34.9675 23.4225 33.909 23.7501 33.4582 24.605C31.1809 28.9241 29.8908 33.8449 29.8908 39.0623ZM83.8265 57.4336C84.6145 57.9932 85.7069 57.8081 86.2666 57.0201C89.8672 51.9505 91.9845 45.7511 91.9845 39.0623C91.9845 21.9157 78.0842 8.01541 60.9377 8.01541C55.7198 8.01541 50.7992 9.30595 46.4796 11.5832C45.6246 12.0339 45.2969 13.0924 45.7476 13.9473C46.1983 14.8023 47.2568 15.13 48.1118 14.6793C51.9413 12.6604 56.3032 11.5154 60.9377 11.5154C76.1512 11.5154 88.4845 23.8487 88.4845 39.0623C88.4845 45.0012 86.607 50.4964 83.413 54.9935C82.8534 55.7815 83.0385 56.8739 83.8265 57.4336Z"
                fill="#D3DBE7"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M2.62551 97.3745C5.85561 100.605 11.1306 100.605 14.3607 97.3745C14.3695 97.3657 14.3782 97.3568 14.3868 97.3478L40.2216 70.3878C40.8903 69.69 40.8667 68.5822 40.1689 67.9135C39.471 67.2448 38.3632 67.2684 37.6945 67.9663L11.8737 94.9117C10.0093 96.7628 6.95959 96.7588 5.10039 94.8996L5.10034 94.8995C3.24122 93.0406 3.23711 89.9907 5.08825 88.1263L31.8552 62.4747C32.553 61.806 32.5766 60.6982 31.9079 60.0004C31.2391 59.3026 30.1314 59.279 29.4336 59.9478L2.65212 85.6132C2.64316 85.6218 2.63429 85.6305 2.62551 85.6392C-0.604558 88.8693 -0.604626 94.1446 2.62551 97.3745Z"
                fill="#D3DBE7"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M32.0989 77.7221C32.7824 77.0387 32.7824 75.9306 32.0989 75.2472L24.7528 67.9011C24.0694 67.2177 22.9614 67.2177 22.278 67.9011C21.5946 68.5845 21.5946 69.6926 22.278 70.376L29.6241 77.7221C30.3075 78.4055 31.4155 78.4055 32.0989 77.7221Z"
                fill="#D3DBE7"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M49.4213 39.0623C49.4213 40.0288 50.2048 40.8123 51.1713 40.8123H51.1727C52.1392 40.8123 52.9227 40.0288 52.9227 39.0623C52.9227 38.0958 52.1392 37.3123 51.1727 37.3123H51.1713C50.2048 37.3123 49.4213 38.0958 49.4213 39.0623Z"
                fill="#D3DBE7"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M59.1869 39.0623C59.1869 40.0288 59.9704 40.8123 60.9369 40.8123H60.9383C61.9048 40.8123 62.6883 40.0288 62.6883 39.0623C62.6883 38.0958 61.9048 37.3123 60.9383 37.3123H60.9369C59.9704 37.3123 59.1869 38.0958 59.1869 39.0623Z"
                fill="#D3DBE7"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M68.9525 39.0623C68.9525 40.0288 69.736 40.8123 70.7025 40.8123H70.7038C71.6703 40.8123 72.4538 40.0288 72.4538 39.0623C72.4538 38.0958 71.6703 37.3123 70.7038 37.3123H70.7025C69.736 37.3123 68.9525 38.0958 68.9525 39.0623Z"
                fill="#D3DBE7"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M67.6183 70.3281C66.0523 70.3281 64.7251 68.9954 64.7251 67.4042C64.7251 65.8 66.0447 64.4804 67.6489 64.4804H68.6569C69.6234 64.4804 70.4069 63.6969 70.4069 62.7304C70.4069 58.5342 73.8084 55.1328 78.0046 55.1328C80.7701 55.1328 83.1925 56.6093 84.5235 58.8255C84.9281 59.4992 85.7321 59.8206 86.4895 59.6114C86.8519 59.5114 87.2347 59.4574 87.6323 59.4574C89.8905 59.4574 91.7421 61.2043 91.9073 63.4192C91.9754 64.3326 92.7365 65.039 93.6525 65.039C95.103 65.039 96.297 66.233 96.297 67.6835C96.297 69.134 95.103 70.3281 93.6525 70.3281H67.6183ZM61.2251 67.4042C61.2251 70.8939 64.085 73.8281 67.6183 73.8281H93.6525C97.0359 73.8281 99.797 71.067 99.797 67.6835C99.797 64.818 97.8165 62.3989 95.1548 61.7254C94.2651 58.4029 91.2351 55.9574 87.6323 55.9574C87.361 55.9574 87.0926 55.9714 86.8278 55.9987C84.802 53.3474 81.6042 51.6328 78.0046 51.6328C72.4608 51.6328 67.8667 55.6974 67.0396 61.0092C63.7862 61.3179 61.2251 64.0725 61.2251 67.4042Z"
                fill="#31C447"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M67.6183 70.3281C66.0523 70.3281 64.7251 68.9954 64.7251 67.4042C64.7251 65.8 66.0447 64.4804 67.6489 64.4804H68.6569C69.6234 64.4804 70.4069 63.6969 70.4069 62.7304C70.4069 58.5342 73.8084 55.1328 78.0046 55.1328C80.7701 55.1328 83.1925 56.6093 84.5235 58.8255C84.9281 59.4992 85.7321 59.8206 86.4895 59.6114C86.8519 59.5114 87.2347 59.4574 87.6323 59.4574C89.8905 59.4574 91.7421 61.2043 91.9073 63.4192C91.9754 64.3326 92.7365 65.039 93.6525 65.039C95.103 65.039 96.297 66.233 96.297 67.6835C96.297 69.134 95.103 70.3281 93.6525 70.3281H67.6183ZM61.2251 67.4042C61.2251 70.8939 64.085 73.8281 67.6183 73.8281H93.6525C97.0359 73.8281 99.797 71.067 99.797 67.6835C99.797 64.818 97.8165 62.3989 95.1548 61.7254C94.2651 58.4029 91.2351 55.9574 87.6323 55.9574C87.361 55.9574 87.0926 55.9714 86.8278 55.9987C84.802 53.3474 81.6042 51.6328 78.0046 51.6328C72.4608 51.6328 67.8667 55.6974 67.0396 61.0092C63.7862 61.3179 61.2251 64.0725 61.2251 67.4042Z"
                fill="#F8FAFE" fillOpacity="0.3"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M38.4702 18.3463C38.4702 19.3128 39.2537 20.0963 40.2202 20.0963H40.2215C41.188 20.0963 41.9715 19.3128 41.9715 18.3463C41.9715 17.3798 41.188 16.5963 40.2215 16.5963H40.2202C39.2537 16.5963 38.4702 17.3798 38.4702 18.3463Z"
                fill="#D3DBE7"/>
            </g>
          </svg>
        )}
        {!search && (
          <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
              d="M67.7822 18.9267H17.2481C13.6632 18.9267 10.7577 21.8322 10.7577 25.4171H110.277V16.2678C110.277 13.6565 108.161 11.5385 105.55 11.5385C98.8841 11.5385 81.6889 11.5385 75.6615 11.5385C73.3986 11.5385 71.3108 12.7522 70.188 14.7144L67.7822 18.9267Z"
              fill="#31C447"/>
            <path fillRule="evenodd" clipRule="evenodd"
              d="M67.7822 18.9267H17.2481C13.6632 18.9267 10.7577 21.8322 10.7577 25.4171H110.277V16.2678C110.277 13.6565 108.161 11.5385 105.55 11.5385C98.8841 11.5385 81.6889 11.5385 75.6615 11.5385C73.3986 11.5385 71.3108 12.7522 70.188 14.7144L67.7822 18.9267Z"
              fill="#F8FAFE" fillOpacity="0.3"/>
            <path fillRule="evenodd" clipRule="evenodd"
              d="M112.369 85.6175C114.452 70.6161 117.07 51.7788 118.634 40.5072C119.022 37.7185 118.182 34.8995 116.335 32.775C114.485 30.6526 111.809 29.4324 108.992 29.4324C86.9332 29.4324 34.1015 29.4324 12.0428 29.4324C9.22597 29.4324 6.54977 30.6526 4.70001 32.775C2.85241 34.8995 2.01299 37.7185 2.40025 40.5072C3.16611 46.0262 4.58534 56.2442 5.66924 64.05C5.75145 64.6428 6.29881 65.056 6.88943 64.9738C7.48006 64.8916 7.89544 64.3442 7.81323 63.7536L4.54208 40.2108C4.24135 38.0409 4.89472 35.8471 6.33126 34.1964C7.76996 32.5457 9.85337 31.5959 12.0428 31.5959H108.992C111.181 31.5959 113.265 32.5457 114.703 34.1964C116.14 35.8471 116.793 38.0409 116.493 40.2108L110.227 85.319C110.145 85.9118 110.558 86.457 111.149 86.5392C111.742 86.6214 112.287 86.2082 112.369 85.6175Z"
              fill="#D3DBE7"/>
            <path fillRule="evenodd" clipRule="evenodd"
              d="M6.64713 71.0964C8.24809 82.6125 9.82309 93.9534 10.814 101.084C11.4825 105.898 15.5974 109.481 20.4565 109.481H100.578C104.306 109.481 107.596 107.373 109.228 104.219C109.502 103.689 109.295 103.036 108.765 102.761C108.232 102.486 107.579 102.694 107.306 103.224C106.037 105.677 103.477 107.317 100.578 107.317C82.7728 107.317 38.2618 107.317 20.4565 107.317C16.6769 107.317 13.475 104.531 12.9558 100.788C11.9649 93.6548 10.3899 82.3139 8.79112 70.8C8.7089 70.2072 8.16155 69.794 7.57092 69.8762C6.9803 69.9584 6.56708 70.5058 6.64713 71.0964Z"
              fill="#D3DBE7"/>
            <path fillRule="evenodd" clipRule="evenodd"
              d="M23.7406 91.1474C23.7406 94.1351 26.1637 96.556 29.1493 96.556H46.457C49.4447 96.556 51.8656 94.1351 51.8656 91.1474V86.8204C51.8656 83.8349 49.4447 81.4118 46.457 81.4118H29.1493C27.0918 81.4118 25.3005 82.5627 24.3875 84.2567C24.1041 84.7825 24.301 85.438 24.8267 85.7214C25.3502 86.0048 26.0079 85.8079 26.2914 85.2844C26.8387 84.2675 27.9139 83.5752 29.1493 83.5752H46.457C48.2505 83.5752 49.7022 85.0291 49.7022 86.8204V91.1474C49.7022 92.9409 48.2505 94.3925 46.457 94.3925H29.1493C27.3579 94.3925 25.9041 92.9409 25.9041 91.1474C25.9041 90.5502 25.4195 90.0656 24.8224 90.0656C24.2252 90.0656 23.7406 90.5502 23.7406 91.1474Z"
              fill="#D3DBE7"/>
            <path fillRule="evenodd" clipRule="evenodd"
              d="M83.2337 47.2594C71.2935 47.2594 61.5991 56.9539 61.5991 68.894C61.5991 80.8341 71.2935 90.5286 83.2337 90.5286C95.1738 90.5286 104.868 80.8341 104.868 68.894C104.868 56.9539 95.1738 47.2594 83.2337 47.2594ZM83.2337 51.5863C92.7854 51.5863 100.541 59.3423 100.541 68.894C100.541 78.4457 92.7854 86.2017 83.2337 86.2017C73.682 86.2017 65.926 78.4457 65.926 68.894C65.926 59.3423 73.682 51.5863 83.2337 51.5863Z"
              fill="#31C447"/>
            <path fillRule="evenodd" clipRule="evenodd"
              d="M83.2337 47.2594C71.2935 47.2594 61.5991 56.9539 61.5991 68.894C61.5991 80.8341 71.2935 90.5286 83.2337 90.5286C95.1738 90.5286 104.868 80.8341 104.868 68.894C104.868 56.9539 95.1738 47.2594 83.2337 47.2594ZM83.2337 51.5863C92.7854 51.5863 100.541 59.3423 100.541 68.894C100.541 78.4457 92.7854 86.2017 83.2337 86.2017C73.682 86.2017 65.926 78.4457 65.926 68.894C65.926 59.3423 73.682 51.5863 83.2337 51.5863Z"
              fill="#F8FAFE" fillOpacity="0.3"/>
            <path fillRule="evenodd" clipRule="evenodd"
              d="M95.8315 83.8175L116.012 103.998C116.856 104.842 118.228 104.842 119.071 103.998C119.915 103.155 119.915 101.783 119.071 100.939L98.8906 80.7584C98.0469 79.9125 96.6753 79.9125 95.8315 80.7584C94.9878 81.6022 94.9878 82.9738 95.8315 83.8175Z"
              fill="#31C447"/>
            <path fillRule="evenodd" clipRule="evenodd"
              d="M95.8315 83.8175L116.012 103.998C116.856 104.842 118.228 104.842 119.071 103.998C119.915 103.155 119.915 101.783 119.071 100.939L98.8906 80.7584C98.0469 79.9125 96.6753 79.9125 95.8315 80.7584C94.9878 81.6022 94.9878 82.9738 95.8315 83.8175Z"
              fill="#F8FAFE" fillOpacity="0.3"/>
          </svg>
        )}
        {title && <Title></Title>}
        {description && <Description>{description}</Description>}
      </InnerContainer>
    </Container>
  );
};

export default TableEmpty;
