const SqlQueryActionTypes = {
  GET_SQL_QUERIES: 'sql-queries/GET',
  GET_SQL_QUERIES_SUCCESS: 'sql-queries/GET_SUCCESS',
  GET_SQL_QUERIES_ERROR: 'sql-queries/GET_ERROR',

  GET_SQL_QUERY: 'sql-queries/GET_ONE',
  GET_SQL_QUERY_SUCCESS: 'sql-queries/GET_ONE_SUCCESS',
  GET_SQL_QUERY_ERROR: 'sql-queries/GET_ONE_ERROR',

  CREATE_SQL_QUERY: 'sql-queries/CREATE',
  CREATE_SQL_QUERY_SUCCESS: 'sql-queries/CREATE_SUCCESS',
  CREATE_SQL_QUERY_ERROR: 'sql-queries/CREATE_ERROR',

  UPDATE_SQL_QUERY: 'sql-queries/UPDATE',
  UPDATE_SQL_QUERY_SUCCESS: 'sql-queries/UPDATE_SUCCESS',
  UPDATE_SQL_QUERY_ERROR: 'sql-queries/UPDATE_ERROR',

  GET_EXAMPLE: 'sql-queries/GET_EXAMPLE',
  GET_EXAMPLE_SUCCESS: 'sql-queries/GET_EXAMPLE_SUCCESS',
  GET_EXAMPLE_ERROR: 'sql-queries/GET_EXAMPLE_ERROR',

  DELETE_SQL_QUERY: 'sql-queries/DELETE',
  DELETE_SQL_QUERY_SUCCESS: 'sql-queries/DELETE_SUCCESS',
  DELETE_SQL_QUERY_ERROR: 'sql-queries/DELETE_ERROR',
};

export default SqlQueryActionTypes;
