import { useTranslation } from '@hooks';

import { moment, tryMongoDateObject } from '@utils';

import { Container, Date, Time } from './styled';

const DateCell = ({ date, isCreated, datetime, customers, testId = {} }) => {
  const d = tryMongoDateObject(date);
  const { t } = useTranslation();

  if (!d || !moment(d).isValid()) {
    return '';
  }

  const resolveCaption = () => {
    if (isCreated === undefined) {
      return '';
    }

    return `${isCreated ? t('statuses.created') : 'statuses.updated'} `;
  }

  return (
    <Container {...testId}>
      <Date>{moment(d).format('YYYY-MM-DD')}</Date>
      {((customers && datetime) || !customers) && <Time>{resolveCaption()}{moment(d).format('hh:mm A')}</Time> }
    </Container>
  );
};

export default DateCell;
