import styled from 'styled-components';

import { switchStyle } from '@utils';

export const Wrapper = styled.div`
  background: #ebf0f8;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  z-index: 1;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  ${switchStyle('$layoutType', {
    fullScreen_portrait_image: `
      height: 455px;
    `,
    ['fullScreen_portrait_image-text']: `
      height: 240px;
    `,
    ['fullScreen_landscape_image-text']: `
      height: 128px;
    `,
    ['fullScreen_landscape_image']: `
      height: 195px;
    `,
    ['fullScreenMobile_portrait_image']: `
      height: 377px;
    `,
    ['fullScreenMobile_portrait_image-text']: `
      height: 187px;
    `,
    ['fullScreenMobile_landscape_image']: `
      height: 163px;
    `,
    ['fullScreenMobile_landscape_image-text']: `
      height: 98px;
    `,
    ['fullScreenLaptop_portrait_image']: `
      height: 200px;
    `,
    ['fullScreenLaptop_portrait_image-text']: `
      height: 100px;
    `,
    ['fullScreenLaptop_landscape_image']: `
      height: 91px;
    `,
    ['fullScreenLaptop_landscape_image-text']: `
      height: 45px;
    `,
    
  })}
`;

export const IconClose = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  ${switchStyle('$layoutType', {
    ['fullScreen_landscape_image']: {
      right: '15px'
    }
  })}
`;

export const FullScreenMobile = styled.div`
  position: absolute;
  top: 40px;
  left: 17px;
  right: 17px;
  bottom: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Image} {
    border-radius: 0px;
  }
`;

export const FullScreenMobileLandscape = styled.div`
  position: absolute;
  right: 37px;
  top: 26px;
  bottom: 28px;
  left: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Image} {
    border-radius: 0px;
  }
`;

export const FullScreenLaptop = styled.div`
  position: absolute;
  width: 120px;
  height: 229px;
  border-radius: 4px;
  overflow: hidden;
  
`;

export const FullScreenLaptopLandscape = styled.div`
  position: absolute;
  width: 230px;
  height: 130px;
  
  border-radius: 4px;
  overflow: hidden;
`;

export const FullScreen = styled.div`
  width: 100%;
  height: 100%;
`;

export const FullScreenLandscape = styled.div`
  width: 480px;
  height: 100%;
  min-height: 256px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-bottom: ${({ $hasContent }) => ($hasContent ? '10px' : '0px')}; */
`;

export const Title = styled.h4`
  color: var(--text-primary-body, #1a232e);
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 5px;
  white-space: pre-line;
  word-wrap: break-word;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const Description = styled.p`
  color: var(--text-primary-body, #1a232e);
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  white-space: pre-line;
  word-wrap: break-word;
  overflow: hidden;
  
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.$lineClamp || 9};
  overflow: hidden;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: ${props => props.$hasContent ? '0' : '0 10px;'}; 
  width: 100%;

  ${switchStyle('$buttonLength', {
    1: `
        button: {
          width: 100%
        }
    `,
    2: `
    button: {
          width: 50%
        }
    `,
  })}
  ${switchStyle('$size', {
    small: `
        gap: 4px;
    `,
    medium: `
        gap: 10px;
    `,
    [`fs-medium`]: `
      gap: 10px;
    `,
    large: `
        gap: 12px;
    `,
  })}
`;

export const Content = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.$hasContent ? 'space-between' : 'center'};
  flex-grow: 1;
  padding: ${props => props.$hasContent ? '10px;' : '0'};


  ${switchStyle('$layoutType', {
    ['fullScreen_portrait_image']: `

    `,
    ['fullScreen_portrait_image-text']: `
  
    `,
    ['fullScreen_landscape_image-text']: `
        ${ButtonContainer}{
          margin-top: 10px;
      }
    `,
  })};
  
  ${switchStyle('$type', {
    fullScreenLaptop_portrait: `
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        ${Title}{
          color: var(--text-primary-body, #1A232E);
          text-align: center;
          font-family: Manrope-SemiBold;
          font-size: 8.761px;
          font-style: normal;
          font-weight: 600;
          line-height: 11.499px;
        }
        ${Description} {
          color: var(--text-primary-body, #1A232E);
          text-align: center;
          font-family: Manrope-Medium;
          font-size: 6.571px;
          font-style: normal;
          font-weight: 500;
          line-height: 9.856px;
        }
    `,
    fullScreenLaptop_landscape: `
        ${Title}{
          color: var(--text-primary-body, #1A232E);
          text-align: center;
          font-family: Manrope-SemiBold;
          font-size: 8.761px;
          font-style: normal;
          font-weight: 600;
          line-height: 11.499px; /* 131.25% */
        }
        ${Description} {
          color: var(--text-primary-body, #1A232E);
          text-align: center;
          font-family: Manrope-Medium;
          font-size: 6.571px;
          font-style: normal;
          font-weight: 500;
          line-height: 9.856px;

          display: -webkit-box;
          -webkit-box-orient: vertical;

          overflow: hidden;
        }
    `,
    fullScreenMobile_landscape: `
        ${Description} {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
    `,
    fullScreen_portrait: `
        ${Title}{
          color: var(--text-primary-body, #1A232E);
          text-align: center;
          font-family: Manrope-SemiBold;
          font-size: 21.051px;
          font-style: normal;
          font-weight: 600;
          line-height: 27.629px; /* 131.25% */
        }
        ${Description} {
          color: var(--text-primary-body, #1A232E);
          text-align: center;
          font-family: Manrope-Medium;
          font-size: 15.788px;
          font-style: normal;
          font-weight: 500;
          line-height: 23.682px;

          display: -webkit-box;
          -webkit-box-orient: vertical;       
          overflow: hidden;
        }
    `,
    fullScreen_landscape: `
        ${Title}{
          color: var(--text-primary-body, #1A232E);
          text-align: center;
          font-family: Manrope-SemiBold;
          font-size: 21.051px;
          font-style: normal;
          font-weight: 600;
          line-height: 27.629px; /* 131.25% */
        }
        ${Description} {
          color: var(--text-primary-body, #1A232E);
          text-align: center;
          font-family: Manrope-Medium;
          font-size: 15.788px;
          font-style: normal;
          font-weight: 500;
          line-height: 23.682px; 
        }
    `,
  })}
`;
