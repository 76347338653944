import styled, { css } from 'styled-components';

import { styledProp , extract } from '@utils';

export const Container = styled.div`
  background: #FBFBFC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 280ms ease all;
  flex: 1;
  border: 2px dashed #DCDFE6;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  
  ${styledProp('src', css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${extract('src')}) center / cover no-repeat !important;
    border: none;
  `)}
  
  ${styledProp('drag', css`
    border-color: #31C447;
    background: rgba(95, 193, 249, 0.25);
  `)}

  ${styledProp('error', css`
    border-color: #C8402F;
    background: #FBFBFC;
  `)}
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
`;

export const Hint = styled.div`
  margin-top: 40px;
  font-family: Manrope-Medium;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #909399;
`;

export const FileName = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 16px;
  color: #303133;
  margin-bottom: 20px;
  text-align: center;
  transition: 280ms ease all;
  white-space: pre-wrap;
  word-break: break-all;
  width: 100%;
  
  & > span {
    color: #909399;
  }

  ${styledProp('white', css`
    color: #FFFFFF;
  `)}
  
  ${styledProp('error', css`
    color: #C8402F;
  `)}
`;
