import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearEntryCount, getEntryCount, getIndexCount } from '@store/actions/creators';
import { createCustomersCountSelector, workflowEntryCountSelector } from '@store/selectors';

import { Input, Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { extract, formatNumberString, identity } from '@utils';

import LineImg from '@res/images/limit_line.svg';
import OrImg from '@res/images/or_icon.svg'

import { Icon, Switch } from '@components';

import { Container, TotalNumberText, TotalNumber, YourLimitTop, InputContainer, DefaultLimit, LeftContent, Line, Or } from './styled';

import { validateWorkflowSetup } from '../../utils';

const countSelector = createCustomersCountSelector('index');

const hasErrors = (validation) => {
  return validation.some(({ errors, filters }) => Object.values(errors || {}).some(identity) || hasErrors(filters || []));
};

const TotalWorkflowUsers = ({ worker, onWorkerChange, disabled }) => {
  const { p } = useTranslation('workflow_page');
  const dispatch = useDispatch();

  const allBaseCount = useSelector(countSelector);
  const entryCounts = useSelector(workflowEntryCountSelector);
  const entryCount = entryCounts?.[worker.id];
  const defaultLimit = isNaN(entryCount?.total) ? allBaseCount?.total : entryCount?.total;
  const appliedLimit = isNaN(worker.total_limit) ? defaultLimit : worker.total_limit;

  const [limit, setLimit] = useState(defaultLimit);

  useEffect(() => {
    dispatch(getIndexCount({}));
  }, []);

  useEffect(() => {
    if (appliedLimit && limit !== appliedLimit) {
      setLimit(appliedLimit);
    }
  }, [appliedLimit])

  useEffect(() => {
    if (worker.total_limit) {
      return;
    }

    const validation = validateWorkflowSetup(worker);

    if (hasErrors(validation.participation)) {
      return;
    }

    const filter = {
      includeSegmentIds: worker.tree_map.children[0]?.data?.segment_ids || [],
      targetSegments: (worker.target_audience || []).filter(extract('segment_id')).map(extract('segment_id')),
      targetQueries: (worker.target_audience || []).filter(({ type }) => type === 'rule'),
    };

    if (!Object.values(filter).some(extract('length'))) {
      dispatch(clearEntryCount());
      return;
    }

    dispatch(getEntryCount({
      id: worker.id,
      filter,
    }))

  }, [worker.participation, worker.target_audience, worker.total_limit]);

  const handleTotalLimitChange = ({ target: { value } }) => {
    setLimit(value);
    onWorkerChange(w => ({ ...w, total_limit: value }));
  };

  const onEditClick = (value) => {
    if(!value){
      onWorkerChange(w => ({ ...w, total_limit: null }));
    }else{
      onWorkerChange(w => ({ ...w, total_limit: defaultLimit }));
    }
  }

  return (
    <Container>
      <LeftContent>
        <TotalNumberText data-testid={'participation-step-total-users-title'}>
          {p('the_total_number_of_users_targeted_by_this_workflow')}
          <Tooltip
            style={{ position: 'absolute' }}
            title={'Participation program'}
          >
            <div>
              <Icon name="Info-icon" size={16} color="#7C94B1" />
            </div>
          </Tooltip>
        </TotalNumberText>
        <DefaultLimit data-testid={'participation-step-default-limit-value'} disabled={worker.total_limit !== null}>
          {formatNumberString(defaultLimit)}
        </DefaultLimit>
      </LeftContent>
      <Line src={LineImg}/>
      <Or src={OrImg}/>
      <TotalNumber>
        <YourLimitTop data-testid={'participation-step-your-limit-title'}>
          {p('your_limit_of_players')}
          <Switch
            disabled={disabled}
            testId={'participation-step-your-limit-switch'}
            large
            checked={worker.total_limit !== null}
            onChange={onEditClick}
          />
        </YourLimitTop>
        <InputContainer>
          <Input
            data-testid={'participation-step-your-limit-input'}
            disabled={worker.total_limit === null || disabled}
            style={{ border: '1px solid #DCDFE6', borderRadius: '9px', height: '44px', width: '160px', fontFamily: 'Manrope-SemiBold', fontSize: '25px', textAlign: 'center' }}
            value={worker.total_limit !== null ? worker.total_limit : defaultLimit}
            onChange={handleTotalLimitChange}
          />
        </InputContainer>
      </TotalNumber>
    </Container>
  );
};

export default TotalWorkflowUsers;
