import { shortNumber } from '@utils';

import ExistArrow from '@res/images/exit-arrow.svg';
import InArrow from '@res/images/in-arrow.svg';
import OutArrow from '@res/images/out-arrow.svg';

import { Container, Arrow, NumContainer, InNum, OutNum, ExitNum } from './styled.js';

const NodeAnalytics = ({ data, testId = '' }) => {
  return (
    <Container data-testid={`analytics-${testId}`}>
      <NumContainer style={{ marginRight: '-3px' }}>
        <Arrow src={InArrow} />
        <InNum data-testid={`analytics-in-${testId}`}>{shortNumber(data.in)}</InNum>
      </NumContainer>
      {!!+data.exit && <NumContainer >
        <Arrow src={ExistArrow} />
        <ExitNum data-testid={`analytics-exit-${testId}`}>{shortNumber(data.exit)}</ExitNum>
      </NumContainer>}
      <NumContainer style={{ marginLeft: '-3px' }}>
        <Arrow src={OutArrow} />
        <OutNum data-testid={`analytics-out-${testId}`}>{shortNumber(data.out)}</OutNum>
        {/*<Icon size={11} style={{marginRight: '2px'}} name="Launch"/>*/}
      </NumContainer>
    </Container>
  );
}

export default NodeAnalytics;

