import React from 'react';

import { DefaultAggregates } from '@constants/defaultAggregates';

import {
  Container,
  DisclaimerTitle,
  FormulaContainer,
  FormulaTop,
  SettingsTitle,
  DisclaimerContainer,
  FormulaBottom,
  SettingsContainer,
  DisclaimerSingleItem, DisclaimerItems, DisclaimerItem
} from './styled';

export const AggregateInfoMap = {
  [DefaultAggregates.NGR_BONUS]: {
    title: 'NGR/Bonus amount ratio',
    disclaimer_title: 'Bonus amount calculation',
    disclaimer_items: [
      'Includes bonuses from the last 90 days',
      'Calculated as the SUM of amount granted usd for unique bonuses (identified by bonus uuid)',
      'Only considers bonuses where the last status ≠ Inactive'
    ],
    formula_top: '(SUM(Real amount USD for bet type = BET) - SUM(Real amount USD for bet type = WIN) - Bonus amount',
    formula_bottom: 'Bonus amount',
  },
  [DefaultAggregates.BONUS_DEPOSIT]: {
    title: 'Bonus to deposit ratio',
    disclaimer_title: 'Bonus to deposit ratio calculation',
    disclaimer_items: [
      'Includes bonuses from the last 90 days',
      'Calculated as the SUM of amount granted usd for unique bonuses (identified by bonus uuid)',
      'Only considers bonuses where the last status = active'
    ],
    formula_top: 'Bonus amount',
    formula_bottom: 'SUM(Deposit amount USD) | Deposit status = completed',
  },
  [DefaultAggregates.ACTIVE_DAYS]: {
    title: 'Active days count',
    disclaimer_title: 'Active days count calculation',
    disclaimer_items: ['Active days defined by performing a Bet, Deposit, or Withdrawal on a given day. '],
    formula_top: 'SUM(active days)',
    formula_bottom: '',
  },
  [DefaultAggregates.DEPOSIT_WITHDRAWAL]: {
    title: 'Deposit to withdrawal ratio',
    disclaimer_title: 'Deposit to withdrawal ratio calculation',
    disclaimer_items: ['Calculates the ratio of the total deposit amount (USD) to the total withdrawal amount (USD).'],
    formula_top: 'SUM(deposit amount in USD) /',
    formula_bottom: 'SUM(withdrawal amount in USD)',
  }
};

const DefaultAggregateSettings = ({ aggregate, simplified }) => {
  const aggregateInfo = AggregateInfoMap[aggregate];

  return (
    <Container>
      <SettingsContainer>
        {!simplified && (
          <SettingsTitle>
            {`${aggregateInfo.title} settings`}
          </SettingsTitle>
        )}
        <FormulaContainer>
          <FormulaTop>
            {aggregateInfo.formula_top}
          </FormulaTop>
          {aggregateInfo.formula_bottom && (
            <FormulaBottom>
              {aggregateInfo.formula_bottom}
            </FormulaBottom>
          )}
        </FormulaContainer>
      </SettingsContainer>
      {!simplified && (
        <DisclaimerContainer>
          <DisclaimerTitle>
            {aggregateInfo.disclaimer_title}
          </DisclaimerTitle>
          {aggregateInfo.disclaimer_items.length > 1 ? (
            <DisclaimerItems>
              {aggregateInfo.disclaimer_items.map(item => (
                <DisclaimerItem key={item}>{item}</DisclaimerItem>
              ))}
            </DisclaimerItems>
          ) : (
            <DisclaimerSingleItem>
              {aggregateInfo.disclaimer_items[0]}
            </DisclaimerSingleItem>
          )}
        </DisclaimerContainer>
      )}
    </Container>
  );
};

export default DefaultAggregateSettings;
