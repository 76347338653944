import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 90.83%);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media screen and (max-width: 769px) {
    flex-direction: column-reverse;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  height: 236px;
  width: 400px;
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 42px;
  line-height: 110%;
  color: #303133;
`;

export const Hint = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 20px;
  line-height: 120%;
  color: #1F1F24;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const Timer = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 30px;
  line-height: 36px;
  color: #1F1F24;
`;

export const Image = styled.div`
  width: 400px;
  height: 400px;
`;
