import styled from 'styled-components';

import IconButton from '../../../../../../controls/ActionButton/ActionButton';

export const NodeActionLayout = styled.div`
  position: absolute;
  right: 0;
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 7px;
  border: 1px solid #d3dbe7;
  background: #fff;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
`;

export const IconButtonWithStyles = styled(IconButton)`
  background: #fff !important;
  border: 0;
  width: 18px;
  height: 18px;
  min-width: 18px;
`;
