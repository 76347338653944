import { useSelector } from 'react-redux';

import { loggedInSelector } from '@store/selectors';

const useCurrentWorkspace = () => {
  const user = useSelector(loggedInSelector);

  return user?.app;
}

export default useCurrentWorkspace;
