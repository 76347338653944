import styled, { css } from 'styled-components';

import { DirectionModes } from '@constants';

export const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  ${({ $labelX, $labelY, $dm, $left }) => $dm === DirectionModes.HORIZONTAL ? ($left ? css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px) translateX(-70px);
  ` : css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px) translateX(-56px);
  `) : ($left ? css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px) translateY(-90px);
  ` : css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px) translateY(-42px);
  `)}
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ConnectionSourceContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${({ $labelX, $labelY, $dm }) => $dm === DirectionModes.HORIZONTAL ? css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px) translateX(-30px);
  ` : css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px) translateY(-40px);
  `}
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RemoveConnectionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${({ $labelX, $labelY, $dm }) => $dm === DirectionModes.HORIZONTAL ? css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px);
  ` : css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px) translateY(-40px);
  `}
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RemoveButtonContainer = styled.div`
  cursor: pointer;
  padding: 2px;
  background: #fff;
`;

export const FirstChildContainer = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  ${({ $labelX, $labelY, $dm }) => $dm === DirectionModes.HORIZONTAL ? css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px);
  ` : css`
    transform: translate(-50%, -50%) translate(${$labelX}px,${$labelY}px);
  `}
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 50px;
  border-radius: 12px;
  border: 1px dashed var(--Colors-Neutral-400, #A1B3C8);
`;

export const FirstChildLabel = styled.div`
  color: var(--Colors-Neutral-400, #A1B3C8);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 14px;
  line-height: 20px;
  
  position: absolute;
  bottom: -5px;
  transform: translateY(100%);
  white-space: nowrap;
  background: #FAFAFA;
`;

