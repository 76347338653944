import styled from 'styled-components';

export const Container = styled.div``;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`