import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 110px;
    max-width: 460px;
    margin: 110px auto 0 auto;

`