import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 7px;
  //margin: 4.5px 5px 5.5px 8px;
`;

export const Input = styled.input`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #303133;
  transition: all 280ms ease;
  width: auto;
  display: flex;
  min-width: 60px;
  max-width: 500px !important;
  margin-right: 8px;
  white-space: nowrap;
  padding: 0px !important;
  text-overflow: ellipsis;

  outline: none;
  border-width: 0px;
  
  ${styledProp('smaller', css`
    font-size: 14px;
    line-height: 100%;
    min-width: 70px;
  `)}

  ${styledProp('medium', css`
    font-size: 16px;
    line-height: 130%;
  `)}
  
  &:disabled {
    background: #fff;
  }

  &:focus {
    outline: none;
  }
`;
