import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
`;

export const SettingsContainer = styled.div`
    border-radius: 7px;
    border: 1px solid var(--cards-border, #D3DBE7);
    background: var(--cards-bg, #F8FAFE);
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const SettingsTitle = styled.div`
    overflow: hidden;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;
    text-overflow: ellipsis;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
`;

export const FormulaContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 41px 0 30px;
    width: fit-content;
    align-self: center;
`;

export const FormulaTop = styled.div`
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-size: 13px;
    line-height: 18px;
    padding-bottom: 6px;
    width: 100%;
    text-align: center
`;

export const FormulaBottom = styled.div`
    padding-top: 6px;
    border-top: 1px solid var(--text-primary-body, #1A232E);
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-size: 13px;
    line-height: 18px;
    width: 100%;
    text-align: center;
`;

export const DisclaimerContainer = styled.div`
    border-radius: 7px;
    border: 1px solid #FFDC7B;
    background: #FFFAED;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const DisclaimerTitle = styled.div`
    overflow: hidden;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;
    text-overflow: ellipsis;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
`;

export const DisclaimerSingleItem = styled.div`
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;
    
    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px; 
`;

export const DisclaimerItem = styled.li`
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;
`;

export const DisclaimerItems = styled.ul`
    gap: 8px;
    display: flex;
    flex-direction: column;
    list-style: disc;
    padding-left: 20px;
`;
