import { Layout } from 'antd';

import { useTranslation } from '@hooks';

import { SiderStyled, LayoutStyled, Title, SubTitle } from './styled';
const { Content, } = Layout;

export const LayoutGroupSplit = ({ siderContent, content, title, subTitle }) => {
  const { p } = useTranslation('workflow_page');
  return (
    <LayoutStyled>
      <SiderStyled width={255}>{siderContent}</SiderStyled>
      <Content>
        {title && <Title>{`${p('choose_prefered_filter')}"${title}"`}</Title>}
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        {content}
      </Content>
    </LayoutStyled>
  );
};
