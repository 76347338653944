import { useEffect } from 'react';

import { Tab, Tabs } from '@velitech/ui';
import uniq from 'lodash.uniq';

import { useModalState, useTranslation } from '@hooks';

import {
  EmailStatuses, GiveRewardStatuses, IncludeCampaignStatuses,
  MobilePushStatuses,
  SmsStatuses,
  UserPermissions, ViberStatuses, WebhookStatuses,
  WebpushStatuses, WorkflowIcons,
  WorkflowNodes,
  WebPopupStatuses,
} from '@constants';
import { ContentCardStatuses } from '@constants/contentCardsStatuses';

import { by, extract, getAnalyticsList } from '@utils';

import { ActionButton, IconSvg, WithPermissions } from '@components';
import { NodeThumbnail } from '@components/lib/WorkflowEditor/components/Node/styled';

import { Container, Header, TabItem, TabsContainer } from './styled';

import {
  SendingAnalytics
} from '../../../../../pages/HomeScene/pages/WorkflowScene/pages/EditWorkflowPage/components/WorkflowAnalytics/components/VersionsSection/components';

// eslint-disable-next-line no-unused-vars
const FunnelNodes = [
  WorkflowNodes.SEND_SMS,
  WorkflowNodes.SEND_EMAIL,
  WorkflowNodes.WEBPUSH,
  WorkflowNodes.MOBILE_PUSH,
  WorkflowNodes.VIBER,
  WorkflowNodes.API_REQUEST,
];

const DownloadByChannelNodes = [
  WorkflowNodes.SEND_SMS,
  WorkflowNodes.SEND_EMAIL,
];

const resolveChannelType = (node) => {
  return ({
    [WorkflowNodes.SEND_SMS]: 'sms',
    [WorkflowNodes.SEND_EMAIL]: 'email',
    [WorkflowNodes.WEBPUSH]: 'webpushes',
    [WorkflowNodes.MOBILE_PUSH]: 'mobile-push',
    [WorkflowNodes.VIBER]: 'viber',
    [WorkflowNodes.API_REQUEST]: 'api-request',
  })[node?.data?.name];
}

export const options = {
  stroke: {
    curve: 'smooth',
    width: 2
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 4,
  },
  chart: {
    id: "basic-bar",
    stacked: true,
    toolbar: {
      show: false
    },
  },
  yaxis: {
    title: {
      text: 'Count'
    },
    labels: {
      formatter: (value) => {
        if (value === Infinity) {
          return 1;
        }
        return +value.toFixed(0);
      }
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MMM \'yy',
        day: 'dd MMM',
        hour: 'HH:mm'
      }
    }
  },
};

const NodeSendingAnalytics = ({ selectedNode, onDownloadByChannel, onDownloadContacts, analyticTrendNodeData, nodeData, worker, program, manageBestChannels, analyticBychannel }) => {
  const { t, p } = useTranslation('workflow_page');
  const downloadCustomersModal = useModalState();
  const isFilterNode = [WorkflowNodes.EXCLUDE_FILTER, WorkflowNodes.QUICK_FILTER].includes(selectedNode?.data?.name);
  const isSplitter = selectedNode?.data?.name === WorkflowNodes.AB_SPLITTER;
  const isGroupDSplit = selectedNode?.data?.name === WorkflowNodes.SWITCH_FILTER;
  const isWaitNode = selectedNode?.data?.name === WorkflowNodes.WAIT;
  const isBestChannel = selectedNode?.data?.name === WorkflowNodes.BEST_CHANNEL_TO_SEND;

  useEffect(() => {
    const isAlreadySelected = analyticBychannel?.some(({ id }) => manageBestChannels.bestChannel === id);
    const firstchannelId = analyticBychannel?.[0]?.id;

    !isAlreadySelected && firstchannelId && manageBestChannels.setBestchannel(firstchannelId);
  }, [analyticBychannel]);

  const resolveStatuses = () => {

    const configStatuses = {
      [WorkflowNodes.SEND_SMS]: SmsStatuses,
      [WorkflowNodes.SEND_EMAIL]: EmailStatuses,
      [WorkflowNodes.WEBPUSH]: WebpushStatuses,
      [WorkflowNodes.MOBILE_PUSH]: MobilePushStatuses,
      [WorkflowNodes.VIBER]: ViberStatuses,
      [WorkflowNodes.API_REQUEST]: WebhookStatuses,
      [WorkflowNodes.GIVE_REWARD]: GiveRewardStatuses,
      [WorkflowNodes.SEND_CARD]: ContentCardStatuses,
      [WorkflowNodes.INCLUDE_CAMPAIGN]: IncludeCampaignStatuses,
      [WorkflowNodes.WEB_POPUP]: WebPopupStatuses,
    };

    if(isBestChannel) {
      const selectedBestChanelName = isBestChannel && analyticBychannel?.find(({ id }) => id === manageBestChannels.bestChannel);
      return Object.values(configStatuses[selectedBestChanelName?.type] || {});
    }

    return Object.values(configStatuses[selectedNode?.data?.name] || {});
  };

  const addCustomStatuses = (statuses, compare) => {
    return uniq(statuses.concat((compare || []).map(extract('status'))));
  }

  const resolveLabel = (data) => {
    if(data?.name === 'ab'){
      return `${data?.name} ${data?.value}%`
    } else if(data?.name === 'include' || data?.name === 'exclude'){
      return p(data?.name);
    } else return data?.label
  }
  const sending = selectedNode?.data?.sending;

  const getStatuses = ({ isFilterNode , isSplitter }) => {

    if(isGroupDSplit || isSplitter || isWaitNode) {
      const data = nodeData?.map((analyticItem) => {
        const count = analyticItem?.data.reduce((a, b) => a + (+b.y), 0);
        return {
          [analyticItem?.name || 'count']: count
        }
      });
      const filtersStatuseObj = Object.assign({}, ...data);

      return filtersStatuseObj;
    }

    if(isFilterNode) {

      const filtersStatuses = nodeData.map((analyticItem) => {
        const count = analyticItem.data.reduce((a, b) => a + (+b.y), 0);

        return {
          [analyticItem.name]: count
        }
      });
      const filtersStatuseObj = Object.assign({}, ...filtersStatuses);

      return filtersStatuseObj;
    }

    const commonStatuses = Object.fromEntries(addCustomStatuses(resolveStatuses(), sending).map(status => [status, (sending || []).find(by('status', status))?.count || 0]) || {});
    return commonStatuses;
  };

  const statuses = getStatuses({ isFilterNode, isSplitter })

  const statusesTotal = Object.values(statuses).reduce((a, b) => a + b, 0);

  const tabs = analyticBychannel?.map((channel) => {

    const iconConfig = {
      'email': 'email-icon',
      'sms': 'sms-icon',
      'viber': 'viber',
      'mobpush': 'mobile_push',
      'card': 'message_feed',
      'webpush': 'web_push'
    }
    const isActive = manageBestChannels.bestChannel === channel.id;
    return {
      id: channel.id,
      active: isActive,
      children: (<TabItem className={`tab-item ${isActive && 'active'}`} $active={isActive}> <IconSvg name={iconConfig[channel.type]} size={18}/> {channel.label} - {(channel?.persentage || 0).toFixed(2)} % ({channel?.count || 0}) </TabItem>),
      value: channel.id,
    }
  });

  const getcommonAnalytic = () => {
    if(isBestChannel) {
      const selectedBestChanelName = analyticBychannel?.find(({ id }) => id === manageBestChannels.bestChannel);

      if(!selectedBestChanelName) {
        return null;
      }

      const data = {
        ...selectedBestChanelName,
        in: selectedBestChanelName.count,
      }
      return getAnalyticsList(data, selectedNode.data?.actionType, p);
    }
    return getAnalyticsList(selectedNode.data?.analytics, selectedNode.data?.actionType, p);
  }
  const commonAnalytic = getcommonAnalytic();

  return (
    <Container>
      <div style={{
        padding: '0 20px',
        position: 'relative'
      }}>
        <Header $hasBorder={selectedNode?.data?.name !== WorkflowNodes.BEST_CHANNEL_TO_SEND}>
          <NodeThumbnail type={selectedNode?.data?.actionType || 'connection_channel'}>
            <IconSvg
              size='18'
              name={WorkflowIcons[selectedNode?.data?.name]}
            />
          </NodeThumbnail>
          {p('node_analytics', { node: resolveLabel(selectedNode?.data) })}
        </Header>
        <WithPermissions
          name={UserPermissions.WORKFLOW_ANALYTICA_DOWNLOAD_CUSTOMERS}
        >
          <ActionButton
            size={26}
            iconSize={20}
            tooltip={t('actions.export_to_csv')}
            style={{
              background: '#fff',
              position: 'absolute',
              right: '20px',
              top: '20px',
              color: '#7C94B1',
            }}
            onClick={~DownloadByChannelNodes.indexOf(selectedNode?.data?.name) ? downloadCustomersModal.open : onDownloadContacts}
            icon='Download-icon'
          />
        </WithPermissions>
        {tabs?.length > 0 && <TabsContainer>
          <Tabs
            tabs={tabs}
            onChange={(value) => manageBestChannels.setBestchannel(value)}
            className="tabs-container"
            renderItem={(props) => {
              return <Tab className='tab' style={{ minWidth: 'unset' }} {...props} />;
            }}
          />
        </TabsContainer>}
      </div>

      <SendingAnalytics
        opened
        selected={selectedNode}
        statuses={statuses}
        commonAnalytic={commonAnalytic}
        defaultStatuses={resolveStatuses()}
        total={statusesTotal}
        downloadModal={downloadCustomersModal}
        channelType={resolveChannelType(selectedNode)}
        onDownloadByChannel={onDownloadByChannel}
        onDownloadAll={onDownloadContacts}
        options={options}
        series={analyticTrendNodeData}
        worker={worker}
        program={program}
      />

    </Container>
  );
};

export default NodeSendingAnalytics;
