import { useEffect, useRef, useState } from 'react';

const useTabElement = (tab, tabs, opened) => {
  const refs = useRef(Object.fromEntries(tabs.map(({ name }) => [name, null])));
  const [updater, setUpdater] = useState(false);
  const [tabEl, setTabEl] = useState(null);

  useEffect(() => {
    setTimeout(() => setUpdater(u => !u), 300);
  }, [opened, tab]);

  useEffect(() => {
    if (!refs.current[tab]?.getBoundingClientRect()?.width) {
      return;
    }

    setTabEl(refs.current[tab]);
  }, [tab, refs.current[tab]]);

  return {
    refChanger: t => rr => refs.current[t] = rr,
    updater,
    tabEl,
  }
};

export default useTabElement;
