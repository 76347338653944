import styled from 'styled-components';

export const Container = styled.div`
`;

export const Customers = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 26px;
  line-height: 31px;
  text-align: right;
  color: #303133;
  white-space: nowrap;
`;

export const Description = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #8B8E9F;
  margin-right: 40px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 20px;
  line-height: 24px;
  color: #303133;
  margin-top: 30px;
  padding-left: 30px;
  
  color: #4FAB74;
  
  & > span {
    margin-left: 10px;
    color: #8B8E9F;
  }
`;
