import { TimePicker } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${styledProp('sendCard', css`
    width: 120px;
  `)}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  font-size: 13px;
`;

export const Title = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 100%;
  color: #909399;
`;

export const StyledTimePicker = styled(TimePicker)`
  border: 1px solid #D3DBE7;
  border-radius: 7px;
  height: 36px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding: 0 !important;
  box-shadow: none;
  overflow: hidden;
  
  ${styledProp('$error', css`
    border: 1px solid #C8402F;
  `)}
  

  & > div {
    transition: all 300ms ease;
    height: 100%;
    padding-left: 11px;

    & > span {
      height: 100%;
      margin-left: 4px;
      border-left: 1px solid #DCDFE6;
    }
  }

  & > div > input {
    min-width: 0px !important;
  }

  & > div > span {
    width: 37px;
    border-left: 1px solid #D3DBE7;
    height: 100%;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #ECF0F6;
    color: #909399;
    margin-left: 0px;
  }

  & > div > span > span {
    justify-content: center;
  }

  &:focus {
    box-shadow: none !important;
    border-color: #31C447;
  }
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 5px;
  line-height: 120%;
  color: #C8402F;
`;

