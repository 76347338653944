import { Endpoints } from '@constants';

import ApiService from '../services/ApiService';

export const twoFAConfirmedPasswordStatusRequest = async (token) => {
  try {
    const { data } = await ApiService.call('get', Endpoints.TWO_FA_CONFIRMED_PASSWORD_STATUS, null, null, {
      Authorization: `Bearer ${token}`,
    });

    return { confirmed: data.confirmed, error: null };
  } catch (error) {
    return { confirmed: false, error: 'Something went wrong...' };
  }
};
