export const getAnalyticsList = (analytics, actionType, translate) => {
  if(!analytics) {
    return null;
  }

  const keys = ['in','exit', 'insufficient', 'unsubscribed', 'out'];
  const isConnectionChannel = actionType === 'connection_channel' ? translate('total_sends') : translate('out_of_node');

  const labelConfig = {
    'in': translate('enter_to_node'),
    'exit': translate('exit_criteria'),
    'unsubscribed': translate('unsubscribed'),
    'insufficient': translate('insufficient_data'),
    'out': isConnectionChannel,
  }
  const colorConfig = {
    'in': '#7C94B1',
    'insufficient': '#F04438',
    'unsubscribed': '#F04438',
    'exit': '#F04438',
    'out': '#12B76A'
  }
  const data = keys.map((key) => {
    return {
      label: labelConfig[key],
      color: colorConfig[key],
      count: analytics[key]
    }
  });

  return data
}