import { ContactsActionTypes, UserActionTypes, WorkspacesActionTypes } from '@store/actions/types';
import FieldsActionTypes from '@store/actions/types/fields';

import uniq from 'lodash.uniq';

import { by, updateAt } from '@utils';

const ORDINARY = Symbol('ordinary');

const initialState = {
  autocomplete: {
    [ORDINARY]: { },
  },
  dynamic_variables: {
    loading: false,
    data: [],
  },
  clickhouse_fields: {
    data: [],
    loading: false,
  },
};

const createPath = (path, filter) => {
  if (!filter) {
    return path.join('.');
  }

  return Object.entries(filter).reduce((acc, [k, v]) => `${acc}.${k}.${v}`, path.join('.'));
}

const fieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FieldsActionTypes.GET_AUTOCOMPLETE: {
      const { path, filter } = action.meta;
      const p = createPath(path, filter);

      return {
        ...state,
        autocomplete: updateAt(state.autocomplete, `${p}.loading`, true),
      };
    }
    case FieldsActionTypes.GET_AUTOCOMPLETE_SUCCESS: {
      const { path, filter, offset } = action.meta;
      const p = createPath(path, filter);

      return {
        ...state,
        autocomplete: updateAt(state.autocomplete, p, s => ({
          ...s,
          loading: false,
          data: uniq([...(action.payload || []), ...((offset !== 0 && s?.data) || [])]),
        })),
      }
    }
    case FieldsActionTypes.GET_AUTOCOMPLETE_ERROR: {
      const { path, filter } = action.meta;
      const p = createPath(path, filter);

      return {
        ...state,
        autocomplete: updateAt(state.autocomplete, `${p}.loading`, false)
      };
    }
    case FieldsActionTypes.GET_DYNAMIC_VARIABLES:
      return {
        ...state,
        dynamic_variables: {
          ...state.dynamic_variables,
          loading: true,
        },
      };
    case FieldsActionTypes.GET_DYNAMIC_VARIABLES_SUCCESS:
      return {
        ...state,
        dynamic_variables: {
          ...state.dynamic_variables,
          loading: false,
          data: action.payload?.data || [],
        },
      };
    case FieldsActionTypes.GET_DYNAMIC_VARIABLES_ERROR:
      return {
        ...state,
        dynamic_variables: {
          ...state.dynamic_variables,
          loading: false,
        },
      };
    case FieldsActionTypes.GET_CLICKHOUSE_FIELDS:
      return {
        ...state,
        clickhouse_fields: {
          ...state.clickhouse_fields,
          loading: true,
        },
      };
    case FieldsActionTypes.GET_CLICKHOUSE_FIELDS_SUCCESS:
      return {
        ...state,
        clickhouse_fields: {
          ...state.clickhouse_fields,
          data: (action.payload || []).map(item => {
            const prev = (state.clickhouse_fields.data || []).find(by('field', item.field));

            return ({
              ...item,
              active: prev?.active === undefined ? item.active : prev.active,
              sort_order: prev?.sort_order === undefined ? item.sort_order : prev?.sort_order,
            })
          }),
          loading: false,
        },
      };
    case FieldsActionTypes.GET_CLICKHOUSE_FIELDS_ERROR:
      return {
        ...state,
        clickhouse_fields: {
          ...state.clickhouse_fields,
          loading: false,
        },
      };
    case ContactsActionTypes.CHANGE_FIELD_DISPLAYING_STATUS: {
      const { field, active } = action.payload;

      return {
        ...state,
        clickhouse_fields: {
          ...state.clickhouse_fields,
          data: (state.clickhouse_fields?.data || []).map((item) => item.field === field ? {
            ...item,
            active,
          } : item)
        },
      };
    }
    case ContactsActionTypes.REORDER_FIELDS: {
      const { fields } = action.payload;

      return {
        ...state,
        clickhouse_fields: {
          ...state.clickhouse_fields,
          data: (state.clickhouse_fields?.data || []).map(f => ({
            ...f,
            sort_order: fields.find(by('field', f.field))?.sort_order,
          }))
        },
      }
    }
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH:
      return initialState;
    default:
      return state;
  }
};

export default fieldsReducer;
