import { useSelector } from 'react-redux';

import { webhookNodeAnalyticsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';
import useWorkflowOptionsPreview from '@hooks/useWorkflowOptionsPreview';

import { TemplateTypes } from '@constants/templates';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { SendingSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendingSection';

import { Container } from './styled.js';

const ApiRequestNodeSettingsInfo = ({ node }) => {
  const apiRequest = useWorkflowOptionsPreview(TemplateTypes.WEBHOOK, node.data?.api_request_id);
  const webhookAnalytics = useSelector(webhookNodeAnalyticsSelector);
  const { t } = useTranslation();

  if (!apiRequest) {
    return (
      <Container>
        <InfoTitle>{t('labels.template')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  return (
    <Container>
      <InfoTitle>{t('labels.template')}:</InfoTitle>
      <InfoText>
        {apiRequest.name}
      </InfoText>
      {(node.data.sending && !webhookAnalytics?.loading) && <SendingSection statuses={node.data.sending} out={node.data.analytics?.out} />}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
      {node.data.analytics && (
        <AnalyticsSection analytics={node.data.analytics} />
      )}
    </Container>
  );
}

export default ApiRequestNodeSettingsInfo;

