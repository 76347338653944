import React, { useState } from 'react';

import { Dropdown } from 'antd';

import { Input } from '@components';

import { Container, Title, List, Item, MenuStyled, MenuItemStyled, IconContainer, StyledButton, Error } from './styled';

const Main = React.forwardRef(({ title, children, onClick, buttonName, testId }, ref) => {
  return (
    <Container ref={ref}>
      {title && <Title>{title}</Title>}
      <List>{children}</List>
      <StyledButton data-testid={testId} onClick={onClick} appearance="secondary" width='100%'>{buttonName}</StyledButton>
    </Container>
  );
});

const ItemContent = ({ label, onClick, icon }) => {
  return (
    <>
      {label}
      <IconContainer onClick={onClick}>{icon}</IconContainer>
    </>
  )
};

const ItemContentWithInput = ({ initialValue, onSave, icon }) => {
  const [value, setValue] = useState(initialValue);

  const handleSave = () => {
    if(value === '') {
      return;
    }
    onSave(value)
  };

  const buttonStyles = {
    marginBottom: '0',
    height: '24px',
    width: '140px',
    fontSize: '14px',
    padding: '0 2px',
    border: 0,
    borderRadius: '4px',
    backgroundColor: '#C9E0FA',
  };

  return (
    <>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={buttonStyles}
        autoFocus
      />
      <IconContainer onClick={handleSave}>{icon}</IconContainer>
    </>
  )
}

const ItemList = ({ open, active, highlight, renderContent, menu, onClick, testId }) => {
  return (
    <div data-testid={testId}>
      <Dropdown open={open} overlay={(menu || <></>)} trigger={['click']}>
        <Item onClick={onClick} $active={active} $error={highlight}>
          {renderContent}
        </Item>
      </Dropdown>
    </div>
  );
};

const DropDownOverlay = ({ items }) => {
  return (
    <MenuStyled>
      {items.map(({ id, label, onClick, type }) => <MenuItemStyled $type={type} onClick={onClick} key={id}>{label}</MenuItemStyled>)}
    </MenuStyled>
  );
};

const ErrorContainer = ({ children }) => {
  return (
    <Error>
      {children}
    </Error>
  )
}

export const GroupSplit = {
  Main,
  ItemList,
  DropDownOverlay,
  ItemContent,
  ItemContentWithInput,
  ErrorContainer
};
