import { useState } from 'react';

import { Dropdown, Tooltip } from 'antd';

import { by } from '@utils';

import { Icon } from '@components';

import { Container, DropdownInner, Option, Overlay, Title, Hint } from './styled';

const Select = ({ tooltip, value, options = [], label, placeholder, onChange, error, containerStyle, hint }) => {
  const [opened, setOpened] = useState(false);

  const handleChange = value => {
    setOpened(false);
    onChange?.(value);
  };

  return (
    <Container>
      <Dropdown
        trigger={['click']}
        open={opened}
        onOpenChange={setOpened}
        overlay={(
          <Overlay>
            {options.map(({ value: current, label }) => (
              <Option key={label} onClick={() => handleChange(current)} $selected={current === value}>
                {label}
              </Option>
            ))}
          </Overlay>
        )}
      >
        <DropdownInner tooltip={tooltip} hasError={!!error} style={containerStyle}>
          {!!label && (
            <Title>{label}</Title>
          )}
          <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '90%' }}>
            {options.find(by('value', value))?.label || placeholder || 'Select'}
          </div>
          <Icon color="#fff" size={12} name="Dropdown-arrow" />
        </DropdownInner>
      </Dropdown>
      {hint && <Hint>{hint}</Hint>}
      {tooltip && (
        <Tooltip trigger={['click', 'hover']} placement="right" title={tooltip}>
          <div>
            <Icon size={20} color="#4986FD" style={{ marginLeft: 8 }} name="Info-icon" />
          </div>
        </Tooltip>
      )}
    </Container>
  );
};

export default Select;
