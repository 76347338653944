import isEmpty from 'lodash.isempty';

import { ReducerKeys } from '@constants';

export const templateSmsSelector = (store) => store[ReducerKeys.TEMPLATES].sms;

export const templateSingleSmsSelector = (store) => store[ReducerKeys.TEMPLATES].singleSmsItem?.data;

export const singleSmsLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].singleSmsItem?.loading;

export const singleEmailLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].singleEmailItem?.loading;

export const singleWebpushLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].singleWebpushItem?.loading;

export const singleViberLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].singleViberItem?.loading;

export const singleMobpilepushLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].singleMobilePush?.loading;

export const templateEmailsSelector = (store) => store[ReducerKeys.TEMPLATES].emails;

export const templateViberSelector = (store) => store[ReducerKeys.TEMPLATES].viber;

export const templateMobilePushSelector = (store) => store[ReducerKeys.TEMPLATES].mobilePush;

export const templateMobilePushLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].mobilePush.loading;

export const viberSummarySelector = (store) => store[ReducerKeys.TEMPLATES].viberSummary || {};

export const mobilePushSummarySelector = (store) => store[ReducerKeys.TEMPLATES].mobilePushSummary || {};

export const singleViberSelector = (store) => store[ReducerKeys.TEMPLATES].singleViberItem;

export const emailSampleDataSelector = (store) => {
  const sampleData = store[ReducerKeys.TEMPLATES].emailSampleData;

  if (isEmpty(sampleData)) {
    return {
      id: '',
      _id: '',
      userName: '',
    }
  }

  return {
    ...sampleData,
    id: sampleData.id,
    _id: sampleData._id,
    userName: sampleData.name || null,
  }
};

export const sampleContactDataSelector = (store) => store[ReducerKeys.TEMPLATES].emailSampleData.sampleData;

export const templateSingleEmailSelector = (store) => store[ReducerKeys.TEMPLATES].singleEmailItem.data;

export const emailDuplicateSelector = store => store[ReducerKeys.TEMPLATES].emailDuplicate;

export const smsDuplicateSelector = store => store[ReducerKeys.TEMPLATES].smsDuplicate;

export const newEmailSelector = store => store[ReducerKeys.TEMPLATES].newEmail;

export const newSmsSelector = store => store[ReducerKeys.TEMPLATES].newSms;

export const templateSingleMobilePushSelector = store => store[ReducerKeys.TEMPLATES].singleMobilePush?.data;

export const templateSingleWebpushSelector = (store) => store[ReducerKeys.TEMPLATES].singleWebpushItem?.data;

export const templateWebpushSelector = (store) => store[ReducerKeys.TEMPLATES].webpush;

export const templateApiRequestSelector = (store) => store[ReducerKeys.TEMPLATES].apiRequest;

export const templateSingleApiRequestSelector = (store) => store[ReducerKeys.TEMPLATES].singleApiRequestItem?.data;

export const apiRequestPreviewSelector = (store) => store[ReducerKeys.TEMPLATES].apiRequestPreview;

export const templateWebpushLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].webpush.loading;

export const templateApiRequestLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].apiRequest.loading;

export const templateViberLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].viber.loading;

export const templateEmailsLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].emails.loading;

export const templateSmsLoadingSelector = (store) => store[ReducerKeys.TEMPLATES].sms.loading;

export const emailPreviewSelector = (store) => store[ReducerKeys.TEMPLATES].emailPreview;

export const smsPreviewSelector = (store) => store[ReducerKeys.TEMPLATES].smsPreview;

export const viberPreviewSelector = (store) => store[ReducerKeys.TEMPLATES].viberPreview;

export const webpushPreviewSelector = (store) => store[ReducerKeys.TEMPLATES].webpushPreview;

export const mobilePushPreviewSelector = (store) => store[ReducerKeys.TEMPLATES].mobilePushPreview;

export const subscriptionGroupsSelector = (store) => store[ReducerKeys.TEMPLATES].subscriptionGroups;

export const foldersSelector = (store) => store[ReducerKeys.TEMPLATES].folders;

export const foldersListSelector = store => store[ReducerKeys.TEMPLATES].foldersList;

export const templatesStatisticsSelector = store => store[ReducerKeys.TEMPLATES].statistics;

export const contentCardTemplatesSelector = store => store[ReducerKeys.TEMPLATES].contentCards;

export const contentCardSelector = store => store[ReducerKeys.TEMPLATES].contentCard;

export const webPopupsSelector = store => store[ReducerKeys.TEMPLATES].webPopups;
export const webPopupSelector = store => store[ReducerKeys.TEMPLATES].webPopup;

export const simpleEmailsSelector = store => store[ReducerKeys.TEMPLATES].simple?.email?.folders;
export const simpleEmailLoadingSelector = store => store[ReducerKeys.TEMPLATES].simple?.email?.loading;
