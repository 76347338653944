import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
padding: 2px 0 32px 0;
`;

export const Link = styled.p`
    border-radius: var(--input-input-radius-big, 7px);
    background: var(--input-input-bg-disabled, #ECF0F6);
    padding: 7px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    transition: border-color 300ms ease;
    
    ${styledProp('$auth', css`
        border: 1px solid var(--Colors-Edge-500p, #31C447);
    `, css`
        border: 1px solid var(--input-input-bg-disabled, #ECF0F6);
    `)}
`;

export const LinkStatus = styled.div`
    text-align: right;
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;

    color: ${({ $isSuccess }) => ($isSuccess ? '#12B76A' : '#C8402F')};
`;

export const ActionsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex: 1;
`;

export const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #D3DBE7;
    /* overflow: hidden; */
    border-radius: 7px;
    transition: all 0.3s linear;
    ${switchStyle('$withoutBorder', {
    true: css`
        border: none;
        border-radius: 0px;
    `,
  })}
`;

export const TrackingOptions = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const TrackingTitle = styled.div`
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-SemiBold;
    font-size: 14px;
    line-height: 20px;
`;

export const DomainRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    & > * {
        width: 100%;
    }
    padding-left: 28px;
`;

export const LinkRow = styled.div`
    display: grid;
    grid-template-columns: 65% 25% 10%;
    grid-column-gap: 5px;
    padding: 8px 16px;
    align-items: center;
    border-bottom: 1px solid #D3DBE7;
    transition: all 0.3s linear;
    ${switchStyle('$isLast', {
    true: css`
        border-bottom: 1px solid transparent;
    `,
  })}
    ${switchStyle('$backgroundType', {
    1: css`
      background: #ECF0F6;
    `,
    2: css`
        background: #F8FAFE;
    `
  })}

`
export const LinkText = styled.p`
    font-family: Manrope-Medium;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
`

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LinkExample = styled.div`
    display: flex;
    flex-direction: column;

    color: var(--Colors-Edge-500p, #31C447);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;
    
    & > span {
        margin-bottom: 12px;
        color: var(--input-input-txt-lable, #7C94B1);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Manrope-Medium;
        font-size: 12px;
        line-height: 18px;
    }
`;
