import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  allWorkflowOptionsSelector,
  optionsSelector,
  subscriptionGroupsSelector,
  workflowOptionsSelector
} from '@store/selectors';

import {
  EmailStatusOptions, MobilePushStatusOptions, RFMColorsConfig,
  SmsStatusOptions,
  ViberStatusOptions,
  WebpushStatusOptions,
  WorkflowNodes
} from '@constants';
import { ContentCardStatusOptions } from '@constants/contentCardsStatuses';
import { TemplateTypes } from '@constants/templates';

import { by, extract, identity } from '@utils';
import { iterate } from '@utils/workflows/refactored/structureParser';

const useSystemAutocomplete = () => {
  const fieldOptions = useSelector(optionsSelector);
  const subscribeGroups = useSelector(subscriptionGroupsSelector);
  const workflowOptions = useSelector(workflowOptionsSelector);
  const allWorkflowOptions = useSelector(allWorkflowOptionsSelector);

  const workflowSuggestions = (allWorkflowOptions || []).map(({ name, version, id }) => ({
    value: id,
    label: `${name} (${version})`
  }));

  const workflowNodesSuggestions = (allWorkflowOptions || []).flatMap(({ name, version, tree_map }) => {
    const nodes = [];

    iterate(tree_map, (node) => {
      if (node.data?.actionType === 'connection_channel') {
        nodes.push(node);
      }
    })

    return nodes.filter(identity).map(node => ({
      value: node.id,
      type: node.type,
      label: `${name} (${version}) - ${node.data?.label}`
    }));
  });

  const systemAutocomplete = useMemo(() => ({
    'nf_notification_id': {
      'event': {
        'email': workflowOptions?.[TemplateTypes.EMAIL]?.map?.(({ id, name }) => ({ value: id, label: name })),
        'sms': workflowOptions?.[TemplateTypes.SMS]?.map?.(({ id, name }) => ({ value: id, label: name })),
        'web_push': workflowOptions?.[TemplateTypes.WEB_PUSH]?.map?.(({ id, name }) => ({ value: id, label: name })),
        'viber': workflowOptions?.[TemplateTypes.VIBER]?.map?.(({ id, name }) => ({ value: id, label: name })),
        'mobile_push': workflowOptions?.[TemplateTypes.MOBILE_PUSH]?.map?.(({ id, name }) => ({ value: id, label: name })),
        'content_card': workflowOptions?.[TemplateTypes.MESSAGE_FEED]?.map?.(({ id, name }) => ({ value: id, label: name })),
        'web_popup': workflowOptions?.[TemplateTypes.WEB_POPUP]?.map?.(({ id, name }) => ({ value: id, label: name })),
      }
    },
    'nf_entity_id': {
      'event': {
        'email': workflowSuggestions,
        'sms': workflowSuggestions,
        'web_push': workflowSuggestions,
        'viber': workflowSuggestions,
        'mobile_push': workflowSuggestions,
        'content_card': workflowSuggestions,
      }
    },
    'nf_sub_entity_id': {
      'event': {
        'email': workflowNodesSuggestions.filter(by('type', WorkflowNodes.SEND_EMAIL)),
        'sms': workflowNodesSuggestions.filter(by('type', WorkflowNodes.SEND_SMS)),
        'web_push': workflowNodesSuggestions.filter(by('type', WorkflowNodes.WEBPUSH)),
        'viber': workflowNodesSuggestions.filter(by('type', WorkflowNodes.VIBER)),
        'mobile_push': workflowNodesSuggestions.filter(by('type', WorkflowNodes.MOBILE_PUSH)),
        'content_card': workflowNodesSuggestions.filter(by('type', WorkflowNodes.SEND_CARD)),
      }
    },
    'nf_event': {
      'event': {
        'email': EmailStatusOptions.filter(extract('value')),
        'sms': SmsStatusOptions.filter(extract('value')),
        'web_push': WebpushStatusOptions.filter(extract('value')),
        'viber': ViberStatusOptions.filter(extract('value')),
        'mobile_push': MobilePushStatusOptions.filter(extract('value')),
        'content_card': ContentCardStatusOptions.filter(extract('value')),
      }
    },
    'rf_rfm_id': {
      'event': {
        'rfm_segment_drifting': (workflowOptions?.rfmResources || []).map(({ name, id }) => ({ value: id, label: name })),
      },
    },
    'rf_segment': {
      'event': {
        'rfm_segment_drifting': RFMColorsConfig.map(({ name }) => ({ value: name, label: name })),
      },
    },
    'rf_previous_segment': {
      'event': {
        'rfm_segment_drifting': RFMColorsConfig.map(({ name }) => ({ value: name, label: name })),
      },
    },
    '_sgids': {
      '_events': {
        'event': {
          '_em': subscribeGroups?.data?.map?.(({ id, name }) => ({ value: id, label: name })),
        },
      },
    },
    '_sg': {
      '_i': subscribeGroups?.data?.map?.(({ id, name }) => ({ value: id, label: name })),
    }
  }), [fieldOptions?.fields, subscribeGroups?.data, allWorkflowOptions?.length]);

  return systemAutocomplete;
}

export default useSystemAutocomplete;
