import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import { archiveMobilePushTemplate, getMobilePushTemplates, getSingleMobilePushTemplate } from "@store/actions/creators";

import { Button } from '@velitech/ui';

import { useModalState, usePermissions, useTranslation, useNavigate } from "@hooks";

import { Paths, UserPermissions } from "@constants";

import { notify, getDefaultLang } from "@utils";

import { Link, NoData, WithPermissions, TemplateStatisticsModal, ViewMobilePushTemplateModal } from "@components";

import { Container } from "./styled";

import LargeTemplateItem from "../../../../../../components/ui/LargeTemplateItem/LargeTemplateItem";
import { EmailTemplatesRow, TemplatesHeader, TemplatesInner } from "../../styled";

const MobilePushSection = ({ mobilePushes = [] }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const viewMobilePushModal = useModalState();
  const [statisticMobilePushId, setMobilePushStatisticsId] = useState(null);
  const statisticsMobilePushModal = useModalState();
  const { p, t } = useTranslation('dashboard_page');
  const [mobilePushViewItem, setMobilePushViewItem] = useState({});

  const handleCreateMobilePushTemplateClick = () => {
    navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/new?f=default`);
  }

  const handleViewMobilePushPush = (data) => {
    const tmpData = getDefaultLang(data?.languages);
    setMobilePushViewItem({ ...tmpData, image: data?.image?.url || data.image_url });
    viewMobilePushModal.open();
  }

  const handleEditMobilePush = (id, used) => {
    if (used !== 1) {
      navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/${id}?view=1&f=default`)
    }
  }

  const handleDuplicateMobilePush = (id) => {
    dispatch(getSingleMobilePushTemplate({ id }));
    navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/duplicate?f=default`)
  }

  const handleMobilePushArchive = (id, name) => {
    dispatch(archiveMobilePushTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.mobile_push_archived'), name);
        dispatch(getMobilePushTemplates({ page: 1, perPage: 6, all: true }));
      },
    }));
  }

  const handleMobilePushStatisticsClick = (id) => {
    setMobilePushStatisticsId(id);
    statisticsMobilePushModal.open();
  };

  const handleCloseMobilePushEmailModal = () => {
    setMobilePushViewItem({});
    viewMobilePushModal.close();
  };

  return (
    <Container data-testid="dashboard-templates-section-mobile-push">
      <TemplatesInner style={{ marginTop: 10, paddingBottom: 0, pointerEvents: !!mobilePushes?.meta?.total ? 'auto' : 'none', minHeight: '246px' }}>
        <TemplatesHeader>
          <Link to={`${Paths.TEMPLATES_MOBILE_PUSHES}/default`} icon>{p('mobile_push')}</Link>
          <WithPermissions name={UserPermissions.CREATE_MOBILE_PUSH}>
            <Button
              size={'sm'}
              style={{
                padding: '6px 14px',
                height: '26px',
                fontSize: '12px',
                lineHeight: '14px',
                borderRadius: '5px'
              }}
              data-testid="dashboard-templates-section-mobile-push-create"
              width="none"
              onClick={() => handleCreateMobilePushTemplateClick(true)}
            >
              {t('actions.create')}
            </Button>
          </WithPermissions>
        </TemplatesHeader>
        <EmailTemplatesRow $empty={!(!!mobilePushes?.data?.filter(t => t.status !== 3)?.length)}>
          {!(!!mobilePushes.data?.filter(t => t.status !== 3)?.length) && <NoData style={{ height: 'auto', width: 'auto' }} />}
          {mobilePushes.data?.filter(t => t.status !== 3)?.slice(0, 4).map(({
            id,
            name,
            created_at,
            status,
            workers,
            image,
            used,
            message,
            title,
            languages
          }, i, arr) => (
            <LargeTemplateItem
              type="mobile_push"
              key={id}
              used={used}
              id={id}
              status={status}
              testId={`mobile-push-templates-item-${i}`}
              workflows={workers}
              image_url={image?.url}
              data={arr[i]}
              view={!permissions.allowed(UserPermissions.UPDATE_MOBILE_PUSH)}
              name={name}
              title={name}
              date={created_at}
              body={message}
              fromDashboard
              onArchive={() => handleMobilePushArchive(id, name)}
              onStatisticsClick={() => handleMobilePushStatisticsClick(id)}
              onViewClick={() => handleViewMobilePushPush({ id, name, message, title, image, languages, workflows: workers })}
              onEditClick={() => handleEditMobilePush(id, !!workers?.length)}
              onDuplicateClick={() => handleDuplicateMobilePush(id)}
            />
          ))}
        </EmailTemplatesRow>
      </TemplatesInner>
      <ViewMobilePushTemplateModal
        data={mobilePushViewItem}
        opened={viewMobilePushModal.opened}
        onClose={handleCloseMobilePushEmailModal}
      />
      <TemplateStatisticsModal
        id={statisticMobilePushId} 
        opened={statisticsMobilePushModal.opened} 
        onClose={statisticsMobilePushModal.close}
        type={'mobile_push'}
      />
    </Container>
  )
}

export default React.memo(MobilePushSection);
