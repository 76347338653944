import { Checkbox } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 7px;
  min-height: 100px;
  max-height: 166px;
  overflow-y: auto;
  padding: 16px 14px;
`;

export const Label = styled.div`
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
`;

export const StyledCheckbox = styled(Checkbox)`
  & > span {
    max-width: 100%;
  }
`;

