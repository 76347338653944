import styled from 'styled-components';

export const Container =styled.div`
  padding: 0px 0 40px 0;
  width: 100%;
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 18px;
`;

export const Description = styled.div`
  background: #FFFAED;
  border: 1px solid #FFDC7B;
  border-radius: 10px;
  font-size: 13px;
  padding: 7px 5px;
`;

export const Content = styled.div`
  margin-top: 20px;
`;
