import styled from 'styled-components';

import { switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  min-width: 22px;
  margin-right: 10px;
  border-radius: 4px;

  ${switchStyle('type', {
    'entry_point': `
      background: linear-gradient(213.29deg, #6EDEAD 11.15%, #3BCD9A 90.5%);
    `,
    'connection_channel': `
      background: linear-gradient(213.29deg, #F4A53C 11.15%, #ED992A 90.5%);
    `,
    'actions': `
      background: linear-gradient(213.29deg, #17A8FD 11.15%, #0890F8 90.5%);
    `,
  })}
`;
