import styled from 'styled-components';

import { styledProp } from '@utils';

export const IconWrapper = styled.div`
  cursor: pointer;
  overflow: visible;
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6 !important;
    border-radius: 7px;
  `)}
`;
