import omit from 'lodash.omit';
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { compose, deepEqual, update } from '@utils';

import useModalState from './useModalState';

const defaultExtractor = ({ target: { value } }) => value;

const resolveType = (init) => {
  if (Array.isArray(init)) {
    return ArrayParam;
  }

  switch (typeof init) {
    case 'number':
      return NumberParam;
    case 'string':
      return StringParam;
    default:
      return StringParam;
  }
};

const defaultOpened = (fs, dfs) => Object.values(omit(fs, dfs)).some(f => {
  if (Array.isArray(f)) {
    return !!f.length;
  }

  return !!f;
});

const useFilters = (initialState, dynamicFilters) => {
  const [filters, setFilters] = useQueryParams(
    Object.keys(initialState).reduce((acc, key) => ({
      ...acc,
      [key]: withDefault(resolveType(initialState[key]), initialState[key])
    }), {})
  );
  const dropdown = useModalState(defaultOpened(filters, dynamicFilters));

  const updateFilter = key => (val) => setFilters(f => ({ [key]: update(f[key], val) }));

  const handleFilterChange = (key, extractor = defaultExtractor) => compose(updateFilter(key), extractor);

  const clear = () => setFilters(initialState);

  const hasChanged = () => !deepEqual(filters, initialState);

  return {
    filters,
    ...dropdown,
    opened: dropdown.opened,
    clear,
    onChange: handleFilterChange,
    hasChanged,
  };
};

export default useFilters;
