import styled from 'styled-components';

import { styledProp } from '@utils';

export const Wrapper = styled.div`
  ${styledProp('$opened', `
    opacity: 1;
    pointer-events: auto;
  `, `
    opacity: 0;
    pointer-events: none;
  `)}
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  transition: all 280ms ease;
`;

export const Container = styled.div`
  padding: 16px 26px;
  background: #F9FBFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 13px;
  line-height: 100%;
  color: #303133;
`;
