import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeLatest, put } from 'redux-saga/effects';

import { Endpoints } from '@constants';

import { HistoryNotificationsActionTypes } from '../../actions/types';

function* getEmailsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_EMAIL,
  }));
}

function* getFacebookSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_FACEBOOK,
  }));
}

function* getMobilePushesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_MOBILE_PUSHES,
  }));
}

function* getPromoCodesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/notificator/discount',
  }));
}

function* getSmsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_SMS,
  }));
}

function* getTemplateSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_TEMPLATES,
  }));
}

function* getVibersSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_VIBER,
  }));
}

function* getWebhookSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_WEBHOOK,
  }));
}

function* getWebpushSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_WEBPUSH,
  }));
}

function* getIncludeCampaignSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_INCLUDE_CAMPAIGN,
  }));
}

function* getGiveRewardSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_GIVE_REWARD,
  }));
}

function* getContentCardsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_CONTENT_CARDS,
  }));
}

function* getWebPopupsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATION_HISTORY_WEB_POPUPS,
  }))
}

function* getIncludeCampaignTemplatesSaga(action) {
  if (action.payload.ids) {
    yield put(createRequestAction(action, {
      method: 'post',
      endpoint: '/api/horizon/campaigns',
      body: { uuids: action.payload.ids, page: null, size: null },
    }));
  }
}

function* getGiveRewardTemplatesSaga(action) {
  if (action.payload.freeSpinIds) {
    yield put(createRequestAction(
      { ...action, meta: { ...action.meta, reward_type: 'freeSpins' } },
      {
        method: 'post',
        endpoint: '/api/horizon/freeSpins',
        body: { uuids: action.payload.freeSpinIds, page: null, size: null },
      }
    ));
  }
  if (action.payload.bonusIds) {
    yield put(createRequestAction(
      { ...action, meta: { ...action.meta, reward_type: 'bonuses' } },
      {
        method: 'post',
        endpoint: '/api/horizon/bonuses',
        body: { uuids: action.payload.bonusIds, page: null, size: null },
      }
    ));
  }

  if (action.payload.freeBetIds) {
    yield put(createRequestAction(
      { ...action, meta: { ...action.meta, reward_type: 'freeBets' } },
      {
        method: 'post',
        endpoint: '/api/horizon/freeBets',
        body: { uuids: action.payload.freeBetIds, page: null, size: null },
      }
    ));
  }
}

function* getNotificationHistoryCountSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/${action.payload.entity}/count`,
  }));
}

export default function* contactsRootSaga() {
  yield all([
    takeLatest(HistoryNotificationsActionTypes.GET_EMAIL, getEmailsSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_SMS, getSmsSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_WEBHOOK, getWebhookSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_VIBERS, getVibersSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_TEMPLATES, getTemplateSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_WEBPUSH, getWebpushSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_MOBILE_PUSHES, getMobilePushesSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_PROMO_CODES, getPromoCodesSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_FACEBOOK, getFacebookSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_GIVE_REWARD, getGiveRewardSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_INCLUDE_CAMPAIGN, getIncludeCampaignSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_CONTENT_CARDS, getContentCardsSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_WEB_POPUPS, getWebPopupsSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_INCLUDE_CAMPAIGN_TEMPLATES, getIncludeCampaignTemplatesSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_GIVE_REWARD_TEMPLATES, getGiveRewardTemplatesSaga),
    takeLatest(HistoryNotificationsActionTypes.GET_NOTIFICATION_HISTORY_COUNT, getNotificationHistoryCountSaga),
  ]);
}
