import { v4 } from "uuid";

import { EntryNodes, FlowNodeData, NodeName } from "@/constants/flow";

export const validNodesByDefault = [
  NodeName.END_PATH,
  NodeName.EXCLUDE,
  NodeName.INCLUDE,
  NodeName.AB_TEST,
  NodeName.AB_SPLITTER,
  NodeName.CONTROL_GROUP,
  ...EntryNodes,
]

const createNodeDataPreset = (name: NodeName | undefined): Partial<FlowNodeData> => {
  switch (name) {
    case NodeName.AB_TEST:
      return {
        segments: [
          {
            id: v4(),
            value: 50,
            name: NodeName.AB_SPLITTER,
            label: 'Path A',
          },
          {
            id: v4(),
            value: 50,
            name: NodeName.AB_SPLITTER,
            label: 'Path B',
          }
        ],
        state: validNodesByDefault.includes(name) ? 1 : 0,
      };
    case NodeName.GROUP_SPLIT:
      return {
        segments: [{
          id: v4(),
          label: 'Group A',
          aggregates: [],
          funnels: [],
          type: 'include',
          name: NodeName.INCLUDE,
          actionType: 'include',
          query: null,
        }],
        state: validNodesByDefault.includes(name) ? 1 : 0,
      };
    default:
      return {
        state: validNodesByDefault.includes(name) ? 1 : 0,
      };
  }
}

export const createNodeData = (name: NodeName, data: FlowNodeData): Partial<FlowNodeData> => {
  const preset = createNodeDataPreset(name);

  return {
    ...preset,
    ...data,
  }
};
