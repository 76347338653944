import styled from "styled-components";

import { TextVariants } from "@constants";

import { styledProp } from "@utils";

import { Text } from "@components";

export const TemplatesSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const TemplatesTitle = styled(Text).attrs({ variant: TextVariants.HEADING_2 })`
  color: #303133;
  margin-bottom: 12px;
  font-family: Manrope-SemiBold !important;
`;

export const TemplatesCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TemplatesContainer = styled.div`
  display: flex;
  flex: 1;
  
  ${styledProp('onlyEmails', `
    display: block;
  `)}
`;

