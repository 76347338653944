import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { by } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';

import { OperatorSelect } from '@components';

import {
  Container, Content,
  DeleteButtonContainer,
  EventName,
  EventPanel,
  FiltersContainer,
} from './styled';

const ArrayFilter = ({ value, onChange, onDelete, errors, disabled }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const field = customerFields?.find?.(by('field', value.field));

  const handleValueChange = (value) => {
    onChange(q => ({ ...q, value: { ...q.value, value } }));
  };

  const handleOperatorChange = (operator, type) => {
    onChange(q => ({ ...q, operator, type: type === 'common' ? field.type : type, value: { ...q.value, value: [], type: 'scalar' } }));
  };

  const handleDelete = () => {
    if(!disabled){
      onDelete();
    }
  }
  console.log(errors?.errors || errors || {}, 'errors?.errors || errors || {}')
  return (
    <Container>
      <EventPanel>
        <Content>
          <EventName>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-inside-1_220_15100" fill="white">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.76522 7.18437C6.76522 8.80713 5.44971 10.1226 3.82694 10.1226C2.20418 10.1226 0.888672 8.80713 0.888672 7.18437C0.888672 5.5616 2.20418 4.24609 3.82694 4.24609C5.44971 4.24609 6.76522 5.5616 6.76522 7.18437ZM6.76522 15.9992C6.76522 17.622 5.44971 18.9375 3.82694 18.9375C2.20418 18.9375 0.888672 17.622 0.888672 15.9992C0.888672 14.3764 2.20418 13.0609 3.82694 13.0609C5.44971 13.0609 6.76522 14.3764 6.76522 15.9992ZM3.82694 27.7523C5.44971 27.7523 6.76522 26.4368 6.76522 24.814C6.76522 23.1912 5.44971 21.8757 3.82694 21.8757C2.20418 21.8757 0.888672 23.1912 0.888672 24.814C0.888672 26.4368 2.20418 27.7523 3.82694 27.7523Z"/>
              </mask>
              <path fillRule="evenodd" clipRule="evenodd" d="M6.76522 7.18437C6.76522 8.80713 5.44971 10.1226 3.82694 10.1226C2.20418 10.1226 0.888672 8.80713 0.888672 7.18437C0.888672 5.5616 2.20418 4.24609 3.82694 4.24609C5.44971 4.24609 6.76522 5.5616 6.76522 7.18437ZM6.76522 15.9992C6.76522 17.622 5.44971 18.9375 3.82694 18.9375C2.20418 18.9375 0.888672 17.622 0.888672 15.9992C0.888672 14.3764 2.20418 13.0609 3.82694 13.0609C5.44971 13.0609 6.76522 14.3764 6.76522 15.9992ZM3.82694 27.7523C5.44971 27.7523 6.76522 26.4368 6.76522 24.814C6.76522 23.1912 5.44971 21.8757 3.82694 21.8757C2.20418 21.8757 0.888672 23.1912 0.888672 24.814C0.888672 26.4368 2.20418 27.7523 3.82694 27.7523Z" fill="white"/>
              <path d="M3.82694 11.1064C5.99304 11.1064 7.74901 9.35046 7.74901 7.18437H5.78142C5.78142 8.26379 4.90637 9.13884 3.82694 9.13884V11.1064ZM-0.0951245 7.18437C-0.0951245 9.35046 1.66085 11.1064 3.82694 11.1064V9.13884C2.74752 9.13884 1.87247 8.26379 1.87247 7.18437H-0.0951245ZM3.82694 3.2623C1.66085 3.2623 -0.0951245 5.01827 -0.0951245 7.18437H1.87247C1.87247 6.10494 2.74752 5.22989 3.82694 5.22989V3.2623ZM7.74901 7.18437C7.74901 5.01827 5.99304 3.2623 3.82694 3.2623V5.22989C4.90637 5.22989 5.78142 6.10494 5.78142 7.18437H7.74901ZM3.82694 19.9213C5.99304 19.9213 7.74901 18.1653 7.74901 15.9992H5.78142C5.78142 17.0786 4.90637 17.9537 3.82694 17.9537V19.9213ZM-0.0951245 15.9992C-0.0951245 18.1653 1.66085 19.9213 3.82694 19.9213V17.9537C2.74752 17.9537 1.87247 17.0786 1.87247 15.9992H-0.0951245ZM3.82694 12.0771C1.66085 12.0771 -0.0951245 13.8331 -0.0951245 15.9992H1.87247C1.87247 14.9198 2.74752 14.0447 3.82694 14.0447V12.0771ZM7.74901 15.9992C7.74901 13.8331 5.99304 12.0771 3.82694 12.0771V14.0447C4.90637 14.0447 5.78142 14.9198 5.78142 15.9992H7.74901ZM5.78142 24.814C5.78142 25.8934 4.90637 26.7685 3.82694 26.7685V28.7361C5.99304 28.7361 7.74901 26.9801 7.74901 24.814H5.78142ZM3.82694 22.8595C4.90637 22.8595 5.78142 23.7346 5.78142 24.814H7.74901C7.74901 22.6479 5.99304 20.8919 3.82694 20.8919V22.8595ZM1.87247 24.814C1.87247 23.7346 2.74752 22.8595 3.82694 22.8595V20.8919C1.66084 20.8919 -0.0951245 22.6479 -0.0951245 24.814H1.87247ZM3.82694 26.7685C2.74752 26.7685 1.87247 25.8934 1.87247 24.814H-0.0951245C-0.0951245 26.9801 1.66085 28.7361 3.82694 28.7361V26.7685Z" fill="#0C1015" mask="url(#path-1-inside-1_220_15100)"/>
              <mask id="path-3-inside-2_220_15100" fill="white">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.28322 7.1847C9.28322 6.02559 10.2229 5.08594 11.382 5.08594H29.0116C30.1707 5.08594 31.1104 6.02559 31.1104 7.1847C31.1104 8.34382 30.1707 9.28347 29.0116 9.28347H11.382C10.2229 9.28347 9.28322 8.34382 9.28322 7.1847ZM9.2832 15.9995C9.2832 14.8404 10.2229 13.9007 11.382 13.9007H29.0116C30.1707 13.9007 31.1104 14.8404 31.1104 15.9995C31.1104 17.1586 30.1707 18.0983 29.0116 18.0983H11.382C10.2229 18.0983 9.2832 17.1586 9.2832 15.9995ZM11.382 22.7156C10.2229 22.7156 9.2832 23.6552 9.2832 24.8143C9.2832 25.9734 10.2229 26.9131 11.382 26.9131H29.0116C30.1707 26.9131 31.1104 25.9734 31.1104 24.8143C31.1104 23.6552 30.1707 22.7156 29.0116 22.7156H11.382Z"/>
              </mask>
              <path fillRule="evenodd" clipRule="evenodd" d="M9.28322 7.1847C9.28322 6.02559 10.2229 5.08594 11.382 5.08594H29.0116C30.1707 5.08594 31.1104 6.02559 31.1104 7.1847C31.1104 8.34382 30.1707 9.28347 29.0116 9.28347H11.382C10.2229 9.28347 9.28322 8.34382 9.28322 7.1847ZM9.2832 15.9995C9.2832 14.8404 10.2229 13.9007 11.382 13.9007H29.0116C30.1707 13.9007 31.1104 14.8404 31.1104 15.9995C31.1104 17.1586 30.1707 18.0983 29.0116 18.0983H11.382C10.2229 18.0983 9.2832 17.1586 9.2832 15.9995ZM11.382 22.7156C10.2229 22.7156 9.2832 23.6552 9.2832 24.8143C9.2832 25.9734 10.2229 26.9131 11.382 26.9131H29.0116C30.1707 26.9131 31.1104 25.9734 31.1104 24.8143C31.1104 23.6552 30.1707 22.7156 29.0116 22.7156H11.382Z" fill="#87E2BA"/>
              <path d="M11.382 4.14149C9.70126 4.14149 8.33877 5.50398 8.33877 7.1847H10.2277C10.2277 6.54719 10.7445 6.03038 11.382 6.03038V4.14149ZM29.0116 4.14149H11.382V6.03038H29.0116V4.14149ZM32.0548 7.1847C32.0548 5.50398 30.6923 4.14149 29.0116 4.14149V6.03038C29.6491 6.03038 30.1659 6.54719 30.1659 7.1847H32.0548ZM29.0116 10.2279C30.6923 10.2279 32.0548 8.86542 32.0548 7.1847H30.1659C30.1659 7.82222 29.6491 8.33902 29.0116 8.33902V10.2279ZM11.382 10.2279H29.0116V8.33902H11.382V10.2279ZM8.33877 7.1847C8.33877 8.86542 9.70126 10.2279 11.382 10.2279V8.33902C10.7445 8.33902 10.2277 7.82222 10.2277 7.1847H8.33877ZM11.382 12.9563C9.70125 12.9563 8.33876 14.3188 8.33876 15.9995H10.2276C10.2276 15.362 10.7445 14.8452 11.382 14.8452V12.9563ZM29.0116 12.9563H11.382V14.8452H29.0116V12.9563ZM32.0548 15.9995C32.0548 14.3188 30.6923 12.9563 29.0116 12.9563V14.8452C29.6491 14.8452 30.1659 15.362 30.1659 15.9995H32.0548ZM29.0116 19.0427C30.6923 19.0427 32.0548 17.6802 32.0548 15.9995H30.1659C30.1659 16.637 29.6491 17.1538 29.0116 17.1538V19.0427ZM11.382 19.0427H29.0116V17.1538H11.382V19.0427ZM8.33876 15.9995C8.33876 17.6802 9.70125 19.0427 11.382 19.0427V17.1538C10.7445 17.1538 10.2276 16.637 10.2276 15.9995H8.33876ZM10.2276 24.8143C10.2276 24.1768 10.7445 23.66 11.382 23.66V21.7711C9.70125 21.7711 8.33876 23.1336 8.33876 24.8143H10.2276ZM11.382 25.9686C10.7445 25.9686 10.2276 25.4518 10.2276 24.8143H8.33876C8.33876 26.495 9.70125 27.8575 11.382 27.8575V25.9686ZM29.0116 25.9686H11.382V27.8575H29.0116V25.9686ZM30.1659 24.8143C30.1659 25.4518 29.6491 25.9686 29.0116 25.9686V27.8575C30.6923 27.8575 32.0548 26.495 32.0548 24.8143H30.1659ZM29.0116 23.66C29.6491 23.66 30.1659 24.1768 30.1659 24.8143H32.0548C32.0548 23.1336 30.6923 21.7711 29.0116 21.7711V23.66ZM11.382 23.66H29.0116V21.7711H11.382V23.66Z" fill="#0C1015" mask="url(#path-3-inside-2_220_15100)"/>
            </svg>
            {field.label}
          </EventName>
          <DeleteButtonContainer $disabled={disabled} onClick={handleDelete}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.75 7.5H7.5V15H8.75V7.5Z" fill="#F04438"/>
              <path d="M12.5 7.5H11.25V15H12.5V7.5Z" fill="#F04438"/>
              <path d="M2.5 3.75V5H3.75V17.5C3.75 17.8315 3.8817 18.1495 4.11612 18.3839C4.35054 18.6183 4.66848 18.75 5 18.75H15C15.3315 18.75 15.6495 18.6183 15.8839 18.3839C16.1183 18.1495 16.25 17.8315 16.25 17.5V5H17.5V3.75H2.5ZM5 17.5V5H15V17.5H5Z" fill="#F04438"/>
              <path d="M12.5 1.25H7.5V2.5H12.5V1.25Z" fill="#F04438"/>
            </svg>
          </DeleteButtonContainer>
        </Content>
        <FiltersContainer>
          <OperatorSelect
            changeDisabled={disabled}
            appearance="row"
            smaller
            wrapperStyle={{ width: '100%' }}
            initialType={field?.type}
            type={field?.type}
            value={{
              operator: value.operator,
              value: value.value?.value || '',
              type: value.type,
              errors: errors?.errors || errors || {}
            }}
            autocomplete={field.field === "tags" && {
              "event": "tag",
              "fields": ["tg_tag_name"]
            }}
            onValueChange={handleValueChange}
            onOperatorChange={handleOperatorChange}
            field={value.field}
          />
        </FiltersContainer>
      </EventPanel>
    </Container>
  );
};

export default ArrayFilter;
