import { useSelector } from 'react-redux';

import { subscriptionGroupsSelector } from '@store/selectors';

import { SegmentsRuleTypes } from '@constants';

import { Container, Field } from './styled';

const UnsubscribeGroupFilterPanel = ({ onSelect }) => {
  const subscriptionGroups = useSelector(subscriptionGroupsSelector);

  const opts = subscriptionGroups.data?.map(({ name, id }) => ({ value: id, label: name }));

  return (
    <Container data-testid={'store-segment-filter-unsubscribe-group-tab-content'}>
      {opts?.map?.(({ value, label }, index) => (
        <Field data-testid={`store-segment-filter-panel-unsubscribe-group-field-${label}`} key={index} onClick={() => { onSelect(value, SegmentsRuleTypes.UNSUBSCRIBE_GROUP); }}>
          {label}
        </Field>
      ))}
    </Container>
  );
};

export default UnsubscribeGroupFilterPanel;
