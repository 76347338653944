import { useState } from 'react';

import { Tab, Tabs } from '@velitech/ui';

import { RateItem } from './RateItem';
import { StatusesTab } from './StatusesTab';
import { Container, Content, RateList, TabsContainer } from "./styled";

export const TemplateStatistics = ({ total, rates, summary, loading }) => {
  const [tab, setTab] = useState('rates')
  const tabs = [{
    id: 'Conversion rate',
    value: 'rates',
    children: 'Conversion rate',
  }, {
    id: 'Status analytics',
    value: 'statuses',
    children: 'Status analytics',
  }];

  return (
    <Container>
      <TabsContainer>
        <Tabs
          tabs={tabs}
          value={tab}
          className="tabs-container"
          onChange={(value) => setTab(value)}
          renderItem={(props) => {
            return <Tab {...props} />;
          }}
        />
      </TabsContainer>
      <Content>
        {tab === 'rates' && (
          <RateList>
            {rates.map((rate, index) => <RateItem key={index} data={rate} loading={loading} />)}
          </RateList>
        )}
        {tab === 'statuses' && (
          <StatusesTab
            total={total}
            data={rates}
            summary={summary}
          />
        )}
      </Content>
    </Container>
  )
}