import { Button } from '@velitech/ui';

import { useNavigate } from '@hooks';

import { Paths } from '@constants';

import { Modal } from '@components';

import { Container, Hint, ResendLink } from './styled.js';

const SuccessModal = ({ opened, onClose, onResend, email, resendEnabled, resendErrors }) => {
  const navigate = useNavigate();

  return (
    <Modal
      opened={opened}
      width={email?.length > 30 ? 780 : 692}
      onClose={onClose}
      wrapClassName="new"
      containerStyle={{ background: 'transparent' }}
      maskStyle={{ backgroundColor: 'transparent', backdropFilter: 'blur(40px)', webkitBackdropFilter: 'blur(40px)' }}
      style={{ borderRadius: '20px' }}
      contentStyles={{
        background: '#313136',
        overflow: 'hidden',
        borderRadius: '20px',
        padding: '60px 70px 22px 70px',
      }}
    >
      <Container>
        {!resendErrors.errors['email'] ? (
          <>
            <Hint data-testid="reg-message-success">
              We have just sent an email with further instructions to "<span>{email}</span>". Please complete registration
              <br/>from your mail
            </Hint>
            <Button onClick={onClose} data-testid="reg-success-close" style={{ width: '180px' }}>Ok, thank you</Button>
            <ResendLink enabled={resendEnabled} data-testid="reg-resend" onClick={onResend}>Resend confirmation letter</ResendLink>
          </>
        ) : (
          <>
            <Hint data-testid="reg-message-error">
              User with email "<span>{email}</span>". already registered
              <br/>from your mail
            </Hint>
            <Button data-testid="reg-login" onClick={() => navigate(Paths.LOGIN)} style={{ width: '180px' }}>Login</Button>
          </>
        )}
      </Container>
    </Modal>
  );
}

export default SuccessModal;

