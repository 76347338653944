import { Container } from './styled.js';

const WindowsBackground = () => {
  return (
    <Container>
      <svg style={{ zIndex: 1 }} width="350" height="40" viewBox="0 0 350 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="350" height="40" fill="#191A1E"/>
        <g clipPath="url(#clip0_5216_303560)">
          <path d="M195.75 17.25H202.625V22.75H195.75V17.25Z" fill="white"/>
          <path d="M195.75 14.5C195.021 14.5 194.321 14.7897 193.805 15.3055C193.29 15.8212 193 16.5207 193 17.25V22.75C193 23.4793 193.29 24.1788 193.805 24.6945C194.321 25.2103 195.021 25.5 195.75 25.5H209.5C210.229 25.5 210.929 25.2103 211.445 24.6945C211.96 24.1788 212.25 23.4793 212.25 22.75V17.25C212.25 16.5207 211.96 15.8212 211.445 15.3055C210.929 14.7897 210.229 14.5 209.5 14.5H195.75ZM209.5 15.875C209.865 15.875 210.214 16.0199 210.472 16.2777C210.73 16.5356 210.875 16.8853 210.875 17.25V22.75C210.875 23.1147 210.73 23.4644 210.472 23.7223C210.214 23.9801 209.865 24.125 209.5 24.125H195.75C195.385 24.125 195.036 23.9801 194.778 23.7223C194.52 23.4644 194.375 23.1147 194.375 22.75V17.25C194.375 16.8853 194.52 16.5356 194.778 16.2777C195.036 16.0199 195.385 15.875 195.75 15.875H209.5ZM215 20C215 20.547 214.783 21.0716 214.396 21.4584C214.009 21.8452 213.485 22.0625 212.938 22.0625V17.9375C213.485 17.9375 214.009 18.1548 214.396 18.5416C214.783 18.9284 215 19.453 215 20Z" fill="white"/>
        </g>
        <path d="M339.25 10.375C339.615 10.375 339.964 10.5199 340.222 10.7777C340.48 11.0356 340.625 11.3853 340.625 11.75V22.75C340.625 23.1147 340.48 23.4644 340.222 23.7223C339.964 23.9801 339.615 24.125 339.25 24.125H335.812C335.386 24.125 334.965 24.2244 334.583 24.4153C334.201 24.6062 333.869 24.8835 333.612 25.225L331 28.7079L328.387 25.225C328.131 24.8835 327.799 24.6062 327.417 24.4153C327.035 24.2244 326.614 24.125 326.188 24.125H322.75C322.385 24.125 322.036 23.9801 321.778 23.7223C321.52 23.4644 321.375 23.1147 321.375 22.75V11.75C321.375 11.3853 321.52 11.0356 321.778 10.7777C322.036 10.5199 322.385 10.375 322.75 10.375H339.25ZM322.75 9C322.021 9 321.321 9.28973 320.805 9.80546C320.29 10.3212 320 11.0207 320 11.75V22.75C320 23.4793 320.29 24.1788 320.805 24.6945C321.321 25.2103 322.021 25.5 322.75 25.5H326.188C326.401 25.5 326.611 25.5497 326.802 25.6452C326.993 25.7406 327.159 25.8792 327.288 26.05L329.9 29.5329C330.028 29.7036 330.194 29.8422 330.385 29.9377C330.576 30.0332 330.787 30.0829 331 30.0829C331.213 30.0829 331.424 30.0332 331.615 29.9377C331.806 29.8422 331.972 29.7036 332.1 29.5329L334.712 26.05C334.841 25.8792 335.007 25.7406 335.198 25.6452C335.389 25.5497 335.599 25.5 335.812 25.5H339.25C339.979 25.5 340.679 25.2103 341.195 24.6945C341.71 24.1788 342 23.4793 342 22.75V11.75C342 11.0207 341.71 10.3212 341.195 9.80546C340.679 9.28973 339.979 9 339.25 9H322.75Z" fill="white"/>
        <path d="M175.532 22.3311C174.325 21.2805 172.748 20.6445 171.021 20.6445C169.293 20.6445 167.716 21.2805 166.507 22.3332C166.473 22.3633 166.453 22.4052 166.45 22.4501C166.447 22.4949 166.461 22.5393 166.49 22.5738L167.263 23.4955C167.325 23.5686 167.435 23.5771 167.508 23.5148C168.445 22.6898 169.674 22.1914 171.021 22.1914C172.368 22.1914 173.597 22.6898 174.533 23.5127C174.606 23.575 174.716 23.5664 174.778 23.4934L175.552 22.5717C175.612 22.5008 175.603 22.3934 175.532 22.3311ZM178.055 19.3211C176.149 17.7334 173.698 16.7773 171.021 16.7773C168.344 16.7773 165.892 17.7334 163.985 19.3211C163.95 19.3505 163.928 19.3926 163.924 19.4381C163.92 19.4835 163.934 19.5288 163.963 19.5639L164.736 20.4855C164.797 20.5586 164.906 20.5672 164.977 20.507C166.616 19.1449 168.724 18.3242 171.021 18.3242C173.317 18.3242 175.425 19.1449 177.062 20.507C177.135 20.5672 177.243 20.5586 177.303 20.4855L178.076 19.5639C178.136 19.4908 178.128 19.3812 178.055 19.3211V19.3211ZM180.562 16.3348C177.954 14.1949 174.615 12.9102 170.978 12.9102C167.364 12.9102 164.047 14.1777 161.445 16.2918C161.427 16.3062 161.412 16.324 161.401 16.3441C161.391 16.3643 161.384 16.3865 161.382 16.4093C161.379 16.4321 161.382 16.4551 161.389 16.477C161.396 16.4989 161.407 16.5192 161.421 16.5367L162.195 17.4584C162.255 17.5293 162.362 17.54 162.433 17.482C164.769 15.5893 167.74 14.457 170.978 14.457C174.239 14.457 177.23 15.6064 179.571 17.5229C179.644 17.583 179.752 17.5723 179.812 17.4992L180.586 16.5775C180.648 16.5045 180.637 16.3949 180.562 16.3348ZM169.624 25.7148C169.624 26.0795 169.769 26.4293 170.027 26.6871C170.285 26.945 170.635 27.0898 170.999 27.0898C171.364 27.0898 171.714 26.945 171.971 26.6871C172.229 26.4293 172.374 26.0795 172.374 25.7148C172.374 25.3502 172.229 25.0004 171.971 24.7426C171.714 24.4847 171.364 24.3398 170.999 24.3398C170.635 24.3398 170.285 24.4847 170.027 24.7426C169.769 25.0004 169.624 25.3502 169.624 25.7148Z" fill="white"/>
        <path d="M241.862 28.2638C242.949 27.1799 243.811 25.8918 244.399 24.4736C244.986 23.0555 245.288 21.5351 245.286 20.0001C245.288 18.465 244.986 16.9447 244.399 15.5265C243.811 14.1084 242.949 12.8203 241.862 11.7363L240.889 12.7085C241.848 13.6649 242.608 14.8014 243.127 16.0528C243.645 17.3041 243.911 18.6456 243.91 20.0001C243.91 22.8477 242.755 25.4258 240.889 27.2917L241.862 28.2638V28.2638Z" fill="white"/>
        <path d="M239.915 26.3197C240.747 25.4907 241.406 24.5057 241.855 23.4212C242.304 22.3366 242.535 21.174 242.533 20.0002C242.535 18.8263 242.304 17.6637 241.855 16.5792C241.406 15.4947 240.747 14.5096 239.915 13.6807L238.943 14.6528C239.647 15.3542 240.204 16.1878 240.585 17.1054C240.965 18.0231 241.16 19.0069 241.158 20.0002C241.16 20.9936 240.965 21.9776 240.585 22.8955C240.206 23.8134 239.648 24.6472 238.945 25.3489L239.915 26.3197Z" fill="white"/>
        <path d="M239.784 20C239.785 20.8127 239.626 21.6176 239.315 22.3685C239.004 23.1193 238.548 23.8013 237.972 24.3752L237 23.4031C237.448 22.9567 237.802 22.4262 238.044 21.8422C238.286 21.2582 238.41 20.6321 238.409 20C238.409 18.6717 237.87 17.4686 237 16.5969L237.972 15.6247C238.548 16.1987 239.004 16.8807 239.315 17.6315C239.626 18.3824 239.785 19.1873 239.784 20V20ZM235.625 14.5C235.625 14.3706 235.588 14.2439 235.519 14.1345C235.45 14.025 235.352 13.9373 235.235 13.8813C235.119 13.8252 234.988 13.8033 234.86 13.8179C234.731 13.8325 234.61 13.883 234.509 13.9637L231.259 16.5625H228.062C227.88 16.5625 227.705 16.6349 227.576 16.7639C227.447 16.8928 227.375 17.0677 227.375 17.25V22.75C227.375 22.9323 227.447 23.1072 227.576 23.2361C227.705 23.3651 227.88 23.4375 228.062 23.4375H231.259L234.509 26.0362C234.61 26.117 234.731 26.1675 234.86 26.1821C234.988 26.1967 235.119 26.1748 235.235 26.1187C235.352 26.0627 235.45 25.975 235.519 25.8655C235.588 25.7561 235.625 25.6294 235.625 25.5V14.5ZM231.929 17.7862L234.25 15.93V24.07L231.929 22.2137C231.807 22.1161 231.656 22.0628 231.5 22.0625H228.75V17.9375H231.5C231.656 17.9372 231.807 17.8839 231.929 17.7862V17.7862Z" fill="white"/>
        <path d="M265.917 18H264.917V11.3774L262.914 12.1133V11.2109L265.761 10.1421H265.917V18Z" fill="white"/>
        <path d="M271.873 18H270.874V11.3774L268.871 12.1133V11.2109L271.718 10.1421H271.873V18Z" fill="white"/>
        <path d="M274.634 17.479C274.634 17.3071 274.684 17.1639 274.785 17.0493C274.888 16.9347 275.042 16.8774 275.247 16.8774C275.451 16.8774 275.605 16.9347 275.708 17.0493C275.816 17.1639 275.87 17.3071 275.87 17.479C275.87 17.6437 275.816 17.7816 275.708 17.8926C275.605 18.0036 275.451 18.0591 275.247 18.0591C275.042 18.0591 274.888 18.0036 274.785 17.8926C274.684 17.7816 274.634 17.6437 274.634 17.479ZM274.64 12.7363C274.64 12.5645 274.69 12.4212 274.79 12.3066C274.894 12.1921 275.048 12.1348 275.252 12.1348C275.456 12.1348 275.61 12.1921 275.714 12.3066C275.821 12.4212 275.875 12.5645 275.875 12.7363C275.875 12.901 275.821 13.0389 275.714 13.1499C275.61 13.2609 275.456 13.3164 275.252 13.3164C275.048 13.3164 274.894 13.2609 274.79 13.1499C274.69 13.0389 274.64 12.901 274.64 12.7363Z" fill="white"/>
        <path d="M280.274 18H279.275V11.3774L277.272 12.1133V11.2109L280.118 10.1421H280.274V18Z" fill="white"/>
        <path d="M288.014 10.7383L284.775 18H283.733L286.961 10.9961H282.729V10.1797H288.014V10.7383Z" fill="white"/>
        <path d="M296.007 15.959H292.73L291.994 18H290.931L293.917 10.1797H294.82L297.811 18H296.753L296.007 15.959ZM293.042 15.1104H295.701L294.368 11.4526L293.042 15.1104Z" fill="white"/>
        <path d="M299.976 10.1797L302.533 16.5605L305.089 10.1797H306.427V18H305.396V14.9546L305.492 11.6675L302.925 18H302.135L299.573 11.6836L299.675 14.9546V18H298.644V10.1797H299.976Z" fill="white"/>
        <path d="M257.917 31H256.917V24.3774L254.914 25.1133V24.2109L257.761 23.1421H257.917V31Z" fill="white"/>
        <path d="M263.873 31H262.874V24.3774L260.871 25.1133V24.2109L263.718 23.1421H263.873V31Z" fill="white"/>
        <path d="M266.865 31.6714H266.011L269.277 23.1797H270.125L266.865 31.6714Z" fill="white"/>
        <path d="M274.143 31H273.144V24.3774L271.141 25.1133V24.2109L273.987 23.1421H274.143V31Z" fill="white"/>
        <path d="M280.1 31H279.101V24.3774L277.098 25.1133V24.2109L279.944 23.1421H280.1V31Z" fill="white"/>
        <path d="M283.092 31.6714H282.238L285.504 23.1797H286.352L283.092 31.6714Z" fill="white"/>
        <path d="M292.228 31H287.104V30.2856L289.811 27.2778C290.212 26.8231 290.488 26.4543 290.638 26.1714C290.792 25.8849 290.869 25.5895 290.869 25.2852C290.869 24.877 290.746 24.5422 290.499 24.2808C290.252 24.0194 289.922 23.8887 289.511 23.8887C289.016 23.8887 288.631 24.0301 288.356 24.313C288.084 24.5923 287.948 24.9826 287.948 25.4839H286.954C286.954 24.7642 287.185 24.1823 287.647 23.7383C288.112 23.2943 288.734 23.0723 289.511 23.0723C290.237 23.0723 290.812 23.2638 291.235 23.647C291.657 24.0265 291.868 24.5332 291.868 25.167C291.868 25.9368 291.378 26.8535 290.397 27.917L288.302 30.189H292.228V31Z" fill="white"/>
        <path d="M297.965 27.6646C297.965 28.8283 297.766 29.693 297.369 30.2588C296.971 30.8245 296.35 31.1074 295.505 31.1074C294.671 31.1074 294.053 30.8317 293.652 30.2803C293.251 29.7253 293.043 28.8981 293.029 27.7988V26.4722C293.029 25.3228 293.228 24.4688 293.625 23.9102C294.022 23.3516 294.646 23.0723 295.494 23.0723C296.336 23.0723 296.955 23.3426 297.353 23.8833C297.75 24.4204 297.954 25.2511 297.965 26.3755V27.6646ZM296.971 26.3057C296.971 25.4642 296.853 24.8519 296.617 24.4688C296.38 24.082 296.006 23.8887 295.494 23.8887C294.986 23.8887 294.615 24.0802 294.382 24.4634C294.15 24.8465 294.03 25.4355 294.022 26.2305V27.8203C294.022 28.6654 294.144 29.2902 294.388 29.6948C294.635 30.0959 295.007 30.2964 295.505 30.2964C295.995 30.2964 296.359 30.1066 296.595 29.7271C296.835 29.3475 296.96 28.7495 296.971 27.9331V26.3057Z" fill="white"/>
        <path d="M302.283 31H301.284V24.3774L299.281 25.1133V24.2109L302.128 23.1421H302.283V31Z" fill="white"/>
        <path d="M310.023 23.7383L306.785 31H305.743L308.971 23.9961H304.738V23.1797H310.023V23.7383Z" fill="white"/>
        <defs>
          <clipPath id="clip0_5216_303560">
            <rect width="22" height="22" fill="white" transform="translate(193 9)"/>
          </clipPath>
        </defs>
      </svg>
      <div style={{ width: '100%', height: '40px', background: '#191A1E', position: 'absolute', bottom: 0 }} />

    </Container>
  );
}

export default WindowsBackground;

