const MobilePushStatuses = {
  SENT: 'sent',
  OPENED: 'open',
  DISMISS: 'dismiss',
  CLICK: 'click',
  FAILED: 'failed',
};

const MobilePushStatusLabels = {
  [MobilePushStatuses.SENT]: 'statuses.sent',
  [MobilePushStatuses.OPENED]: 'statuses.opened',
  [MobilePushStatuses.DISMISS]: 'statuses.dismiss',
  [MobilePushStatuses.CLICK]: 'statuses.click',
  [MobilePushStatuses.FAILED]: 'statuses.failed',
};

const MobilePushStatusOptions = [
  ...Object.entries(MobilePushStatusLabels).map(([value, label]) => ({ value, label }))
];

export { MobilePushStatuses, MobilePushStatusOptions, MobilePushStatusLabels };
