import { useTranslation } from '@hooks';

import { NodeActionLayout, IconButtonWithStyles } from './styled';
export const NodeOverlayActions = ({
  canDelete,
  canCopy,
  onCopy,
  onDelete,
}) => {
  const { p } = useTranslation('workflow_page');

  return (
    <NodeActionLayout>
      {canCopy && (
        <IconButtonWithStyles
          data-testid={'workflow-node-actions-copy-btn'}
          icon='Duplicate'
          tooltip={p('copy')}
          onClick={onCopy}
          disabled={false}
          tooltipProps={{ placement: 'bottom' }}
          iconSize={16}
        />
      )}
      {canDelete && (
        <IconButtonWithStyles
          data-testid={'workflow-node-actions-delete-btn'}
          icon='Delete-icon'
          tooltip={p('delete')}
          tooltipProps={{ placement: 'bottom' }}
          appearance='danger'
          disabled={!canDelete}
          onClick={onDelete}
          iconSize={16}
        />
      )}
    </NodeActionLayout>
  );
};
