import styled from 'styled-components';

import { switchStyle, styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--stroke-50, #E5E8ED);
  background: var(--Colors-Neutral-100, #F8FAFE);
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.30);
  display: flex;
  flex-direction: column;
  width: 225px;
  min-height: 266px;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px 20px 20px;
  ${switchStyle('$type',{
    'rfm': `
      min-height: 266px;
      padding: 14px 0 0 0;
      `,
  })}
`;

export const RemoveButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;

  ${styledProp('$disabled', `
    opacity: 40%;
    cursor: not-allowed;
  `)}
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
`;

export const Count = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ParentRFmName = styled.div`
  background: var(--Colors-Neutral-200, #ECF0F6);
  color: var(--text-secondary-body, #7C94B1);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  width: 100%;
  margin-top: 13px;
  padding: 19px 0;
  /* Body 3 */
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
`
