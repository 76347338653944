import { useContext, useRef, useState, } from 'react';
import { useSelector } from "react-redux";

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useModalState, useTranslation } from '@hooks';

import { ViewModes } from '@constants';

import { findAttributes, notify, replaceKeysWithValues } from "@utils";
import { clickhouseFields2Customer } from '@utils/fields';

import {
  EmailSampleData,
  ToggleButton,
  ViewModeSwitch,
  Divider, TemplatesGeneralAttributesDrawer,
} from '@components';
import { OtpButtons } from "@components/lib/EmailTemplateEditor/components/OtpButtons";
import { FallbackValuesModal } from "@components/modals/FallbackValuesModal";

import { Container, EditorContainer, Editor, PreviewTitle } from './styled.js';

import { SelectLanguage, DefaultLangModal, LanguageTabs, LanguageTab, MultiLanguageContext } from '../../../MultiLanguage';
import Stripo from '../../../Stripo/Stripo.js';

const HTMLEditor = ({ value, onChangeFallback, id, loaded, errors, clearError, isOTP, otpError, }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector)?.data || [];
  const fields = clickhouseFields2Customer(clickhouseFields).map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const [fallbackBody, setFallbackBody] = useState('');
  const [fallbackCss, setFallbackCss] = useState('');
  const fallbackModal = useModalState();
  const exampleFields = useModalState();
  const discountFields = useModalState();
  const container = useRef(null);
  const [viewMode, setViewMode] = useState(ViewModes.DESKTOP);

  const { langModal, multiLangTabsController } = useContext(MultiLanguageContext);
  const { p, t } = useTranslation('templates');

  const handleToggleFieldsExample = () => {
    discountFields.close();
    exampleFields.toggle();
  };

  const handleDiscountToggle = () => {
    exampleFields.close();
    discountFields.toggle();
  };

  const handleSubmitFallbacks = (values) => {
    const replaced = replaceKeysWithValues(values, ['body'], { body: fallbackBody });
    onChangeFallback({
      ...value.meta,
      design: { css: fallbackCss, html: replaced.body } 
    }, 'meta');
  }

  const handleClickFallback = async () => {
    if (!window.StripoApi) {
      notify('error', t('labels.error'), p('you_have_no_selected_attributes'));
      return;
    }

    const design = await new Promise(res => window.StripoApi.getTemplate((html, css) => res({ css, html })));

    if (design.html.indexOf('{{customer.') === -1) {
      notify('error', t('labels.error'), p('you_have_no_selected_attributes'));
      return;
    }

    setFallbackBody(design.html);
    setFallbackCss(design.css);
    fallbackModal.open();
  }

  const handleCloseFallback = () => {
    setFallbackCss('');
    setFallbackBody('');
    fallbackModal.close();
  }

  const handleChangeSelectTab = (langName) => {
    const tabItem = multiLangTabsController.languages.find((lang) => lang.value === langName);
    multiLangTabsController.onSelectLangTab(tabItem.id);
  }

  const langTabs = multiLangTabsController.languages.map((lang) => {
    const isActive = multiLangTabsController.selectedLangTab === lang.id
    const hasErrors = errors && Object.keys(errors).some((errorKey) => errors[errorKey] && errorKey.split('-')[1] === lang.id);
    return {
      ...lang,
      active: isActive,
      children: (
        <LanguageTab 
          onlyOne={multiLangTabsController?.languages?.length === 1}
          onClick={handleChangeSelectTab}
          isActive={isActive} 
          isDefault={lang.is_default} 
          value={lang.value} 
          icon={lang.icon} 
          id={lang.id}
          hasError={hasErrors}
          onDeleteLang={() => multiLangTabsController.onRemoveLang(lang.id)}
          onSetDefaultLang={() => multiLangTabsController.onSelectDefaultLang(lang.id)}
        />),
    };
  }).filter(({ language_code }) => language_code !== null);

  return (
    <Container>
      <PreviewTitle>{p('builder')}</PreviewTitle>
      <div style={{ position: 'absolute', top: '-30px', right: 0, display: 'flex', alignItems: 'center' }} >
        {/*{value?.body?.indexOf('{{customer.') !== -1 && (*/}
        {isOTP && <OtpButtons clearError={clearError} errors={errors} />}
        {multiLangTabsController.enableLanguage && (
          <SelectLanguage 
            selectedLanguages={multiLangTabsController.languages} 
            onAddLang={multiLangTabsController.onAddLang} 
            handleOpenLang={multiLangTabsController.onOpenSelectLang}
            
          />
        )}
        <ToggleButton
          testId="html-fallback-toggle"
          opened={fallbackModal.opened}
          style={{ marginRight: 4 }}
          fallback
          onToggle={handleClickFallback}
        >
          {p('fallback_values')}
        </ToggleButton>
        {/*)}*/}
        <ToggleButton
          id="codeEditor"
          testId="email-code-toggle"
          appearance="secondary"
          style={{
            width: 'auto',
            padding: 'auto 16px !important',
            height: '26px',
            fontSize: '12px',
            marginRight: 10,
          }}
        >
          {p('code')}
        </ToggleButton>
        <ToggleButton
          testId="email-attributes-toggle"
          opened={exampleFields.opened}
          style={{ marginRight: 12 }}
          onToggle={handleToggleFieldsExample}
        >
          {p('customer_attributes')}
        </ToggleButton>
        <ToggleButton
          error={errors?.['unsubscribe_url'] || errors?.['subscribe_url']}
          testId="email-discount-toggle"
          style={{ marginRight: errors?.['unsubscribe_url'] || errors?.['subscribe_url'] ? 26 : 10 }}
          opened={discountFields.opened}
          onToggle={handleDiscountToggle}
        >
          {p('general_attributes')}
        </ToggleButton>
        <Divider vertical style={{ marginRight: 10, height: 16, background: '#909399' }} />
        <ViewModeSwitch value={viewMode} onChange={setViewMode} containerStyle={{ position: 'static' }} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', overflowX: langTabs?.length > 8 ? 'auto' : 'visible' }}>
        {langTabs?.length > 0 && <LanguageTabs tabs={langTabs} onChange={handleChangeSelectTab} errors={errors}/>}
      </div>
      <EditorContainer>
        <Editor ref={container}>
          <EmailSampleData
            onClose={exampleFields.close}
            opened={exampleFields.opened}
            getContainer={container.current || { addEventListener: () => {} }}
            onVisibilityChanged={exampleFields.setOpened}
          />
          <TemplatesGeneralAttributesDrawer
            onClose={discountFields.close}
            opened={discountFields.opened}
            small
            onVisibilityChanged={discountFields.setOpened}
          />
          <Stripo
            id={id}
            loaded={loaded}
            viewMode={viewMode}
            languageCode={value.language_code}
            html={value.meta?.design?.html}
            cssDesign={value?.meta?.design?.css}
            pluginId="35e7df46df0f480aa845d389534e5682"
            secretKey="7b8349fcb32148b8835b5ded6074571d"
          />
        </Editor>

        <FallbackValuesModal
          opened={fallbackModal.opened}
          onClose={handleCloseFallback}
          testId="fallback-modal"
          onSubmit={handleSubmitFallbacks}
          fallbacks={findAttributes([fallbackBody], fields)}
        />
        <DefaultLangModal 
          opened={langModal.opened}
          title={langModal.title}
          onClose={langModal.onDecline}
          onSave={langModal.onAccept}
          options={langModal.languageOptions}

        />
      </EditorContainer>
    </Container>
  );
};

export default HTMLEditor;

