import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';

import { verifyEmail } from '@store/actions/creators';

import { StringParam, useQueryParam } from 'use-query-params';

import { Paths } from '@constants';

const VerifyEmailPage = () => {
  const dispatch = useDispatch();
  const [verified, setVerfied] = useState(false);
  const [error, setError] = useState(false);
  const [hash] = useQueryParam('hash', StringParam);
  const [email] = useQueryParam('email', StringParam);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (sent) {
      return;
    }

    setSent(true);
    dispatch(verifyEmail({
      hash,
      email,
      onSuccess: () => {
        setVerfied(true);
      },
      onError: () => {
        setError(true);
      }
    }));
  }, [hash, sent]);

  if (verified) {
    return <Navigate to={Paths.EMAIL_VERIFICATION_SUCCESS_PAGE} replace />;
  }

  if (error) {
    return <Navigate to={Paths.LOGIN} replace />;
  }

  return 'You being redirected...';
};

export default VerifyEmailPage;
