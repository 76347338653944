import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding: 8px;
  background-color: #FFFAED;
  border: 1px solid #FFDC7B;
  border-radius: 7px;
  margin-bottom: ${({ $withMargin }) => $withMargin ? '10px' : '0px'};

  p {
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
`;

export const AlertText = styled.p`
  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #1A232E;
  a {
    color: #3253FF;
  }
`