import { config, useTransition } from '@react-spring/web';
import { FilterTable, ICONS_NAMES, Input, Pagination } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { formatNumberString } from '@utils';

import { TableEmpty } from '@components';

import { Container, PaginationContainer, InfoTitleContainer, ActionsRow, Divider, SelectionHeaderContainer, TotalCount } from './styled';

const SortConfig = {
  undetermined: 'none',
  asc: 'asc',
  desc: 'desc',
};

const SortConfigReverse = {
  none: 'undetermined',
  asc: 'asc',
  desc: 'desc',
};

const TableLayout = ({
  columns,
  loading,
  fullPage,
  selection,
  onSelectionChange,
  name,
  data,
  table,
  total,
  withTabs,
  localizer,
  hideSearch,
  disablePaddings,
  selectionKeyExtractor,
  selectionActions,
  renderFilter,
  columnSettings,
  heightType = null
}) => {
  const { t } = useTranslation();
  const searchActive = table?.search?.length > 2;

  const handleSearchChange = ({ target: { value } }) => {
    table.changeSearch(value);
  };

  const handleSortChange = (sortData) => {
    const sortType = SortConfigReverse[sortData.sort];
    table.ordering?.set(sortData.name, sortType);
  };

  const localize = (s, ...translationArgs) => {
    return localizer(s, ...translationArgs);
  };

  const mappedColumns = columns.map(c => ({
    ...c,
    header: {
      ...c.header,
      sort: c.header.sort ? SortConfig[table?.ordering?.get?.(c.name)] : void 0,
    }
  }));

  const selectionTransition = useTransition(selection?.length > 0, {
    from: { opacity: 0, y: -14 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -14 },
    config: config.default,
  });

  const searchTransition = useTransition(searchActive, {
    from: { opacity: 0, y: -14 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -14 },
    config: config.default,
  });

  const titleTransition = useTransition(!searchActive && !selection?.length, {
    from: { opacity: 0, y: 8 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 8 },
  });

  return (
    <Container $fullPage={fullPage} $disablePaddings={disablePaddings} $withTabs={withTabs} $heightType={heightType}>
      <FilterTable
        tableColumnSettings={columnSettings && {
          label: t('labels.displaying'),
          ...(typeof columnSettings === 'object' ? columnSettings : {})
        }}
        renderInfo={({ selected }) => {
          return (
            <InfoTitleContainer>
              {selectionTransition((style, active) => active && (
                <SelectionHeaderContainer style={style}>
                  <TotalCount $fxd>
                    {localize(selected.length === 1 ? `selected_item` : `selected_items`, { count: formatNumberString(selected.length) })}
                  </TotalCount>
                  <ActionsRow>
                    {selectionActions}
                  </ActionsRow>
                  <Divider/>
                  <ActionsRow>

                  </ActionsRow>
                </SelectionHeaderContainer>
              ))}
              {titleTransition((style, active) => active && (
                <TotalCount style={style}>
                  {localize(total === 1 ? `total_item` : `total_items`, { count: formatNumberString(total) })}
                </TotalCount>
              ))}
              {searchTransition((style, active) => active && (
                <SelectionHeaderContainer style={style}>
                  <TotalCount>
                    {localize(total === 1 ? `search_item` : `search_items`, { count: formatNumberString(total) })}
                  </TotalCount>
                </SelectionHeaderContainer>
              ))}
            </InfoTitleContainer>
          );
        }}
        filter={{
          ...(renderFilter ? {
            reset: {
              onClick: table.discardFilters,
              disableSpaceReserve: true,
              children: t('actions.discard'),
            },
            submit: {
              onClick: table.applyFilters,
              disableSpaceReserve: true,
              children: t('actions.search'),
            },
            children: renderFilter,
            filterButton: renderFilter && t('actions.filter'),
          } : {}),
          search: (!hideSearch && !!table) && (
            <Input
              data-testid={'filter-name-input'}
              value={table.search}
              className="search-field"
              onChange={handleSearchChange}
              prefix={ICONS_NAMES.Search}
              smallControl
              placeholder={t('actions.search')}
            />
          ),
        }}
        style={{ width: '100%' }}
        table={{
          name,
          data,
          scrollable: true,
          stickyHeader: true,
          columns: mappedColumns,
          onSortChange: handleSortChange,
          stickyOuterFooter: true,
          loading: loading,
          selectedItemKey: selectionKeyExtractor,
          canSelectAll: !!selectionKeyExtractor,
          onSelectionChange: !!selectionKeyExtractor && onSelectionChange,
          empty: <TableEmpty search={!!table?.search} />,
          outerFooter: table && (
            <PaginationContainer>
              <Pagination
                formatNumber={formatNumberString}
                onPageChange={table?.changePage}
                onPerPageChange={table?.changePerPage}
                rowsPerPageLabel='Rows per page:'
                elementsOfLabel='of'
                sizeOptions={[10, 20, 50, 100]}
                pagination={{
                  page: table.pagination.page,
                  size: table.pagination.perPage,
                  totalElements: total,
                  totalPages: Math.ceil(total / table.pagination.perPage),
                }}
                onPaginate={(page) => table.changePage(page)}
                onSizeChange={(data) => table.changePerPage(data.size)}
              />
            </PaginationContainer>
          )
        }}
      />
    </Container>
  );
};

export default TableLayout;
