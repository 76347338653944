import styled, { css } from 'styled-components';

import { extract, styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  padding: 14px 21px 16px 21px;
  background: #F9FBFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: visible;
  z-index: 2;
  margin-top: 5px;
  margin-bottom: 16px;
  position: relative;
  transition: 330ms ease all;

  ${styledProp('$opened', css`
    max-height: 400px;
    background: #F9FBFF;
  `, css`
    max-height: 32px;
    background: #F9FBFF;
    cursor: pointer;
    overflow: hidden;
  `)}
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 100%;
  color: #303133;
  position: absolute;
  transition: 200ms ease all;
  left: 20px;
  top: 9px;
  
  ${styledProp('$opened', css`
    opacity: 0;
  `, css`
    opacity: 1;
  `)}
`

export const NoIntegrations = styled.div`
  padding: 20px 14px;
  font-size: 13px;
  font-family: Manrope-Medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OpenButton = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  transition: all 330ms ease;
  flex: 0;
  height: 16px;
  
  ${styledProp('$opened', css`
    transform: rotateZ(-180deg);
  `, css`
    transform: rotateZ(0deg);
  `)}
`;

export const Content = styled.div`
  display: flex;
  transition: 330ms ease all;

  ${styledProp('$opened', css`
    opacity: 1;
  `, css`
    opacity: 0;
  `)}
`;

export const Option = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Image = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 2px;
    overflow: hidden;
    
    background: center / contain url(${extract('$url')}) no-repeat;
`;

export const SelectContainer = styled.div`
  label {
    min-width: 200px;
  }
`