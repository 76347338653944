import { Input as ANTDInput } from 'antd';
import styled from 'styled-components';

import { styledProp } from '@utils';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 10px 12px 16px;
  align-items: center;
  border-bottom: 1px solid var(--border-divider, #D3DBE7);
  background: var(--cards-bg, #F8FAFE);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 500px;
`;

export const StyledInput = styled(ANTDInput)`
  align-self: center;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 7px;
  height: 28px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding-right: 24px;
  margin-top: 13px;
  box-shadow: none !important;
  width: 450px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  
  ${styledProp('hasError', `
    margin-bottom: 0px;
    color: #C8402F;
    border-color: #C8402F !important;
  `)}
  
  ${styledProp('withSuffix', `
    padding-right: 5px;
  `)}
  
  ${styledProp('withPrefix', `
    padding-left: 6px;
  `)}

  ${styledProp('highlight', `
    border-color: #f3cc50 !important;
    color: #f3cc50 !important;
  `)}

  &:focus {
    box-shadow: none !important;
    border-color: #DCDFE6 !important;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  flex: 0;
`;
