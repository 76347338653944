import { useState } from 'react';
import { useLocation } from 'react-router';

import { useTranslation } from '@hooks';

import settingsLogo from '@res/images/topbar-settings.svg'

import { Popover } from '@components/layout/Topbar/components/Popover';

import {
  Container,
  Item,
  Img
} from './styled';

const TopbarSettings = ({ opened, pages, onToggle, testId }) => {
  const location = useLocation();
  const { p } = useTranslation('sidebar');
  const active = location.pathname.indexOf('settings') !== -1 || location.pathname.indexOf('integrations') !== -1 || location.pathname.indexOf('workspaces') !== -1 || location.pathname.indexOf('account') !== -1;
  const [hovered, setHovered] = useState(false);

  const handleToggle = () => {
    onToggle?.();
  }

  return (
    <Container>
      <Popover
        testId={'topbar-settings-popover'}
        larger
        isSettings={true}
        setHovered={setHovered}
        overlayClassName="stt-1"
        title={p('settings')}
        pages={pages}
      >
        <Item
          data-testid={testId}
          onClick={handleToggle}
          style={{ borderTop: 'none' }}
          $active={active}
          $opened={opened}
          $hovered={hovered}
        >
          <Img src={settingsLogo}/>
        </Item>
      </Popover>
    </Container>
  );
};

export default TopbarSettings;
