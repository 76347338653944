import { useTranslation } from '@hooks';

import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const NewContactNodeSettingsInfo = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <InfoRow>
        <InfoTitle style={{ fontSize: '14px' }}>
          {t('workflow_page.customer_addition_info')}
        </InfoTitle>
      </InfoRow>
    </Container>
  );
}

export default NewContactNodeSettingsInfo;

