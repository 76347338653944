import { useEffect, useState } from 'react';

import { Dropdown, Spin, Tooltip } from 'antd';
import uniqBy from 'lodash.uniqby';

import { useTranslation } from '@hooks';
import useAutocomplete from '@hooks/useAutocomplete';

import { OPERATOR_BETWEEN, OPERATOR_NOT_BETWEEN } from '@constants';

import { zeroIfNaN, resolveDropdownFieldType, identity, sequence } from '@utils';

import {
  Container,
  StyledAutoComplete,
  Error,
  Title,
  PlusContainer,
  IconContainer,
  Overlay,
  DropdownItem, Loader,
  ArrowContainer,
} from './styled';

import { Icon } from '../../ui';
import { InfoIconContainer } from '../Input/styled';
import { Suffix } from '../Select/styled';

const allowedOperatorsList = [
  'equals', 'does_not_equal', 'greater_than', 'less_than', OPERATOR_BETWEEN, OPERATOR_NOT_BETWEEN,
]

const shouldRenderPlus = (operator) => allowedOperatorsList.indexOf(operator) !== -1;

const resolveIcon = (type) => {
  switch (type) {
    case 'variable':
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.75 2.25C7.30626 2.25 7.85003 2.41495 8.31254 2.72399C8.77505 3.03303 9.13554 3.47229 9.34841 3.9862C9.56128 4.50012 9.61698 5.06562 9.50846 5.61119C9.39994 6.15676 9.13207 6.6579 8.73874 7.05124C8.3454 7.44457 7.84426 7.71244 7.29869 7.82096C6.75312 7.92948 6.18762 7.87378 5.6737 7.66091C5.15979 7.44804 4.72053 7.08755 4.41149 6.62504C4.10245 6.16253 3.9375 5.61876 3.9375 5.0625C3.9375 4.31658 4.23382 3.60121 4.76126 3.07376C5.28871 2.54632 6.00408 2.25 6.75 2.25ZM6.75 1.125C5.97124 1.125 5.20996 1.35593 4.56244 1.78859C3.91492 2.22125 3.41024 2.8362 3.11222 3.55568C2.8142 4.27517 2.73623 5.06687 2.88816 5.83067C3.04009 6.59447 3.4151 7.29606 3.96577 7.84673C4.51644 8.3974 5.21803 8.77241 5.98183 8.92434C6.74563 9.07627 7.53733 8.9983 8.25682 8.70028C8.9763 8.40226 9.59125 7.89758 10.0239 7.25006C10.4566 6.60254 10.6875 5.84126 10.6875 5.0625C10.6875 4.01821 10.2727 3.01669 9.53423 2.27827C8.79581 1.53984 7.79429 1.125 6.75 1.125Z" fill="#5F728A"/>
          <path d="M12.375 16.875H11.25V14.0625C11.25 13.3166 10.9537 12.6012 10.4262 12.0738C9.89879 11.5463 9.18342 11.25 8.4375 11.25H5.0625C4.31658 11.25 3.60121 11.5463 3.07376 12.0738C2.54632 12.6012 2.25 13.3166 2.25 14.0625V16.875H1.125V14.0625C1.125 13.0182 1.53984 12.0167 2.27827 11.2783C3.01669 10.5398 4.01821 10.125 5.0625 10.125H8.4375C9.48179 10.125 10.4833 10.5398 11.2217 11.2783C11.9602 12.0167 12.375 13.0182 12.375 14.0625V16.875Z" fill="#5F728A"/>
          <path d="M18 2.25H12.375V3.375H18V2.25Z" fill="#5F728A"/>
          <path d="M18 5.0625H12.375V6.1875H18V5.0625Z" fill="#5F728A"/>
          <path d="M16.3125 7.875H12.375V9H16.3125V7.875Z" fill="#5F728A"/>
        </svg>
      );
    case 'query-variable':
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.4375 9.00002L13.5 12.9375L12.7069 12.1444L15.8456 9.00002L12.7069 5.85565L13.5 5.06252L17.4375 9.00002Z" fill="#5F728A"/>
          <path d="M0.5625 9.00002L4.5 5.06252L5.29312 5.85565L2.15437 9.00002L5.29312 12.1444L4.5 12.9375L0.5625 9.00002Z" fill="#5F728A"/>
          <path d="M9.92187 3.37555L6.98541 14.3346L8.07208 14.6258L11.0085 3.66672L9.92187 3.37555Z" fill="#5F728A"/>
        </svg>
      );
    case 'scalar':
    default:
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.7102 2.97729L15.0227 1.28979C14.9705 1.23754 14.9085 1.19608 14.8402 1.1678C14.772 1.13952 14.6988 1.12496 14.625 1.12496C14.5511 1.12496 14.478 1.13952 14.4097 1.1678C14.3415 1.19608 14.2795 1.23754 14.2273 1.28979L11.0171 4.49998H9.61575C8.33311 4.50097 7.08241 4.90012 6.03632 5.64232C4.99023 6.38452 4.20034 7.43316 3.77571 8.64347L1.15659 16.1269C1.12165 16.2268 1.11562 16.3346 1.1392 16.4378C1.16278 16.541 1.21502 16.6355 1.2899 16.7103C1.36478 16.7852 1.45927 16.8374 1.5625 16.8609C1.66573 16.8845 1.77351 16.8784 1.87344 16.8434L9.35678 14.2243C10.567 13.7996 11.6156 13.0097 12.3578 11.9636C13.0999 10.9175 13.499 9.66682 13.5 8.38421V6.98291L16.7102 3.77267C16.7624 3.72046 16.8039 3.65846 16.8322 3.59023C16.8604 3.52199 16.875 3.44885 16.875 3.37498C16.875 3.30111 16.8604 3.22797 16.8322 3.15973C16.8039 3.0915 16.7624 3.0295 16.7102 2.97729V2.97729ZM12.5398 6.35229C12.4343 6.45776 12.375 6.60081 12.375 6.74998V8.38421C12.3742 9.43365 12.0476 10.4569 11.4403 11.3128C10.8331 12.1687 9.9751 12.815 8.98485 13.1625L3.82802 14.9675L8.99998 9.79541L8.20455 8.99998L3.03264 14.1721L4.83748 9.01511C5.18494 8.02486 5.83122 7.16689 6.68712 6.55964C7.54302 5.95239 8.56632 5.62581 9.61575 5.62498H11.25C11.3992 5.62495 11.5422 5.56566 11.6477 5.46017L14.625 2.48291L15.5171 3.37498L12.5398 6.35229Z" fill="#5F728A"/>
        </svg>
      );
  }
};

const resolveTooltip = (type) => {
  switch (type) {
    case 'variable':
      return 'Field type';
    case 'query-variable':
      return 'SQL Query type';
    case 'scalar':
    default:
      return 'Value type';
  }
};

const AutoComplete = ({
  testId = undefined,
  title,
  large = false,
  error = '',
  type = undefined,
  value,
  style= {},
  operator = undefined,
  fullWidth = false,
  setFieldSelected = undefined,
  additionalOptions = undefined,
  showArrow = false,
  fieldSelected = undefined,
  onPlusChange = undefined,
  valueType = undefined,
  valueKey = undefined,
  disabled = undefined,
  field = undefined,
  suggestionsOptions,
  options = undefined,
  showTypes = false,
  smaller = undefined,
  displaySelf = true,
  altSelection = undefined,
  onChange,
  getPopupContainer = undefined,
  eventSubFilter = undefined,
  isNodeSettings = undefined,
  isTwoValues = undefined,
  isFilterSplit = undefined,
  isStepper = undefined,
  ...props
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const { t, p, e } = useTranslation('segments_page');
  const [inputFocused, setIsInputFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [autocompleteEffectEnabled, setAutocompleteEffectEnabled] = useState(false);
  const autocomplete = useAutocomplete({ ...(suggestionsOptions || {}), query: value });

  useEffect(() => {
    if (!autocompleteEffectEnabled || !suggestionsOptions) {
      return;
    }

    autocomplete.dispatcher();
  }, [value, autocompleteEffectEnabled, !!suggestionsOptions]);

  const handleChange = (newValue, option) => {

    const findValueByLabel = Object.values(option)?.length === 0 && getOptions().find(({ label }) => newValue === label)

    if (type === 'number') {
      const v = newValue?.replaceAll?.(',', '.') || newValue;

      if (zeroIfNaN(String(v)?.length) > zeroIfNaN(String(value)?.length)) {
        if (/^-?\d+\.?\d*$/.test(v) || v === '' || v === '-') {
          onChange(v, null, option)
        }
        return;
      }
    }
    const currentValue = Object.values(option)?.length === 0 && findValueByLabel ? findValueByLabel.value : newValue
    setFieldSelected?.(false);
    onChange(currentValue, null, option);
  }

  const popup = document.querySelector('.acp:not(.ant-select-dropdown-hidden) .rc-virtual-list-holder');

  useEffect(() => {
    if (!autocompleteEffectEnabled) {
      return;
    }

    let p = popup || document.querySelector('.acp:not(.ant-select-dropdown-hidden) .rc-virtual-list-holder');

    const listener = (e) => {
      if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
        autocomplete.loadMore();
      }
    };

    if (!p) {
      return () => {
        p?.removeEventListener?.('scroll', listener);
      }
    }

    p?.addEventListener?.('scroll', listener);

    return () => {
      p?.removeEventListener?.('scroll', listener);
    }
  }, [popup, autocomplete.suggestions.length, autocompleteEffectEnabled]);

  useEffect(() => {
    if (!autocompleteEffectEnabled) {
      popup?.removeAllListeners?.('scroll');
    }
  }, [autocompleteEffectEnabled]);

  const handlePlusChange = (value) => {
    if (onPlusChange) {
      onPlusChange(value);
      setFieldSelected?.(true);
      setDropdownOpened(false);
    }
  }

  const getOptions = () => {
    if (!options) {
      return (autocomplete.suggestions || []).map(o => ({ ...o, label: !!~String(o.label).indexOf(':') ? o.label : t(o.label) }));
    }

    const opt = (displaySelf ? uniqBy(
      props.value ? [{ value: props.value, label: t(props.value) }, ...(options || [])] : options,
      ({ key, value, nested }) => nested ? key : value) : options)
      .map(({ label, ...o }) => ({ label: typeof label !== 'object' ? t(label) : label, ...o }));

    return showTypes ? opt.map((o, idx) => ({ ...o, label: (
      <div key={idx} style={{ display: 'flex', justifyContent: 'space-between', padding: '0' }}>
        <div style={{ fontSize: 13 }}>{o.label}</div>
        <Suffix style={{ marginRight: 5 }}>{resolveDropdownFieldType(o.nested, o.type, p)}</Suffix>
      </div>
    ) })) : opt;
  }

  const getValue = () => {
    const opt = options || autocomplete.suggestions;

    if (valueKey) {
      return opt.find(o => o.key === valueKey)?.label || value;
    }

    if (!value) {
      return value;
    }

    return opt.find(o => o.value === value)?.label || value;
  }

  const v = getValue();

  const filterOption = (inputValue, option) => String(option.label).toUpperCase().indexOf(String(inputValue).toUpperCase()) !== -1;
  const sortOption = (a, b) => String(a.value).toLowerCase().localeCompare(String(b.value).toLowerCase());

  const renderAutocomplete = () => {
    if ((typeof v === 'string' || typeof v === 'number' || !v) && !options?.length) {
      const val = v ? (!!~String(v).indexOf(':') ? String(v) : t(String(v))) : v === 0 ? '0' : String(v);
      const largeValue = val.length > 15;

      return (
        <Tooltip open={hovered && largeValue && !inputFocused} title={val}>
          <StyledAutoComplete
            {...props}
            style={props.innerStyle}
            onChange={handleChange}
            disabled={disabled}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            $smaller={smaller}
            htmlType={type}
            popupClassName={'acp'}
            onFocus={sequence(props.onFocus || identity, () => { setIsInputFocused(true); setAutocompleteEffectEnabled(true) })}
            onBlur={sequence(props.onBlur || identity, () => { setIsInputFocused(false); setAutocompleteEffectEnabled(false) })}
            large={large && !fullWidth}
            fullWidth={fullWidth}
            placeholder={(altSelection && !fieldSelected) ? '' : t('labels.select')}
            value={val}
            error={error}
            options={getOptions().sort(sortOption)}
            filterOption={filterOption}
            getPopupContainer={getPopupContainer}
            eventSubFilter={eventSubFilter}
            isTwoValues={isTwoValues}
            isNodeSettings={isNodeSettings}
            $isFilterSplit={isFilterSplit}
            $isStepper={isStepper}
          />
        </Tooltip>
      )
    }

    return (
      <StyledAutoComplete
        {...props}
        style={props.innerStyle}
        onChange={handleChange}
        disabled={disabled}
        $smaller={smaller}
        htmlType={type}
        popupClassName={'acp'}
        onFocus={sequence(props.onFocus || identity, () => setAutocompleteEffectEnabled(true))}
        onBlur={sequence(props.onBlur || identity, () => setAutocompleteEffectEnabled(false))}
        large={large && !fullWidth}
        fullWidth={fullWidth}
        placeholder={v ? '' : t('labels.select')}
        value={v}
        error={error}
        options={getOptions().sort(sortOption)}
        filterOption={filterOption}
        getPopupContainer={getPopupContainer}
        isTwoValues={isTwoValues}
      >
        {(!!v && (typeof v === 'string' || typeof v === 'number')) ? null : <div>{v}</div>}
      </StyledAutoComplete>
    );
  }

  return (
    <Container disabled={disabled} style={style} fullWidth={fullWidth} data-testid={`${testId}-container`}>
      {title && altSelection && !fieldSelected ? (
        <Tooltip placement="topLeft" title={p('you_can_write_your_own_value')}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title>{title}</Title>
            <InfoIconContainer style={{ marginBottom: 7, backgroundColor: '#FFFFFF' }}>
              <Icon color="#909399" name="Info-icon" size={10}/>
            </InfoIconContainer>
          </div>
        </Tooltip>
      ) : title && (
        <Title>{title}</Title>
      )}
      {renderAutocomplete()}
      {altSelection && shouldRenderPlus(operator) ? (
        <Dropdown
          trigger={['click']}
          open={dropdownOpened}
          onOpenChange={setDropdownOpened}
          placement="bottomRight"
          getPopupContainer={getPopupContainer}
          overlay={(
            <Overlay>
              {additionalOptions.map((opt, idx) => (
                <DropdownItem key={idx} onClick={() => handlePlusChange(opt.value)}>
                  {field === '_te' ? (null) : opt.label}
                </DropdownItem>
              ))}
            </Overlay>
          )}
        >
          <Tooltip placement="right" title={resolveTooltip(valueType)}>
            <PlusContainer $sm={smaller} $labeled={!!title}>
              <IconContainer $sm={smaller}>
                {resolveIcon(valueType)}
              </IconContainer>
            </PlusContainer>
          </Tooltip>
        </Dropdown>
      ) : showArrow && (
        <ArrowContainer>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M9 12.375L3.375 6.75002L4.1625 5.96252L9 10.8L13.8375 5.96252L14.625 6.75002L9 12.375Z"
              fill="#5F728A"/>
          </svg>
        </ArrowContainer>
      )}
      {(autocomplete.loading) && (
        <Loader smaller={smaller} left={altSelection && shouldRenderPlus(operator)} $error={error}>
          <Spin size={smaller ? 'small' : 'default'} spinning/>
        </Loader>
      )}
      {error && (
        <Tooltip title={e(error)} placement="bottom">
          <Error smaller={smaller} left={altSelection && shouldRenderPlus(operator)}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="7" cy="7" r="7" fill="#C8402F"/>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M7.78412 7.79622C7.78412 8.24339 7.4203 8.60721 6.97313 8.60721C6.52596 8.60721 6.16214 8.24339 6.16214 7.79622V4.00002C6.16214 3.55284 6.52596 3.18903 6.97313 3.18903C7.4203 3.18903 7.78412 3.55284 7.78412 4.00002V7.79622ZM7.78213 9.959C7.78213 10.4062 7.41832 10.77 6.97115 10.77C6.52398 10.77 6.16016 10.4062 6.16016 9.959C6.16016 9.51183 6.52398 9.14801 6.97115 9.14801C7.41832 9.14801 7.78213 9.51183 7.78213 9.959Z"
                fill="white"/>
            </svg>
          </Error>
        </Tooltip>
      )}
    </Container>
  );
};

export default AutoComplete;
