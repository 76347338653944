import styled from 'styled-components';

import { Colors } from '@res/theme';

export const Container = styled.div`
  padding-bottom: 30px;
`;

export const Box = styled.div`
  display: flex;
  padding: 12px;
  background-color: #faf2f1;
  border-left: 4px solid ${Colors.Common.DANGER};
  border-radius: 2px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  background-color: ${Colors.Common.DANGER};
  border-radius: 50%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  margin-bottom: 6px;
  font-size: 14px;
  color: ${Colors.Text.DANGER};
`;

export const ErrorsList = styled.ul`
  font-size: 12px;
  margin: 0;
  padding: 0 0 0 16px;
`;

export const Error = styled.li``;
