import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    /* min-width: 65px; */
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 5px;
        width: 1px;
        height: 221px;
        background: var(--border-divider, #D3DBE7);
    }

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        right: 5px;
        width: 1px;
        height: 214px;
        background: var(--border-divider, #D3DBE7);
    }

    #line-chart {
        position: relative;
    } 

    .custom-apexcharts-yaxis-label {
        padding: 0 20px;
        color: var(--Colors-Neutral-300, #7C94B1);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Manrope-Medium;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        background-color: #fff;

    }
    .custom-apexcharts-xaxis-label {
        color: var(--text-secondary-body, #7C94B1);
        text-align: center;
        font-family: Manrope-Medium;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* transform: translateX(15px); */
        &:first-child {
            transform: translateX(15px);
        }
    }
`