import get from 'lodash.get';

import useSystemAutocomplete from '@hooks/useSystemAutocomplete';

const createPath = (path, filter) => {
  if (!filter) {
    return path.join('.');
  }

  return Object.entries(filter).reduce((acc, [k, v]) => `${acc}.${k}.${v}`, path.join('.'));
}

const useSystemEntityOptions = (path, filter) => {
  const systemAutocompletes = useSystemAutocomplete();

  return get(systemAutocompletes, createPath(path, filter)) || [];
};

export default useSystemEntityOptions;
