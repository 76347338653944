import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTemplatePreviewInfo } from '@store/actions/creators';
import { emailSampleDataSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { getDefaultLang, stringTemplate } from '@utils';

import { Modal } from '@components';

import { Container, PreviewWrapper, Body, PreviewData, Icon, Button } from './styled';

const ViewViberTemplateModal = ({ opened, onClose, data }) => {

  const defaultTemplate = data?.languages && getDefaultLang(data?.languages) || {};
  const [body, setBody] = useState(defaultTemplate?.data);
  const [buttonName, setButtonName] = useState(defaultTemplate?.btn_name);
  const dispatch = useDispatch();
  const { id: sampleContactId } = useSelector(emailSampleDataSelector);
  const { p } = useTranslation('notification_history')
  const image = defaultTemplate.image?.url || defaultTemplate.image_url;

  useEffect(() => {
    setBody(defaultTemplate?.data);
    setButtonName(defaultTemplate?.btn_name);
  }, [data]);

  useEffect(() => {
    if (opened) {
      dispatch(getTemplatePreviewInfo({
        fields: {
          data: defaultTemplate?.data,
          btn_name: defaultTemplate?.btn_name,
        },
        customer_id: sampleContactId,
        onSuccess: (d) => {
          setBody(d.templates.data)
          setButtonName(d.templates.btn_name)
        },
      }))
    }
  }, [opened]);

  const formatMatches = () => {
    const cfg = {
      '\\`\\`\\`': props => <code {...props} />,
      '\\*': props => <b {...props} />,
      '_': props => <i {...props} />,
      '~': props => <del {...props} />,
    };

    return Object.entries(cfg)
      .flatMap(([matcher, render]) => (data.data
        ?.match(new RegExp(`(${matcher}.*${matcher})`, 'g')) || [])?.map(match => [matcher, match, render])
      ).reduce((acc, [matcher, match, render]) => ({
        ...acc,
        [match]: render({ children: match.replaceAll(new RegExp(matcher, 'g'), '') })
      }), {
        '\n': <br />
      });
  };

  return (
    <Modal
      appearance="default"
      title={p('preview', { name: defaultTemplate.name })}
      contentStyles={{ padding: '20px', paddingTop: '14px', maxHeight: '60vh' }}
      opened={opened}
      onClose={onClose}
    >
      <Container>
        <PreviewWrapper>
          <PreviewData>
            <Body>{stringTemplate(formatMatches(), body)}</Body>
            {image && <Icon src={image} />}
            {(!!defaultTemplate.btn_name && defaultTemplate.btn_name !== 'null') ? <Button>{buttonName}</Button> : null}
          </PreviewData>
        </PreviewWrapper>
      </Container>
    </Modal>
  );
};

export default ViewViberTemplateModal;
