import { useTranslation } from '@hooks';

import { Container, OptionText, Option, Title } from './styled';
export const AiBestTimeInfo = ({ title, option }) => {
  const { p } = useTranslation('workflow_page');
  return (
    <Container>
      <Title>{title}</Title>
      <Option>{p('fallback_option')}</Option>
      <OptionText>{option}</OptionText>
    </Container>
  );
};
