import { Dropdown } from 'antd';

import { by } from '@utils';

import { Container, Label, Overlay, Option, Divider } from './styled';

const QuickPickDoubleSelect = ({ selected, leftOptions, rightOptions, leftValue, rightValue, onLeftChange, onRightChange }) => {
  const leftLabel = leftOptions.find(by('value', leftValue))?.label;
  const rightLabel = rightOptions.find(by('value', rightValue))?.label;

  const renderOverlay = (options, value, onChange) => {
    return (
      <Overlay>
        {options.map(({ value: curr, label }, idx) => (
          <Option key={idx} data-testid={`date-filter-pick-button-select-option-${curr}`} $selected={value === curr} onClick={() => onChange(curr)}>
            {label}
          </Option>
        ))}
      </Overlay>
    );
  }

  return (
    <Container $selected={selected}>
      <Dropdown
        trigger={['click']}
        overlay={renderOverlay(leftOptions, leftValue, onLeftChange)}
      >
        <Label data-testid="date-filter-pick-button-left-select-button" $selected={selected}>
          {leftLabel}
          <div style={{ marginLeft: 32 }}>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.7334 2.5987L4.00007 6.33203L0.266734 2.5987L1.20007 1.66537L4.00007 4.46536L6.80007 1.66537L7.7334 2.5987Z" fill={selected ? '#31C447' : '#909399'}/>
            </svg>
          </div>
        </Label>
      </Dropdown>
      <Divider />
      <Dropdown
        trigger={['click']}
        overlay={renderOverlay(rightOptions, rightValue, onRightChange)}
      >
        <Label data-testid="date-filter-pick-button-right-select-button" $selected={selected}>
          {rightLabel}
          <div style={{ marginLeft: 32 }}>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.7334 2.5987L4.00007 6.33203L0.266734 2.5987L1.20007 1.66537L4.00007 4.46536L6.80007 1.66537L7.7334 2.5987Z" fill={selected ? '#31C447' : '#909399'}/>
            </svg>
          </div>
        </Label>
      </Dropdown>
    </Container>
  );
};

export default QuickPickDoubleSelect;
