import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Link, WithPermissions } from '@components';

import { Container, Title, Content, Header } from './styled.js';

import { NoData } from '../../../../../../components';

const RecentActivityTable = ({ title, onCreateClick, viewPermission, createPermission, linkPath, link, renderItem, data, total, containerStyle }) => {
  const { t } = useTranslation('dashboard_page');

  return (
    <Container style={containerStyle}>
      <Title data-testid="dashboard-title-recent-activity">
        {title}
      </Title>
      <Content>
        <Header>
          <WithPermissions name={viewPermission}>
            <Link to={linkPath} icon>
              {link}
            </Link>
          </WithPermissions>
          <WithPermissions name={createPermission}>
            <Button
              size={'sm'}
              style={{
                padding: '6px 14px',
                height: '26px',
                fontSize: '12px',
                lineHeight: '14px',
                borderRadius: '5px'
              }}
              width="none"
              onClick={onCreateClick}
            >
              {t('actions.create')}
            </Button>
          </WithPermissions>
        </Header>
        {total ? data.map(renderItem) : <NoData />}
      </Content>
    </Container>
  );
}

export default RecentActivityTable;

