import { useEffect, useRef, useState } from 'react';
import {
  useOnViewportChange
} from 'reactflow';

import { Dropdown } from 'antd';

import { useOutsideClickListener, useTranslation } from '@hooks';

import { WorkflowIcons, WorkflowNodes } from '@constants';
import nodeItems from '@constants/workflow/nodeItems';

import { by } from '@utils';

import { IconSvg } from '@components';

import { Container, Header, Tabs, Tab, TabContainer, TabIndicator, List, ListOption, NodeThumbnail } from './styled';

const tabs = [
  {
    name: 'connection_channel',
    title: 'labels.channels',
  },
  {
    name: 'actions',
    title: 'labels.actions',
  },
];

const AddNodeDropdown = ({ children, onSelectNode, alwaysOpen }) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('connection_channel');
  const refs = useRef(Object.fromEntries(tabs.map(({ name }) => [name, null])));
  const container = useRef(null);
  const { t, p } = useTranslation('workflow_page');

  const [updater, setUpdater] = useState(false);

  useEffect(() => {
    setTimeout(() => setUpdater(u => !u), 300);
  }, [open]);

  useOutsideClickListener(container, () => {
    setOpen(false);
  }, [setOpen]);

  const handleViewPortChange = () => {
    if (open) {
      setOpen(false);
    }
  }

  useOnViewportChange({
    onChange: handleViewPortChange,
  });

  const sizeIcon = {
    [WorkflowNodes.BEST_CHANNEL_TO_SEND]: 18,
    [WorkflowNodes.WEB_POPUP]: 18
  };

  const handleOpenChange = (open) => {
    const newEvent = new Event("react-flow-mousedown");
    document.dispatchEvent(newEvent);
    setOpen(open);
  };

  return (
    <Dropdown
      trigger={['click']}
      open={open || !!alwaysOpen}
      getPopupContainer={() => document.body}
      onOpenChange={handleOpenChange}
      overlay={(
        <Container ref={container}>
          <Header>
            <Tabs>
              {tabs.map(({ name, title }, idx) => (
                <TabContainer key={idx} onClick={() => setTab(name)}>
                  <Tab
                    data-testid={`workflow-add-node-dropdown-${name}-tab`}
                    id={`om-tab-${name}`}
                    ref={rr => refs.current[name] = rr}
                    selected={name === tab}
                  >
                    {t(title)}
                  </Tab>
                </TabContainer>
              ))}
              <TabIndicator key={updater} $tab={refs.current[tab]} />
            </Tabs>
          </Header>
          <List>
            {nodeItems.find(by('type', tab)).options.map(({ title, name }, idx) => {
              return (
                <ListOption key={idx} data-testid={`workflow-add-node-dropdown-${tab}-tab-option-${name}`} onClick={() => onSelectNode(tab, name)}>
                  <NodeThumbnail $type={tab}>
                    <IconSvg
                      size={sizeIcon[name] || 12}
                      name={WorkflowIcons[name]}
                    />
                  </NodeThumbnail>
                  {p(title)}
                </ListOption>
              );
            })}
          </List>
        </Container>
      )}
    >
      {children}
    </Dropdown>
  )
};

export default AddNodeDropdown;
