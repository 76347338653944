export const OPTIONS_KEYS = {
  AGGREGATES: 'aggregates',
  FIELDS: 'fields',
  FIELDS_ATTRIBUTES: 'attributes',
  FIELDS_EVENTS: 'events',
  FUNNELS: 'funnels',
  QUERY_VALUES: 'query_values',
  SEGMENTS: 'segments',
  WORKFLOW: 'workflow',
  ALL_WORKFLOWS: 'all-workflows',
  CAMPAIGN: 'campaign',
};
