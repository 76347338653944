import React, { useMemo } from 'react';
import { useSelector } from "react-redux";

import { dashboardMobilePushSummarySelector } from "@store/selectors/dashboard";

import { useTranslation } from "@hooks";

import { Paths } from "@constants";

import { zeroIfNaN } from "@utils";

import { SendingInfo } from "../SendingInfo";

const MobilePushSendingInfo = () => {
  const { p } = useTranslation('dashboard_page');
  const mobilePushSummary = useSelector(dashboardMobilePushSummarySelector);

  const sendingInfoContainerStyle = useMemo(() => {
    return { display: 'flex', flex: 1 };
  }, []);

  const mobilePushClicks = useMemo(() => {
    return {
      percentage: zeroIfNaN(mobilePushSummary?.click / mobilePushSummary?.total * 100),
      count: mobilePushSummary?.click
    };
  }, [mobilePushSummary?.click, mobilePushSummary?.total]);

  return (
    <SendingInfo
      total={mobilePushSummary?.total}
      to={`${Paths.NOTIFICATIONS}?tab=mobile-push`}
      link={p('mobile_push')}
      clicks={mobilePushClicks}
      testId="dashboard-webpush-sendings"
      containerStyle={sendingInfoContainerStyle}
    />
  )
}

export default React.memo(MobilePushSendingInfo);
