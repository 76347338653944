import { useEffect, useState } from 'react';

import { Button } from '@velitech/ui';

import { useTranslation } from "@hooks";

import { isoDateStringRegex, moment } from "@utils";

import { Input, Modal, Switch } from '@components';

import { Container, Description, Content } from './styled';

const FallbackValuesModal = ({ testId, opened, onClose, fallbacks, onSubmit }) => {
  const [values, setValues] = useState(fallbacks);
  const [showErrors, setShowErrors] = useState(false);
  const { p, t } = useTranslation('templates');

  useEffect(() => {
    if (opened) {
      setShowErrors(false);
      setValues(fallbacks.map(f => ({ ...f, checked: f.custom !== null ? false : true })));
    }
  }, [opened])

  const handleSubmit = () => {
    if (values.filter(v => !v.checked && !v.custom).length) {
      setShowErrors(true);
      return;
    }

    onSubmit(values);
    onClose();
  };

  const handleChangeCustom = (key, value, toChange) => {
    setShowErrors(false);
    const newValues = [...values];
    const index = newValues.findIndex(v => v.key === key);

    if (index !== -1) {
      newValues[index] = {
        ...newValues[index],
        [toChange]: value,
      }
    }

    setValues(newValues);
  }

  return (
    <Modal
      title={p('fallback_values')}
      style={{ maxWidth: '500px' }}
      {...{ opened, onClose }}
      actions={(
        <>
          <Button data-testid="fallback-rfm-cancel" onClick={onClose} variant="secondary" style={{ width: '140px' }}>{t('actions.cancel')}</Button>
          <Button data-testid="fallback-rfm-submit" onClick={handleSubmit} appearance="primary" style={{ width: '140px' }}>{t('actions.save')}</Button>
        </>
      )}
    >
      <Container data-testid={testId}>
        <Description>{p('fallback_description')}</Description>
        <Content>
          {values?.map(({ name, key, value, checked, custom }, index) => {
            const v = isoDateStringRegex.test(String(value)) ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value;
            return (
              <div key={index} style={{ display: 'flex' }}>
                <div>
                  {index === 0 && <div style={{ marginBottom: 15 }}>{p('customer_attributes')}</div>}
                  <Input
                    value={v}
                    onChange={() => {}}
                    title={name}
                    style={{ cursor: 'not-allowed', pointerEvents: 'none' }}
                    wrapperStyles={{ width: 220 }}
                  />
                </div>
                <div>
                  {index === 0 && <div style={{ marginBottom: 15 }}>{p('fallback_values')}</div>}
                  <Input
                    value={custom}
                    disabled={checked}
                    error={(showErrors && values.find(v => v.key === key && !v.custom && !v.checked)) ? p('required_field') : null}
                    onChange={(e) => handleChangeCustom(key, e.target.value, 'custom')}
                    title={(
                      <div style={{ display: 'flex' }}>
                        <div>{p('leave_empty')}</div>
                        <Switch
                          checked={checked}
                          onChange={() => handleChangeCustom(key, !checked, 'checked')}
                          style={{ marginLeft: '10px' }}
                        />
                      </div>
                    )}
                    wrapperStyles={{ marginLeft: 20, width: 220 }}
                  />
                </div>
              </div>
            )
          })}
        </Content>
      </Container>
    </Modal>
  );
};

export default FallbackValuesModal;
