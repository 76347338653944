const ContactsActionTypes = {
  GET: 'contacts/GET',
  GET_SUCCESS: 'contacts/GET_SUCCESS',
  GET_ERROR: 'contacts/GET_ERROR',

  GET_IMPORT_HISTORY: 'contacts/GET_IMPORT_HISTORY',
  GET_IMPORT_HISTORY_SUCCESS: 'contacts/GET_IMPORT_HISTORY_SUCCESS',
  GET_IMPORT_HISTORY_ERROR: 'contacts/GET_IMPORT_HISTORY_ERROR',

  UPLOAD_FILE: 'contacts/UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'contacts/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'contacts/UPLOAD_FILE_ERROR',

  DELETE_ALL: 'contacts/DELETE_ALL',
  DELETE_ALL_SUCCESS: 'contacts/DELETE_ALL_SUCCESS',
  DELETE_ALL_ERROR: 'contacts/DELETE_ALL_ERROR',

  GET_CONTACT_EVENT: 'contact/GET_CONTACT_EVENT',
  GET_CONTACT_EVENT_SUCCESS: 'contact/GET_CONTACT_EVENT_SUCCESS',
  GET_CONTACT_EVENT_ERROR: 'contact/GET_CONTACT_EVENT_ERROR',

  GET_CONTACT_EVENT_TOTAL: 'contact/GET_CONTACT_EVENT_TOTAL',
  GET_CONTACT_EVENT_TOTAL_SUCCESS: 'contact/GET_CONTACT_EVENT_TOTAL_SUCCESS',
  GET_CONTACT_EVENT_TOTAL_ERROR: 'contact/GET_CONTACT_EVENT_TOTAL_ERROR',

  START_IMPORT: 'contacts/START_IMPORT',
  START_IMPORT_SUCCESS: 'contacts/START_IMPORT_SUCCESS',
  START_IMPORT_ERROR: 'contacts/START_IMPORT_ERROR',

  CREATE_NEW_CONTACT_FIELDS: 'contacts/CREATE_NEW_FIELDS',
  CREATE_NEW_CONTACT_FIELDS_SUCCESS: 'contacts/CREATE_NEW_FIELDS_SUCCESS',
  CREATE_NEW_CONTACT_FIELDS_ERROR: 'contacts/CREATE_NEW_FIELDS_ERROR',

  GET_IMPORT_PROGRESS: 'contacts/GET_IMPORT_PROGRESS',
  GET_IMPORT_PROGRESS_SUCCESS: 'contacts/GET_IMPORT_PROGRESS_SUCCESS',
  GET_IMPORT_PROGRESS_ERROR: 'contacts/GET_IMPORT_PROGRESS_ERROR',

  DELETE_FIELD_SETTINGS: 'customers/DELETE_FIELD_SETTINGS',
  DELETE_FIELD_SETTINGS_SUCCESS: 'customers/DELETE_FIELD_SETTINGS_SUCCESS',
  DELETE_FIELD_SETTINGS_ERROR: 'customers/DELETE_FIELD_SETTINGS_ERROR',

  GET_AGGREGATES_LIST: 'contacts/GET_AGGREGATES_LIST',
  GET_AGGREGATES_LIST_SUCCESS: 'contacts/GET_AGGREGATES_LIST_SUCCESS',
  GET_AGGREGATES_LIST_ERROR: 'contacts/GET_AGGREGATES_LIST_ERROR',

  REORDER_FIELDS: 'contacts/REORDER_FIELD',
  REORDER_FIELDS_SUCCESS: 'contacts/REORDER_FIELD_SUCCESS',
  REORDER_FIELDS_ERROR: 'contacts/REORDER_FIELD_ERROR',

  CHANGE_FIELD_DISPLAYING_STATUS: 'contacts/CHANGE_FIELD_DISPLAYING_STATUS',
  CHANGE_FIELD_DISPLAYING_STATUS_SUCCESS: 'contacts/CHANGE_FIELD_DISPLAYING_STATUS_SUCCESS',
  CHANGE_FIELD_DISPLAYING_STATUS_ERROR: 'contacts/CHANGE_FIELD_DISPLAYING_STATUS_ERROR',

  DELETE_CONTACT: 'contacts/DELETE',
  DELETE_CONTACT_SUCCESS: 'contacts/DELETE_SUCCESS',
  DELETE_CONTACT_ERROR: 'contacts/DELETE_ERROR',

  CREATE_COMMENT: 'contacts/CREATE_COMMENT',
  CREATE_COMMENT_SUCCESS: 'contacts/CREATE_COMMENT_SUCCESS',
  CREATE_COMMENT_ERROR: 'contacts/CREATE_COMMENT_ERROR',

  DELETE_COMMENT: 'contacts/DELETE_COMMENT',
  DELETE_COMMENT_SUCCESS: 'contacts/DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_ERROR: 'contacts/DELETE_COMMENT_ERROR',

  CREATE_CONTACT: 'contacts/CREATE',
  CREATE_CONTACT_SUCCESS: 'contacts/CREATE_SUCCESS',
  CREATE_CONTACT_ERROR: 'contacts/CREATE_ERROR',

  DOWNLOAD_IMPORT_FILE: 'contacts/DOWNLOAD_IMPORT_FILE',
  DOWNLOAD_IMPORT_FILE_SUCCESS: 'contacts/DOWNLOAD_IMPORT_FILE_SUCCESS',
  DOWNLOAD_IMPORT_FILE_ERROR: 'contacts/DOWNLOAD_IMPORT_FILE_ERROR',

  DELETE_MULTIPLE: 'contacts/DELETE_MULTIPLE',
  DELETE_MULTIPLE_SUCCESS: 'contacts/DELETE_MULTIPLE_SUCCESS',
  DELETE_MULTIPLE_ERROR: 'contacts/DELETE_MULTIPLE_ERROR',

  UPDATE_CONTACT_FIELD: 'contacts/UPDATE_FIELD',
  UPDATE_CONTACT_FIELD_SUCCESS: 'contacts/UPDATE_FIELD_SUCCESS',
  UPDATE_CONTACT_FIELD_ERROR: 'contacts/UPDATE_FIELD_ERROR',

  CREATE_EXPRESSION: '/customers/CREATE_EXPRESSION',
  CREATE_EXPRESSION_SUCCESS: '/customers/CREATE_EXPRESSION_SUCCESS',
  CREATE_EXPRESSION_ERROR: '/customers/CREATE_EXPRESSION_ERROR',

  CHANGE_IMPORT_STEP: 'contacts/CHANGE_IMPORT_STEP',
  UPDATE_IMPORT_FIELDS_CONFIGURATION: 'contacts/UPDATE_IMPORT_FIELDS_CONFIGURATION',

  GET_CONTACT_INFO: 'contacts/GET_CONTACT_INFO',
  GET_CONTACT_INFO_SUCCESS: 'contacts/GET_CONTACT_INFO_SUCCESS',
  GET_CONTACT_INFO_ERROR: 'contacts/GET_CONTACT_INFO_ERROR',

  GET_CONTACT_EMAIL_INFO: 'contacts/GET_CONTACT_EMAIL_INFO',
  GET_CONTACT_EMAIL_INFO_SUCCESS: 'contacts/GET_CONTACT_EMAIL_INFO_SUCCESS',
  GET_CONTACT_EMAIL_INFO_ERROR: 'contacts/GET_CONTACT_EMAIL_INFO_ERROR',

  GET_CUSTOMER_FIELD_INFO: 'customers/GET_CUSTOMER_FIELD_INFO',
  GET_CUSTOMER_FIELD_INFO_SUCCESS: 'customers/GET_CUSTOMER_FIELD_INFO_SUCCESS',
  GET_CUSTOMER_FIELD_INFO_ERROR: 'customers/GET_CUSTOMER_FIELD_INFO_ERROR',

  GET_CONTACT_SMS_INFO: 'contacts/GET_CONTACT_SMS_INFO',
  GET_CONTACT_SMS_INFO_SUCCESS: 'contacts/GET_CONTACT_SMS_INFO_SUCCESS',
  GET_CONTACT_SMS_INFO_ERROR: 'contacts/GET_CONTACT_SMS_INFO_ERROR',

  GET_CONTACT_PUSH_INFO: 'contacts/GET_CONTACT_PUSH_INFO',
  GET_CONTACT_PUSH_INFO_SUCCESS: 'contacts/GET_CONTACT_PUSH_INFO_SUCCESS',
  GET_CONTACT_PUSH_INFO_ERROR: 'contacts/GET_CONTACT_PUSH_INFO_ERROR',

  GET_CONTACT_INVOLVEMENT: 'contacts/GET_CONTACT_INVOLVEMENT',
  GET_CONTACT_INVOLVEMENT_SUCCESS: 'contacts/GET_CONTACT_INVOLVEMENT_SUCCESS',
  GET_CONTACT_INVOLVEMENT_ERROR: 'contacts/GET_CONTACT_INVOLVEMENT_ERROR',

  GET_CONTACT_AUDITS: 'contacts/GET_CONTACT_AUDITS',
  GET_CONTACT_AUDITS_SUCCESS: 'contacts/GET_CONTACT_AUDITS_SUCCESS',
  GET_CONTACT_AUDITS_ERROR: 'contacts/GET_CONTACT_AUDITS_ERROR',

  GET_EMAIL_SUMMARY: 'contacts/GET_EMAIL_SUMMARY',
  GET_EMAIL_SUMMARY_SUCCESS: 'contacts/GET_EMAIL_SUMMARY_SUCCESS',
  GET_EMAIL_SUMMARY_ERROR: 'contacts/GET_EMAIL_SUMMARY_ERROR',

  GET_SMS_SUMMARY: 'contacts/GET_SMS_SUMMARY',
  GET_SMS_SUMMARY_SUCCESS: 'contacts/GET_SMS_SUMMARY_SUCCESS',
  GET_SMS_SUMMARY_ERROR: 'contacts/GET_SMS_SUMMARY_ERROR',

  GET_WEBPUSH_SUMMARY: 'contacts/GET_PUSH_SUMMARY',
  GET_WEBPUSH_SUMMARY_SUCCESS: 'contacts/GET_PUSH_SUMMARY_SUCCESS',
  GET_WEBPUSH_SUMMARY_ERROR: 'contacts/GET_PUSH_SUMMARY_ERROR',

  GET_WEBHOOK_SUMMARY: 'contacts/GET_WEBHOOK_SUMMARY',
  GET_WEBHOOK_SUMMARY_SUCCESS: 'contacts/GET_WEBHOOK_SUMMARY_SUCCESS',
  GET_WEBHOOK_SUMMARY_ERROR: 'contacts/GET_WEBHOOK_SUMMARY_ERROR',

  DOWNLOAD_CONTACTS: 'contacts/DOWNLOAD',
  DOWNLOAD_CONTACTS_SUCCESS: 'contacts/DOWNLOAD_SUCCESS',
  DOWNLOAD_CONTACTS_ERROR: 'contacts/DOWNLOAD_ERROR',

  SET_COLS: 'contacts/SET_COLS',
  SET_COLS_OPTIONS: 'contacts/SET_COLS_OPTIONS',

  GET_CUSTOMERS_COUNT: 'customers/GET_COUNT',
  GET_CUSTOMERS_COUNT_SUCCESS: 'customers/GET_COUNT_SUCCESS',
  GET_CUSTOMERS_COUNT_ERROR: 'customers/GET_COUNT_ERROR',
};

export default ContactsActionTypes;
