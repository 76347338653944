const RolesActionTypes = {
  GET_ROLES: 'roles/GET_ROLES',
  GET_ROLES_SUCCESS: 'roles/GET_ROLES_SUCCESS',
  GET_ROLES_ERROR: 'roles/GET_ROLES_ERROR',
  GET_ROLE: 'roles/GET_ROLE',
  GET_ROLE_SUCCESS: 'roles/GET_ROLE_SUCCESS',
  GET_ROLE_ERROR: 'roles/GET_ROLE_ERROR',
  DELETE_ROLE: 'roles/DELETE_ROLE',
  DELETE_ROLE_SUCCESS: 'roles/DELETE_ROLE_SUCCESS',
  DELETE_ROLE_ERROR: 'roles/DELETE_ROLE_ERROR',
  GET_PERMISSIONS: 'roles/GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS: 'roles/GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_ERROR: 'roles/GET_PERMISSIONS_SUCCESS',
  UPDATE_ROLE: 'roles/UPDATE_ROLE',
  UPDATE_ROLE_SUCCESS: 'roles/UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_ERROR: 'roles/UPDATE_ROLE_ERROR',
  GET_ROLES_LIST: 'roles/GET_LIST',
  GET_ROLES_LIST_SUCCESS: 'roles/GET_LIST_SUCCESS',
  GET_ROLES_LIST_ERROR: 'roles/GET_LIST_ERROR',
};

export default RolesActionTypes;
