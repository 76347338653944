import { useSelector } from 'react-redux';

import { emailNodeAnalyticsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';
import useWorkflowOptionsPreview from '@hooks/useWorkflowOptionsPreview';

import { TemplateTypes } from '@constants/templates';

import { moment } from '@utils';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import { SendingSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendingSection';

import { Container, Sending, AiSection, AiTitle, AiSubtitle, SubText } from './styled.js';

const SendEmailNodeSettingsInfo = ({ node }) => {
  const emailNodeAnalytics = useSelector(emailNodeAnalyticsSelector);
  const { p, t } = useTranslation();

  const email = useWorkflowOptionsPreview(TemplateTypes.EMAIL, node.data?.email_id);

  if (!email) {
    return (
      <Container>
        <InfoTitle>{t('labels.template')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  return (
    <Container>
      <InfoTitle>{t('labels.template')}:</InfoTitle>
      <InfoText>{email.name}</InfoText>
      {node.data.sending && !emailNodeAnalytics?.loading && (
        <SendingSection
          out={node.data.analytics?.out}
          statuses={node.data.sending}
        />
      )}
      {node.data.analytics && (
        <AnalyticsSection analytics={node.data.analytics} />
      )}
      <Sending>
        <InfoTitle>{t('labels.sending')}:</InfoTitle>
        {!node.data?.best_time_enabled ? (
          <InfoText>{p('workflow_page.upon_node_entrance')}</InfoText>
        ) : (
          <AiSection>
            <AiTitle>
              {p('workflow_page.ai_best_time')}
            </AiTitle>
            <AiSubtitle>
              {p('workflow_page.fallback_option')}
            </AiSubtitle>
            <SubText>{node.data?.best_time_fallback ? `${p('workflow_page.at')} ${moment(node.data.best_time_fallback)?.hour?.()}:${moment(node.data.best_time_fallback)?.minute?.() === 0 ? moment(node.data.best_time_fallback)?.minute?.() + '0' : moment(node.data.best_time_fallback)?.minute?.()}` : p('workflow_page.upon_node_entrance')}</SubText>
          </AiSection>
        )}
      </Sending>
    </Container>
  );
};

export default SendEmailNodeSettingsInfo;
