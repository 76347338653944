import NotificationsActionTypes from '@store/actions/types/notifications';
import { asCreator, withCallbacks } from '@store/lib';

import { compose } from '@utils';

export const getNotifications = () => ({
  type: NotificationsActionTypes.GET_NOTIFICATIONS,
});

export const markAsRead = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: NotificationsActionTypes.MARK_AS_READ,
    payload: { ids },
  }))
);

export const markAsImportant = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: NotificationsActionTypes.MARK_AS_IMPORTANT,
    payload: { ids },
  }))
);

export const markNotImportant = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: NotificationsActionTypes.MARK_NOT_IMPORTANT,
    payload: { ids },
  }))
);

export const addNotification = (notification) => ({
  type: NotificationsActionTypes.ADD_NOTIFICATION,
  payload: { notification },
});
