import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeLatest, put } from 'redux-saga/effects';

import { Endpoints } from '@constants';

import { AppActionTypes } from '../../actions/types';

function* checkLinkSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/auth/link/check',
    // endpoint: ~['r', 'n'].indexOf(action.payload.type) ? '/api/auth/link/check' : '/api/link/check',
    body: {
      email: action.payload.email,
      hash: action.payload.hash,
      type: action.payload.type,
      systemType: action.payload.systemType,
    },
  }));
}

function* pingApiSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/health',
  }));
}

function* sentOnboardParamsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'put',
    endpoint: Endpoints.ONBOARDING,
    body: {
      on_boarding: { ...action.payload.params },
    }
  }));
}

function* checkWebSDKHealthSAGA(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.HEALTH_CHECK_SDK,
  }));
}

export default function* appRootSaga() {
  yield all([
    takeLatest(AppActionTypes.PING_API, pingApiSaga),
    takeLatest(AppActionTypes.CHECK_APP_LINK, checkLinkSaga),
    takeLatest(AppActionTypes.SENT_ONBOARD_PARAMS, sentOnboardParamsSaga),
    takeLatest(AppActionTypes.CHECK_SDK, checkWebSDKHealthSAGA),
  ]);
}
