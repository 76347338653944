import { Skeleton } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-radius: 7px;
  background-color: #F8FAFE;
  border: 1px solid #D3DBE7;
  min-width: 225px;
`;

export const RateNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 6px;
`

export const RateName = styled.p`
  font-size: 13px;
  font-family: Manrope-SemiBold;
  color: #7C94B1;
`;

export const SkeletonCount = styled(Skeleton.Input)`
    font-family: Manrope-SemiBold;
    padding: 12px 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.1;
    text-align: center;
`;

export const Count = styled.div`
  font-family: Manrope-SemiBold;
  padding: 12px 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;

`