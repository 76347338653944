import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useModalState } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import { clickhouseFields2Customer, clickhouseFields2Extra } from '@utils/fields';

import { NoData } from '@components';

import { Container, DDIcon, ExtraFieldsContainer, ExtraFieldsList, ExtraFieldsTitle, Field } from './styled.js';

const LinearFilterPanel = ({ onSelect, hidePreview }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const linearFields = clickhouseFields2Customer(clickhouseFields?.data)?.filter(f => f.type !== 'array');
  const extraFields = clickhouseFields2Extra(clickhouseFields?.data);
  const extraFieldsOpened = useModalState();

  if (!linearFields?.length) {
    return (
      <Container hidePreview={hidePreview}>
        <NoData />
      </Container>
    )
  }

  return (
    <Container hidePreview={hidePreview} data-testid={'store-segment-filter-linear-tab-content'}>
      <div>
        {!!extraFields.length && (
          <ExtraFieldsContainer $opn={extraFieldsOpened.opened}>
            <ExtraFieldsTitle onClick={extraFieldsOpened.toggle}>
              Custom fields
              <DDIcon $opn={extraFieldsOpened.opened}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5.6251L3.375 11.2501L4.1625 12.0376L9 7.2001L13.8375 12.0376L14.625 11.2501L9 5.6251Z"
                    fill="#5F728A"/>
                </svg>
              </DDIcon>
            </ExtraFieldsTitle>
            <ExtraFieldsList $opn={extraFieldsOpened.opened}>
              {extraFields?.map((lf, idx) => (
                <Field
                  key={idx}
                  style={{ paddingLeft: '32px' }}
                  data-testid={`store-segment-filter-panel-linear-extra-field-${lf.field}`}
                  onClick={() => onSelect(lf, SegmentsRuleTypes.LINEAR)}
                >
                  {lf.label}
                </Field>
              ))}
            </ExtraFieldsList>
          </ExtraFieldsContainer>
        )}
        <Field
          data-testid={`store-segment-filter-panel-linear-field-tags`}
          onClick={() => onSelect('tags', SegmentsRuleTypes.ARRAY)}
        >
        Tags
        </Field>
        {linearFields?.map((lf, idx) => (
          <Field
            key={idx}
            data-testid={`store-segment-filter-panel-linear-field-${lf.field}`}
            onClick={() => onSelect(lf, SegmentsRuleTypes.LINEAR)}
          >
            {lf.label}
          </Field>
        ))}
      </div>
    </Container>
  );
}

export default LinearFilterPanel;

