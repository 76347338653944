import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
    border-radius: 7px;
    border: 1px solid var(--cards-border, #D3DBE7);
    background: var(--Colors-Additional-White, #FFF);
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 10px;
    border-bottom: 1px solid var(--cards-border, #D3DBE7);
`;

export const Title = styled.div`
    overflow: hidden;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
`;

export const SubTitle = styled.div`
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-SemiBold;
    font-size: 14px;
    line-height: 20px;
`;

export const Body = styled.div`
    padding: 30px 24px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const RecepientsPreview = styled.div`
    border-radius: var(--input-input-radius-big, 7px);
    border: 1px dashed #D3DBE7;
    background: #FFF;
    min-height: 58px;
    cursor: text;
    
    transition: border-color 330ms ease;
    
    &:focus-within {
        border-color: #5cbc6a;
    }
    
    ${styledProp('$error', css`
        border-color: var(--input-input-border-danger, #F04438);
    `)}
    
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 12px;
`;

export const RecepientsInput = styled.input`
    min-width: 120px;
    height: 26px;
    outline: none;
    border: none;
    
    &:focus {
        outline: none;
        border: none;
    }
    
    &:active {
        outline: none;
        border: none;
    }
`;

export const Tag = styled.div`
    display: flex;
    border-radius: 4px;
    background: var(--buttons-secondary-bg, #ECF0F6);
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px;
    height: 26px;

    color: var(--buttons-secondary-txt, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;


    ${styledProp('$error', css`
        background: var(--Colors-Additional-Negative-500p, #F04438);
        color: #fff;
    `)}
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;

export const Error =styled.div`
    position: absolute;
    color: var(--input-input-txt-danger, #F04438);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-SemiBold;
    font-size: 11px;
    line-height: 16px; 
    bottom: 4px;
`;

export const InputContainer = styled.div`
    display: flex;
    gap: 16px;
`;
