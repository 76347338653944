import styled from "styled-components";

export const Container = styled.tr`
    justify-content: center;
    align-items: center;
    height: calc(100vh - 288px);
`;

export const InnerContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const Title = styled.div`
    margin-top: 16px;
    color: var(--text-primary-body, #1A232E);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
`;

export const Description = styled.div`
    color: var(--text-secondary-body, #7C94B1);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;
`;
