import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const EventContainer = styled.div``;

export const FilterContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 5px;
    font-family: Manrope-Medium;
`

export const FilterRow = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`