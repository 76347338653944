import styled from 'styled-components';

import { extract } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FooterOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 14px;
  cursor: pointer;
  border-top: 1px solid #DCDFE6;;
`;

export const FooterOptionLabel = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  margin-left: 6px;
`;

export const RFMOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RFMOptionIndicator = styled.div`
  width: 14px;
  min-width: 14px;
  height: 14px;
  background: ${extract('$color')};
  border-radius: 4px;
  margin-right: 6px;
  margin-left: 3px;
`;
