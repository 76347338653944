import { Icon } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { ActionButton } from '@components';

import { Container } from './styled';

export const TableActions = ({ onEdit, onDelete, onCreateWorkflow, onDuplicate, onPreview, onInfo }) => {
  const { p, t } = useTranslation('field_settings');
  return (
    <Container>
      {onEdit && (
        <ActionButton
          onClick={onEdit}
          icon={Icon.NAMES.Edit}
          iconSize={Icon.SIZES.S16}
          testId="edit-action"
          tooltip={t('actions.edit')}
          useDeprecatedIcon={false}
        />
      )}

      {onCreateWorkflow && (
        <ActionButton
          onClick={onCreateWorkflow}
          icon={Icon.NAMES.Edit}
          iconSize={Icon.SIZES.S16}
          testId="create-workflow-action"
          tooltip={p('create_workflow')}
          useDeprecatedIcon={false}
        />
      )}

      {onDuplicate && (
        <ActionButton
          onClick={onDuplicate}
          icon={Icon.NAMES.Copy}
          iconSize={Icon.SIZES.S16}
          testId="duplicate-action"
          tooltip={t('actions.duplicate')}
          useDeprecatedIcon={false}
        />
      )}

      {onDelete && (
        <ActionButton
          onClick={onDelete}
          icon={Icon.NAMES.TrashCan}
          iconSize={Icon.SIZES.S16}
          testId="delete-action"
          tooltip={t('actions.delete')}
          useDeprecatedIcon={false}
        />
      )}
      {onPreview && (
        <ActionButton
          onClick={onPreview}
          icon={Icon.NAMES.View}
          iconSize={Icon.SIZES.S16}
          testId="preview-action"
          tooltip={p('show_preview')}
          useDeprecatedIcon={false}
        />
      )}

      {onInfo && (
        <ActionButton
          onClick={onInfo}
          icon={Icon.NAMES.Information}
          iconSize={Icon.SIZES.S16}
          testId="info-action"
          tooltip={p('show_info')}
          useDeprecatedIcon={false}
        />
      )}
    </Container>
  );
};
