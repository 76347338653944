import React, { useMemo } from 'react';
import { useSelector } from "react-redux";

import { dashboardEmailSummarySelector } from "@store/selectors/dashboard";

import { useTranslation } from "@hooks";

import { Paths } from "@constants";

import { zeroIfNaN } from "@utils";

import { SendingInfo } from "../SendingInfo";

const EmailSendingInfo = () => {
  const { p } = useTranslation('dashboard_page');
  const emailSummary = useSelector(dashboardEmailSummarySelector);

  const emailClicks = useMemo(() => {
    return {
      percentage: zeroIfNaN(emailSummary?.click / emailSummary?.total * 100),
      count: emailSummary?.click
    };
  }, [emailSummary?.click, emailSummary?.total]);

  const emailOpens = useMemo(() => {
    return {
      percentage: zeroIfNaN(emailSummary?.open / emailSummary?.total * 100),
      count: emailSummary?.open
    };
  }, [emailSummary?.open, emailSummary?.total]);

  const sendingInfoContainerStyle = useMemo(() => {
    return { display: 'flex', flex: 1 };
  }, []);

  return (
    <SendingInfo
      testId="dashboard-email-sendings"
      total={emailSummary?.total}
      link={p('emails')}
      clicks={emailClicks}
      to={`${Paths.NOTIFICATIONS}?tab=email`}
      openedCount={emailOpens}
      containerStyle={sendingInfoContainerStyle}
    />
  )
}

export default React.memo(EmailSendingInfo);
