import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { by } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';

import { OperatorSelect, SearchSelect } from '@components';

import { Container } from './styled';

const ValueTypeOptions = [
  {
    value: 'scalar',
    label: 'Value type'
  },
  {
    value: 'variable',
    label: 'Field type'
  },
  {
    value: 'query-variable',
    label: 'Sql query type'
  },
];

const gamePreferenceOptions = [
  {
    value: 'Sport',
    label: 'Sport',
  },
  {
    value: 'Casino',
    label: 'Casino',
  },
];

const LinearSelect = ({ query, onChange, showErrors, appearance }) => {
  const { t } = useTranslation('segments_page');

  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const segmentsOptions = useSelector(optionsSelector);
  const options = customerFields?.filter?.(f => f.type !== 'array').map(f => ({ value: f.field, label: f.field.startsWith('extra') ? `Custom ${f.label}` : f.label }));
  const field = customerFields?.find?.(by('field', query.field));

  const handleFieldChange = (field) => {
    onChange(q => ({ ...q, query: { ...q.query, field, operator: '', value: { value: '', type: 'scalar' } } }));
  };

  const handleTypeChange = (type) => {
    onChange(q => ({ ...q, query: { ...q.query, type: type === 'common' ? field.type : type } }));
  };

  const handleValueChange = (value) => {
    onChange(q => ({ ...q, query: { ...q.query, value: { ...q.query.value, value } } }));
  };

  const handleValueTypeChange = (type) => {
    onChange(q => ({ ...q, query: { ...q.query, value: { ...q.query.value, value: '', type } } }));
  };

  const handleOperatorChange = (operator, type) => {
    onChange(q => ({ ...q, query: { ...q.query, operator, type: type === 'common' ? field.type : type, value: { ...q.query.value, value: '', type: 'scalar' } } }));
  };

  const resolveAdditionalOptions = () => {
    if (query.value.type === 'variable') {
      return options;
    }

    if (query.value.type === 'query-variable') {
      return (segmentsOptions.query_values || []).map(({ id, name }) => ({ value: id, label: name }));
    }

    return null;
  };

  return (
    <Container $appearance={appearance} data-testid={`store-segment-field-linear-container-${query.name}`}>
      <SearchSelect
        testId={`store-segment-field-linear-select-${query.name}`}
        style={{ width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
        options={options}
        label={t('labels.field')}
        value={query?.field}
        tooltipError={showErrors && query.errors?.['field']}
        onChange={handleFieldChange}
      />
      <OperatorSelect
        testId={`store-segment-field-linear-operator-select-${query.name}`}
        disabled={false}
        wrapperStyle={{ marginLeft: appearance !== 'column' ? 6 : 0 }}
        style={{ width: appearance !== 'column' ? 186 : 'auto' }}
        value={{ ...query, valueType: query.value.type, value: query.value.value, errors: showErrors && query.errors }}
        type={query.type}
        autocomplete={query.type === 'numeric' ? undefined : {
          fields: [query.field],
        }}
        field={query.field}
        additionalOptions={resolveAdditionalOptions()}
        additionalOptionsOptions={ValueTypeOptions}
        strictOptions={field?.field === "game_preferences" && gamePreferenceOptions}
        initialType={field?.type}
        initialIsField={query.value.type === 'variable'}
        appearance={appearance}
        fullWidth={appearance === 'column'}
        onTypeChange={handleTypeChange}
        onValueChange={handleValueChange}
        onValueTypeChange={handleValueTypeChange}
        onOperatorChange={handleOperatorChange}
        autoComplete={field?.autocomplete || []}
        labeled
      />
    </Container>
  );
};

export default LinearSelect;
