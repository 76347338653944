const SegmentsRuleTypes = {
  ARRAY: 'array',
  LINEAR_ARRAY: 'array_linear',
  LINEAR: 'linear',
  EVENT: 'event',
  AGGREGATE: 'aggregate',
  FUNNEL: 'funnel',
  RFM_SEGMENT: 'rfm-segment',
  UNSUBSCRIBE_GROUP: 'unsubscribe-group',
};

export default SegmentsRuleTypes;
