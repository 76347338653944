import { useTranslation } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import {
  AggregatesIconSvg,
  LinearIconSvg,
  ArrayIconSvg,
  EventIconSvg,
  SegmentIconSvg,
  RFMSegmentIconSvg,
} from './icons';
import { Container, Image, Title } from './styled';

const resolveImage = (type) => {
  switch (type) {
    case SegmentsRuleTypes.AGGREGATE:
      return <AggregatesIconSvg />;
    case SegmentsRuleTypes.EVENT:
      return <EventIconSvg />;
    case SegmentsRuleTypes.ARRAY:
      return <ArrayIconSvg />;
    case SegmentsRuleTypes.RFM_SEGMENT:
      return <RFMSegmentIconSvg />;
    case 'segment':
      return <SegmentIconSvg />;
    case SegmentsRuleTypes.LINEAR:
    default:
      return <LinearIconSvg />;
  }
};

export const FilterTypeCard = ({ type, testId, ...props }) => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container data-testid={testId} {...props}>
      <Image $type={type}>{resolveImage(type)}</Image>
      <Title>{p(`filter_type_${type}`)}</Title>
    </Container>
  );
};
