import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTemplateStatistics } from '@store/actions/creators';
import { templatesStatisticsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import {
  SmsStatuses,
  EmailStatuses,
  ViberStatuses,
  WebpushStatuses,
  MobilePushStatuses,
  WebhookStatuses,
  WebPopupStatuses,
} from '@constants';
import { ContentCardStatuses } from '@constants/contentCardsStatuses';

import { zeroIfNaN } from '@utils';

import { Modal } from '@components';

import { TemplateStatistics } from './TemplateStatistics';
import { getRatesConfig } from './utils';

export const TemplateStatisticsModal = ({ id, opened, onClose, type }) => {
  const dispatch = useDispatch();
  const { p } = useTranslation('templates');
  const { p: campaignTranslate } = useTranslation('campaigns_page');
  const statistics = useSelector(templatesStatisticsSelector);
  const loading = statistics.loading;
  const s = statistics?.[type] || {};

  useEffect(() => {
    if (!id || !opened) {
      return;
    }

    dispatch(getTemplateStatistics({ template_id: [id], type }));
  }, [id, opened]);

  const statusesConfig = {
    sms: SmsStatuses,
    email: EmailStatuses,
    viber: ViberStatuses,
    web_push: WebpushStatuses,
    mobile_push: MobilePushStatuses,
    webhook: WebhookStatuses,
    message_feed: ContentCardStatuses,
    web_popup: WebPopupStatuses,
  };

  const statusesList = statusesConfig[type];
  const summary = Object.fromEntries(
    Object.values(statusesList)
      .map((status) => {
        const statusData = Object.values(s).find((value) => value.status === status);

        return [status, statusData?.count || 0];
      })
      .concat([['total', s?.total || 0]]),
  );

  const statuses = getRatesConfig(summary?.total, campaignTranslate, summary);

  const ratesItems = statuses?.filter(({ status }) => Object.keys(summary).includes(status));
  const ratesItemsWithAdditionalData = ratesItems?.map((rateItem) => {
    return {
      ...rateItem,
      persentage: zeroIfNaN((rateItem.actual / rateItem.total) * 100),
    };
  });

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={p('template_statistics')}
      statistics={true}
      style={{ maxWidth: '500px' }}
    >
      <TemplateStatistics
        total={summary?.total}
        rates={ratesItemsWithAdditionalData}
        summary={summary}
        loading={loading}
      />
    </Modal>
  );
};
