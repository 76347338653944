import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
    white-space: nowrap;
  ${({ maxWidth }) => maxWidth ? `
    max-width: ${maxWidth}px;
    overflow: hidden;
    text-overflow: ellipsis;
  ` : ''}
  ${styledProp('gray', `
    color: #909399 !important;
    * {
       color: #909399 !important;
    }
  `)}
`;

