import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 67px;
  border-radius: 10px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Neutral-100, #F8FAFE);
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.30);
  flex: 1;
  margin-bottom: 14px;
`;

export const Line = styled.div`
  height: 52px;
  width: 36px;
  border: 1px solid;
  background: transparent;
  border-color: transparent;
  border-left-color: #C1CEFC;
  border-bottom-color: #C1CEFC;
  border-bottom-left-radius: 15px;
  position: absolute;
  left: -36px;
  top: -38px;
  
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 9px;
    height: 9px;
    border: 1px solid #fff;
    background-color: #C1CEFC;
    bottom: -5px;
    right: -5px;
    border-radius: 50%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #D3DBE7;
  padding: 8px 10px 7px 20px;
`;

export const Name = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 14px;
  line-height: 20px;
`;

export const RemoveFilterContainer = styled.div`
  cursor: pointer;

  ${styledProp('$disabled', `
    cursor: not-allowed !important;
    opacity: 40%;
   
  `)}
`;

export const Content = styled.div`
  padding: 7px 10px 10px 10px;
`;
