import { useState } from 'react';

const useTimedFlag = (ms = 330) => {
  const [triggered, setTriggered] = useState(false);

  const trigger = (delay = ms) => {
    setTriggered(true);
    setTimeout(() => setTriggered(false), delay);
  };

  return {
    trigger,
    triggered,
  }
};

export default useTimedFlag;
