import styled, { keyframes, css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: all 330ms ease-in;
  //filter: blur(22px);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  ${styledProp('shown', css`
    //filter: blur(0px);
    opacity: 1;
    transform: scale(1);
  `, css`
    opacity: 0;
    transform: scale(2);
    //filter: blur(20px);
  `)}
`;

const animation = (i) => keyframes`
  0% {
    transform: rotate(${i * 120}deg) translateY(-40px);
  }
  50% {
    transform: rotate(${i * 120 + 180}deg) translateY(-40px);
  }
  100% {
    transform: rotate(${i * 120 + 360}deg) translateY(-40px);
  }
`;

export const LoaderContainer = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  animation: ${({ $i }) => animation($i)} 1.2s linear infinite;
`;

