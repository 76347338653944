const EmailStatuses = {
  SENT: 'sent',
  DROPPED: 'dropped',
  DEFERRED: 'deferred',
  BOUNCE: 'bounce',
  BLOCKED: 'blocked',
  DELIVERED: 'delivered',
  OPEN: 'open',
  PROCESSED: 'processed',
  CLICK: 'click',
  SPAM_REPORT: 'spam_report',
  UNSUBSCRIBED: 'unsubscribed',
  FAILED: 'failed',
  RESUBSCRIBED: 'resubscribed',
};

const EmailStatusLabels = {
  [EmailStatuses.SENT]: 'Sent',
  [EmailStatuses.DROPPED]: 'Dropped',
  [EmailStatuses.DEFERRED]: 'Deferred',
  [EmailStatuses.BOUNCE]: 'Bounce',
  [EmailStatuses.BLOCKED]: 'Blocked',
  [EmailStatuses.DELIVERED]: 'Delivered',
  [EmailStatuses.PROCESSED]: 'Processed',
  [EmailStatuses.OPEN]: 'Open',
  [EmailStatuses.CLICK]: 'Click',
  [EmailStatuses.SPAM_REPORT]: 'Spam report',
  [EmailStatuses.UNSUBSCRIBED]: 'Unsubscribed',
  [EmailStatuses.FAILED]: 'Failed',
  [EmailStatuses.RESUBSCRIBED]: 'Resubscribed',
};

const EmailStatusOptions = [
  ...Object.entries(EmailStatusLabels).map(([value, label]) => ({ value, label }))
];

export { EmailStatuses, EmailStatusLabels, EmailStatusOptions }
