import { Tooltip } from 'antd';

import { usePermissions, useTranslation } from '@hooks';

import { DisabledContainer, NoPermission } from './styled';

const WithPermissions = ({ name, names, children, fallback, hidden, placement = 'top', ...props }) => {
  const permissions = usePermissions();
  const { p } = useTranslation('permissions');

  if (name === undefined && !names?.length) {
    return children;
  }

  const denied = names?.length ? names.find(name => !permissions.allowedProduct(name) || !permissions.allowed(name)) : ((!permissions.allowedProduct(name) || !permissions.allowed(name)) && name);

  if (denied) {
    if (fallback) {
      return fallback;
    }

    if (hidden) {
      return (
        <div style={{ height: '100%', width: '100%', display: 'flex' }} {...props}>
          <NoPermission>{p('you_dont_have_permission', { permission: p(denied) })} {!permissions.allowedProduct(denied) ? p('upgrade_plan') : p('contact_admin')}</NoPermission>
        </div>
      );
    }

    const handleClick = (event) => {
      event.stopPropagation();
    }

    return (
      <Tooltip placement={placement} title={`${p('you_dont_have_permission', { permission: p(denied) })} ${!permissions.allowedProduct(denied) ? p('upgrade_plan') : p('contact_admin')}`}>
        <div style={{ cursor: 'not-allowed' }} {...props}>
          <DisabledContainer onClick={handleClick}>{children}</DisabledContainer>
        </div>
      </Tooltip>
    )
  }

  return children;
}

export default WithPermissions;

