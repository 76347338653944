import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import { archiveViberTemplate, getSingleViber, getViberTemplates } from "@store/actions/creators";

import { Button } from '@velitech/ui';

import { useModalState, useTranslation, useNavigate } from "@hooks";

import { Paths, UserPermissions } from "@constants";

import { notify } from "@utils";

import { Link, NoData, WithPermissions, ViewViberTemplateModal, TemplateStatisticsModal } from "@components";

import { Container } from "./styled";

import LargeTemplateItem from "../../../../../../components/ui/LargeTemplateItem/LargeTemplateItem";
import { RioService } from "../../../../../../services";
import { EmailTemplatesRow, TemplatesHeader, TemplatesInner } from "../../styled";

const ViberSection = ({ viberTemplates = [] }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewViberModal = useModalState();
  const { p, t } = useTranslation('dashboard_page');
  const [statisticViberId, setViberStatisticsId] = useState(null);
  const statisticsViberModal = useModalState();
  const [viberViewItem, setViberViewItem] = useState({});

  const handleCreateViberTemplateClick = () => {
    navigate(`${Paths.TEMPLATES_VIBER}/new?f=default`);
    RioService.track('templates_dash');
  }

  const handleViewViber = (data) => {
    setViberViewItem(data);
    viewViberModal.open();
  }

  const handleEditViber = (id, used) => {
    if (used !== 1) {
      navigate(`${Paths.TEMPLATES_VIBER}/${id}?view=1&f=default`)
    }
  }

  const handleDuplicateViber = (id) => {
    dispatch(getSingleViber({ id,
      onSuccess: () => {
        navigate(`${Paths.TEMPLATES_VIBER}/duplicate?f=default`)
      },
    }));
   
  }

  const deleteViberMenuHandler = () => {

  }

  const handleViberArchive = (id, name) => {
    dispatch(archiveViberTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.viber_template_archived'), name);
        dispatch(getViberTemplates({ page: 1, perPage: 6, all: true }));
      },
    }));
  }

  const handleViberStatisticsClick = (id) => {
    setViberStatisticsId(id);
    statisticsViberModal.open();
  };

  const handleCloseViberEmailModal = () => {
    setViberViewItem({});
    viewViberModal.close();
  };

  const handleViberModalAction = () => {
    handleEditViber(viberViewItem.id);
  };

  return (
    <Container data-testid="dashboard-templates-section-viber">
      <TemplatesInner style={{ marginTop: 10, paddingBottom: 0, pointerEvents: !!viberTemplates?.meta?.total ? 'auto' : 'none', minHeight: '246px' }}>
        <TemplatesHeader>
          <Link to={`${Paths.TEMPLATES_VIBERS}/default`} icon>{p('viber')}</Link>
          <WithPermissions name={UserPermissions.CREATE_VIBER}>
            <Button
              size={'sm'}
              style={{
                padding: '6px 14px',
                height: '26px',
                fontSize: '12px',
                lineHeight: '14px',
                borderRadius: '5px'
              }}
              data-testid="dashboard-templates-section-viber-create"
              width="none"
              onClick={() => handleCreateViberTemplateClick(true)}
            >
              {t('actions.create')}
            </Button>
          </WithPermissions>
        </TemplatesHeader>
        <EmailTemplatesRow $empty={!(!!viberTemplates?.data?.filter(t => t.status !== 3)?.length)}>
          {!(!!viberTemplates.data?.filter(t => t.status !== 3)?.length) && <NoData style={{ height: 'auto', width: 'auto' }} />}
          {viberTemplates.data?.filter(t => t.status !== 3)?.slice(0, 4).map(({
            id,
            name,
            created_at,
            body,
            status,
            btn_name,
            workers,
            used,
            image_url,
          }, i, arr) => (
            <LargeTemplateItem
              key={id}
              type="viber"
              used={used}
              testId={`viber-templates-item-${i}`}
              status={status}
              image_url={image_url}
              name={name}
              title={name}
              btn_name={btn_name}
              date={created_at}
              body={body}
              data={arr[i]}
              fromDashboard
              onArchive={() => handleViberArchive(id, name)}
              onStatisticsClick={() => handleViberStatisticsClick(id)}
              onViewClick={() => handleViewViber({ id, name, body, image_url, btn_name })}
              onEditClick={() => handleEditViber(id, workers?.used)}
              onDuplicateClick={() => handleDuplicateViber(id)}
              onDeleteClick={() => deleteViberMenuHandler(id, workers, name)}
            />
          ))}
        </EmailTemplatesRow>
      </TemplatesInner>
      <ViewViberTemplateModal
        opened={viewViberModal.opened}
        onClose={handleCloseViberEmailModal}
        onActionClick={handleViberModalAction}
        data={viberViewItem}
      />
      <TemplateStatisticsModal
        id={statisticViberId} 
        opened={statisticsViberModal.opened} 
        onClose={statisticsViberModal.close}
        type={'viber'}
      />
    </Container>
  )
}

export default React.memo(ViberSection);
