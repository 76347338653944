const DashboardActionTypes = {
  GET_SMS_SUMMARY: 'dashboard/GET_SMS_SUMMARY',
  GET_SMS_SUMMARY_SUCCESS: 'dashboard/GET_SMS_SUMMARY_SUCCESS',
  GET_SMS_SUMMARY_ERROR: 'dashboard/GET_SMS_SUMMARY_ERROR',

  GET_EMAIL_SUMMARY: 'dashboard/GET_EMAIL_SUMMARY',
  GET_EMAIL_SUMMARY_SUCCESS: 'dashboard/GET_EMAIL_SUMMARY_SUCCESS',
  GET_EMAIL_SUMMARY_ERROR: 'dashboard/GET_EMAIL_SUMMARY_ERROR',

  GET_VIBER_SUMMARY: 'dashboard/GET_VIBER_SUMMARY',
  GET_VIBER_SUMMARY_SUCCESS: 'dashboard/GET_VIBER_SUMMARY_SUCCESS',
  GET_VIBER_SUMMARY_ERROR: 'dashboard/GET_VIBER_SUMMARY_ERROR',

  GET_MOBILE_PUSH_SUMMARY: 'dashboard/GET_MOBILE_PUSH_SUMMARY',
  GET_MOBILE_PUSH_SUMMARY_SUCCESS: 'dashboard/GET_MOBILE_PUSH_SUMMARY_SUCCESS',
  GET_MOBILE_PUSH_SUMMARY_ERROR: 'dashboard/GET_MOBILE_PUSH_SUMMARY_ERROR',

  GET_CONTENT_CARD_SUMMARY: 'dashboard/GET_CONTENT_CARD_SUMMARY',
  GET_CONTENT_CARD_SUMMARY_SUCCESS: 'dashboard/GET_CONTENT_CARD_SUMMARY_SUCCESS',
  GET_CONTENT_CARD_SUMMARY_ERROR: 'dashboard/GET_CONTENT_CARD_SUMMARY_ERROR',

  GET_WEBPUSH_SUMMARY: 'dashboard/GET_WEBPUSH_SUMMARY',
  GET_WEBPUSH_SUMMARY_SUCCESS: 'dashboard/GET_WEBPUSH_SUMMARY_SUCCESS',
  GET_WEBPUSH_SUMMARY_ERROR: 'dashboard/GET_WEBPUSH_SUMMARY_ERROR',

  GET_GENERAL_ACTIVITY_HOURLY: 'dashboard/GET_GENERAL_ACTIVITY_HOURLY',
  GET_GENERAL_ACTIVITY_HOURLY_SUCCESS: 'dashboard/GET_GENERAL_ACTIVITY_HOURLY_SUCCESS',
  GET_GENERAL_ACTIVITY_HOURLY_ERROR: 'dashboard/GET_GENERAL_ACTIVITY_HOURLY_ERROR',

  GET_GENERAL_ACTIVITY_DAILY: 'dashboard/GET_GENERAL_ACTIVITY_DAILY',
  GET_GENERAL_ACTIVITY_DAILY_SUCCESS: 'dashboard/GET_GENERAL_ACTIVITY_DAILY_SUCCESS',
  GET_GENERAL_ACTIVITY_DAILY_ERROR: 'dashboard/GET_GENERAL_ACTIVITY_DAILY_ERROR',

  GET_DATABASE_EFFICIENCY: 'dashboard/GET_DATABASE_EFFICIENCY',
  GET_DATABASE_EFFICIENCY_SUCCESS: 'dashboard/GET_DATABASE_EFFICIENCY_SUCCESS',
  GET_DATABASE_EFFICIENCY_ERROR: 'dashboard/GET_DATABASE_EFFICIENCY_ERROR',

  GET_DATABASE_EFFICIENCY2: 'dashboard/GET_DATABASE_EFFICIENCY2',
  GET_DATABASE_EFFICIENCY2_SUCCESS: 'dashboard/GET_DATABASE_EFFICIENCY2_SUCCESS',
  GET_DATABASE_EFFICIENCY2_ERROR: 'dashboard/GET_DATABASE_EFFICIENCY2_ERROR',

  CHANGE_ANALYTICS_OPTIONS: 'dashboard/CHANGE_ANALYTICS_OPTIONS',
  CHANGE_ANALYTICS_OPTIONS_SUCCESS: 'dashboard/CHANGE_ANALYTICS_OPTIONS_SUCCESS',
  CHANGE_ANALYTICS_OPTIONS_ERROR: 'dashboard/CHANGE_ANALYTICS_OPTIONS_ERROR',

  GET_ANALYTICS_ENTITIES: 'dashboard/GET_ANALYTICS_ENTITIES',
  GET_ANALYTICS_ENTITIES_SUCCESS: 'dashboard/GET_ANALYTICS_ENTITIES_SUCCESS',
  GET_ANALYTICS_ENTITIES_ERROR: 'dashboard/GET_ANALYTICS_ENTITIES_ERROR',
};

export default DashboardActionTypes;
