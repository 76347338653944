const RewardTypes = {
  BONUS: 'bonus',
  FREE_SPIN: 'free-spin',
  FREE_BET: 'free-bet',
  CASHBACK: 'cashback',
  MONEY_REWARD: 'money',
  LOYALTY_POINTS: 'loyalty-point',
  TAG: 'tag-reward',
};

export default RewardTypes;
