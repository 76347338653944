import { useState } from 'react';

import { Dropdown } from 'antd';

import { useTranslation } from '@hooks';

import { Container, Line, Overlay, Option } from './styled';

const AddFilterButton = ({ onSelect, options, disabled }) => {
  const { p } = useTranslation('workflow_page');
  const [opened, setOpened] = useState(false);

  const handleSelect = ({ type, value }) => () => {
    onSelect({ type, value });
    setOpened(false);
  };

  return (
    <Dropdown
      disabled={disabled}
      trigger={['click']}
      open={opened}
      onOpenChange={setOpened}
      overlay={(
        <Overlay>
          {options.map((o, idx) => (
            <Option data-testid={'event-trigger-settings-filter-option-' + idx} key={o.value} onClick={handleSelect(o)}>
              {o.label}
            </Option>
          ))}
        </Overlay>
      )}
    >
      <Container $disabled={disabled} data-testid={'event-trigger-settings-add-filter-btn'}>
        {p('add_filter_plus')}
        <Line />
      </Container>
    </Dropdown>
  );
};

export default AddFilterButton;
