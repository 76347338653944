const SmsStatuses = {
  SENT: 'sent',
  QUEUED: 'queued',
  DELIVERED: 'delivered',
  SENDING: 'sending',
  UNDELIVERED: 'undelivered',
  CLICK: 'click',
  FAILED: 'failed',
};

const SmsStatusLabels = {
  [SmsStatuses.SENT]: 'statuses.sent',
  [SmsStatuses.QUEUED]: 'statuses.queued',
  [SmsStatuses.DELIVERED]: 'statuses.delivered',
  [SmsStatuses.SENDING]: 'statuses.sending',
  [SmsStatuses.UNDELIVERED]: 'statuses.undelivered',
  [SmsStatuses.CLICK]: 'statuses.click',
  [SmsStatuses.FAILED]: 'statuses.failed',
};

const SmsStatusOptions = [
  ...Object.entries(SmsStatusLabels).map(([value, label]) => ({ value, label })),
];

export { SmsStatuses, SmsStatusLabels, SmsStatusOptions };
