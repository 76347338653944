import styled from 'styled-components';

import { switchStyle } from '@utils';

export const PreviewIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  overflow: hidden;
`;

export const Container = styled.div`
  border: 1px solid var(--cards-border, #d3dbe7);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  ${switchStyle('$viewMode', {
    mobile: `
        width: 420px;
        height: 420px;
    `,
    desktop: `
        width: 680px;
        height: 420px;
    `,
  })}
`;

export const Placeholder = styled.p`
  color: var(--text-secondary-body, #7c94b1);
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  text-wrap: nowrap;
`;
