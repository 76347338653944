import { WebPopupContainer, IframPreview, WebPopupContent, Title, Description, WebPopupImg } from './styled';

export const WebPopupPreview = ({ data }) => {
  const isCode = data.type === 'code';
  const image = data?.image?.url || data?.image_url;
  return (
    <WebPopupContainer>
      {isCode && (
        <IframPreview
          srcDoc={data.code}
          scrolling='no'
          allowfullscreen='true'
        />
      )}
      { !isCode && (
        <>
          <WebPopupContent>
            <Title>
              {data?.title}
            </Title>
            <Description>
              {data?.message}
            </Description>
          </WebPopupContent>
          <WebPopupImg src={image} />
        </>
      )}
    </WebPopupContainer>
  );
};
