import React, { useContext } from 'react';
import { getSmoothStepPath, BaseEdge, EdgeLabelRenderer } from 'reactflow';

import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { DirectionModes } from '@constants';

import { WorkflowEditorContext, NodeActionsContext } from '@contexts';

import {
  AddNodeIcon, ConnectionIcon, ConnectionIconActive, CopyIcon
} from '@components/lib/WorkflowEditor/components/DefaultEdge/components/AddNodeButton/AddNodeButton';

import { AddNodeButton } from './components';
import {
  ConnectionSourceContainer,
  Container,
  FirstChildContainer,
  FirstChildLabel,
  RemoveButtonContainer,
  RemoveConnectionContainer
} from './styled';

const style = { stroke: '#748BAD' };

const DefaultEdge = ({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
}) => {
  const { p } = useTranslation('workflow_page');
  const pathOptions = {
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 16,
  };

  if (data.removable) {
    pathOptions.centerX = targetX - 20;
  }

  const [edgePath, labelX, labelY] = getSmoothStepPath(pathOptions);
  const ctx = useContext(WorkflowEditorContext);
  const nodeActions = useContext(NodeActionsContext);

  const handleSelectNode = (actionType, type, position) => {
    if (position) {
      ctx.hideEdgesWhenUpdate();
      return ctx.addNewBranchAfter(source, target, position, actionType, type);
    }

    ctx.addNodeBefore(target, actionType, type);
  };

  const handlePaste = (position) => {
    if (position) {
      return ctx.pasteNewBranch(source, target, position);
    }

    ctx.pasteBefore(target);
  };

  const handleSelectConnectionSource = () => {
    ctx.selectNewConnectionSource(source);
  };

  const handleSelectConnectionTarget = () => {
    ctx.selectNewConnectionTarget(target);
  };

  const handleRemoveEdge = () => {
    ctx.removeEdge(id, source);
  };

  const disabled = !ctx.editable;
  const additionAllowed = ctx.isAdditionBeforeAllowed(target);
  const additionBranchAllowed = ctx.isAdditionBranchAllowed(target);
  const connectionSourceAllowed = ctx.isConnectionSourceAllowed(target);
  const connectionTargetAllowed = ctx.isConnectionTargetAllowed(source, target);
  const firstChildMode = ctx.isOnlyTriggerSelected(source, target);
  const shouldRotate = ctx.hasMultipleChildren(source);
  const firstEdge = ctx.isFirstEdge(source);
  const pasteModeActive = ctx.pasteModeActive;
  const displayConnectionSource = ((connectionSourceAllowed && !ctx.newConnectionSource) || (ctx.newConnectionSource && connectionTargetAllowed));

  const edge = <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />;
  const tooltipPlacement = ctx.directionMode === DirectionModes ? 'right' : 'top';
  const hasOnlyControlSplitter = ctx.state.root?.children?.length === 1;

  if(nodeActions.hideEdge) {
    return <></>
  }
  if (disabled || ctx.isControlGroup(source)) {
    return edge;
  }

  if (pasteModeActive && ctx.pasteBeforeAllowed(target)) {
    return (
      <>
        {edge}
        <EdgeLabelRenderer>
          <Container
            data-testid={`action-copy-${id}`}
            $dm={ctx.directionMode}
            $labelX={targetX}
            $labelY={targetY}
            onClick={() => handlePaste()}
          >
            {CopyIcon}
          </Container>
        </EdgeLabelRenderer>
      </>
    );
  }

  if (firstChildMode) {
    return (
      <>
        {edge}
        <EdgeLabelRenderer>
          <AddNodeButton onSelectNode={handleSelectNode} onCloseDropdown={ctx.hideEdge}>
            <FirstChildContainer
              data-testid={`workflow-add-node-btn-after-node-id-${source}`}
              $dm={ctx.directionMode}
              $labelX={labelX}
              $labelY={ctx.directionMode === DirectionModes.HORIZONTAL ? targetY : labelY}
            >
              {AddNodeIcon}
              <FirstChildLabel>Click to add node</FirstChildLabel>
            </FirstChildContainer>
          </AddNodeButton>
        </EdgeLabelRenderer>
      </>
    );
  }

  if (data.removable) {
    return (
      <>
        <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
        <EdgeLabelRenderer>
          <RemoveConnectionContainer
            $dm={ctx.directionMode}
            $labelX={labelX}
            $labelY={labelY}
          >
            <Tooltip title={p('delete_connection')} placement="right">
              <RemoveButtonContainer data-testid={'workflow-delete-connection-btn'} onClick={handleRemoveEdge}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="28" height="28" fill="#FFF"/>
                  <path d="M12.75 11.5H11.5V19H12.75V11.5Z" fill="#1A232E"/>
                  <path d="M16.5 11.5H15.25V19H16.5V11.5Z" fill="#1A232E"/>
                  <path d="M6.5 7.75V9H7.75V21.5C7.75 21.8315 7.8817 22.1495 8.11612 22.3839C8.35054 22.6183 8.66848 22.75 9 22.75H19C19.3315 22.75 19.6495 22.6183 19.8839 22.3839C20.1183 22.1495 20.25 21.8315 20.25 21.5V9H21.5V7.75H6.5ZM9 21.5V9H19V21.5H9Z" fill="#1A232E"/>
                  <path d="M16.5 5.25H11.5V6.5H16.5V5.25Z" fill="#1A232E"/>
                </svg>
              </RemoveButtonContainer>
            </Tooltip>
          </RemoveConnectionContainer>
        </EdgeLabelRenderer>
      </>
    );
  }

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      {(additionAllowed && !ctx.newConnectionSource) && (
        <EdgeLabelRenderer>
          <Container
            data-testid={`workflow-add-node-btn-after-node-id-${source}`}
            $dm={ctx.directionMode}
            $left={displayConnectionSource && !hasOnlyControlSplitter}
            $labelX={targetX - (25 * +(!!firstEdge && ctx.directionMode === DirectionModes.HORIZONTAL))}
            $labelY={targetY}
          >
            <AddNodeButton
              onSelectNode={handleSelectNode}
              directionMode={ctx.directionMode}
              rotate={shouldRotate}
              addBranchAllowed={additionBranchAllowed}
            >
              {AddNodeIcon}
            </AddNodeButton>
          </Container>
        </EdgeLabelRenderer>
      )}
      {displayConnectionSource && !hasOnlyControlSplitter && (
        <EdgeLabelRenderer>
          <Tooltip title={!ctx.newConnectionSource ? p('connect_to_another_branch') : p('paste_node')} placement={tooltipPlacement}>
            <ConnectionSourceContainer
              data-testid={'workflow-connect-to-another-branch-btn'}
              $dm={ctx.directionMode}
              $labelX={targetX}
              $labelY={targetY}
            >
              {ctx.newConnectionSource ? (
                <ConnectionIconActive
                  onClick={handleSelectConnectionTarget}
                />
              ) : (
                <ConnectionIcon
                  onClick={handleSelectConnectionSource}
                />
              )}
            </ConnectionSourceContainer>
          </Tooltip>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default React.memo(DefaultEdge);
