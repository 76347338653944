import { Navigate } from 'react-router';

import { useCurrentWorkspace } from '@hooks';

import { Paths } from '@constants';

import { hashAppId } from '@utils';

const AppRedirect = () => {
  const app = useCurrentWorkspace();

  if (!app) {
    return <Navigate to={Paths.LOGIN} replace />;
  }

  if(window.location.pathname === '/') {
    return <Navigate to={`/app/${hashAppId(String(app.id))}${window.location.pathname + window.location.search}`} replace />
  }

};

export default AppRedirect;
