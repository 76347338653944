import { isValidElement } from 'react';

import {
  FullScreenLaptop,
  FullScreenMobile,
  FullScreenMobileLandscape,
  FullScreenLandscape,
  FullScreenLaptopLandscape,
  FullScreen,
  Wrapper,
  Content,
  Image,
  IconClose,
  TextContainer,
  Title,
  Description,
} from './styled';

export const CloseSvg = ({ size, layoutType }) => {
  const sizeConfig = {
    small: '9',
    medium: '19',
    'fs-small': '12',
    'fs-medium': '21',
  };

  const width = sizeConfig[size] || 21;

  return (
    <IconClose $layoutType={layoutType} $size={size}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={width}
        viewBox='0 0 19 19'
        fill='none'
      >
        <g opacity='0.5'>
          <path
            d='M9.51877 1.38379C5.0071 1.38379 1.4209 4.96999 1.4209 9.48167C1.4209 13.9933 5.0071 17.5795 9.51877 17.5795C14.0304 17.5795 17.6167 13.9933 17.6167 9.48167C17.6167 4.96999 14.0304 1.38379 9.51877 1.38379ZM9.51877 16.4227C5.7012 16.4227 2.57774 13.2992 2.57774 9.48167C2.57774 5.6641 5.7012 2.54063 9.51877 2.54063C13.3363 2.54063 16.4598 5.6641 16.4598 9.48167C16.4598 13.2992 13.3363 16.4227 9.51877 16.4227Z'
            fill='white'
          />
          <path
            d='M12.6422 13.5306L9.51877 10.4071L6.39531 13.5306L5.46984 12.6051L8.5933 9.48167L5.46984 6.3582L6.39531 5.43273L9.51877 8.55619L12.6422 5.43273L13.5677 6.3582L10.4442 9.48167L13.5677 12.6051L12.6422 13.5306Z'
            fill='white'
          />
        </g>
      </svg>
    </IconClose>
  );
};

export const buttonSizeMap = {
  fullScreenMobile_portrait: 'medium',
  fullScreenMobile_landscape: 'fs-medium',
  fullScreenLaptop_portrait: 'fs-small',
  fullScreenLaptop_landscape: 'fs-small',
  fullScreen_portrait: 'fs-medium',
  fullScreen_landscape: 'fs-medium',
};

export const PreviewContentFullScreen = ({
  type,
  title = '',
  description = '',
  actionButton,
  layout,
  imageUrl,
  placeholder
}) => {
  const componentMap = {
    fullScreenMobile_portrait: FullScreenMobile,
    fullScreenMobile_landscape: FullScreenMobileLandscape,
    fullScreenLaptop_portrait: FullScreenLaptop,
    fullScreenLaptop_landscape: FullScreenLaptopLandscape,
    fullScreen_portrait: FullScreen,
    fullScreen_landscape: FullScreenLandscape,
  };

  const Component = componentMap[type] || 'div';

  const isValidButtons =
    Array.isArray(actionButton?.props?.children) &&
    actionButton?.props?.children?.some((element) => isValidElement(element));
  const hasContent = title?.length > 0 || description?.length > 0;

  const lineClampConfig = {
    'fullScreen_portrait_image-text': isValidButtons ? '7' : '8',
    'fullScreen_landscape_image-text': isValidButtons ? '2' : '3',
    'fullScreenMobile_landscape_image-text': isValidButtons ? '2' : '3',
    'fullScreenMobile_portrait_image-text': isValidButtons ? '8' : '9',
    'fullScreenLaptop_landscape_image-text': isValidButtons ? '2' : '3',
    'fullScreenLaptop_portrait_image-text': isValidButtons ? '6' : '7',
  };

  const layoutType = `${type}_${layout}`;
  return (
    <Component>
      <Wrapper>
        <CloseSvg layoutType={layoutType} size={buttonSizeMap[type]} />
        {!imageUrl && placeholder}
        {imageUrl && <Image $layoutType={layoutType} src={imageUrl} alt='' />}
        <Content $hasContent={layout !== 'image'} $layoutType={layoutType} $type={type}>
          {layout !== 'image' && <TextContainer $hasButtons={isValidButtons} $hasContent={hasContent}>
            <Title>{title}</Title>
            <Description $lineClamp={lineClampConfig[layoutType]} >{description}</Description>
          </TextContainer>}
          {actionButton}
        </Content>
      </Wrapper>
    </Component>
  );
};
