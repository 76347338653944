import { forwardRef, useImperativeHandle, useState } from 'react';

import { Container } from './styled.js';

const Popover = forwardRef(({ duration = 1000, children, ...props }, ref) => {
  const [shown, setShown] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setShown(true);
      setTimeout(() => {
        setShown(false);
      }, duration);
    },
  }));

  return (
    <Container shown={shown} {...props}>
      {children}
    </Container>
  );
})

export default Popover;

