import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  ${styledProp('$hideSidebar', css`
      margin-top: 0 !important;
  `)}
`;

export const PageContent = styled.div`
  overflow-x: hidden;
  width: 100%;
  transition: width 380ms ease;
  height: 100vh;
 ${styledProp('sidebarOpened', css`
      width: calc(100vw - 220px);
    `, css`
      width: calc(100vw - 52px);
    `)} 

  ${styledProp('$hideSidebar', css`
      width: 100vw;
    `)}
  
  & > div {
  }
`;
