import { Container, PreviewIframe, Placeholder } from './styled';

export const CustomCode = ({ viewMode, htmlCode }) => {

  return (
    <Container $viewMode={viewMode}>
      {!htmlCode && <Placeholder>Your pop-up preview will be displayed here</Placeholder>}
      {htmlCode && <PreviewIframe
        srcDoc={htmlCode}
        scrolling='no'
        allowfullscreen='true'
      ></PreviewIframe>}
    </Container>
  );
};
