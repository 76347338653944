import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClickhouseFields } from '@store/actions/creators/fields';
import {
  optionsAggregatesSelector
} from '@store/selectors';
import { clickhouseFieldsSelector, dynamicVariablesSelector } from '@store/selectors/fields';

import uniqBy from 'lodash.uniqby';

import { extract, identity } from '@utils';
import { clickhouseFields2Customer, clickhouseFields2Events } from '@utils/fields';

const discountEntities = [
  {
    name: '{{promo_code}}',
    label: 'Promo code',
  },
  {
    name: '{{discount_value}}',
    label: 'Discount value',
  },
  {
    name: '{{discount_type}}',
    label: 'Discount type',
  },
  {
    name: '{{discount_start_date}}',
    label: 'Discount start date',
  },
  {
    name: '{{discount_end_date}}',
    label: 'Discount end date',
  },
  {
    name: '{{discount_available_tries}}',
    label: 'Discount quantity',
  },
  {
    name: '{{discount_priority}}',
    label: 'Discount priority',
  },
];

const traverseEvents = (events, nestedAggregates, namePrefix = 'payload', labelPrefix = '') => {
  let result = [];

  events.forEach((item) => {
    if (item.payload && item.payload?.length) {
      const newNamePrefix = namePrefix ? `${namePrefix}` : item.field || item.name;
      const newLabelPrefix = labelPrefix ? `${labelPrefix}.${item.label}` : 'Event';
      result = result.concat(traverseEvents(item.payload, newNamePrefix, newLabelPrefix));
    } else {
      const name = namePrefix ? `{{${namePrefix}.${item.field || item.name}}}` : `{{${item.field || item.name}}}`;
      const label = labelPrefix ? `${labelPrefix}.${item.label}` : item.label;
      result.push({ name, label });
    }
    if (item.nested_aggregates?.length) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      result.push(...item.nested_aggregates?.map(item => ({
        name: namePrefix ? `{{${namePrefix}.${item.name}}}` : `{{${item.name}}}`,
        label: labelPrefix ? `${labelPrefix}.${item.label}` : item.label,
      })))
    }
  });

  return uniqBy(result, extract('name'));
};

const useTemplateEntities = () => {
  const dynamicVariables = useSelector(dynamicVariablesSelector);
  const aggregates = useSelector(optionsAggregatesSelector);
  const fieldSettings = useSelector(clickhouseFieldsSelector)?.data || [];
  const fields = clickhouseFields2Customer(fieldSettings).map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const events = clickhouseFields2Events(fieldSettings);
  const eventEntities = (events.data || []).filter(({ payload }) => payload && Object.keys(payload).length);
  const otpEntities = [{
    "name": "{{dynamic.otp_code}}",
    "label": "OTP",
    "kind": "dynamic_otp"
  }]

  const dispatch = useDispatch();
  // const nestedAggregates = useSelector(nestedAggregatesListSelector);

  useEffect(() => {
    // dispatch(listNestedAggregates());
    dispatch(getClickhouseFields());
  }, []);

  const discount = discountEntities.map(e => ({ ...e, kind: 'discount' }));
  const dynamic = dynamicVariables.map(({ name, label }) => ({ name: `{{dynamic.${name}}}`, label, kind: 'dynamic_variable' }));
  const aggregate = aggregates.map(({ name, label }) => ({ name: `{{customer.${name}}}`, label, kind: 'aggregate' }));
  const linear = fields.map(({ key, label }) => ({ name: `{{customer.${key}}}`, label, kind: 'field' }));
  const event = traverseEvents(eventEntities).map(e => ({ ...e, kind: 'event' }));

  const entities = useMemo(() => {
    return [discount, dynamic, otpEntities, aggregate, linear, event].flatMap(identity);
  }, [[discount, dynamic, aggregate, linear, event, otpEntities].flatMap(es => es.map(extract('name'))).join()]);

  return entities;
};

export default useTemplateEntities;
