import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  max-width: 500px;
`;

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export const RowItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const AddFieldButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #31C447;
  border-radius: 7px;
  padding: 10px 0;
  width: 100%;
  font-family: Manrope-Medium;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #31C447;
  user-select: none;
  cursor: pointer;
  
  ${styledProp('$disabled', css`
    cursor: not-allowed;
    opacity: 0.7;
  `)}
`;

export const Labels = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Label = styled.div`
  font-family: Manrope-Medium;
  margin-left: 4px;
  font-size: 13px;
  line-height: 100%;
  width: 100%;
  color: #909399;
`;

export const DeleteIcon = styled.div`
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  flex: 0;
  flex-shrink: 1;
  flex-grow: 0;
`;
