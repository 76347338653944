import styled from 'styled-components';

import { styledProp } from '@utils';
export const Container = styled.div`
  width: 140px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--buttons-big-radius, 7px);
  border: 1px solid var(--buttons-outline-border, #1A232E);
  margin-left: 67px;
  position: relative;
  overflow: visible;
  cursor: pointer;

  ${styledProp('$disabled', `
    cursor: not-allowed;
    color: #7C94B1;
    border: 1px solid var(--buttons-outline-border, #7C94B1) !important;
  `)}
`;

export const Line = styled.div`
  height: 52px;
  width: 32px;
  border: 1px solid;
  background: transparent;
  border-color: transparent;
  border-left-color: #C1CEFC;
  border-bottom-color: #C1CEFC;
  border-bottom-left-radius: 15px;
  position: absolute;
  left: -36px;
  bottom: 13px;
  pointer-events: none;
`;

export const Overlay = styled.div`
  display: flex;
  width: 160px;
  padding: 6px 1px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 7px;
  border: 1px solid var(--select-select-border, #D3DBE7);
  background: var(--input-input-bg, #FFF);
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
  max-height: 300px;
  overflow: scroll;
`;

export const Option = styled.div`
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  transition: all 300ms ease;
  cursor: pointer;
  
  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;
