import { useState } from 'react';
import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { OPERATOR_EQUALS, OPERATOR_MATCHES_RANGE } from '@constants';

import { by, update } from '@utils';
import { clickhouseFields2Events } from '@utils/fields';

import { Input, Switch, Select } from '@components';

import { LogicalOperatorSelect, AddFilterButton, EventSubFilter } from './components';
import {
  Container,
  EventName,
  EventPanel,
  DeleteButtonContainer,
  LogicalOperatorSelectContainer,
  FiltersContainer,
  Line,
  ChangeRuleWarning,
  AddFilterButtonContainer,
  EventRow, CompletionPeriodForm, CompletionPeriodTitle, CompletionPeriod
} from './styled';

const systemEvents = [
  "email",
  "sms",
  "webpush",
  "viber",
  "mobilepush",
  "page_view",
  "session_start",
  "session_first",
  "session_end"
];

const EventIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5006 10.2546V18.6309C15.4074 18.6217 15.3141 18.6141 15.2258 18.6099L15.2142 18.6094H15.2026H13.1367H12.3278L12.6895 19.333L15.2998 24.5547L15.2997 24.5547L15.305 24.5647C15.5049 24.9431 15.4334 25.4505 15.1079 25.7766C15.1077 25.7768 15.1075 25.777 15.1073 25.7772L13.2405 27.6283L13.2403 27.6282L13.2315 27.6375C13.0455 27.8316 12.7822 27.9438 12.4989 27.9438C12.4316 27.9438 12.3616 27.9366 12.2901 27.9217L12.2864 27.9209C11.9293 27.8491 11.6407 27.6049 11.5103 27.2662C11.5101 27.2657 11.5099 27.2652 11.5097 27.2647L8.3842 18.9338L8.26251 18.6094H7.91606H7.44471C7.29146 18.6094 7.1669 18.4848 7.1669 18.3317V17.5539V17.0539H6.6669H4.33347C4.18022 17.0539 4.05566 16.9293 4.05566 16.7762V12.1098C4.05566 11.9567 4.18022 11.8321 4.33347 11.8321H6.6669H7.1669V11.3321V10.5543C7.1669 10.4012 7.29146 10.2766 7.44471 10.2766H15.1948H15.2063L15.2178 10.2761C15.3087 10.2719 15.4048 10.264 15.5006 10.2546Z" fill="#87E2BA" stroke="#0C1015"/>
    <path d="M27.8865 4.18464L27.8863 4.18472L27.8931 4.19563C27.9371 4.26624 27.945 4.30676 27.945 4.33242V24.5535C27.945 24.5788 27.9374 24.6185 27.8951 24.6872C27.8806 24.7095 27.8752 24.7176 27.8699 24.7249C27.833 24.7716 27.7977 24.7962 27.7637 24.8099C27.7522 24.8143 27.7414 24.8189 27.7312 24.8236C27.7073 24.8291 27.6863 24.8313 27.6672 24.8313C27.6429 24.8313 27.6163 24.8274 27.5857 24.8178C27.5739 24.8094 27.559 24.7993 27.5417 24.789L27.5418 24.7888L27.5305 24.7824C27.4911 24.7602 27.4726 24.7439 27.4648 24.7356C27.4493 24.7181 27.434 24.7032 27.42 24.6908C25.0104 21.7836 21.6955 19.8082 18.0557 19.0074V9.88014C21.6911 9.08019 25.0024 7.10713 27.4112 4.20578C27.4212 4.19726 27.4316 4.18781 27.442 4.17736L27.4519 4.16747L27.4612 4.15703C27.4893 4.1257 27.5233 4.10142 27.5665 4.08458L27.5739 4.08342C27.5847 4.08168 27.6008 4.07897 27.6193 4.07507L27.62 4.07492C27.6445 4.06972 27.6661 4.06405 27.6814 4.05988C27.6854 4.06095 27.6899 4.06221 27.6951 4.0637L27.7179 4.07032L27.7413 4.07716C27.7419 4.07735 27.7426 4.07753 27.7432 4.07772C27.7595 4.08256 27.7754 4.08639 27.7905 4.08941C27.8164 4.10476 27.8446 4.12891 27.8745 4.16693L27.8744 4.16697L27.8795 4.17325C27.8802 4.17424 27.8821 4.17728 27.8865 4.18464Z" fill="#87E2BA" stroke="#0C1015"/>
    <path d="M17.8058 9.93164L14.75 10.2194V10.9428V18.6706L17.8058 18.9595V9.93164Z" fill="white" stroke="#0C1015"/>
  </svg>
);

const CompletionPeriodUnitOptions = [
  {
    value: 'minutes',
    label: 'Minutes',
  },
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
];

const OverrideLabels = {
  "nf_notification_id": 'Template',
  "nf_entity_id": 'Workflow version',
  "nf_sub_entity_id": 'Workflow node',
  "nf_event": 'Status',
};

const EventFilter = ({ value, onChange, onDelete, newErrorStructure, smaller, errors, errorType, onClearError, testId, isNodeSettings, customIcon, isFilterSplit, isStepper, disabled }) => {
  const { p } = useTranslation('workflow_page');
  const [isVisible, setIsVisible] = useState(false);
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const events = clickhouseFields2Events(clickhouseFields.data);

  const eventOptions = events.data?.map(({ label, id, name, payload }) => {
    return {
      label,
      name,
      value: String(id),
      system: systemEvents.includes(name),
      payload,
      field: name,
    }
  });

  const event = eventOptions?.find(by('value', String(value.event_id)));

  const eventTitle = event?.label;
  const isSystem = event?.system;
  const fieldOptions = event?.payload?.map?.(({ field, label, type }) => ({ value: field, label: OverrideLabels[field] || label, type })) || [];
  const wasPerformed = !(value.filter_builder?.filters || []).length;

  const handleLogicalOperatorChange = (lo) => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        logicalOperator: lo,
      }
    }));
  }

  const handleDeleteFilter = (index) => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        filters: (s.filter_builder?.filters || []).filter((_, i) => i !== index),
      }
    }));
  };

  const handleAddFilter = ({ value: field, type }) => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        filters: (s.filter_builder?.filters || []).concat({
          field,
          operator: type === 'date' || type === 'datetime' ? OPERATOR_MATCHES_RANGE : OPERATOR_EQUALS,
          type,
          value: { type: 'scalar', value: '' }
        }),
      }
    }));
  };

  const handleDeleteEvent = () => {
    if(!disabled){
      onDelete();
    }
  };

  const handleFilterChange = (index) => (updater) => {
    onChange(s => ({
      ...s,
      filter_builder: {
        ...(s.filter_builder || {}),
        filters: (s.filter_builder?.filters || []).map((f, i) => i === index ? update(f, updater) : f),
      }
    }));
  };

  const handleOperatorClick = () =>{
    if(!disabled){
      setIsVisible(true);
      if (wasPerformed) {
        const timeoutId = setTimeout(() => {
          setIsVisible(false);
        }, 3000);

        return () => clearTimeout(timeoutId);
      }
    }
  };

  const handleCompletionPeriodEnabledChange = (enabled) => {
    onClearError('completion_period');
    onChange(s => ({
      ...s,
      completion_period: enabled ? ({
        type: 'hours',
        quantity: 1,
      }) : null,
    }))
  };

  const handleCompletionPeriodQuantityChange = ({ target: { value } }) => {
    onClearError('completion_period');
    onChange(s => ({
      ...s,
      completion_period: {
        ...s.completion_period,
        quantity: value,
      },
    }));
  };

  const handleCompletionPeriodUnitChange = (type) => {
    onClearError('completion_period');
    onChange(s => ({
      ...s,
      completion_period: {
        ...s.completion_period,
        type,
      },
    }))
  };

  return (
    <Container data-testid={testId} $smaller={smaller}>
      <EventPanel>
        <EventRow>
          <EventName>
            {customIcon || EventIcon}
            {eventTitle}
          </EventName>
          <DeleteButtonContainer $disabled={disabled} data-testid={'event-trigger-delete'} onClick={handleDeleteEvent}>
            <svg opacity={disabled ? '40%' : 1} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.75 7.5H7.5V15H8.75V7.5Z" fill="#F04438"/>
              <path d="M12.5 7.5H11.25V15H12.5V7.5Z" fill="#F04438"/>
              <path d="M2.5 3.75V5H3.75V17.5C3.75 17.8315 3.8817 18.1495 4.11612 18.3839C4.35054 18.6183 4.66848 18.75 5 18.75H15C15.3315 18.75 15.6495 18.6183 15.8839 18.3839C16.1183 18.1495 16.25 17.8315 16.25 17.5V5H17.5V3.75H2.5ZM5 17.5V5H15V17.5H5Z" fill="#F04438"/>
              <path d="M12.5 1.25H7.5V2.5H12.5V1.25Z" fill="#F04438"/>
            </svg>
          </DeleteButtonContainer>
        </EventRow>
        {value.completion_period !== undefined && (
          <CompletionPeriod>
            <CompletionPeriodTitle>
              {p('goal_completion_period')}
              <Switch
                disabled={disabled}
                checked={value.completion_period !== null}
                onChange={handleCompletionPeriodEnabledChange}
              />
            </CompletionPeriodTitle>
            <CompletionPeriodForm>
              <Input
                value={value.completion_period?.quantity}
                error={errors?.('errors')?.completion_period?.quantity || errors?.('errors')?.completion_period?.type}
                onChange={handleCompletionPeriodQuantityChange}
                disabled={value.completion_period === null || disabled}
                style={{ marginBottom: 0 }}
                type="number"
                tooltip="Set the time frame within which a player must complete the specified event after entering the workflow for it to count as a successful goal conversion"
                errorStyle={{ whiteSpace: 'nowrap' }}
              />
              <Select
                options={CompletionPeriodUnitOptions}
                value={value.completion_period?.type}
                error={(errors?.('errors')?.completion_period?.quantity || errors?.('errors')?.completion_period?.type) && ' '}
                onChange={handleCompletionPeriodUnitChange}
                disabled={value.completion_period === null || disabled}
                wrapperStyles={{ width: '100%' }}
                style={{ width: '100%' }}
              />
            </CompletionPeriodForm>
          </CompletionPeriod>
        )}
        <LogicalOperatorSelectContainer onClick={handleOperatorClick}>
          <LogicalOperatorSelect
            disabled={disabled}
            value={value.filter_builder?.logicalOperator}
            onChange={handleLogicalOperatorChange}
            wasPerformed={wasPerformed}
          />
        </LogicalOperatorSelectContainer>
      </EventPanel>
      <FiltersContainer>
        {(value.filter_builder?.filters || []).map((filter, index) => (
          <EventSubFilter
            disabled={disabled}
            key={index}
            isNodeSettings={isNodeSettings}
            isFilterSplit={isFilterSplit}
            isStepper={isStepper}
            testId={`event-filter-item-container-${index}`}
            event={event}
            value={filter}
            index={index}
            newErrorStructure={newErrorStructure}
            errors={() => errors('filters')}
            errorType={errorType}
            onClearError={onClearError}
            isSystem={isSystem}
            required={event?.name === 'bet' && filter.field === 'b_type'}
            light={((value.filter_builder?.filters || []).length === 1) && !value.simplified}
            isHidden={value.hidden}
            isSimplified={value.simplified}
            onChange={handleFilterChange(index)}
            onDelete={handleDeleteFilter}
          />
        ))}
        <Line />
      </FiltersContainer>
      <AddFilterButtonContainer onClick={() => setIsVisible(false)}>
        <AddFilterButton disabled={disabled} options={fieldOptions} onSelect={handleAddFilter}/>
      </AddFilterButtonContainer>
      {wasPerformed && isVisible && (
        <ChangeRuleWarning>
          {p('to_change_the_rule')}
        </ChangeRuleWarning>
      )}
    </Container>
  );
};

export default EventFilter;
