import { useMemo } from 'react';

import { Timezones } from '@constants';

import { Select } from '@components';

const TimezoneSelect = ({ value, onChange, onboard, component = Select, ...props }) => {

  const Component = useMemo(() => component, [component]);

  return (
    <Component
      options={Object.entries(Timezones).map(([value, label]) => ({ value, label }))}
      onboard={onboard}
      {...{ value, onChange, ...props }} />
  );
}

export default TimezoneSelect;

