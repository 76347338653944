import { Row } from './styled';

export const WorkSpaceItem = ({ active, style, addonLeft, addonRight, onClick, children, testId }) => {
  return (
    <Row data-testid={testId} style={style} $active={active} onClick={onClick}>
      {addonLeft}
      {children}
      {addonRight}
    </Row>
  );
};
