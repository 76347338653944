import { TABS } from './constants';

export const tabs = [
  {
    'name': TABS.EMAIL,
    'title': 'labels.email',
  },
  {
    'name': TABS.SMS,
    'title': 'labels.sms',
  },
  {
    'name': TABS.WEBPUSH,
    'title': 'labels.web_push',
  },
  {
    'name': TABS.MOBILE_PUSH,
    'title': 'labels.mobile_push',
  },
  {
    'name': TABS.WEBHOOK,
    'title': 'labels.webhook',
  },
  {
    'name': TABS.VIBER,
    'title': 'labels.viber',
  },
  // {
  //   'name': TABS.PROMO_CODES,
  //   'title': 'labels.discount',
  // },
  // {
  //   'name': TABS.FACEBOOK,
  //   'title': 'labels.facebook_audience',
  // },
  {
    'name': TABS.INCLUDE_CAMPAIGN,
    'title': 'labels.include_campaign',
  },
  {
    'name': TABS.GIVE_REWARD,
    'title': 'labels.give_reward',
  },
  {
    'name': TABS.CONTENT_CARDS,
    'title': 'labels.content_cards',
  },
  {
    'name': TABS.WEB_POPUPS,
    'title': 'labels.web_popups',
  },
];

export const EMAIL_STATIC_COLS_OPTIONS = [
  {
    value: 'customer_id',
    title: 'labels.customer_id',
    locked: true,
  },
  {
    value: 'email',
    title: 'labels.email',
    locked: true,
  },
  // {
  //   value: 'id',
  //   title: 'labels.mail_view',
  //   locked: true,
  // },
  {
    value: 'email_name',
    title: 'labels.template',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.send_date',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.status_id',
    locked: true,
  },
  // {
  //   value: 'id',
  //   title: 'labels.response',
  //   locked: true,
  // },
  {
    value: 'updated_at',
    title: 'labels.updated_at',
    locked: false,
  },
  {
    value: 'group',
    title: 'labels.group',
    locked: false,
  },
];

export const SMS_STATIC_COLS_OPTIONS = [
  {
    value: 'customer_id',
    title: 'labels.customer_id',
    locked: true,
  },
  {
    value: 'number',
    title: 'labels.phone_number',
    locked: true,
  },
  {
    value: 'message',
    title: 'labels.message_text',
    locked: true,
  },
  {
    value: 'sms_name',
    title: 'labels.template',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.send_date',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.status_id',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.response',
    locked: true,
  },
  {
    value: 'updated_at',
    title: 'labels.updated_at',
    locked: false,
  },
];

export const WEBHOOK_STATIC_COLS_OPTIONS = [
  {
    value: 'customer_id',
    title: 'labels.customer_id',
    locked: true,
  },
  {
    value: 'url',
    title: 'labels.url',
    locked: true,
  },
  {
    value: 'body',
    title: 'labels.body',
    locked: true,
  },
  {
    value: 'webhook_name',
    title: 'labels.webhook',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.send_date',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.status_id',
    locked: true,
  },
  {
    value: 'updated_at',
    title: 'labels.updated_at',
    locked: false,
  },
  {
    value: 'group',
    title: 'labels.group',
    locked: false,
  },
];

export const WEBPUSH_STATIC_COLS_OPTIONS = [
  {
    value: 'customer_id',
    title: 'labels.customer_id',
    locked: true,
  },
  {
    value: 'webpush_id',
    title: 'labels.template',
    locked: true,
  },
  {
    value: 'date',
    title: 'labels.date',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.status_id',
    locked: true,
  },
];

export const FACEBOOK_STATIC_COLS_OPTIONS = [
  {
    value: 'customer_id',
    title: 'labels.customer_id',
    locked: true,
  },
  {
    value: 'date',
    title: 'labels.date',
    locked: true,
  },
  {
    value: 'action',
    title: 'labels.action',
    locked: true,
  },
  {
    value: 'audience_id',
    title: 'labels.audience',
    locked: true,
  },
  {
    value: 'status',
    title: 'labels.status',
    locked: true,
  },
];

export const MOBILE_PUSH_STATIC_COLS_OPTIONS = [
  {
    value: 'customer_id',
    title: 'labels.customer_id',
    locked: true,
  },
  {
    value: 'number',
    title: 'labels.phone_number',
    locked: true,
  },
  {
    value: 'webpush_id',
    title: 'labels.template',
    locked: true,
  },
  {
    value: 'date',
    title: 'labels.date',
    locked: true,
  },
  {
    value: 'id',
    title: 'labels.status_id',
    locked: true,
  },
];

export const VIBER_STATIC_COLS_OPTIONS = [
  {
    value: 'customer_id',
    title: 'labels.customer_id',
    locked: true,
  },
  {
    value: 'phone',
    title: 'labels.phone_number',
    locked: true,
  },
  {
    value: 'webpush_id',
    title: 'labels.template',
    locked: true,
  },
  {
    value: 'date',
    title: 'labels.date',
    locked: true,
  },
  {
    value: 'status',
    title: 'labels.status',
    locked: true,
  },
];

export const PROMO_CODES_STATIC_COLS_OPTIONS = [

];

export const selectOptions = [
  { label: 'Sent', value: 'sent' },
  { label: 'Delivered', value: 'delivered' },
  { label: 'Dropped', value: 'dropped' },
]
