const FacebookAudiencesStatuses = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

const FacebookAudiencesStatusLabels = {
  [FacebookAudiencesStatuses.SUCCESS]: 'statuses.success',
  [FacebookAudiencesStatuses.FAILED]: 'statuses.failed',
};

const FacebookAudiencesStatusOptions = [
  ...Object.entries(FacebookAudiencesStatusLabels).map(([value, label]) => ({ value, label }))
];

export { FacebookAudiencesStatusOptions, FacebookAudiencesStatuses, FacebookAudiencesStatusLabels };
