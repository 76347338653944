/* eslint-disable no-mixed-operators */

import { useTranslation } from '@hooks';

import { moment } from '@utils';

import { Container, Row, Title } from './styled.js';

const resolveType = (type) => {
  switch (type) {
    case 0:
      return 'labels.one_off';
    case 1:
      return 'labels.repeating';
    case 2:
      return 'labels.realtime';
    default:
      return type;
  }
};

const formatDate = (date) => {
  return moment(date).format('YYYY.MM.DD hh:mm A');
};

const resolveDay = (day) => {
  return [
    'Sun',
    'Mon',
    'Thue',
    'Wen',
    'Thue',
    'Fr',
    'Sat'
  ][day];
};

const WorkflowSchedule = ({ schedule }) => {
  const { t } = useTranslation();

  if (!schedule) {
    return (
      <Container>
        {t('labels.not_started')}
      </Container>
    );
  }

  return (
    <Container>
      <Title>
        {t(resolveType(schedule.type))}
      </Title>
      {schedule.start_date && (
        <Row green>
          {t('labels.start_at', { at: formatDate(schedule.start_date) })}
        </Row>
      )}
      {schedule.end_date && (
        <Row green>
          {t('labels.finish_at', { at: formatDate(schedule.end_date) })}
        </Row>
      )}
      {(schedule.type === 1) && (
        <Row>
          {t('labels.repeat_at', { at: (schedule.week_day?.map?.(resolveDay)?.join?.() + schedule.active_tab === 'daily' && `Every ${schedule.day} day${schedule.day > 1 ? 's' : ''}` || '') })}
        </Row>
      )}
      {schedule.repeat_time && (
        <Row>
          {t('labels.time_at', { at: moment(schedule.repeat_time).format('hh:mm A') })}
        </Row>
      )}
    </Container>
  );
}

export default WorkflowSchedule;

