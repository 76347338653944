import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  border-radius: 7px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Additional-White, #FFF);
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    border-bottom: 1px solid var(--cards-border, #D3DBE7);
`;

export const Body = styled.div`
    padding: 30px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px 20px;
`;

export const Title = styled.div`
    overflow: hidden;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
`;
