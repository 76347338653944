import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  border-bottom: 1px dashed #31C447;
  width: fit-content;
  font-size: 13px;
  line-height: 120%;
  font-family: Manrope-Medium;

  ${styledProp('isTitle', `
     color: #909399;
     border-bottom: none;
  `)}
`;

