export const Platforms = {
  WINDOWS: 'windows',
  MACOS: 'macos',
  UNIX: 'unix',
  LINUX: 'linux',
  IOS: 'ios',
  ANDROID: 'android',
};

export const getOS = () => {
  if (navigator.appVersion.indexOf("Win") !== -1) {
    return 'windows';
  }
  if (navigator.appVersion.indexOf("Mac") !== -1) {
    return 'macos';
  }
  if (navigator.appVersion.indexOf("X11") !== -1) {
    return 'unix';
  }
  if (navigator.appVersion.indexOf("Linux") !== -1) {
    return 'linux';
  }
}

export const select = (map) => map[getOS()] || map.fallback;
