import { Container } from './styled';

const QuickPickButton = ({ selected, value, onSelect, title, ...props }) => {

  const handleClick = () => {
    onSelect(value);
  };

  return (
    <Container $selected={selected} onClick={handleClick} {...props}>
      {title}
    </Container>
  );
};

export default QuickPickButton;
