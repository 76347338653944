import { getDefaultLang } from '@utils';

import MobilePushPreview from '@res/images/Template previes.png';

import { MobilePushImage, MobpushPreview as MobilePushPreviewComponent } from './styled';

export const MobpushPreview = ({ data }) => {
  const templateData = getDefaultLang(data?.languages) || data;

  return (
    <MobilePushPreviewComponent>
      <MobilePushImage src={templateData.image_url || templateData?.image?.url || MobilePushPreview} />
      <div style={{ padding: '10px 14px' }}>{templateData.data}</div>
    </MobilePushPreviewComponent>
  );
};
