import { useSelector } from 'react-redux';

import { dashboardAnalyticsEntitiesSelector } from '@store/selectors/dashboard';

import { DonutChart } from '@carbon/charts-react';
import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import {
  by,
  findScaleIndicators,
  formatNumberByK,
  removeTrailing0,
  zeroIfNaN
} from '@utils';

import { NoData } from '@components';

import {
  AnalyticsContainer,
  Container,
  TextContainer,
  Title,
  ChartContainer,
  TotalBar,
  PercentageBar,
  Primary,
  Secondary,
  DonutContainer,
  SegmentsChart,
  SegmentBar,
  SegmentNumber,
  SegmentPercentage,
  ScaleIndicator,
  ScaleIndicatorCaption,
} from './styled.js';

import { NoDataContainer } from '../BaseActivitySection/styled';

const CompareContactsChart = ({
  title,
  primary,
  secondary,
  chartColors,
  chartType,
  chartData,
  noData,
  segment,
  containerStyle,
  tooltip,
  double,
}) => {
  const options = useSelector(dashboardAnalyticsEntitiesSelector);
  const { p } = useTranslation('dashboard_page');
  const renderChart = () => {
    if (chartType === 'base') {
      const percentage = (chartData.count / chartData.total * 100);
      const [color] = chartColors;

      return (
        <ChartContainer>
          <TotalBar />
          <PercentageBar percentage={zeroIfNaN(percentage)} color={color} />
        </ChartContainer>
      );
    }

    if (chartType === 'segment') {
      const max = Math.max(...chartData.segments.map(({ customer_count }) => zeroIfNaN(customer_count / chartData.total) * 100));
      const scaleIndicators = findScaleIndicators(max, 100, 3);

      return (
        <SegmentsChart>
          {scaleIndicators.map(({ n, h }) => (
            <ScaleIndicator key={n} $height={h}>
              <ScaleIndicatorCaption>{removeTrailing0(n * 100)}%</ScaleIndicatorCaption>
              <ScaleIndicatorCaption>{removeTrailing0(n * 100)}%</ScaleIndicatorCaption>
            </ScaleIndicator>
          ))}
          {chartData.segments.map(({ id, customer_count }, i, self) => {
            const segment = (options?.segments || []).find(by(id))?.name;

            return (
              <Tooltip key={id} title={`${segment}: ${customer_count} of ${chartData.total}`}>
                <SegmentBar $color={chartColors[segment]} $height={((customer_count / chartData.total * 100) / max)}>
                  {self.length < 5 && (
                    <>
                      <SegmentPercentage $color={chartColors[segment]}>{removeTrailing0(zeroIfNaN(customer_count / chartData.total * 100), 1)}%</SegmentPercentage>
                      <SegmentNumber>{formatNumberByK(customer_count)}</SegmentNumber>
                    </>
                  )}
                </SegmentBar>
              </Tooltip>
            )
          })}
        </SegmentsChart>
      );
    }

    if (chartType === 'funnel') {
      const options = {
        height: '120px',
        width: '120px',
        innerRadius: 50,
        outerRadius: 70,
        legend: {
          enabled: false,
        },
        donut: {
          center: {
            label: `${zeroIfNaN(chartData.completed / chartData.total * 100).toFixed(2)}%`,
            titleFontSize: () => 14,
            titleYPosition: () => 4,
            numberFontSize: () => 0,
          },
        },
        color: {
          scale: {
            [p('not_completed')]: '#DCDFE6',
            [p('not_included')]: '#DCDFE6',
            [p('completed')]: '#31C447'
          }
        },
        pie: {
          labels: {
            formatter: () => null
          }
        },
        toolbar: {
          enabled: false,
        }
      };
      const data = [
        {
          group: segment ? p('not_included') : p('not_completed'),
          value: chartData.total - chartData.completed,
        },
        {
          group: p('completed'),
          value: chartData.completed,
        },
      ];

      return (
        <DonutContainer className="ccc-donut-container">
          <DonutChart options={options} data={data} />
        </DonutContainer>
      );
    }

    return null;
  };

  return (
    <Container data-testid={`dashboard-contacts-chart-container-${chartType}`} style={containerStyle} double={double}>
      {!!tooltip && (
        <Tooltip title={tooltip}>
          <div style={{ position: 'absolute', top: 6, right: 6 }}>
            <svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_6241_313623)">
                <path fillRule="evenodd" clipRule="evenodd" d="M5 0.679688C2.64844 0.679688 0.679688 2.64844 0.679688 5C0.679688 7.35156 2.59375 9.32031 5 9.32031C7.35156 9.32031 9.32031 7.40625 9.32031 5C9.32031 2.64844 7.35156 0.679688 5 0.679688ZM5 8.71875C2.97656 8.71875 1.28125 7.07812 1.28125 5C1.28125 2.92187 2.92187 1.28125 5 1.28125C7.02344 1.28125 8.71875 2.92187 8.71875 5C8.71875 7.07812 7.02344 8.71875 5 8.71875ZM5.21826 4.39841H4.78076C4.72607 4.39841 4.67139 4.39841 4.67139 4.45309V7.13278C4.67139 7.18747 4.72607 7.18747 4.72607 7.18747H5.21826C5.27295 7.18747 5.27295 7.13278 5.27295 7.13278V4.45309C5.27295 4.45309 5.27295 4.39841 5.21826 4.39841ZM4.98401 3.65623C5.217 3.65623 5.40588 3.46735 5.40588 3.23436C5.40588 3.00136 5.217 2.81248 4.98401 2.81248C4.75101 2.81248 4.56213 3.00136 4.56213 3.23436C4.56213 3.46735 4.75101 3.65623 4.98401 3.65623Z" fill="#31C447"/>
              </g>
              <defs>
                <clipPath id="clip0_6241_313623">
                  <rect width="8.75" height="8.75" fill="white" transform="translate(0.625 0.625)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </Tooltip>
      )}
      <Title>{title}</Title>
      <AnalyticsContainer>
        {noData && (
          <NoDataContainer>
            <NoData emptyStyle={{ fontSize: 13 }} style={{ paddingTop: 30, maxWidth: 200 }} description={p('no_customers_in_funnel_short')} />
          </NoDataContainer>
        )}
        {!noData && (
          <>
            <TextContainer>
              <Primary data-testid={`contacts-chart-primary-${chartType}`}>
                {primary}
              </Primary>
              <Secondary data-testid={`contacts-chart-secondary-${chartType}`}>
                {secondary}
              </Secondary>
            </TextContainer>
            {renderChart()}
          </>
        )}
      </AnalyticsContainer>
    </Container>
  );
}

export default CompareContactsChart;

