import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`
export const PieChartTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PieChartTitle = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 28px;
  line-height: 33px;
  color: #303133;
`;

export const PieChartTotalCaption = styled.div`
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #8B8E9F;
`;

export const StatusesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const StatusesLegendRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  min-width: 170px;
  gap: 10px;
`;

export const StatusesLegendValue = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  text-align: right;
  color: #303133;
`;