import { useState } from 'react';

import Tree from '@atlaskit/tree';

import { useTranslation } from '@hooks';

import { flatGroups, flatTree, isFirstInGroup, isNextGroup, isPrevGroup, moveRule } from '@utils';

import {
  LogicalOperatorIndicator,
  Negation
} from '@components/lib/SegmentEditor/components/FiltersPrecedenceTable/styled';

import { GroupBrackets } from './components';
import { Container, DnDIcon, FilterRow, FilterTitle, RuleName, OperatorRow } from './styled';

const resolveOppositeOperator = op => op === 'and' ? 'or' : 'and';

const OrderingTree = ({ query: { query, ...other }, onChange, onLogicalOperatorChange }) => {
  const { p } = useTranslation('segments_page');
  const [anyDragging, setAnyDragging] = useState(false);

  const tree = ({
    rootId: query.name,
    items: flatTree({ query }, query),
  });

  const handleDragEnd = (source, destination) => {
    setAnyDragging(false);

    if (!destination) {
      return;
    }

    onChange(flatGroups(moveRule({ query, ...other }, source, destination)));
  };

  const renderItem = ({ item, provided, depth, snapshot }) => {
    const q = item.data.root;
    const prevGroup = isPrevGroup(tree.items, item.data.name);
    const isFirst = isFirstInGroup(tree.items, item.data.name, { query });

    if (snapshot.isDragging && !anyDragging) {
      setAnyDragging(true);
    }

    if (item.children) {
      return (
        <>
          {!isFirst && (
            <OperatorRow>
              <LogicalOperatorIndicator $blue $dragging={anyDragging} data-testid={`store-segment-scheme-formula-logical-operator-${q.query.name}`} onClick={() => onLogicalOperatorChange(item.data.query)(resolveOppositeOperator(q.query.logicalOperator))}>
                {p(q.query.logicalOperator)}
              </LogicalOperatorIndicator>
              {item.data.query.query.negation && (
                <Negation $dragging={anyDragging} style={{ marginRight: 0, marginLeft: 0 }} data-testid={`store-segment-scheme-formula-negation-${q.query.name}`}>
                  {p('not')}
                </Negation>
              )}
            </OperatorRow>
          )}
          <GroupBrackets
            testId={`store-segment-scheme-ordering-tree-group-brackets-${item.data.name}`}
            item={item}
            provided={provided}
            depth={depth}
            tree={tree}
          />
        </>
      );
    }

    return (
      <>
        {!isFirst && (
          <OperatorRow>
            <LogicalOperatorIndicator $blue $dragging={anyDragging} data-testid={`store-segment-scheme-formula-logical-operator-${q.query.name}`} onClick={() => onLogicalOperatorChange(item.data.query)(resolveOppositeOperator(q.query.logicalOperator))}>
              {p(q.query.logicalOperator)}
            </LogicalOperatorIndicator>
            {item.data.query.query.negation && (
              <Negation $dragging={anyDragging} style={{ marginRight: 0, marginLeft: 0 }} data-testid={`store-segment-scheme-formula-negation-${q.query.name}`}>
                {p('not')}
              </Negation>
            )}
          </OperatorRow>
        )}
        <FilterRow
          data-testid={`store-segment-scheme-ordering-tree-filter-row-container-${item.data.name}`}
          key={item.data.name}
          $depth={depth}
          $nextGroup={isNextGroup(tree.items, item.data.name)}
          $prevGroup={prevGroup}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <FilterTitle data-testid={`store-segment-scheme-ordering-tree-filter-title-${item.data.name}`}>
            <RuleName>{item.data.name}</RuleName> {p(item.data.type)}
          </FilterTitle>
          <DnDIcon data-testid={`store-segment-scheme-ordering-tree-filter-dnd-icon-${item.data.name}`}>
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="17.2339" y="8.95703" width="10.5" height="2.1" rx="0.75" transform="rotate(-180 17.2339 8.95703)" fill="#C4C5C8"/>
              <rect x="17.2339" y="15.1523" width="10.5" height="2.1" rx="0.75" transform="rotate(-180 17.2339 15.1523)" fill="#C4C5C8"/>
            </svg>
          </DnDIcon>
        </FilterRow>
      </>
    );
  };

  return (
    <Container data-testid={`store-segment-scheme-ordering-tree-container`}>
      <Tree
        tree={tree}
        onExpand={() => {}}
        onCollapse={() => {}}
        onDragEnd={handleDragEnd}
        onDragStart={() => {}}
        isDragEnabled
        offsetPerLevel={10}
        renderItem={renderItem}
        getIcon={() => null}
      />
    </Container>
  );
};

export default OrderingTree;
