import styled, { css } from 'styled-components';

import { extract, styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  border-radius: 20px;
  background: #fff;
  width: 95%;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  padding: 16px 12px 16px 12px;
  z-index: 10;
  margin-left: 17px;
  margin-top: 50px;
  
  transition: all 330ms ease;
  
  ${styledProp('$mobilePush', css`
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
  `)}
  
  ${styledProp('$opened', css`
    max-height: 300px;
    align-items: flex-start;
  `, css`
  `)}
`;

export const Footer = styled.div`
  display: flex;
  background: #EEEEEE;
  padding: 0 8px;
  overflow: hidden;
  transition: 330ms ease all;

  ${styledProp('$opened', css`
    opacity: 1;
  `, css`
    opacity: 0;
    height: 0;
  `)}
`;

export const Image = styled.div`
  background: url(${extract('$image')}) center / cover no-repeat;
  width: 100%;
  border-radius: 4px;

  ${styledProp('$opened', css`
    height: 120px;
  `, css`
    height: 0;
  `)}
`;

export const MobilePushButtonsContainer = styled.div`
  z-index: 999999;
  margin-top: 10px;
  //background: transparent;
  display: flex;
  //width: 272px;
`;

export const MobpushButton = styled.div`
  color: #31C447;
  margin-left: 5px;
  font-size: 12px;
  max-width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${styledProp('$first', css`
    margin-left: 0;
  `)}
`;

export const IconImage = styled.div`
  background: url(${extract('image')}) center / contain no-repeat;
  border-radius: 2px;
  width: 38px;
  height: 38px;
  min-height: 38px;
  min-width: 38px;
  margin-top: -18px;
  margin-left: 12px;
  margin-right: 24px;

  ${styledProp('$opened', css`
    width: 0;
    height: 0;
    margin: 0 0 0 0;
    min-width: 0px;
  `, css`
  `)}
`;

export const CompanyContainer = styled.div`
  display: flex;
  margin-bottom: 2px;
  align-items: center;
`;

export const Company = styled.div`
  font-family: unquote("Roboto");
  font-weight: normal;
  font-size: 12px;
  color: #838383;
  margin-right: 8px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
`;

export const Title = styled.div`
  font-family: unquote("Roboto");
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
`;

export const DropdownIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  width: 24px;
  height: 24px;
  background: #F0F0FB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${styledProp('$opened', css`
    transform: translateY(0%);
    top: -6px;
  `, css`
    transform: rotate(180deg) translateY(50%);
  `)}
`;

export const Text = styled.div`
  font-family: unquote("Roboto");
  font-weight: normal;
  font-size: 12px;
  color: #212121;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
`;

export const Button = styled.div`
  font-family: unquote("Roboto");
  font-weight: normal;
  font-size: 12px;
  padding: 10px;
`;

export const NotificationIcon = styled.div`
`;

export const Inner = styled.div`
  position: relative;
  margin-left: 12px;
  width: 100%;

  ${styledProp('$integrated', css`
    width: 85%;
  `)}
`;
