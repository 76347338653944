const RFMAnalyticsActionTypes = {
  GET_RFM_ANALYTICS: 'rfm-analytics/GET',
  GET_RFM_ANALYTICS_SUCCESS: 'rfm-analytics/GET_SUCCESS',
  GET_RFM_ANALYTICS_ERROR: 'rfm-analytics/GET_ERROR',

  CREATE_RFM_ANALYTICS: 'rfm-analytics/CREATE',
  CREATE_RFM_ANALYTICS_SUCCESS: 'rfm-analytics/CREATE_SUCCESS',
  CREATE_RFM_ANALYTICS_ERROR: 'rfm-analytics/CREATE_ERROR',

  EDIT_RFM_ANALYTICS: 'rfm-analytics/EDIT',
  EDIT_RFM_ANALYTICS_SUCCESS: 'rfm-analytics/EDIT_SUCCESS',
  EDIT_RFM_ANALYTICS_ERROR: 'rfm-analytics/EDIT_ERROR',

  EDIT_RFM_ANALYTICS_RANGE: 'rfm-analytics/EDIT_RANGE',
  EDIT_RFM_ANALYTICS_RANGE_SUCCESS: 'rfm-analytics/EDIT_RANGE_SUCCESS',
  EDIT_RFM_ANALYTICS_RANGE_ERROR: 'rfm-analytics/EDIT_RANGE_ERROR',

  RENAME_RFM_ANALYTICS: 'rfm-analytics/RENAME',
  RENAME_RFM_ANALYTICS_SUCCESS: 'rfm-analytics/RENAME_SUCCESS',
  RENAME_RFM_ANALYTICS_ERROR: 'rfm-analytics/RENAME_ERROR',

  GET_RFM_LIST: 'rfm-analytics/GET_LIST',
  GET_RFM_LIST_SUCCESS: 'rfm-analytics/GET_RFM_LIST_SUCCESS',
  GET_RFM_LIST_ERROR: 'rfm-analytics/GET_RFM_LIST_ERROR',

  STOP_RM_ANALYTICS: 'frm-analytics/STOP',
  STOP_RM_ANALYTICS_SUCCESS: 'frm-analytics/STOP_SUCCESS',
  STOP_RM_ANALYTICS_ERROR: 'frm-analytics/STOP_ERROR',

  DELETE_RFM_ANALYTICS: 'rfm-analytics/DELETE',
  DELETE_RFM_ANALYTICS_SUCCESS: 'rfm-analytics/DELETE_SUCCESS',
  DELETE_RFM_ANALYTICS_ERROR: 'rfm-analytics/DELETE_ERROR',

  ARCHIVE_RFM_ANALYTICS: 'rfm-analytics/ARCHIVE',
  ARCHIVE_RFM_ANALYTICS_SUCCESS: 'rfm-analytics/ARCHIVE_SUCCESS',
  ARCHIVE_RFM_ANALYTICS_ERROR: 'rfm-analytics/ARCHIVE_ERROR',

  UNARCHIVE_RFM_ANALYTICS: 'rfm-analytics/UNARCHIVE',
  UNARCHIVE_RFM_ANALYTICS_SUCCESS: 'rfm-analytics/UNARCHIVE_SUCCESS',
  UNARCHIVE_RFM_ANALYTICS_ERROR: 'rfm-analytics/UNARCHIVE_ERROR',
};

export default RFMAnalyticsActionTypes;
