import { Container } from './styled.js';

const InfoText = ({ children, ...props }) => {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
}

export default InfoText;

