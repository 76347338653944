export const EditIconSvg = ({ testId }) => {
  return (
    <div data-testid={testId}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
      >
        <path d='M20.625 17.875H1.375V19.25H20.625V17.875Z' fill='#7C94B1' />
        <path
          d='M17.4625 6.1875C18.0125 5.6375 18.0125 4.8125 17.4625 4.2625L14.9875 1.7875C14.4375 1.2375 13.6125 1.2375 13.0625 1.7875L2.75 12.1V16.5H7.15L17.4625 6.1875ZM14.025 2.75L16.5 5.225L14.4375 7.2875L11.9625 4.8125L14.025 2.75ZM4.125 15.125V12.65L11 5.775L13.475 8.25L6.6 15.125H4.125Z'
          fill='#7C94B1'
        />
      </svg>
    </div>
  );
};
