import { Container, Indicator } from './styled';

const LegendItem = ({ name, style= {}, color }) => {
  return (
    <Container style={style} data-testid={`legend-item-${name}`}>
      <Indicator $c={color} />
      {name}
    </Container>
  );
};

export default LegendItem;
