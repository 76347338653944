import { Container, Row, Title, RowItem, ItemTitle, ItemData } from './styled';

const defaultRenderData = data => {
  try {
    return data;
  } catch (e) {
    console.log("Reward data render error: ", e);
    try {
      return JSON.stringify(data);
    } catch (e) {
      console.log("Reward JSON data render error: ", e);
    }
  }
}

const RewardTemplatePreviewCard = ({
  title,
  rows,
  data,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {rows.map((row, index) => (
        <Row key={index}>
          {row.map(({ title, key, renderData = defaultRenderData, renderTitle = defaultRenderData }) => (
            <RowItem key={index}>
              <ItemTitle>
                {renderTitle(title)}
              </ItemTitle>
              <ItemData>
                {renderData(data?.[key], data) || '---'}
              </ItemData>
            </RowItem>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default RewardTemplatePreviewCard;
