import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 900;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  transition: opacity 290ms ease;
    
  ${styledProp('sidebarOpened', css`
      left: 230px;
    `, css`
      left: 55px;
    `)} 
`;

export const LoaderContainer = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;
    
    ${styledProp('sidebarOpened', css`
        left: calc(50% - 230px);
    `, css`
        left: calc(50% - 55px);
    `)} 
`
