import { useEffect } from 'react';

import debounce from 'lodash.debounce';

import { useCurrentWorkspace } from '@hooks';

import { ViewModes } from '@constants';

import { EMPTY_TEMPLATE_CSS, EMPTY_TEMPLATE_HTML } from '@components/lib/Stripo/baseTemplate';

import { initStripo } from './helpers';
import './styles.css';

const handleScroll = debounce(() => {
  window.dispatchEvent(new Event('resize'));
}, 300);

const Stripo = ({ html, cssDesign, pluginId, secretKey, id, loaded, viewMode, languageCode }) => {
  const app = useCurrentWorkspace();

  useEffect(() => {
    document.querySelector('#page_container')?.addEventListener?.('scroll', handleScroll);

    return () => {
      document.querySelector('#page_container')?.removeEventListener?.('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (html && cssDesign && loaded) {
      initStripo({ appId: app?.id, emailId: id, html, css: cssDesign, pluginId, secretKey, onChange: () => {} });
    } else if (loaded) {
      initStripo({ appId: app?.id, emailId: id, html: EMPTY_TEMPLATE_HTML, css: EMPTY_TEMPLATE_CSS, pluginId, secretKey, onChange: () => {} });
    }
  }, [html, cssDesign, pluginId, secretKey, loaded, id, languageCode]);

  useEffect(() => {
    const iframe = document.querySelector('#stripoPreviewContainer > div > iframe');
    if (!iframe) {
      return;
    }

    if (viewMode === ViewModes.MOBILE) {
      iframe.style.width = '380px';
    } else {
      iframe.style.width = '100%';
    }
  }, [viewMode]);

  return (
    <div className="stripo">
      <div className="stripo__header">
      </div>
      <div className="stripo__content">
        <div id="stripoSettingsContainer">Loading...</div>
        <div id="stripoPreviewContainer"/>
      </div>
    </div>
  );
}

export default Stripo;

