import { CountryIcon, CountryItem, CountryName, DefaultLang, Left } from "./styled"

export const LanguageItem = ({ classNames, icon, name, code, isDefault, onClick }) => {
  return (
    <CountryItem className={classNames} onClick={onClick}>
      <Left>
        <CountryIcon>
          {icon}
        </CountryIcon>
        <CountryName>
          {name} {code && `(${code})`}
        </CountryName>

      </Left>
      {isDefault && <DefaultLang>Default</DefaultLang>}
    </CountryItem>
  )
}