import { ReducerKeys } from '@constants';

import { moment } from '@utils';

import { createSelector } from 'reselect';

const getUserId = store => store[ReducerKeys.USER]?.user?.data?.id;

export const loggedInSelector = createSelector(
  [getUserId, (store) => store[ReducerKeys.USER]?.user?.data],
  (userId, userData) => userData,
);

export const authenticatedSelector = ({ [ReducerKeys.USER]: { authenticated } }) => authenticated;

export const userSelector = createSelector(
  [getUserId, (store) => store[ReducerKeys.USER]?.user?.data],
  (userId, userData) => userData,
);

export const usersSelector = ({ [ReducerKeys.USER]: user }) => user.users;

export const usersLoadingSelector = (store) => store[ReducerKeys.USER].users.loading;

export const allUsersSelector = ({ [ReducerKeys.USER]: user }) => user.allUsers;

export const usersListSelector = ({ [ReducerKeys.USER]: user }) => user.usersList;

export const singleUserSelector = (store) => store[ReducerKeys.USER].singleUser.data;

export const twoFASelector = (store) => store[ReducerKeys.USER].twoFA;

export const recoveryCodesSelector = (store) => store[ReducerKeys.USER].twoFA.recoveryCodes;

export const cachedUsersSelector = (store) => store[ReducerKeys.USER].cachedUsersToInvite;

export const userLoadingSelector = store => store[ReducerKeys.USER].loading;

export const userTrialSelector = store => store[ReducerKeys.USER].user?.data?.isTrial;

export const userTrialEndsAtSelector = store => store[ReducerKeys.USER].user?.data?.ownerTrialEndsAt;

export const isCustomSubscriptionSelector = store => store[ReducerKeys.USER].user?.data?.customSubscription;

export const isSubscribedSelector = store => store[ReducerKeys.USER].user?.data?.subscribed;

export const userDiscountSelector = store => store[ReducerKeys.USER].user?.data?.meta?.coupon;

export const userTrialHasEndedSelector = createSelector(
  userLoadingSelector,
  userTrialEndsAtSelector,
  isCustomSubscriptionSelector,
  isSubscribedSelector,
  (loading, endsAt, isCustomSubscription, isSubscribed) =>
    !loading &&
    (!isCustomSubscription || (moment(endsAt).isBefore(moment()))) &&
    !isSubscribed &&
    (moment(endsAt).isBefore(moment()) || !endsAt)
);

export const userCurrentDiscountSelector = createSelector(
  userDiscountSelector,
  discount => {
    if (!discount) {
      return null;
    }

    switch (discount.coupon_id) {
      case 'trial_second_coupon':
        return moment(discount.start_date).add(6, 'month').isAfter(moment()) && discount;
      default:
        return discount;
    }
  }
);

export const dashboardAnalyticsOptionsSelector = store => store[ReducerKeys.USER].dashboard_analytics_options;

export const accessTokenSelector = store => store[ReducerKeys.USER].access_token;

export const refreshTokenSelector = store => store[ReducerKeys.USER].refresh_token;
