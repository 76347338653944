import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  font-size: 13px;
  line-height: 120%;
  display: inline-flex;
  flex-shrink: 1;
  align-items: flex-start;
  justify-content: flex-start;
  
  ${styledProp('$u', css`
    border-bottom: 1px dashed #31C447;
  `)}

  ${switchStyle('status', {
    Design: css`
      color: #909399;
    `,
    Active: css`
      color: #24993E;
    `,
    'On pause': css`
      color: #24993E;
    `,
    Stop: css`
      color: #909399;
    `,
    Done: css`
      color: #303133;
    `,
  })}
`;

export const Overlay = styled.div`
  border-radius: 7px;
  border: 1px solid var(--stroke, #DCDFE6);
  background: var(--white, #FFF);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.22);
  display: inline-flex;
  padding: 16px 14px 14px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
