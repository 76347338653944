import { useEffect, useRef } from 'react';

const useUpdate = (callback, deps, cbOnInit = false) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      if (cbOnInit) {
        callback();
      }
    } else {
      callback();
    }
  }, [...deps]);
};

export default useUpdate;
