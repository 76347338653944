import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  padding-bottom: 34px;
`;

export const Row = styled.div`
  display: flex;
  
  ${styledProp('single', css`
  `, css`
    & > *:first-child {
      width: 50%;
      padding-right: 20px;
    }

    & > *:last-child {
      width: 50%;
    }
  `)}
`;
