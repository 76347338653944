import { useSelector } from 'react-redux';

import {
  contentCardTemplatesSelector,
  currentContactInfoSelector, integrationsSelector, myIntegrationsSelector,
  rfmAnalyticsSelector, rolesSelector,
  segmentsSelector,
  templateApiRequestSelector,
  templateEmailsSelector,
  templateSingleMobilePushSelector,
  templateSmsSelector,
  templateViberSelector,
  templateWebpushSelector, usersSelector, webPopupsSelector,
  workflowListSelector
} from '@store/selectors';
import { campaignsCalendarSelector } from '@store/selectors/campaigns';
import { funnelsSelector } from '@store/selectors/funnels';

import { extract, identity } from '@utils';

const mapId = arr => arr.map(({ id, ...rest }) => ({ ...rest, id: String(id) }));

const extractTemplates = (store) => {
  return Object.values(store || {}).filter(identity).flatMap(extract('data')).filter(identity);
}

const useBreadcrumbsOptions = () => {
  const workflows = useSelector(workflowListSelector);
  const segments = useSelector(segmentsSelector);
  const emailTemplates = useSelector(templateEmailsSelector);
  const smsTemplates = useSelector(templateSmsSelector);
  const webPushTemplates = useSelector(templateWebpushSelector);
  const mobilePushTemplates = useSelector(templateSingleMobilePushSelector);
  const viberTemplates = useSelector(templateViberSelector);
  const apiRequestTemplates = useSelector(templateApiRequestSelector);
  const contentCardTemplates = useSelector(contentCardTemplatesSelector);
  const webPopupTemplates = useSelector(webPopupsSelector);
  const funnels = useSelector(funnelsSelector);
  const rfm = useSelector(rfmAnalyticsSelector);
  const campaigns = useSelector(campaignsCalendarSelector);
  const currentContact = useSelector(currentContactInfoSelector);
  const users = useSelector(usersSelector);
  const roles = useSelector(rolesSelector);
  const integrations = useSelector(integrationsSelector);
  const myIntegrations = useSelector(myIntegrationsSelector);

  return {
    workflows: mapId(workflows?.data || []),
    segments: mapId(segments?.data || []),
    emailTemplates: mapId(extractTemplates(emailTemplates)),
    smsTemplates: mapId(extractTemplates(smsTemplates)),
    webPushTemplates: mapId(extractTemplates(webPushTemplates)),
    mobilePushTemplates: mapId(extractTemplates(mobilePushTemplates)),
    viberTemplates: mapId(extractTemplates(viberTemplates)),
    apiRequestTemplates: mapId(extractTemplates(apiRequestTemplates)),
    contentCardTemplates: mapId(extractTemplates(contentCardTemplates)),
    webPopupTemplates: mapId(extractTemplates(webPopupTemplates)),
    funnels: mapId(funnels?.data || []),
    rfm: mapId(rfm?.data || []),
    campaigns: mapId(campaigns?.data || []),
    contact: [currentContact.info],
    users: mapId(users.data || []),
    roles: mapId(roles || []),
    integrations: mapId(integrations || []),
    myIntegrations: mapId(myIntegrations.data || []),
  };
};

export default useBreadcrumbsOptions;
