import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: rgba(26, 35, 46, 1);
  margin-bottom: 0;
  font-family: Manrope-Medium;
  word-break: break-word;
  ${styledProp('notSelected', css`
     color: rgba(211, 219, 231, 1);
  `)}
`;
