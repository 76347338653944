import { Icon, ICON_SIZES, ICONS_NAMES, PALETTES, TextButton } from "@velitech/ui";

import { CopyHandle, IconSvg } from "@/components";
import { useTranslation } from "@/hooks";

export const OtpButtons = ({ clearError, errors }) => {
  const { p } = useTranslation('templates');

  return (
    <>
      <CopyHandle
        style={{ marginRight: '10px' }}
        data={"{{dynamic.otp_link}}"}
        copyCallback={() => clearError('otp_code')}
      >
        <TextButton
          outline
          truncate
          color={PALETTES.PRIMARY}
          addonLeft={
            <Icon className={'icon'} name={ICONS_NAMES.Copy} size={ICON_SIZES.S16} color={PALETTES.PRIMARY}/>
          }
          addonRight={
            errors['otp_code'] && <IconSvg name={'warning-filled'} size={16} />
          }
        >
          {p('copy_otp_link')}
        </TextButton>
      </CopyHandle>
      <CopyHandle style={{ marginRight: '10px' }} data={"{{dynamic.otp_code}}"} copyCallback={() => clearError('otp_code')}>
        <TextButton
          outline
          truncate
          color={PALETTES.PRIMARY}
          addonLeft={
            <Icon className={'icon'} name={ICONS_NAMES.Copy} size={ICON_SIZES.S16} color={PALETTES.PRIMARY}/>
          }
          addonRight={
            errors['otp_code'] && <IconSvg name={'warning-filled'} size={16} />
          }
        >
          {p('copy_otp_attribute')}
        </TextButton>
      </CopyHandle>

    </>
  )
}