import { by, extract } from './functions';

export const clickhouseFields2Customer = (fields = []) => {
  return fields
    .filter(by('type', 'customer'))
    .filter(extract('field_type'))
    .map(({ field_type, ...rest }) => ({ ...rest, type: field_type }));
};

export const clickhouseFields2Extra = (fields = []) => {
  const extraField = fields.find(by('field', 'extra'));

  if (!extraField) {
    return [];
  }

  return fields
    .filter(by('parent_field_id', extraField.id))
    .map(({ field_type, ...rest }) => ({ ...rest, type: field_type }));
};

export const clickhouseFields2Events = fields => {
  const rfmEvent = fields.find(by('field', 'rfm'));

  return {
    data: fields
      .filter(by('type', 'event'))
      .filter(({ parent_field_id }) => !parent_field_id)
      .filter(({ field, parent_field_id }) => field !== 'rfm' && parent_field_id !== rfmEvent?.id)
      .map(({ field_type, field, ...rest }) => ({
        ...rest,
        type: field_type,
        name: field,
        payload: fields
          .filter(by('type', 'event'))
          .filter(by('parent_field_id', rest.id))
          .map(({ field_type, ...rest }) => ({ ...rest, type: field_type }))
      }))
  };
};
