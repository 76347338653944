import { Dropdown } from 'antd';

import { zeroIfNaN } from '@utils';

import { Icon } from '@components';

import { Container, Overlay, PerPageOption } from './styled';

const PER_PAGE_OPTIONS = [
  10,
  20,
  50,
  100,
];

const HeaderPagination = ({ page, disablePerPageOptions, onPageChange, perPage, onPerPageChange, style = {}, total, loading, ...props }) => {
  const f = zeroIfNaN((page * perPage) > total ? ((total / perPage).toFixed(0) - 1) * perPage : page * perPage);
  const s = f + perPage > total ? (total || 0) : f + perPage;

  const leftDisabled = zeroIfNaN(page) === 0;
  const rightDisabled = (zeroIfNaN(page) + 1) * perPage >= total;

  const handleLeftClick = () => {
    if (leftDisabled || loading) {
      return;
    }

    onPageChange(zeroIfNaN((page) - 1));
  };

  const handleRightClick = () => {
    if (rightDisabled || loading) {
      return;
    }

    onPageChange(zeroIfNaN(page) + 1);
  };

  const handleOptionClick = (o) => () => {
    const maxPage = (+zeroIfNaN(total / o).toFixed(0));

    if (page > maxPage) {
      onPageChange(maxPage);
    }

    onPerPageChange(o);
  };

  return (
    <Container style={style}>
      <div key={`left-${page}`} onClick={handleLeftClick} >
        <Icon
          data-testid={'pagination-arrow-left'}
          color={leftDisabled ? '#C4C4C4' : '#31C447'}
          name="Back-arrow"
          size={14}
        />
      </div>
      <Dropdown
        disabled={disablePerPageOptions}
        overlay={(
          <Overlay>
            {PER_PAGE_OPTIONS.map(o => (
              <PerPageOption data-testid={`pagination-per-page-option-${o}`} key={o} onClick={handleOptionClick(o)}>
                {o}
              </PerPageOption>
            ))}
          </Overlay>
        )}
        {...props}
      >
        <div style={{ color: '#31C447' }} data-testid={'pagination-per-page-count-dropdown'}>
          {f + 1} - {s}
        </div>
      </Dropdown>
      <div key={`right-${page}`} onClick={handleRightClick}>
        <Icon
          data-testid={'pagination-arrow-right'}
          color={rightDisabled ? '#C4C4C4' : '#31C447'}
          style={{ transform: 'rotate(180deg)', marginRight: '12px' }}
          name="Back-arrow"
          size={14}
        />
      </div>
      {!!total && (<>of {zeroIfNaN(total)?.toLocaleString?.('ru-RU')}</>)}
    </Container>
  );
};

export default HeaderPagination;
