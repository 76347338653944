import { Modal, PreviewTemplate } from '@components';

import { Container } from './styled';

import { showTextContent } from '../../../../../TemplatesScene/pages/CreateWebPopupTemplatePage/module/utils';

const WebPopupPreviewModal = ({ opened, onClose, data }) => {
  const shouldShowTextContent = showTextContent(data);
  const isIframeClasess = data.code ?
    { minWidth: '500px' } : {}

  return (
    <Modal style={isIframeClasess} title={'Template preview'} {...{ opened, onClose }}>
      <Container>
        <PreviewTemplate
          template={data}
          type={data.type}
          viewMode={'mobile'}
          device={false}
          format={data?.device_orientation}
          shouldShowTextContent={shouldShowTextContent}
          imageUrl={data?.image}
        />
      </Container>
    </Modal>
  );
};

export default WebPopupPreviewModal;
