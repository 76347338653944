import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #C8402F;
  border-radius: 6px;
  width: 22px;
  min-width: 22px;
  height: 22px;
  
  ${styledProp('$d', css`
    opacity: .5;
  `)}
`;
