import styled, { css } from 'styled-components';

import { switchStyle } from '@utils';

export const Preview = styled.iframe`
  width: 100%;
  border: none;
  border-radius: 9px 9px 0 0;
  position: absolute;

  ${switchStyle('$type', {
    web_popup: css`
     position: absolute;
      width: 100%;
      height: 136px;

      top: 0;
      left: 0;
      border: none;
    `,
  })}
`;

export const HoverMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  //background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  & > div {
    transition: 330ms ease all;
    transform: scale(0.85);
  }
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  transition: all 330ms ease;
`;

export const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 9px 9px 0 0;
  overflow: hidden;
  background: #F0F2F6;
`;