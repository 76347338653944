import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: start;
    gap: 10px;
`