import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  padding: 0 24px;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #dcdfe6;
`;

export const TableCell = styled.p`
  width: ${({ widthPercent }) => `${widthPercent}%`};
  padding: ${({ withPadding }) => (withPadding ? '0 4px' : '0')};
  margin: 0;
  color: #303133;
  font-size: 13px;
  text-align: ${({ alignEnd }) => (alignEnd ? 'right' : 'left')};
  
  ${styledProp('$header', css`
    color: #909399;
  `)}
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CopyButton = styled.button`
  padding: 0;
  font-size: 13px;
  color: #31C447;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

