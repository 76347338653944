const WorkflowEditorActionTypes = {
  SET_NODES: 'workflow-editor/SET_NODES',
  DISCARD_NEW: 'workflow-editor/DISCARD_NEW',
  DISCARD: 'workflow-editor/DISCARD',
  UPDATE_OPENED_NODE: 'workflow-editor/SET_OPENED_NODE',
  DISCARD_OPENED_NODE: 'workflow-editor/DISCARD_OPENED_NODE',
  SET_CURRENT_VERSION: 'workflow-editor/SET_CURRENT_VERSION',
};

export default WorkflowEditorActionTypes;
