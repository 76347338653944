import { Container, Title, Description } from './styled.js';

export const OptionCard = ({ iconNode, title, description, testId, ...rest }) => {
  return (
    <Container data-testid={testId} {...rest}>
      {iconNode}
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};
