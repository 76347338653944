import { Container } from './styled.js';

const InfoRow = ({ children, padded, style, condition = true }) => {
  if (!condition) {
    return null;
  }

  return (
    <Container padded={padded} style={style}>
      {children}
    </Container>
  );
}

export default InfoRow;

