import styled from 'styled-components';

export const BodyContainer = styled.div`
  min-width: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CopyButton = styled.div`
  border-bottom: 1px solid #109DFB;
  box-sizing: border-box;
  color: #109DFB;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  gap: 5px;
`
export const Container = styled.div`
  
  .minWidth-250 {
    min-width: 250px;
  }
`;
