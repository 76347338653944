import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate as useReactRouterNavigate } from 'react-router';

import {
  listWorkspaces,
  switchWorkspaces,
  changeImportStep,
  getDemoWorkspaces,
  switchToDemoWorkspace,
} from '@store/actions/creators';
import {
  workspacesListSelector,
  loggedInSelector,
  demoWorkspacesSelector,
} from '@store/selectors';

import { Icon, Popover, Divider } from '@velitech/ui';

import { Paths } from '@constants';

import { by, hashAppId } from '@utils';

import { Container, Column } from './styled';
import { WorkSpaceItem } from "./WorkspaceItem";

import { IconSvg } from '../../../../ui/IconSvg/IconSvg';
import { Avatar } from '../../styled';
import { resolveDemoWorkspaceIcon } from '../Popover/WorkspacePopover';
import { WorkspaceAvatar } from '../SidebarHeader/styled';

export const WorkspaceList = () => {
  const dispatch = useDispatch();
  const navigate = useReactRouterNavigate();

  const workspaces = useSelector(workspacesListSelector);
  const user = useSelector(loggedInSelector);
  const demoWorkspaces = useSelector(demoWorkspacesSelector);

  useEffect(() => {
    !workspaces?.length && dispatch(getDemoWorkspaces());
    !workspaces?.length && dispatch(listWorkspaces());
  }, []);

  const handleWorkspaceClick = (id, isDemo) => () => {
    if (id === user?.app?.id) {
      return;
    }

    if (isDemo) {
      return dispatch(
        switchToDemoWorkspace({
          app_id: id,
          onSuccess: () => {
            dispatch(listWorkspaces());
            dispatch(getDemoWorkspaces());
            navigate(`/app/${hashAppId(id)}${Paths.DASHBOARD}`, {
              replace: true,
            });
          },
        })
      );
    }

    dispatch(
      switchWorkspaces({
        id,
        onSuccess: () => {
          navigate(`/app/${hashAppId(id)}${Paths.DASHBOARD}`, {
            replace: true,
          });
          dispatch(changeImportStep({ step: 'upload' }));
        },
      })
    );
  };

  const currentAt = workspaces?.findIndex?.(by('id', user?.app?.id));

  const sorterWorkspaces = [
    ...(workspaces?.[currentAt] ? [workspaces?.[currentAt]] : []),
    ...(workspaces?.filter((_, index) => index !== currentAt) || []),
  ];

  const handleClickWorkspaces = () => {
    navigate(`/app/${hashAppId(currentAt.id)}${Paths.WORKSPACES}`);
  };

  return (
    <>
      <Column data-testid={'sidebar-workspaces-list'}>
        {sorterWorkspaces.map((workspace, index) => {
          return (
            <WorkSpaceItem
              key={index}
              data-testid={`sidebar-workspaces-list-item-${index}`}
              active={user?.app?.id === workspace.id}
              onClick={handleWorkspaceClick(workspace.id)}
              addonLeft={
                <>
                  <WorkspaceAvatar>
                    <Avatar image={workspace.link}>
                      {!user?.app?.link && user?.app?.name[0]}
                    </Avatar>
                  </WorkspaceAvatar>
                </>
              }
            >
              {workspace.name}
            </WorkSpaceItem>
          );
        })}
      </Column>
      <Container>
        <Popover
          noPadding
          offset={0}
          fitTarget
          placement='right-end'
          hover
          contentClassName='popover-inner'
          content={demoWorkspaces?.data?.map?.(
            ({ name, id }, idx) => (
              <WorkSpaceItem
                key={idx}
                testId={'sidebar-demo-workspaces-list-item'}
                onClick={handleWorkspaceClick(id, true)}
                addonLeft={
                  <WorkspaceAvatar>
                    {resolveDemoWorkspaceIcon(name)}
                  </WorkspaceAvatar>
                }
              >
                {name}
              </WorkSpaceItem>
            )
          )}
        >
          <WorkSpaceItem
            testId={'sidebar-demo-workspaces-menu-item'}
            addonLeft={
              <Icon inherit name={'SelectWindow'} size={Icon.SIZES.S18} />
            }
            addonRight={
              <Icon inherit name={'ChevronRight'} size={Icon.SIZES.S18} />
            }
          >
            {'Demo Workspaces'}
          </WorkSpaceItem>
        </Popover>
        <Divider noMargin/>
        <WorkSpaceItem
          testId={'sidebar-manage-workspaces-btn'}
          onClick={handleClickWorkspaces}
          style={{ justifyContent: 'space-between' }}
          addonRight={
            <IconSvg name={'redirect'} size={18} />
          }
        >
          {'Manage Workspaces'}
        </WorkSpaceItem>
      </Container>
    </>
  );
};
