import atob from 'atob';
import pako from 'pako';

export const decompress = base64 => {
  if (!base64) {
    return base64;
  }

  try {
    return new TextDecoder("utf-8").decode(
      pako.inflate(new Uint8Array(atob(base64).split('').map(x => x.charCodeAt(0))))
    );
  } catch (e) {
    console.log('Decompress error:', e, base64);
    return base64;
  }
};
