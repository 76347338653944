import { useModalState, useTranslation } from '@hooks';

import { OrderingTree } from './components';
import {
  Container,
  Title,
  HideButton,
  HideIcon,
  NoData,
} from './styled';

const FiltersPrecedentTable = ({ query: { query, ...other }, headerHidden, onLogicalOperatorChange, onChange }) => {
  const state = useModalState();
  const { p } = useTranslation('segments_page');

  return (
    <Container fixed={headerHidden} data-testid={'store-segment-scheme-container'} $opened={state.opened}>
      <HideButton onClick={state.toggle} $opened={state.opened}>
        <HideIcon $opened={state.opened}>
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.80078 11.0998C6.80078 10.8998 6.70078 10.6998 6.60078 10.5998L3.20078 7.0998L2.30078 5.9998L3.30078 4.89981L6.60078 1.4998C6.70078 1.3998 6.80078 1.1998 6.80078 0.999805C6.80078 0.799805 6.70078 0.599805 6.60078 0.499805C6.40078 0.299805 6.30078 0.299805 6.10078 0.299805C5.90078 0.299805 5.70078 0.399805 5.60078 0.499805L0.300781 5.9998L5.60078 11.5998C5.70078 11.6998 5.80078 11.6998 5.90078 11.7998C6.00078 11.7998 6.10078 11.8998 6.20078 11.8998C6.30078 11.8998 6.40078 11.8998 6.50078 11.7998C6.60078 11.7998 6.70078 11.6998 6.80078 11.5998C6.70078 11.3998 6.80078 11.2998 6.80078 11.0998Z" fill="#31C447"/>
          </svg>
        </HideIcon>
      </HideButton>
      <Title data-testid={'store-segment-scheme-title'} >{p('segment_scheme')}</Title>

      <div style={{ overflowY: 'auto' }}>
        {query.children.length ? (
          <>
            {/*<FormulaRow className="show-scrollbar" data-testid={'store-segment-scheme-formula-row'}>*/}
            {/*  {renderFormula(query)}*/}
            {/*</FormulaRow>*/}
            <OrderingTree onLogicalOperatorChange={onLogicalOperatorChange} query={{ query, ...other }} onChange={onChange} />
          </>
        ) : (
          <NoData>
            {p('segment_scheme_nodata')}
          </NoData>
        )}
      </div>
    </Container>
  )
}

export default FiltersPrecedentTable;
