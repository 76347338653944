import { HistoryNotificationsActionTypes, UserActionTypes, WorkspacesActionTypes } from '../actions/types';

const initialState = {
  emails: {
    loading: false,
  },
  sms: {
    loading: false,
  },
  webhook: {
    loading: false,
  },
  vibers: {
    loading: false,
  },
  mobilePushes: {
    loading: false,
  },
  webpush: {
    loading: false,
  },
  facebook: {
    loading: false,
  },
  promoCodes: {
    loading: false,
  },
  templates: {
    loading: false,
  },
  includeCampaign: {
    loading: false,
  },
  giveReward: {
    loading: false,
  },
  contentCards: {
    loading: false,
  },
  webPopups: {
    loading: false,
  },
  giveRewardTemplates: {
    loading: false,
  },
  includeCampaignTemplates: {
    loading: false,
  },
  counts: {

  },
};

const historyNotificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case HistoryNotificationsActionTypes.GET_NOTIFICATION_HISTORY_COUNT:
      return {
        ...state,
        counts: {
          ...(state.counts || {}),
          [action.meta.entity]: {
            ...(state.counts?.[action.meta.entity] || {}),
            loading: true,
          },
        },
      };
    case HistoryNotificationsActionTypes.GET_NOTIFICATION_HISTORY_COUNT_SUCCESS:
      return {
        ...state,
        counts: {
          ...(state.counts || {}),
          [action.meta.entity]: {
            ...(state.counts?.[action.meta.entity] || {}),
            ...action.payload,
            loading: false,
          },
        },
      };
    case HistoryNotificationsActionTypes.GET_NOTIFICATION_HISTORY_COUNT_ERROR:
      return {
        ...state,
        counts: {
          ...(state.counts || {}),
          [action.meta.entity]: {
            loading: false,
          },
        },
      };
    case HistoryNotificationsActionTypes.GET_EMAIL: {
      return {
        ...state,
        emails: {
          ...state.emails,
          loading: true,
        }
      }
    }
    case HistoryNotificationsActionTypes.GET_SMS:
      return {
        ...state,
        sms: {
          ...state.sms,
          loading: true,
        }
      }
    case HistoryNotificationsActionTypes.GET_WEBHOOK:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          loading: true,
        }
      };
    case HistoryNotificationsActionTypes.GET_WEBPUSH:
      return {
        ...state,
        webpush: {
          ...state.webpush,
          loading: true,
        }
      };
    case HistoryNotificationsActionTypes.GET_TEMPLATES:
      return {
        ...state,
        templates: {
          loading: true,
        }
      };
    case HistoryNotificationsActionTypes.GET_PROMO_CODES:
      return {
        ...state,
        promoCodes: {
          loading: true,
        }
      };
    case HistoryNotificationsActionTypes.GET_PROMO_CODES_SUCCESS:
      return {
        ...state,
        promoCodes: {
          ...payload,
          loading: false,
        }
      };
    case HistoryNotificationsActionTypes.GET_PROMO_CODES_ERROR:
      return {
        ...state,
        promoCodes: {
          loading: false,
        }
      };
    case HistoryNotificationsActionTypes.GET_EMAIL_SUCCESS:
      return {
        ...state,
        emails: { ...payload, loading: false },
      };
    case HistoryNotificationsActionTypes.GET_SMS_SUCCESS:
      return {
        ...state,
        sms: { ...payload, loading: false },
      };
    case HistoryNotificationsActionTypes.GET_WEBHOOK_SUCCESS:
      return {
        ...state,
        webhook: { ...payload, loading: false },
      };
    case HistoryNotificationsActionTypes.GET_WEBPUSH_SUCCESS:
      return {
        ...state,
        webpush: { ...payload, loading: false },
      };
    case HistoryNotificationsActionTypes.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: { ...payload, loading: false },
      };
    case HistoryNotificationsActionTypes.GET_EMAIL_ERROR:
      return {
        ...state,
        email: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_SMS_ERROR:
      return {
        ...state,
        sms: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_MOBILE_PUSHES:
      return {
        ...state,
        vibers: { loading: true },
      };
    case HistoryNotificationsActionTypes.GET_MOBILE_PUSHES_SUCCESS:
      return {
        ...state,
        mobilePushes: { loading: false, ...payload }
      };
    case HistoryNotificationsActionTypes.GET_MOBILE_PUSHES_ERROR:
      return {
        ...state,
        mobilePushes: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_FACEBOOK:
      return {
        ...state,
        facebook: { loading: true },
      };
    case HistoryNotificationsActionTypes.GET_FACEBOOK_SUCCESS:
      return {
        ...state,
        facebook: { loading: false, ...payload }
      };
    case HistoryNotificationsActionTypes.GET_FACEBOOK_ERROR:
      return {
        ...state,
        facebook: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_VIBERS:
      return {
        ...state,
        mobilePushes: { loading: true },
      };
    case HistoryNotificationsActionTypes.GET_VIBERS_SUCCESS:
      return {
        ...state,
        vibers: { loading: false, ...payload }
      };
    case HistoryNotificationsActionTypes.GET_VIBERS_ERROR:
      return {
        ...state,
        vibers: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_INCLUDE_CAMPAIGN:
      return {
        ...state,
        includeCampaign: { loading: true },
      };
    case HistoryNotificationsActionTypes.GET_INCLUDE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        includeCampaign: { loading: false, ...payload }
      };
    case HistoryNotificationsActionTypes.GET_INCLUDE_CAMPAIGN_ERROR:
      return {
        ...state,
        includeCampaign: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_GIVE_REWARD:
      return {
        ...state,
        giveReward: { loading: true },
      };
    case HistoryNotificationsActionTypes.GET_GIVE_REWARD_SUCCESS:
      return {
        ...state,
        giveReward: { loading: false, ...payload }
      };
    case HistoryNotificationsActionTypes.GET_GIVE_REWARD_ERROR:
      return {
        ...state,
        giveReward: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_CONTENT_CARDS:
      return {
        ...state,
        contentCards: { loading: true },
      };
    case HistoryNotificationsActionTypes.GET_CONTENT_CARDS_SUCCESS:
      return {
        ...state,
        contentCards: { loading: false, ...payload }
      };
    case HistoryNotificationsActionTypes.GET_CONTENT_CARDS_ERROR:
      return {
        ...state,
        contentCards: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_WEB_POPUPS:
      return {
        ...state,
        webPopups: { loading: true },
      };
    case HistoryNotificationsActionTypes.GET_WEB_POPUPS_SUCCESS:
      return {
        ...state,
        webPopups: { loading: false, ...payload }
      };
    case HistoryNotificationsActionTypes.GET_WEB_POPUPS_ERROR:
      return {
        ...state,
        webPopups: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_WEBHOOK_ERROR:
      return {
        ...state,
        webhook: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_WEBPUSH_ERROR:
      return {
        ...state,
        webpush: { loading: false }
      };
    case HistoryNotificationsActionTypes.GET_INCLUDE_CAMPAIGN_TEMPLATES:
      return {
        ...state,
        includeCampaignTemplates: {
          ...state.includeCampaignTemplates,
          loading: true,
        },
      };
    case HistoryNotificationsActionTypes.GET_INCLUDE_CAMPAIGN_TEMPLATES_SUCCESS:
      return {
        ...state,
        includeCampaignTemplates: {
          ...state.includeCampaignTemplates,
          data: Object.fromEntries((payload.data || []).map(({ id, ...rest }) => [id, { id, ...rest }])),
          loading: false,
        },
      };
    case HistoryNotificationsActionTypes.GET_INCLUDE_CAMPAIGN_TEMPLATES_ERROR:
      return {
        ...state,
        includeCampaignTemplates: {
          ...state.includeCampaignTemplates,
          loading: false,
        },
      };
    case HistoryNotificationsActionTypes.GET_GIVE_REWARD_TEMPLATES:
      return {
        ...state,
        giveRewardTemplates: {
          ...state.giveRewardTemplates,
          bonuses: {
            ...(state.giveRewardTemplates.bonuses || {}),
            loading: true,
          },
          freeSpins: {
            ...(state.giveRewardTemplates.freeSpins || {}),
            loading: true,
          },
        },
      };
    case HistoryNotificationsActionTypes.GET_GIVE_REWARD_TEMPLATES_SUCCESS:
      return {
        ...state,
        giveRewardTemplates: {
          ...state.giveRewardTemplates,
          [action.meta.reward_type]: {
            ...(state.giveRewardTemplates[action.meta.reward_type] || {}),
            data: Object.fromEntries((payload.data || []).map(({ id, ...rest }) => [id, { id, ...rest }])),
            loading: false,
          },
        },
      };
    case HistoryNotificationsActionTypes.GET_GIVE_REWARD_TEMPLATES_ERROR:
      return {
        ...state,
        giveRewardTemplates: {
          ...state.giveRewardTemplates,
          [action.meta.reward_type]: {
            ...(state.giveRewardTemplates[action.meta.reward_type] || {}),
            loading: false,
          },
        },
      };
    case HistoryNotificationsActionTypes.GET_TEMPLATES_ERROR:
      return {
        ...state,
        templates: { loading: false }
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return { ...state };
  }
};

export default historyNotificationReducer;
