import { DatePicker as ANTDDatePicker } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  font-size: 13px;
  margin-left: 9px;
  
  ${styledProp('$darkLabel', css`
    margin-left: 0;
  `)}
`;

export const Title = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 100%;

  ${styledProp('$darkLabel', css`
  `, css`
    color: #909399;
  `)}
`;

export const StyledDatePicker = styled(ANTDDatePicker)`
  border: 1px solid #D3DBE7;
  ${styledProp('error', css`
    border: 1px solid #C8402F;
  `)}
  border-radius: 7px;
  height: 36px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding: 0 !important;
  margin-bottom: 16px;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  
  ${styledProp('sendCard', css`
    margin-bottom: 0 !important;
  `)}

  & > div {
    padding-left: 11px;
    height: 100%;
  }

  & > div > span {
    width: 37px;
    border-left: 1px solid #D3DBE7;
    height: 100%;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #ECF0F6;
    color: #909399;
  }

  & > div > span > span {
    justify-content: center;
  }

  &:focus {
    box-shadow: none !important;
    border-color: #D3DBE7;
  }
`;
