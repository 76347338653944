import React, { useMemo } from 'react';
import { useSelector } from "react-redux";

import { dashboardViberSummarySelector } from "@store/selectors/dashboard";

import { useTranslation } from "@hooks";

import { Paths } from "@constants";

import { zeroIfNaN } from "@utils";

import { SendingInfo } from "../SendingInfo";

const ViberSendingInfo = () => {
  const { p } = useTranslation('dashboard_page');
  const viberSummaryData = useSelector(dashboardViberSummarySelector);

  const viberSummary = useMemo(() => {
    return {
      total: viberSummaryData?.total || 0,
      click: viberSummaryData?.data?.find(d => d.status === 'click')?.count || 0,
    }
  }, [viberSummaryData?.total, viberSummaryData?.data])

  const sendingInfoContainerStyle = useMemo(() => {
    return { display: 'flex', flex: 1 };
  }, []);

  const viberClicks = useMemo(() => {
    return {
      percentage: zeroIfNaN(viberSummary?.click / viberSummary?.total * 100),
      count: viberSummary?.click
    };
  }, [viberSummary?.click, viberSummary?.total]);

  return (
    <SendingInfo
      total={viberSummary?.total}
      link={p('viber')}
      clicks={viberClicks}
      testId="dashboard-viber-sendings"
      to={`${Paths.NOTIFICATIONS}?tab=viber`}
      containerStyle={sendingInfoContainerStyle}
    />
  )
}

export default React.memo(ViberSendingInfo);
