import styled from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 14px;
  margin-right: 8px;

  ${styledProp('notifications', `
    font-size: 13px !important;
  `)}
`;

export const SwitchContainer = styled.div`
  width: 26px;
  height: 14px;
  border-radius: 7px;
  transition: ease all 280ms;
  position: relative;
  
  ${styledProp('disabled', `
    opacity: 0.7;
  `)}

  ${switchStyle('checked',{
    [true]: `
      background: #19B645;
    `,
    [false]: `
      background: #CCCCCC;
    `
  })}

  ${styledProp('large', `
    width: 34px;
    height: 18px;
    border-radius: 100px !important;
  `)}
  
  ${styledProp('notifications', `
    width: 40px;
    height: 20px;
    border-radius: 100px !important;
  `)}
`;

export const SwitchCircle = styled.div`
  background: #FFFFFF;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  transition: ease all 280ms;
  top: 2px;
  left: 2px;

  ${switchStyle('checked',{
    [true]: `
      transform: translateX(12px);
    `,
    [false]: `
      transform: translateX(0px);
    `
  })}

  ${styledProp('large', `
    width: 14px;
    height: 14px;
  `)}

  ${({ large, checked }) => large && checked && `
  left: 6px;
`}
  ${({ notifications, checked }) => notifications && checked && `
  left: 11px;
`}
  ${styledProp('notifications', `
    top: 3px !important;
  `)}
`;
