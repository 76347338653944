import { useTranslation } from '@hooks';

import { StyledTimePicker, Container, Title, TitleContainer, Error } from './styled';

const TimePicker = ({ title = '', style = {}, error = '', sendCard = false, ...props }) => {
  const { e } = useTranslation();
  return (
    <Container sendCard={sendCard} style={style}>
      {title && (
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
      )}
      <StyledTimePicker placeholder={'Set time'} $error={!!error} {...props} />
      {error && (<Error>{e(error)}</Error>)}
    </Container>
  );
};

export default TimePicker;
