import { useState } from 'react';

import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { formatTranslation } from '@utils';

import OnboardingPreview from '@res/images/getting-started/onboarding-preview.png';

import { Modal } from '@components';

import { Container, Text, StepsText, ImageContainer, Image } from './styled.js';

const WelcomeModal = ({ opened, onClose, userName }) => {
  const [step, setStep] = useState('second');
  const { t, p } = useTranslation('dashboard_page')

  const renderContent = () => {
    if (step === 'first') {
      return (
        <Container>
          <Text data-testid="welcome-title">{formatTranslation(p('welcome_long'), userName)}</Text>
        </Container>
      )
    } else {
      return (
        <Container>
          <ImageContainer>
            <Image image={OnboardingPreview} />
          </ImageContainer>
          <Text>{p('onboarding_assistant')}</Text>
          <StepsText>{p('onboarding_assistant_path')}</StepsText>
        </Container>
      )
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={p('welcome_to_retainly')}
      actions={(
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {step === 'first' ? (<Button onClick={() => setStep('second')} style={{ width: '160px' }}>{t('actions.next')}</Button>) : (<Button onClick={onClose} width={160}>{p('ok_lets_go')}</Button>)}
        </div>
      )}
    >
      {renderContent()}
    </Modal>
  );
}

export default WelcomeModal;

