import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  padding: 46px 105px 44px;
  position: relative;
`;

export const ListContainer = styled.div`
  overflow: hidden;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 43px;
`;

export const Description = styled.div`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  color: var(--text-secondary-body, #7C94B1);

  ${styledProp('$lighter', css`
    color: var(--Colors-Neutral-300, #D3DBE7);
  `)}
`;
