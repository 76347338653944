import { Dropdown } from 'antd';

import { useModalState } from '@hooks';

import info from "@res/images/information_icon_gr.svg";

import { TextOverlay, Info } from '../../styled';

const TextDetails = ({ text }) => {
  const textModal = useModalState(false);

  return (
    <Dropdown
      trigger={['hover']}
      onOpenChange={textModal.toggle}
      // placement="bottomCenter"
      overlay={(
        <TextOverlay>
          {text}
        </TextOverlay>
      )}
    >
      <Info src={info}/>
    </Dropdown>
  );
}

export default TextDetails;
