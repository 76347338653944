import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex: 0;
`;

export const Type = styled.div`
  display: flex;
  flex: 0;
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;
  white-space: nowrap;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
  flex-shrink: 1;
  ${styledProp('$nb', css``, css`
    border-bottom: 1px dashed var(--Colors-Edge-500p, #31C447);
  `)}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  border: 1px solid var(--select-select-border, #D3DBE7);
  background: var(--input-input-bg, #FFF);

  /* dropdown-shadow */
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 14px;
  gap: 10px;
`;
