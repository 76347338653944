import { useContext } from 'react';

import RadioGroupContext from './RadioGroupContext';
import { Container, CircleContainer, Circle, Title } from './styled';

const RadioButton = ({ name, title, children = null, bigger = false, sendCard = false, ...props }) => {
  const context = useContext(RadioGroupContext);
  const selected = context.value === name;
  const disabled = context.disabled;

  const handleChange = () => {
    if (!disabled) {
      context.onChange(name);
    }
  };

  return (
    <>
      <Container sendCard={sendCard} onClick={handleChange} {...props}>
        <CircleContainer>
          <Circle disabled={disabled} selected={selected} />
        </CircleContainer>
        <Title sendCard={sendCard} disabled={disabled && !selected} bigger={bigger}>{title}</Title>
      </Container>
      {children}
    </>
  );
};

export default RadioButton;
