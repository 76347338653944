import React from 'react';

import { NumberInputField } from '@velitech/ui';

import { Select } from '@components';

import { Container } from './styled';

const ActionOptions = [
  {
    label: 'Add',
    value: 'ADD',
  },
  {
    label: 'Subtract',
    value: 'SUBTRACT',
  }
];

const LoyaltyPointsSettings = ({ action, onActionChange, errors, amount, onAmountChange }) => {
  return (
    <Container>
      <Select
        options={ActionOptions}
        value={action}
        label="Action"
        wrapperStyles={{ width: '100%' }}
        onChange={onActionChange}
        error={errors['reward_action']}
      />
      <NumberInputField
        style={{ width: 100 }}
        label="Amount"
        placeholder="Value"
        disableErrorSpaceReserve
        onChange={onAmountChange}
        error={errors['reward_amount']}
        value={amount}
      />
    </Container>
  )
};

export default LoyaltyPointsSettings;
