const AccountActionTypes = {
  GET_SUBSCRIPTION: 'account/GET_SUBSCRIPTION',
  GET_SUBSCRIPTION_SUCCESS: 'account/GET_SUBSCRIPTION_SUCCESS',
  GET_SUBSCRIPTION_ERROR: 'account/GET_SUBSCRIPTION_ERROR',

  SUBSCRIBE: 'account/SUBSCRIBE',
  SUBSCRIBE_SUCCESS: 'account/SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'account/SUBSCRIBE_ERROR',

  GET_DEFAULT_PAYMENT_METHOD: 'account/GET_DEFAULT_PAYMENT_METHOD',
  GET_DEFAULT_PAYMENT_METHOD_SUCCESS: 'account/GET_DEFAULT_PAYMENT_METHOD_SUCCESS',
  GET_DEFAULT_PAYMENT_METHOD_ERROR: 'account/GET_DEFAULT_PAYMENT_METHOD_ERROR',

  UPDATE_PAYMENT_METHOD: 'account/UPDATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_METHOD_SUCCESS: 'account/UPDATE_PAYMENT_METHOD_SUCCESS',
  UPDATE_PAYMENT_METHOD_ERROR: 'account/UPDATE_PAYMENT_METHOD_ERROR',

  SETUP_STRIPE_INTENT: 'account/SETUP_STRIPE_INTENT',
  SETUP_STRIPE_INTENT_SUCCESS: 'account/SETUP_STRIPE_INTENT_SUCCESS',
  SETUP_STRIPE_INTENT_ERROR: 'account/SETUP_STRIPE_INTENT_ERROR',

  GET_CUSTOMER: 'account/GET_CUSTOMER',
  GET_CUSTOMER_SUCCESS: 'account/GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_ERROR: 'account/GET_CUSTOMER_ERROR',

  UPDATE_CUSTOMER: 'account/UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_SUCCESS: 'account/UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_ERROR: 'account/UPDATE_CUSTOMER_ERROR',

  GET_REGULAR_INVOICES: 'account/GET_REGULAR_INVOICES',
  GET_REGULAR_INVOICES_SUCCESS: 'account/GET_REGULAR_INVOICES_SUCCESS',
  GET_REGULAR_INVOICES_ERROR: 'account/GET_REGULAR_INVOICES_ERROR',

  GET_UPCOMING_INVOICES: 'account/GET_UPCOMING_INVOICES',
  GET_UPCOMING_INVOICES_SUCCESS: 'account/GET_UPCOMING_INVOICES_SUCCESS',
  GET_UPCOMING_INVOICES_ERROR: 'account/GET_UPCOMING_INVOICES_ERROR',

  CANCEL_SUBSCRIPTION: 'account/CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_SUCCESS: 'account/CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR: 'account/CANCEL_SUBSCRIPTION_ERROR',

  GET_PRODUCT: 'account/GET_PRODUCT',
  GET_PRODUCT_SUCCESS: 'account/GET_PRODUCT_SUCCESS',
  GET_PRODUCT_ERROR: 'account/GET_PRODUCT_ERROR',

  DOWNLOAD_INVOICE: 'account/DOWNLOAD_INVOICE',
  DOWNLOAD_INVOICE_SUCCESS: 'account/DOWNLOAD_INVOICE_SUCCESS',
  DOWNLOAD_INVOICE_ERROR: 'account/DOWNLOAD_INVOICE_ERROR',

  GET_QUANTITY_LOG: 'account/GET_QUANTITY_LOG',
  GET_QUANTITY_LOG_SUCCESS: 'account/GET_QUANTITY_LOG_SUCCESS',
  GET_QUANTITY_LOG_ERROR: 'account/GET_QUANTITY_LOG_ERROR',

  RESUME_SUBSCRIPTION: 'account/RESUME_SUBSCRIPTION',
  RESUME_SUBSCRIPTION_SUCCESS: 'account/RESUME_SUBSCRIPTION_SUCCESS',
  RESUME_SUBSCRIPTION_ERROR: 'account/RESUME_SUBSCRIPTION_ERROR',

  GET_APPS_CONTACTS_COUNT: 'account/GET_APPS_CONTACTS_COUNT',
  GET_APPS_CONTACTS_COUNT_SUCCESS: 'account/GET_APPS_CONTACTS_COUNT_SUCCESS',
  GET_APPS_CONTACTS_COUNT_ERROR: 'account/GET_APPS_CONTACTS_COUNT_ERROR',

  GET_ACCOUNT_METRICS: 'account/GET_METRICS',
  GET_ACCOUNT_METRICS_SUCCESS: 'account/GET_METRICS_SUCCESS',
  GET_ACCOUNT_METRICS_ERROR: 'account/GET_METRICS_ERROR',

  GET_SENDING_LIMIT_METRICS: 'account/GET_SENDING_LIMIT_METRICS',
  GET_SENDING_LIMIT_METRICS_SUCCESS: 'account/GET_SENDING_LIMIT_METRICS_SUCCESS',
  GET_SENDING_LIMIT_METRICS_ERROR: 'account/GET_SENDING_LIMIT_METRICS_ERROR',
};

export default AccountActionTypes;
