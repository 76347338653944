export const CloseIconSvg = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='26' height='26' rx='13' fill='#7C94B1' fillOpacity='0.12' />
      <path
        d='M17 9.7L16.3 9L13 12.3L9.7 9L9 9.7L12.3 13L9 16.3L9.7 17L13 13.7L16.3 17L17 16.3L13.7 13L17 9.7Z'
        fill='#7C94B1'
      />
    </svg>
  );
};
