import { by, resolveAB } from '@utils';

import { Container, StepContainer, Label } from './styled.js';

const whiteTheme = resolveAB() === 'registration.white';

const StepperTabs = ({ steps, current, style }) => {
  return (
    <Container style={style}>
      {steps.map(({ label }, index) => {
        const indexOfCurrent = steps.findIndex(by('name', current));
        const curr = indexOfCurrent === index;
        const completed = indexOfCurrent > index;

        return (
          <StepContainer key={label} current={curr} completed={completed} whiteTheme={resolveAB() === 'registration.white'}>
            {completed && (
              <svg style={{ marginBottom: 5 }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="7" fill="#24993E"/>
                <path d="M10.5879 4.38417C10.3609 4.16967 10.005 4.17293 9.78201 4.39154L5.60685 8.48458L4.26852 7.1743C4.0471 6.95753 3.69362 6.95554 3.46979 7.16982C3.23183 7.39763 3.23393 7.77851 3.47439 8.00368L4.91551 9.35316C5.30325 9.71625 5.90721 9.71281 6.2908 9.34534L10.5908 5.22608C10.8307 4.99624 10.8294 4.61239 10.5879 4.38417Z" fill="white"/>
              </svg>
            )}
            <Label current={curr} whiteTheme={whiteTheme && completed} completed={completed}>{label}</Label>
          </StepContainer>
        );
      })}
    </Container>
  );
}

export default StepperTabs;

