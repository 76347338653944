import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { updateRedirects } from '@store/actions/creators';
import { workflowEditorSetOpenedNode } from '@store/actions/creators/workflowEditor';
import { WorkflowActionTypes } from '@store/actions/types';

import { useTranslation, useNavigate } from '@hooks';

import { AppRedirects, Paths } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { diffFields, identity, removeAppFromPath, testId, zeroIfNaN } from '@utils';

import { WorkflowEditorContext } from '@contexts';

import { ActionButton, Input, SearchSelect, Switch, Textarea } from '@components';
import {
  FooterOption,
  FooterOptionLabel
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/WebpushNodeSettings/styled';

import currencies from './currencies.json';
import { Container, Row, Divider } from './styled';

import { NodeSettingsAccordion } from '../../../NodeSettingsAccordion';

const sourceOptions = [
  {
    value: 1,
    label: 'labels.webhook',
  },
  {
    value: 0,
    label: 'labels.url'
  }
];

const periodTypeOptions = [
  {
    value: 0,
    label: 'labels.till_end_of_day',
  },
  {
    value: 1,
    label: 'labels.custom_settings',
  }
];

const durationUnitOptions = [
  {
    value: 'hours',
    label: 'labels.hours',
  },
  {
    value: 'days',
    label: 'labels.days',
  },
];

const discountTypeOptions = [
  {
    value: 0,
    label: 'labels.percent',
  },
  {
    value: 1,
    label: 'labels.unit',
  },
];

const requestTypeOptions = [
  {
    value: 'POST',
    label: 'POST',
  },
  {
    value: 'GET',
    label: 'GET',
  },
  {
    value: 'PUT',
    label: 'PUT',
  },
  {
    value: 'DELETE',
    label: 'DELETE',
  },
];

const currenciesOptions = currencies.map(({ name, symbol, code }) => ({ label: `${symbol} ${name}`, value: code }));

const PushDiscountNodeSettings = ({
  value,
  label,
  description,
  onChange,
  options,
  onLabelChange,
  onDescriptionChange,
  errors,
  onClearError,
  compareWithValue,
}) => {
  const { t, p } = useTranslation('workflow_page');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useContext(WorkflowEditorContext);
  const [promoCode, setPromoCode] = useState(!!value.promo_code_id);
  const [priority, setPriority] = useState(!!value.priority);
  const search = useLocation().search;
  const worker = new URLSearchParams(search).get('w');

  useEffect(() => {
    if (!promoCode && value.promo_code_id) {
      setPromoCode(true);
    }
  }, [value.promo_code_id, promoCode]);

  useEffect(() => {
    if (value.api_request_id && value.type !== 1) {
      setTimeout(() => {
        onChange(s => ({ ...s, type: 1 }))
      }, 500);
    }
  }, [value.api_request_id, value.type])

  const handleChange = (name, extractor = ({ target: { value } }) => value) => (event) => {
    onClearError(name);
    onChange(s => ({ ...s, [name]: extractor(event) }));
  };

  const handleInputChange = cb => ({ target: { value } }) => {
    cb(value);
  };

  const handlePromoCodeEnabledChange = (enabled) => {
    setPromoCode(enabled);
    if (!enabled) {
      onChange(s => ({ ...s, promo_code_id: null }));
    }
  };

  const resolveDurationUnitMultiplier = (unit) => {
    return unit === 'days' ? 60 * 24 : 60;
  }

  const handleDurationChange = ({ target: { value } }) => {
    onClearError('duration');
    onChange(s => ({ ...s, duration: resolveDurationUnitMultiplier(s.duration_unit) * value }));
  };

  const handleDurationUnitChange = (duration_unit) => {
    onClearError('duration_unit');
    onChange(s => ({ ...s, duration_unit, duration: resolveDurationUnitMultiplier(duration_unit) / resolveDurationUnitMultiplier(s.duration_unit) * s.duration }));
  };

  const promoCodeOptions = options?.promo_code?.map(({ id, name }) => ({ label: name, value: id }));
  const apiRequestOptions = options?.[TemplateTypes.WEBHOOK]?.map(({ id, name }) => ({ label: name, value: id }));

  const diff = compareWithValue ? (diffFields(value, compareWithValue) || {}) : {};

  const handleCreateNewApiRequest = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.API_REQUEST_TEMPLATE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'api_request_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.TEMPLATES_API_REQUEST}/new`);
  };

  const handleCreateNewPromoCode = () => {
    editor.saveCachedNodes(worker || 'new');
    dispatch(updateRedirects({
      [AppRedirects.PROMO_CODE_CREATION]: {
        to: removeAppFromPath(window.location.pathname + window.location.search),
        updateAction: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER_NODE,
        updateMeta: { node: value.id, worker: worker || 'new' },
        mapConfig: [['id', 'promo_code_id']],
      }
    }));
    dispatch(workflowEditorSetOpenedNode({ id: value.id }));
    navigate(`${Paths.PROMO_CODES}?create=1`);
  };

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <>
            <SearchSelect
              {...testId('push-discount-node-settings-required-settings-source')()}
              highlight={diff['type'] === false}
              error={errors['type']}
              absoluteError
              errorStyles={{ top: 56 }}
              style={{ width: '100%', marginBottom: 16 }}
              value={value.type}
              options={sourceOptions}
              getPopupContainer={t => t.parentElement.parentElement.parentElement}
              label={t('labels.source')}
              onChange={handleChange('type', identity)}
            />
            {+value.type === 1 && (
              <SearchSelect
                {...testId('push-discount-node-settings-required-webhook')()}
                highlight={diff['api_request_id'] === false}
                error={errors['api_request_id']}
                style={{ width: '100%', marginBottom: 16, position: 'relative' }}
                value={value.api_request_id}
                errorStyle={{ bottom: -22 }}
                options={apiRequestOptions}
                getPopupContainer={t => t.parentElement.parentElement.parentElement}
                title={t('labels.webhook_templates')}
                onChange={handleChange('api_request_id', identity)}
                footerOption={(
                  <FooterOption onClick={handleCreateNewApiRequest}>
                    <ActionButton {...testId('api-request-node-settings-required-template-add')()} size={22} icon="Plus-icon" />
                    <FooterOptionLabel>
                      {p('create_template')}
                    </FooterOptionLabel>
                  </FooterOption>
                )}
              />
            )}
            {+value.type === 0 && (
              <>
                <Input
                  {...testId('push-discount-node-settings-required-settings-url')()}
                  highlight={diff['url'] === false}
                  error={errors['url']}
                  value={value.url}
                  onChange={handleChange('url')}
                  title={t('labels.url')}
                  tooltip={p('url_description')}
                />
                <SearchSelect
                  {...testId('push-discount-node-settings-required-settings-request-type')()}
                  highlight={diff['method'] === false}
                  error={errors['method']}
                  style={{ width: '100%' }}
                  value={value.method}
                  options={requestTypeOptions}
                  getPopupContainer={t => t.parentElement.parentElement.parentElement}
                  label={t('labels.request_type')}
                  onChange={handleChange('method', identity)}
                />
              </>
            )}
            <Divider />
            <SearchSelect
              {...testId('push-discount-node-settings-required-settings-discount-period')()}
              highlight={diff['period_type'] === false}
              error={errors['period_type']}
              style={{ width: '100%' }}
              value={value.period_type}
              options={periodTypeOptions}
              getPopupContainer={t => t.parentElement.parentElement.parentElement}
              label={t('labels.discount_period')}
              onChange={handleChange('period_type', identity)}
            />
            {+value.period_type === 1 && (
              <Row style={{ alignItems: 'flex-end', marginTop: '16px' }}>
                <Input
                  {...testId('push-discount-node-settings-required-settings-custom-duration')()}
                  highlight={diff['duration'] === false}
                  type="number"
                  min={0}
                  title={t('labels.duration')}
                  error={errors['duration'] || (errors['duration_unit'] && ' ')}
                  onChange={handleDurationChange}
                  value={zeroIfNaN(value.duration / resolveDurationUnitMultiplier(value.duration_unit))}
                />
                <SearchSelect
                  {...testId('push-discount-node-settings-required-settings-custom-period')()}
                  highlight={diff['duration_unit'] === false}
                  error={(errors['duration_unit'] && errors['duration'] && ' ') || errors['duration_unit']}
                  absoluteError
                  wrapperStyles={{ marginBottom: (errors['duration'] && ' ') || errors['duration_unit'] ? '37px' : '16px', width: '100%', marginLeft: '10px' }}
                  style={{ width: '100%' }}
                  options={durationUnitOptions}
                  getPopupContainer={t => t.parentElement.parentElement.parentElement}
                  onChange={handleDurationUnitChange}
                  value={value.duration_unit}
                />
              </Row>
            )}
            <Divider />
            <Row style={{ marginTop: '16px' }}>
              <SearchSelect
                {...testId('push-discount-node-settings-required-settings-discount-type')()}
                highlight={diff['discount_type'] === false}
                error={errors['discount_type']}
                wrapperStyles={{ marginBottom: '16px', width: '100%', marginRight: 20 }}
                style={{ width: '100%' }}
                options={discountTypeOptions}
                label={t('labels.discount_type')}
                getPopupContainer={t => t.parentElement.parentElement.parentElement}
                onChange={handleChange('discount_type', identity)}
                value={value.discount_type}
              />
              <Input
                {...testId('push-discount-node-settings-required-settings-percent-discount')()}
                highlight={diff['percentage'] === false}
                type="number"
                min={0}
                error={errors['percentage']}
                wrapperStyles={{ opacity: +value.discount_type === 0 ? undefined : 0, width: '100%' }}
                value={value.percentage}
                onChange={handleChange('percentage')}
                title={t('labels.discount_percentage')}
              />
            </Row>
            {+value.discount_type === 1 && (
              <Row style={{ marginTop: '16px' }}>
                <SearchSelect
                  {...testId('push-discount-node-settings-required-settings-unit-currency')()}
                  highlight={diff['currency'] === false}
                  error={errors['currency']}
                  containerStyle={{ marginBottom: !!errors['amount'] ? 36 : 16, width: '100%', marginRight: 20 }}
                  style={{ width: '100%' }}
                  options={currenciesOptions}
                  title={t('labels.currency')}
                  getPopupContainer={t => t.parentElement.parentElement.parentElement}
                  onChange={handleChange('currency', identity)}
                  value={value.currency}
                />
                <Input
                  {...testId('push-discount-node-settings-required-settings-unit-amount')()}
                  highlight={diff['amount'] === false}
                  type="number"
                  min={0}
                  error={errors['amount']}
                  wrapperStyles={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
                  value={value.amount}
                  onChange={handleChange('amount')}
                  title={t('labels.amount')}
                />
              </Row>
            )}
            <Row style={{ marginTop: '16px' }}>
              <Input
                {...testId('push-discount-node-settings-required-settings-number-of-use')()}
                highlight={diff['number_of_use'] === false}
                type="number"
                error={errors['number_of_use']}
                wrapperStyles={{ width: '100%', marginRight: 10 }}
                value={value.number_of_use}
                min={1}
                onChange={handleChange('number_of_use')}
                title={t('labels.number_of_use')}
              />
              <Input
                {...testId('push-discount-node-settings-required-settings-priority')()}
                highlight={diff['priority'] === false}
                type="number"
                error={errors['priority']}
                min={0}
                disabled={!priority}
                tooltip={p('discount_priority_description')}
                renderRightTitle={<Switch checked={priority} onChange={setPriority} />}
                wrapperStyles={{ width: '100%', marginLeft: 10 }}
                value={value.priority}
                onChange={handleChange('priority')}
                title={t('labels.priority')}
              />
            </Row>
            <Divider />
            <Switch
              {...testId('push-discount-node-settings-required-settings-promo-switch')()}
              checked={promoCode}
              style={{ marginBottom: '16px' }}
              onChange={handlePromoCodeEnabledChange}
              title={t('labels.promo_code')}
            />
            {promoCode && (
              <SearchSelect
                {...testId('push-discount-node-settings-required-settings-promo-templates')()}
                highlight={diff['promo_code_id'] === false}
                footerOption={(
                  <FooterOption onClick={handleCreateNewPromoCode}>
                    <ActionButton {...testId('api-request-node-settings-required-template-add')()} size={22} icon="Plus-icon" />
                    <FooterOptionLabel>
                      {p('create_template')}
                    </FooterOptionLabel>
                  </FooterOption>
                )}
                value={value.promo_code_id}
                style={{ width: '100%' }}
                title={t('labels.promo_code_template')}
                getPopupContainer={t => t.parentElement.parentElement.parentElement}
                options={promoCodeOptions || []}
                onChange={handleChange('promo_code_id', identity)}
              />
            )}
          </>
        )}
        descriptionSettings={(
          <>
            <Input
              {...testId('push-discount-node-settings-description-name')()}
              highlight={diff['label'] === false}
              error={errors['label']}
              value={label}
              onChange={handleInputChange(onLabelChange)}
              title={t('labels.name')}
            />
            <Textarea
              {...testId('push-discount-node-settings-description-text')()}
              highlight={diff['description'] === false}
              value={description}
              onChange={handleInputChange(onDescriptionChange)}
              title={t('labels.description')}
            />
          </>
        )}
      />
    </Container>
  );
};

export default PushDiscountNodeSettings;
