import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: absolute;
  background: #262626;
  border-radius: 4px;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  padding: 6px 8px;
  transition: 330ms ease all;
  pointer-events: none;
  
  ${styledProp('shown', css`
    opacity: 1;
  `, css`
    opacity: 0;
    transform: translateY(20px);
  `)}
`;
