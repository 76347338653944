import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;

  .w-25 {
    width: 25%;
  }

  .w-10 {
    width: 10%;
  }

  .w-5 {
    width: 5%;
  }

  .maxWidth-250 {
    max-width: 250px;
    width: 100%;
  }

  .justify-items-end{
    display: flex;
    justify-content: end;
  }
`;

export const Total = styled.p`
  color: var(--text-primary-body, #1A232E);

  font-family: Manrope-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export const TableRowText = styled.p`
  color: #1a232e;
  font-family: Manrope-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;
