import { useTranslation } from '@hooks';
import useWorkflowOptionsPreview from '@hooks/useWorkflowOptionsPreview';

import { TemplateTypes } from '@constants/templates';

import { moment } from '@utils';

import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container, Sending, AiSection, AiTitle, AiSubtitle, SubText } from './styled.js';

export const WebPopupNodeSettingsInfo = ({ node }) => {
  const { p, t } = useTranslation();
  const webpopup = useWorkflowOptionsPreview(TemplateTypes.WEB_POPUP, node.data?.web_popup_id);

  if (!webpopup) {
    return (
      <Container>
        <InfoTitle>{t('labels.template')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  return (
    <Container>
      <InfoTitle>{t('labels.template')}:</InfoTitle>
      <InfoText>{webpopup.name}</InfoText>
      <Sending>
        <InfoTitle>{t('labels.sending')}:</InfoTitle>
        {!node.data?.best_time_enabled ? (
          <InfoText>{p('workflow_page.upon_node_entrance')}</InfoText>
        ) : (
          <AiSection>
            <AiTitle>
              {p('workflow_page.ai_best_time')}
            </AiTitle>
            <AiSubtitle>
              {p('workflow_page.fallback_option')}
            </AiSubtitle>
            <SubText>{node.data?.best_time_fallback ? `${p('workflow_page.at')} ${moment(node.data.best_time_fallback)?.hour?.()}:${moment(node.data.best_time_fallback)?.minute?.() === 0 ? moment(node.data.best_time_fallback)?.minute?.() + '0' : moment(node.data.best_time_fallback)?.minute?.()}` : p('workflow_page.upon_node_entrance')}</SubText>
          </AiSection>
        )}
      </Sending>
    </Container>
  );
};

