import { animated } from '@react-spring/web';
import styled, { css } from "styled-components";

import { styledProp, switchStyle } from '@utils';

import { Device } from '../../../res';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    
    .search-field {
        width: 250px;
    }

    ${styledProp('$fullPage', css`
        position: relative;
        height: calc(100vh - 110px);
        flex-direction: row;

        ${styledProp('$withTabs', css`
            height: calc(100vh - 156px);
        `)}
    `)}

    ${styledProp('$disablePaddings', css`
         padding: 0;
    `)}

  ${switchStyle('$heightType', {
    'height-215': css`
              height: calc(100vh - 215px);
      `,
  })}

    & th {
        white-space: nowrap;
    }

    & th:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and ${Device.desktopS} {
        padding: 20px 260px;
    }
`;

export const PaginationContainer = styled.div`
  padding: 2px 12px;
`;

export const InfoTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ActionsRow = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`;

export const Divider = styled.div`
    height: 28px;
    width: 1px;
    background: #D3DBE7;
`;

export const TotalCount = styled(animated.div)`
    user-select: none;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-Medium;
    font-size: 12px;
    line-height: 18px;
    
    ${styledProp('$fxd', css`
        width: 130px;
    `)}
`;

export const SelectionHeaderContainer = styled(animated.div)`
    display: flex;
    align-items: center;
    position: absolute;
    gap: 12px;
`;
