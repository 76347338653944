import { Dropdown } from 'antd';

import { useTranslation } from '@hooks';

import { WorkflowSchedule } from './components';
import { Title } from './styled';

const resolveType = (type) => {
  switch (type) {
    case 0:
      return 'labels.one_off';
    case 1:
      return 'labels.repeating';
    case 2:
      return 'labels.realtime';
    default:
      return type;
  }
};

const LaunchCell = ({ schedule }) => {
  const { t } = useTranslation();
  const parsed = JSON.parse(schedule || "null");

  if (!parsed) {
    return (
      <Title style={{ color: '#909399', marginRight: 6 }}>
        {t('labels.not_started')}
      </Title>
    );
  }

  if (parsed?.type === 0) {
    return (
      <Title style={{ marginRight: 6 }}>
        {t(resolveType(parsed?.type))}
      </Title>
    );
  }

  return (
    <Dropdown
      trigger={['hover']}
      getPopupContainer={t => t.parentElement.parentElement}
      overlay={(
        <WorkflowSchedule schedule={parsed} />
      )}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title style={{ marginRight: 6 }}>
          {t(resolveType(parsed?.type))}
        </Title>
        <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6C24 9.31371 21.3137 12 18 12H6C2.68629 12 0 9.31371 0 6Z" fill="#DCDFE6"/>
          <circle cx="6" cy="6" r="2" fill="#909399"/>
          <circle cx="12" cy="6" r="2" fill="#909399"/>
          <circle cx="18" cy="6" r="2" fill="#909399"/>
        </svg>
      </div>
    </Dropdown>
  );
}

export default LaunchCell;

