import { useState } from 'react';

import { useTranslation } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import { FilterTypeCard } from './FilterTypeCard';
import { Container, CardsContainer, Description } from './styled';

const resolveDescription = (type) => {
  switch (type) {
    case SegmentsRuleTypes.LINEAR:
      return 'filter_description_linear';
    case SegmentsRuleTypes.ARRAY:
      return 'filter_description_array';
    case SegmentsRuleTypes.EVENT:
      return 'filter_description_event';
    case SegmentsRuleTypes.AGGREGATE:
      return 'filter_description_aggregate';
    case SegmentsRuleTypes.RFM_SEGMENT:
      return 'rfm_segment';
    case 'segment':
      return 'filter_description_aggregate';
    default:
      return 'filter_description';
  }
};
export const SelectFilter = ({ containerStyles, type, options, onChangeType, testId }) => {
  const [hoveredType, setHoveredType] = useState();
  const { p } = useTranslation('workflow_page');

  return (
    <Container $s={!!type}>
      {!type && (
        <>
          <CardsContainer style={containerStyles}>
            {options?.map((ruleType, index) => (
              <FilterTypeCard
                testId={`${testId}-card-${ruleType}`}
                key={index}
                type={ruleType}
                onClick={() => onChangeType(ruleType)}
                onMouseEnter={() => setHoveredType(ruleType)}
                onMouseLeave={() => setHoveredType()}
              />
            ))}
          </CardsContainer>
          <Description $lighter={!hoveredType}>
            {p(resolveDescription(hoveredType))}
          </Description>
        </>
      )}
      <></>
    </Container>
  );
};
