import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Paths } from '@constants';

import { Link, Modal } from '@components';

import { Container, Title, WorkflowsContainer, Row, Type } from './styled';

const EditTemplateModal = ({ opened, workflows, onClose, onSubmit }) => {
  const { t, p } = useTranslation('templates');

  const handleSubmit = onSubmit;
  const handleClose = onClose;

  return (
    <Modal
      title={p('edit_template')}
      opened={opened}
      // bigger
      onClose={handleClose}
      actions={(
        <>
          <Button data-testid={'create-events-cancel'} onClick={handleClose} variant="secondary" style={{ width: '140px' }}>{t('actions.cancel')}</Button>
          <Button data-testid={'create-events-submit'} style={{ marginLeft: 10, width: '140px' }} onClick={handleSubmit}>{t('actions.edit')}</Button>
        </>
      )}
    >
      <Container>
        <Title>{p('edit_used_template_title')}</Title>
        <WorkflowsContainer>
          {workflows.map(({ id, name }) => (
            <Row key={id} onClick={() => handleClose(true)}>
              <Link to={`${Paths.EDIT_WORKFLOW}/${id}/editor`}>{name}</Link>
              <Type>{t('labels.workflow')}</Type>
            </Row>
          ))}
        </WorkflowsContainer>
      </Container>
    </Modal>
  );
};

export default EditTemplateModal;
