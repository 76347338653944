import styled from 'styled-components';

export const Container = styled.div`
  .search {
    min-height: 20px;
  }
`
export const ContryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
  height: 250px;
  overflow-y: scroll;

`
export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const CountryIcon = styled.span`
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  max-height: 24px;
  min-width: 24px;
`

export const CountryName = styled.p`
  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #1A232E;
  `
export const DefaultLang = styled.p`
  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #A1B3C8;
  white-space: nowrap;
`

export const CountryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 12px;
    min-height: 32px;
    transition: 0.3s ease;
    gap: 8px;
    cursor: pointer;
  
    &.disabled {
      cursor: default;
        &:hover {
          background-color: transparent;
        }
      ${CountryName} {
        color: #A1B3C8;
       
      }
    }

    &:hover {
      background-color: #7C94B11F;
    }
`

export const Content = styled.div`
  display: flex;
`
export const ListType = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  min-height: 32px;
  font-weight: 500;
  line-height: 18px;
  color: #7C94B1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
`