import styled, { css } from 'styled-components';

import { extract, styledProp } from '@utils';

export const Container = styled.div`
  width: 100%;
  padding: 15px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background: ${extract('background')}
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ChartContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #DCDFE6;
  border-left: 1px solid #DCDFE6;
  border-right: 1px solid #DCDFE6;
  justify-content: space-around;
  padding: 0 5vw;
  height: 180px;
  align-items: flex-end;
  position: relative;
`;

export const ChartTitle = styled.div`
  font-family: Manrope-Medium;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #909399;
`;

export const RightTitle = styled.div`
`;

export const Bar = styled.div`
  border-radius: 8px 8px 0px 0px;
  width: 106px;
  position: relative;
  height: ${({ percentage }) => 180 * percentage}px;
  background: linear-gradient(213.29deg, #17A8FD 11.15%, #0890F8 90.5%);
  display: flex;
  min-height: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10px;
`;

export const BarTitleContainer = styled.div`
  ${styledProp('outOfBounds', css`
    position: absolute;
    ${styledProp('showDiff', css`
      top: -40px;
    `, css`
      top: -26px;
    `)}
  `)}
  z-index: 1;
`;

export const BarTitle = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  z-index: 1;

  ${styledProp('outOfBounds', css`
    color: #31C447;
  `)}
`;

export const BarDiff = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 15.6px;
  text-align: center;
  color: #FFFFFF;

  ${styledProp('outOfBounds', css`
    color: #31C447;
  `)}
`;

export const BarCaption = styled.div`
  position: absolute;
  bottom: -16px;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: #303133;
`;

export const CheckpointCaption = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #DCDFE6;
  background: ${extract('background')};
  padding: 1px;
`;

export const CheckpointLine = styled.div`
  overflow: visible;
  height: 0;
  position: absolute;
  top: ${extract('top')}px;
  left: 0;
  right: 0;
  border-bottom: 1px dashed #DCDFE6;
  display: flex;
  justify-content: space-between;
  padding: 0 13px;
  align-items: center;
`;

export const Borders = styled.div`
  width: 100%;
  height: 10px;
  background: transparent;
  border-right: 1px solid #DCDFE6;
  border-left: 1px solid #DCDFE6;
  margin-top: 16px;
`;

export const ConversionArrow = styled.div`
  position: absolute;
  left: 116px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${styledProp('outOfBounds', css`
    bottom: 14px;
  `)}
`;

export const ConversionLabel = styled.div`
  font-family: Manrope-Medium;
  font-size: 12px;
  line-height: 100%;
  color: #24993E;
  position: absolute;
  left: 4px;
  
  & > span {
    font-size: 10px;
  }
`;

export const DiffIndicator = styled.div`
  position: absolute;
  border-radius: 8px 8px 0px 0px;
  top: 0;
  right: 0;
  left: 0;
  min-height: 10px;
  
  ${({ diff, total }) => css`
    height: ${Math.abs(diff) / total * 180}px;
    background: ${diff > 0 ? 'linear-gradient(213.29deg, #a2dcfe 11.15%, #9cd3fc 90.5%)' : '#1E386A'};
  `}
`;
