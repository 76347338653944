import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import { checkAppLink, getUser, updateInviteUser } from '@store/actions/creators';

import { useErrors, useModalState, useQuery, useNavigate } from "@hooks";

import { AppLinkTypes, Paths } from '@constants';

import { extract, identity, notify, sequence } from '@utils';
import { isRequired, maxLength, minLength } from '@utils/validators';

import logo from '@res/images/edge-logo.svg';

import { Button, Input } from '@components';

import { Container, ExpiredCaption, ExpiredTitle , Image, InnerContainer } from './styled';

import { UserActionTypes } from "../../store/actions/types";

const validationConfig = {
  name: [
    isRequired,
    minLength(2, 'Name should be at least 2 characters long'),
    maxLength(20, 'Name should be no more than 20 characters long'),
    name => !(/^[a-zA-Z\s]+$/.test(name.trim())) && 'Only latin characters allowed'
  ],
  surname: [
    isRequired,
    minLength(2, 'Name should be at least 2 characters long'),
    maxLength(20, 'Name should be no more than 20 characters long'),
    name => !(/^[a-zA-Z\s]+$/.test(name.trim())) && 'Only latin characters allowed'
  ],
  email: [
    isRequired,
    maxLength(50, 'Email should be less than 50 characters long'),
    name => name.match(/[а-яА-Я]+/g) !== null && 'Only latin characters allowed',
    name => (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(name)) || ~name.indexOf('+')) && 'Invalid email'
  ],
};

const passwordValidation = [
  {
    tooltip: 'min 6 - max 32 characters',
    matcher: v => v?.length < 33,
  },
  {
    error: 'At least 6 characters required',
    matcher: v => v?.length > 5,
  },
  {
    error: 'No more than 32 characters allowed',
    matcher: v => v?.length < 33,
  },
  {
    error: 'No spaces allowed',
    matcher: v => !v?.includes(' '),
  },
  {
    tooltip: '1 lowercase(s) (a-z)',
    error: 'Al least one lowercase letter required',
    matcher: v => /[a-z]/.test(v),
  },
  {
    tooltip: '1 uppercase(s) (A-Z)',
    error: 'Al least one uppercase letter required',
    matcher: v => /[A-Z]/.test(v),
  },
  {
    tooltip: '1 number(s) (0-9)',
    error: 'Al least one number required',
    matcher: v => /\d/.test(v),
  },
  {
    tooltip: '1 special character(s) (#)',
    error: 'Al least one special character required',
    // eslint-disable-next-line no-useless-escape
    matcher: v => /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g.test(v),
  },
];

const validate = (name, value) => validationConfig[name].map(validate => validate(value)).find(identity);

const InviteUserFormPage = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const errors = useErrors([], [429]);
  const dispatch = useDispatch();
  const linkExpiredModal = useModalState();

  useEffect(() => {
    if (!query.get('email') || !query.get('hash')) {
      navigate(Paths.LOGIN);
      return;
    }

    dispatch(checkAppLink({
      email: query.get('email') || '',
      hash: query.get('hash') || '',
      type: AppLinkTypes.INVITE,
      systemType: 'edge',
      onSuccess: ({ status }) => {
        if (!status) {
          linkExpiredModal.open();
        }
      },
      onError: ({ response }) => {
        if (response.status === 422) {
          linkExpiredModal.open();
        }
      }
    }))
  }, []);

  const handleChange = (onChangeFn) => ({ target }) => {
    errors.clear();
    onChangeFn(target.value);
  };

  const handleSubmit = () => {
    const nameError = validate('name', firstName);
    const surnameError = validate('surname', lastName);

    const passwordError = passwordValidation
      .filter(extract('error'))
      .find(({ matcher }) => !matcher(password))?.error;

    if (password !== repeatPassword) {
      errors.setError('confirm', 'Password doesn\'t match')
      return;
    }

    if (passwordError) {
      errors.setError('password', passwordError);
      return;
    }

    if ([nameError, surnameError].some(identity)) {
      errors.setError('first_name', nameError);
      errors.setError('last_name', surnameError);
      return;
    }

    dispatch(
      updateInviteUser({
        hash: query.get('hash'),
        email: query.get('email'),
        password: password,
        first_name: firstName,
        last_name: lastName,
        onSuccess: (res) => {
          dispatch({ type: UserActionTypes.LOGIN_SUCCESS, payload: res })
          dispatch(getUser());
          navigate(Paths.DASHBOARD);
        },
        onError: (err) => {
          errors.handleRequestError(err);
          notify('error', 'Error', err?.response?.data?.message)
        },
      })
    );
  };

  return (
    <Container>
      <Image src={logo} alt="logo" />
      {linkExpiredModal.opened ? (
        <InnerContainer>
          <ExpiredTitle>Authorization Error</ExpiredTitle>
          <ExpiredCaption>Authorization link is no longer active. <br />Please contact admin account owner</ExpiredCaption>
        </InnerContainer>
      ) : (
        <InnerContainer>
          <h2>Create password</h2>
          <>
            <Input
              style={{ width: '258px' }}
              title='First name'
              error={errors.errors['first_name']}
              type="text"
              onChange={sequence(handleChange(setFirstName), () => errors.clearError('first_name'))}
              autoComplete='off'
            />
            <Input
              style={{ width: '258px' }}
              title='Last name'
              error={errors.errors['last_name']}
              type="text"
              onChange={sequence(handleChange(setLastName), () => errors.clearError('last_name'))}
              autoComplete="new-password"
            />
            <Input
              style={{ width: '258px' }}
              title='Password'
              error={errors.errors['password']}
              type="password"
              onChange={sequence(handleChange(setPassword), () => errors.clearError('password'))}
              autoComplete="new-password"
            />
            <Input
              style={{ width: '258px' }}
              title='Repeat password'
              error={errors.errors['confirm']}
              type="password"
              onChange={sequence(handleChange(setRepeatPassword), () => errors.clearError('confirm'))}
              autoComplete='off'
            />
            <Button onClick={handleSubmit}>Submit</Button>
          </>
        </InnerContainer>
      )}
    </Container>
  );
};

export default InviteUserFormPage;
