import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleRow = styled.div`
  padding-bottom: 6px;
`;

export const ItemTitle = styled.div`
  border-radius: 7px;
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
  `)}
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 100%;
  color: #909399;
  padding-left: 16px;
`;

export const Category = styled.div`
  padding: 10px 0;
  transition: all 280ms ease;
  overflow: hidden;
  
  ${styledProp('$opened', `
    max-height: 600px;
  `, `
    max-height: 28px;
  `)}
`;

export const CategoryName = styled.div`
  font-size: 12px;
  line-height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  transition: all 280ms ease;
  font-family: Manrope-SemiBold;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 16px;
  color: #7C94B1;
  
  ${styledProp('selected', `
    color: #31C447;
  `, `
    color: #303133;
  `)}
`;

export const Count = styled.div`
  font-size: 10px;
  background-color: #7C94B11F;
  border-radius: 4px;
  padding: 1px 4px;
  color: #7C94B1;
  margin-left: 2px;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${styledProp('$selected', `
    background-color: #31C4471F !important;
    color: #31C447 !important;
  `)}
`;

export const DropdownIcon = styled.div`
  align-self: center;
  margin-left: 6px;
  transition: all 280ms ease;

  ${styledProp('selected', `
    transform: rotate(180deg);
  `, `
    transform: rotate(0deg);
  `)}
`;

export const Option = styled.div`
  font-size: 12px;
  line-height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  margin-top: 18px;
  padding-left: 32px;
  font-family: Manrope-SemiBold;
  align-items: center;
  gap: 4px;
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
  `)}

  ${styledProp('selected', `
    color: #31C447;
  `, `
    color: #303133;
  `)}
`;

export const SelectedLine = styled.div`
  width: 3px;
  height: 20px;
  background: #31C447;
  left: 0;
  position: absolute;
`;
