import SqlQueryActionTypes from '@store/actions/types/sqlQuery';
import { asCreator, withCallbacks, withFilters, withPagination } from '@store/lib';

import { compose } from '@utils';

export const getSqlQueries = compose(
  withCallbacks,
  withPagination,
  withFilters(['input_value', 'name']),
  asCreator(() => ({
    type: SqlQueryActionTypes.GET_SQL_QUERIES,
  })),
);

export const getSqlQuery = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: SqlQueryActionTypes.GET_SQL_QUERY,
    payload: { id },
    meta: { id },
  })),
);

export const createSqlQuery = compose(
  withCallbacks,
  asCreator(({ query }) => ({
    type: SqlQueryActionTypes.CREATE_SQL_QUERY,
    payload: { query },
  })),
);

export const editSqlQuery = compose(
  withCallbacks,
  asCreator(({ id, query }) => ({
    type: SqlQueryActionTypes.UPDATE_SQL_QUERY,
    payload: { id, query },
    meta: { id },
  })),
);

export const getSqlQueryExample = compose(
  withCallbacks,
  asCreator(({ id, query }) => ({
    type: SqlQueryActionTypes.GET_EXAMPLE,
    payload: { query },
    meta: { id },
  }))
);

export const deleteSqlQuery = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: SqlQueryActionTypes.DELETE_SQL_QUERY,
    payload: { id },
    meta: { id },
  }))
);
