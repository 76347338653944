import { useTranslation } from '@hooks';

import { Button } from '@components/controls';

import {
  Container,
  Title,
  Description,
  ButtonContainer,
  Divider,
} from './styled';

export const DeleteGroupModalContent = ({ onDeleteGroup, onClose }) => {
  const { p } = useTranslation('workflow_page');
  return (
    <Container>
      <Title>{p('confirm_delete_title')}</Title>
      <Description>
        {p('confirm_delete_description')}
      </Description>
      <Divider />
      <ButtonContainer>
        <Button appearance='outlined' style={{ width: 140 }} onClick={onClose}>
          {p('cancel')}
        </Button>
        <Button
          appearance='danger'
          style={{ width: 140 }}
          onClick={onDeleteGroup}
        >
          {p('delete')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};
