import { useState } from 'react';

import { Checkbox, Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Container, Option, OptionCaption, OptionTitle, OptionHeader, SubOption, ColorIndicator, ApplySelected } from './styled';

import { ListNoData } from '../../../ListNoData';

const RFMSegmentsList = ({ options, onSelect, omitValues = [], containerStyles = {}, isWorkflow }) => {
  const [openedAnalytics, setOpenedAnalytics] = useState();
  const [selected, setSelected] = useState([]);
  const { p } = useTranslation();

  const existFilter = (subOption) => {
    return selected.find((subOptionItem) => subOptionItem.parentId === subOption.parentId && subOptionItem.id === subOption.id);
  }

  const handleSelectFilter = (value) => {
    const existFilterOption = existFilter(value);

    if(existFilterOption) {
      const newFilter = selected.filter((selectedFilter) => selectedFilter.id !== existFilterOption.id)
      setSelected(newFilter)
    } else {
      setSelected([...selected, value])
    }
  };

  const handleApply = () => {
    onSelect(selected);
  }

  const handleOpen = (v) => () => {
    setOpenedAnalytics(v);
  };

  const handleSelectOption = (value) => (event) => {
    handleSelectFilter(value)
    event.stopPropagation();
  };

  const handleSelect = (v) => () => {
    onSelect(v);
  };

  return (
    <Container $hasSelected={selected?.length > 0} style={containerStyles}>
      {!options.filter(({ value }) => !omitValues.includes(value)).length && (
        <ListNoData />
      )}
      {options.filter(({ value }) => !omitValues.includes(value)).map(({ value, label, options }, index) => (
        <Option
          data-testid={'segment-trigger-settings-add-rfm-segment-option-' + index}
          key={value}
          onClick={openedAnalytics === value ? () => setOpenedAnalytics() : handleOpen(value)}
          $opn={openedAnalytics === value}
        >
          <OptionHeader>
            <OptionTitle>
              {label}
            </OptionTitle>
            <OptionCaption>
              {/* @TODO: customers count */}
            </OptionCaption>
            {openedAnalytics === value ? (
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5.625L14.625 11.25L13.8375 12.0375L9 7.2L4.1625 12.0375L3.375 11.25L9 5.625Z" fill="#5F728A"/>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                <path d="M6 7.37539L0.375 1.75039L1.1625 0.962891L6 5.80039L10.8375 0.962891L11.625 1.75039L6 7.37539Z" fill="#5F728A"/>
              </svg>
            )
            }
          </OptionHeader>
          {options.map(({ value: sub, label, ...rest }, idx) => {
            const optionData = { id: sub, parentId: value, type: "rfm", ...rest };
            const isSelected = existFilter(optionData);

            return (
              <SubOption key={idx} $selected={isSelected} onClick={ isWorkflow ? handleSelect(optionData) : handleSelectOption(optionData)}>
                {!isWorkflow && <Checkbox checked={isSelected} onLabelClick={(e) => e.preventDefault()}/>}
                <ColorIndicator $color={rest?.cfg?.color?.ui} />
                <OptionTitle>{label}</OptionTitle>
              </SubOption>
            )
          }
          )}
        </Option>
      ))}
      {selected.length > 0 && !isWorkflow && (
        <ApplySelected $isWorkflow={isWorkflow}>
          <Button size='lg' onClick={handleApply} className='vl-w-[130px]'>{p('actions.apply')}</Button>
        </ApplySelected>
      )}
    </Container>
  );
};

export default RFMSegmentsList;
