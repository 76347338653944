import { nestedAggregatesListSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { ReducerKeys } from '@constants';

import { clickhouseFields2Events } from '@utils/fields';

import { createSelector } from 'reselect';

export const eventsLoadingSelector = store => store[ReducerKeys.EVENTS].loading;

export const eventListWithNestedAggregaesSelector = createSelector(
  nestedAggregatesListSelector,
  clickhouseFieldsSelector,
  (nestedAggregates, clickhouseFields) => {
    const eventsList = clickhouseFields2Events(clickhouseFields.data);
    if (!eventsList || !nestedAggregates?.data) return [];

    const nestedAggregatesList = nestedAggregates.data;

    const mapNestedAggregates = (event) => {
      if (Array.isArray(event.nested_aggregates)) {
        const nested_aggregates = event.nested_aggregates?.map(
          (nestedAggregateId) =>
            nestedAggregatesList.find(({ id }) => id === nestedAggregateId)
        );
        return { ...event, nested_aggregates };
      }
      return event;
    };

    return {
      data: eventsList.data.map(mapNestedAggregates),
    };
  }
);
