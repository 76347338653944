import React from 'react';

import { Skeleton } from 'antd';

import { useTranslation } from '@hooks';

import { FontSizes, TextVariants } from '@constants';

import { Container } from './styled';

const Text = ({ children, className, width = 100, style, variant = TextVariants.PARAGRAPH_2, skipSkeleton = false, ...props }) => {
  const { i18n } = useTranslation();

  if ((!i18n.isInitialized) && !skipSkeleton) {
    return (
      <div className={className} style={style}>
        <Skeleton.Input
          active
          style={{
            width,
            minWidth: 'auto',
            height: FontSizes[variant] - 2,
            borderRadius: 7,
          }}
        />
      </div>
    );
  }

  return (
    <Container style={style} className={className} $variant={variant} {...props}>
      {children}
    </Container>
  );
};

export default React.memo(Text);
