import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAutocomplete } from '@store/actions/creators/fields';
import { createFieldAutocompleteSelector } from '@store/selectors/fields';

import useSystemEntityOptions from '@hooks/useSystemEntityOptions';

import { identity } from '@utils';

const LIMIT = 20;

const resolvePath = (event, fields, nestedField) => {
  if (event) {
    return [nestedField, ...fields].filter(identity);
  }

  return [...fields, nestedField].filter(identity);
};

const resolveFilter = (event) => {
  if (!event) {
    return void 0;
  }

  return { 'event': event };
};

const useAutocomplete = ({ event, fields = [], nestedField, query, effect }) => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const path = resolvePath(event, fields, nestedField);
  const filter = resolveFilter(event, fields, nestedField);
  const selector = useMemo(() => {
    return createFieldAutocompleteSelector(path, filter);
  }, [path, filter]);
  const data = useSelector(selector);

  const dispatcher = () => {
    if (!path) {
      return;
    }

    dispatch(getAutocomplete({ query, path, filter, limit: LIMIT, offset }))
  }

  useEffect(() => {
    if (!effect) {
      return;
    }

    dispatcher();
  }, [query, fields.join('.'), nestedField, event, offset, effect]);

  useEffect(() => {
    if (effect || offset === 0) {
      return;
    }

    dispatcher();
  }, [offset]);

  useEffect(() => {
    setOffset(0);
  }, [query]);

  const systemSuggestions = useSystemEntityOptions(path, filter);

  const suggestions = systemSuggestions?.length ? systemSuggestions : (data?.data || []).map(v => ({
    value: String(v),
    label: v,
  })).filter(({ value }) => value !== '');

  const loadMore = () => {
    setOffset(suggestions.length);
  };

  return {
    loadMore,
    loading: data?.loading,
    suggestions,
    dispatcher,
    offset,
  };
};

export default useAutocomplete;
