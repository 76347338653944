import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 8px 0;

    border-radius: 7px;
    border: 1px solid #FFDC7B;
    background: #FFFAED;

    color: var(--text-primary-body, #1A232E);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;

    margin: 10px 14px;
    
    & > span {
        color: var(--Colors-Additional-Warning-500p, #F79009);
    }
`;

export const IconContainer = styled.div`
    position: absolute;
    left: 8px;
`;
