import { useEffect, useState } from 'react';

import { Dropdown } from 'antd';
import parsePhoneNumber from 'libphonenumber-js';

import { by } from '@utils';

import countryEmoji from '@res/countryEmoji.json';

import { Icon } from '@components';

import { EmojiContainer, CountriesContainer, Country } from './styled.js';

import { Input } from '../Input';

const PhoneInput = ({ value, onChange, ...props }) => {
  const [country, setCountry] = useState('');

  useEffect(() => {
    fetch(`https://ipapi.co/json/`).then(res => res.json()).then(res => {
      handleChange({ target: { value: res.country_calling_code } });
      setCountry(res.country);
    }).catch(console.log);
  }, []);

  useEffect(() => {
    if (!value) {
      setCountry('');
    }
  }, [value])

  const handleChange = ({ target }) => {
    let phoneString = target.value;
    if (phoneString) {
      phoneString = phoneString.match(/\d|\.|\+/g)?.join('') || '';
    }

    if (phoneString.indexOf('+') === -1) {
      phoneString = '+'.concat(phoneString);
    }

    const pn = parsePhoneNumber(phoneString);
    setCountry(pn?.country);
    onChange(pn?.formatInternational?.() || phoneString);
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      renderRight={(
        <Dropdown
          trigger={['click']}
          placement="bottomLeft"
          overlay={(
            <CountriesContainer>
              {countryEmoji.map(({ emoji, name, dialCode, code }) => (
                <Country key={name} onClick={() => {
                  handleChange({ target: { value: dialCode } });
                  setCountry(code);
                }}>
                  <span style={{ fontSize: 22 }}>
                    {emoji}
                  </span>
                  <span style={{ marginLeft: 10, marginRight: 6 }}>
                    {name}
                  </span>
                  <span>
                    {dialCode}
                  </span>
                </Country>
              ))}
            </CountriesContainer>
          )}
        >
          <EmojiContainer>
            {countryEmoji.find(by('code', country))?.emoji}
            <Icon name="Dropdown-arrow-icon" color="#fff" size={16} style={{ marginLeft: 6 }} />
          </EmojiContainer>
        </Dropdown>
      )}
    />
  );
}

export default PhoneInput;

