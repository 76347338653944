import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  ${styledProp('$shouldRender', '',css`
    opacity: 0.5;
  `)}
  ${styledProp('$fullWidth', '',css`
    width: 100%;
  `)}
`;
