import { SegmentNodeTypes } from '@constants';

import { SegmentsRuleTypes } from '../../constants';

export const isGroup = (node) => node.type === SegmentNodeTypes.QUERY_GROUP || node.type === 'static' || node.type === 'dynamic';
export const isRule = (node) => node.type === SegmentNodeTypes.QUERY_RULE;

export const isFunnel = (query) => query.ruleType === SegmentsRuleTypes.FUNNEL;

export const isAggregate = (query) => query.ruleType === SegmentsRuleTypes.AGGREGATE;

export const resolveFilterType = query => isFunnel(query) ? 'funnel' : 'filter';

export const createFromFunnel = ({ mainFunnel, drillDownValue, drillByType, drillByProperty, drillByField, ...query }) => {
  const funnel = { ...mainFunnel };

  if (drillDownValue && funnel.field === drillByField) {
    const filter = { field: drillByProperty, type: drillByType, operator: 'equals', value: { type: 'scalar', value: drillDownValue } };
    funnel.steps = funnel.steps.map(s => ({ ...s, filters: s.filters ? [...s.filters, filter] : [filter] }));
  }

  return {
    ...query,
    funnels: [...(query.funnels || []), funnel],
    aggregates: query.aggregates || [],
    query: {
      name: 'root',
      root: true,
      logicalOperator: "and",
      ...(query?.query || {}),
      children: [
        ...(query?.query?.children || []),
        ...(drillDownValue && funnel.field !== drillByField ? [{
          type: SegmentNodeTypes.QUERY_RULE,
          query: {
            negation: false,
            name: (query?.query?.children || []).length + 1,
            field: drillByField,
            ruleType: SegmentsRuleTypes.LINEAR,
            operator: 'equals',
            type: drillByType,
            value: {
              type: 'scalar',
              value: drillDownValue,
            }
          }
        }] : []),
        {
          type: SegmentNodeTypes.QUERY_RULE,
          query: {
            negation: false,
            name: (query?.query?.children || []).length + 1 + +!!(drillDownValue && funnel.field !== drillByField),
            field: funnel.label,
            ruleType: SegmentsRuleTypes.FUNNEL,
            type: 'numeric',
            operator: 'equals',
            value: {
              type: 'scalar',
              value: funnel.steps.length,
            }
          }
        }
      ],
    }
  };
};

export const resolveDropdownFieldType = (prefix, type, p) => {
  if (prefix && (type === 'array' || type === 'event')) {
    return p('nested_array');
  }
  if (type === 'array') {
    return p('array');
  }
  if (type === 'event') {
    return p('event');
  }
  if (type === 'aggregate') {
    return p('aggregate');
  }

  return p('linear');
}
