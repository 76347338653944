import { useSelector } from "react-redux";

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from "@hooks";

import {
  AGGREGATE_FUNCTION_AVG,
  AGGREGATE_FUNCTION_COUNT,
  AGGREGATE_FUNCTION_LABEL_MAP,
  AGGREGATE_FUNCTION_MAP,
  AGGREGATE_FUNCTION_SUM, AGGREGATE_FUNCTION_TYPE_MAP,
  AGGREGATE_TYPE_ARRAY,
  AGGREGATE_TYPE_ARRAY_NESTED,
  AGGREGATE_TYPE_EVENT,
  AGGREGATE_TYPE_EVENT_NESTED,
  AggregateOperatorOptions,
  SegmentsRuleTypes
  , AGGREGATE_FUNCTION_GGR } from "@constants";

import { extract, getFieldOptions, getFilterOptions, getNestedOptions } from "@utils";
import { clickhouseFields2Customer, clickhouseFields2Events } from '@utils/fields';

import { ActionButton, NoData, OperatorSelect, SearchSelect, Select } from "@components";
import {
  AddFilterButton,
  FieldRow
} from "@components/lib/SegmentEditor/components/FilterEditor/components/SubFilterRule/styled";
import { FiltersDropdown } from "@components/lib/SegmentEditor/components/FiltersDropdown";

import { Container, SelectsContainer, FiltersContainer } from './styled';

const OverrideLabels = {
  "nf_notification_id": 'Template',
  "nf_entity_id": 'Workflow version',
  "nf_sub_entity_id": 'Workflow node',
  "nf_event": 'Status',
};

const AggregateSettings = ({ state, setState, onClear, isLoaded, main, showErrors, errors = {}, aggregateSaved }) => {
  const { p, t } = useTranslation('segments_page');
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const events = clickhouseFields2Events(clickhouseFields.data);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);

  const aggregateFunctionLabels = AGGREGATE_FUNCTION_LABEL_MAP(p);

  const aggregateFuncOptions = AGGREGATE_FUNCTION_MAP.map(item => {
    return {
      label: aggregateFunctionLabels[item],
      value: item
    }
  });

  const resetFilters = (filters) => filters.filter(f => !f.hide).map(() => ({ field: '', value: { type: 'scalar', value: '' }, type: '', operator: '' }))

  const handleChangeName = (v, t) => {
    setState({
      ...state,
      nestedField: v.nested ? v.value : null,
      filters: t === SegmentsRuleTypes.EVENT ? [
        ...resetFilters(state.filters),
        {
          hide: true,
          field: 'event',
          type: 'text',
          operator: 'equals',
          value: { type: 'scalar', value: v.nested || v.value },
        },
      ] : resetFilters(state.filters),
      field: t === SegmentsRuleTypes.EVENT ? '_events' : (v.nested || v.value),
      type: v.nested ? (t === SegmentsRuleTypes.EVENT ? AGGREGATE_TYPE_EVENT_NESTED : AGGREGATE_TYPE_ARRAY_NESTED) : (t === SegmentsRuleTypes.EVENT ? AGGREGATE_TYPE_EVENT : AGGREGATE_TYPE_ARRAY),
    });
  };

  const getSelectedItem = () => {
    const fieldName = state.field;

    if (state.type === AGGREGATE_TYPE_ARRAY_NESTED) {
      return customerFields?.find(f => f.field === fieldName)?.payload?.find(p => p.field === state.nestedField) || {};
    } else if (state.type === AGGREGATE_TYPE_EVENT_NESTED && state.filters.find(f => f.hide)?.value?.value) {
      return events?.data?.find(e => e.name === state.filters.find(f => f.hide)?.value?.value)?.payload?.find(p => p.field === state.nestedField) || {};
    } else if (state.type === AGGREGATE_TYPE_ARRAY) {
      return customerFields?.find(f => f.field === fieldName) || {};
    } else if (state.type === AGGREGATE_TYPE_EVENT && state.filters.find(f => f.hide)?.value?.value) {
      return events?.data?.find(e => e.name === state.filters.find(f => f.hide)?.value?.value) || {};
    }

    return {};
  }

  const getNestedAggregates = () => {
    if (state.nestedField) {
      return [];
    }

    if (state.type === AGGREGATE_TYPE_EVENT_NESTED || state.type === AGGREGATE_TYPE_EVENT) {
      const eventName = state.filters?.find(f => f.hide === true)?.value?.value || '';
      return events?.data?.find(e => e.name === eventName)?.nested_aggregates || [];
    }

    if (state.type === AGGREGATE_TYPE_ARRAY_NESTED || state.type === AGGREGATE_TYPE_ARRAY) {
      return customerFields?.find(a => a.field === state.field)?.nested_aggregates || [];
    }

    return [];
  }

  const getSelectedKey = () => {
    if (state.type === AGGREGATE_TYPE_ARRAY || state.type === AGGREGATE_TYPE_ARRAY_NESTED) {
      return state.nestedField ? `${state.field}.${state.nestedField}` : state.field;
    } else if ((state.type === AGGREGATE_TYPE_EVENT || state.type === AGGREGATE_TYPE_EVENT_NESTED) && state.filters.find(f => f.hide)?.value?.value) {
      return state.nestedField ? `${state.filters.find(f => f.hide)?.value?.value}.${state.nestedField}` : state.filters.find(f => f.hide)?.value?.value;
    }

    return '';
  }

  const handleChangeField = (field, index, options) => {
    const newFilters = JSON.parse(JSON.stringify(state.filters));

    if (options.nested) {
      newFilters[index] = {
        type: 'array',
        field: options.nested,
        errors: { field: false },
        filters: [{
          field,
          type: '',
          filterType: options.type === 'aggregate' ? 'aggregate' : 'default',
          aggregateId: options.type === 'aggregate' ? options.id : null,
          operator: '',
          value: { type: 'scalar', value: '' },
        }],
      };
    } else {
      if (newFilters[index].filters) {
        newFilters[index] = { field, filterType: options.type === 'aggregate' ? 'aggregate' : 'default', aggregateId: options.type === 'aggregate' ? options.id : null, errors: { field: false }, value: { type: 'scalar', value: '' }, type: '', operator: '' };
      } else {
        newFilters[index] = { ...newFilters[index], filterType: options.type === 'aggregate' ? 'aggregate' : 'default', aggregateId: options.type === 'aggregate' ? options.id : null, field, operator: '', type: '', value: { value: '', type: 'scalar' } };
      }
    }

    setState({ ...state, filters: newFilters })
  }

  const handleChangeValue = (value, index) => {
    const newFilters = JSON.parse(JSON.stringify(state.filters));

    if (newFilters[index].filters) {
      newFilters[index] = {
        ...newFilters[index],
        filters: [{ ...newFilters[index].filters[0], value: { ...newFilters[index].filters[0].value, value } }]
      }
    } else {
      newFilters[index] = { ...newFilters[index], value: { ...newFilters[index].value, value } };
    }

    setState({ ...state, filters: newFilters })
  }

  const handleChangeOperator = (operator, type, index) => {
    const newFilters = JSON.parse(JSON.stringify(state.filters));

    if (newFilters[index].filters) {
      newFilters[index] = {
        ...newFilters[index],
        filters: [{ ...newFilters[index].filters[0], operator, type: type === 'common' ? null : type, value: { type: newFilters[index].filters[0].value.type, value: '' } }]
      }
    } else {
      newFilters[index] = { ...newFilters[index], operator, type: type === 'common' ? null : type, value: { type: newFilters[index].value.type, value: '' } };
    }

    setState({ ...state, filters: newFilters })
  }

  const handleRemoveFilter = (index) => {
    const newFilters = JSON.parse(JSON.stringify(state.filters));
    newFilters.splice(index, 1);

    setState({ ...state, filters: newFilters })
  }

  const handleAddFilter = () => {
    setState({ ...state, filters: [...state.filters, { field: '', value: { type: 'scalar', value: '' }, type: '', operator: '' }] })
  }

  const handleAttributeChange = (v, _, opt) => {
    setState({ ...state, attribute: v, aggregateMeta: opt.type === 'aggregate' ? { aggregateId: opt.id } : {} })
  };

  const nestedAggregates = getNestedAggregates();

  // eslint-disable-next-line no-whitespace-before-property
  const fieldOptions = getFieldOptions(getSelectedItem()?.payload ?.filter(p => p.type !== 'array').filter(({ type }) => AGGREGATE_FUNCTION_TYPE_MAP[state.aggregate]?.includes?.(type)))
    .concat(nestedAggregates.length ? nestedAggregates.map(({ label, name, id }) => ({ label, value: name, id, type: 'aggregate' })) : []).map(({ label, ...rest }) => ({
      label: OverrideLabels[rest.value] || label,
      ...rest,
    }));
  const nestedOptions = getNestedOptions(customerFields, events);

  const isGGR = state.aggregate === AGGREGATE_FUNCTION_GGR;

  const handleAggregateChange = (v) => {
    if (state.aggregate !== v) {
      if (v === AGGREGATE_FUNCTION_GGR) {
        setState({
          ...state,
          aggregate: v,
          type: AGGREGATE_TYPE_EVENT,
          "field": "b_total_stake_amount_usd",
          "attribute": "b_total_stake_amount_usd",
          "logicalOperator": "and",
          "filters": [
            {
              "field": "event",
              "type": "text",
              "operator": "equals",
              hide: true,
              "value": {
                "type": "scalar",
                "value": "bet"
              }
            },
            {
              "field": "b_bet_date",
              "value": {
                "type": "scalar",
                "value": '',
              },
              "type": "datetime",
              "operator": "matches_range"
            }
          ],
        });
        onClear?.();
        return;
      }

      setState({ ...state, aggregate: v });
      onClear?.();
    }
  }

  return (
    <Container>
      <SelectsContainer>
        <SearchSelect
          value={state.aggregate}
          label={t('labels.function')}
          style={{ width: '186px' }}
          disabled={isLoaded || aggregateSaved}
          options={aggregateFuncOptions}
          onChange={handleAggregateChange}
          testId="aggregate-function"
        />
        <FiltersDropdown
          defaultTab="event"
          hiddenTypes={['aggregate', 'linear', 'rfm-segment', 'array', 'unsubscribe-group']}
          style={{ width: 'auto' }}
          nested={main}
          hidePreview
          onSelect={(v, t) => handleChangeName(v, t)}
          testId="aggregate-filter"
        >
          <Select
            value={state.nestedField || (state.type === AGGREGATE_TYPE_EVENT ? state.filters.find(f => f.hide)?.value?.value : state.field)}
            options={nestedOptions}
            disabled={isLoaded || aggregateSaved || isGGR}
            valueKey={getSelectedKey()}
            hiddenOverlay
            label={`${(state.type === AGGREGATE_TYPE_EVENT || state.type === AGGREGATE_TYPE_EVENT_NESTED) ? p('event') : (state.type ? p('array') : p('field'))}`}
            style={{ width: '186px', marginBottom: 0 }}
            wrapperStyles={{ marginLeft: 6 }}
            testId="aggregate-select"
          />
        </FiltersDropdown>
        {!!state.field && state.aggregate !== 'count' && (
          <Select
            testId="aggregate-type"
            options={isGGR ? fieldOptions.filter(({ value }) => value.startsWith('b_total_stake_amount')) : (state.nestedField ? fieldOptions.map(o => ({ ...o, prefix: getSelectedItem().label })) : fieldOptions)}
            showTypes
            page="segments_page"
            noData={(
              <NoData style={{ padding: 20, maxWidth: 186 }} description={p('no_field_for_function')} />
            )}
            label={`${(state.type === AGGREGATE_TYPE_EVENT || state.type === AGGREGATE_TYPE_EVENT_NESTED) ? p('event') : p('array')} ${p('field_argument')}`}
            style={{ width: '186px' }}
            disabled={isLoaded || aggregateSaved}
            wrapperStyles={{ marginLeft: 6 }}
            onChange={handleAttributeChange}
            error={showErrors && state?.errors?.attribute}
            value={state.attribute}
          />
        )}
      </SelectsContainer>
      <FiltersContainer>
        {!!state.field && state.filters.length ? (
          <SearchSelect
            value={state.logicalOperator}
            bordered={false}
            disabled={isLoaded || aggregateSaved}
            label={`${(state.type === AGGREGATE_TYPE_EVENT || state.type === AGGREGATE_TYPE_EVENT_NESTED) ? p('event') : p('array')} ${p('filter_operator')}`}
            style={{ width: '186px', border: '1px solid #303133', background: '#F9FBFF', borderRadius: '10px' }}
            wrapperStyles={{ marginTop: 10 }}
            options={AggregateOperatorOptions(p)}
            onChange={(v) => setState({ ...state, logicalOperator: v })}
            testId="aggregate-logicalOperator"
          />
        ) : null}
        {(!!state.field && state.logicalOperator) && state.filters?.flatMap((f) => f.type === 'array' ? f.filters.map(ff => ({ ...ff, nested: f.field })) : f).map(({ field, value, type, hide, operator, nested }, index) => {
          if (hide) {
            return null;
          }

          const first = index === 0;

          const getInitialType = () => {
            const agr = nestedAggregates.find(na => na.name === field);
            const p = getSelectedItem()?.payload;
            const t = nested ? p?.find(f => f.field === nested)?.payload?.find(f => f.field === field) : p?.find(p => p.field === field);

            if (!!agr) {
              return (agr.aggregate === AGGREGATE_FUNCTION_COUNT || agr.aggregate === AGGREGATE_FUNCTION_SUM || agr.aggregate === AGGREGATE_FUNCTION_AVG)
                ? 'numeric'
                : t?.find(i => i.field === agr.attribute)?.text;
            }

            return t?.type || 'numeric';
          }

          const filterOptions = getFilterOptions(getSelectedItem()?.payload).concat(nestedAggregates.length ? nestedAggregates.map(({ label, name, id }) => ({ label, value: name, id, type: 'aggregate' })) : []).map(({ label, ...rest }) => ({
            label: OverrideLabels[rest.value] || label,
            ...rest,
          }));

          const SHOW_NUMERIC_FIELDS = ['rf_rfm_id', 'nf_notification_id'];

          return (
            <FieldRow key={index}>
              <Select
                showTypes
                page="segments_page"
                valueKey={nested ? `${nested}.${field}` : void 0}
                options={state.nestedField ? filterOptions.map(o => ({ ...o, prefix: getSelectedItem().label })) : filterOptions}
                style={{ height: 26, width: 186 }}
                disabled={isLoaded || aggregateSaved || isGGR}
                label={first ? t('labels.field') : ''}
                onChange={(v, g, o) => handleChangeField(v, index, o)}
                value={field}
                testId="aggregate-nested"
              />
              <OperatorSelect
                smaller
                testId="aggregate-operator"
                wrapperStyle={{ marginLeft: 6 }}
                initialType={getInitialType()}
                value={{ value: value.value, type, operator, errors: showErrors && errors?.filters?.[index]?.errors }}
                type={type}
                isAggregate
                onValueChange={(v) => handleChangeValue(v, index)}
                onOperatorChange={(v, t) => handleChangeOperator(v, t, index)}
                field={field}
                disabled={isLoaded || aggregateSaved}
                nestedField={nested}
                validateDate
                autocomplete={(type === 'numeric' && !SHOW_NUMERIC_FIELDS.includes(field)) ? undefined : {
                  event: (state.type === AGGREGATE_TYPE_EVENT || state.type === AGGREGATE_TYPE_EVENT_NESTED) && state.filters?.find?.(extract('hide'))?.value?.value,
                  fields: [(state.type === AGGREGATE_TYPE_ARRAY || state.type === AGGREGATE_TYPE_ARRAY_NESTED) && (state.field), state.nestedField, nested, field],
                  nested: nested,
                }}
                labeled={first}
              />
              <ActionButton
                testId={'aggregate-delete'}
                iconSize={14}
                appearance="danger"
                size={20}
                hide={isLoaded || aggregateSaved || isGGR}
                style={{ marginLeft: 6, marginTop: first ? 23 : 3 }}
                icon="Delete-icon"
                tooltip={p('remove_filter')}
                onClick={() => handleRemoveFilter(index)}
              />
            </FieldRow>
          )
        })}
        {(!isLoaded && !aggregateSaved && !!state.field && !isGGR) && (
          <AddFilterButton onClick={handleAddFilter}>
            + {p('add_aggregate_filter')}
          </AddFilterButton>
        )}
      </FiltersContainer>
    </Container>
  )
};

export default AggregateSettings;
