import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: var(--buttons-big-padding-TB, 8px) var(--buttons-big-icon-padding, 8px);
    justify-content: center;
    align-items: center;
    border-radius: var(--buttons-big-radius, 7px);
    background: var(--Colors-Additional-Positive-opacity-medium, rgba(18, 183, 106, 0.12));

    color: var(--Colors-Additional-Positive-500p, #12B76A);
    text-align: center;

    font-family: Manrope-SemiBold;
    font-size: 13px;
    line-height: 18px;
    gap: 4px;
    
    & > svg {
        min-width: 18px;
    }
    
    padding: 8px;
`;

export const Overlay = styled.div`
    border-radius: 7px;
    border: 1px solid var(--select-select-border, #D3DBE7);
    background: var(--input-input-bg, #FFF);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);

    display: flex;
    flex-direction: column;
    padding: 3px 0;
    max-height: 600px;
    overflow-y: auto;
`;

export const WorkerRow = styled.div`
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 12px;
    
    &:hover {
        background: var(--cards-bg, #F8FAFE);
    }
`;

export const Worker = styled.div`
    color: var(--buttons-primary-bg, #31C447);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    width: 124px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Status = styled.div`
    color: var(--text-secondary-body, #7C94B1);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;
`;
