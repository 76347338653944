import { motion } from 'framer-motion';
import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    x: '50%',
  },
  animate: { opacity: 1, x: '0%', scale: 1 },
  exit: direction => direction === -1 ? {
    opacity: 0,
    x: '30%'
  } : {
    opacity: 0,
    x: '50%'
  },
})`
  border-radius: 10px;
  border: 2px solid var(--Colors-Neutral-300, #D3DBE7);
  background: var(--Colors-Neutral-200, #ECF0F6);
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.30);
  position: fixed;
  top: 186px;
  right: 30px;
  width: 300px;
  display: flex;
  flex-direction: column;
    z-index: 1;

  
  ${styledProp('$disabled', `
    background: #99A3C24D !important;
  `)}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
    padding: 0 20px;
    margin-top: 22px;
    margin-bottom: 16px;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  gap: 4px;
`;

export const Info = styled.div`
  cursor: pointer;
`;

export const InputRow = styled.div`
    display: flex;
    align-content: stretch;
    gap: 16px;
    border-bottom: 1px solid var(--border-divider, #D3DBE7);
    padding: 0 20px 23px;
`;

export const Description = styled.div`
  color: var(--input-input-txt-lable, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 12px;
  line-height: 18px;
`;

export const Divider = styled.div`
`;

export const HeaderTitle = styled.div`
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
    padding: 12px 20px;
    border-bottom: 1px solid var(--border-divider, #D3DBE7);
`;

export const RadioContainer = styled.div`
  padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 22px;
`;

export const ConversionMethod = styled.div`
    color: var(--text-secondary-body, #7C94B1);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-Medium;
    font-size: 12px;
    line-height: 18px;
    
    text-align: left;
    
    & > span {
        color: var(--text-tertiary-body, #FFF);
    }
`;
