import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Modal } from '@components';

import { DetailsTree } from './components';

const EventDetailsModal = ({ label, payload, opened, onClose, onCopy, resolveCopyData, path }) => {
  const { p, t } = useTranslation('templates');

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      contentStyles={{ paddingTop: 20 }}
      title={p('details', { label })}
      actions={(
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button onClick={onClose} variant="secondary" style={{ width: '160px' }}>{t('actions.close')}</Button>
        </div>
      )}
    >
      <DetailsTree value={payload} onCopy={onCopy} resolveCopyData={resolveCopyData} path={path} />
    </Modal>
  );
};

export default EventDetailsModal;
