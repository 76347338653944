import React, { useMemo, useState } from 'react';
import { useDispatch } from "react-redux";

import { archiveWebhookTemplate, getApiRequestTemplates, getSingleApiRequest } from "@store/actions/creators";

import { Button } from '@velitech/ui';

import { useModalState, useTranslation, useNavigate } from "@hooks";

import { Paths, UserPermissions } from "@constants";

import { notify } from "@utils";

import { Link, WithPermissions, TemplateStatisticsModal } from "@components";
import { SmallTemplateItem } from "@components/ui/SmallTemplateItem";

import { Container } from "./styled";

import { SmsTemplatesRow, TemplatesHeader, TemplatesInner } from "../../styled";

const WebhookSection = ({ apiRequests = [] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { p, t } = useTranslation('dashboard_page');
  const [statisticWebhookId, setWebhookStatisticsId] = useState(null);
  const statisticsWebhookModal = useModalState();

  const shouldRender = useMemo(() => {
    return apiRequests?.data?.filter(t => t.status !== 3)?.length;
  }, [apiRequests?.data])

  const handleWebhookArchive = (id, name) => {
    dispatch(archiveWebhookTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.template_archived'), name);
        dispatch(getApiRequestTemplates({ page: 1, perPage: 6, all: true }));
      }
    }))
  }

  const handleCreateApiRequest = () => {
    navigate(`${Paths.TEMPLATES_API_REQUEST}/new`);
  };

  const handleWebhookStatisticsClick = (id) => {
    setWebhookStatisticsId(id);
    statisticsWebhookModal.open();
  };

  const handleViewApiRequest = (id) => {
    navigate(`${Paths.TEMPLATES_API_REQUEST}/${id}?view=1`);
  };

  const handleEditApiRequest = (id, used) => {
    if (used === 1) {
      return;
    }
    navigate(`${Paths.TEMPLATES_API_REQUEST}/${id}`);
  };

  const handleDuplicateApiRequest = (id) => {
    dispatch(getSingleApiRequest({ id }))
    navigate(`${Paths.TEMPLATES_API_REQUEST}/duplicate`);
  };

  return (
    <Container data-testid="dashboard-templates-section-webhook">
      {shouldRender ? (
        <TemplatesInner style={{ paddingBottom: 0 }}>
          <TemplatesHeader>
            <Link to={`${Paths.TEMPLATES_API_REQUESTS}/default`} icon>{p('api_requests')}</Link>
            <WithPermissions name={UserPermissions.CREATE_API_REQUESTS}>
              <Button
                size={'sm'}
                style={{
                  padding: '6px 14px',
                  height: '26px',
                  fontSize: '12px',
                  lineHeight: '14px',
                  borderRadius: '5px'
                }}
                data-testid="dashboard-templates-section-webhook-create"
                width="none"
                onClick={handleCreateApiRequest}
              >
                {t('actions.create')}
              </Button>
            </WithPermissions>
          </TemplatesHeader>
          <SmsTemplatesRow>
            {apiRequests.data?.filter(t => t.status !== 3)?.slice(0, 3).map(({ id, used, name, created_at, workers, status }, i, arr) => (
              <SmallTemplateItem
                key={id}
                status={status}
                testId={`webhook-templates-item-${i}`}
                used={used}
                type="api_request"
                title={name}
                data={arr[i]}
                onArchive={() => handleWebhookArchive(id, name)}
                fromDashboard
                onStatisticsClick={() => handleWebhookStatisticsClick(id)}
                onDeleteClick={() => {}}
                small
                workflows={workers}
                style={{ ...(i === (arr.length - 1) ? { marginRight: '0px' } : {}) }}
                date={created_at}
                onViewClick={() => handleViewApiRequest(id)}
                onEditClick={() => handleEditApiRequest(id)}
                onDuplicateClick={() => handleDuplicateApiRequest(id)}
              />
            ))}
          </SmsTemplatesRow>
        </TemplatesInner>
      ) : null}
      <TemplateStatisticsModal
        id={statisticWebhookId} 
        opened={statisticsWebhookModal.opened} 
        onClose={statisticsWebhookModal.close}
        type={'webhook'}
      />
    </Container>
  )
}

export default React.memo(WebhookSection);
