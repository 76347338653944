import { useSelector } from 'react-redux';

import { loggedInSelector } from '@store/selectors';

import { Paths } from '@constants';

import { Popover } from '@components/layout/Topbar/components/Popover';
import { Item } from '@components/layout/Topbar/components/TopbarSettings/styled';

import {
  Container,
  UserImg
} from './styled';

const accountPage = {
  name: 'Profile',
  path: `${Paths.ACCOUNT_SETTINGS}/account`,
};
const UserInfo = ({ setHovered, opened, hovered, handleLogout, testId }) => {

  const loggedInUser = useSelector(loggedInSelector);

  const firstLetter = loggedInUser?.name?.[0].toUpperCase();

  return (
    <Container data-testid={testId}>
      <Popover
        larger
        isUserInfo={true}
        setHovered={setHovered}
        overlayClassName="stt-3"
        handleLogout={handleLogout}
        firstLetter={firstLetter}
        name={loggedInUser?.name}
        surname={loggedInUser?.surname}
        role={loggedInUser?.roles?.[0]?.label}
        accountPage={accountPage}
      >
        <Item
          style={{ borderTop: 'none' }}
          $opened={opened}
          $hovered={hovered}
          isUserInfo={true}
        >
          <UserImg data-testid={`${testId}-user-letter`}>
            {firstLetter}
          </UserImg>
        </Item>
      </Popover>
    </Container>
  );
};

export default UserInfo;
