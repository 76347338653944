import { forwardRef } from 'react';

import { Icon } from '@components';

import { Wrapper, ArrowWrapper } from './styled';

const SizeChangeHandler = forwardRef((props, ref) => (
  <Wrapper {...props} ref={ref}>
    <ArrowWrapper isLeft>
      <Icon
        size={14}
        name="Back-arrow1"
        color="#303133"
        style={{ transform: 'rotate(180deg)', cursor: 'col-resize' }}
      />
    </ArrowWrapper>
    <ArrowWrapper>
      <Icon size={14} style={{ lineHeight: '14px' }} name="Back-arrow1" color="#303133" />
    </ArrowWrapper>
  </Wrapper>
));

export default SizeChangeHandler;
