import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import * as Colors from "@res/theme/colors";

export const Container = styled.div`
    position: relative;
    ${styledProp('$small', css`
      margin: 0 12px 12px 0;
    `, css`
      flex: 1;
      min-width: 214px;
      max-width: 500px;
    `)}

    outline: none;
    overflow: visible;

    & * {
        outline: none;
        user-select: none;
    }
    margin: 1px;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 206px;
    min-width: 240px;
    max-width: 500px;
    padding: 0 0 0 0;
    border-radius: 10px;
    transition: all 330ms ease;
    position: relative;
    border: 1px solid #D3DBE7;
    background: var(--cards-bg, #F8FAFE);


    ${styledProp('isExample', '', css`
      &:hover {
        background: #F9FBFF;
      }
    `)}

    ${styledProp('$isDragging', css`
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    `)}
    
    ${styledProp('$draggingOverFolder', css`         
      transform: scale(0.3);
    `)}
`;

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    background: var(--cards-bg, #F8FAFE);
    border-radius: 0 0 10px 10px;
    position: relative;
    height: 70px;

    ${styledProp('isExample', '', css`
    &:hover {
      background: #F9FBFF;
    }
  `)}
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 34px;
    height: 34px;
  
    border-radius: var(--buttons-big-radius, 7px);
    background: var(--buttons-secondary-bg, #ECF0F6);
    
  ${styledProp('transparent', `
    background: transparent;
    align-items: flex-start;
    justify-content: flex-start;
  `)}
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: wrap;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 144px;
    padding-left: 12px;
    justify-content: center;
`;

export const Title = styled.div`
  max-width: 170px;
  font-size: 13px;
  color: ${Colors.Text.PRIMARY};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;;
`;

export const Date = styled.p`
    color: var(--text-secondary-body, #7C94B1);
    font-feature-settings: 'liga' off, 'clig' off;
    
    font-family: Manrope-Medium;
    font-size: 12px;
    line-height: 18px;
`;

export const PreviewWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 136px;
    overflow: hidden;
    justify-content: flex-start;
    display: flex;
    z-index: 1;

  ${styledProp('hovered', css`
  .HoverMenu {
    opacity: 1;
    transform: scale(1);

    & > div {
      transform: scale(1);
    }
  }
`)}

  &:hover {
    .HoverMenu {
      opacity: 1;
      transform: scale(1);

      & > div {
        transform: scale(1);
      }
    }
  }
`;

