import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  //padding: 14px 16px;
  background: transparent;
  border-radius: 7px;
  transition: all 330ms ease;
  position: relative;
  
  &:focus-within {
    //border-color: #fff;
    //
    //& > label {
    //  color: #fff;
    //}

    ${styledProp('hasError', css`
      border-color: #DC5F4E;
      
      & > input {
        color: #DC5F4E;
      }

      & > label {
        color: #DC5F4E;
      }
    `)}
  }
  
  ${styledProp('hasError', css`
    border-color: #DC5F4E;

    & > input {
      color: #DC5F4E;
    }

    & > label {
      color: #DC5F4E;
    }
  `)}
`;

export const Title = styled.label`
  font-family: Manrope-Medium;
  position: absolute;
  font-size: 13px;
  line-height: 140%;
  color: #1A232E;
  padding: 0 3px;
  background: #E6F8FC;
  top: -21px;
  left: 0px;
  transition: all 330ms ease;
`;

export const HTMLInput = styled.input`
  border: 1px solid rgb(220, 223, 230);
  box-sizing: border-box;
  border-radius: 7px;
  height: 36px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding-right: 24px;
  padding-left: 13px;
  margin-bottom: 16px;
  color: #1A232E;
  width: 100%;
  transition: all 230ms ease;
  overflow: visible;
  outline: none;
  background-color: transparent !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 1s ease-in-out 50000s, color 1s ease-in-out 50000s !important;
    transition-delay: 5000s !important;
    -webkit-box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    outline: none !important;
    background-color: transparent !important;
    font-family: Manrope-SemiBold;
    color: #1A232E !important;
    font-size: 16px !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 16px;
    line-height: 140%;
    color: #3F3F46;
    ${styledProp('registration', css`
      color: #7C94B1;
    `)}
  }
`;

export const Error = styled.div`
  color: #DC5F4E;
  top: 52px;
  left: 0;
  font-size: 11px;
  position: absolute;
  line-height: 140%;
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
