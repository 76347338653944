import { EventsActionTypes } from '@store/actions/types';
import { asCreator, withCallbacks } from '@store/lib';

import { compose } from '@utils';

export const createEvent = compose(
  withCallbacks,
  asCreator(({ name, id, payload, label, description }) => ({
    type: EventsActionTypes.CREATE,
    payload: { name, id, payload, label, description },
  })),
);

export const editEvent = compose(
  withCallbacks,
  asCreator(() => ({
    type: EventsActionTypes.EDIT,
  })),
);

export const deleteEvent = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: EventsActionTypes.DELETE,
    payload: { id },
  }))
);
