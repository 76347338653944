import { UserActionTypes, WorkspacesActionTypes } from "../actions/types";

const initialState = {
  workspaces: [],
  demo: {
    loading: false,
  },
  viewDemoModalOpened: false,
  loading: false,
};

const workspacesReducer = (state = initialState, action) => {
  switch (action.type) {
    case WorkspacesActionTypes.LIST:
      return {
        ...state,
        workspaces: [],
        loading: true,
      };
    case WorkspacesActionTypes.LIST_SUCCESS:
      return {
        ...state,
        workspaces: action.payload,
        loading: false,
      };
    case WorkspacesActionTypes.LIST_ERROR:
      return {
        ...state,
        workspaces: [],
        loading: false,
      };
    case WorkspacesActionTypes.GET_DEMO:
      return {
        ...state,
        demo: {
          ...state.demo,
          loading: true,
        },
      };
    case WorkspacesActionTypes.GET_DEMO_SUCCESS:
      return {
        ...state,
        demo: {
          ...state.demo,
          ...action.payload,
          loading: false,
        },
      };
    case WorkspacesActionTypes.GET_DEMO_ERROR:
      return {
        ...state,
        demo: {
          ...state.demo,
          loading: false,
        },
      };
    case WorkspacesActionTypes.SET_VIEW_DEMO_MODAL_OPENED:
      return {
        ...state,
        viewDemoModalOpened: action.payload.opened,
      };
    case UserActionTypes.LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default workspacesReducer;
