export const TABS = {
  SMS: 'sms',
  EMAIL: 'email',
  INITIAL: 'email',
  WEBHOOK: 'webhook',
  WEBPUSH: 'web_push',
  MOBILE_PUSH: 'mobile-push',
  VIBER: 'viber',
  PROMO_CODES: 'promo-codes',
  FACEBOOK: 'facebook-audience',
  INCLUDE_CAMPAIGN: 'include-campaign',
  GIVE_REWARD: 'give-reward',
  CONTENT_CARDS: 'content-cards',
  WEB_POPUPS: 'web-popups'
};

export const STATUSES = {
  SENT: 'sent',
  DELIVERED: 'delivered',
  DROPPED: 'dropped',
  BLOCKED: 'blocked',
  BOUNCE: 'bounce',
  FAILED: 'failed',
}
