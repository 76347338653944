import { useSelector } from 'react-redux';

import { subscriptionGroupsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { by, update } from '@utils';

import { SearchSelect } from '@components';

import { Container } from './styled';

const subscribedOptions = [
  {
    label: 'Subscribed',
    value: true,
  },
  {
    label: 'Unsubscribed',
    value: false,
  }
];

const UnsubscribeGroupSelect = ({ appearance, query, onChange, showErrors }) => {
  const { t } = useTranslation('segments_page');
  const subscriptionGroups = useSelector(subscriptionGroupsSelector);

  const opts = subscriptionGroups.data?.map(({ name, id }) => ({ value: id, label: name }));

  const onQueryChange = updater => onChange(q => ({ ...q, query: update(q.query, updater) }));

  const subscribedFilter = (query.filters || []).find(by('field', 'subscribed'));
  const subscribed = subscribedFilter?.value.value;
  const handleSubscribedChange = (value) => {
    onQueryChange(q => ({ ...q, filters: q.filters.map((f) => f.field === 'subscribed' ? { ...f, value: { ...f.value, value } } : f) }));
  };

  const subscribeIdFilter = (query.filters || []).find(by('field', 'subscribe_id'));
  const subscribeId = subscribeIdFilter?.value.value;
  const handleSubscribeIdChange = (value) => {
    onQueryChange(q => ({ ...q, filters: q.filters.map((f) => f.field === 'subscribe_id' ? { ...f, value: { ...f.value, value } } : f) }));
  };

  return (
    <Container $appearance={appearance} data-testid={`store-segment-field-linear-container-${query.name}`}>
      <SearchSelect
        testId={`store-segment-field-linear-select-${query.name}`}
        style={{ width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
        options={opts}
        label={t('labels.unsubscribe_group')}
        value={subscribeId}
        tooltipError={showErrors && subscribeIdFilter?.errors?.value}
        onChange={handleSubscribeIdChange}
      />
      <SearchSelect
        testId={`store-segment-field-linear-select-${query.name}`}
        style={{ width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
        containerStyle={{ marginLeft: 12 }}
        options={subscribedOptions}
        label={t('labels.status')}
        value={subscribed}
        tooltipError={showErrors && subscribedFilter?.errors?.value}
        onChange={handleSubscribedChange}
      />
    </Container>
  );
};

export default UnsubscribeGroupSelect;
