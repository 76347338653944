/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { AccordionItem } from './components';
import { Container, SettingsContainer } from './styled';

const tabs = [
  {
    title: 'required_settings',
    name: 'required',
  },
  {
    title: 'description',
    name: 'description',
  },
  {
    title: 'additional_settings',
    name: 'additional',
  },
];

const NodeSettingsAccordion = ({ requiredSettings, descriptionSettings, matchingPreset, additionalSettings, requiredContainerStyle }) => {
  const [tab, setTab] = useState('required');
  const refs = useRef(Object.fromEntries(tabs.map(({ name }) => [name, null])));
  const [tabEl, setTabEl] = useState(null);

  const ts = tabs.filter(({ name }) => {
    if (name === 'required') {
      return !!requiredSettings;
    }
    if (name === 'additional') {
      return !!additionalSettings;
    }

    return true;
  });

  useEffect(() => {
    if (!refs.current[tab]?.getBoundingClientRect()?.width) {
      return;
    }

    setTabEl(refs.current[tab]);
  }, [tab, refs.current[tab]]);

  return (
    <Container>
      {/*<Tabs>*/}
      {/*  {ts.map(({ name, title }) => (*/}
      {/*    <Tab*/}
      {/*      key={name}*/}
      {/*      id={`nsm-tab-${name}`}*/}
      {/*      selected={name === tab}*/}
      {/*      ref={rr => refs.current[name] = rr}*/}
      {/*      onClick={() => setTab(name)}*/}
      {/*    >*/}
      {/*      {p(title)}*/}
      {/*    </Tab>*/}
      {/*  ))}*/}
      {/*  <TabIndicator tab={tab} $tab={tabEl} />*/}
      {/*</Tabs>*/}
      {tab === 'required' && (
        <SettingsContainer style={requiredContainerStyle}>
          {requiredSettings}
          {!!matchingPreset && (
            <AccordionItem initialOpened={true} title={'Matching preset'}>
              {matchingPreset}
            </AccordionItem>
          )}
        </SettingsContainer>
      )}
      {tab === 'description' && (
        <SettingsContainer>
          {descriptionSettings}
        </SettingsContainer>
      )}
      {tab === 'additional' && (
        <SettingsContainer>
          {additionalSettings}
        </SettingsContainer>
      )}
    </Container>
  );
};

export default NodeSettingsAccordion;
