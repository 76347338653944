import { SegmentsRuleTypes } from '@constants';

import { AggregateSelect } from '../AggregateSelect';
import { ArraySelect } from '../ArraySelect';
import { EventSelect } from '../EventSelect';
import { FunnelSelect } from '../FunnelSlect';
import { LinearSelect } from '../LinearSelect';
import { RFMSegmentSelect } from '../RFMSegmentSelect';
import { UnsubscribeGroupSelect } from '../UnsubscribeGroupSelect';

const FilterRule = ({
  query,
  onChange,
  aggregates,
  addFilter,
  funnels,
  appearance,
  aggregateSaved,
  onAggregateChange,
  onFunnelChange,
  showErrors,
  aggregateCreationDisabled,
  onDeleteFilter = () => {},
  onDeleteFunnelStep,
}) => {
  switch (query.ruleType) {
    case SegmentsRuleTypes.LINEAR:
      return <LinearSelect {...{ query, onChange, showErrors, appearance }} />;
    case SegmentsRuleTypes.ARRAY:
    case SegmentsRuleTypes.LINEAR_ARRAY:
      return <ArraySelect {...{ query, onChange, showErrors, appearance }} />;
    case SegmentsRuleTypes.EVENT:
      return <EventSelect {...{ query, onChange, showErrors, appearance }} />;
    case SegmentsRuleTypes.AGGREGATE:
      return <AggregateSelect {...{ query, onChange, showErrors, appearance, addFilter, aggregateCreationDisabled, onDeleteFilter, onAggregateChange, aggregates, aggregateSaved }} />;
    case SegmentsRuleTypes.FUNNEL:
      return <FunnelSelect {...{ query, showErrors, appearance, funnels }} onChange={onFunnelChange} onDeleteStep={onDeleteFunnelStep} />;
    case SegmentsRuleTypes.RFM_SEGMENT:
      return <RFMSegmentSelect {...{ query, onChange, showErrors, appearance }} />
    case SegmentsRuleTypes.UNSUBSCRIBE_GROUP:
      return <UnsubscribeGroupSelect {...{ query, onChange, showErrors, appearance }} />
    default:
      return null;
  }
};

export default FilterRule;
