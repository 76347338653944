import { ContentCardPreview, Modal } from '@components';

import { Container } from './styled';

const ContentCardPreviewModal = ({ opened, onClose, data }) => {
  return (
    <Modal
      title={'Template preview'}
      {...{ opened, onClose }}
    >
      <Container>
        <ContentCardPreview value={data} image={data.image?.url || data.image_url || data.image} />
      </Container>
    </Modal>
  );
};

export default ContentCardPreviewModal;
