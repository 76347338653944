import { useSelector } from 'react-redux';

import { Tooltip } from 'antd';

import { Container } from './styled';

import { WorkflowNodes } from '../../../../../../../constants';
import { TEST_INTEGRATIONS } from '../../../../../../../constants/testIntegrations';
import { myIntegrationsSelector } from '../../../../../../../store/selectors';
import { by } from '../../../../../../../utils';

const NodeOptionsMap = {
  [WorkflowNodes.SEND_SMS]: 'sms',
  [WorkflowNodes.SEND_EMAIL]: 'email',
  [WorkflowNodes.MOBILE_PUSH]: 'mobile_push',
  [WorkflowNodes.WEBPUSH]: 'web_push',
  [WorkflowNodes.VIBER]: 'viber',
  [WorkflowNodes.API_REQUEST]: 'webhook',
  [WorkflowNodes.WEB_POPUP]: 'web_popup',
  [WorkflowNodes.SEND_CARD]: 'message_feed',
};

const NodeFieldMap = {
  [WorkflowNodes.SEND_SMS]: 'sms_id',
  [WorkflowNodes.SEND_EMAIL]: 'email_id',
  [WorkflowNodes.MOBILE_PUSH]: 'mobile_push_id',
  [WorkflowNodes.WEBPUSH]: 'webpush_id',
  [WorkflowNodes.VIBER]: 'viber_id',
}

const TestSendingDisclaimer = ({ data }) => {
  const options = { };
  const integrations = useSelector(myIntegrationsSelector);

  const integrationId = options[NodeOptionsMap[data.name]]?.find?.(by(data[NodeFieldMap[data.name]]))?.app_integration_id;
  const integration = integrations?.data?.find?.(by(integrationId));

  if (!TEST_INTEGRATIONS.includes(integration?.integration_key)) {
    return null;
  }

  return (
    <Container>
      <Tooltip title={"Chosen test integration, there will be no sending."}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
              d="M20.625 19.6556L2.34438 1.375L1.375 2.34438L6.875 7.84437L1.82875 9.66625C1.69831 9.71573 1.58601 9.80372 1.50676 9.91853C1.42751 10.0333 1.38507 10.1696 1.38507 10.3091C1.38507 10.4486 1.42751 10.5848 1.50676 10.6996C1.58601 10.8144 1.69831 10.9024 1.82875 10.9519L8.42875 13.5919L11.0688 20.1919C11.1184 20.3166 11.2034 20.424 11.3133 20.501C11.4233 20.578 11.5533 20.6211 11.6875 20.625C11.8264 20.6222 11.9612 20.5773 12.0742 20.4963C12.1871 20.4153 12.2728 20.3019 12.32 20.1713L14.1625 15.125L19.6625 20.625L20.625 19.6556ZM3.9875 10.3125L7.93375 8.9375L10.0306 11L8.7725 12.2581L3.9875 10.3125ZM11.6875 18.0125L9.76938 13.2L11 11.9694L13.0625 14.0662L11.6875 18.0125Z"
              fill="#F79009"/>
            <path
              d="M10.7869 7.87187L16.0394 5.96063L14.1281 11.2131L15.2006 12.2856L17.8338 5.04625C17.878 4.92346 17.8865 4.7906 17.858 4.6632C17.8296 4.5358 17.7655 4.41912 17.6732 4.32682C17.5809 4.23452 17.4642 4.1704 17.3368 4.14197C17.2094 4.11353 17.0765 4.12195 16.9537 4.16625L9.71438 6.79938L10.7869 7.87187Z"
              fill="#F79009"/>
          </svg>
        </div>
      </Tooltip>
    </Container>
  );
};

export default TestSendingDisclaimer;
