import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 376px) {
    max-width: calc(100vw - 20px);
    width: calc(100vw - 20px);
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
