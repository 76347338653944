import React, { useMemo } from 'react';
import { useSelector } from "react-redux";

import { dashboardSmsSummarySelector } from "@store/selectors/dashboard";

import { useTranslation } from "@hooks";

import { Paths } from "@constants";

import { zeroIfNaN } from "@utils";

import { SendingInfo } from "../SendingInfo";

const SmsSendingInfo = () => {
  const { p } = useTranslation('dashboard_page');
  const smsSummary = useSelector(dashboardSmsSummarySelector);

  const smsClicks = useMemo(() => {
    return {
      percentage: zeroIfNaN(smsSummary?.click / smsSummary?.total * 100),
      count: smsSummary?.click
    };
  }, [smsSummary?.click, smsSummary?.total]);

  const sendingInfoContainerStyle = useMemo(() => {
    return { display: 'flex', flex: 1 };
  }, []);

  return (
    <SendingInfo
      total={smsSummary?.total}
      link={p('sms')}
      clicks={smsClicks}
      to={`${Paths.NOTIFICATIONS}?tab=sms`}
      openedCount={null}
      testId="dashboard-sms-sendings"
      containerStyle={sendingInfoContainerStyle}
    />
  )
}

export default React.memo(SmsSendingInfo);
