import styled, { css } from 'styled-components';

import { switchStyle } from '@utils';

export const Container = styled.div`
  width: 180px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #FFF;
  background: #F4F5FD;
  margin-right: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 22px 14px;
  align-self: flex-start;
`;

export const NodeIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 76px;
  border-radius: 14px;
  margin-bottom: 14px;
  
  ${switchStyle('$actionType', {
    'connection_channel': css`
      border: 1.762px solid var(--White, #FFF);
      background: linear-gradient(208deg, #FEBB64 10.05%, #FF7153 93.88%);
      box-shadow: 0px 0px 17.619px 0px #F3C215 inset;
    `,
    'actions': css`
      border: 1px solid var(--White, #FFF);
      background: linear-gradient(208deg, #32D5FE 10.05%, #46BCFE 51.09%, #757FFE 93.88%);
      box-shadow: 0px 0px 10px 0px #508AF8 inset;
    `,
  })}
`;

export const NodeIcon = styled.img`
  ${switchStyle('$actionType', {
    'connection_channel': css`
      filter: drop-shadow(0px 8.259px 19.271px #EF7843);
    `,
    'actions': css`
      filter: drop-shadow(0px 4.688px 10.938px #508AF8);
    `,
  })}
`;

export const Title = styled.div`
  color: var(--Black-text, #303133);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
`;
