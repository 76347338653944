const colors = {
  tangaroa: '#1A232E',
  white: '#ffffff',
  oslo_gray: '#909399',
  golden_grass: '#DABB1F',
  malibu: 'linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%)',
  mischka: 'linear-gradient(180deg, #DCDFE6 21.88%, #C8CDDA 100%)',
  edge_green: '#31C447',
  dark: '#303133',
  dander: '#C8402F',
};

const Sidebar = {
  BG: colors.tangaroa,
  SELECTED_BG: '#12181F',
  HOVERED_BG: '#12181F',
  TEXT_CONTRAST: colors.white,
  TEXT_PRIMARY: '#7C94B1',
  ICON_ACTIVE: '#31C447',
  ICON_HOVER: '#FFFFFF',
  ICON_DEFAULT: '#7C94B1',
};

const Text = {
  PRIMARY: colors.dark,
  SECONDARY: 'colors.oslo_gray',
  DANGER: colors.dander,
  LIGHT: colors.mischka,
  WHITE: colors.white,
};

const Common = {
  DARK_BG: colors.tangaroa,
  APP_BG: colors.white,
  BUTTON_DEFAULT: colors.edge_green,
  DANGER: colors.dander,
  OUTLINED: colors.mischka,
};

export { Sidebar, Text, Common };
