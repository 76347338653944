import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { setFolder } from '@store/actions/creators';
import { TEMPLATES_WITHOUT_SEARCH } from '@store/reducers/templates';
import { foldersListSelector } from '@store/selectors';

import { Tooltip } from 'antd';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { useTranslation } from '@hooks';

import { by, moment, notify, sequence } from '@utils';

import { WithPermissions, Icon } from '@components';
import { DraggableTemplateItem } from '@components/ui/SmallTemplateItem/components';
import { TemplateItemText } from '@components/ui/TemplateItemText';
import { TemplateItemWorkers } from '@components/ui/TemplateItemWorkers';

import { IframePreview } from './components/IframePreview';
import { MobpushPreview } from './components/MobpushPreview';
import { MoreButtonAction } from './components/MoreButton';
import { ViberPreview } from './components/ViberPreview';
import {
  Container,
  InfoWrapper,
  Info,
  Date,
  PreviewWrapper,
  IconContainer,
  Content,
} from './styled';

import useTemplatePermissions from '../../../pages/HomeScene/pages/TemplatesScene/hooks/useTemplatePermissions';

const LargeTemplateItem = React.forwardRef(({
  id,
  testId,
  onViewClick,
  onEditClick,
  onDuplicateClick,
  onArchive,
  refreshFolders,
  dragging,
  data,
  onRefresh,
  onUnarchive,
  onStatisticsClick,
  onDeleteClick,
  search,
  small,
  folders,
  fromDashboard = false,
  style,
  type,
  isExample = false,
  used,
}, ref) => {
  const { folder } = useParams();
  const frame = useRef(null);
  const dispatch = useDispatch();
  const foldersList = useSelector(foldersListSelector);
  const [hovered, setHovered] = useState(false);
  const { t, p } = useTranslation('templates');
  const [fs] = useQueryParam('fq', withDefault(StringParam, ''));

  const handleClick = fn => sequence(e => e?.stopPropagation?.(), e => e?.domEvent?.stopPropagation?.(), fn);

  const showPlaceholder = !!(frame.current?.contentDocument?.body?.textContent && frame.current?.contentDocument?.body?.textContent?.replaceAll?.('\n', '')?.trim?.() === '');

  const handleMoveToFolder = (folder_id) => () => {
    const name = foldersList?.[type]?.[fs || TEMPLATES_WITHOUT_SEARCH]?.data?.find(by(+folder_id))?.name;

    dispatch(setFolder({
      id,
      type,
      folder_id,
      onSuccess: () => {
        notify('success', p('template_moved'), name || 'Default');
        refreshFolders?.();
        onRefresh?.();
      }
    }))
  };

  const archiveDisabled = !!data.used || !!used;

  const deleteDisabled = data.workers?.[0] !== undefined;

  const templatePermissions = useTemplatePermissions(type);

  const renderTemplatePreview = () => {
    if (type === 'email' || (type === 'web_popup' && data.code)) {
      return (
        <IframePreview type={type} frame={frame} data={data} showPlaceholder={!!showPlaceholder} hovered={hovered}/>
      )
    }

    if (type === 'viber') {
      return (
        <ViberPreview data={data}/>
      )
    }

    if (type === 'mobile_push' || type === 'web_push' || type === 'message_feed' || type === 'web_popup') {
      return (
        <MobpushPreview data={data}/>
      )
    }

    return null;
  };

  const isShiftDown = (event) => {
    return event.shiftKey;
  }

  const isCtrlDown = (event) => {
    return navigator.platform.includes('Mac') ? event.metaKey : event.ctrlKey;
  }

  const handleContainerClick = (event) => {
    event.stopPropagation();

    if (isCtrlDown(event) || isShiftDown(event)) {
      return folders.toggleSelection(id);
    }

    return onEditClick(id, data);
  };

  const handleDraggableClick = () => {
    folders.toggleSelection(id);
  };

  const isSelected = folders?.isSelected?.(id);
  const isDraggingThis = dragging?.snapshot?.isDragging;
  const isDraggingGroup = isSelected && folders?.draggingId;

  const getDraggableStyle = (style, snapshot) => {
    if (!isDraggingThis) {
      return { };
    }

    if (!snapshot?.isDropAnimating) {
      return style;
    }

    // eslint-disable-next-line no-unsafe-optional-chaining
    const { curve } = snapshot?.dropAnimation;

    const translate = `translate(0, 0)`;

    return {
      ...style,
      transform: `${translate}`,
      opacity: 0,
      transition: `all ${curve} 420ms`,
    };
  };

  const handleView = () => {
    onViewClick(id, data);
  };

  const handleDuplicate = () => {
    onDuplicateClick(id, data);
  };

  const handleDelete = () => {
    onDeleteClick(id, data);
  };

  const handleArchive = () => {
    onArchive([id]);
  };

  const handleUnarchive = () => {
    onUnarchive([id]);
  };

  const handleStatistics = () => {
    onStatisticsClick(id, data);
  };

  return (
    <Container
      $small={small}
      ref={ref}
      {...(dragging?.provided?.dragHandleProps || {})}
      onClick={handleContainerClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={e => e.stopPropagation()}
    >
      {(!small && !fromDashboard) && (
        <DraggableTemplateItem
          selected={isSelected}
          large
          ref={dragging.provided.innerRef}
          selectionCount={folders.selection.selectedItems?.length}
          templateName={data.name}
          active={isDraggingThis}
          isDragging={isDraggingGroup}
          onClick={handleDraggableClick}
          {...dragging.provided.draggableProps}
          style={getDraggableStyle(dragging.provided.draggableProps.style, dragging.snapshot)}
        />
      )}
      <Content
        isExample={isExample}
        data-testid={testId}
        style={{ cursor: isExample ? 'default' : 'pointer', ...(style || {}) }}
      >
        <PreviewWrapper isExample={isExample}>
          {renderTemplatePreview()}
        </PreviewWrapper>
        <InfoWrapper>
          <Info>
            <TemplateItemText search={search} trunc={100}>
              {data.name}
            </TemplateItemText>
            <Date>Updated: {moment(data.created_at).fromNow()}</Date>
          </Info>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10, minWidth: 114, justifyContent: 'flex-end', paddingRight: 12 }}>
            {isExample && (
              <Tooltip title={t('labels.description')}>
                <IconContainer transparent>
                  <Icon name="Info-icon" color="#31C447" size={14} />
                </IconContainer>
              </Tooltip>
            )}
            <TemplateItemWorkers workers={data.workers} />
            <WithPermissions name={templatePermissions.VIEW}>
              <Tooltip title={p('view_template')}>
                <IconContainer data-testid={`${testId}-view`} onClick={handleClick(handleView)} style={{ marginRight: 2 }}>
                  <Icon name="Show-icon" size={18} />
                </IconContainer>
              </Tooltip>
            </WithPermissions>
            <MoreButtonAction
              testId={testId}
              data={data}
              handleClick={handleClick}
              handleDuplicate={handleDuplicate}
              templatePermissions={templatePermissions}
              onStatisticsClick={onStatisticsClick}
              isExample={isExample}
              fromDashboard={fromDashboard}
              handleStatistics={handleStatistics}
              handleMoveToFolder={handleMoveToFolder}
              folder={folder}
              foldersList={foldersList?.[type]?.[fs || TEMPLATES_WITHOUT_SEARCH] || { data: [] }}
              handleUnarchive={handleUnarchive}
              deleteDisabled={deleteDisabled}
              archiveDisabled={archiveDisabled}
              handleDelete={handleDelete}
              handleArchive={handleArchive}
            />
          </div>
        </InfoWrapper>
      </Content>
    </Container>
  );
});

export default LargeTemplateItem;
