import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

import { TextVariants } from '@constants';

import { styledProp } from '@utils';

import * as Colors from '@res/theme/colors';

import { Text } from '@components';

export const Container = styled.div`
  width: 220px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  position: relative;
  outline: none;
  border-bottom: none;
  text-decoration: none;
  
  ${styledProp('$opened', `
  `, `
    &:hover {
      background-color: ${Colors.Sidebar.HOVERED_BG};
    }
  `)}
  
  ${styledProp('$active', `
    background-color: ${Colors.Sidebar.HOVERED_BG};
  `)}
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7px 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  transition: background-color 280ms ease;
  background-color: ${Colors.Sidebar.BG};
  ${styledProp('$active', css`
     background-color: ${Colors.Sidebar.SELECTED_BG};
  `, css`
    background-color: ${Colors.Sidebar.BG};
`)}
  &:hover {
    background-color: ${Colors.Sidebar.HOVERED_BG};
  }
  flex-wrap: nowrap;
  width: 220px;
  white-space: nowrap;
  cursor: pointer;
`;

export const ItemInfo = styled(animated.div)`
  white-space: nowrap;
  color: ${Colors.Sidebar.ICON_HOVER};
  z-index: -1;
  position: fixed;
  //height: 40px;
  background-color: ${Colors.Sidebar.HOVERED_BG};
  left: 48px;
  border-radius: 0 9px 9px 0;
  overflow: hidden;
  min-width: 120px;
  
  ${styledProp('pad', `
  `, css`
    padding: 7px 12px;
  `)}
  
  ${styledProp('mg36', `
    margin-top: 36px;
  `)}
  
  ${styledProp('l220', `
    left: 220px;
  `)}
`;

export const ItemRow = styled(Text).attrs({ width: 140 })`
  padding: 7px 12px;
  user-select: none;
  
  ${styledProp('$header', css`
    padding: 7px 12px;
  `, css`
    transition: background-color 280ms ease;

    &:hover {
      background: #1A232E;
      
      & * {
        color: #fff;
      }
    }

    cursor: pointer;
  `)}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${({ active }) => active ? Colors.Sidebar.TEXT_CONTRAST : Colors.Sidebar.TEXT_PRIMARY};
  ${styledProp('notSubcategory', `
    &:hover {
      color: ${Colors.Sidebar.TEXT_CONTRAST};
      & svg {
        stroke: ${Colors.Sidebar.TEXT_CONTRAST};
      }
    }
  `)}
  transition: 230ms all ease;
  display: flex;
  padding-top: 14px;
  align-items: center;
  line-height: 14px;
  white-space: nowrap;
  font-size: 14px;
  height: 23px;
`;

export const ActiveIndicator = styled.div`
  opacity: ${({ active }) => Number(active)};
  transition: 300ms ease all;
  
  position: absolute;
  background: ${Colors.Sidebar.ICON_ACTIVE};
  top: 0;
  left: 0;
  width: 2px;
  height: 26px;
  border-radius: 0 2px 2px 0;
`;

export const Accordion = styled.div`
  transition: 300ms ease all;
  display: flex;
  flex-direction: column;
  max-height: ${({ $opened }) => $opened ? '800px' : '0px'};
  padding: 0;
  overflow: hidden;
  margin-right: -98px;
  margin-left: -50px;
  
  ${styledProp('$opened', css`
    padding-top: 10px;
  `)}
`;

export const SubItemContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`

export const BetaLabel = styled.div`
    border-radius: 4px;
    font-family: 'Manrope-Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    padding: 2px 4px 1px;
    position: absolute;
    right: 15px;
    text-transform: uppercase;
    background: var(--Colors-Additional-System-opacity-medium, rgba(50, 83, 255, 0.12));
`

export const BetaText = styled.span`
  color: rgba(50, 83, 255, 1);
`

export const AccordionRow = styled(Text).attrs({ variant: TextVariants.MENU_1 })`
  padding: 5px 0;
  padding-left: 50px;
  font-size: 14px;
  color: #7C94B1;
  height: 36px;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: all 280ms ease;
  ${styledProp('$active', `
      color: white;
  `)}
  ${styledProp('$isBeta', `
    &:after {
      content: "Beta";
      background: #31C447;
      border-radius: 4px;
      color: #FFFFFF;
      font-family: 'Manrope-Medium';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 100%;
      padding: 2px 4px 1px;
      margin-left: 6px;
    }
  `)}
  &:active {
    color: #909399;
    text-decoration: none;
  }
  &:hover {
    background: #1A232E;
    color: white;
  }
`;

export const ItemContent = styled.div`
  white-space: nowrap;
    margin: 0;
    width: 100%;
    padding-left: 14px;
`
export const ItemContainer = styled.div`
    .menu-item {
      & > div {
          display: flex;
      }
    }
    [class^="_list_"] {
      user-select: none;
      div[name] {
        width: 100%;
      }
    }
`
export const MenuItemIcon = styled.div`
   
`
