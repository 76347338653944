import { useCallback } from 'react';
import { useNavigate as useReactRouterNavigate } from 'react-router';

import { hashAppId } from '@utils';

import { useCurrentWorkspace } from './index';

const useNavigate = () => {
  const navigate = useReactRouterNavigate();
  const currentApp = useCurrentWorkspace();

  const n = useCallback((path, ...rest) => {
    if (path === -1) {
      navigate(path, ...rest);
    }

    navigate(`/app/${hashAppId(currentApp?.id)}${path}`, ...rest);
  }, [navigate, currentApp?.id]);

  if (!currentApp) {
    return navigate;
  }

  return n;
};

export default useNavigate;
