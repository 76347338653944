import { SqlQueryActionTypes } from '@store/actions/types';
import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeLatest, put, throttle } from 'redux-saga/effects';

function* getQueriesSaga(action) {
  yield put(
    createRequestAction(action, {
      endpoint: `/api/seg/settings/query-values`,
    })
  );
}

function* getQuerySaga(action) {
  yield put(
    createRequestAction(action, {
      endpoint: `/api/seg/settings/query-values/${action.payload.id}`,
    })
  );
}

function* createQuerySaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/seg/settings/query-values`,
      body: action.payload.query,
    })
  );
}

function* updateQuerySaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'put',
      endpoint: `/api/seg/settings/query-values/${action.payload.id}`,
      body: action.payload.query,
    })
  );
}

function* getSqlQueryExample(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/seg/settings/query-values/example`,
      body: {
        query: action.payload.query,
      }
    })
  );
}

function* deleteSqlQuerySaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `/api/seg/settings/query-values/${action.meta.id}`,
    })
  );
}

export default function* sqlQueryRootSaga() {
  yield all([
    takeLatest(SqlQueryActionTypes.GET_SQL_QUERIES, getQueriesSaga),
    takeLatest(SqlQueryActionTypes.GET_SQL_QUERY, getQuerySaga),
    takeLatest(SqlQueryActionTypes.CREATE_SQL_QUERY, createQuerySaga),
    takeLatest(SqlQueryActionTypes.UPDATE_SQL_QUERY, updateQuerySaga),
    takeLatest(SqlQueryActionTypes.DELETE_SQL_QUERY, deleteSqlQuerySaga),
    throttle(1000, SqlQueryActionTypes.GET_EXAMPLE, getSqlQueryExample),
  ]);
}
