import { tryParse } from "@utils/common";
import { by } from "@utils/functions";

import { RFMColorsConfig } from "@/constants";

export const getRfmOptions = (rfmResources, t) => {

  const rfmAnalyticsOptions = rfmResources || [].map(({ id, name, resources }) => ({
    value: id,
    label: name,
    options: (resources || [])
      .filter(({ parent_segment_id }) => !parent_segment_id)
      .flatMap(({ segment_id, name, monetary_aggregate }) => [
        {
          value: segment_id,
          label: t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`),
          cfg: RFMColorsConfig.find(by('name', name)),
        },
        ...(resources || [])
          .filter(by('parent_segment_id', segment_id))
          .map(({ segment_id, name: subName }) => {
            const monetaryValues = (tryParse(monetary_aggregate) || []).find(by('monetary_ranks', +subName[subName.length - 1]));

            return {
              value: segment_id,
              label: `${t(`rfm_analytics.segment_title_${RFMColorsConfig.find(by('name', name))?.title}`)}.${(+monetaryValues.min).toFixed(2)} - ${(+monetaryValues.max).toFixed(2)} revenue`,
              cfg: RFMColorsConfig.find(by('name', name)),
              rank: +subName[subName.length - 1],
            };
          })
      ])
  }));

  return rfmAnalyticsOptions;
}
