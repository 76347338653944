import { useMemo } from 'react';

import { Container, Highlight } from './styled';

const HighlightText = ({ search, startAt = 3, children, ...props }) => {
  const getTrimmed = (text) => {
    return text;
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const getHighlighted = (text, search) => {
    const trimmed = getTrimmed(text);

    if (!search || search.length < startAt) {
      return trimmed;
    }

    const escapedSearch = escapeRegExp(search);
    const regex = new RegExp(`(${escapedSearch})`, 'gi');
    const parts = trimmed.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <Highlight key={index}>{part}</Highlight>
      ) : (
        part
      )
    );
  };

  const highlighted = useMemo(() => getHighlighted(children, search), [search, children]);

  return (
    <Container {...props}>
      {highlighted}
    </Container>
  );
};

export default HighlightText;
