import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 320px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 16px;
  border-radius: 5px;
  border: 1px dashed var(--uploader-upload-border-default, #D3DBE7);
  background: var(--uploader-upload-bg-default, #FFF);
  margin-top: 30px;
`;

export const Title = styled.div`
  overflow: hidden;
  color: var(--text-secondary-body, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  width: 100%;
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 140px;
`;

export const ItemTitle = styled.div`
  color: var(--input-input-txt-lable, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 12px;
  line-height: 18px;
`;

export const ItemData = styled.div`
  color: var(--input-input-txt, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;
