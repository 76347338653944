import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Dropdown } from 'antd';

import { useOutsideClickListener, useTranslation } from '@hooks';

import {
  addNodes,
  deleteConnection,
  connectNodes,
  editNode
} from '@res/lottie';

import { ActionButton } from '@components';

import { GuideItem, Key } from './components';
import { Container, Row, Label, Arrow, Col, Plus } from './styled.js';

const animations = [
  addNodes,
  connectNodes,
  editNode,
  deleteConnection,
];

const info = [
  {
    title: 'add_nodes_to_canvas',
    description: 'dnd_description',
  },
  {
    title: 'connect_code',
    description: 'connect_description',
  },
  {
    title: 'edit_node',
    description: 'edit_description',
  },
  {
    title: 'disconnect_nodes',
    description: 'disconnect_description',
  },
];

const configs = animations.map((data) => ({
  loop: true,
  autoplay: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  }
}));

const width = 156;
const height = 47;

const HotkeysDropdown = ({ config, children }) => {
  const [currentActive, setCurrentActive] = useState(0);
  const [visible, setVisible] = useState(!localStorage.getItem('workflowTutorialHidden'));
  // eslint-disable-next-line no-unused-vars
  const [_, update] = useState(false);
  const ref = useRef();
  const { p } = useTranslation('workflow_page');

  const isFirstTimeVisible = !localStorage.getItem('workflowTutorialHidden');

  const handleFirstTimeClose = () => {
    localStorage.setItem('workflowTutorialHidden', 'true');
    setVisible(false);
    update(u => !u);
  };

  useOutsideClickListener(ref, () => {
    localStorage.setItem('workflowTutorialHidden', 'true');
    setVisible(false);
    update(u => !u);
  }, []);

  const handleAnimationEnd = useCallback(() => {
    setCurrentActive(ca => (ca + 1) % animations.length);
  }, [setCurrentActive]);

  useEffect(() => {
    setCurrentActive(0);
  }, [visible]);

  const listeners = useMemo(() => [
    {
      eventName: 'loopComplete',
      callback: handleAnimationEnd,
    }
  ], [handleAnimationEnd]);

  return (
    <Dropdown
      trigger={['hover']}
      placement="bottomRight"
      open={visible || isFirstTimeVisible}
      onOpenChange={setVisible}
      overlay={(
        <Container ref={ref}>
          <Arrow>
            <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 4L4 4L0 4L4 1.27146e-07L8 4Z" fill="rgba(250, 250, 250, 0.8)"/>
            </svg>
          </Arrow>
          <Row style={{ padding: '37px 0' }}>
            <Col>
              <GuideItem
                style={{ marginBottom: 20 }}
                options={configs[0]}
                width={width}
                listeners={listeners}
                height={96}
                isPlaying={currentActive === 0}
                title={p(info[0].title)}
                description={p(info[0].description)}
              />
              <GuideItem
                options={configs[2]}
                width={width}
                height={96}
                listeners={listeners}
                isPlaying={currentActive === 2}
                title={p(info[2].title)}
                description={p(info[2].description)}
              />
            </Col>
            <Col style={{ marginLeft: 50, marginRight: 50 }}>
              <GuideItem
                style={{ marginBottom: 20 }}
                options={configs[1]}
                width={width}
                height={height}
                listeners={listeners}
                isPlaying={currentActive === 1}
                title={p(info[1].title)}
                description={p(info[1].description)}
              />
              <GuideItem
                options={configs[3]}
                width={width}
                height={height}
                listeners={listeners}
                isPlaying={currentActive === 3}
                title={p(info[3].title)}
                description={p(info[3].description)}
              />
            </Col>
            <Col>
              {config.map(({ label, keys }) => (
                <Row key={label}>
                  {keys.map((key, i) => (
                    <>
                      <Key
                        key={key}
                        buttonKey={key}
                      />
                      {i !== keys.length - 1 && (
                        <Plus>+</Plus>
                      )}
                    </>
                  ))}
                  <Label>{label}</Label>
                </Row>
              ))}
            </Col>
          </Row>
          {isFirstTimeVisible && (
            <Row style={{ padding: '18px 0', width: '100%', justifyContent: 'center', borderTop: '1px solid #DCDFE6' }}>
              <ActionButton
                onClick={handleFirstTimeClose}
                style={{ width: 150, height: 26, borderRadius: 7 }}
                appearance="darker"
                renderIcon={p('ok_thank_you')}
              />
            </Row>
          )}
        </Container>
      )}
    >
      {children}
    </Dropdown>
  );
}

export default HotkeysDropdown;

