import styled, { css } from 'styled-components';

import { extract, styledProp } from '@utils';

export const Container = styled.div`
  transition: all 330ms ease;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
  border-radius: 12px;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  width: 95%;
  max-width: 95%;
  position: relative;
  margin-top: 50px;
  margin-left: 17px;
  
  ${styledProp('$mobilePush', css`
    box-shadow: none;
    border-radius: 12px;
    //background: linear-gradient(267.47deg, rgba(255, 255, 255, 0.553) 1.62%, rgba(255, 255, 255, 0.385) 92.73%);
    backdrop-filter: blur(15px);
  `)}

  ${styledProp('$opened', css`
    background: #fff;
    padding-bottom: 22px;
  `, css`
  `)}
`;

export const TitleContainer = styled.div`

`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  max-width: 140px;
  
  ${styledProp('$mobilePush', css`
    color: #303133;
  `)}
`;

export const Image = styled.div`
  background: url(${extract('image')}) center / cover no-repeat;
  transition: 330ms ease all;
  margin: 8px 0 10px 0;
  position: absolute;
  bottom: -140px;
  left: 0;
  right: 0;
  border-radius: 0 0 12px 12px;
  overflow: hidden;

  ${styledProp('$opened', css`
    height: 140px;
  `, css`
    max-height: 0px;
    margin-bottom: 0;
  `)}
`;

export const Footer = styled.div`
  background: #F9F9F9;
  border-radius: 5.73333px;
  filter: drop-shadow(0px 0px 3.58333px rgba(0, 0, 0, 0.3));
  overflow: hidden;
  //padding: 3px 0;
  margin-top: 8px;
  transition: 330ms ease all;

  ${styledProp('$opened', css`
    transform: translateY(0);
    opacity: 1;
  `, css`
    opacity: 0;
    transform: translateY(-30px);
  `)}
`;

export const CompanyRow = styled.div`
  padding: 8px 10px 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobpushLogo = styled.div`
  margin-right: 5px;
  ${styledProp('src', css`
    width: 16px;
    height: 16px;
    background: url(${extract('src')}) center / cover no-repeat !important;
    border: none;
  `)}
`;

export const Company = styled.div`
  font-family: Helvetica;
  font-size: 12px;
  color: #838383;

  ${styledProp('mobilePush', css`
    color: #303133;
    //font-weight: 600;
  `)}
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex: 1;

  ${styledProp('$opened', css`
    width: 100%;
  `, css`
  `)}
`;

export const Now = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  line-height: 130%;
  color: #909399;
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 10px;
  overflow: hidden;
`;

export const MobilePushButtonsContainer = styled.div`
  z-index: 999999;
  margin-top: 10px;
  background: transparent;
  width: 272px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${styledProp('$preview', css`
    width: 320px;
  `)}
  
  ${styledProp('$opened', css`
    margin-top: 140px;
  `)}
`;

export const IOSButtonWarning = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  background: #FFFAED;
  border-radius: 7px;
  border: 1px solid #FFDC7B;
  font-size: 13px;
  margin-bottom: 16px;
`;

export const MobpushButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 5px 5px 15px;
  width: 217px;
  background: linear-gradient(267.47deg,rgba(255,255,255,0.553) 1.62%,rgba(255,255,255,0.385) 92.73%);
  margin-top: 1px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  min-height: 30px;

  ${styledProp('$preview', css`
    //width: 320px;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
  `)}
  
  ${styledProp('$first', css`
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 0;
  `)}

  ${styledProp('$last', css`
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  `)}
`;

export const Text = styled.div`
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;

  ${styledProp('$opened', css`
    max-width: 100%;
    -webkit-line-clamp: 10;
  `, css`
  `)}
`;

export const CompanyIcon = styled.div`
  background-image: url(${extract('image')});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 18px;
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  margin-left: 26px;
  border-radius: 4px;
  overflow: hidden;

  ${styledProp('ghost', css`
    height: 40px;
    opacity: 0;
  `)}
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 0;
  font-size: 16px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #EBEBEB;
`;
