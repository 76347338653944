import React, { useMemo } from 'react';
import { useSelector } from "react-redux";

import { dashboardContentCardSummarySelector } from "@store/selectors/dashboard";

import { useTranslation } from "@hooks";

import { Paths } from "@constants";

import { zeroIfNaN } from "@utils";

import { SendingInfo } from "../SendingInfo";

const CardSendingInfo = () => {
  const { p } = useTranslation('dashboard_page');
  const cardSummaryData = useSelector(dashboardContentCardSummarySelector);

  const cardSummary = useMemo(() => {
    return {
      total: cardSummaryData?.total || 0,
      click: cardSummaryData?.click || 0,
      open: cardSummaryData?.open || 0
    }
  }, [cardSummaryData?.total, cardSummaryData?.click, cardSummaryData?.open])

  const sendingInfoContainerStyle = useMemo(() => {
    return { display: 'flex', flex: 1 };
  }, []);

  const cardClicks = useMemo(() => {
    return {
      percentage: zeroIfNaN(cardSummary?.click / cardSummary?.total * 100),
      count: cardSummary?.click
    };
  }, [cardSummary?.click, cardSummary?.total]);

  const cardOpens = useMemo(() => {
    return {
      percentage: zeroIfNaN(cardSummary?.open / cardSummary?.total * 100),
      count: cardSummary?.open
    };
  }, [cardSummary?.open, cardSummary?.total]);

  return (
    <SendingInfo
      total={cardSummary?.total}
      openedCount={cardOpens}
      link={p('card_feed')}
      clicks={cardClicks}
      testId="dashboard-card-sendings"
      to={`${Paths.NOTIFICATIONS}?tab=content-cards`}
      containerStyle={sendingInfoContainerStyle}
    />
  )
}

export default React.memo(CardSendingInfo);
