import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: #FFF;
  width: 240px; 
  height: 360px;
    overflow: hidden;

  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
`;

export const Header = styled.div`
  background: var(--cards-bg, #F8FAFE);
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid rgba(189, 203, 250, 1);
  padding: 14px;
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;
  margin-top: 2px;
  padding: 0 10px;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope-SemiBold;
  font-size: 14px;
  line-height: 20px;

  ${styledProp('selected', css`
    color: #31C447;
  `)}
`;

export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #31C447;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ $tab: tab }) => {
    return css`
      width: ${tab?.getBoundingClientRect()?.width}px;
      left: ${tab?.offsetLeft}px;
    `;
  }}
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

export const List = styled.div`
  padding-top: 19px;
    overflow-y: auto;
`;

export const ListOption = styled.div`
  padding: 6px 16px;
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
  
  transition: 290ms ease all;
  
  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;

export const NodeThumbnail = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  position: relative;
  overflow: visible;

  ${switchStyle('$type', {
    'entry_point': css`
      background: linear-gradient(208deg, #5BE3CE 10.05%, #55D58D 93.88%);
      box-shadow: 0px 0px 10px 0px #3FCC8E inset;
    `,
    'connection_channel': css`
      background: linear-gradient(208deg, #FEBB64 10.05%, #FF7153 93.88%);
      box-shadow: 0px 0px 10px 0px #F3C215 inset;
    `,
    'actions': css`
      background: linear-gradient(208deg, #32D5FE 10.05%, #46BCFE 51.09%, #757FFE 93.88%);
      box-shadow: 0px 0px 10px 0px #508AF8 inset;
    `,
  })}
`;
