import { useState } from 'react';

import { Checkbox, Dropdown } from 'antd';

import { useTranslation } from '@hooks';

import { Icon, NoData } from '@components';

import { Overlay, Container, TitleRow, Title, ValueContainer, IconContainer } from './styled';

import { Error } from '../Select/styled';

const Multiselect = ({
  value,
  onChange,
  options = [],
  additionalField = null,
  disabled = false,
  containerStyle,
  title,
  transparentTitle = false,
  placement,
  error,
  absoluteError,
  placeholder,
  dropdownProps = {},
  getParentElement,
  testId,
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const { t, e } = useTranslation();

  const handleOptionClick = (changed, values) => ({ target: { checked }, stopPropagation, preventDefault }) => {
    stopPropagation();
    preventDefault();

    if (checked) {
      if (values) {
        onChange([]);
      } else {
        onChange([...value, changed]);
      }
    } else {
      if (values) {
        onChange([]);
      } else {
        onChange(value.filter(v => String(v) !== String(changed)));
      }
    }
  }

  const isAllChosen = () => {
    const valuesOption = options.find(o => !!o.values);
    return !!valuesOption && value.every(v => valuesOption.values.indexOf(String(v)) !== -1);
  }

  const resolveValue = () => {
    const valuesOption = options.find(o => !!o.values);
    if (!!valuesOption && (value.length === 0 || value.length === valuesOption.values.length)) {
      return t(valuesOption.label);
    }

    if (value.length === 0) {
      return placeholder || t('labels.choose');
    }

    if (value.length === 1) {
      return t(options.find(({ value: v }) => String(v) === String(value[0]))?.label);
    }

    return t(`labels.chosen`, { n: value.length });
  }

  return (
    <div data-testid={testId + '-container'} style={containerStyle}>
      {title && (
        <TitleRow titleMarginLeft>
          <Title transparentTitle={transparentTitle} >
            {title}
          </Title>
        </TitleRow>
      )}
      <Dropdown
        style={{ width: 'auto' }}
        trigger={['click']}
        onVisibleChange={setOpened}
        getPopupContainer={getParentElement}
        placement={placement || 'bottomLeft'}
        disabled={disabled}
        {...dropdownProps}
        overlay={(
          <Overlay onClick={e => e.stopPropagation()}>
            {options.map(({ value: curr, label, values }) => (
              <Checkbox
                key={label}
                style={{ marginLeft: 0, minHeight: '23px' }}
                checked={values ? !value.length : value.indexOf(String(curr)) !== -1}
                onClick={e => e.stopPropagation()}
                onChange={handleOptionClick(String(curr), values)}
              >
                <div data-testid={`${testId}-value-${t(label)}`} style={{ whiteSpace: 'nowrap' }} onClick={e => e.stopPropagation()}>{t(label)}</div>
              </Checkbox>
            ))}
            { !options.length && <NoData /> }
            { additionalField && additionalField }
          </Overlay>
        )}
      >
        <Container error={error} disabled={disabled} {...props}>
          <ValueContainer error={error} value={!!value.length || isAllChosen()}>
            {resolveValue()}
          </ValueContainer>
          <IconContainer $opened={opened}>
            <Icon name="Dropdown-arrow" size={10} />
          </IconContainer>
        </Container>
      </Dropdown>
      {error && <Error style={{ ...(absoluteError ? { position: 'absolute', top: '42px', } : {}) }}>{e(error)}</Error>}
    </div>
  );
};

export default Multiselect;
