import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  background: #F9FBFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  padding: 23px 20px 11px 20px;
  margin-bottom: 15px;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderCell = styled.div`
  flex: 1;
  display: flex;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #303133;

  ${styledProp('header', css`
    font-size: 13px;
    line-height: 100%;
    color: #909399;
    margin-bottom: 24px;
  `, css`
    margin-top: 11px;
  `)}
`;

export const HeaderCellDiff = styled.div`
  ${styledProp('positive', css`
    color: #24993E;
  `)}
  ${styledProp('negative', css`
    color: #C8402F;
  `)}
`;

