import { motion } from 'framer-motion';
import styled from 'styled-components';

import { switchStyle } from '@utils';

export const Container = styled(motion.div).attrs({
  initial: { opacity: 0, y: 60, scale: 0.98 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: 60, scale: 0.98 },
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 110px;
  gap: 54px;
  position: relative;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px;
  position: absolute;
  top: 119px;
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  justify-content: center;
  margin-top: 54px;
`;

export const AddWorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const WorkflowCard = styled.div`
  border: 1px solid #D3DBE7;
  border-radius: 10px;
  width: 596px;
  background-color: #F8FAFE;
`;

export const WorkflowCardInnerTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-family: Manrope-Medium;
  font-size: 21px;
  padding: 20px 18px;
  gap: 10px;
  border-bottom: 1px solid #D3DBE7;
  flex-wrap: wrap;
`;

export const WorkflowCardInnerBottom = styled.div`
  padding: 20px;
  
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
`
export const DeleteIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  
  &:hover{
    cursor: pointer;
  }
`

export const ExitItem = styled.div`
  border: 1px solid #D3DBE7;
  border-radius: 7px;
  padding: 10px 12px;
  width: fit-content;
`;

export const ExistItemLabel = styled.span`
 
  ${switchStyle('$selected',{
    [true]: `
      color: var(--text-primary-body, #1A232E);
    `,
  })}
`;

export const ExitItemDisclaimer = styled.div`
  background-color: #FFFAED;
  border: solid 1px #FFDC7B;
  text-align: center;
  border-radius: 7px;
  padding: 9px 0;
  margin-top: 22px;
`;

export const WorkflowCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ExitPoints = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
