import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #303133;
  text-align: center;
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  text-align: center;
  margin-bottom: 20px;
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #E5E8ED;
  grid-gap: 1px;
  margin-bottom: 20px;
  width: 100%;
`;

export const CardContainer = styled.div`
  position: relative;
  background: transparent;
`;

export const BorderDivider = styled.div`
  position: absolute;
  width: 19px;
  bottom: -9px;
  right: -9px;
  height: 19px;
  //z-index: 1;
  background: #fff;
`;

export const DummyCard = styled.div`
  height: 150px;
  display: flex;
  flex: 1;
  position: relative;
  z-index: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
`;
