import styled from 'styled-components';

export const Container = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(124, 148, 177, 1);
  line-height: 18px;
  margin-bottom: 5px;
  font-family: Manrope-Medium;
`;
