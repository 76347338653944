import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const EditorContainer = styled.div`
  width: 100%;
  margin-top: 12px;

  ${styledProp('onboard', `
    border: 3px solid #BDD1F6 !important;
    border-radius: 14px !important;
  `)}
`;

export const Editor = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 70px);
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
`;

export const PreviewTitle = styled.div`
  position: absolute;
  left: 0px;
  top: -25px;
  font-size: 18px;
  line-height: 21px;
  font-family: Manrope-SemiBold;
`;
