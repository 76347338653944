import { Select } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 100%;
  color: #909399;
  padding-left: 4px;
  margin-bottom: 7px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 7px;
  height: 36px;
  overflow: hidden;
  padding: 0 7px 0 7px;
  margin-right: 20px;
  margin-bottom: 16px;
`;

export const StyledSelect = styled(Select)`
  border: none !important;
  min-width: 110px !important;
  height: 36px !important;
  
  & .ant-select-selector {
    border: none !important;
    height: 36px !important;
  }
`;
