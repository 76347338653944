import axios from 'axios';
import { parse } from 'lossless-json';

import { API_URL } from '@constants';

function convertLosslessNumber (key, value) {
  if (value && value.isLosslessNumber) {
    try {
      if (value.valueOf() > Number.MAX_SAFE_INTEGER) {
        return value.toString();
      }

      return value.valueOf();
    } catch (e) {
      return value.value;
    }
  }
  else {
    return value;
  }
}

const makeApiRequest = (config) => axios({
  ...config,
  withCredentials: true,
});

const ApiService = {
  call: (
    method = 'get',
    endpoint,
    body,
    params,
    headers = {},
    responseType,
    onUploadProgress,
    disableTimeout = false,
  ) => {
    const parseParam = (acc, [key, val]) => {
      if (typeof val === 'object' && val.toJSON) {
        return {
          ...acc,
          [key]: val.toJSON(),
        };
      }
      if (Array.isArray(val)) {
        return {
          ...acc,
          [key]: val.map(v => {
            if (typeof v === 'object' && v?.toJSON) {
              return v.toJSON();
            }

            return v;
          }),
        };
      }

      return {
        ...acc,
        [key]: val,
      };
    };
    const parsedParams = Object.entries(params || {}).reduce(parseParam, {});

    return makeApiRequest({
      method,
      url: endpoint,
      data: body,
      params: parsedParams,
      headers: { ...headers, 'Target-URL': API_URL },
      transformResponse: (res) => {
        if (!res) {
          return res;
        }
        try {
          return parse(res, convertLosslessNumber);
        } catch (e) {
          return res;
        }
      },
      responseType,
      onUploadProgress,
      timeout: disableTimeout ? undefined : 30000,
    });
  }
};

export default ApiService;
