import uniqBy from 'lodash.uniqby';

import { extract } from '@utils';

import { UserActionTypes, WorkflowActionTypes, WorkspacesActionTypes } from '../actions/types';

const initialState = {
  workflows: {
    loading: true,
  },
  program: {
    loading: true,
  },
  workers: {
    ['templates']: {
      status: -1,
      flow: { nodes: [], edges: [] },
      tree_map: { children: [] },
      id: 'templates',
    },
    loading: true,
  },
  options: {
    loading: false,
  },
  analytics: {
    loading: false,
  },
  programAnalytics: {
    loading: false,
  },
  nodeAnalytics: {
    loading: false,
  },
  sendingNodeAnalytics: {

  },
  nodeTrendAnalytics: {
  },
  loading: false,
  editMetadata: {},
  publishHistory: {
    loading: false,
  },
  workflowLogs: {},
  defaultNodes: [],
  smsNodeAnalytics: {
    data: {},
    loading: false,
  },
  emailNodeAnalytics: {
    data: {},
    loading: false,
  },
  webpushNodeAnalytics: {
    data: {},
    loading: false,
  },
  webhookNodeAnalytics: {
    data: {},
    loading: false,
  },
  viberNodeAnalytics: {
    data: {},
    loading: false,
  },
  mobilePushAnalytics: {
    data: {},
    loading: false,
  },
  webpopupNodeAnalytics: {
    data: {},
    loading: false,
  },
  webpopupElementAnalytics: {
    data: {},
    loading: false,
  },
  entry_count: {

  },
  horizonCampaigns: {

  },
  horizonBonuses: {

  },
  horizonCurrencies: {
    loading: false,
  },
  autoSave: {
    enabled: true,
  },
  horizonFreeSpins: {
  },
  horizonFreeBets: {
  },
  horizonTags: {
  },
  initialWorker: {

  },
  goalsAnalytics: {

  },
  isSaving: false,
  exitTriggers: {
    loading: false,
  },
};

export const WITHOUT_SEARCH = Symbol('without_search');

const workflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case WorkflowActionTypes.SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case WorkflowActionTypes.SAVE_SUCCESS:
    case WorkflowActionTypes.SAVE_ERROR:
      return {
        ...state,
        isSaving: false,
      };
    case WorkflowActionTypes.GET_WORKFLOW_PROGRAM:
      return {
        ...state,
        program: {
          loading: true,
          ...state.program,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_PROGRAM_SUCCESS:
      return {
        ...state,
        program: {
          ...action.payload.data,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_PROGRAM_ERROR:
      return {
        ...state,
        program: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_ENTRY_COUNT:
      return {
        ...state,
        entry_count: {
          ...(state.entry_count || {}),
          [action.meta.id]: {
            loading: true,
          },
        },
      };
    case WorkflowActionTypes.GET_ENTRY_COUNT_SUCCESS:
      return {
        ...state,
        entry_count: {
          ...(state.entry_count || {}),
          [action.meta.id]: {
            ...action.payload,
            loading: false,
          },
        },
      };
    case WorkflowActionTypes.GET_ENTRY_COUNT_ERROR:
      return {
        ...state,
        entry_count: {
          ...(state.entry_count || {}),
          [action.meta.id]: {
            loading: false,
          },
        },
      };
    case WorkflowActionTypes.GET_EXIT_TRIGGERS:
      return {
        ...state,
        exitTriggers: {
          ...state.exitTriggers,
          loading: true,
        }
      };
    case WorkflowActionTypes.GET_EXIT_TRIGGERS_SUCCESS:
      return {
        ...state,
        exitTriggers: {
          loading: false,
          [action.meta.id]: {
            ...action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_EXIT_TRIGGERS_ERROR:
      return {
        ...state,
        exitTriggers: {
          ...state.exitTriggers,
          loading: false,
        }
      };
    case WorkflowActionTypes.CLEAR_ENTRY_COUNT:
      return {
        ...state,
        entry_count: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_WORKER:
      return {
        ...state,
        workers: {
          ...state.workers,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_WORKER_SUCCESS:
      const migrated = !action.payload.data?.flow?.nodes && !!state.workers[action.payload.data.id]?.flow?.nodes;

      return {
        ...state,
        workers: {
          ...state.workers,
          [action.payload.data.id]: {
            ...action.payload.data,
            tree_map: migrated && action.payload.data.status !== 0 ? state.workers[action.payload.data.id]?.tree_map : action.payload.data?.tree_map,
            flow: migrated ? state.workers[action.payload.data.id]?.flow : action.payload.data?.flow,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.UPDATE_WORKFLOW_WORKER:
      const { id, tree_map, styles, flow } = action.payload;

      return {
        ...state,
        workers: {
          ...state.workers,
          [id]: {
            ...(state.workers[id] || {}),
            tree_map,
            flow,
            cached: true,
            styles: {
              ...(state.workers[id]?.styles || {}),
              styles,
            },
          },
        },
      };
    case WorkflowActionTypes.CLEAR_WORKFLOW_WORKER:
      return {
        ...state,
        workers: {
          ...state.workers,
          [action.payload.id]: undefined,
        },
      };
    case WorkflowActionTypes.GET_WORKFLOW_WORKER_ERROR:
      return {
        ...state,
        workers: {
          ...state.workers,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_EDIT_METADATA:
      return {
        ...state,
        editMetadata: {
          ...state.editMetadata,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_EDIT_METADATA_SUCCESS:
      return {
        ...state,
        editMetadata: {
          ...action.payload.data,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_EDIT_METADATA_ERROR:
      return {
        ...state,
        editMetadata: {
          ...state.editMetadata,
          loading: false,
        },
      };
    case WorkflowActionTypes.LIST:
      return {
        ...state,
        workflows: {
          // ...state.workflows,
          loading: true,
        },
      };
    case WorkflowActionTypes.LIST_SUCCESS:
      return {
        ...state,
        workflows: {
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.LIST_ERROR:
      return {
        ...state,
        workflows: {
          loading: false
        },
      };
    case WorkflowActionTypes.GET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_OPTIONS_SUCCESS:

      if (action.meta?.part === 'events') {
        return {
          ...state,
          options: {
            ...state.options,
            events: action.payload.data,
            loading: true,
          },
        };
      }

      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_OPTIONS_ERROR:
      return {
        ...state,
        options: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_NODE_ANALYTICS:
      return {
        ...state,
        nodeAnalytics: {
          ...state.nodeAnalytics,
          loading: action.meta.withLoader,
        },
      };
    case WorkflowActionTypes.GET_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        nodeAnalytics: {
          ...state.nodeAnalytics,
          [action.meta.node_id]: {
            ...(state.nodeAnalytics[action.meta.node_id] || {}),
            ...action.payload,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        nodeAnalytics: {
          ...state.nodeAnalytics,
          loading: false
        },
      };
    case WorkflowActionTypes.CLEAR_ANALYTICS:
      return {
        ...state,
        analytics: {
          loading: false,
        },
        nodeAnalytics: {
          loading: false,
        },
      }
    case WorkflowActionTypes.GET_ANALYTICS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: action.meta.withLoader,
        },
      };
    case WorkflowActionTypes.GET_ANALYTICS_SUCCESS:
      const analyticsGroupById = action.payload.nodes.reduce((acc, nodeInstance) => {
        return {
          ...acc,
          [nodeInstance.node]: nodeInstance
        }
      }, {});

      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...action.payload,
          nodes: analyticsGroupById,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_ANALYTICS_ERROR:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loading: false
        },
      };
    case WorkflowActionTypes.SET_SINGLE_ANALYTICS:

      return {
        ...state,
        analytics: {
          ...state.analytics,
          nodes: {
            ...state.analytics.nodes,
            [action.meta.id]: {
              ...action.payload,
            },
          }
        }
      }
    case WorkflowActionTypes.GET_PROGRAM_ANALYTICS:
      return {
        ...state,
        programAnalytics: {
          ...state.programAnalytics,
          loading: action.meta.withLoader,
        },
      };
    case WorkflowActionTypes.GET_PROGRAM_ANALYTICS_SUCCESS:
      return {
        ...state,
        programAnalytics: {
          ...state.programAnalytics,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_PROGRAM_ANALYTICS_ERROR:
      return {
        ...state,
        programAnalytics: {
          ...state.programAnalytics,
          loading: false
        },
      };
    case WorkflowActionTypes.GET_PUBLISH_HISTORY:
      return {
        ...state,
        publishHistory: {
          ...state.publishHistory,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_PUBLISH_HISTORY_SUCCESS:
      return {
        ...state,
        publishHistory: {
          ...state.publishHistory,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_PUBLISH_HISTORY_ERROR:
      return {
        ...state,
        publishHistory: {
          ...state.publishHistory,
          loading: false,
        },
      };
    case WorkflowActionTypes.SET_DEFAULT_ENTRY_POINT:
      return {
        ...state,
        defaultNodes: [action.payload.node],
      };
    case WorkflowActionTypes.SET_DEFAULT_NODES:
      return {
        ...state,
        defaultNodes: action.payload.nodes,
      };
    case WorkflowActionTypes.CLEAR_DEFAULT_ENTRY_POINT:
      return {
        ...state,
        defaultNodes: [],
      };
    case WorkflowActionTypes.GET_LOGS:
      return {
        ...state,
        workflowLogs: {
          ...state.workflowLogs,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_LOGS_SUCCESS:
      return {
        ...state,
        workflowLogs: {
          ...state.workflowLogs,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_LOGS_ERROR:
      return {
        ...state,
        workflowLogs: {
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_SENDING_NODE_ANALYTICS:
      return {
        ...state,
        sendingNodeAnalytics: {
          ...(state.sendingNodeAnalytics || {}),
          [action.meta.node_id]: {
            ...(state.sendingNodeAnalytics[action.payload.node_id] || {}),
            loading: true,
          },
        },
      }
    case WorkflowActionTypes.GET_SENDING_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        sendingNodeAnalytics: {
          ...(state.sendingNodeAnalytics || {}),
          [action.meta.node_id]: {
            ...(state.sendingNodeAnalytics[action.payload.node_id] || {}),
            statuses: action.payload,
            loading: false,
          },
        },
      }
    case WorkflowActionTypes.GET_SENDING_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        sendingNodeAnalytics: {
          ...(state.sendingNodeAnalytics || {}),
          [action.meta.node_id]: {
            ...(state.sendingNodeAnalytics[action.payload.node_id] || {}),
            loading: false,
          },
        },
      }
    case WorkflowActionTypes.GET_SMS_NODE_ANALYTICS:
      return {
        ...state,
        smsNodeAnalytics: {
          ...state.smsNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_SMS_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        smsNodeAnalytics: {
          ...state.smsNodeAnalytics,
          data: {
            ...state.smsNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_SMS_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        smsNodeAnalytics: {
          ...state.smsNodeAnalytics,
          data: {
            ...state.smsNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_EMAIL_NODE_ANALYTICS:
      return {
        ...state,
        emailNodeAnalytics: {
          ...state.emailNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_EMAIL_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        emailNodeAnalytics: {
          ...state.emailNodeAnalytics,
          loading: false,
          data: {
            ...state.emailNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_EMAIL_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        emailNodeAnalytics: {
          ...state.emailNodeAnalytics,
          data: {
            ...state.emailNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WEBPUSH_NODE_ANALYTICS:
      return {
        ...state,
        webpushNodeAnalytics: {
          ...state.webpushNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_WEBPUSH_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        webpushNodeAnalytics: {
          ...state.webpushNodeAnalytics,
          data: {
            ...state.webpushNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WEBPUSH_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        webpushNodeAnalytics: {
          ...state.webpushNodeAnalytics,
          data: {
            ...state.webpushNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WEBHOOK_NODE_ANALYTICS:
      return {
        ...state,
        webhookNodeAnalytics: {
          ...state.webhookNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_WEBHOOK_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        webhookNodeAnalytics: {
          ...state.webhookNodeAnalytics,
          loading: false,
          data: {
            ...state.webhookNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_WEBHOOK_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        webhookNodeAnalytics: {
          ...state.webhookNodeAnalytics,
          data: {
            ...state.webhookNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_MOBILE_PUSH_NODE_ANALYTICS:
      return {
        ...state,
        mobilePushAnalytics: {
          ...state.mobilePushAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_MOBILE_PUSH_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        mobilePushAnalytics: {
          ...state.mobilePushAnalytics,
          loading: false,
          data: {
            ...state.mobilePushAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_MOBILE_PUSH_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        mobilePushAnalytics: {
          ...state.mobilePushAnalytics,
          data: {
            ...state.mobilePushAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_VIBER_NODE_ANALYTICS:
      return {
        ...state,
        viberNodeAnalytics: {
          ...state.viberNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_VIBER_NODE_ANALYTICS_SUCCESS:
      return {
        ...state,
        viberNodeAnalytics: {
          ...state.viberNodeAnalytics,
          loading: false,
          data: {
            ...state.viberNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_VIBER_NODE_ANALYTICS_ERROR:
      return {
        ...state,
        viberNodeAnalytics: {
          ...state.viberNodeAnalytics,
          data: {
            ...state.viberNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_CONTENT_CARD_NODE_ANALYTICS:
      return {
        ...state,
        contentCardNodeAnalytics: {
          ...state.contentCardNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_CONTENT_CARD_ANALYTICS_SUCCESS:
      return {
        ...state,
        contentCardNodeAnalytics: {
          ...state.contentCardNodeAnalytics,
          loading: false,
          data: {
            ...state.contentCardNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_CONTENT_CARD_ANALYTICS_ERROR:
      return {
        ...state,
        contentCardNodeAnalytics: {
          ...state.contentCardNodeAnalytics,
          data: {
            ...state.contentCardNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WEB_POPUP_NODE_ANALYTICS:
      return {
        ...state,
        webpopupNodeAnalytics: {
          ...state.webpopupNodeAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_WEB_POPUP_ANALYTICS_SUCCESS:
      return {
        ...state,
        webpopupNodeAnalytics: {
          ...state.webpopupNodeAnalytics,
          loading: false,
          data: {
            ...state.webpopupNodeAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_WEB_POPUP_ANALYTICS_ERROR:
      return {
        ...state,
        webpopupNodeAnalytics: {
          ...state.webpopupNodeAnalytics,
          data: {
            ...state.webpopupNodeAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_WEB_POPUP_ELEMENT_ANALYTICS:
      return {
        ...state,
        webpopupElementAnalytics: {
          ...state.webpopupElementAnalytics,
          loading: action.meta.withLoader,
          node_id: action.payload.node_id,
        },
      };
    case WorkflowActionTypes.GET_WEB_POPUP_ELEMENT_ANALYTICS_SUCCESS:
      return {
        ...state,
        webpopupElementAnalytics: {
          ...state.webpopupElementAnalytics,
          loading: false,
          data: {
            ...state.webpopupElementAnalytics.data,
            [action.meta.node_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_WEB_POPUP_ELEMENT_ANALYTICS_ERROR:
      return {
        ...state,
        webpopupElementAnalytics: {
          ...state.webpopupElementAnalytics,
          data: {
            ...state.webpopupElementAnalytics.data,
            [action.meta.node_id]: undefined,
          },
          loading: false,
        },
      };

    case WorkflowActionTypes.GET_NODE_TREND_ANALYTICS:
      return {
        ...state,
        nodeTrendAnalytics: {
          ...state.nodeTrendAnalytics,
          loading: action.meta.withLoader,
        },
      };
    case WorkflowActionTypes.GET_NODE_TREND_ANALYTICS_SUCCESS:
      return {
        ...state,
        nodeTrendAnalytics: {
          ...state.nodeTrendAnalytics,
          loading: false,
          data: {
            ...state.nodeTrendAnalytics.data,
            [action.meta.sub_entity_id]: action.payload,
          },
        },
      };
    case WorkflowActionTypes.GET_NODE_TREND_ANALYTICS_ERROR:
      return {
        ...state,
        nodeTrendAnalytics: {
          ...state.nodeTrendAnalytics,
          data: {
            ...state.nodeTrendAnalytics.data,
            [action.meta.id]: action.payload,
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.CLEAR_WORKFLOW_LOGS:
      return {
        ...state,
        workflowLogs: {},
      };
    case WorkflowActionTypes.GET_HORIZON_CAMPAIGNS:
      return {
        ...state,
        horizonCampaigns: {
          ...state.horizonCampaigns,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_CAMPAIGNS_SUCCESS:
      if (action.meta.page === state.horizonCampaigns?.[action.meta.search || WITHOUT_SEARCH]?.page) {
        return state;
      }

      return {
        ...state,
        horizonCampaigns: {
          ...state.horizonCampaigns,
          [action.meta.search || WITHOUT_SEARCH]: {
            ...action.meta,
            data: uniqBy((state.horizonCampaigns[action.meta.search || WITHOUT_SEARCH]?.data || []).concat(action.payload.data), extract('id')),
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_CAMPAIGNS_ERROR:
      return {
        ...state,
        horizonCampaigns: {
          ...state.horizonCampaigns,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_BONUSES:
      return {
        ...state,
        horizonBonuses: {
          ...state.horizonBonuses,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_BONUSES_SUCCESS:
      return {
        ...state,
        horizonBonuses: {
          ...state.horizonBonuses,
          [action.meta.search || WITHOUT_SEARCH]: {
            ...action.meta,
            data: uniqBy((state.horizonBonuses[action.meta.search || WITHOUT_SEARCH]?.data || []).concat(action.payload.data), extract('id')),
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_BONUSES_ERROR:
      return {
        ...state,
        horizonBonuses: {
          ...state.horizonBonuses,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_TAGS:
      return {
        ...state,
        horizonTags: {
          ...state.horizonTags,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_TAGS_SUCCESS:
      return {
        ...state,
        horizonTags: {
          ...state.horizonTags,
          [action.meta.search || WITHOUT_SEARCH]: {
            ...action.meta,
            data: uniqBy((state.horizonTags[action.meta.search || WITHOUT_SEARCH]?.data || []).concat(action.payload.data), extract('id')),
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_TAGS_ERROR:
      return {
        ...state,
        horizonTags: {
          ...state.horizonTags,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_FREE_SPINS:
      return {
        ...state,
        horizonFreeSpins: {
          ...state.horizonFreeSpins,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_FREE_SPINS_SUCCESS:
      return {
        ...state,
        horizonFreeSpins: {
          ...state.horizonFreeSpins,
          [action.meta.search || WITHOUT_SEARCH]: {
            ...action.meta,
            data: uniqBy((state.horizonFreeSpins[action.meta.search || WITHOUT_SEARCH]?.data || []).concat(action.payload.data), extract('id')),
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_FREE_SPINS_ERROR:
      return {
        ...state,
        horizonFreeSpins: {
          ...state.horizonFreeSpins,
          loading: false,
        },
      };

    case WorkflowActionTypes.GET_HORIZON_FREE_BETS:
      return {
        ...state,
        horizonFreeBets: {
          ...state.horizonFreeBets,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_FREE_BETS_SUCCESS:
      return {
        ...state,
        horizonFreeBets: {
          ...state.horizonFreeBets,
          [action.meta.search || WITHOUT_SEARCH]: {
            ...action.meta,
            data: uniqBy((state.horizonFreeBets[action.meta.search || WITHOUT_SEARCH]?.data || []).concat(action.payload.data), extract('id')),
          },
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_FREE_BETS_ERROR:
      return {
        ...state,
        horizonFreeBets: {
          ...state.horizonFreeBets,
          loading: false,
        },
      };
    case WorkflowActionTypes.SET_INITIAL_WORKER:
      return {
        ...state,
        initialWorker: {
          ...action.payload
        }
      };

    case WorkflowActionTypes.GET_GOALS_ANALYTICS:
      return {
        ...state,
        goalsAnalytics: {
          ...state.goalsAnalytics,
          [action.meta.id]: {
            ...(state.goalsAnalytics[action.meta.id] || {}),
            loading: true,
          },
        },
      };
    case WorkflowActionTypes.GET_GOALS_ANALYTICS_SUCCESS:
      return {
        ...state,
        goalsAnalytics: {
          ...state.goalsAnalytics,
          [action.meta.id]: {
            ...(state.goalsAnalytics[action.meta.id] || {}),
            ...action.payload,
            loading: false,
          },
        },
      };
    case WorkflowActionTypes.GET_GOALS_ANALYTICS_ERROR:
      return {
        ...state,
        goalsAnalytics: {
          ...state.goalsAnalytics,
          [action.meta.id]: {
            ...(state.goalsAnalytics[action.meta.id] || {}),
            loading: false,
          },
        },
      };
    case WorkflowActionTypes.GET_HORIZON_CURRENCIES:
      return {
        ...state,
        horizonCurrencies: {
          ...state.horizonCurrencies,
          loading: true,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_CURRENCIES_SUCCESS:
      return {
        ...state,
        horizonCurrencies: {
          ...state.horizonCurrencies,
          ...action.payload,
          loading: false,
        },
      };
    case WorkflowActionTypes.GET_HORIZON_CURRENCIES_ERROR:
      return {
        ...state,
        horizonCurrencies: {
          ...state.horizonCurrencies,
          loading: false,
        },
      };
    case WorkflowActionTypes.TOGGLE_AUTOSAVE:
      return {
        ...state,
        autoSave: {
          enabled: action.payload.enabled,
        },
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default workflowReducer;
