import styled from 'styled-components';

export const Container = styled.div``;

export const Sending = styled.div`
  margin-top: 16px;
`;
export const AiSection = styled.div`
  border: 1px solid #ECF0F6;
  background: #F8FAFE;
  padding: 10px 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AiTitle = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 14px;
`;

export const AiSubtitle = styled.div`
  font-family: Manrope-Medium;
  font-size: 12px;
  color: #12B76A;
`;

export const SubText = styled.div`
  font-family: Manrope-Medium;
  font-size: 12px;
`;

