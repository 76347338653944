import { Dropdown } from 'antd';

import { useTranslation } from '@hooks';

import { moment, tryParse } from '@utils';

import { Container, Overlay } from './styled.js';

const UTCToLocalMoment = (time) => {
  if (!time) {
    return null;
  }

  const [h, m] = time.split(':');
  return moment.utc().set('hours', h).set('minutes', m).add(moment().utcOffset(), 'minutes');
};

const resolveStatus = (status) => {
  switch (status) {
    case 'Design':
      return 'statuses.design';
    case 'Active':
      return 'statuses.active';
    case 'Stop':
      return 'statuses.stopped';
    case 'Done':
      return 'statuses.finished';
    case 'Archive':
      return 'statuses.archived';
    case 'On pause':
      return 'statuses.on_pause';
    default:
      return 'Unknown';
  }
};

const StatusCell = ({ status, worker }) => {
  const { t, p } = useTranslation('workflow_page');

  if (status === 'On pause' && worker) {
    const qh = tryParse(worker.quite_hours);

    return (
      <Dropdown
        trigger={['hover']}
        overlay={(
          <Overlay>
            {p('quite_hours')}
            <br />
            {[qh?.start, qh?.end].map(t => UTCToLocalMoment(t)?.format?.('HH:mm')).join(' - ')}
          </Overlay>
        )}
      >
        <Container status={status} $u>
          {t(resolveStatus(status))}
        </Container>
      </Dropdown>
    );
  }

  return (
    <Container status={status}>
      {t(resolveStatus(status, worker))}
    </Container>
  );
}

export default StatusCell;

