import styled from 'styled-components';

import { extract } from '@utils';

export const Container = styled.div`
  padding: 5px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #303133;
  margin-bottom: 30px;
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  //color: #909399;
  //margin-bottom: 50px;
`;

export const ImageContainer = styled.div`
  border-radius: 6px;
  margin-bottom: 30px;
`;

export const Image = styled.div`
  width: 320px;
  height: 160px;
  background: url(${extract('image')}) center / cover no-repeat;
`;

export const StepsText = styled.div`
  text-align: center;
  margin-top: 30px;
  color: #24993E;
  font-size: 13px;
  line-height: 120%;
`;
