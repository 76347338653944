import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const Container = styled(animated.div)`
  position: fixed;
  top: 50px;
  height: calc(100vh - 50px);
  margin-bottom: 2vh;
  right: 0;
  z-index: 10001;
  width: 410px;
  overflow: hidden;
  border: 1px solid #D3DBE7;
  background: #F8FAFE;
  transition: left 300ms ease;
  // ${({ $opened }) => $opened ? '' : 'display: none;'}
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 10000;
`;

export const OldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 21px;
`;

export const CollapseButton = styled.div`
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-color: #F0F2F6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
`;

export const Badge = styled.div`
  background: #E5E8ED;
  border-radius: 10px;
  padding: 4px 8px;
  font-size: 10px;
  color: #303133;
  font-family: Manrope-Medium;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  align-items: center;
  padding: 20px 10px 10px 10px;
  background: #F9FBFF;
  z-index: 10;
  color: #7C94B1;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  //border-bottom: 1px solid #DCDFE6;;
`;

export const Period = styled.div`
`;

export const ReadAll = styled.div`
  color: #31C447;
  cursor: pointer;
  user-select: none;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
`;

export const Divider = styled.div`
  width: 370px;
  height: 1px;
  background-color: rgba(220, 223, 230, 1);
`;

export const Items = styled.div`
  width: 370px;
`;

export const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 180px);
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: 100vh;
`;

export const ScrollContainer = styled.div`
  //overflow-y: scroll;
  //height: 570px;
`;

export const Hint = styled.div`
  color: #909399;
  cursor: pointer;
  user-select: none;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  padding: 10px 0 14px 0;
  width: 100%;
  text-align: center;
`;

export const OnlyShowUnread = styled.div`
  
`;
