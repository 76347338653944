import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled(motion.div).attrs({
  initial: { y: '-100%' },
  animate: { y: '0%' },
  transition: { duration: 0.33, easing: 'ease', delay: 0.27 }
})`
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 72px;
  min-height: 72px;
  width: 100%;
  flex: 1;
  padding: 10px 30px 11px;
  border-bottom: 1px solid var(--Stroke, #DCDFE6);
  background: #F9FAFC;
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 21px;
  font-style: normal;
  line-height: 26px;
`;

export const StepsContainer = styled.div`
  padding-bottom: 16px;
`;

export const StepsIndicator = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
  grid-auto-rows: minmax(6px, auto);
  gap: 0 102px;
  align-items: center;
  height: 6px;
  min-height: 6px;
  overflow: visible;
  background: #D8E2F9;
`;

export const StepContainer = styled.div`
  height: 30px;
  width: 30px;
  border: 4px solid #D8E2F9;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Rubik-Regular;
  font-size: 14px;
  font-style: normal;
  line-height: 120%;
  position: relative;
  
  background: #FFFFFF;
  color: #7C94B1;
  box-shadow: 0px 1px 2px #B5C6EF;
  cursor: pointer;

  ${styledProp('$disabled', css`
    cursor: default;
  `)}
  
  ${styledProp('$completed', css`
    background: #22BF7E;
    box-shadow: none;
    color: #fff;
  `)}

  ${styledProp('$current', css`
    background: #4986FD;
    box-shadow: none;
    color: #fff;
  `)}

  transition: background-color 330ms ease;
`;

export const StepTitle = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 11px;
  line-height: 120%;
  position: absolute;
  bottom: -20px;
  left: -40px;
  right: -40px;
  text-align: center;
  
  ${styledProp('$darker', css`
    color: #1A232E;
  `, css`
    color: #7C94B1;
  `)}
`;
