const WebPopupStatuses = {
  SENT: 'sent',
  OPENED: 'open',
  REDIRECT: 'redirect',
  OPT_IN: 'opt-in',
  DISMISS: 'dismiss',
};

const WebPopupStatusLabels = {
  [WebPopupStatuses.SENT]: 'statuses.sent',
  [WebPopupStatuses.OPENED]: 'statuses.opened',
  [WebPopupStatuses.REDIRECT]: 'statuses.redirect',
  [WebPopupStatuses.OPT_IN]: 'statuses.opt_in',
  [WebPopupStatuses.DISMISS]: 'statuses.dismiss',
};

const WebPopupStatusOptions = [
  ...Object.entries(WebPopupStatusLabels).map(([value, label]) => ({ value, label }))
];

export { WebPopupStatuses, WebPopupStatusOptions, WebPopupStatusLabels };
