import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeLatest, put } from 'redux-saga/effects';

import { Endpoints } from '@constants';

import { NotificationsActionTypes } from '../../actions/types';

function* getNotificationsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATIONS,
  }));
}

function* markAsReadSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.NOTIFICATIONS_MARK_AS_READ,
    body: {
      ids: action.payload.ids,
    }
  }));
}

function* markAsImportantSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.NOTIFICATIONS_MARK_AS_IMPORTANT,
    body: {
      ids: action.payload.ids,
    }
  }));
}

function* markNotImportantSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.NOTIFICATIONS_MARK_NOT_IMPORTANT,
    body: {
      ids: action.payload.ids,
    }
  }));
}

function* getImportantSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.NOTIFICATIONS,
  }));
}

export default function* contactsRootSaga() {
  yield all([
    takeLatest(NotificationsActionTypes.GET_NOTIFICATIONS, getNotificationsSaga),
    takeLatest(NotificationsActionTypes.MARK_AS_READ, markAsReadSaga),
    takeLatest(NotificationsActionTypes.MARK_AS_IMPORTANT, markAsImportantSaga),
    takeLatest(NotificationsActionTypes.MARK_NOT_IMPORTANT, markNotImportantSaga),
    takeLatest(NotificationsActionTypes.GET_IMPORTANT, getImportantSaga),
  ]);
}
