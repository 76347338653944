import { CustomCode } from './components/CustomCode';
import { FullScreen } from './components/FullScreen/FullScreen';
import { FullScreenLaptop } from './components/FullScreen/FullScreenLaptop';
import { FullScreenMobile } from './components/FullScreen/FullScreenMobile';
import { Modal } from './components/Modal/Modal';
import { ModalLaptop } from './components/Modal/ModalLaptop';
import { ModalPhone } from './components/Modal/ModalPhone';

import { WEB_POPUPS_TYPES } from '../../../pages/HomeScene/pages/TemplatesScene/pages/CreateWebPopupTemplatePage/constants';

export const PreviewTemplate = ({
  testId,
  viewMode,
  device,
  type,
  format,
  shouldShowTextContent,
  template,
  imageUrl
}) => {
  const { title, message, layout } = template;
    
  const previewConditionConfig = {
    code: type === WEB_POPUPS_TYPES.CODE,
    modal_laptop: type === WEB_POPUPS_TYPES.MODAL && viewMode === 'desktop' && device,
    modal_mobile: type === WEB_POPUPS_TYPES.MODAL && viewMode === 'mobile' && device,
    modal_default: type === WEB_POPUPS_TYPES.MODAL && !device,
    full_screen_laptop: type === WEB_POPUPS_TYPES.FULL_SCREEN && viewMode === 'desktop' && device,
    full_screen_mobile: type === WEB_POPUPS_TYPES.FULL_SCREEN && viewMode === 'mobile' && device,
    full_screen: type === WEB_POPUPS_TYPES.FULL_SCREEN && !device,
  };

  const templateConfig = {
    code: CustomCode,
    modal_laptop: ModalLaptop,
    modal_mobile: ModalPhone,
    modal_default: Modal,
    full_screen_laptop: FullScreenLaptop,
    full_screen_mobile: FullScreenMobile,
    full_screen: FullScreen,
  };

  const configPreview = Object.entries(previewConditionConfig).find(
    (configItem) => {
      // eslint-disable-next-line no-unused-vars
      const [_notificationType, conditionToDisplay] = configItem;
      return conditionToDisplay;
    }
  );

  if(!configPreview) return <></>

  const ComponentToShow = templateConfig[configPreview[0]];
  const hasContent = title?.length > 0 || message?.length > 0;

  return (
    <div data-testid={testId}>
      <ComponentToShow  
        viewMode={viewMode}
        shouldShowTextContent={shouldShowTextContent}
        title={title} 
        message={message} 
        layout={layout}
        buttons={template.buttons}
        format={format} 
        imageOnly={layout === 'image' || !hasContent }
        imageUrl={imageUrl}
        htmlCode={template.code}
      />
    </div>
  );
};
