import { Button } from '@velitech/ui';

import { Modal } from "@components";

const ConfirmationModal = ({
  appearance,
  wrapClassName = '',
  onCancel,
  onClose,
  title,
  opened,
  zIndex = undefined,
  testId = '',
  content = null,
  onSubmitClick,
  containerProps = {},
  buttonsParams = {},
  getContainer = (c) => c,
  submitLoading,
  text,
}) => {
  return (
    <Modal
      onCancel={onCancel}
      containerProps={containerProps}
      opened={opened}
      onClose={onClose}
      zIndex={zIndex}
      getContainer={getContainer}
      wrapClassName={wrapClassName}
      title={title}
      appearance={appearance}
      actionsStyle={{ display: 'flex', alignItems: 'center', gap: 14, padding: '14px 33px' }}
      actions={(
        <>
          <Button
            data-testid={buttonsParams.cancelTestId || ''}
            onClick={onCancel}
            variant={buttonsParams.cancelAppearance}
            size="lg"
            style={{ width: 'calc(50% - 7px)' }}
          >
            {buttonsParams.cancelLabel}
          </Button>
          <Button
            data-testid={buttonsParams.submitTestId || ''}
            variant={buttonsParams.submitAppearance}
            onClick={onSubmitClick}
            loading={submitLoading}
            outline
            style={{ width: 'calc(50% - 7px)' }}
            size="lg"
          >
            {buttonsParams.submitLabel}
          </Button>
        </>
      )}
    >
      <div data-testid={testId}>
        <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
          {content || text}
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal;
