import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled(motion.div).attrs({
  initial: { opacity: 0, y: 60, scale: 0.98 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: 60, scale: 0.98 },
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;

  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px;
  
  margin-top: 119px;
  margin-bottom: 50px;
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  justify-content: center;
  margin-top: 54px;
`;

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 530px;
  width: 100%;
  margin: 0 auto;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ${styledProp('$empty', css`
    flex: 1;
  `)}
`;
