import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 16px;
  padding-top: 40px;
`;

export const Label = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: normal;
`;
