import { useEffect } from 'react';
import { createRoutesFromElements, RouterProvider } from 'react-router';
// import 'zone.js';
// import 'zone.js/plugins/zone-patch-message-port';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  createBrowserRouter, Route } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import '@velitech/ui/base.css';
import '@velitech/ui/common.css';
import '@velitech/ui/edge.css';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.min.js';
import './index.css';

if (process.env.NODE_ENV === 'production') {
  if (window.location.host.indexOf('dev') !== -1) {
    window.document.title = 'veliedge.app-dev';
  } else if (window.location.host.indexOf('stage') !== -1) {
    window.document.title = 'veliedge.app-stage';
  } else {
    window.document.title = 'veliedge.app';
  }

  Sentry.init({
    dsn: "https://01c5cab9b94099c8ee4c96602154ab53@sentry.velitech.com/14",
    release: process.env.PUBLIC_RELEASE_BRANCH,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    environment: !!~window.location.host.indexOf('stage') ? 'stage' : 'production',
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (process.env.NODE_ENV === 'development') {
  window.document.title = 'veliedge.app-local';
}

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'production') {
  window.document.title = 'veliedge.app';
}

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/*" element={<App />} errorElement={null} />
));

createRoot(document.getElementById('root')).render((
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
