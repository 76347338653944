import { useState, useEffect } from 'react';

const useBreakpoint = (breakpoint = 1024) => {
  const [is1024, setIs1024] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < breakpoint) {
        setIs1024(true);
      } else if (window.innerWidth >= breakpoint) {
        setIs1024(false);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return is1024;
};

export default useBreakpoint;
