import { UserActionTypes, WorkflowOptionsActionTypes, WorkspacesActionTypes } from '@store/actions/types';

import uniqBy from 'lodash.uniqby';

import { extract } from '@/utils';

const initialState = {
  options: {},
  preview: {},
};

export const WORKFLOW_OPTIONS_WITHOUT_SEARCH = 'workflow-options-no-search';

const workflowOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case WorkflowOptionsActionTypes.GET: {
      const { entity, search: s } = action.meta;
      const search = s || WORKFLOW_OPTIONS_WITHOUT_SEARCH;

      return {
        ...state,
        options: {
          ...state.options,
          [entity]: {
            ...state.options[entity],
            loading: {
              ...(state.options[entity]?.loading || {}),
              [search]: true,
            },
          },
        },
      };
    }

    case WorkflowOptionsActionTypes.GET_SUCCESS: {
      const { entity, search: s, page } = action.meta;
      const search = s || WORKFLOW_OPTIONS_WITHOUT_SEARCH;

      const prevEntityState = state.options[entity] || {};

      const prevSearches = prevEntityState.searches || {};
      const prevSearchState = prevSearches[search] || { data: [] };

      const newData =
        page === 1
          ? action.payload.data
          : uniqBy(
            [...prevSearchState.data, ...action.payload.data],
            extract('id')
          );

      return {
        ...state,
        options: {
          ...state.options,
          [entity]: {
            ...prevEntityState,
            loading: {
              ...(prevEntityState.loading || {}),
              [search]: false,
            },
            searches: {
              ...prevSearches,
              [search]: {
                data: newData,
                meta: { ...action.meta },
              },
            },
          },
        },
      };
    }

    case WorkflowOptionsActionTypes.GET_ERROR: {
      const { entity, search: s } = action.meta;
      const search = s || WORKFLOW_OPTIONS_WITHOUT_SEARCH;

      return {
        ...state,
        options: {
          ...state.options,
          [entity]: {
            ...state.options[entity],
            loading: {
              ...(state.options[entity]?.loading || {}),
              [search]: false,
            },
          },
        },
      };
    }
    case WorkflowOptionsActionTypes.PREVIEW:
      return {
        ...state,
        preview: {
          ...state.preview,
          [action.meta.entity]: {
            ...(state.preview[action.meta.entity] || {}),
            loading: true,
          },
        },
      };
    case WorkflowOptionsActionTypes.PREVIEW_SUCCESS:
      return {
        ...state,
        preview: {
          ...state.preview,
          [action.meta.entity]: {
            ...(state.preview[action.meta.entity] || {}),
            data: uniqBy((state.preview[action.meta.entity]?.data || []).concat(action.payload.data), extract('id')),
            loading: false,
          },
        }
      };
    case WorkflowOptionsActionTypes.PREVIEW_ERROR:
      return {
        ...state,
        preview: {
          ...state.preview,
          [action.meta.entity]: {
            ...(state.options[action.meta.entity] || {}),
            loading: false,
          },
        }
      }
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default workflowOptionsReducer;
