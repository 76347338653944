import { Position } from "@xyflow/react";

import { createNodeData } from "@utils/flow/nodeDataFabric";

import {
  EdgeTypes,
  GenericFlowNode, NodeActionType,
  NodeName,
  NodeType,
  NodeTypeDataMap
} from "@/constants/flow";
import { generateShortId } from '@/utils/flow/common';

export const createNode = <T extends NodeType>(
  { type, data, id }: { type: T; data: NodeTypeDataMap[T]; id?: string; }
): GenericFlowNode<T, NodeName, NodeTypeDataMap[T], NodeActionType> => {
  return {
    type,
    position: { x: 0, y: 0 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    id: id || generateShortId(),
    data: createNodeData(data?.name, data),
  };
};

export const createEdge = ({ type = EdgeTypes.DEFAULT, target, source }: { type?: EdgeTypes, target: string, source: string }) => {
  return {
    target,
    source,
    type,
    sourceHandle: "b",
    data: {},
    targetHandle: "a",
    id: generateShortId(),
  };
};
