import { useSelector } from 'react-redux';

import {
  optionsSelector,
} from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTransition } from '@react-spring/web';
import { Tooltip } from 'antd';

import { useModalState, useTranslation } from '@hooks';

import {
  resolveAggregateType
} from '@constants';

import { by , decomposeFilters } from '@utils';
import { clickhouseFields2Customer, clickhouseFields2Events } from '@utils/fields';

import { Input, OperatorSelect, SearchSelect , ActionButton } from '@components';
import { InfoIconContainer } from '@components/inputs/Input/styled';
import { AggregateSettings } from "@components/ui/AggregateSettings";
import { DefaultAggregateSettings } from '@components/ui/DefaultAggregateSettings';
import { AggregateInfoMap } from '@components/ui/DefaultAggregateSettings/DefaultAggregateSettings';

import {
  Container,
  Row,
  Title,
  Divider,
  FooterOption,
  FooterOptionLabel,
  DefaultContainer,
  DefaultTitle, DefaultHeader, InfoIcon, ToggleButton, DefaultSettingsContainer, DefaultAnimatedContainer
} from './styled';

import { Icon } from '../../../../../../ui';

const AggregateSelect = ({ query, onChange, aggregates, aggregateCreationDisabled = false, addFilter, aggregateSaved, onDeleteFilter, onAggregateChange, showErrors, appearance }) => {
  const { p, t } = useTranslation('segments_page');
  const options = useSelector(optionsSelector);
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data)
  const events = clickhouseFields2Events(clickhouseFields.data);
  const loadedAggregatesOptions = options?.aggregates?.map?.(({ label, name }) => ({ value: name, label }));
  const created = aggregates.find(by('name', query.field));
  const loaded = options?.aggregates?.find?.(by('name', query.field));
  const isLoaded = !!loaded;
  const aggregate = created ? { ...created, filters: decomposeFilters(created.filters) } : (loaded ? { ...loaded, filters: decomposeFilters(loaded.filters) } : {});

  const handleFieldChange = (field) => {
    const aggregate = options?.aggregates?.find(by('name', field));
    onAggregateChange(query.field, aggregate);
    onChange(q => ({ ...q, query: { ...q.query, field, operator: '', value: { value: '', type: 'scalar' }, aggregateId: aggregate.id, errors: { ...(q.query.errors || {}), label: undefined } } }));
  };

  const handleCreateFieldChange = ({ target: { value } }) => {
    onChange(q => ({ ...q, query: { ...q.query, errors: { ...(q.query.errors || {}), label: undefined } } }));
    onAggregateChange(query.field, ({ ...aggregate, label: value }));
  };

  const handleTypeChange = (type) => {
    onChange(q => ({ ...q, query: { ...q.query, type: type === 'common' ? null : type } }));
  };

  const handleValueChange = (value) => {
    onChange(q => ({ ...q, query: { ...q.query, value: { ...q.query.value, value } } }));
  };

  const handleOperatorChange = (operator, type) => {
    onChange(q => ({ ...q, query: { ...q.query, operator, value: { ...q.query.value, value: '' }, type: type === 'common' ? null : type } }));
  };

  const handleCreateNew = () => {
    addFilter(null, 'aggregate');
    onDeleteFilter();
  }

  const handleClearAggregateConditions = () => {
    onChange(q => ({ ...q, query: { ...q.query, value: {}, operator: "" } }));
  }

  const aggregateInfo = AggregateInfoMap[aggregate.aggregate];

  const aggregateAccordion = useModalState(false);

  const defaultSettingsTransition = useTransition(aggregateAccordion.opened, {
    enter: { height: 177 },
    from: { height: 0 },
    leave: { height: 0 },
  })

  return (
    <Container $appearance={appearance}>
      {appearance !== 'column' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title disabled={isLoaded || aggregateSaved}>
            {p('aggregate_condition')}:
          </Title>
          <Tooltip title={p('segments_filter_description')}>
            <InfoIconContainer style={{ marginBottom: 14, marginLeft: 5, backgroundColor: '#FFFFFF' }}>
              <Icon color="#31C447" name="Info-icon" size={14}/>
            </InfoIconContainer>
          </Tooltip>
        </div>
      )}
      <Row $appearance={appearance}>
        {isLoaded ? (
          <SearchSelect
            label={t('labels.aggregate_name')}
            error={query.errors?.label}
            style={{ width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
            title={t('labels.aggregate_name')}
            options={loadedAggregatesOptions}
            value={query.field}
            onChange={handleFieldChange}
            footerOption={aggregateCreationDisabled ? null : (
              <FooterOption onClick={handleCreateNew}>
                <ActionButton size={22} icon="Plus-icon"/>
                <FooterOptionLabel>
                  {t('actions.create')}
                </FooterOptionLabel>
              </FooterOption>
            )}
          />
        ) : (
          <Input
            title={t('labels.aggregate_name')}
            wrapperStyles={{ width: 186, maxWidth: 186 }}
            error={query.errors?.label}
            value={aggregate.label}
            maxLength={25}
            onChange={handleCreateFieldChange}
          />
        )}
        <OperatorSelect
          wrapperStyle={{ marginLeft: appearance !== 'column' ? 6 : 0 }}
          style={{ width: appearance !== 'column' ? 186 : 'auto', background: '#F9FBFF' }}
          value={{ ...query, value: query?.value?.value, errors: showErrors && query.errors }}
          type={query.type || resolveAggregateType(aggregate, customerFields, events.data)}
          initialType={query.type || resolveAggregateType(aggregate, customerFields, events.data)}
          labeled
          isAggregate
          appearance={appearance}
          fullWidth={appearance === 'column'}
          onTypeChange={handleTypeChange}
          onValueChange={handleValueChange}
          onOperatorChange={handleOperatorChange}
        />
      </Row>
      {appearance !== 'column' && aggregate.default ? (
        <DefaultContainer>
          <DefaultHeader>
            <DefaultTitle>
              {aggregateInfo.title}
              <Tooltip placement="right" title={
                aggregateInfo.disclaimer_items.length > 1 ? (
                  <ul>
                    {aggregateInfo.disclaimer_items.map(item => (
                      <li key={item}>
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : (aggregateInfo.disclaimer_items[0])
              }>
                <InfoIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                    <path d="M7.4375 10.125V6.625H5.6875V7.5H6.5625V10.125H5.25V11H8.75V10.125H7.4375Z" fill="#7C94B1"/>
                    <path
                      d="M7 4C6.87021 4 6.74333 4.03849 6.63541 4.1106C6.52749 4.18271 6.44338 4.2852 6.39371 4.40512C6.34404 4.52503 6.33104 4.65698 6.35636 4.78428C6.38168 4.91158 6.44419 5.02851 6.53596 5.12029C6.62774 5.21207 6.74467 5.27457 6.87197 5.29989C6.99927 5.32521 7.13122 5.31222 7.25114 5.26255C7.37105 5.21288 7.47354 5.12877 7.54565 5.02085C7.61776 4.91293 7.65625 4.78605 7.65625 4.65625C7.65625 4.4822 7.58711 4.31528 7.46404 4.19221C7.34097 4.06914 7.17405 4 7 4Z"
                      fill="#7C94B1"/>
                    <path
                      d="M7 13.625C5.78859 13.625 4.60439 13.2658 3.59713 12.5928C2.58988 11.9197 1.80483 10.9631 1.34124 9.84394C0.877654 8.72474 0.756358 7.49321 0.992693 6.30507C1.22903 5.11694 1.81238 4.02557 2.66897 3.16897C3.52557 2.31238 4.61694 1.72903 5.80507 1.49269C6.99321 1.25636 8.22474 1.37765 9.34394 1.84124C10.4631 2.30483 11.4197 3.08988 12.0928 4.09713C12.7658 5.10439 13.125 6.28859 13.125 7.5C13.125 9.12445 12.4797 10.6824 11.331 11.831C10.1824 12.9797 8.62445 13.625 7 13.625ZM7 2.25C5.96165 2.25 4.94662 2.55791 4.08326 3.13479C3.2199 3.71166 2.547 4.5316 2.14964 5.49091C1.75228 6.45023 1.64831 7.50583 1.85088 8.52423C2.05345 9.54263 2.55347 10.4781 3.28769 11.2123C4.02192 11.9465 4.95738 12.4466 5.97578 12.6491C6.99418 12.8517 8.04978 12.7477 9.00909 12.3504C9.9684 11.953 10.7883 11.2801 11.3652 10.4167C11.9421 9.55339 12.25 8.53835 12.25 7.5C12.25 6.10762 11.6969 4.77226 10.7123 3.78769C9.72775 2.80313 8.39239 2.25 7 2.25Z"
                      fill="#7C94B1"/>
                  </svg>
                </InfoIcon>
              </Tooltip>
            </DefaultTitle>
            <ToggleButton onClick={aggregateAccordion.toggle}>
              {aggregateAccordion.opened ? 'Hide settings' : 'Open settings'}
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M9 12.375L3.375 6.75002L4.1625 5.96252L9 10.8L13.8375 5.96252L14.625 6.75002L9 12.375Z"
                  fill="#31C447"/>
              </svg>
            </ToggleButton>
          </DefaultHeader>
          {defaultSettingsTransition((style, active) => active && (
            <DefaultAnimatedContainer style={style}>
              <DefaultSettingsContainer>
                <DefaultAggregateSettings simplified aggregate={aggregate.aggregate}/>
              </DefaultSettingsContainer>
            </DefaultAnimatedContainer>
          ))}
        </DefaultContainer>
      ) : (
        <>
          <Divider/>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title disabled={isLoaded || aggregateSaved}>
              {loadedAggregatesOptions?.find(a => a.value === query.field)?.label || 'Aggregate'} {p('settings')}:
            </Title>
            <Tooltip title={p('aggregate_value_description')}>
              <InfoIconContainer style={{ marginBottom: 14, marginLeft: 5, backgroundColor: '#FFFFFF' }}>
                <Icon color="#31C447" name="Info-icon" size={14} />
              </InfoIconContainer>
            </Tooltip>
          </div>
          <div>
            <AggregateSettings
              isLoaded={isLoaded}
              aggregateSaved={aggregateSaved}
              state={aggregate}
              showErrors={showErrors}
              errors={query.errors}
              onClear={handleClearAggregateConditions}
              setState={(params) => {
                onAggregateChange(query.field, params);
              }}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default AggregateSelect;
