import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  width: 100%;
  transition: 330ms ease all;
  border-radius: 10px;
  overflow: visible;
  background: #F9FBFF;

  ${styledProp('$opened', `
    max-height: 400px;
    background: #F9FBFF;
  `, `
    max-height: 32px;
    background: #F9FBFF;
    cursor: pointer;
  `)}
`;

export const ToggleButtonContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 7.5px;
  transition: all 330ms ease;

  ${styledProp('$opened', `
    transform: rotateZ(-180deg);
  `, `
    transform: rotateZ(0deg);
  `)}
`;

export const Title = styled.div`
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  padding: 5px 0 5px 20px;
`;

export const TooltipContainer = styled.div`
  margin-left: 6px;
  margin-top: 5px;
`;

