import styled from 'styled-components';

export const Container = styled.div`
    /* position: relative; */
`;

export const Title = styled.h3`
  color: #1a232e;
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 22px;
`;

export const Description = styled.p`
  color: #7c94b1;
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 46px;
`;

export const Divider = styled.div`
    width: 100%;
    position: absolute;
    bottom: 65px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #D3DBE7;
    margin: 10px 0;
`

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
`