import { SqlQueryActionTypes, UserActionTypes, WorkspacesActionTypes } from '@store/actions/types';

const initialState = {
  queries: {
    data: [],
    loading: false,
  },
  example: { },
  query: { },
};

const sqlQueryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SqlQueryActionTypes.GET_SQL_QUERIES:
      return {
        ...state,
        queries: {
          ...state.queries,
          loading: true,
        },
      };
    case SqlQueryActionTypes.GET_SQL_QUERIES_SUCCESS:
      return {
        ...state,
        queries: {
          ...state.queries,
          data: action.payload,
          loading: false,
        },
      };
    case SqlQueryActionTypes.GET_SQL_QUERIES_ERROR:
      return {
        ...state,
        queries: {
          ...state.queries,
          loading: false,
        },
      };
    case SqlQueryActionTypes.GET_SQL_QUERY:
      return {
        ...state,
        query: {
          [action.meta.id]: {
            ...(state.query[action.meta.id] || {}),
            loading: true,
          },
        },
      };
    case SqlQueryActionTypes.GET_SQL_QUERY_SUCCESS:
      return {
        ...state,
        query: {
          [action.meta.id]: {
            ...(state.query[action.meta.id] || {}),
            data: action.payload,
            loading: true,
          },
        },
      };
    case SqlQueryActionTypes.GET_SQL_QUERY_ERROR:
      return {
        ...state,
        query: {
          [action.meta.id]: {
            ...(state.query[action.meta.id] || {}),
            loading: false,
          },
        },
      };
    case SqlQueryActionTypes.GET_EXAMPLE:
      return {
        ...state,
        example: {
          ...(state.example || {}),
          [action.meta.id]: {
            ...(state.example?.[action.meta.id] || {}),
            loading: true,
          }
        },
      };
    case SqlQueryActionTypes.GET_EXAMPLE_SUCCESS:
      return {
        ...state,
        example: {
          ...(state.example || {}),
          [action.meta.id]: {
            ...(state.example?.[action.meta.id] || {}),
            data: action.payload,
            loading: false,
          }
        },
      };
    case SqlQueryActionTypes.GET_EXAMPLE_ERROR:
      return {
        ...state,
        example: {
          ...(state.example || {}),
          [action.meta.id]: {
            ...(state.example?.[action.meta.id] || {}),
            loading: false,
          }
        },
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default sqlQueryReducer;
