import { WorkflowActionTypes } from '@store/actions/types';
import WorkflowEditorActionTypes from '@store/actions/types/workflowEditor';

export const workflowEditorSetNodes = ({ id, tree_map, flow, styles }) => ({
  type: WorkflowActionTypes.UPDATE_WORKFLOW_WORKER,
  payload: { id, tree_map, styles, flow },
  meta: { id },
});

export const discardNewWorkflow = () => ({
  type: WorkflowEditorActionTypes.DISCARD_NEW,
});

export const discardWorkflowChanges = ({ id }) => ({
  type: WorkflowEditorActionTypes.DISCARD,
  meta: { id },
});

export const workflowEditorSetOpenedNode = ({ id, ...node }) => ({
  type: WorkflowEditorActionTypes.UPDATE_OPENED_NODE,
  payload: { id, ...node },
});

export const workflowEditorDiscardOpenedNode = () => ({
  type: WorkflowEditorActionTypes.DISCARD_OPENED_NODE,
});

export const workflowEditorSetCurrentVersion = ({ version }) => ({
  type: WorkflowEditorActionTypes.SET_CURRENT_VERSION,
  payload: { version }
});
