import React, { useMemo } from 'react';
import { useSelector } from "react-redux";

import {
  templateApiRequestSelector,
  templateEmailsSelector, templateMobilePushSelector,
  templateSmsSelector,
  templateViberSelector,
  templateWebpushSelector, userSelector
} from "@store/selectors";

import { useTranslation } from "@hooks";

import { TemplatesCol, TemplatesContainer, TemplatesSection, TemplatesTitle } from "./styled";

import { EmailSection } from "../EmailSection";
import { MobilePushSection } from "../MobilePushSection";
import { OptionalItem } from "../OptionalItem";
import { SmsSection } from "../SmsSection";
import { ViberSection } from "../ViberSection";
import { WebhookSection } from "../WebhookSection";
import { WebpushSection } from "../WebpushSection";

const DashboardTemplates = () => {
  const { p } = useTranslation('dashboard_page');
  const smsTemplatesData = useSelector(templateSmsSelector);
  const viberTemplatesData = useSelector(templateViberSelector);
  const emailTemplatesData = useSelector(templateEmailsSelector);
  const webpushesData = useSelector(templateWebpushSelector);
  const mobilePushesData = useSelector(templateMobilePushSelector);
  const apiRequestsData = useSelector(templateApiRequestSelector);
  // const contentCards = useSelector(contentCardTemplatesSelector);
  const user = useSelector(userSelector);

  const smsTemplates = smsTemplatesData['default'];
  const viberTemplates = viberTemplatesData['default'];
  const emailTemplates = emailTemplatesData['default'];
  const webpushes = webpushesData['default'];
  const mobilePushes = mobilePushesData['default'];
  const apiRequests = apiRequestsData['default'];

  const renderTemplates = useMemo(() => {
    return !!emailTemplates?.meta?.total || !!viberTemplates?.meta?.total || !!smsTemplates?.meta?.total || !!webpushes?.meta?.total || mobilePushes?.meta?.total || apiRequests?.meta?.total || user?.app?.on_boarding?.templateCreated;
  }, [emailTemplates?.meta?.total, viberTemplates?.meta?.total, smsTemplates?.meta?.total, webpushes?.meta?.total, mobilePushes?.meta?.total, apiRequests?.meta?.total, user?.app?.on_boarding?.templateCreated])

  const renderOnlyLargeTemplates = useMemo(() => {
    return !(!!smsTemplates?.meta?.total) && !(!!webpushes?.meta?.total) && !(!!apiRequests?.meta?.total);
  }, [smsTemplates?.meta?.total, webpushes?.meta?.total, apiRequests?.meta?.total])

  const shouldRender = useMemo(() => {
    return (!!user?.app?.id);
  }, [user?.app?.id]);

  return shouldRender ? (
    <TemplatesSection data-testid="dashboard-templates-section">
      <TemplatesTitle data-testid="dashboard-title-last-templates" style={renderTemplates || !renderOnlyLargeTemplates ? {} : { opacity: '0.5' }}>
        {p('last_templates')}
      </TemplatesTitle>
      <TemplatesContainer onlyEmails={renderOnlyLargeTemplates}>
        <TemplatesCol style={{ marginRight: 10, flex: 1 }}>
          <OptionalItem
            shouldRender={!!emailTemplates?.meta?.total}
            tooltip={p('tab_after_emails')}
            content={(
              <EmailSection emailTemplates={emailTemplates} />
            )}
          />
          <OptionalItem
            shouldRender={!!viberTemplates?.meta?.total}
            tooltip={p('tab_after_vibers')}
            content={(
              <ViberSection viberTemplates={viberTemplates} />
            )}
          />
          <OptionalItem
            shouldRender={!!mobilePushes?.meta?.total}
            tooltip={p('tab_after_mobilepush')}
            content={(
              <MobilePushSection mobilePushes={mobilePushes} />
            )}
          />
          <OptionalItem
            shouldRender={!!webpushes?.meta?.total}
            tooltip={p('tab_after_webpush')}
            content={(
              <WebpushSection webpushes={webpushes} />
            )}
          />
        </TemplatesCol>
        <TemplatesCol style={{ marginLeft: 10 }}>
          <SmsSection smsTemplates={smsTemplates} />
          <WebhookSection apiRequests={apiRequests} />
        </TemplatesCol>
      </TemplatesContainer>
    </TemplatesSection>
  ) : null;
}

export default React.memo(DashboardTemplates);
