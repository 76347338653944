const GiveRewardStatuses = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

const GiveRewardStatusLabels = {
  [GiveRewardStatuses.SUCCESS]: 'statuses.success',
  [GiveRewardStatuses.FAILED]: 'statuses.failed',
};

const GiveRewardStatusOptions = [
  ...Object.entries(GiveRewardStatusLabels).map(([value, label]) => ({ value, label }))
];

export { GiveRewardStatuses, GiveRewardStatusLabels, GiveRewardStatusOptions };
