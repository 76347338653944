import styled from 'styled-components';

import { TextVariants } from '@constants';

import { Text } from '@components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const DeleteRuleButton = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #c8402f;
  display: flex;
  width: auto;
  flex-shrink: 1;
  flex-grow: 0;
  justify-content: center;
  padding: 14px;
  user-select: none;
  cursor: pointer;
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-top: 15px;
  margin-bottom: 12px;
  line-height: 120%;
  color: #c8402f;
  text-align: center;
`;

export const Optioncontainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const Title = styled(Text).attrs({ variant: TextVariants.HEADING_2 })`
  display: flex;
  justify-content: center;
`;
