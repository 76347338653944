import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { extract } from '@utils';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0;
  flex-shrink: 1;
  flex-grow: 0;
  
  align-items: ${extract('$align')};
`;

export const Message = styled(animated.div)`
  position: absolute;
  background: #262626;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
  padding: 6px 8px;
  pointer-events: none;
  top: -30px;
  right: -12px;
`;

