import { useTranslation } from '@hooks';

import { Container, ProgressIndicator, Description } from './styled';

const ABPercentageIndicator = ({ segments, invalid }) => {
  const total = segments.reduce((a, { value }) => a + Number(value), 0);
  const { p } = useTranslation('workflow_page');

  return (
    <Container>
      <ProgressIndicator invalid={invalid} total={total}>
        {p('ab_total', { value: (isNaN(+total) ? 0 : +total) })}
      </ProgressIndicator>
      <Description>
        {p('ab_error')}
      </Description>
    </Container>
  );
};

export default ABPercentageIndicator;
