import styled, { css } from 'styled-components';

import { switchStyle } from '@utils';

export const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 27px;
  flex: 1;
  width: 100%;
  align-self: auto;
`;
export const RemovalEvent = styled.div`
  background-color: #FFFFFF;
  border: solid 1px #D3DBE7;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RemovalLeft = styled.div`
  display: flex;
  gap: 10px;
`;

export const TemplateContainer = styled.div`
  border: solid 1px #D3DBE7;
  border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  position: relative;
  background-color: #FFFFFF;
  padding: 20px 20px 16px 20px;

  ${switchStyle('$previewType', {
    'preview-3-column': css`
      padding: 20px 20px 16px 20px;
    `,
    [undefined]: `
    `
  })}
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
`;

export const LeftLine = styled.div`
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: #31C447;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  top: 0;
  left: 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const TemplateContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const InnerTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: Manrope-SemiBold;
  font-size: 16px;
`;

export const InnerDescription = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
  width: 337px;

  ${switchStyle('$previewType', {
    'preview-3-column': css`
      width: 100%;
    `,
    [undefined]: `
    `
  })}
`;

export const IconImg = styled.img`
  
`;

export const Check = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const EditTemplateButton = styled.div`
    border-radius: 4px;
    background: var(--colors-black-60, rgba(12, 16, 21, 0.60));
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TestIntegrationDisclaimer = styled.div`
    border-radius: 7px;
    border: 1px solid #FFDC7B;
    background: #FFFAED;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;

    color: var(--text-primary-body, #1A232E);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;
`;

export const WebSDKDisclaimer = styled.div`
  margin: 20px 0 0 0 ;
`