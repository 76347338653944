import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: 5px 12px;
    gap: 8px;

    border-top-right-radius: var(--buttons-small-radius, 5px);
    border-top-left-radius: var(--buttons-small-radius, 5px);
    border-bottom: 1px solid var(--input-input-border, #D3DBE7);
    background: var(--input-input-bg, #FFF);
    
    position: relative;
    padding-right: 32px;
    overflow: hidden;
`;

export const Input = styled.input`
    display: flex;
    flex: 1;
    outline: none;
    border: none;
    height: 18px;

    color: var(--input-input-txt, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;

    &::placeholder {
        color: var(--input-input-placeholder, #A1B3C8);
        font-feature-settings: 'clig' off, 'liga' off;

        font-family: Manrope-Medium;
        font-size: 13px;
        line-height: 18px;
        user-select: none;
    }
    
    &:hover {
        outline: none;
        border: none;
    }

    &:focus {
        outline: none;
        border: none;
    }

    &:active {
        outline: none;
        border: none;
    }
`;

export const Clear = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 8px 0 4px;
`;
