import { Handle } from 'reactflow';

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Wrapper = styled(motion.div).attrs({ animate: { opacity: 1, x: 0 } })`
  display: flex;
  justify-content: center;
  background: transparent;
  opacity: 0;
  width: 240px;
  height: 48px;
  transform: translateX(-255px);
  background: transparent;
  display: flex;
  flex-direction: column;
  flex: 0;
  max-width: 240px;
  margin-right: 0;
  align-items: center;
  border-radius: 6px;
  ${switchStyle('position', {
    left: css`
        height: 56px;
        transform: translate(0);
    `,
    top: css`
      height: 200px;
      transform: translate(0);
    `,
    bottom: css`
     height: 200px;
      transform: translate(0);
    `,
    right: css`
     height: 56px;
     transform: translate(0);
    `,
  })}
`;

export const PointerContainer = styled.div`
  display: flex;
  justify-content: center;
  background: transparent;
  pointer-events: none;
`;

export const Container = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ABText = styled.div`
  display: inline-flex;
  text-wrap: nowrap;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid #748BAD;
  background: #FFF;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;;
  text-align: center;
  color: #1A232E;
  position: absolute;
`;

export const ControlGroup = styled.div`
  border-radius: 10px;
  border: 2px solid var(--Colors-Neutral-500p, #7C94B1);
  background: var(--cards-bg, #F8FAFE);
  padding: 12px;
  width: 240px;
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;

  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 14px;
  line-height: 20px;
`;

export const ControlGroupInfo = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
`;

export const NodeAnalyticsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  position: absolute;
  top: -35px;
`;

export const StyledHandle = styled(Handle)`
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  background: #A9A7A7;
  cursor: copy !important;
  border-radius: 50%;
  transition: border-width 280ms ease, transform 280ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  ${styledProp('disabled', css`
    pointer-events: none;
  `)}

  ${switchStyle('position', {
    top: css`
      transform: translate(0);
    `,
    bottom: css`
      transform: translate(0);
    `,
    left: css`
        transform: translate(0, -4px);
    `,
    right: css`
        transform: translate(0, -4px);
    `,
  })}

  & > * {
    opacity: 0;
    transition: all 280ms ease;
    pointer-events: none;
  }

  :hover {
    transform: scale(1.5) translateY(-1.5px);
    border-width: 0px;
    & > * {
      opacity: 1;
      //transform: scale(1.5) translateY(-0.1px);
      //transform: translateY(1.5px);
    }
  }
`;
