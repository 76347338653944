import { by } from '@utils';

import RewardTemplatePreviewCard
  from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/GiveRewardNodeSettings/components/RewardTemplatePreviewCard/RewardTemplatePreviewCard';
import RewardTypes
  from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/GiveRewardNodeSettings/rewardTypes';

const config = {
  [RewardTypes.BONUS]: [
    [{ title: 'Grant', key: 'grant', renderData: g => g?.type }],
    [
      {
        title: 'Wagering requirement',
        key: 'wageringRequirement',
        renderData: wr => `XOF ${wr?.value?.find?.(by('currency', 'XOF'))?.amount}`
      },
      {
        title: 'Wagering requirement type',
        key: 'wageringRequirement',
        renderData: wr => wr?.type
      }
    ],
    [
      {
        title: 'Money type priority',
        key: 'moneyTypePriority',
      },
      {
        title: 'Lock amount strategy',
        key: 'lockAmountStrategy',
      }
    ],
    [
      {
        title: 'Min reward',
        key: 'minBet',
        renderData: mb => `${mb?.[0]?.currency} ${mb?.[0]?.amount}`
      },
      {
        title: 'Capping',
        key: 'capping',
        renderData: c => c ? `${c?.type} ${c?.value?.[0]?.currency} ${c?.value?.[0]?.amount}` : '---',
      }
    ],
    [
      {
        title: 'Bonus lifetime',
        key: 'bonusLifeTime',
        renderData: d => `${d} days`
      },
      {
        title: 'Claimable',
        key: 'claimable',
        renderData: String
      }
    ],
    [
      {
        title: 'Claim period',
        key: 'claimablePeriod'
      }
    ]
  ],
  [RewardTypes.FREE_SPIN]: [
    [
      {
        title: 'Provider',
        key: 'provider',
      },
      {
        title: 'Aggregator',
        key: 'aggregator',
      }
    ],
    [
      {
        title: 'Game',
        key: 'game',
      },
      {
        title: 'Free spin amount',
        key: 'freeSpinsAmount',
      }
    ],
    [
      {
        title: 'Claimable',
        key: 'claimable',
        renderData: String
      },
      {
        title: 'Claim period',
        key: 'claimablePeriod'
      }
    ],
    [
      {
        title: 'Free spin lifetime',
        key: 'freeSpinsLifeTime',
      },
    ],
  ],
  [RewardTypes.FREE_BET]: [
    [
      {
        title: 'Bet amount',
        key: 'betAmounts',
        renderData: ba => ba && `${ba?.[0].currency} ${ba?.[0].amount}`
      },
    ],
    [
      {
        title: 'Free bet lifetime',
        key: 'freeBetLifeTime',
        renderData: d => `${d} days`
      },
      {
        title: 'Claimable',
        key: 'claimable',
        renderData: String
      }
    ],
    [
      {
        title: 'Claim period',
        key: 'claimablePeriod'
      }
    ]
  ]
}

const RewardTemplatePreview = ({ type, data }) => {
  const cfg = config[type];

  return <RewardTemplatePreviewCard rows={cfg} data={data} title={`${data?.name} characteristics`} />
};

export default RewardTemplatePreview;
