import uniqBy from 'lodash.uniqby';

export const parseLinks = (html, prevLinks) => {

  const parser = new DOMParser();
  const document = parser.parseFromString(html, 'text/html');

  const links = document.getElementsByTagName('a');

  const formattedLink = Array.from(links)
    .filter(link => !!link?.attributes?.href?.value)
    .map((link) => {
      const existLink = prevLinks?.find(l => l.url === link.href);
      return { url: link.href, track: existLink ? existLink.track : true }
    });

  return uniqBy(formattedLink, 'url');
};
