import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  //padding: 12px 20px;
  justify-content: space-between;
`;

export const RangeContainer = styled.div`
  background: #FAFAFA;
  border-radius: 10px;
  padding: 10px 14px;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  ${styledProp('$smaller', css`
    justify-content: center;
  `)}
`;

export const EditButton = styled.div`
  font-family: Manrope-Medium;
  margin-right: 20px;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  color: #31C447;
  display: flex;
  align-items: center;
`;
