import { formatNumberString } from '@utils';

import { LegendItem } from '@components';

import { Container, StatusesLegendContainer, StatusesLegendRow, StatusesLegendValue } from './styled.js';

export const NodeAnalyticsPopover = ({ data, style }) => {
  const filteredData = data?.filter(({ count }) => +count);

  return (
    <Container style={style}>
      <StatusesLegendContainer>
        {filteredData.map(({ color, label, count }, index) => (
          <StatusesLegendRow key={index}>
            <LegendItem style={{ textTransform: 'initial' }} name={label} color={color} />
            <StatusesLegendValue>
              {formatNumberString(count)}
            </StatusesLegendValue>
          </StatusesLegendRow>
        ))}
      </StatusesLegendContainer>
    </Container>
  );
}

