import { useDispatch, useSelector } from 'react-redux';

import { getOptions, getOptionsWorkflow, setLastUpdate, getOptionsCampaigns } from '@store/actions/creators';
import { optionsSelector } from '@store/selectors';

export const useSystemOptions = () => {
  const dispatch = useDispatch();
  const options = useSelector(optionsSelector);

  const requestOptions = () => {

    dispatch(getOptions());
    dispatch(setLastUpdate(Date.now()));
  }

  const updateOptions = () => {
    dispatch(getOptions());
    dispatch(setLastUpdate(Date.now()));
  };

  const requestWorkflowOptions = () => {
    dispatch(getOptionsWorkflow());
  };

  const requestAllCampaign = () => {
    dispatch(getOptionsCampaigns())
  };

  return {
    requestOptions,
    requestWorkflowOptions,
    updateOptions,
    requestAllCampaign,
    options,
  }
};
