import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const SlideOut = styled(animated.div)`
  
`;

export const Container = styled.div`
    display: flex;
    padding: 16px 20px;
    flex-shrink: 0;
    
    border-radius: 7px;
    border: 1px solid var(--cards-border, #d3dbe7);
    background: var(--cards-bg, #f8fafe);
    margin-bottom: 16px;
    
    
`;

export const Title = styled.h4`
    color: var(--input-input-txt-lable, #7c94b1);
    font-family: Manrope-medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 5px;
    
    user-select: none;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LeftSideContainer = styled.div``;

export const RightSideContainer = styled.div`
margin-top: 20px;
    display: inline-flex;
    gap: 10px;
    align-items: center;
`;
