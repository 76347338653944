import Echo from 'laravel-echo';

import ApiService from '../services/ApiService';

const initPusher = ({ accessToken, onReceiveNotification, user }) => {
  const hostname = window.location.hostname;

  if(window.Echo) {
    console.log('Echo is already exist')
    return;
  }

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'websocketkey',
    wsHost: hostname.includes('my') ? hostname.replace('my','ws') : 'stage.ws.retainly.app',
    wsPort: 80,
    port: 80,
    forceTLS: true,
    debug: false,
    disableStats: true,
    authorizer: (channel, _options) => {
      return {
        authorize: async (socketId, callback) => {
          await ApiService.call(
            'post',
            '/api/broadcasting/auth',
            { socket_id: socketId, channel_name: channel.name },
            {},
            { Authorization: `Bearer ${accessToken}` })
            .then(response => {
              callback(false, response.data);
            })
            .catch(error => {
              callback(true, error);
            });
        }
      };
    },
  });

  window.Echo.private('App.User.' + user.id)
    .notification((notification) => {
      onReceiveNotification(notification);
    });
}

export default initPusher;
