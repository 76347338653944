import { useState } from 'react';

import { Popover } from '@components/layout/Topbar/components/Popover';
import { Item } from '@components/layout/Topbar/components/TopbarSettings/styled';

import { Container } from './styled';

const options = [
  { value: 'en', label: 'English' },
  // { value: 'ge', label: 'GE' },
];

const LanguageSelect = ({ opened, language, onChange, onToggle }) => {
  const [hovered, setHovered] = useState(false);
  const handleToggle = () => {
    onToggle();
  }

  return (
    <Container>
      <Popover
        larger
        isLanguage={true}
        setHovered={setHovered}
        overlayClassName="stt-2"
        options={options}
        onLanguageChange={onChange}
        language={language}
      >
        <Item onClick={handleToggle} style={{ borderTop: 'none' }} $opened={opened} $hovered={hovered} isLanguage={true} >
          {(language || 'En').toUpperCase()}
        </Item>
      </Popover>
    </Container>
  );
};

export default LanguageSelect;
