import {
  zeroIfNaN,
} from '@utils';

export const getRatesConfig = (total, translate, statuses) => [
  {
    title: translate('delivery_rate'),
    tooltip: translate('delivery_rate_description'),
    icon: 'EmailNew',
    color: '#24B277',
    total: total,
    name: 'delivery',
    actual: [
      'delivered',
      'open',
      'unsubscribed',
      'resubscribed',
      'click',
      'spam_report',
    ]
      .map((s) => zeroIfNaN(statuses[s]))
      .reduce((a, b) => a + b, 0),
    status: 'delivered',
    small: true,
  },
  {
    title: translate('opened_rate'),
    tooltip: translate('opened_rate_description'),
    icon: 'EmailOpened',
    name: 'opened',
    color: '#24B277',
    total: total,
    actual: ['open', 'unsubscribed', 'resubscribed', 'click', 'spam_report']
      .map((s) => zeroIfNaN(statuses[s]))
      .reduce((a, b) => a + b, 0),
    status: 'open',
    small: true,
  },
  {
    title: translate('click_through_rate'),
    tooltip: translate('click_through_rate_description'),
    icon: 'Cursor2',
    color: '#24B277',
    total: total,
    name: 'click',
    actual: statuses['click'],
    status: 'click',
    small: true,
  },
  {
    title: translate('bounce_rate'),
    tooltip: translate('bounce_rate_description'),
    icon: 'Error',
    color: '#F04438',
    name: 'failed',
    total: total,
    actual: statuses['bounce'],
    status: 'bounce',
    small: true,
  },
  {
    title: translate('unsub_rate'),
    tooltip: translate('unsub_rate_description'),
    icon: 'Error',
    name: 'failed',
    color: '#F04438',
    total: total,
    actual: statuses['unsubscribed'],
    status: 'unsubscribed',
    small: true,
  },
];