import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

`;

export const CountryIcon = styled.span`
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  max-height: 24px;

`

export const CountryName = styled.p`
  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #1A232E;
  `

