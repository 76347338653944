import { FunnelsActionTypes } from '@store/actions/types';
import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeLatest, takeEvery, put } from 'redux-saga/effects';

function* exportFunnelCustomersSaga(action) {
  const { query, ...payload } = action.payload;
  const body = payload;

  if (query?.children) {
    body.query = query;
  }

  yield put(createRequestAction(action, {
    endpoint: '/api/seg/analytics/funnel/export-customers',
    method: 'post',
    body,
  }));
  // Handle file download in a different place based on the result of the above action
}

function* getFunnelSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/seg/analytic-funnel/${action.payload.id}`,
  }));
}

function* listFunnelsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/seg/analytic-funnel'
  }));
}

function* previewFunnelSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/seg/analytics/funnel/preview',
    body: {
      ...action.payload,
      query: action.payload.query ? action.payload.query : void 0,
      dateRange: action.payload.dateRange?.length ? action.payload.dateRange : void 0
    },
  }));
}

function* storeFunnelSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/seg/analytic-funnel',
    body: action.payload,
  }));
}

function* archiveFunnelSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `/api/seg/analytic-funnel/${action.payload.id}/archive`,
  }));
}

function* unarchiveFunnelSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `/api/seg/analytic-funnel/${action.payload.id}/un_archive`,
  }));
}

function* deleteFunnelSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: `/api/seg/analytic-funnel/${action.payload.id}`,
    body: action.payload,
  }));
}

function* funnelsRootSaga() {
  return yield all([
    takeLatest(FunnelsActionTypes.LIST, listFunnelsSaga),
    takeLatest(FunnelsActionTypes.GET, getFunnelSaga),
    takeLatest(FunnelsActionTypes.STORE, storeFunnelSaga),
    takeEvery(FunnelsActionTypes.PREVIEW, previewFunnelSaga),
    takeEvery(FunnelsActionTypes.ARCHIVE, archiveFunnelSaga),
    takeEvery(FunnelsActionTypes.UNARCHIVE, unarchiveFunnelSaga),
    takeEvery(FunnelsActionTypes.DELETE, deleteFunnelSaga),
    takeLatest(FunnelsActionTypes.EXPORT_CUSTOMERS, exportFunnelCustomersSaga),
  ]);
}

export default funnelsRootSaga;
