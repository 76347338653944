import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  width: 100%;
  > div:first-of-type {
    width: 100%;
  }

  .popover-inner {
    border-radius: 0 5px 5px 0; 
  }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: auto;
`;

export const Row = styled.div`
  display: flex;
  min-width: 200px;
  align-items: center;
  display: flex;
  width: 100%;
  align-items: center;
 
  cursor: pointer;
  padding: 3px 16px;
  min-height: 40px;
  gap: 8px;
  color: var(--Menu-item-text-default, #7C94B1);
  font-family: Manrope-medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  &:hover {
    color: #fff;
    background: var(--menu-item-hover);
  }

  ${styledProp('$active', `
      color: #fff;
       background: var(--menu-item-hover);
  `)}
`
