import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.button`
  border-radius: 12px;
  padding: 18px 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  position: relative;
  overflow: visible;
  cursor: pointer;
  transition: background 230ms ease;
  -webkit-backface-visibility: hidden;
  
  ${styledProp('disabled', css`
    //pointer-events: none;
  `)}
  
  ${switchStyle('$appearance', {
    primary: css`
      box-shadow: inset 0px -5px 10px rgba(44, 53, 204, 0.4);
      background: linear-gradient(180deg, #4A88FF 18%, #5A56FD 106%);
      &::after {
        position: absolute;
        border-radius: 12px;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, #6052FB 18%, #6052FB 106%);
        z-index: 1;
        opacity: 0;
        transition: opacity 330ms linear;
        overflow: visible;
        -webkit-backface-visibility: hidden;
      }

      &:hover::after {
        opacity: 1;
      }

      &:focus::after {
        opacity: 1;
      }

      &::before {
        position: absolute;
        bottom: -7px;
        left: 10px;
        right: 10px;
        content: "";
        height: 30px;
        background: linear-gradient(180deg, #4986FD 0%, #9D67F6 100%);
        opacity: 0.6;
        filter: blur(20px);
        -webkit-filter: blur(20px);
        border-radius: 7px;
        overflow: visible;
        z-index: 1;
        pointer-events: none;
        transition: 330ms filter ease;
      }
    `,
    secondary: css`
      background: #282B4D;
      opacity: 0.9;
      border: 1px solid #4986FD;
      box-sizing: border-box;
      box-shadow: inset 0px 0px 10px rgba(90, 86, 253, 0.5);
    `,
    lightSecondary: css`
      background: linear-gradient(180deg, #FFFFFF 0%, #E6E6E6 100%);;
      opacity: 0.9;
      border: none;
      box-sizing: border-box;
      //box-shadow: inset 0px 0px 10px;
    `,
    lightPrimary: css`
      background: #31C447;
      opacity: 0.9;
      border: none;
      box-sizing: border-box;
      //box-shadow: inset 0px 0px 10px;
    `,
  })}
`;

export const Title = styled.div`
  font-family: Manrope-Medium;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  z-index: 1000;

  ${switchStyle('$appearance', {
    lightSecondary: css`
      color: #303133;
      font-weight: 400;
      font-size: 15px;
      line-height: 100%;
    `,
  })}
`;
