import { Icon } from '@velitech/ui';
import { Tooltip } from 'antd';

import { Icon as DEPRECATED_Icon } from '@components';

import { Container } from './styled';

const ActionButton = ({
  testId,
  appearance = 'default',
  icon,
  renderIcon,
  onClick,
  onClickCapture,
  disabled,
  disabledTooltip,
  tooltip,
  hide = false,
  size = 22,
  iconColor,
  iconSize = 12,
  tooltipProps = {},
  preview,
  useDeprecatedIcon = true,
  ...props
}) => {
  const handleClick = (event) => {
    if (disabled) {
      return;
    }

    onClick?.(event);
  };

  const handleClickCapture = (event) => {
    if (disabled) {
      return;
    }

    onClickCapture?.(event);
  };
  const IconComponent = useDeprecatedIcon ? DEPRECATED_Icon : Icon;

  return (
    <Tooltip title={disabled ? disabledTooltip || tooltip : tooltip} {...tooltipProps}>
      <Container $newStyle={!useDeprecatedIcon} preview={preview} hide={hide} data-testid={testId} onClickCapture={handleClickCapture} onClick={handleClick} disabled={disabled} danger={appearance === 'danger'} appearance={appearance} size={size} {...props}>
        {renderIcon ? renderIcon : <IconComponent name={icon} size={iconSize} color={iconColor} />}
      </Container>
    </Tooltip>
  );
};

export default ActionButton;
