import { useTranslation } from '@hooks';

import { by } from '@utils';

import {
  AnalyticsSection
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import {
  SendingSection
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendingSection';

import { Container } from './styled';

const ActionOptions = [
  {
    value: 'include',
    label: 'Include in campaign',
  },
  {
    value: 'exclude',
    label: 'Remove from campaign',
  }
];

const IncludeCampaignNodeSettingsInfo = ({ node }) => {
  const { t } = useTranslation();

  if (!node.data.campaign_id) {
    return (
      <Container>
        <InfoTitle>{t('labels.selected_campaign')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  const action = ActionOptions.find(by('value', node.data?.action))?.label;

  return (
    <Container>
      <InfoTitle>
        {t('labels.action')}:
      </InfoTitle>
      <InfoText style={{ marginBottom: 8 }}>
        {action}
      </InfoText>
      <InfoTitle>
        {t('labels.selected_campaign')}:
      </InfoTitle>
      <InfoText>
        {node.data.campaign_name}
      </InfoText>
      {node.data.sending && <SendingSection statuses={node.data.sending} out={node.data.analytics?.out} />}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
};

export default IncludeCampaignNodeSettingsInfo;
