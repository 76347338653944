import { UserPermissions } from '@constants';
import { TemplateTypes } from '@constants/templates';

const useTemplatePermissions = (type) => {
  switch (type) {
    case TemplateTypes.SMS:
      return {
        CREATE: UserPermissions.CREATE_SMS,
        UPDATE: UserPermissions.UPDATE_SMS,
        VIEW: UserPermissions.REVIEW_SMS,
        DELETE: UserPermissions.DELETE_SMS,
        ARCHIVE: UserPermissions.ARCHIVE_SMS,
        UNARCHIVE: UserPermissions.UN_ARCHIVE_SMS,
      };
    case TemplateTypes.EMAIL:
      return {
        CREATE: UserPermissions.CREATE_EMAILS,
        UPDATE: UserPermissions.UPDATE_EMAILS,
        VIEW: UserPermissions.REVIEW_EMAILS,
        DELETE: UserPermissions.DELETE_EMAILS,
        ARCHIVE: UserPermissions.ARCHIVE_EMAILS,
        UNARCHIVE: UserPermissions.UN_ARCHIVE_EMAILS,
      };
    case TemplateTypes.WEB_PUSH:
      return {
        CREATE: UserPermissions.CREATE_WEBPUSHES,
        UPDATE: UserPermissions.UPDATE_WEBPUSHES,
        VIEW: UserPermissions.REVIEW_WEBPUSHES,
        DELETE: UserPermissions.DELETE_WEBPUSHES,
        ARCHIVE: UserPermissions.ARCHIVE_WEBPUSH,
        UNARCHIVE: UserPermissions.UN_ARCHIVE_WEBPUSH,
      };
    case TemplateTypes.MOBILE_PUSH:
      return {
        CREATE: UserPermissions.CREATE_MOBILE_PUSH,
        UPDATE: UserPermissions.UPDATE_MOBILE_PUSH,
        VIEW: UserPermissions.REVIEW_MOBILE_PUSH,
        DELETE: UserPermissions.DELETE_MOBILE_PUSH,
        ARCHIVE: UserPermissions.ARCHIVE_MOBILE_PUSH,
        UNARCHIVE: UserPermissions.UN_ARCHIVE_MOBILE_PUSH,
      };
    case TemplateTypes.VIBER:
      return {
        CREATE: UserPermissions.CREATE_VIBER,
        UPDATE: UserPermissions.UPDATE_VIBER,
        VIEW: UserPermissions.REVIEW_VIBER,
        DELETE: UserPermissions.DELETE_VIBER,
        ARCHIVE: UserPermissions.ARCHIVE_VIBER,
        UNARCHIVE: UserPermissions.UN_ARCHIVE_VIBER,
      };
    case TemplateTypes.WEBHOOK:
      return {
        CREATE: UserPermissions.CREATE_API_REQUESTS,
        UPDATE: UserPermissions.UPDATE_API_REQUESTS,
        VIEW: UserPermissions.REVIEW_API_REQUESTS,
        DELETE: UserPermissions.DELETE_API_REQUESTS,
        ARCHIVE: UserPermissions.ARCHIVE_WEBHOOKS,
        UNARCHIVE: UserPermissions.UN_ARCHIVE_WEBHOOKS,
      };
    case TemplateTypes.MESSAGE_FEED:
      return {
        CREATE: UserPermissions.CREATE_CONTENT_CARDS,
        UPDATE: UserPermissions.UPDATE_CONTENT_CARDS,
        VIEW: UserPermissions.REVIEW_CONTENT_CARDS,
        DELETE: UserPermissions.DELETE_CONTENT_CARDS,
        ARCHIVE: UserPermissions.ARCHIVE_EMAILS,
        UNARCHIVE: UserPermissions.UN_ARCHIVE_EMAILS,
      };
    case TemplateTypes.WEB_POPUP:
      return {
        CREATE: UserPermissions.CREATE_CONTENT_CARDS,
        UPDATE: UserPermissions.UPDATE_CONTENT_CARDS,
        VIEW: UserPermissions.REVIEW_CONTENT_CARDS,
        DELETE: UserPermissions.DELETE_CONTENT_CARDS,
        ARCHIVE: UserPermissions.ARCHIVE_EMAILS,
        UNARCHIVE: UserPermissions.UN_ARCHIVE_EMAILS,
      };
    default:
      return {};
  }
};

export default useTemplatePermissions;
