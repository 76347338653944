import { Layout } from 'antd';
import styled from 'styled-components';
const { Sider } = Layout;

export const SiderStyled = styled(Sider)`
  min-width: 300px;
  padding: 0 30px;
  background: #f4f5fd;
  position: relative;
  max-height: 600px;
  overflow: scroll;

  &::before {
    content: '';
    width: 1px;
    height: 98%;
    position: absolute;
    right: 0;
    background: #d3dbe7;
  }
`;

export const LayoutStyled = styled(Layout)`
  padding-top: 40px;
  background: #f4f5fd;
`;

export const Title = styled.h2`
  color: #1a232e;
  text-align: center;

  font-family: Manrope-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const SubTitle = styled.h4`
  color: #7c94b1;
  text-align: center;
  font-family: Manrope-Semibold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
