import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { IconSvg } from '@components';

import { Container, Icon as IconContainer, InnerContainer } from './styled';

const BlockIcons = {
  aggregate: 'Aggregate-attribute',
  field: 'Linear-attribute',
  expression: 'Expression-attribute',
  event: 'Event-attribute',
  discount: 'Discount-attribute',
  dynamic_variable: 'Dynamic-attribute',
  "dynamic_otp": "user_icon"
};

const BlockColors = {
  aggregate: 'linear-gradient(206deg, #F4A53C 0%, #ED992A 100%)',
  field: '#31C447',
  event: '#24993E',
  discount: '#13A6C7',
  dynamic_variable: '#303133',
  expression: '#24993E',
  "dynamic_otp": "#3253FF"
};

const resolveLabel = (label) => {
  return label === 'field' ? 'linear_field' : label;
}

const EntityBlock = ({ attributes, element, children }) => {
  const { t } = useTranslation();

  return (
    <Container
      {...attributes}
      contentEditable={false}
      data-cy={`entity-${element.name}`}
    >
      <Tooltip title={t(`labels.${resolveLabel(element.kind)}`)}>
        <InnerContainer>
          <IconContainer $bg={BlockColors[element.kind]}>
            <IconSvg name={BlockIcons[element.kind]} size={10} color="#fff" />
          </IconContainer>
          {element.label}
        </InnerContainer>
      </Tooltip>
      {children}
    </Container>
  );
};

export default EntityBlock;
