import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import * as Colors from "@res/theme/colors";

export const Container = styled.div`
    position: relative;
    ${styledProp('$small', css`
    `, css`
      flex: 1;
      min-width: 214px;
      max-width: 550px;
    `)}

    outline: none;
    margin: 1px;
    
    & * {
        outline: none;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid var(--cards-border, #D3DBE7);
    background: var(--cards-bg, #F8FAFE);
    user-select: none;
    position: relative;
    height: 70px;
    
    ${styledProp('$dragging', css`
        opacity: 0.5;
    `)}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
    position: absolute;
    white-space: wrap;
    overflow: hidden;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100px;
    padding-left: 12px;
    justify-content: center;
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-SemiBold;
    font-size: 14px;
    line-height: 20px;
`;

export const Date = styled.p`
  margin: 0;
    color: var(--text-secondary-body, #7C94B1);
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: Manrope-Medium;
    font-size: 12px;
    line-height: 18px;
`;

export const MenuItem = styled.p`
  font-size: 13px;
  color: ${({ danger }) => (danger ? Colors.Text.DANGER : Colors.Text.PRIMARY)};
  margin: 0;
    white-space: nowrap;
  ${({ disabled }) => disabled ? 'opacity: 0.4; pointer-events: none;' : ''}
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
