import styled from 'styled-components';

export const Container = styled.div`
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #DCDFE6;
  margin-top: 24px;
  margin-bottom: 24px;
`;
