import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 160px;
  width: 300px;
`;

export const FolderIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(193, 206, 252, 0.4);
  box-shadow: 0px 4px 6px rgba(193, 206, 252, 0.4);
`;

export const Description = styled.div`
    color: var(--text-secondary-body, #7C94B1);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
    margin-top: 20px;
`;
