import { useState } from 'react';

import { useTransition } from '@react-spring/web';
import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { DirectionModes } from '@constants';

import { AddBranchContainer, Container, IconContainer } from './styled';

import { AddNodeDropdown } from '../AddNodeDropdown';

export const AddNodeIcon = (
  <IconContainer>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 0C8.09354 0.0351657 5.31603 1.20538 3.26071 3.26071C1.20538 5.31603 0.0351657 8.09354 0 11C0.0351657 13.9065 1.20538 16.684 3.26071 18.7393C5.31603 20.7946 8.09354 21.9648 11 22C13.9065 21.9648 16.684 20.7946 18.7393 18.7393C20.7946 16.684 21.9648 13.9065 22 11C21.9648 8.09354 20.7946 5.31603 18.7393 3.26071C16.684 1.20538 13.9065 0.0351657 11 0ZM17.2857 11.7857H11.7857V17.2857H10.2143V11.7857H4.71429V10.2143H10.2143V4.71429H11.7857V10.2143H17.2857V11.7857Z" fill="#D3DBE7"/>
    </svg>
  </IconContainer>
);

export const AddBranchIcon = (
  <IconContainer>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="22" height="22" rx="11" fill="#D3DBE7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9801 5.92383L14.9801 3.42383L14.0199 4.57616L15.4285 5.74999H9.75C8.09315 5.74999 6.75 7.09314 6.75 8.74999C6.75 9.57842 6.07843 10.25 5.25 10.25H3V11.75H5.25C6.07843 11.75 6.75 12.4216 6.75 13.25C6.75 14.9068 8.09315 16.25 9.75 16.25H15.4285L14.0199 17.4238L14.9801 18.5762L17.9801 16.0762L18.6715 15.5L17.9801 14.9238L14.9801 12.4238L14.0199 13.5762L15.4285 14.75H9.75C8.92157 14.75 8.25 14.0784 8.25 13.25C8.25 12.354 7.85718 11.5497 7.23437 11C7.85718 10.4503 8.25 9.64601 8.25 8.74999C8.25 7.92157 8.92157 7.24999 9.75 7.24999H15.4285L14.0199 8.42383L14.9801 9.57616L17.9801 7.07616L18.6715 6.49999L17.9801 5.92383Z" fill="white"/>
    </svg>
  </IconContainer>
);

export const ConnectionIcon = (props) => (
  <IconContainer {...props}>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="22" height="22" rx="11" fill="#D3DBE7"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.75 7.25C10.5784 7.25 11.25 7.92157 11.25 8.75C11.25 9.64602 11.6428 10.4503 12.2656 11C11.6428 11.5497 11.25 12.354 11.25 13.25C11.25 14.0784 10.5784 14.75 9.75 14.75H5V16.25H9.75C11.4069 16.25 12.75 14.9069 12.75 13.25C12.75 12.4216 13.4216 11.75 14.25 11.75H16.9285L15.5199 12.9238L16.4801 14.0762L19.4801 11.5762L20.1715 11L19.4801 10.4238L16.4801 7.92383L15.5199 9.07617L16.9285 10.25H14.25C13.4216 10.25 12.75 9.57843 12.75 8.75C12.75 7.09315 11.4069 5.75 9.75 5.75H5V7.25H9.75Z" fill="white"/>
    </svg>
  </IconContainer>
);

export const ConnectionIconActive = (props) => (
  <IconContainer {...props}>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="22" height="22" rx="11" fill="#31C447"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.75 7.25C10.5784 7.25 11.25 7.92157 11.25 8.75C11.25 9.64602 11.6428 10.4503 12.2656 11C11.6428 11.5497 11.25 12.354 11.25 13.25C11.25 14.0784 10.5784 14.75 9.75 14.75H5V16.25H9.75C11.4069 16.25 12.75 14.9069 12.75 13.25C12.75 12.4216 13.4216 11.75 14.25 11.75H16.9285L15.5199 12.9238L16.4801 14.0762L19.4801 11.5762L20.1715 11L19.4801 10.4238L16.4801 7.92383L15.5199 9.07617L16.9285 10.25H14.25C13.4216 10.25 12.75 9.57843 12.75 8.75C12.75 7.09315 11.4069 5.75 9.75 5.75H5V7.25H9.75Z" fill="white"/>
    </svg>
  </IconContainer>
)

export const LineIconHorizontal = (
  <svg width="2" height="11" viewBox="0 0 2 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.5V9.5" stroke="#C1C2C4" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export const LineIconVertical = (
  <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1H10" stroke="#C1C2C4" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);

export const CopyIcon = (
  <IconContainer>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="22" height="22" rx="11" fill="#31C447"/>
      <path d="M16.7 10.35L13.65 7.3C13.5 7.1 13.25 7 13 7H9C8.45 7 8 7.45 8 8V17C8 17.55 8.45 18 9 18H16C16.55 18 17 17.55 17 17V11.05C17 10.8 16.9 10.55 16.7 10.35ZM13 8L15.95 11H13V8ZM9 17V8H12V11C12 11.55 12.45 12 13 12H16V17H9Z" fill="white"/>
      <path d="M6 12H5V5C5 4.45 5.45 4 6 4H13V5H6V12Z" fill="white"/>
    </svg>
  </IconContainer>
);

const AddNodeButton = ({ onSelectNode, children, addBranchAllowed, directionMode }) => {
  const [hovered, setHovered] = useState(false);
  const { p } = useTranslation('workflow_page');

  const transition = useTransition(addBranchAllowed && hovered, {
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleSelectLeft = (tab, name) => {
    onSelectNode(tab, name, 'left');
  };

  const handleSelectRight = (tab, name) => {
    onSelectNode(tab, name, 'right');
  };
  const tooltipPlacement = directionMode === DirectionModes.HORIZONTAL ? 'right' : 'top';

  return (
    <Container
      $dm={directionMode}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {transition((style, active) => active && (
        <Tooltip title={p('add_node_to_new_branch')} placement={tooltipPlacement}>
          <AddBranchContainer data-testid={'workflow-add-node-to-new-branch-btn-top'} style={style} $dm={directionMode}>
            <AddNodeDropdown onSelectNode={handleSelectLeft}>
              {AddBranchIcon}
            </AddNodeDropdown>
            {directionMode === DirectionModes.HORIZONTAL ? LineIconHorizontal : LineIconVertical}
          </AddBranchContainer>
        </Tooltip>
      ))}
      <AddNodeDropdown onSelectNode={onSelectNode}>
        <Tooltip title={p('add_node')} placement={tooltipPlacement}>
          {children}
        </Tooltip>
      </AddNodeDropdown>
      {transition((style, active) => active && (
        <Tooltip title={p('add_node_to_new_branch')} placement={tooltipPlacement}>
          <AddBranchContainer data-testid={'workflow-add-node-to-new-branch-btn-bottom'} style={style} $dm={directionMode}>
            {directionMode === DirectionModes.HORIZONTAL ? LineIconHorizontal : LineIconVertical}
            <AddNodeDropdown onSelectNode={handleSelectRight}>
              {AddBranchIcon}
            </AddNodeDropdown>
          </AddBranchContainer>
        </Tooltip>
      ))}
    </Container>
  );
};

export default AddNodeButton;
