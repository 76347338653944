import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { workflowOptionWithEventsSelector } from '@store/selectors';

import { v4 as uuidv4 } from 'uuid';

import { WorkflowNodes } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { moment } from '@utils';

import {
  modifySendNodes,
  modifyAPiReqesutNode,
  modifyWebPush,
  modifyMobilePush,
  modifyContentCard,
  modifyEmailNodes,
  modifyViberNodes,
} from './helpers';

export const AI_BEST_TIME_TO_SEND = 'ai_best_time_to_send';

export const viewSteps = {
  SHOW_TEMPLATES: 'SHOW_TEMPLATES',
  SHOW_AI_BEST_CHANNEL_TO_SEND: 'SHOW_AI_BEST_CHANNEL_TO_SEND',
  SHOW_EMPTY: 'SHOW_EMPTY',
};

export const keyTemplateconfig = {
  [WorkflowNodes.SEND_EMAIL]: 'email_id',
  [WorkflowNodes.SEND_SMS]: 'sms_id',
  [WorkflowNodes.SEND_CARD]: 'content_card_id',
  [WorkflowNodes.VIBER]: 'viber_id',
  [WorkflowNodes.MOBILE_PUSH]: 'mobile_push_id',
  [WorkflowNodes.WEBPUSH]: 'webpush_id',
};

export const useBestChannelToSend = ({ value, onChange, onClearError }) => {
  const options = useSelector(workflowOptionWithEventsSelector);

  const [currentChannel, setCurrentChannel] = useState(
    WorkflowNodes.SEND_EMAIL
  );

  const [step, setStep] = useState(viewSteps.SHOW_TEMPLATES);

  const templateKey = keyTemplateconfig[currentChannel];
  const defaultChannel = useMemo(() => {
    const defafultchannel = value.channels.find(
      (channel) => channel.default
    );
    return defafultchannel?.type;
  }, [value.channels]);

  const selectedChannels = value.channels;
  const currentChannelData = selectedChannels.find(
    ({ type }) => type === currentChannel
  );

  const getTemplateKey = (channelType) => {
    return keyTemplateconfig[channelType];
  };

  const handleSelectChannel = (type) => {
    if (type === AI_BEST_TIME_TO_SEND) {
      setStep(viewSteps.SHOW_AI_BEST_CHANNEL_TO_SEND);
    } else {
      setStep(viewSteps.SHOW_TEMPLATES);
    }

    setCurrentChannel(type);
  };

  const handleAddChannel = (channel) => {

    const id = uuidv4();

    const additionalData = channel.type === WorkflowNodes.SEND_CARD
      ? {
        expiration: {
          type: 'duration',
        },
      }
      : {};

    const dataChannel = {
      type: channel.type,
      id: id,
      label: channel.label,
      data: {
        name: channel.type,
        label: channel.label,
        id: id,
        ...additionalData
      },
    };

    const updatedChannels = [...selectedChannels, dataChannel];

    onChange((prev) => {
      return {
        ...prev,
        channels: updatedChannels,
      };
    });

    handleSelectChannel(channel.type);
    onClearError();
  };

  const handleRemoveChannel = (channelToRemove) => {
    const newChannels = selectedChannels.filter(
      (channel) => channel.type !== channelToRemove.type
    );

    onChange((prev) => {
      return {
        ...prev,
        channels: newChannels,
      };
    });

    if (channelToRemove.type === currentChannel && newChannels.length > 0) {
      setCurrentChannel(newChannels[0].type);
    }

    if (
      newChannels.length === 0 &&
      step !== viewSteps.SHOW_AI_BEST_CHANNEL_TO_SEND
    ) {
      setStep(viewSteps.SHOW_EMPTY);
    }
  };

  const handleChangegDefaultchannel = (channelType) => {
    const updatedChannels = selectedChannels.map((channel) => {
      if (channel.type === channelType) {
        return {
          ...channel,
          default: true,
        };
      }

      return {
        ...channel,
        default: false,
      };
    });

    onChange((prev) => {
      return {
        ...prev,
        channels: updatedChannels,
      };
    });
    onClearError('default_channel');
  };

  const handleChangeTemplate = (templateId) => {
    const updatedChannels = selectedChannels.map((channel, index) => {
      if (channel.type === currentChannel) {
        onClearError(templateKey, index);
        return {
          ...channel,
          data: {
            ...channel.data,
            [templateKey]: templateId,
          }
        };
      }
      return channel;
    });

    onChange((prev) => {
      return {
        ...prev,
        channels: updatedChannels,
      };
    });
  };

  const handleNodeValueChange = (updater) => {
    const result = updater(currentChannelData?.data);

    const updatedChannels = selectedChannels.map((channel) => {
      if (channel.type === result.name) {
        return {
          ...channel,
          data: result
        };
      }
      return channel;
    });
    onClearError('expiration_duration')
    onChange((prev) => {
      return {
        ...prev,
        channels: updatedChannels,
      };
    });
  };

  const handleChangeAiSendSettings = (type) => {
    const time = type === 'specific_time' ? moment().add('1', 'hour').startOf('hour').toISOString() : null;
    onChange((prev) => {
      return {
        ...prev,
        send_settigs_type: type,
        best_time_fallback: time,
      };
    });
  };

  const handleChangeAiSendSettingsTime = (time) => {
    onClearError('send_settigs_type')

    onChange((prev) => {
      return {
        ...prev,
        best_time_fallback: time ? time.toISOString() : null,
      };
    });
  };

  const handleChangeEnabledIdBestTime = (enabled) => {
    enabled && handleSelectChannel(AI_BEST_TIME_TO_SEND);
    onChange((prev) => {
      return {
        ...prev,
        best_time_fallback: null,
        best_time_enabled: enabled,
      };
    });
  };

  const getSelectedChannelIndex = () => {
    const index = selectedChannels.findIndex((channel) => channel.type === currentChannel);
    return index;
  };

  const configOptions = {
    [WorkflowNodes.SEND_EMAIL]: options?.[TemplateTypes.EMAIL],
    [WorkflowNodes.VIBER]: options?.[TemplateTypes.VIBER],
    [WorkflowNodes.SEND_SMS]: options?.[TemplateTypes.SMS],
    [WorkflowNodes.MOBILE_PUSH]: options?.[TemplateTypes.MOBILE_PUSH],
    [WorkflowNodes.API_REQUEST]: options?.[TemplateTypes.WEBHOOK],
    [WorkflowNodes.WEBPUSH]: options?.[TemplateTypes.WEB_PUSH],
    [WorkflowNodes.SEND_CARD]: options?.[TemplateTypes.MESSAGE_FEED],
  };

  const currentTypeOption = configOptions[currentChannel] || [];

  const optionModifyConfig = {
    [WorkflowNodes.SEND_EMAIL]: modifyEmailNodes,
    [WorkflowNodes.SEND_SMS]: modifySendNodes,
    [WorkflowNodes.VIBER]: modifyViberNodes,
    [WorkflowNodes.MOBILE_PUSH]: modifyMobilePush,
    [WorkflowNodes.API_REQUEST]: modifyAPiReqesutNode,
    [WorkflowNodes.WEBPUSH]: modifyWebPush,
    [WorkflowNodes.SEND_CARD]: modifyContentCard,
  };

  const modifyOptionMethod = optionModifyConfig[currentChannel];

  const currentModifeiedOptions =
    currentTypeOption?.length > 0
      ? currentTypeOption?.filter((item) => item?.status !== 3).map(modifyOptionMethod)
      : [];

  return {
    step,
    defaultChannel,
    currentChannel,
    selectedChannels,
    options: currentModifeiedOptions,
    currentChannelData: currentChannelData?.data,
    enabledAiBestTime: !!value.best_time_enabled,
    templateKey: templateKey,
    handleSelectChannel,
    handleAddChannel,
    handleRemoveChannel,
    handleChangegDefaultchannel,
    handleChangeTemplate,
    handleNodeValueChange,
    handleChangeEnabledIdBestTime,
    handleChangeAiSendSettings,
    handleChangeAiSendSettingsTime,
    getTemplateKey,
    getSelectedChannelIndex
  };
};
