import { useRef, useState } from 'react';

import { Dropdown, Tooltip } from 'antd';

import { useOutsideClickListener, useTranslation } from '@hooks';

import { UserPermissions } from '@constants';

import { by, moment, testId } from '@utils';

import { WithPermissions, Button } from '@components';
import {
  FooterOptionLabel
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/SendEmailNodeSettings/styled';

import { Option, Overlay, Col, Label, Updated, Status, FooterOption } from './styled';

const VersionSelect = ({
  options,
  value,
  onChange,
  larger,
  allowCreate,
  hideStatus,
  useDateField = 'updated_at',
  onCreate,
  style,
  buttonTestId = '',
}) => {
  const { p, t } = useTranslation('workflow_page')
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useOutsideClickListener(ref, () => {
    setVisible(false);
  });

  const handleChange = (v) => {
    setVisible(false);
    onChange(v);
  };

  const handleCreate = (e) => {
    setVisible(false);
    onCreate(e);
  }

  const resolveStatus = (s) => {
    return t(`statuses.${[
      'draft',
      'active',
      'stopped',
      'finished',
      'on_pause',
    ][+s]}`);
  };

  const disabled = !options.length;
  return (
    <Dropdown
      trigger={['click']}
      disabled={disabled}
      onOpenChange={setVisible}
      open={visible}
      getPopupContainer={t => t.parentElement}
      overlay={(
        <Overlay style={{ borderRadius: '10px' }}>
          {options.map(({ value: curr, label, status }, i) => (
            <Option {...testId('workflow-versions-item')()} key={label} onClick={() => handleChange(curr)}>
              <Col>
                <Label>
                  {label}
                </Label>
                {!hideStatus && (
                  <Status $status={+status}>
                    {resolveStatus(status)}
                  </Status>
                )}
              </Col>
              <Updated>
                {moment(options[i][useDateField]).fromNow(true)}
              </Updated>
            </Option>
          ))}
          {allowCreate && (
            <WithPermissions name={UserPermissions.UPDATE_WORKFLOWS}>
              <FooterOption data-testid={'workflow-versions-add-new'} onClick={handleCreate}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M10 2.5C14.125 2.5 17.5 5.875 17.5 10C17.5 14.125 14.125 17.5 10 17.5C5.875 17.5 2.5 14.125 2.5 10C2.5 5.875 5.875 2.5 10 2.5ZM10 1.25C5.1875 1.25 1.25 5.1875 1.25 10C1.25 14.8125 5.1875 18.75 10 18.75C14.8125 18.75 18.75 14.8125 18.75 10C18.75 5.1875 14.8125 1.25 10 1.25Z"
                    fill="#0C1015"/>
                  <path d="M15 9.375H10.625V5H9.375V9.375H5V10.625H9.375V15H10.625V10.625H15V9.375Z" fill="#0C1015"/>
                </svg>
                <FooterOptionLabel>
                  {p('add_new_version')}
                </FooterOptionLabel>
              </FooterOption>
            </WithPermissions>
          )}
        </Overlay>
      )}
    >
      <div style={{ opacity: disabled ? .6 : 1 }}>
        <Tooltip title={disabled ? p('no_versions_found') : ''}>
          <Button
            ref={ref}
            data-testid={buttonTestId}
            appearance={'outlined'}
            style={{
              width: 'auto',
              paddingLeft: '16px',
              height: larger ? '36px' : '26px',
              minWidth: larger ? undefined : '120px',
              justifyContent: larger ? undefined : 'space-between',
              fontSize: '12px',
              ...style
            }}
          >
            <div style={{ marginRight: '10px', fontSize: 14 }}>
              {disabled ? p('no_versions'): options.find(by('value', value))?.label}
            </div>
          </Button>
        </Tooltip>
      </div>
    </Dropdown>
  );
};

export default VersionSelect;
