import { useTranslation } from '@hooks';

import { Container } from './styled';

const QuietHoursDisclaimer = () => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container data-testid={'workflow-quiet-hours-disclaimer'}>
      <svg style={{ marginRight: 18 }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <g clipPath="url(#clip0_18387_7403)">
          <path fillRule="evenodd" clipRule="evenodd" d="M8.4836 1.51549C8.34938 1.23598 8.13892 1.00008 7.87647 0.834963C7.61402 0.669849 7.31026 0.582245 7.00019 0.582245C6.69012 0.582245 6.38636 0.669849 6.12391 0.834963C5.86145 1.00008 5.65099 1.23598 5.51677 1.51549L0.782438 10.8284C0.216021 11.9402 0.931771 13.4167 2.26527 13.4167H11.7345C13.0686 13.4167 13.7832 11.9408 13.2179 10.8284L8.4836 1.51549ZM7.00019 4.66666C7.1549 4.66666 7.30327 4.72812 7.41267 4.83752C7.52206 4.94691 7.58352 5.09528 7.58352 5.24999V8.16666C7.58352 8.32137 7.52206 8.46974 7.41267 8.57914C7.30327 8.68854 7.1549 8.74999 7.00019 8.74999C6.84548 8.74999 6.6971 8.68854 6.58771 8.57914C6.47831 8.46974 6.41685 8.32137 6.41685 8.16666V5.24999C6.41685 5.09528 6.47831 4.94691 6.58771 4.83752C6.6971 4.72812 6.84548 4.66666 7.00019 4.66666ZM7.00019 9.62499C7.1549 9.62499 7.30327 9.68645 7.41267 9.79585C7.52206 9.90524 7.58352 10.0536 7.58352 10.2083V10.5C7.58352 10.6547 7.52206 10.8031 7.41267 10.9125C7.30327 11.0219 7.1549 11.0833 7.00019 11.0833C6.84548 11.0833 6.6971 11.0219 6.58771 10.9125C6.47831 10.8031 6.41685 10.6547 6.41685 10.5V10.2083C6.41685 10.0536 6.47831 9.90524 6.58771 9.79585C6.6971 9.68645 6.84548 9.62499 7.00019 9.62499Z" fill="#FFBB00"/>
        </g>
        <defs>
          <clipPath id="clip0_18387_7403">
            <rect width="14" height="14" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      {p('on_qh_disclaimer')}
    </Container>
  );
};

export default QuietHoursDisclaimer;
