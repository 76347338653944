const FieldsActionTypes = {
  GET_AUTOCOMPLETE: 'fields/GET_AUTOCOMPLETE',
  GET_AUTOCOMPLETE_SUCCESS: 'fields/GET_AUTOCOMPLETE_SUCCESS',
  GET_AUTOCOMPLETE_ERROR: 'fields/GET_AUTOCOMPLETE_ERROR',

  GET_DYNAMIC_VARIABLES: 'fields/GET_DYNAMIC_VARIABLES',
  GET_DYNAMIC_VARIABLES_SUCCESS: 'fields/GET_DYNAMIC_VARIABLES_SUCCESS',
  GET_DYNAMIC_VARIABLES_ERROR: 'fields/GET_DYNAMIC_VARIABLES_ERROR',

  CREATE_DYNAMIC_VARIABLE: 'fields/CREATE_DYNAMIC_VARIABLE',
  CREATE_DYNAMIC_VARIABLE_SUCCESS: 'fields/CREATE_DYNAMIC_VARIABLE_SUCCESS',
  CREATE_DYNAMIC_VARIABLE_ERROR: 'fields/CREATE_DYNAMIC_VARIABLE_ERROR',

  UPDATE_DYNAMIC_VARIABLE: 'fields/UPDATE_DYNAMIC_VARIABLE',
  UPDATE_DYNAMIC_VARIABLE_SUCCESS: 'fields/UPDATE_DYNAMIC_VARIABLE_SUCCESS',
  UPDATE_DYNAMIC_VARIABLE_ERROR: 'fields/UPDATE_DYNAMIC_VARIABLE_ERROR',

  DELETE_DYNAMIC_VARIABLE: 'fields/DELETE_DYNAMIC_VARIABLE',
  DELETE_DYNAMIC_VARIABLE_SUCCESS: 'fields/DELETE_DYNAMIC_VARIABLE_SUCCESS',
  DELETE_DYNAMIC_VARIABLE_ERROR: 'fields/DELETE_DYNAMIC_VARIABLE_ERROR',

  GET_CLICKHOUSE_FIELDS: 'fields/GET_CLICKHOUSE',
  GET_CLICKHOUSE_FIELDS_SUCCESS: 'fields/GET_CLICKHOUSE_SUCCESS',
  GET_CLICKHOUSE_FIELDS_ERROR: 'fields/GET_CLICKHOUSE_ERROR',
};

export default FieldsActionTypes;
