import styled from 'styled-components';

export const MobileContainer = styled.div`
    width: 260px;
    height: 520px;
    position: relative;
`;

export const LaptopContainer = styled.div`
    width: 100%;
    height: 362px;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Container = styled.div`
    width: 320px;
    height: 100%;
    min-height: 320px;
    position: relative;
    border-radius: 6.194px;
    border: 1px solid var(--cards-border, #D3DBE7);
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    
`;

export const ModalContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    min-height: 218px;
`

