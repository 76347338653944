import styled from 'styled-components';

import { styledProp } from '@utils';

import { Device } from '@res';

import * as Colors from '@components';

export const Container = styled.div`
  background-color: #F0F2F6;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;

  @media screen and ${Device.desktopS} {
    padding: 20px 260px;
  }
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:first-child {
    margin-right: 6px;
  }
`;

export const FiltersCol = styled.div`
  display: flex;
  padding: 16px 21px 0 21px;
  flex-direction: column;
`;

export const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SearchFilter = styled.div`
  width: 345px;
`;

export const DateInputsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DateFilter = styled.div`
  width: 162px;
`;

export const FilterActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Example = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  overflow: hidden;
`;

export const MenuItem = styled.div`
  font-size: 13px;
  color: ${({ danger }) => (danger ? Colors.Text.DANGER : Colors.Text.PRIMARY)};
  margin: 0;

  ${styledProp('disabled', `
    opacity: .5;
    cursor: not-allowed;
    // pointer-events: none;
  `)}
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
    border-radius: 7px;
  `)}
`;
