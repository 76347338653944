export const DEFAULT_COLS = [
  'id',
  'email',
  'phone',
];

export const COLS_SELECTION_OPTIONS = [
  {
    value: 'id',
    title: 'ID',
    locked: true,
    active: true,
    sort_order: -1,
    type: 'text',
  },
  {
    value: 'email',
    title: 'Email',
    active: true,
    locked: false,
    type: 'text',
  },
  {
    value: 'phone',
    title: 'Phone number',
    active: true,
    locked: false,
    type: 'text',
  },
  // {
  //   value: 'event',
  //   title: 'Event',
  //   locked: true,
  // },
];
