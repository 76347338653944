import { useTranslation } from '@hooks';
import useWorkflowOptionsPreview from '@hooks/useWorkflowOptionsPreview';

import { TemplateTypes } from '@constants/templates';

import { moment } from '@utils';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import {
  AiSection, AiSubtitle,
  AiTitle, Sending, SubText
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendEmailNodeSettingsInfo/styled';

import { Container } from './styled.js';

const SendCardNodeSettingsInfo = ({ node }) => {

  const dateTime = {
    year: moment(node.data.expiration?.date)?.year?.(),
    month: moment(node.data.expiration?.date)?.month?.() + 1,
    day: moment(node.data.expiration?.date)?.date?.(),
    hour: moment(node.data.expiration?.date)?.hour?.(),
    minute: moment(node.data.expiration?.date)?.minute?.()
  };

  const card = useWorkflowOptionsPreview(TemplateTypes.MESSAGE_FEED, node.data?.content_card_id);
  const { p, t } = useTranslation();

  if (!card) {
    return (
      <Container>
        <InfoTitle>{t('labels.template')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  return (
    <Container>
      <InfoTitle>
        {t('labels.template')}:
      </InfoTitle>
      <InfoText>
        {card.name}
      </InfoText>
      {node.data.expiration && (
        <>
          <InfoTitle style={{ marginTop: '16px' }}>
            {t('labels.expiration')}:
          </InfoTitle>
          <InfoText>
            {node.data.expiration?.type === 'duration' ? (
              <>
                {`After ${node.data?.expiration?.duration} days`}
              </>
            ) : (
              <>
                {` ${dateTime.day}.0${dateTime.month}.${dateTime.year} at ${dateTime.hour}: ${dateTime.minute === 0 ? dateTime.minute + '0' : dateTime.minute}`}
              </>
            )
            }
          </InfoText>
        </>
      )}
      {/*{(node.data.sending && smsNodeAnalytics?.loading === false) && <SendingSection statuses={node.data.sending} out={node.data.analytics?.out} />}*/}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
      {node.data.analytics && (
        <AnalyticsSection analytics={node.data.analytics} />
      )}
      <Sending>
        <InfoTitle>{t('labels.sending')}:</InfoTitle>
        {!node.data?.best_time_enabled ? (
          <InfoText>{p('workflow_page.upon_node_entrance')}</InfoText>
        ) : (
          <AiSection>
            <AiTitle>
              {p('workflow_page.ai_best_time')}
            </AiTitle>
            <AiSubtitle>
              {p('workflow_page.fallback_option')}
            </AiSubtitle>
            <SubText>{node.data?.best_time_fallback ? `${p('workflow_page.at')} ${moment(node.data.best_time_fallback)?.hour?.()}:${moment(node.data.best_time_fallback)?.minute?.() === 0 ? moment(node.data.best_time_fallback)?.minute?.() + '0' : moment(node.data.best_time_fallback)?.minute?.()}` : p('workflow_page.upon_node_entrance')}</SubText>
          </AiSection>
        )}
      </Sending>
    </Container>
  );
}

export default SendCardNodeSettingsInfo;

