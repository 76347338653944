import * as Sentry from '@sentry/react';

const identifyUser = async (userId) => {

  if (userId) {
    Sentry.setUser({ id: userId });
  }
};

const log = async (level, label, _message, ctx) => {

  Sentry.withScope((scope) => {
    scope.setLevel(level);
    scope.setTag('label', label);
    if (ctx) {
      scope.setContext('ctx', ctx);
    }

    Sentry.captureException(_message);
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  identifyUser,
  log,
};
