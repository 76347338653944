import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  margin-bottom: 2px;
`;

export const DeleteButtonContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  ${styledProp('$disabled', `
    opacity: 40%;
    cursor: not-allowed !important;
  `)}
`;

export const EventName = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-family: Manrope-SemiBold;
  font-size: 21px;
  line-height: 26px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EventPanel = styled.div`
  border-radius: 10px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Neutral-100, #F8FAFE);
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.30);
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-divider, #D3DBE7);
  padding: 20px;
`;

export const FiltersContainer = styled.div`
  position: relative;
  padding: 10px 20px;
`;
