export const WEB_POPUPS_TYPES = {
  MODAL: 'modal',
  FULL_SCREEN: 'full-screen',
  CODE: 'code',
};

export const WEB_POPUPS_LAYOUT = {
  IMAGE_ONLY: 'image',
  TEXT: 'text',
  TEXT_WITH_OPTIONAL_IMAGE: 'image-text',
};

export const WEB_POPUPS_ORIENTATION = {
  LANDSCAPE: 'landscape',
  PORTRAIT: "portrait"
}