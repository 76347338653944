import { useSelector } from 'react-redux';

import { templateSelector } from '@store/selectors';

import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Paths } from '@constants';
import { TemplateTypes } from '@constants/templates';

import { Modal } from '@components';

import { Container, InfoContainer, InfoText, InfoTextTitle } from './styled';

import { UserPermissions } from '../../../../../../constants';
import { InfoRow } from '../InfoRow';

const createEmailDetails = (data) => {
  const { entity_id, customer_id, email, created_at, updated_at, status, group, template_id, external_id, integration, response, meta, email_name } = data;
  return [
    { attribute: 'labels.email_id', value: template_id },
    { attribute: 'labels.customer_id', value: customer_id, shouldLink: true, link: `${Paths.CONTACT}/${customer_id}`, permission: UserPermissions.REVIEW_CONTACTS },
    { attribute: 'labels.email', value: email },
    { attribute: 'labels.template', value: email_name, link: `${Paths.TEMPLATES_EMAIL}/code/${template_id}`, permission: UserPermissions.REVIEW_EMAILS },
    { attribute: 'labels.send_date', value: created_at, date: true },
    { attribute: 'labels.updated_at', value: updated_at, date: true },
    { attribute: 'labels.status', value: status, status: true },
    { attribute: 'labels.group', value: group },
    { attribute: 'labels.entity_id', value: entity_id, link: true },
    { attribute: 'labels.external_id', value: external_id, link: true },
    { attribute: 'labels.integration', value: integration },
    { attribute: 'labels.response', value: response },
    { attribute: 'labels.meta', value: meta },
  ]
};

const EmailInfoModal = ({ opened, onClose, data }) => {
  const modalInfo = createEmailDetails(data);
  const templates = useSelector(templateSelector);
  const { t } = useTranslation('notification_history')

  return (
    <Modal
      opened={opened}
      contentStyles={{ maxHeight: '60vh' }}
      onClose={onClose}
      bigger
      title={`E-mail ${templates?.[TemplateTypes.EMAIL]?.find(e => e.id === data.template_id)?.name}`}
      centerFooter={true}
      actions={(
        <>
          <Button onClick={onClose} variant="secondary" style={{ width: '160px' }} >{t('actions.close')}</Button>
        </>
      )}
    >
      <Container>
        <InfoContainer>
          <InfoTextTitle first={true}>{t('labels.attribute')}</InfoTextTitle>
          <InfoText first={true}>{t('labels.value')}</InfoText>
        </InfoContainer>
        { modalInfo.map((props) => <InfoRow {...props} key={props.attribute} />)}
      </Container>
    </Modal>
  );
};

export default EmailInfoModal;
