import { useTranslation } from '@hooks';

import {
  AnalyticsSection
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const translationMap = {
  'add': 'workflow_page.add_to_audience',
  'remove': 'workflow_page.remove_from_audience',
  'remove_from_all': 'workflow_page.remove_from_all_audiences',
};

const RetargetingNodeSettingsInfo = ({ node, options }) => {
  const { t, p } = useTranslation('workflow_page');

  if (!node.data?.integration_id && !node.data?.audience_name && !node.data?.action) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <InfoRow>
        <InfoTitle>
          {t('labels.action')}:
        </InfoTitle>
        <InfoText>
          {t(translationMap[node.data?.action])}
        </InfoText>
      </InfoRow>
      <InfoRow>
        <InfoTitle>
          {p('audience')}:
        </InfoTitle>
        <InfoText>
          {node.data?.audience_name}
        </InfoText>
      </InfoRow>
      <InfoRow>
        <InfoTitle>
          {t('labels.integration')}:
        </InfoTitle>
        <InfoText>
          {options?.facebookIntegrations?.find(i => i.id === node.data?.integration_id)?.name}
        </InfoText>
      </InfoRow>
      {/*{node.data.something.map(({ field, value }, index) => (*/}
      {/*  <React.Fragment key={index}>*/}
      {/*    <InfoRow>*/}
      {/*      <InfoTitle>*/}
      {/*        {t('labels.field')}:*/}
      {/*      </InfoTitle>*/}
      {/*      <InfoText>*/}
      {/*        {field}*/}
      {/*      </InfoText>*/}
      {/*    </InfoRow>*/}
      {/*    <InfoRow padded>*/}
      {/*      <InfoTitle>*/}
      {/*        {t('labels.value')}:*/}
      {/*      </InfoTitle>*/}
      {/*      <InfoText>*/}
      {/*        {value}*/}
      {/*      </InfoText>*/}
      {/*    </InfoRow>*/}
      {/*  </React.Fragment>*/}
      {/*))}*/}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
};

export default RetargetingNodeSettingsInfo;
