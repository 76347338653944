import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Text } from '../Text';

export const Container = styled(Text)`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  color: #31C447;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ $underline }) => $underline ? `
    text-decoration: underline;
  ` : ''}

  ${({ $semibold }) => $semibold? `
    font-family: Manrope-Semibold;
  ` : ''}

  &:hover {
    color: #65c073; /* General hover color outside of the specific context */
    /* You can also add other general hover styles here */
  }

  ${({ $type }) => $type === 'secondary' ? `
    color: var(--text-primary-body, #1A232E);
    font-family: Manrope-Medium;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-decoration-line: underline;
    &:hover {
      color: #31C447;
      text-decoration-line: underline;
    }
  ` : ''}

  /* Specific hover effect for links inside .ant-table-cell td > div > div */
  .ant-table-cell > div > div &:hover {
    color: #31C447; /* Specific hover color for the given context */
    /* You can also add other specific hover styles here */
  }
  .ant-table-cell > div > div > a{
    color: black;
  }
`;
