import React, { useEffect, useRef } from 'react';

import { Container } from './styled';

const InfiniteList = ({ children, onScrollToEnd, updater, ...props }) => {
  const ref = useRef(null);
  const prevScrollHeight = useRef(0);
  const prevScrollTop = useRef(0);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollTop + clientHeight >= scrollHeight) {
      if (ref.current) {
        prevScrollHeight.current = ref.current.scrollHeight;
        prevScrollTop.current = ref.current.scrollTop;
      }

      onScrollToEnd?.();
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = prevScrollTop.current;
    }
  }, [updater, ref.current]);

  return (
    <Container onScroll={handleScroll} ref={ref} {...props}>
      {children}
    </Container>
  );
}

export default InfiniteList;
