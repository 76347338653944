import { createRequestAction } from "@store/lib/utils/creators";

import { all, takeLatest, throttle, put } from 'redux-saga/effects';

import { Endpoints } from "@constants";

import { SegmentsActionTypes } from '../../actions/types';

function* createAggregateSaga(action) {
  const body = {
    label: action.payload.label,
    field: action.payload.field,
    filters: action.payload.filters,
    type: action.payload.type,
    name: action.payload.name,
    aggregateMeta: action.payload.aggregateMeta || {},
    attribute: action.payload.attribute,
    aggregate: action.payload.aggregate,
    segmentId: action.payload.segmentId,
    createdId: action.payload.createdId,
    logicalOperator: action.payload.logicalOperator,
  };

  if (action.payload.nestedField) {
    body.nested_field = action.payload.nestedField;
  }

  if (action.payload.id) {
    body.id = action.payload.id;
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_AGGREGATE,
      body,
    })
  );
}

function* deleteAggregateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `${Endpoints.DELETE_AGGREGATE}/${action.payload.id}`,
    })
  );
}

function* deleteSegmentSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `/api/seg/segment/${action.payload.id}`,
      body: { id: action.payload.id },
    })
  );
}

function* getSegmentCustomerDownloadHandler(action) {
  console.log(action);
  yield all([]);
}

function* exportSegmentCustomersSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/seg/customer/segment/export',
      body: {
        query: action.payload.query,
        aggregates: action.payload.aggregates,
        funnels: action.payload.funnels,
        fields: action.payload.fields,
        segment_id: action.payload.id,
      }
    })
  );
}

function* getAggregatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.GET_AGGREGATES,
      params: action.payload,
    })
  );
}

function* getContactsExampleSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.SEGMENTS_CONTACTS_EXAMPLE(action.payload.type),
      body: action.payload,
    })
  );
}

function* getEditMetadataSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/seg/segment/${action.meta.id}`,
    })
  );
}

function* getFilterResultsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.SEGMENTS_FILTER_RESULTS,
      body: action.payload,
    })
  );
}

function* getSegmentsOptionsSaga(action) {
  yield all([
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'app' } }, {
        method: 'get',
        endpoint: '/api/apps/options',
        disableTimeout: true,
      })
    ),
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'workflow' } }, {
        method: 'get',
        endpoint: '/api/cus/settings/fields/clickhouse/list',
        disableTimeout: true,
      })
    ),
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'segment' } }, {
        method: 'get',
        endpoint: '/api/seg/analytics/options',
        disableTimeout: true,
      })
    ),
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'aggregate' } }, {
        method: 'get',
        endpoint: '/api/seg/settings/aggregate/list',
        disableTimeout: true,
      })
    ),
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'events' } }, {
        method: 'get',
        endpoint: Endpoints.EVENTS,
        disableTimeout: true,
      })
    ),
  ]);
}

function* getSingleAggregateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `${Endpoints.EDIT_AGGREGATE}/${action.payload.id}`,
    })
  );
}

function* listSegmentsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: '/api/seg/segment',
    })
  );
}

function* publishSegmentSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.PUBLISH_SEGMENT,
      body: { id: action.payload.id },
    })
  );
}

function* editSegmentSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'put',
      endpoint: `/api/seg/segment/${action.meta?.id || action.payload?.id}`,
      body: {
        id: action.meta?.id || action.payload?.id,
        segment_id: action.payload.segment_id,
        query: action.payload.query,
        name: action.payload.name,
        type: action.payload.type,
        description: action.payload.description,
        static_query: action.payload.static_query,
        aggregates: action.payload.aggregates,
        funnels: action.payload.funnels,
      },
    })
  );
}

function* saveSegmentSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/seg/segment',
      body: {
        id: action.meta?.id || action.payload?.id,
        segment_id: action.payload.segment_id,
        query: action.payload.query,
        name: action.payload.name,
        type: action.payload.type,
        static_query: action.payload.static_query,
        description: action.payload.description,
        aggregates: action.payload.aggregates,
        funnels: action.payload.funnels,
      },
    })
  );
}

function* unPublishSegmentSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.UN_PUBLISH_SEGMENT,
      body: { id: action.payload.id }
    })
  );
}

function* listNestedAggregatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: '/api/seg/settings/aggregate/nested/list',
    })
  );
}

function* uploadSegmentFileSaga(action) {
  const fdata = new FormData();

  fdata.append('file', action.payload.file);
  fdata.append('segmentId', action.payload.id);
  fdata.append('fields', JSON.stringify(action.payload.fields));

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/seg/segment/static/import-csv/examples',
      body: fdata,
      onUploadProgress: action.payload.onProgress,
    })
  );
}

function* cancelSegmentFileUploadSaga() {
  //
}

function* saveCSVSegmentSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/seg/segment/static/import-csv',
      body: action.payload,
    }),
  );
}

export default function* segmentsRootSaga() {
  yield all([
    takeLatest(SegmentsActionTypes.GET, listSegmentsSaga),
    takeLatest(SegmentsActionTypes.PUBLISH, publishSegmentSaga),
    takeLatest(SegmentsActionTypes.UN_PUBLISH, unPublishSegmentSaga),
    takeLatest(SegmentsActionTypes.DELETE, deleteSegmentSaga),
    takeLatest(SegmentsActionTypes.GET_OPTIONS, getSegmentsOptionsSaga),
    takeLatest(SegmentsActionTypes.GET_EDIT_METADATA, getEditMetadataSaga),
    takeLatest(SegmentsActionTypes.SAVE, saveSegmentSaga),
    takeLatest(SegmentsActionTypes.EDIT, editSegmentSaga),
    takeLatest(SegmentsActionTypes.GET_AGGREGATE, getAggregatesSaga),
    takeLatest(SegmentsActionTypes.GET_SINGLE_AGGREGATE, getSingleAggregateSaga),
    throttle(1000, SegmentsActionTypes.GET_FILTER_RESULTS, getFilterResultsSaga),
    takeLatest(SegmentsActionTypes.CREATE_AGGREGATE, createAggregateSaga),
    takeLatest(SegmentsActionTypes.DELETE_AGGREGATE, deleteAggregateSaga),
    takeLatest(SegmentsActionTypes.GET_CONTACTS_EXAMPLE, getContactsExampleSaga),
    takeLatest(SegmentsActionTypes.EXPORT_CUSTOMERS, exportSegmentCustomersSaga),
    takeLatest(SegmentsActionTypes.EXPORT_CUSTOMERS_SUCCESS, getSegmentCustomerDownloadHandler),
    takeLatest(SegmentsActionTypes.LIST_NESTED_AGGREGATES, listNestedAggregatesSaga),
    takeLatest(SegmentsActionTypes.UPLOAD_FILE, uploadSegmentFileSaga),
    takeLatest(SegmentsActionTypes.UPLOAD_FILE_CANCEL, cancelSegmentFileUploadSaga),
    takeLatest(SegmentsActionTypes.SAVE_CSV_SEGMENT, saveCSVSegmentSaga),
  ]);
}
