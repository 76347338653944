const ContentCardStatuses = {
  SENT: 'sent',
  OPENED: 'open',
  DISMISS: 'dismiss',
  CLICK: 'click',
  FAILED: 'failed',
};

const ContentCardStatusLabels = {
  [ContentCardStatuses.SENT]: 'statuses.sent',
  [ContentCardStatuses.OPENED]: 'statuses.opened',
  [ContentCardStatuses.CLICK]: 'statuses.click',
  [ContentCardStatuses.DISMISS]: 'statuses.dismiss',
  [ContentCardStatuses.FAILED]: 'statuses.failed',
};

const ContentCardStatusOptions = [
  ...Object.entries(ContentCardStatusLabels).map(([value, label]) => ({ value, label }))
];

export { ContentCardStatuses, ContentCardStatusOptions, ContentCardStatusLabels };
