import { useTranslation } from '@hooks';

import { IconSvg } from '@components';

import { Container, Text } from './styled';

export const ExcludeStep = () => {
  const { p } = useTranslation('workflow_page');
  return (
    <Container>
      <IconSvg name='group-settings' size='116' />
      <Text>
        {p('exclude_step')}
      </Text>
    </Container>
  );
};
