import { useState } from 'react';

import { Button } from '@velitech/ui';

import { useTranslation } from "@hooks";

import { Modal, Select } from '@components';

import { ActionsRow, Container, ContryContainer, CountryIcon, CountryName, Listcontainer, SelectedValue, Title } from './styled';

import { SearchInput } from '../SearchInput';
import { LanguageItem } from '../SelectLanguage/LnaguageItem';

export const DefaultLangModal = ({ title, opened, options, onClose, onSave }) => {
  const { t } = useTranslation('templates');
  const [search, setSearch] = useState('');
  const [value, setValue] = useState(null);

  const filterExtractor = children => children?.props?.children?.[1] || "";

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value)
  };

  const selected = value && options.find((option) => option.value === value);
  const languages = options.filter(({ label }) => search ? label.toLowerCase().includes(search.toLowerCase()) : true);

  return (
    <Modal
      appearance="default"
      title={t('templates.default_language')}
      opened={opened}
      onClose={onClose}
      actions={
        <ActionsRow>
          <Button onClick={onClose} variant="secondary" style={{ width: '140px' }}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={() => onSave(value)} style={{ width: '140px' }}>
            {t('actions.save')}
          </Button>
        </ActionsRow>
      }
    >
      <Container>
        <Title>{title}</Title>
        <Select
          style={{ width: '100%', background: '#fff' }}
          label={t('templates.default_language')}
          showArrow
          customValue={value && (
            <SelectedValue> 
              <CountryIcon>{selected?.icon}</CountryIcon>
              <CountryName>{selected?.label}</CountryName>
            </SelectedValue>
          )}
          customOverlay={({ setOpened }) => (
            <Listcontainer>
              <SearchInput
                value={search}
                onClear={() => setSearch('')}
                onChange={handleSearchChange}
              />
              <ContryContainer>
                {languages.map(({ icon, value, label }) => (
                  <LanguageItem onClick={() => {
                    setValue(value);
                    setOpened(false);
                  }} 
                  key={value} 
                  icon={icon} 
                  name={label} 
                  isDefault={false}/>
                ))}
              </ContryContainer>
            </Listcontainer>
          )}
          value={value}
          filterExtractor={filterExtractor}
          onChange={(data) => setValue(data)}
        />
      </Container>
    </Modal>
  )
}