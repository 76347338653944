import { Colors } from '@res/theme';

import { StyledButton } from './styled';

const Button = ({ testId, appearance = 'default', width = 258, ...props }) => {
  return (
    <StyledButton
      data-testid={testId}
      {...props}
      appearance={appearance}
      style={{
        background: Colors.Common.BUTTON_DEFAULT,
        width: `${width}px`,
        ...(props.style || {})
      }}
      type={'primary'}
    />
  );
};

export default Button;
