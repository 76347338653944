import RadioGroupContext from './RadioGroupContext';

const RadioGroup = ({ value, disabled = false, onChange, children }) => {
  return (
    <RadioGroupContext.Provider value={{ value, disabled, onChange }}>
      {children}
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
