import { useEffect, useMemo, useState } from 'react';

import later from 'later';

import { useTranslation } from '@hooks';

import { moment } from '@utils';

import { Container, NextButton, RangeContainer, PrevButton } from './styled';

later.date.UTC();

const earliest = new Date(moment('0001-01-01').unix() * 1000);
const latest = new Date(moment('2030-01-01').unix() * 1000);
const now = new Date().getTime();

const resolveDate = (m, fallback) => {
  return m ? new Date(moment(m).unix() * 1000) : fallback;
}

const filterSchedule = (s) => Object.fromEntries(
  Object.entries(s)
    .map(([key, val]) => [key, val?.sort?.((a, b) => a - b)])
    .filter(([_, v]) => !!v && !!v?.length)
);

const formatDate = (date) => {
  return date.format('ddd, D MMM YYYY [at] HH:mm:ss')
};

const ScheduleSlider = ({ value }) => {
  const schedule = useMemo(() => {
    return later.schedule({
      schedules: [
        filterSchedule({
          Y: value.specific.unit.years,
          M: value.specific.unit.months,
          D: value.specific.unit.days,
          dw: value.specific.unit.week_days?.map?.(d => d + 1),
          h: value.specific.unit.hours,
          m: value.specific.unit.minutes,
        })
      ],
    })
  }, [value.specific]);
  const { p } = useTranslation('segments_page')

  const ranges = useMemo(() => {
    return (schedule
      .nextRange(-1, resolveDate(value.specific.range[0], earliest), resolveDate(value.specific.range[1], latest)) || [])
      .sort(([a], [b]) => new Date(a) - new Date(b));
  }, [schedule]);

  const closest = useMemo(() => {
    const sorted = [...ranges].sort((a, b) => Math.abs(now - new Date(a[0])) - Math.abs(now - new Date(b[0])));

    return ranges.findIndex(([v]) => v.getTime() === new Date(sorted[0][0])?.getTime?.());
  }, [ranges]);

  const [current, setCurrent] = useState(closest);
  const currentRange = ranges[current]?.map(d => moment(d).utc());

  useEffect(() => {
    setCurrent(closest || 0);
  }, [closest]);

  const handleNext = () => {
    if (current === ranges.length - 1) {
      return;
    }

    setCurrent(c => c + 1);
  };

  const handlePrev = () => {
    if (current === 0) {
      return;
    }

    setCurrent(c => c - 1);
  };

  return (
    <Container>
      <PrevButton onClick={handlePrev}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_9808_30669" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="12" height="12">
            <rect x="1" y="1" width="12" height="12" fill="#C4C4C4"/>
          </mask>
          <g mask="url(#mask0_9808_30669)">
            <path d="M9.08197 13C8.96721 13 8.85246 12.9754 8.7377 12.9263C8.62295 12.8771 8.51776 12.8133 8.42213 12.7346L3 7.01474L8.42213 1.29484C8.61339 1.09828 8.83333 1 9.08197 1C9.3306 1 9.55055 1.09828 9.7418 1.29484C9.91393 1.47174 10 1.69287 10 1.95823C10 2.22359 9.91393 2.44472 9.7418 2.62162L6.41393 6.04177L5.52609 7.01474L6.35656 7.92875L9.7418 11.3784C9.91393 11.5749 10 11.801 10 12.0565C10 12.312 9.91393 12.5381 9.7418 12.7346C9.64617 12.8133 9.54098 12.8771 9.42623 12.9263C9.31148 12.9754 9.19672 13 9.08197 13Z" fill="#909399"/>
          </g>
        </svg>
      </PrevButton>
      <RangeContainer>
        {currentRange ? `${formatDate(currentRange[0])} - ${formatDate(currentRange[1].add(-1, 'second'))}` : p('no_date_range_matches')}
      </RangeContainer>
      <NextButton onClick={handleNext}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_9808_30673" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="12" height="12">
            <rect width="12" height="12" transform="matrix(-1 0 0 1 13 1)" fill="#C4C4C4"/>
          </mask>
          <g mask="url(#mask0_9808_30673)">
            <path d="M4.91803 13C5.03279 13 5.14754 12.9754 5.2623 12.9263C5.37705 12.8771 5.48224 12.8133 5.57787 12.7346L11 7.01474L5.57787 1.29484C5.38661 1.09828 5.16667 1 4.91803 1C4.6694 1 4.44945 1.09828 4.2582 1.29484C4.08607 1.47174 4 1.69287 4 1.95823C4 2.22359 4.08607 2.44472 4.2582 2.62162L7.58607 6.04177L8.47391 7.01474L7.64344 7.92875L4.2582 11.3784C4.08607 11.5749 4 11.801 4 12.0565C4 12.312 4.08607 12.5381 4.2582 12.7346C4.35383 12.8133 4.45902 12.8771 4.57377 12.9263C4.68852 12.9754 4.80328 13 4.91803 13Z" fill="#909399"/>
          </g>
        </svg>
      </NextButton>
    </Container>
  );
};

export default ScheduleSlider;
