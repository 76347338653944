import styled from 'styled-components';

import { HighlightText } from '@components/ui/HighlightText';

const TemplateItemText = styled(HighlightText)`
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-SemiBold;
    font-size: 14px;
    line-height: 20px;
    white-space: wrap;
    word-break: break-word;
`;

export default TemplateItemText;
