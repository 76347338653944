import { SegmentsRuleTypes } from '@constants';

import { by } from '@utils';

export const viewSteps = {
  ATTRIBUTE_LIST: 'ATTRIBUTE_LIST',
  SEGMENT_LIST: 'SEGMENT_LIST',
  SHOW_LIST: 'SHOW_LIST',
  SHOW_SELECTED_FILTER: 'SHOW_SELECTED_FILTER',
  SHOW_SELECTED_SEGMENT: 'SHOW_SELECTED_SEGMENT'
};

export const SegmentType = {
  SEGMENT: 'segment',
};

export const listAttributeOptions = [
  SegmentsRuleTypes.LINEAR,
  // SegmentsRuleTypes.ARRAY,
  SegmentsRuleTypes.EVENT,
  SegmentsRuleTypes.AGGREGATE,
];

export const listSegmentOptions = [
  SegmentType.SEGMENT,
  SegmentsRuleTypes.RFM_SEGMENT,
];

export const titleListConfig = {
  [SegmentsRuleTypes.LINEAR]: 'linear_fields',
  [SegmentsRuleTypes.ARRAY]: 'arrays_list',
  [SegmentsRuleTypes.AGGREGATE]: 'aggregates_list',
  [SegmentsRuleTypes.EVENT]: 'events_list',
  [SegmentType.SEGMENT]: 'segments',
  [SegmentsRuleTypes.RFM_SEGMENT]: 'rfm_segments',
}

export const resolveFilterValue = (filter, options) => {
  if(!filter.query?.children?.[0]) return null;

  if (filter.query.children[0]?.query?.ruleType === SegmentsRuleTypes.EVENT) {

    return {
      ...filter.query.children[0]?.query,
      filter_builder: filter.query.children[0].query,
      event_id: (options || []).find(
        by('name', filter.query.children[0].query.field)
      ).id,
    };
  }

  return filter.query.children[0].query;
};

export const getlistTypeOptions = (step) => {

  if (step === viewSteps.ATTRIBUTE_LIST) {
    return listAttributeOptions
  }

  if (step === viewSteps.SEGMENT_LIST) {
    return listSegmentOptions
  }

  return null;
};
