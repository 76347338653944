import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Modal } from '@components';

import { Container } from './styled';

const ChangesNotSavedDisclaimer = ({ onClose, onSubmit, hidden, onLeave }) => {
  const { p } = useTranslation('templates');

  return (
    <Modal
      title={p('unsaved_changes')}
      opened={!hidden}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onLeave} variant="secondary" style={{ width: '140px' }}>
            {p('dont_save')}
          </Button>
          <Button onClick={onSubmit} variant="primary" style={{ width: '140px' }}>
            {p('save_and_leave')}
          </Button>
        </>
      }
    >
      <Container>
        {p('want_save_current_template')}
      </Container>
    </Modal>
  );
};

export default ChangesNotSavedDisclaimer;
