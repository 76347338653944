import { createRequestAction } from '@store/lib/utils/creators';
import { importMappingSelector } from '@store/selectors';

import { all, takeLatest, takeEvery, throttle, put, select } from 'redux-saga/effects';

import { Endpoints } from '@constants';

import { ContactsActionTypes } from '../../actions/types';

function* createCommentSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.CREATE_CONTACT_COMMENT,
    body: {
      customer_id: action.payload.id,
      comment: action.payload.comment,
    }
  }));
}

function* createNewContactFieldSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.CREATE_NEW_CONTACT_FIELDS,
    body: {
      validate_id: action.meta.validate_id,
      new_fields: action.payload.new_fields,
    },
  }));
}

function* createContactSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.CREATE_CONTACT,
    body: {
      customer_info: action.payload.fields,
      validate_id: action.meta.validate_id,
      // new_fields: action.payload.new_fields,
    },
  }));
}

function* createExpressionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.CREATE_EXPRESSION,
    body: {
      id: action.payload.id,
      name: action.payload.name,
      arrayField: action.payload.arrayField,
      logicalOperator: action.payload.logicalOperator,
      filters: action.payload.filters,
      expression: action.payload.expression,
      aggregates: action.payload.aggregates,
      linearFields: action.payload.linearFields,
    },
  }));
}

function* deleteAllCustomersSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: '/api/cus/edge/customers/all-delete',
    body: {},
  }));
}

function* deleteCommentSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: Endpoints.DELETE_CONTACT_COMMENT,
    body: {
      id: action.payload.id,
      customer_id: action.payload.customer_id,
    }
  }));
}

function* deleteContactFieldSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: Endpoints.FIELD_SETTINGS,
    body: {
      field: action.payload.field,
    }
  }));
}

function* deleteContactSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: `${Endpoints.DELETE_CONTACT}/${action.payload.id}`,
    body: {
      id: action.payload.id,
      customer_id: action.payload.customer_id,
    }
  }));
}

function* deleteMultipleContactsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: Endpoints.DELETE_MULTIPLE_CONTACTS,
    body: { ids: action.payload.ids },
  }));
}

function* downloadContactsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/seg/customer/export',
    body: {
      fields: action.payload.fields,
      ids: action.payload.ids,
      ...action.payload.filters
    },
  }));
}

function* downloadImportFileSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.DOWNLOAD_FILE}/${action.payload.id}`,
    responseType: 'blob',
  }));
}

function* getAggregatesListSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/seg/settings/aggregate/list',
  }));
}

function* getContactAuditsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_AUDITS}${action.meta.id}`,
  }));
}

function* getContactEmailInfoSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_EMAIL_INFO}${action.meta.id}`,
  }));
}

function* getContactInfoSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_INFO(action.meta.id)}`,
  }));
}

function* getContactEventsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_EVENTS(action.meta.id)}`,
  }));
}

function* getContactEventsTotalSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_EVENTS_TOTAL(action.meta.id)}`,
  }));
}

function* getContactInvolvementSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_INVOLVEMENT}/${action.meta.id}`,
  }));
}

function* getContactPushInfoSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_PUSH_INFO}${action.meta.id}`,
  }));
}

function* getContactsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.CONTACTS,
    body: {
      aggregates: action.payload.aggregates,
      fields: action.payload.fields,
    },
    disableTimeout: true,
  }));
}

const resolveType = (page) => {
  switch (page) {
    case "segment":
    case "index":
    case "aggregate":
      return "examples";
    case "funnel":
      return "funnel";
    case "unsubscribe_group":
      return "unsubscribe/examples";
    default:
      return "examples";
  }
};

function* getCountSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    body: action.payload,
    endpoint: `/api/seg/customer/${resolveType(action.meta.page)}/count`,
  }));
}

function* getCustomerFieldInfoSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.FIELD_INFO(action.payload.customerId, action.payload.id),
    params: {
      index: action.payload.subArrayIndex ? action.payload.subArrayIndex : void 0,
      nested_field: action.payload.subArrayId ? action.payload.subArrayId : void 0,
    },
    body: {
      id: action.payload.id,
    }
  }));
}

function* getEmailSummarySaga(action) {
  const [dateFrom, dateTo] = action.payload.range || [];
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.CONTACT_EMAIL_SUMMARY,
    disableTimeout: true,
    params: {
      dateFrom,
      dateTo,
      customer_id: action.meta.id,
      email_id: action.meta.email_id,
    }
  }));
}

function* getImportHistorySaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.IMPORT_HISTORY,
  }));
}

function* getImportProgressSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.TRACK_CONTACTS_IMPORT_PROGRESS}/${action.payload.id}`
  }));
}

function* getPushSummarySaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_PUSH_SUMMARY}`,
    params: {
      customer_id: action.meta.id,
      web_push_id: [action.payload.web_push_id],
    },
    disableTimeout: true,
  }));
}

function* getSmsSummarySaga(action) {
  const [dateFrom, dateTo] = action.payload.range || [];
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.CONTACT_SMS_SUMMARY,
    disableTimeout: true,
    params: {
      customer_id: action.meta.id,
      sms_id: action.meta.sms_id ? [action.meta.sms_id] : [],
      dateFrom,
      dateTo,
    },
  }));
}

function* getWebhookSummarySaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/webhook/summary`,
    params: { request_id: [action.payload.template_id] },
    disableTimeout: true,
  }));
}

function* startImportSaga(action) {
  const mapping = yield select(importMappingSelector);
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.START_CONTACTS_IMPORT,
    body: {
      id: mapping.id,
      fields: action.payload.fields
    },
  }));
}

function* updateContactFieldSaga(action) {
  const body = {
    id: action.meta.id,
    label: action.payload.label,
    description: action.payload.description,
  };
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/cus/settings/fields/clickhouse/edit',
    body,
  }));
}

function* uploadFileSaga(action) {
  const formData = new FormData();
  formData.append('file', action.payload.file);
  formData.append('name', action.payload.file.name);
  formData.append('meta', JSON.stringify(action.meta));
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.UPLOAD_CONTACTS,
    body: formData,
    headers: { "Content-Type": "multipart/form-data" }
  }));
}

function* getContactSmsInfoSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `${Endpoints.CONTACT_SMS_INFO}${action.meta.id}`,
  }));
}

export default function* contactsRootSaga() {
  yield all([
    throttle(2000, ContactsActionTypes.GET, getContactsSaga),
    takeLatest(ContactsActionTypes.GET_IMPORT_HISTORY, getImportHistorySaga),
    takeLatest(ContactsActionTypes.UPLOAD_FILE, uploadFileSaga),
    takeLatest(ContactsActionTypes.START_IMPORT, startImportSaga),
    takeLatest(ContactsActionTypes.GET_IMPORT_PROGRESS, getImportProgressSaga),
    takeLatest(ContactsActionTypes.DELETE_CONTACT, deleteContactSaga),
    takeLatest(ContactsActionTypes.CREATE_COMMENT, createCommentSaga),
    takeLatest(ContactsActionTypes.DELETE_COMMENT, deleteCommentSaga),
    takeLatest(ContactsActionTypes.CREATE_CONTACT, createContactSaga),
    takeLatest(ContactsActionTypes.CREATE_EXPRESSION, createExpressionSaga),
    takeLatest(ContactsActionTypes.DOWNLOAD_IMPORT_FILE, downloadImportFileSaga),
    takeLatest(ContactsActionTypes.DELETE_MULTIPLE, deleteMultipleContactsSaga),
    takeLatest(ContactsActionTypes.DOWNLOAD_CONTACTS, downloadContactsSaga),
    takeLatest(ContactsActionTypes.UPDATE_CONTACT_FIELD, updateContactFieldSaga),
    takeLatest(ContactsActionTypes.GET_CUSTOMER_FIELD_INFO, getCustomerFieldInfoSaga),
    takeLatest(ContactsActionTypes.DELETE_FIELD_SETTINGS, deleteContactFieldSaga),
    takeLatest(ContactsActionTypes.GET_CONTACT_INFO, getContactInfoSaga),
    takeLatest(ContactsActionTypes.GET_CONTACT_EVENT, getContactEventsSaga),
    takeLatest(ContactsActionTypes.GET_CONTACT_EVENT_TOTAL, getContactEventsTotalSaga),
    takeLatest(ContactsActionTypes.DELETE_ALL, deleteAllCustomersSaga),
    takeLatest(ContactsActionTypes.GET_CONTACT_EMAIL_INFO, getContactEmailInfoSaga),
    takeLatest(ContactsActionTypes.GET_CONTACT_SMS_INFO, getContactSmsInfoSaga),
    takeLatest(ContactsActionTypes.GET_CONTACT_PUSH_INFO, getContactPushInfoSaga),
    takeLatest(ContactsActionTypes.CREATE_NEW_CONTACT_FIELDS, createNewContactFieldSaga),
    takeLatest(ContactsActionTypes.GET_CONTACT_INVOLVEMENT, getContactInvolvementSaga),
    takeLatest(ContactsActionTypes.GET_CONTACT_AUDITS, getContactAuditsSaga),
    takeLatest(ContactsActionTypes.GET_AGGREGATES_LIST, getAggregatesListSaga),
    takeEvery(ContactsActionTypes.GET_EMAIL_SUMMARY, getEmailSummarySaga),
    takeEvery(ContactsActionTypes.GET_SMS_SUMMARY, getSmsSummarySaga),
    takeEvery(ContactsActionTypes.GET_WEBHOOK_SUMMARY, getWebhookSummarySaga),
    takeEvery(ContactsActionTypes.GET_WEBPUSH_SUMMARY, getPushSummarySaga),
    throttle(2000, ContactsActionTypes.GET_CUSTOMERS_COUNT, getCountSaga),
  ]);
}
