import styled from 'styled-components';

import { extract } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ColorIndicator = styled.div`
  width: 14px;
  height: 14px;
  background: ${extract('$color')};
  border-radius: 4px;
  margin-right: 6px;
  margin-left: 3px;
`;
