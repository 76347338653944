import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  ${styledProp('$s', css`
  `, css`
    align-items: center;
    padding: 16px 0 43px 0;
  `)}
`;

export const CardsContainer = styled.div`
  display: flex;
  padding: 46px 28px 43px;
  align-items: center;
  gap: 55px;

  position: relative;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 2px;
  z-index: 1;
`;

export const Description = styled.div`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  color: var(--text-secondary-body, #7C94B1);

  ${styledProp('$lighter', css`
    color: var(--Colors-Neutral-300, #D3DBE7);
  `)}
`;
