import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useDateFormat, useTranslation } from '@hooks';

import { tryMongoDateObject } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';

import { CopyHandle } from '@components';

import { Container, TableCell, TableRow, Table, CopyButton } from './styled';

const SubscriptionAttributes = () => {
  const { t, p } = useTranslation('templates');
  const format = useDateFormat();
  const fieldSettings = useSelector(clickhouseFieldsSelector)?.data || [];
  // eslint-disable-next-line no-unused-vars
  const fields = clickhouseFields2Customer(fieldSettings).map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));

  // const tableFields = useMemo(
  //   () => fields.filter(({ name, key }) => key.includes('unsubscribe_url') || key.includes('subscribe_url')),
  //   [fields]
  // );

  const tableFields = [
    {
      name: 'Subscribe',
      key: 'subscribe_url'
    },
    {
      name: 'Unsubscribe',
      key: 'unsubscribe_url'
    },
    {
      name: 'Global unsubscribe',
      key: 'global_unsubscribe_url'
    }
  ]

  const resolveCopyData = (key) => {
    if (key === 'subscribe_url' || key === 'unsubscribe_url' || key === 'global_unsubscribe_url') {
      return `{{${key}}}`;
    }

    return `{{customer.${key}}}`;
  };

  return (
    <Container>
      <Table>
        <TableRow>
          <TableCell widthPercent={50} $header>
            {t('labels.link')}
          </TableCell>
          <TableCell widthPercent={50} alignEnd $header>
            {t('labels.action')}
          </TableCell>
        </TableRow>
        {tableFields.map(({ name, value, key }) => (
          <TableRow key={key}>
            <TableCell widthPercent={39}>{name}</TableCell>
            <TableCell widthPercent={39} withPadding>
              {typeof value === 'boolean' ? JSON.stringify(value) : tryMongoDateObject(value, format)}
            </TableCell>
            <TableCell widthPercent={22} alignEnd>
              <CopyHandle align="flex-end" data={resolveCopyData(key, name)}>
                <CopyButton>{p('copy')}</CopyButton>
              </CopyHandle>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Container>
  );
};

export default SubscriptionAttributes;
