import { TimePicker } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTimePicker = styled(TimePicker)`
  border: 1px solid #D3DBE7;
  border-radius: 7px;
  height: 34px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding: 0 !important;
  box-shadow: none;
  overflow: hidden;
  
  ${styledProp('$error', css`
    border: 1px solid #C8402F;
  `)}

  &.ant-picker-focused > div {
    transition: all 300ms ease;
    padding-left: 11px;
    max-width: 200px !important;

    & > span {
      height: 100%;
      margin-left: 4px;
      border-left: 1px solid #DCDFE6;
    }

    & > input {
      width: auto !important;
    }
  }
  
  & > div {
    transition: all 300ms ease;
    height: 100%;
    padding-left: 11px;

    & > span {
      height: 100%;
      margin-left: 4px;
      border-left: 1px solid #DCDFE6;
    }
  }

  & > div > input {
    min-width: 0px !important;
  }

  & > div > span {
    width: 37px;
    border-left: 1px solid #D3DBE7;
    height: 100%;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFFFFF;
    color: #A1B3C8;
    margin-left: 0px;
  }

  & > div > span > span {
    justify-content: center;
  }

  &:focus {
    box-shadow: none !important;
    border-color: #31C447;
  }
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 5px;
  line-height: 120%;
  color: #C8402F;
`;

