import styled from 'styled-components';

export const ViberImage = styled.img`
  max-width: 100%;
  max-height: 300px;
  margin-top: 15px;
`;

export const ViberPreviewComponent = styled.div`
  position: absolute;
  padding: 14px 0px 4px 0px;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  line-height: 120%;
  height: 100%;
  background: #EFEFEF;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
`;

export const ViberButton = styled.div`
  background: #7062C8;
  border-radius: 58px;
  font-size: 17px;
  line-height: 120%;
  color: #FFFFFF;
  padding: 6px;
  margin: 10px 14px 10px 14px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
