import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getApiRequestTemplates,
  getDashboardAnalyticsEntities,
  getDashboardAnalyticsOptions,
  getEmailTemplates,
  getMobilePushTemplates,
  getSmsTemplates,
  getUser,
  getWebpushTemplates,
  listSegments,
  listWorkflows,
  myListIntegrations,
  sentOnboardParams,
  setHintMode,
  setViewDemoWorkspacesModalOpened,

  getViberTemplates } from '@store/actions/creators';
import {
  myIntegrationsSelector,
  userSelector,
  userTrialHasEndedSelector,
} from '@store/selectors';
import {
  dashboardDatabaseEfficiencySelector,
  dashboardDataLoadedSelector,
} from '@store/selectors/dashboard';

import {
  useBreakpoint,
  useGAPageTracking,
  useModalState,
  useTranslation,
} from '@hooks';

import { IntegrationPresets } from '@constants';

import {
  gaFlowEventEvent,
} from '@utils';

import { ActivateBar, TrialBar } from '@components';

import {
  BaseActivitySection,
  CustomSubscriptionExpiredModal, FacebookIntegrationDisclaimer,
  MobileDisclaimerModal, OptionalItem,
  WelcomeModal
} from './components';
import { DashboardTemplates } from "./components/DashboardTemplates";
import { RecentSegmentsTable } from "./components/RecentSegmentsTable";
import { RecentWorkflowTable } from "./components/RecentWorkflowTable";
import { TemplateSendingSection } from "./components/TemplateSendingSection";
import {
  Container,
  RecentActivitySection,
  AnalyticSection, EntitiesSection,
} from './styled';

const page = 1;
const perPage = 6;

const DashboardPage = () => {
  const { p, i18n } = useTranslation('dashboard_page');
  const dispatch = useDispatch();
  const welcomeModal = useModalState(false);
  const welcomeMobileModal = useModalState(false);
  const welcome = window.location.search.indexOf('welcome') !== -1;
  const user = useSelector(userSelector);
  const databaseEfficiency = useSelector(dashboardDatabaseEfficiencySelector);
  const loaded = useSelector(dashboardDataLoadedSelector);
  const startQuestionnaireModal = useModalState(false);
  const trialHasEnded = useSelector(userTrialHasEndedSelector);
  const isMobile = useBreakpoint(769);
  const customSubscriptionExpiredModal = useModalState();
  const myIntegrations = useSelector(myIntegrationsSelector);

  useEffect(() => {
    dispatch(getUser());
  }, [])

  useEffect(() => {
    if (!user?.app?.id) {
      return;
    }

    dispatch(getDashboardAnalyticsEntities());
    dispatch(myListIntegrations());
    dispatch(getDashboardAnalyticsOptions({ app_id: user?.app?.id }));
  }, [user?.app?.id]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useGAPageTracking('welcome');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!user?.app?.id) {
      return;
    }

    dispatch(listSegments({ page, perPage, orderCol: 'updated_at', orderDir: 'desc' }));
    dispatch(listWorkflows({ page, perPage, orderCol: 'created_at', orderDir: 'desc' }));
    dispatch(getSmsTemplates({ page, perPage, all: true, folder: 'default' }));
    dispatch(getViberTemplates({ page, perPage, all: true, folder: 'default' }));
    dispatch(getEmailTemplates({ page, perPage, all: true, folder: 'default' }));
    dispatch(getMobilePushTemplates({ page, perPage, all: true, folder: 'default' }));
    dispatch(getWebpushTemplates({ page, perPage, all: true, folder: 'default' }));
    dispatch(getApiRequestTemplates({ page, perPage, all: true, folder: 'default' }));
  }, [user?.app?.id]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (user?.app?.id && i18n.isInitialized) {
      if (trialHasEnded) {
        customSubscriptionExpiredModal.open();
        startQuestionnaireModal.open();
      } else if (user?.name && welcome) {
        dispatch(setHintMode(true));
        dispatch(sentOnboardParams({ enabled: true }));
        if (!isMobile) {
          welcomeModal.open();
          gaFlowEventEvent('registration', 'dashboard-welcome-visit', true);
        } else {
          welcomeMobileModal.open();
        }
      }
    }

  }, [loaded, user?.app?.id, isMobile, i18n.isInitialized]);

  const renderContacts = useMemo(() => {
    return !!databaseEfficiency?.total;
    // return !!user?.has_customers;
  }, [databaseEfficiency?.total]);

  const baseActivityRenderConfig = {
    shouldRender: renderContacts,
    tooltip: p('schedule_after_customers'),
    content: <BaseActivitySection />
  }

  const invalidFacebookConnections = (myIntegrations.data || []).some(({ integration_key, invalid }) => integration_key === IntegrationPresets.FACEBOOK_ADS && invalid);

  return (
    <>
      <Container>
        <AnalyticSection>
          <div style={{ padding: '5px 20px 0 20px', backgroundColor: 'linear-gradient(251.17deg, #E6F1FC 2.11%, #ECF8FF 90.83%)' }}>
            <TrialBar onOpenTrial={startQuestionnaireModal.open} />
          </div>
          <div style={{ padding: '5px 20px 0 20px', backgroundColor: 'linear-gradient(251.17deg, #E6F1FC 2.11%, #ECF8FF 90.83%)' }}>
            <ActivateBar onResend={() => {}} />
          </div>
          {invalidFacebookConnections && (
            <div style={{ padding: '5px 20px 0 20px', backgroundColor: 'linear-gradient(251.17deg, #E6F1FC 2.11%, #ECF8FF 90.83%)' }}>
              <FacebookIntegrationDisclaimer />
            </div>
          )}
          {/*{(!!user?.app?.id && !userLoading) ? (*/}
          {/*  <OnboardingSection />*/}
          {/*) : null}*/}
          <OptionalItem {...baseActivityRenderConfig} />
          <TemplateSendingSection />
        </AnalyticSection>
        <EntitiesSection>
          <RecentActivitySection>
            <RecentWorkflowTable />
            <RecentSegmentsTable />
          </RecentActivitySection>
          <DashboardTemplates />
        </EntitiesSection>
        <CustomSubscriptionExpiredModal
          opened={customSubscriptionExpiredModal.opened}
          onClose={customSubscriptionExpiredModal.close}
        />
        <WelcomeModal
          userName={user?.name}
          opened={welcomeModal.opened}
          onClose={() => {
            welcomeModal.close();
            dispatch(setViewDemoWorkspacesModalOpened({ opened: true }));
          }}
        />
        <MobileDisclaimerModal opened={welcomeMobileModal.opened} onClose={welcomeMobileModal.close} />
        {/*<StartQuestionnaireModal*/}
        {/*  userName={user?.name}*/}
        {/*  onClose={startQuestionnaireModal.close}*/}
        {/*  opened={startQuestionnaireModal.opened}*/}
        {/*/>*/}
      </Container>
    </>
  );
};

export default DashboardPage;

