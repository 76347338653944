import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  padding: 3px 4px;
  width: 100%;
  .slate-input- {
    width: 100%;
  }
  p {
    font-size: 100%;
  }
    
    ${styledProp('$fullHeight', css`
        .slate-input- {
            min-height: 500px;
        }
    `)}
`;
