const WorkflowNodes = {
  RESOURCE: 'resource',
  ENTRY_ANOTHER_WF: 'worker',
  MOBILE_PUSH: 'mobpush',
  API_REQUEST: 'apirequest',
  DATA_CHANGE: 'contactupdated',
  QUICK_FILTER: 'quick',
  API_FILTER: 'apifilter',
  EXCLUDE_FILTER: 'filterexclude',
  CONTACT_CREATED: 'contactcreated',
  SCHEDULE: 'schedule',
  REALTIME_RESOURCE: 'realtimeResourceComponent',
  PUSH_DISCOUNT: 'discount',
  SWITCH_FILTER: 'filter',
  WAIT: 'wait',
  CUSTOMER_CHANGE: 'changeAttribute',
  SEND_EMAIL: 'email',
  SEND_SMS: 'sms',
  SEND_CARD: 'card',
  EVENT: 'event',
  EMAIL: 'emailtriggered',
  PAGE_VIEW: 'page',
  DYNAMIC_DISCOUNT: 'dynamicdiscount',
  END_PATH: 'end',
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
  AB_SPLITTER: 'splitter',
  RETARGETING: 'facebook',
  AB_TEST: 'splitter',
  WEBPUSH: 'webpush',
  VIBER: 'viber',
  WEB_POPUP: 'webpopup',
  QUIET_HOURS: 'quiethours',
  GIVE_REWARD: 'reward',
  INCLUDE_CAMPAIGN: 'include-campaign',
  BEST_CHANNEL_TO_SEND: 'bestchannel',
  TEAM_MESSAGE: 'notification',
}

export default WorkflowNodes;
