import { useLocation, useNavigate } from 'react-router';

import { MenuItem, ListMenuItem, useMenuContext } from '@velitech/ui';
import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { IconSvg, WithPermissions } from '@components';

import { SubItemContainer, BetaLabel, BetaText, ItemContent, ItemContainer } from './styled';

export const SidebarItemNew = (props) => {
  const menuCtx = useMenuContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { p } = useTranslation('sidebar');

  const handleClickItem = (path) => () => {
    navigate(path);
  };

  const isActive = (itemPath) => {

    const isWorkflowAnalytics = ['workflow', 'analytics'].every((item) => pathname.includes(item));

    if(isWorkflowAnalytics && itemPath.includes('analytics')) {
      return false;
    }

    return pathname.includes(itemPath);
  };

  const itemWithLabel = (item) => {
    return (
      <SubItemContainer>
        {p(item.name)}
        <BetaLabel>
          <BetaText>Beta</BetaText>
        </BetaLabel>
      </SubItemContainer>
    );
  };

  const items = props.items?.map((item) => {
    const isbetaLabel = item.isBeta;

    return {
      ...item,
      children: <ItemContent>{isbetaLabel ? itemWithLabel(item) : p(item.name)}</ItemContent>,
      onClick: handleClickItem(item.path),
      active: isActive(item.path),
    };
  });

  const getIconcolor = (path) => {
    return isActive(path) && 'var(--vl-menu-item-addon-left-color)';
  };

  return (
    <WithPermissions name={props.permission} placement='right'>
      {props.index === 0 && <div style={{ paddingTop: '17px' }}></div>}
      {props.items && (
        <ItemContainer>
          <ListMenuItem {...props} className='menu-item' items={items} active={isActive(props.path)}>
            <div
              style={{ display: 'inline-flex', gap: '8px', alignItems: 'center' }}
            >
              <IconSvg
                size={22}
                $color={getIconcolor(props.path)}
                name={props.icon}
              />
              {(menuCtx.opened || menuCtx.opening) && p(props.name)}
            </div>
          </ListMenuItem>
        </ItemContainer>
      )}

      {!props.items && (
        <Tooltip placement={'right'} title={!menuCtx.opened && p(props.name)}>
          <MenuItem
            {...props}
            addonLeft={props.addonLeft || <IconSvg size={22} name={props.icon} />}
            onClick={handleClickItem(props.path)}
            active={isActive(props.path)}
          >
            {p(props.name || props.name)}
          </MenuItem>
        </Tooltip>
      )}
    </WithPermissions>
  );
};
