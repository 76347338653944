import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  ${styledProp('$s', css`
    margin-top: 10px;
  `, css`
    padding-left: 20px;
    margin-left: 5px;
    padding-top: 10px;
    border-left: 1px solid #C1CEFC;
  `)}
  
  ${styledProp('$l', css`
    padding-left: 0;
    margin-left: 0;
    padding-top: 0;
    border-left: none;
  `)}
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 130%;
  color: #8B8E9F;
  margin-bottom: 6px;
`;

export const SimplifiedTitle = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #8B8E9F;
  width: 165px;
  min-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
