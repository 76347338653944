const AppActionTypes = {
  CHECK_APP_LINK: 'app/CHECK_LINK',
  CHECK_APP_LINK_SUCCESS: 'app/CHECK_LINK_SUCCESS',
  CHECK_APP_LINK_ERROR: 'app/CHECK_LINK_ERROR',

  PING_API: 'app/PING',
  PING_API_SUCCESS: 'app/PING_SUCCESS',
  PING_API_ERROR: 'app/PING_ERROR',

  UPDATE_REDIRECTS: 'app/UPDATE_REDIRECTS',

  PURGE: 'persist/PURGE',
  SET_TRIAL_BAR_SHOWN: 'app/SET_TRIAL_BAR_SHOWN',
  SET_SIDEBAR_OPENED: 'app/SET_SIDEBAR_OPENED',

  SET_HINT_MODE: 'app/SET_HINT_MODE',

  UPDATE_ONBOARDING: 'app/UPDATE_ONBOARDING',
  SKIP_ONBOARD: 'app/SKIP_ONBOARD',
  RESET_ONBOARD: 'app/RESET_ONBOARD',

  SENT_ONBOARD_PARAMS: 'app/SENT_ONBOARD_PARAMS',
  SENT_ONBOARD_PARAMS_SUCCESS: 'app/SENT_ONBOARD_PARAMS_SUCCESS',
  SENT_ONBOARD_PARAMS_ERROR: 'app/SENT_ONBOARD_PARAMS_ERROR',

  SET_MESSAGE_CHANNELS_DISCLAIMER_CLOSED: 'app/SET_MESSAGE_CHANNELS_DISCLAIMER_CLOSED',

  CHECK_SDK: 'app/CHECK_SDK',
  CHECK_SDK_SUCCESS: 'app/CHECK_SDK_SUCCESS',
  CHECK_SDK_ERROR: 'app/CHECK_SDK_ERROR',
};

export default AppActionTypes;
