import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  border-radius: 10px;
  border: 2px solid var(--Colors-Additional-White, #FFF);
  background: #F4F5FD;
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.30);
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
`;

export const Image = styled.img`
  ${styledProp('smaller', css`
    width: 36px;
    height: 36px;
  `)}
`;
