export const SegmentIconSvg = () => {
  return (
    <svg
      width='142'
      height='52'
      viewBox='0 0 142 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M52.0529 39.1235L51.7755 39.4907C51.6525 39.6536 51.5859 39.8522 51.5859 40.0563V41.0962C51.5859 42.4638 52.6946 43.5725 54.0623 43.5725H86.932C88.2997 43.5725 89.4084 42.4638 89.4084 41.0962V40.5165C89.4084 40.0138 89.2444 39.5247 88.9414 39.1235C79.6934 26.8791 61.3009 26.8791 52.0529 39.1235Z'
        fill='#87E2BA'
        stroke='#0C1015'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path
        d='M82.979 18.2967C82.979 24.8476 77.6298 30.166 71.0215 30.166C64.4133 30.166 59.0641 24.8476 59.0641 18.2967C59.0641 11.7457 64.4133 6.42734 71.0215 6.42734C77.6298 6.42734 82.979 11.7457 82.979 18.2967Z'
        fill='#F8F4E4'
        stroke='#0C1015'
        strokeWidth='1.3'
      />
    </svg>
  );
};
