import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  padding-top: 15px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #303133;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const YearButton = styled.div`
  user-select: none;
  cursor: pointer;
`;

export const Months = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Month = styled.div`
  height: 36px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: #F0F2F6;
  border-radius: 7px;
  font-family: Manrope-Medium;
  font-size: 14px;
  line-height: 17px;
  color: #8B8E9F;
  user-select: none;
  cursor: pointer;
  transition: 280ms ease all;
  box-sizing: border-box;
  border: 2px solid transparent;
  
  ${styledProp('$hovered', css`
    border-color: #31C447;
    color: #31C447;
  `)}

  ${styledProp('$selected', css`
    background: #31C447;
    color: #fff;
  `)}

  ${styledProp('$disabled', css`
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  `)}
`;

export const Footer = styled.div`
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F0F2F6;
  font-family: Manrope-Medium;
  margin-top: 18px;
  font-size: 13px;
  line-height: 120%;
  color: #8B8E9F;
`;
