import { FunnelsActionTypes } from '@store/actions/types';

const initialState = {
  funnels: {
    data: [],
    loading: false,
  },
  funnel: {
    loading: false,
  },
  preview: {
    loading: false,
    data: [],
  }
};

const funnelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FunnelsActionTypes.LIST:
      return {
        ...state,
        funnels: {
          ...state.funnels,
          loading: true,
        },
      };
    case FunnelsActionTypes.LIST_SUCCESS:
      return {
        ...state,
        funnels: {
          ...state.funnels,
          data: action.payload,
          loading: false,
        },
      };
    case FunnelsActionTypes.LIST_ERROR:
      return {
        ...state,
        funnels: {
          ...state.funnels,
          loading: false,
        },
      };
    case FunnelsActionTypes.GET:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          loading: true,
        },
      };
    case FunnelsActionTypes.GET_SUCCESS:
      return {
        ...state,
        funnel: {
          data: {
            ...(state.funnel?.data || {}),
            ...action.payload,
          },
          loading: false,
        },
      };
    case FunnelsActionTypes.GET_ERROR:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          loading: false,
        },
      };

    case FunnelsActionTypes.PREVIEW:
      return {
        ...state,
        preview: {
          ...state.preview,
          [action.meta.id]: {
            ...(state.preview[action.meta.id] || {}),
            loading: true,
          }
        },
      };
    case FunnelsActionTypes.PREVIEW_SUCCESS:
      return {
        ...state,
        preview: {
          ...state.preview,
          [action.meta.id]: {
            ...(state.preview[action.meta.id] || {}),
            loading: false,
            data: {
              ...(state.preview[action.meta.id]?.data || {}),
              [action.meta.type]: action.payload,
            },
          }
        },
      };
    case FunnelsActionTypes.PREVIEW_ERROR:
      return {
        ...state,
        preview: {
          ...state.preview,
          [action.meta.id]: {
            ...(state.preview[action.meta.id] || {}),
            data: [],
            loading: false
          }
        },
      };
    default:
      return state;
  }
};

export default funnelsReducer;
