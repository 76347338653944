import styled from "styled-components";

export const Container = styled.div`
  width: 320px;
`;

export const Row = styled.div`
    display: flex;
    gap: 16px;
    
    & > *:first-child {
        width: 100%;
    }
    
    & > *:last-child {
        width: 100px;
    }
`;
