export const decomposeFilters = (filters = []) => filters.flatMap(f => f.type === 'array' ? f.filters.flatMap(ff => ({ type: 'array', field: f.field, filters: [{ ...ff }] })) : f);

export const composeFilters = (filters = []) => {
  const newFilters = JSON.parse(JSON.stringify(filters))
  const parsedFilters = [];

  newFilters.forEach(filter => {
    if (filter.type === 'array' && !parsedFilters.find(f => f.field === filter.field && f.type === 'array')) {
      parsedFilters.push(filter);
    } else if (filter.type === 'array' && parsedFilters.find(f => f.field === filter.field && f.type === 'array')) {
      parsedFilters[parsedFilters.findIndex(f => f.field === filter.field && f.type === 'array')].filters.push(filter.filters[0]);
    } else {
      parsedFilters.push(filter);
    }
  })

  return parsedFilters;
}
