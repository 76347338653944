import styled from 'styled-components';

const COEF = 168 / 420;

export const IframPreview = styled.iframe`
    position: absolute;
    width: 420px;
    height: 420px;
    transform: scale(${COEF}) translate(-315px, -315px);
    top: 0;
    left: 0;
    border: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    overflow: visible;
`;

export const WebPopupContainer = styled.div`
   display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`

export const WebPopupImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
`

export const WebPopupContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 5px;
  z-index: 1;
`

export const Title = styled.h5`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
`;

export const Description = styled.p`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
`;