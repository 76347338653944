import { CampaignActionTypes } from '@store/actions/types';
import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from '@store/lib';

import { compose } from '@utils';

export const listCampaigns = compose(
  withCallbacks,
  withPagination,
  withOrdering,
  withFilters(['name', 'status', 'sending_type', 'resource', 'user', 'date_filter', 'execution_range', 'start_date', 'end_date']),
  asCreator(() => ({
    type: CampaignActionTypes.LIST,
  }))
);

export const listCalendarCampaigns = compose(
  withCallbacks,
  withFilters(['name', 'status', 'sending_type', 'resource', 'user', 'date_filter', 'execution_range', 'start_date', 'end_date']),
  asCreator(() => ({
    type: CampaignActionTypes.LIST_CALENDAR,
  }))
);

export const setCachedCampaign = compose(
  asCreator(({ data }) => ({
    type: CampaignActionTypes.SET_CACHED_CAMPAIGN,
    payload: { data },
  }))
)

export const clearCachedCampaign = compose(
  asCreator(() => ({
    type: CampaignActionTypes.CLEAR_CACHED_CAMPAIGN,
  }))
)

export const saveCampaign = compose(
  withCallbacks,
  asCreator(({ campaign, tags }) => ({
    type: CampaignActionTypes.SAVE,
    payload: { campaign, tags },
  }))
);

export const publishCampaign = compose(
  withCallbacks,
  asCreator(({ id, schedule, fake }) => ({
    type: CampaignActionTypes.PUBLISH,
    payload: { id, schedule, fake },
  }))
);

export const editCampaign = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: CampaignActionTypes.EDIT,
    meta: { id },
  }))
);

export const stopCampaign = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: CampaignActionTypes.STOP_CAMPAIGN,
    payload: { id }
  }))
);

export const getCampaignContacts = compose(
  withCallbacks,
  withPagination,
  asCreator(({ channel_type, worker_id, node_id, range, status, fields }) => ({
    type: CampaignActionTypes.GET_CAMPAIGN_CONTACTS_IDS,
    payload: { channel_type, worker_id, node_id, status, range },
    meta: { fields }
  }))
);

export const getCampaignNotes = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: CampaignActionTypes.GET_CAMPAIGN_NOTES,
    payload: { id },
    meta: { id },
  }))
);

export const saveCampaignNote = compose(
  withCallbacks,
  asCreator(({ note, id }) => ({
    type: CampaignActionTypes.CREATE_CAMPAIGN_NOTE,
    payload: { id, note },
    meta: { id },
  }))
);

export const deleteCampaignNote = compose(
  withCallbacks,
  asCreator(({ id, note_id }) => ({
    type: CampaignActionTypes.DELETE_CAMPAIGN_NOTE,
    payload: { id, note_id },
    meta: { id },
  }))
);

export const setNewCampaign = ({ campaign }) => ({
  type: CampaignActionTypes.SET_NEW_CAMPAIGN,
  payload: { campaign },
});

export const discardNewCampaign = () => ({
  type: CampaignActionTypes.DISCARD_NEW_CAMPAIGN,
});
