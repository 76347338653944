import { Tooltip } from 'antd';

import { by } from '@utils';

import { OperatorSelect } from '@components';

import { Container, Content, Header, Line, Name, RemoveFilterContainer } from './styled';

const OverrideLabels = {
  "nf_notification_id": 'Template',
  "nf_entity_id": 'Workflow version',
  "nf_sub_entity_id": 'Workflow node',
  "nf_event": 'Status',
};

const EventSubFilter = ({ event, value, onChange, onDelete, index, onClearError, errors, errorType, testId, isNodeSettings, isFilterSplit, isStepper, disabled, required }) => {
  const fieldOptions = event?.payload?.map?.(({ field, label, type }) => ({ value: field, label: label, type })) || [];
  const fieldTitle = OverrideLabels[value?.field] || fieldOptions.find(by('value', value.field))?.label;

  const handleDelete = () => {
    if(!disabled && !required){
      onDelete(index);
    }
  };

  const handleValueChange = (value) => {
    onClearError('filter_builder');
    onChange(s => ({ ...s, value: { type: 'scalar', value } }));
  };

  const handleOperatorChange = (operator) => {
    onClearError('filter_builder');
    onChange(s => ({
      ...s,
      operator,
      value: { type: 'scalar', value: '' },
    }));
  };

  const handleGetErrors = (errorType) => {
    if(errorType === 'query') {
      return { value: errors('filter_builder')?.[index]?.errors?.value }
    } else {
      return { value: errors('filter_builder')?.[index]?.value }
    }
  };

  const removeBtn = (
    <RemoveFilterContainer $disabled={disabled || required} data-testid={'event-trigger-subfilter-delete-'} onClick={handleDelete}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10 2.5C14.125 2.5 17.5 5.875 17.5 10C17.5 14.125 14.125 17.5 10 17.5C5.875 17.5 2.5 14.125 2.5 10C2.5 5.875 5.875 2.5 10 2.5ZM10 1.25C5.1875 1.25 1.25 5.1875 1.25 10C1.25 14.8125 5.1875 18.75 10 18.75C14.8125 18.75 18.75 14.8125 18.75 10C18.75 5.1875 14.8125 1.25 10 1.25Z" fill="#F04438"/>
        <path d="M15 9.375H5V10.625H15V9.375Z" fill="#F04438"/>
      </svg>
    </RemoveFilterContainer>
  );

  const SHOW_NUMERIC_FIELDS = ['rf_rfm_id', 'nf_notification_id'];

  return (
    <Container data-testid={testId}>
      <Header>
        <Name>
          {fieldTitle}
        </Name>
        {required ? (
          <Tooltip title="This filter is required">
            {removeBtn}
          </Tooltip>
        ) : removeBtn}
      </Header>
      <Content>
        <OperatorSelect
          changeDisabled={disabled}
          isStepper={isStepper}
          testId={'event-filter'}
          appearance="row"
          smaller
          wrapperStyle={{ width: '100%' }}
          initialType={value.type}
          type={value.type}
          value={{
            operator: value.operator,
            value: value.value?.value || '',
            type: value.type,
            errors: handleGetErrors(errorType),
          }}
          validateDate
          onValueChange={handleValueChange}
          onOperatorChange={handleOperatorChange}
          field={value.field}
          autocomplete={(value.type === 'numeric' && !SHOW_NUMERIC_FIELDS.includes(value.field)) ? undefined : {
            fields: [value.field],
            event: event?.name
          }}
          eventSubFilter={true}
          isNodeSettings={isNodeSettings}
          isFilterSplit={isFilterSplit}
        />
      </Content>
      <Line />
    </Container>
  );
};

export default EventSubFilter;
