export const InformationIconSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M11 1.375C9.09636 1.375 7.23546 1.9395 5.65264 2.99711C4.06982 4.05471 2.83616 5.55793 2.10766 7.31667C1.37917 9.07541 1.18856 11.0107 1.55995 12.8777C1.93133 14.7448 2.84802 16.4598 4.1941 17.8059C5.54018 19.152 7.25519 20.0687 9.12226 20.4401C10.9893 20.8114 12.9246 20.6208 14.6833 19.8923C16.4421 19.1638 17.9453 17.9302 19.0029 16.3474C20.0605 14.7645 20.625 12.9036 20.625 11C20.625 8.44729 19.6109 5.99913 17.8059 4.1941C16.0009 2.38906 13.5527 1.375 11 1.375ZM11 5.5C11.204 5.5 11.4033 5.56048 11.5729 5.6738C11.7425 5.78711 11.8747 5.94817 11.9528 6.13661C12.0308 6.32504 12.0512 6.53239 12.0114 6.73244C11.9716 6.93248 11.8734 7.11623 11.7292 7.26045C11.585 7.40468 11.4012 7.50289 11.2012 7.54269C11.0011 7.58248 10.7938 7.56205 10.6054 7.484C10.4169 7.40595 10.2559 7.27377 10.1426 7.10418C10.0292 6.93459 9.96876 6.73521 9.96876 6.53125C9.96876 6.25775 10.0774 5.99544 10.2708 5.80205C10.4642 5.60865 10.7265 5.5 11 5.5ZM13.75 16.5859H8.25001V15.0391H10.2266V11.0859H8.93751V9.53906H11.7734V15.0391H13.75V16.5859Z'
        fill='#F7C583'
      />
    </svg>
  );
};
