import { WorkflowNodes } from '@constants';

import abtest from './b test.svg';
import bestChannel from './bestChannel.svg'
import card from './CARD.svg'
import contactCreated from './contactCreated.svg';
import email from './E-mail.svg';
import event from './event.svg';
import exclude from './Exclude filter.svg';
import give from './Give reward.svg';
import groupSplit from './Group split.svg';
import campaign from './Include in campaign.svg';
import mobilePush from './Mobile push.svg';
import pushDiscount from './Nodes icons (1).svg'
import changeAttribute from './Nodes icons.svg';
import quick from './Quick filter.svg';
import resource from './resource.svg';
import sms from './SMS.svg';
import teamMessage from './teamMessage.svg';
import viber from './Viber.svg';
import wait from './Wait.svg';
import webhook from './Webhook.svg';
import webPopup from './webPopup.svg';
import webpush from './WebPush.svg';

const NodeIcons = {
  [WorkflowNodes.SEND_EMAIL]: email,
  [WorkflowNodes.RESOURCE]: resource,
  [WorkflowNodes.AB_TEST]: abtest,
  [WorkflowNodes.SEND_SMS]: sms,
  [WorkflowNodes.SEND_CARD]: card,
  [WorkflowNodes.MOBILE_PUSH]: mobilePush,
  [WorkflowNodes.WEBPUSH]: webpush,
  [WorkflowNodes.API_REQUEST]: webhook,
  [WorkflowNodes.VIBER]: viber,
  [WorkflowNodes.WAIT]: wait,
  [WorkflowNodes.QUICK_FILTER]: quick,
  [WorkflowNodes.EXCLUDE_FILTER]: exclude,
  [WorkflowNodes.SWITCH_FILTER]: groupSplit,
  [WorkflowNodes.CUSTOMER_CHANGE]: changeAttribute,
  [WorkflowNodes.PUSH_DISCOUNT]: pushDiscount,
  [WorkflowNodes.GIVE_REWARD]: give,
  [WorkflowNodes.INCLUDE_CAMPAIGN]: campaign,
  [WorkflowNodes.EVENT]: event,
  [WorkflowNodes.BEST_CHANNEL_TO_SEND]: bestChannel,
  [WorkflowNodes.TEAM_MESSAGE]: teamMessage,
  [WorkflowNodes.CONTACT_CREATED]: contactCreated,
  [WorkflowNodes.WEB_POPUP]: webPopup,
};

export default NodeIcons;
