import React from 'react';

import capitalize from 'lodash.capitalize';

import { useTranslation } from '@hooks';

import { by } from '@utils';

import {
  AnalyticsSection
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import {
  SendingSection
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendingSection';
import RewardTypes
  from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/GiveRewardNodeSettings/rewardTypes';

import { Container } from './styled';

const RewardTypeOptions = [
  {
    value: RewardTypes.BONUS,
    label: 'Bonus'
  },
  {
    value: RewardTypes.FREE_SPIN,
    label: 'Free spin'
  },
  {
    value: RewardTypes.MONEY_REWARD,
    label: 'Money reward'
  },
  {
    value: RewardTypes.CASHBACK,
    label: 'Cashback'
  },
  {
    value: RewardTypes.LOYALTY_POINTS,
    label: 'Loyalty points reward'
  },
  {
    value: RewardTypes.TAG,
    label: 'Tag reward'
  },
];

const GiveRewardNodeSettingsInfo = ({ node }) => {
  const { t } = useTranslation();

  if (!node.data.reward_type) {
    return (
      <Container>
        <InfoTitle>{t('labels.selected_reward')}:</InfoTitle>
        {node.data?.reward_type === RewardTypes.CASHBACK ? <InfoText>{node.data?.reward_type}</InfoText> : <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>}
      </Container>
    );
  }

  return (
    <Container>
      <InfoTitle>
        {t('labels.reward_type')}:
      </InfoTitle>
      <InfoText>
        {RewardTypeOptions.find(by('value', node.data?.reward_type))?.label}
      </InfoText>
      {![RewardTypes.LOYALTY_POINTS, RewardTypes.MONEY_REWARD, RewardTypes.CASHBACK].includes(node.data.reward_type) && (
        <>
          <InfoTitle>
            {t('labels.selected_reward')}:
          </InfoTitle>
          <InfoText>
            {node.data?.reward_data?.name}
          </InfoText>
        </>
      )}
      {node.data?.reward_type === RewardTypes.MONEY_REWARD && (node.data?.reward_money_mapping || []).map(({ currency, amount }, index) => (
        <React.Fragment key={currency}>
          <InfoTitle>
            {index + 1}{{ 1: 'st', 2: 'nd', 3: 'rd', ['rest']: 'th' }[index + 1 > 3 ? 'rest' : index + 1]} currency:
          </InfoTitle>
          <InfoText>
            {amount} {currency}
          </InfoText>
        </React.Fragment>
      ))}
      {node.data?.reward_type === RewardTypes.LOYALTY_POINTS && (
        <>
          <InfoTitle style={{ marginTop: 6 }}>
            Action
          </InfoTitle>
          <InfoText>
            {capitalize((node?.data?.reward_action || '').toLowerCase())}
          </InfoText>
          <InfoTitle style={{ marginTop: 6 }}>
            Amount
          </InfoTitle>
          <InfoText>
            {node?.data?.reward_amount}
          </InfoText>
        </>
      )}
      {node.data?.reward_type === RewardTypes.TAG && (
        <>
          <InfoTitle style={{ marginTop: 6 }}>
            Action
          </InfoTitle>
          <InfoText>
            {capitalize(node.data?.tags_action)}
          </InfoText>
          {node.data?.reward_tags.map((tag) => (
            <>
              <InfoTitle style={{ marginTop: 6 }}>
                Tag
              </InfoTitle>
              <InfoText>
                {tag}
              </InfoText>
            </>
          ))}
          <InfoTitle style={{ marginTop: 6 }}>
            Rank
          </InfoTitle>
          <InfoText>
            {node.data.tags_rank}
          </InfoText>
        </>
      )}
      {node.data.sending && <SendingSection statuses={node.data.sending} out={node.data.analytics?.out} />}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
};

export default GiveRewardNodeSettingsInfo;
