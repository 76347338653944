import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px 0;
  //margin: 5px 20px 0 20px;
  max-height: 26px;
  background: #F9FBFF;
  border: 1px solid #BDD1F6;
  border-radius: 10px;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  align-items: center;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 6px;
  padding: 4px;
  cursor: pointer;
`;
