import { clamp } from './functions';

export const createScaleIndicators = (max, n = 5) => {
  const stepApprox = Math.trunc(max / n) || 1;
  const step = stepApprox - stepApprox % Math.pow(10, String(stepApprox).length - 1);
  const steps = Array.from({ length: Math.trunc(max / step) }, (_, i) => step * (i + 1));

  return steps.slice(-n);
};

export const findScaleIndicators = (max, total, n = 4) => {
  let top = clamp(max / total, 0, 100);
  let pow = 1;

  while (top * Math.pow(10, pow) < 5) {
    pow += 1;
  }

  const multiplier = Math.pow(10, pow);

  return createScaleIndicators(top * multiplier, n)
    .map(i => ({
      n: Math.round(i + Number.EPSILON) / multiplier,
      h: i / top / multiplier,
    }));
};
