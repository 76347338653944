import { useTranslation } from '@hooks';

import { Container } from './styled';

const systemEvents = [
  "email",
  "sms",
  "webpush",
  "viber",
  "mobilepush",
  "page_view",
  "session_start",
  "session_first",
  "session_end"
];

const resolveType = (name) => {
  return systemEvents.includes(name) ? 'System' : 'types.custom';
};

const EventType = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {t(resolveType(name))}
    </Container>
  );
};

export default EventType;
