import { ReducerKeys } from '@constants';

import { extract, identity } from '@utils';

import { createSelector } from 'reselect';

export const dashboardSmsSummarySelector = store => store[ReducerKeys.DASHBOARD].smsSummary;

export const dashboardEmailSummarySelector = store => store[ReducerKeys.DASHBOARD].emailSummary;

export const dashboardMobilePushSummarySelector = store => store[ReducerKeys.DASHBOARD].mobilePushSummary;

export const dashboardViberSummarySelector = store => store[ReducerKeys.DASHBOARD].viberSummary;

export const dashboardWebpushSummarySelector = store => store[ReducerKeys.DASHBOARD].webpushSummary;

export const dashboardContentCardSummarySelector = store => store[ReducerKeys.DASHBOARD].cardSummary;

export const dashboardDatabaseEfficiencySelector = store => store[ReducerKeys.DASHBOARD].databaseEfficiency;

export const dashboardGeneralActivityDailySelector = store => store[ReducerKeys.DASHBOARD].generalActivityDaily;

export const dashboardGeneralActivityHourlySelector = store => store[ReducerKeys.DASHBOARD].generalActivityHourly;

export const dashboardDataLoadedSelector = createSelector(
  dashboardSmsSummarySelector,
  dashboardEmailSummarySelector,
  dashboardWebpushSummarySelector,
  dashboardDatabaseEfficiencySelector,
  dashboardGeneralActivityDailySelector,
  dashboardGeneralActivityHourlySelector,
  dashboardContentCardSummarySelector,
  (...data) => data.filter(identity).map(extract('loading')).every((loading) => !loading),
);

export const dashboardSummaryLoadedSelector = createSelector(
  dashboardSmsSummarySelector,
  dashboardEmailSummarySelector,
  dashboardWebpushSummarySelector,
  dashboardMobilePushSummarySelector,
  dashboardViberSummarySelector,
  dashboardContentCardSummarySelector,
  (...data) => data.filter(identity).map(extract('loading')).every((loading) => !loading),
);

export const dashboardAnalyticsEntitiesSelector = store => store[ReducerKeys.DASHBOARD].analyticsEntities;
