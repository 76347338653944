import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { pingApi } from '@store/actions/creators';

import { useNavigate } from '@hooks';

import { Paths } from '@constants';

import { Title, Image, MaintenanceContainer, Paragraph } from './styled.js'

const UnderConstructionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(checkApi, 1000);

    function checkApi () {
      dispatch(pingApi({
        onSuccess: () => {
          clearInterval(interval);
          navigate(Paths.DASHBOARD);
        },
      }));
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <MaintenanceContainer>
      <Image src="https://sdn.retainly.app/maintenance.svg" alt="maintenance" />
      <Title>
         The site is under maintenance
      </Title>
      <Paragraph>
        {/*  eslint-disable-next-line react/no-unescaped-entities */}
        We are always working to improve our service and improve your user experience. Normally, we try to do this in a way that doesn't interfere with your work, so we apologize for the temporary inconvenience
      </Paragraph>
    </MaintenanceContainer>
  );
}

export default UnderConstructionPage;

