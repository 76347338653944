import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { ActionButton, Input, SearchSelect } from '@components';

import { Container, SelectContainer } from './styled';

const UnitOptions = [
  { value: 'minutes', label: 'labels.minutes' },
  { value: 'hours', label: 'labels.hours' },
  { value: 'days', label: 'labels.days' },
  { value: 'weeks', label: 'labels.weeks' },
  { value: 'months', label: 'labels.months' },
  { value: 'years', label: 'labels.years' },
];

const CompletionPeriodSelect = ({ value, onChange, disabled }) => {
  const { p, t } = useTranslation('segments_page');

  const handleAddCompletionPeriod = () => {
    onChange([{ quantity: 0, unit: 'minutes' }, { quantity: 0, unit: 'hours' }]);
  };

  if (!value) {
    return disabled ? (
      <Tooltip title={t('tooltips.select_field_with_date')}>
        <SelectContainer disabled={disabled} data-testid="add-completion-period-disabled">
          + {p('add_completion_period')}
        </SelectContainer>
      </Tooltip>
    ) : (
      <SelectContainer disabled={disabled} onClick={handleAddCompletionPeriod} data-testid="add-completion-period">
        + {p('add_completion_period')}
      </SelectContainer>
    );
  }

  const handleClear = () => {
    onChange(null);
  };

  const handleFromQuantityChange = ({ target: { value } }) => {
    onChange(v => {
      const vv = v ? v : [{ quantity: 0, unit: 'minutes' }, { quantity: 0, unit: 'hours' }];

      return [{ ...vv[0], quantity: value }, vv[1]];
    });
  };

  const handleFromUnitChange = (unit) => {
    onChange(v => {
      const vv = v ? v : [{ quantity: 0, unit: 'minutes' }, { quantity: 0, unit: 'hours' }];

      return [{ ...vv[0], unit }, vv[1]];
    });
  };

  const handleToQuantityChange = ({ target: { value } }) => {
    onChange(v => {
      const vv = v ? v : [{ quantity: 0, unit: 'minutes' }, { quantity: 0, unit: 'hours' }];

      return [vv[0], { ...vv[1], quantity: value }];
    });
  };

  const handleToUnitChange = (unit) => {
    onChange(v => {
      const vv = v ? v : [{ quantity: 0, unit: 'minutes' }, { quantity: 0, unit: 'hours' }];

      return [vv[0], { ...vv[1], unit }];
    });
  };

  return (
    <Container $disabled={disabled}>
      <Tooltip title={p('period_description')}>
        <div style={{ borderBottom: '1px dashed #31C447' }}>
          {t('actions.period_from')}
        </div>
      </Tooltip>
      <Input
        style={{ width: 30, borderRadius: 7, paddingRight: 4, paddingLeft: 4, marginBottom: 0, marginLeft: 10, marginRight: 4, height: 26 }}
        showArrowButtons={false}
        type="number"
        data-testid="completion-period-from-quantity"
        value={value[0].quantity}
        onChange={handleFromQuantityChange}
      />
      <SearchSelect
        options={UnitOptions}
        value={value[0].unit}
        smaller
        testId="completion-period-from-unit"
        style={{ height: 26, width: 100, borderRadius: 7, marginRight: 10 }}
        onChange={handleFromUnitChange}
      />
      to
      <Input
        type="number"
        showArrowButtons={false}
        style={{ width: 30, borderRadius: 7, paddingRight: 4, paddingLeft: 4, marginBottom: 0, marginLeft: 10, marginRight: 4, height: 26 }}
        value={value[1].quantity}
        data-testid="completion-period-to-quantity"
        onChange={handleToQuantityChange}
      />
      <SearchSelect
        options={UnitOptions}
        smaller
        testId="completion-period-to-unit"
        style={{ height: 26, width: 100, borderRadius: 7, marginRight: 10 }}
        value={value[1].unit}
        onChange={handleToUnitChange}
      />
      <ActionButton
        testId={'delete-action'}
        tooltip={t('actions.delete')}
        onClick={handleClear}
        appearance="danger"
        icon="Delete-icon"
      />
    </Container>
  );
};

export default CompletionPeriodSelect;
