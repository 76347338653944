import styled from 'styled-components';

export const Container = styled.div`
`;

export const InfoContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding-top: 14px;
  padding-bottom: 14px;
`;

export const InfoText = styled.div`
  color: ${({ first }) => first ? '#909399' : '#303133' };
  word-break: break-word;
`;

export const InfoTextTitle = styled.div`
  min-width: 150px;
  color: ${({ first }) => first ? '#909399' : '#303133' };
`;

