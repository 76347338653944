import { useSelector } from 'react-redux';

import { loggedInSelector, workspacesListSelector } from '@store/selectors';

import { by, formatFromPHP } from '@utils';

const useDateFormat = () => {
  //@TODO: use reselect
  const user = useSelector(loggedInSelector);
  const workspaces = useSelector(workspacesListSelector);
  const active = workspaces?.find?.(by(user?.app?.id));

  if (!active) {
    return () => '';
  }

  return m => {
    if (!active.date_format) {
      console.warn('Unable to find workspace date format', active);

      return m.toISOString();
    }

    return formatFromPHP(m, active.date_format)
  };
};

export default useDateFormat;
