import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 30px;
  top: 101px;
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope-SemiBold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  ${styledProp('$disabled', `
    cursor: not-allowed;
    color: #7C94B1;
  `)}
`;
