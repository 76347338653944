import { useEffect, useRef, useState } from 'react';

import FileDownload from 'js-file-download';

import { useTimedFlag, useTranslation } from '@hooks';

import { CONTACTS_IMPORT } from '@constants';

import { formatFileSize, notify } from '@utils';

import { Button, Link } from '@components';

import { Container, FileName, Hint, StyledInput } from './styled';

const DEFAULT_MAX_SIZE = 2 * 1024 * 1024;
const DEFAULT_MAX_WIDTH = 10000;
const DEFAULT_MAX_HEIGHT = 10000;
const defaultOptions = {
  maxSize: 2,
  format: 'CSV format',
  extension: 'csv',
  type: 'text',
  accepts: '.csv',
  blob: [CONTACTS_IMPORT],
};

const getFileUrl = (file, isImage) => new Promise(resolve => {
  if (!isImage) {
    resolve('');
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    resolve(reader.result);
  };
})

const validateImageSize = (file, minWidth, minHeight, maxWidth, maxHeight, square) => new Promise((resolve) => {
  let image = document.createElement('img');

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    image.src = reader.result;
    image.onload = () => {
      resolve((image.width >= minWidth && image.height >= minHeight && image.width <= maxWidth && image.height <= maxHeight) && (square ? image.width === image.height : true))
    }
  };
})

const FilePicker = ({
  value,
  onChange,
  style = {},
  maxSize = DEFAULT_MAX_SIZE,
  options = defaultOptions,
  showHint = true,
  webpush,
  minWidth = 0,
  minHeight = 0,
  maxWidth = DEFAULT_MAX_WIDTH,
  maxHeight = DEFAULT_MAX_HEIGHT,
  onboardParams,
  outerUrl,
  ignoreValidation = false,
  isImage = false,
  square = false,
  hasError,
  onRemoveFile = () => {},
}) => {
  const input = useRef(null);
  const [dragEnter, setDragEnter] = useState(false);
  const error = useTimedFlag(5000);
  const [previewSrc, setPreviewSrc] = useState(value || null);
  const { p, t } = useTranslation('templates');

  useEffect(() => {
    if (typeof value === 'string') {
      setPreviewSrc(value)
    }

    if(value?.name) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
          setPreviewSrc(reader.result)
        };
      });
    }
  }, [value])

  useEffect(() => {
    const onDragEnter = () => {
      setDragEnter(true);
    };

    const onDragLeave = () => {
      setDragEnter(false);
    };

    input.current?.addEventListener('dragenter', onDragEnter);
    input.current?.addEventListener('dragleave', onDragLeave);
    input.current?.addEventListener('drop', onDragLeave);

    const ref = input.current;

    return () => {
      ref?.removeEventListener('dragenter', onDragEnter);
      ref?.removeEventListener('dragleave', onDragLeave);
      ref?.removeEventListener('drop', onDragLeave);
    };
  }, []);

  const notifyError = () => {
    notify('error', t('labels.error'), p('file_too_large'))
  };

  const handleChange = async ({ target }) => {
    const file = target.files[0];
    const ext = (file.name || []).split('.').pop();

    if (file.size > maxSize) {
      error.trigger();
      notifyError();
      return onChange(null, '');
    }

    if (ext && options.accepts.indexOf(ext) === -1) {
      error.trigger();
      notifyError();
      return onChange(null, '');
    }

    if (file && isImage && (minWidth || minHeight)) {
      const isSizeValid = await validateImageSize(file, minWidth, minHeight, maxWidth, maxHeight, square)

      if (!isSizeValid && !ignoreValidation) {
        error.trigger();
        notifyError();
        return onChange(null, '');
      }
    }

    const url = await getFileUrl(file, isImage);
    setPreviewSrc(url);

    onChange(file, url);
  };

  const handleRemoveFile = () => {
    onRemoveFile();
  }

  const handleDownloadExample = (e) => {
    e.stopPropagation();
    const file = new Blob(options.blob, { type: `${options.type}/${options.extension}` });
    FileDownload(file, `example.${options.extension}`, `${options.type}/${options.extension}`);
  };

  return (
    <Container src={value ? (previewSrc || outerUrl || '') : (outerUrl || '')} style={{ ...style }} drag={dragEnter} error={error.triggered || hasError}>
      <StyledInput onChange={handleChange} value="" name="file" accept={options.accepts} type="file" ref={input} />
      <FileName error={error.triggered || hasError} white={(webpush && value) || outerUrl}>
        {(error.triggered || hasError) ? (
          <>{p('file_should_be', { format: options.format })}<br />{p('max_size_is', { size: options.maxSize })}</>
        ) : (
          value ? (
            <>
              {value.name}
              <span style={((webpush && value) || outerUrl) ? { color: '#FFFFFF' } : {}}>
                {' ' + formatFileSize(value.size || 0)}
              </span>
            </>
          ) : (typeof outerUrl !== 'object' && outerUrl) || (
            <>
              {p('drag_and_drop_to_start')}
              <br />
              {p('or_choose_on_computer')}
            </>
          )
        )}
      </FileName>
      { onboardParams ? (
        <Button
          width="auto"
          style={{ borderRadius: '5px', height: '26px', fontSize: '12px', paddingLeft: '16px', paddingRight: '16px', pointerEvents: 'none', cursor: 'pointer' }}
        >
          {value ? p('choose_another_file') : p('choose_file')}
        </Button>
      ) : (
        <>
          {(!webpush || (webpush && !value && !outerUrl)) && (
            <Button
              width="auto"
              style={{ borderRadius: '5px', height: '26px', fontSize: '12px', paddingLeft: '16px', paddingRight: '16px', pointerEvents: 'none', cursor: 'pointer', border: onboardParams?.enabled ? '3px solid #BDD1F6' : 'auto' }}
            >
              {value ? p('choose_another_file') : p('choose_file')}
            </Button>
          )}
          {((webpush && value) || outerUrl) && (
            <Button
              width="auto"
              onClick={handleRemoveFile}
              appearance="removeFile"
              style={{ borderRadius: '5px', height: '26px', fontSize: '12px', paddingLeft: '16px', paddingRight: '16px', cursor: 'pointer' }}
            >
              {p('remove_file')}
            </Button>
          )}
        </>
      )}
      {showHint && <Hint><div style={{ display: 'flex' }}>{p('file_should_be_in_csv')} <Link to="#" style={{ zIndex: 10000 }} onClick={handleDownloadExample}>({p('example')})</Link>,</div><div>{p('max_size_is_lower', { size: options.maxSize })}.</div></Hint>}
    </Container>
  );
};

export default FilePicker;
