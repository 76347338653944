import styled from 'styled-components';

import { extract } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  text-transform: capitalize;
`;

export const Indicator = styled.div`
  width: 10px;
  height: 10px;
  background: ${extract('$c')};
  border-radius: 50%;
  margin-right: 6px;
`;
