const PromoCodesActionTypes = {
  GET: 'promo-codes/GET',
  GET_SUCCESS: 'promo-codes/GET_SUCCESS',
  GET_ERROR: 'promo-codes/GET_ERROR',

  GET_EDIT_METADATA: 'promo-codes/GET_EDIT_METADATA',
  GET_EDIT_METADATA_SUCCESS: 'promo-codes/GET_EDIT_METADATA_SUCCESS',
  GET_EDIT_METADATA_ERROR: 'promo-codes/GET_EDIT_METADATA_ERROR',

  PUBLISH: 'promo-codes/PUBLISH',
  PUBLISH_SUCCESS: 'promo-codes/PUBLISH_SUCCESS',
  PUBLISH_ERROR: 'promo-codes/PUBLISH_ERROR',

  DELETE: 'promo-codes/DELETE',
  DELETE_SUCCESS: 'promo-codes/DELETE_SUCCESS',
  DELETE_ERROR: 'promo-codes/DELETE_ERROR',

  SAVE: 'promo-codes/SAVE',
  SAVE_SUCCESS: 'promo-codes/SAVE_SUCCESS',
  SAVE_ERROR: 'promo-codes/SAVE_ERROR',

  ARCHIVE: 'promo-codes/ARCHIVE',
  ARCHIVE_SUCCESS: 'promo-codes/ARCHIVE_SUCCESS',
  ARCHIVE_ERROR: 'promo-codes/ARCHIVE_ERROR',

  UN_ARCHIVE: 'promo-codes/UN_ARCHIVE',
  UN_ARCHIVE_SUCCESS: 'promo-codes/UN_ARCHIVE_SUCCESS',
  UN_ARCHIVE_ERROR: 'promo-codes/UN_ARCHIVE_ERROR',
};

export default PromoCodesActionTypes;

