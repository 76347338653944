import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getEmailSampleData,
  getTemplatePreviewInfo
} from '@store/actions/creators';
import { emailSampleDataSelector } from '@store/selectors';

import { Button } from '@velitech/ui';

import { useTranslation, useSystemOptions } from '@hooks';

import { UserPermissions } from '@constants';

import { decompress } from '@utils/ungzip';

import { Icon, Modal, WithPermissions } from '@components';

import { Container, Header, Name, Tab, PreviewWrapper, PreviewIframe, IconContainer } from './styled';

const DESKTOP_MODE = 'desktop';
const MOBILE_MODE = 'mobile';

const ViewEmailTemplateModal = ({ opened, onClose, name, html, workflows, onActionClick, isExample }) => {
  const [mode, setMode] = useState(DESKTOP_MODE);
  const dispatch = useDispatch();
  const [htmlValue, setHtmlValue] = useState(html);
  const { id: sampleContactId } = useSelector(emailSampleDataSelector);
  const { t, p } = useTranslation('notification_history');
  const { requestOptions } = useSystemOptions();

  useEffect(() => {
    setHtmlValue(html);
  }, [html]);

  useEffect(() => {
    dispatch(getEmailSampleData());
    requestOptions();
  }, []);

  useEffect(() => {
    if (opened) {
      dispatch(getTemplatePreviewInfo({
        fields: {
          data: html,
        },
        customer_id: sampleContactId,
        onSuccess: (d) => setHtmlValue(d.templates.data),
      }))
    }
  }, [opened]);

  return (
    <Modal
      appearance="default"
      title={p('preview', { name })}
      contentStyles={{ padding: '20px', paddingTop: '14px', maxHeight: '60vh' }}
      style={{ maxWidth: mode === DESKTOP_MODE ? '900px' : undefined }}
      opened={opened}
      onClose={onClose}
      actions={
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingTop: 12 }}>
          <Button onClick={onClose} variant="secondary" style={{ width: '160px' }}>
            {t('actions.close')}
          </Button>
          <WithPermissions name={isExample ? UserPermissions.CREATE_EMAILS : UserPermissions.UPDATE_EMAILS}>
            <Button disabled={!isExample && workflows?.used === 1} onClick={onActionClick} style={{ width: '160px' }}>
              {isExample ? t('actions.duplicate') : t('actions.edit')}
            </Button>
          </WithPermissions>
        </div>
      }
    >
      <Container>
        <Header>
          <Name>{name}</Name>
          <Tab>
            <IconContainer
              onClick={() => setMode(DESKTOP_MODE)}
              isActive={mode === DESKTOP_MODE}
              isFirst={true}
            >
              <Icon color={mode === DESKTOP_MODE ? '#303133' : '#909399'} name={'Preview-desktop'} />
            </IconContainer>
            <IconContainer
              onClick={() => setMode(MOBILE_MODE)}
              isActive={mode === MOBILE_MODE}
            >
              <Icon color={mode === MOBILE_MODE ? '#303133' : '#909399'} name={'Preview-mobile'} />
            </IconContainer>
          </Tab>
        </Header>

        <PreviewWrapper isMobile={mode === MOBILE_MODE}>
          <PreviewIframe srcDoc={decompress(htmlValue)} />
        </PreviewWrapper>
      </Container>
    </Modal>
  );
};

export default ViewEmailTemplateModal;
