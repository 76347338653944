import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
`;

export const ActionsRow = styled.div`
  display: flex;

  & > *:first-child {
    margin-right: 6px;
  }
`;

export const Tag = styled.div`
  border-radius: var(--badges-square-radius, 4px);
  background: var(--Colors-Neutral-opacity-medium, rgba(124, 148, 177, 0.12));
  color: var(--Colour-Neutral-500p, var(--Colors-Neutral-500p, #7C94B1));
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  align-items: center;
  display: flex;

  padding: 1px var(--badges-small-padding, 4px);

  ${styledProp('$green', css`
    background: var(--badges-bg-ghost-branded, rgba(49, 196, 71, 0.12));
    color: var(--badges-txt-ghost-branded, #31C447);
  `)}
`;

export const Tags = styled.div`
    display: flex;
    gap: 6px;
`;

export const FieldTypeLabel = styled.div`
    color: var(--text-secondary-body, #7C94B1);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 11px;
    line-height: 16px;
    width: 60px;
`;

export const FieldLabel = styled.div`
    color: var(--controller-cntr-text, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;
`;

export const FieldLabelRow = styled.div`
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
