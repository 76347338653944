const FunnelsActionTypes = {
  LIST: 'funnels/LIST',
  LIST_SUCCESS: 'funnels/LIST_SUCCESS',
  LIST_ERROR: 'funnels/LIST_ERROR',

  STORE: 'funnels/STORE',
  STORE_SUCCESS: 'funnels/STORE_SUCCESS',
  STORE_ERROR: 'funnels/STORE_ERROR',

  GET: 'funnels/GET',
  GET_SUCCESS: 'funnels/GET_SUCCESS',
  GET_ERROR: 'funnels/GET_ERROR',

  PREVIEW: 'funnels/PREVIEW',
  PREVIEW_SUCCESS: 'funnels/PREVIEW_SUCCESS',
  PREVIEW_ERROR: 'funnels/PREVIEW_ERROR',
  CLEAR_PREVIEW: 'funnels/CLEAR_PREVIEW',

  EXPORT_CUSTOMERS: 'funnels/EXPORT_CUSTOMERS',
  EXPORT_CUSTOMERS_SUCCESS: 'funnels/EXPORT_CUSTOMERS_SUCCESS',
  EXPORT_CUSTOMERS_ERROR: 'funnels/EXPORT_CUSTOMERS_ERROR',

  ARCHIVE: 'funnels/ARCHIVE',
  ARCHIVE_SUCCESS: 'funnels/ARCHIVE_SUCCESS',
  ARCHIVE_ERROR: 'funnels/ARCHIVE_ERROR',

  UNARCHIVE: 'funnels/UNARCHIVE',
  UNARCHIVE_SUCCESS: 'funnels/UNARCHIVE_SUCCESS',
  UNARCHIVE_ERROR: 'funnels/UNARCHIVE_ERROR',

  DELETE: 'funnel/DELETE',
  DELETE_SUCCESS: 'funnel/DELETE_SUCCESS',
  DELETE_ERROR: 'funnel/DELETE_ERROR',
};

export default FunnelsActionTypes;
