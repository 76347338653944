import uniq from 'lodash.uniq';
import uniqBy from 'lodash.uniqby';

import { by, compose, extract } from '@utils';

import { CheckboxNode } from './components';

const childrenIds = tree => {
  const out = [];

  const populate = (tree, index) => {
    if (!tree.children?.length || index !== undefined) {
      out.push(tree.id);
    }

    tree.children.forEach(populate);
  };
  populate(tree);

  return out;
};

const CheckboxTree = ({ tree, value, onChange, roleDisabled }) => {
  const find = (tree, checkFn) => {
    if (checkFn(tree)) {
      return tree;
    }

    return tree.children.map(child => find(child, checkFn)).find(v => !!v);
  };

  const getLeaves = (t) => {
    if (!t.children?.length) {
      return [t];
    }

    return t.children.flatMap(getLeaves);
  };

  const updateParents = (value) => {
    const updated = [...value];

    const checkTree = (t, v) => {
      const checked = !!~v.indexOf(t.id);
      const shouldBeChecked =
        (t.children.length && t.children.some(child => !!~v.indexOf(child.id)))
        || (checked && !t.children.length);

      if (checked && !shouldBeChecked) {
        return 'delete';
      }
      if (!checked && shouldBeChecked) {
        return 'insert';
      }

      return 'remain';
    }

    const updateUp = (leaf, out) => {
      const parent = find(tree, by(leaf.parent_id));

      if (!parent) {
        return;
      }

      const operation = checkTree(parent, out);

      if (operation === 'insert') {
        out.push(parent.id);
      }

      if (operation === 'delete') {
        out.splice(out.indexOf(parent.id), 1);
      }

      updateUp(parent, out);
    };

    const uniqParentLeaves = uniqBy(getLeaves(tree), extract('parent_id'));
    uniqParentLeaves.forEach(leaf => updateUp(leaf, updated));

    return updated;
  };

  const handleChange = compose(onChange, updateParents);

  const handleCheckboxChange = (t) => {
    if (t.children) {
      const ids = childrenIds(t);

      if (ids.every(id => value.indexOf(id) !== -1)) {
        return handleChange(value.filter(id => id !== t.id && !ids.some(cid => cid === id)));
      }

      return handleChange(uniq([...value, t.id, ...ids]));
    }

    if (value.indexOf(t.id) !== -1) {
      return handleChange(value.filter(id => id !== t.id));
    }

    return handleChange(uniq([...value, t.id]));
  };

  return (
    <CheckboxNode roleDisabled={roleDisabled} value={value} tree={tree} onChange={handleCheckboxChange} />
  )
};

export default CheckboxTree;
