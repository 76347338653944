import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  background: transparent;
  border: 1px solid #7C8C9E;
  border-radius: 12px;
  transition: all 330ms ease;
  position: relative;
  
  &:focus-within {
    //border-color: #fff;

    & > label {
      //color: #fff;
    }

    ${styledProp('hasError', css`
      border-color: #DC5F4E;
      
      & > input {
        color: #DC5F4E;
      }

      & > label {
        color: #DC5F4E;
      }
    `)}
  }
  
  ${styledProp('hasError', css`
    border-color: #DC5F4E;

    & > input {
      color: #DC5F4E;
    }

    & > label {
      color: #DC5F4E;
    }
  `)}
`;

export const Title = styled.label`
  position: absolute;
  font-size: 11px;
  line-height: 140%;
  color: #7C8C9E;
  padding: 0 3px;
  //background: #1F1F24;
  //top: -7px;
  top: -23px;
  //left: 13px;
  left: 0;
  transition: all 330ms ease;
  
  ${styledProp('black', css`
    color: #303133;
    font-size: 15px;
  `)}
`;

export const HTMLInput = styled.input`
  font-size: 16px;
  line-height: 140%;
  //color: #fff;
  width: 100%;
  height: 22px;
  transition: all 230ms ease;
  border: none;
  border-radius: 0;
  overflow: visible;
  outline: none;
  background-color: transparent !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 1s ease-in-out 50000s, color 1s ease-in-out 50000s !important;
    transition-delay: 5000s !important;
    -webkit-box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    outline: none !important;
    background-color: transparent !important;
    font-family: Manrope-SemiBold;
    color: #fff !important;
    font-size: 16px !important;
    
    
  }

  &:-webkit-autofill {
    ${({ autoComplete }) => ((() => {
    console.log(autoComplete); return true; })()) && (autoComplete === 'off' || autoComplete === 'new-password') && css`
      //display: none !important;
      //opacity: 0 !important;
      //transition: opacity 1s ease-in-out 50000s;
      //-webkit-box-shadow: 0 0 0 30px white inset !important;
    `}
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 16px;
    line-height: 140%;
    color: #3F3F46;
  }
`;

export const Error = styled.div`
  color: #DC5F4E;
  top: 52px;
  left: 4px;
  font-size: 11px;
  position: absolute;
  line-height: 140%;
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
