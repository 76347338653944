import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const StyledTemplateCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const TitleContainer = styled.div`
  text-align: center;
  font-size: 14px;
  font-family: Manrope-Medium;

  &:hover{
    cursor: pointer;
  }
`;

export const CardContentContainer = styled.div`
  background-color: #FFFFFF;
  position: relative;
  width: 170px;
  height: 170px;
  
  border-radius: 10px;
  border: 1px solid #DCDFE6;
  overflow: hidden;

  ${styledProp('$isSelected', css`
    border: 1px solid #D3DBE7 !important;

  `, css`
      &:hover {
          cursor: pointer;
          border: 2px solid #31C447 !important;
      }
  `)}

  ${switchStyle('$previewType', {
    'preview-3-column': css`
      width: 152px;
      height: 152px;
    `,
   
    [undefined]: `
    `
  })}
  
  transition: all 330ms ease;
  
`;

const COEF = 168 / 420;

export const EmailPreview = styled.iframe`
    position: absolute;
    width: 420px;
    height: 420px;
    transform: scale(${COEF}) translate(-315px, -315px);
    top: 0;
    left: 0;
    border: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    overflow: visible;
`;

export const SmsPreview = styled.div`
    color: var(--Colors-Neutral-600, #5F728A);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope-Medium;
    font-size: 11px;
    line-height: 16px;
  padding: 10px;
  border-radius: 10px;
  pointer-events: none;
`;

export const ViberImg = styled.img`
  width: 100%;
`;

export const ViberPreview = styled.div`
  position: absolute;
  padding: 14px 0px 4px 0px;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  line-height: 120%;
  height: 100%;
  background: #EFEFEF;
  border-radius: 8px 8px 0 0;
`;

export const ViberPreviewInner = styled.div`
  margin-bottom: 17px;
  padding: 0 14px;
`;
export const WebhookPreview = styled.div`
  padding: 10px 10px 0 10px;
    color: #A1B3C8;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope-Medium;
    font-size: 8.25px;
    line-height: 12px;
    background: #263238;
    height: 168px;
`;
export const WebpushPreview = styled.div`
  
`;

export const MobpushPreview = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobpushInnerTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 15px;
`;
export const WebpushInnerTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: start;
  padding: 15px;
`;
export const PushContent = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MobTitle = styled.div`
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-variant: tabular-nums;
  font-weight: 500;
  line-height: 1.5715;
  font-size: 11px;
  max-width: 75px;
`;
export const MobMessage = styled.div`
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-size: 11px;
  max-width: 120px;
`;
export const Time = styled.div`
  position: absolute;
  right: 5px;
  top: 16px;
  font-size: 7px;
  line-height: 130%;
  color: #909399;
`;

export const SubTitle = styled.div`
  font-family: Helvetica;
  font-size: 8px;
  color: #838383;
`;

export const ContentCardPreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;