const WorkflowOptionsActionTypes = {
  GET: 'workflow-options/GET',
  GET_SUCCESS: 'workflow-options/GET_SUCCESS',
  GET_ERROR: 'workflow-options/GET_ERROR',

  PREVIEW: 'workflow-options/PREVIEW',
  PREVIEW_SUCCESS: 'workflow-options/PREVIEW_SUCCESS',
  PREVIEW_ERROR: 'workflow-options/PREVIEW_ERROR',
};

export default WorkflowOptionsActionTypes;
