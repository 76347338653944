import { SubscriptionGroupsActionTypes } from '@store/actions/types';

const initialState = {
  defaultGroups: {
    loading: false,
    data: [],
  },
  customGroups: {
    loading: false,
    data: [],
  },
  groupsInGlobal: {
    loading: false,
    data: [],
  },
  contacts: {
    loading: false,
    data: [],
  },
  edit: {
    loading: false,
    data: {},
  },
  counts: {
    loading: false
  }
};

const subscriptionGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionGroupsActionTypes.GET_GROUPS_CUSTOMER_COUNT:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: true,
        },
      };
    case SubscriptionGroupsActionTypes.GET_GROUPS_CUSTOMER_COUNT_SUCCESS:
      return {
        ...state,
        counts: {
          ...state.counts,
          [action.meta.subscribeId]: action.payload.total,
          loading: false
        }
      }
    case SubscriptionGroupsActionTypes.GET_GROUPS_CUSTOMER_COUNT_ERROR:
      return {
        ...state,
        counts: {
          ...state.counts,
          loading: false,
        },
      }
    case SubscriptionGroupsActionTypes.GET_DEFAULT:
      return {
        ...state,
        defaultGroups: {
          ...state.defaultGroups,
          loading: true,
        },
      };
    case SubscriptionGroupsActionTypes.GET_DEFAULT_SUCCESS:
      return {
        ...state,
        defaultGroups: {
          ...state.defaultGroups,
          ...action.payload,
          loading: false,
        },
      };
    case SubscriptionGroupsActionTypes.GET_DEFAULT_ERROR:
      return {
        ...state,
        defaultGroups: {
          ...state.defaultGroups,
          loading: false,
        },
      };
    case SubscriptionGroupsActionTypes.GET_CUSTOM:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          loading: true,
        },
      };
    case SubscriptionGroupsActionTypes.GET_CUSTOM_SUCCESS:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          ...action.payload,
          data: Array.isArray(action.payload.data) ? action.payload.data : Object.entries(action.payload.data).filter(([key]) => !isNaN(+key)).map(([, value]) => value),
          loading: false,
        },
      };
    case SubscriptionGroupsActionTypes.GET_CUSTOM_ERROR:
      return {
        ...state,
        customGroups: {
          ...state.customGroups,
          loading: false,
        },
      };
    case SubscriptionGroupsActionTypes.GET_GROUP_CONTACTS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
        },
      };
    case SubscriptionGroupsActionTypes.GET_GROUP_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          data: action.payload,
        },
      };
    case SubscriptionGroupsActionTypes.GET_GROUP_CONTACTS_ERROR:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
        },
      };
    case SubscriptionGroupsActionTypes.GET:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: true,
        },
      };
    case SubscriptionGroupsActionTypes.GET_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          ...action.payload,
          loading: false,
        },
      };
    case SubscriptionGroupsActionTypes.GET_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
        },
      };
    case SubscriptionGroupsActionTypes.GET_GROUPS_IN_GLOBAL:
      return {
        ...state,
        groupsInGlobal: {
          ...state.groupsInGlobal,
          loading: true,
        },
      };
    case SubscriptionGroupsActionTypes.GET_GROUPS_IN_GLOBAL_SUCCESS:
      return {
        ...state,
        groupsInGlobal: {
          ...state.groupsInGlobal,
          ...action.payload,
          loading: false,
        },
      };
    case SubscriptionGroupsActionTypes.GET_GROUPS_IN_GLOBAL_ERROR:
      return {
        ...state,
        groupsInGlobal: {
          ...state.groupsInGlobal,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default subscriptionGroupsReducer;
