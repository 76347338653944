import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';

import { WorkflowNodes } from '@constants';

import { by, extract, identity, update } from '@utils';

export const omitUnused = (root) => {
  return omit({
    ...root,
    data: Object.fromEntries(Object.entries(omit({
      ...root.data,
      aggregates: root.data?.query?.aggregates?.map?.(aggregate => ({
        ...aggregate,
        errors: null,
      })),
      query: {
        ...(root.data?.query || {}),
        errors: null,
        children: root.data?.query?.children?.map?.(child => ({
          ...child,
          query: {
            ...child.query,
            errors: null,
            value: {
              ...(root.data?.query?.value || {}),
              value: root.data?.query?.value?.value || '',
            },
          }
        })),
      },
    }, ['id', 'originId', 'serverId', 'renderId'])).filter(([, value]) => value !== '' && value !== null && value !== undefined)),
  }, ['id', 'sourcePosition', 'targetPosition', 'children']);
};

export const notEnd = ({ type }) => type !== WorkflowNodes.END_PATH;
export const notSplitter = ({ type }) => type !== 'ab';
export const isSplitter = ({ type }) => type === 'ab';
export const excludeInclude = (child) => child?.type === 'exclude' || child?.type === 'include';

const childrenEquals = (r1, r2) => {
  const r1Children = r1?.children?.flatMap(n => isSplitter(n) ? n.children : [n]).filter(notEnd) || [];
  const r2Children = r2?.children?.flatMap(n => isSplitter(n) ? n.children : [n]).filter(notEnd) || [];

  if (!r1Children.length || !r2Children.length) {
    return r1Children.length === r2Children.length;
  }

  return r1Children.every(child => {
    return treeMapEquals(
      child,
      r2Children.find(by(child?.data?.id)) ||
      r2Children.find(by(child?.data?.serverId))
    );
  });
};

export const treeMapEquals = (r1, r2) => {
  if (!r1 || !r2) {
    if ([r1, r2].some(excludeInclude)) {
      return true;
    }
    return !!r1 === !!r2;
  }

  if (!isEqual(omitUnused(r1), omitUnused(r2))) {
    return false;
  }

  const r1ChildrenCount = (r1?.children?.flatMap(n => isSplitter(n) ? n.children : [n]).filter(notEnd) || []).length;
  const r2ChildrenCount = (r2?.children?.flatMap(n => isSplitter(n) ? n.children : [n]).filter(notEnd) || []).length;

  if (r1ChildrenCount !== r2ChildrenCount) {
    return false;
  }

  return childrenEquals(r1, r2);
};

export const isConnectorsValid = (root, styles) => {
  const check = (node) => {
    const hasInvalidConnector = (node.children || []).some(child => styles[child.id]?.connectorRemoved?.includes(node.id));
    if (hasInvalidConnector) {
      return false;
    }
    return node.children.every(check);
  };

  return !root || check(root);
};

export const createMultinodeUpdater = (value, onChange, errors, onClearError) => {
  const values = (value.children || []).map(extract('data'));

  const changeNode = changeAt => updater => {
    onChange?.(s => ({
      ...s,
      children: (s.children || []).map((c, i) => ({
        ...c,
        data: i === changeAt ? update(c.data, updater) : c.data,
      })),
    }));
  };

  const addNode = (node, updater = identity) => {
    onChange(s => ({
      ...s,
      children: [...s.children, {
        ...node,
        data: update(node.data, updater),
      }],
    }));
  };

  const deleteNode = (deleteAt) => {
    onChange(s => ({
      ...s,
      children: (s.children || []).filter((_, i) => i !== deleteAt),
    }));
  };

  const getError = at => key => {
    return errors[`mn_${at}_${key}`];
  };

  const clearError = at => key => {
    return onClearError(`mn_${at}_${key}`);
  };

  return {
    values,
    changeNode,
    addNode,
    deleteNode,
    getError,
    clearError,
  };
};
