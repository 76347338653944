import { useState } from 'react';

const useTransition = (initialShown = false) => {
  const [shown, setShown] = useState(initialShown);

  return {
    shown,
    show: () => setShown(true),
    hide: () => setShown(false),
  }
};

export default useTransition;
