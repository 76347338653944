import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { Dropdown, Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { by, extract, sequence, resolveDropdownFieldType, renderProp } from '@utils';

import { Icon } from '@components';

import {
  Overlay,
  Container,
  Option,
  FooterContainer,
  IconContainer,
  TitleContainer,
  InputContainer,
  LabelRow,
  Label,
  GroupTitleRow,
  InfoIconContainer,
  GroupTitle,
  Error,
  Suffix,
} from './styled';

const Select = forwardRef(({
  testId,
  value,
  title,
  label,
  disabled = false,
  options,
  onChange,
  customValue,
  footerOption,
  onScrollDown,
  onFooterOptionClick,
  optionGroups,
  absoluteError,
  footerStyles = {},
  getPopupContainer = () => document.body,
  wrapperStyles,
  tooltip,
  tooltipColor,
  page,
  transparentLabel= false,
  showInfoIcon = false,
  noData = null,
  error,
  valueKey,
  smaller = false,
  containerStyle,
  errorStyles = {},
  tooltipError,
  onClear,
  showTypes = false,
  customOverlay,
  hiddenOverlay = false,
  dropdownProps = {},
  overlayStyles = {},
  menuClassName,
  filterOptions = () => true,
  ...props
}, ref) => {
  const { t, e, p } = useTranslation(page);
  const [opened, setOpened] = useState(false);
  const hidden = useRef(null);

  useImperativeHandle(ref, () => ({
    onClose() {
      setOpened(false);
    },
    open() {
      setOpened(true);
    }
  }));

  const handleChange = (v, g, option) => () => {
    onChange(v, g, option);
    setOpened(false);
  };

  const renderOverlay = () => {
    if (customOverlay) {
      return renderProp(customOverlay, { opened, setOpened });
    }

    if (!options?.length && !optionGroups?.length) {
      return (
        <Overlay>
          {noData}
          {footerOption && (
            <FooterContainer style={footerStyles} onClick={sequence(onFooterOptionClick, (e) => e.stopPropagation())}>
              {footerOption}
            </FooterContainer>
          )}
        </Overlay>
      );
    }

    const handleScroll = (e) => {
      if (e.currentTarget.scrollTop - (e.currentTarget.scrollHeight - e.currentTarget.offsetHeight) < 10 && e.currentTarget.scrollTop - (e.currentTarget.scrollHeight - e.currentTarget.offsetHeight) >= 0) {
        onScrollDown?.();
      }
    }

    if (options) {
      return (
        <Overlay style={overlayStyles} onScroll={handleScroll} className="show-scrollbar" data-testid={opened ? 'select-opened' : 'select-closed'}>
          <div style={{ flex: 1 }} className={menuClassName}>
            {options.filter(filterOptions).filter(({ hide }) => !hide).map(({ label, value: curr, prefix, ...other }) => (
              <Option key={`${curr}-${other.type}-${label}-${prefix}`} onClick={handleChange(curr, null, { label, value: curr, prefix, ...other })} data-testid={`select-value-${curr}`} data-test-label={label}>
                <span className="prefix">{prefix ? `${t(prefix)}.` : ''}</span>{t(label)}{showTypes ? <Suffix>{resolveDropdownFieldType(prefix, other.type, p)}</Suffix> : null}
              </Option>
            ))}
          </div>
          {footerOption && (
            <FooterContainer style={footerStyles} onClick={sequence(onFooterOptionClick, (e) => e.stopPropagation())}>
              {footerOption}
            </FooterContainer>
          )}
        </Overlay>
      );
    }

    return (
      <>
        <Overlay className={menuClassName}>
          {optionGroups.map(({ title, name, options }) => options.filter(({ hide }) => !hide).length > 0 ? (
            <>
              <GroupTitleRow>
                <GroupTitle data-testid={`${testId}-option-group-${name}`}>{title}</GroupTitle>
              </GroupTitleRow>
              <div>
                {options.filter(({ hide }) => !hide).map(({ label, value: curr, prefix, ...other }) => (
                  <Option key={`${curr}-${other.type}-${label}-${prefix}`} onClick={handleChange(curr, name, { label, value: curr, prefix, ...other })} data-testid={`${testId}-option-${name}-${curr}`}>
                    {prefix ? `${t(prefix)}.` : ''}{t(label)}
                  </Option>
                ))}
              </div>
            </>
          ) : null)}
          {footerOption && <FooterContainer style={footerStyles} onClick={onFooterOptionClick}>{footerOption}</FooterContainer>}
        </Overlay>
      </>
    );
  };

  const resolveTitle = () => {
    if (customValue) {
      return customValue;
    }

    if (options) {
      let option = options.map(({ value, label, prefix }) => ({ value: String(value), label, prefix })).find(by('value', String(value)));

      if (valueKey) {
        option = options.map(({ value, label, prefix, key }) => ({ value: String(value), label, prefix, key })).find(by('key', String(valueKey)))
      }

      if (option?.prefix) {
        return <><span className="prefix">{option?.prefix}.</span>{t(option?.label)}</>
      }

      return t(option?.label);
    }

    const option = (optionGroups || []).flatMap(extract('options')).map(({ value, label, prefix }) => ({ value: String(value), label, prefix })).find(by('value', String(value)));

    if (option?.prefix) {
      return <><span className="prefix">{option?.prefix}.</span>{t(option?.label)}</>
    }

    return t(option?.label);
  };

  const isPlaceholder = !resolveTitle();

  const handleVisibilityChange = (v) => {
    if (!hiddenOverlay) {
      setOpened(v);
    }
  };

  return (
    <div style={{ ...(wrapperStyles || {}), position: 'relative' }} data-testid={`${testId}-container`}>
      <Container style={containerStyle}>
        {label && (tooltip ? (
          <Tooltip title={tooltip} placement="topLeft">
            <LabelRow>
              <Label transparentLabel={transparentLabel}>{t(label)}</Label>
              {showInfoIcon && <InfoIconContainer><Icon color={tooltipColor || '#909399'} name="Info-icon" size={10} /></InfoIconContainer>}
            </LabelRow>
          </Tooltip>
        ) : (
          <LabelRow>
            <Label transparentLabel={transparentLabel}>{t(label)}</Label>
            {showInfoIcon && <InfoIconContainer><Icon color={tooltipColor || '#909399'} name="Info-icon" size={10} /></InfoIconContainer>}
          </LabelRow>
        ))}
        <Dropdown
          overlayClassName={`${testId}-dropdown`}
          {...dropdownProps}
          disabled={disabled}
          trigger={['click']}
          open={opened}
          getPopupContainer={getPopupContainer}
          onOpenChange={v => handleVisibilityChange(v)}
          placement="bottomLeft"
          overlay={renderOverlay()}
        >
          <InputContainer smaller={smaller} hasError={!!error || !!tooltipError} disabled={disabled} {...props}>
            <TitleContainer
              isPlaceholder={isPlaceholder}
              data-testid={`select-value-${resolveTitle()}`}
              value={value || customValue || title}
              disabled={disabled}
              smaller={smaller}
            >
              {isPlaceholder ? (title || t('labels.select')) : resolveTitle()}
            </TitleContainer>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <IconContainer opened={opened} clearEnabled={onClear && (value || customValue)} visible style={{ pointerEvents: 'none' }}>
                <Icon style={{ height: '6px', lineHeight: '6px', pointerEvents: 'none' }} name="Dropdown-arrow" size={10} />
              </IconContainer>
              {!!onClear && (
                <IconContainer clear visible={value || customValue} onClick={onClear}>
                  <Icon name="Clear" size={14}/>
                </IconContainer>
              )}
              {tooltipError && (
                <Tooltip title={e(tooltipError)} placement="bottom">
                  <svg style={{ marginLeft: '6px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7" r="7" fill="#C8402F"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.78412 7.79622C7.78412 8.24339 7.4203 8.60721 6.97313 8.60721C6.52596 8.60721 6.16214 8.24339 6.16214 7.79622V4.00002C6.16214 3.55284 6.52596 3.18903 6.97313 3.18903C7.4203 3.18903 7.78412 3.55284 7.78412 4.00002V7.79622ZM7.78213 9.959C7.78213 10.4062 7.41832 10.77 6.97115 10.77C6.52398 10.77 6.16016 10.4062 6.16016 9.959C6.16016 9.51183 6.52398 9.14801 6.97115 9.14801C7.41832 9.14801 7.78213 9.51183 7.78213 9.959Z" fill="white"/>
                  </svg>
                </Tooltip>
              )}
            </div>
          </InputContainer>
        </Dropdown>
        <div ref={hidden} />
      </Container>
      {error && <Error style={{ ...(absoluteError ? { position: 'absolute', top: '42px', } : {}), ...errorStyles }}>{e(error)}</Error>}
    </div>
  );
});

export default Select;
