import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 1px solid #D3DBE7;
    bottom: -2px;
  }

 .tabs-container {
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-family: 'Manrope-SemiBold';
 }


.tab {
    &.error {
      --vl-tab-color: #F04438;
    }
    cursor: pointer;
    &:hover {
      .tab-item:not(.active):not(.error) {
        color: var(--tabs-text-hover);
      }
    }
    .active  {
      color: #31C447;
    }

    .error {
      color: #F04438;
    }
  }
`;

export const Content = styled.div`
  padding: 0 0 20px 0;  
  min-height: 360px;
`;

export const RateList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;