import styled, { css } from 'styled-components';

import { SegmentsRuleTypes } from '@constants';

import { switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Image = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;

  ${switchStyle('$type', {
    [SegmentsRuleTypes.LINEAR]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #109dfb 5.92%, #0c74ef 94.84%);
      box-shadow: 0px 6.25px 14.063px 0px rgba(16, 157, 251, 0.4);
    `,
    [SegmentsRuleTypes.ARRAY]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #945dbd 5.92%, #7c44a6 94.84%);
      box-shadow: 0px 6.25px 14.062px 0px rgba(124, 82, 156, 0.4);
    `,
    [SegmentsRuleTypes.AGGREGATE]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #f4b53c 5.92%, #e3a328 94.84%);
      box-shadow: 0px 6.25px 14.063px 0px rgba(244, 165, 60, 0.4);
    `,
    [SegmentsRuleTypes.EVENT]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #2de0e0 5.92%, #0bbaba 94.84%);
      box-shadow: 0px 6.25px 14.063px 0px rgba(56, 206, 206, 0.4);
    `,
    [SegmentsRuleTypes.RFM_SEGMENT]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #64ecb1 5.92%, #50d49a 94.84%);
      box-shadow: 0px 8px 18px 0px rgba(93, 190, 147, 0.4);
    `,
    segment: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #64ecb1 5.92%, #50d49a 94.84%);
      box-shadow: 0px 8px 18px 0px rgba(93, 190, 147, 0.4);
    `,
  })}
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1a232e);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 14px;
  line-height: 20px;
`;
