import { identity } from '@utils';

import { Container, Tab } from './styled';

const SliderTabs = ({ tabs, tab, onTabChange, tabStyle = {}, localize = identity, ...props }) => {

  const handleSelect = name => () => {
    onTabChange(name);
  };

  const l = localize;

  return (
    <Container {...props}>
      {tabs.map(({ name, label }, index, arr) => (
        <Tab
          key={index}
          style={{ marginRight: index !== arr.length - 1 ? '8px' : '0px', ...(tabStyle || {}) }}
          selected={tab === name}
          data-testid={`slider-tabs-${name}`}
          onClick={handleSelect(name)}
        >
          {l(label)}
        </Tab>
      ))}
    </Container>
  );
};

export default SliderTabs;
