import { useRef, useState } from 'react';

import { ActionButton } from '@components';

import { Container, Input } from './styled';

const EditableTitle = ({ testId, value, onChange, disabled, onEditClick, onFocus, size, smaller, onBlur, medium, containerStyle, ...props }) => {
  const input = useRef(null);
  const [focused, setFocused] = useState(false);

  const handleBlur = () => {
    onBlur?.();
    setFocused(false);
  };

  const handleFocus = () => {
    onFocus?.();
    setFocused(true);
  };

  const handleFocusClick = (e) => {
    if (onEditClick) {
      return onEditClick(e);
    }

    input.current?.focus();
    input.current?.setSelectionRange(0, value?.length);
  };

  return (
    <Container style={containerStyle}>
      <Input
        data-testid={testId}
        {...props}
        disabled={disabled}
        smaller={smaller}
        medium={medium}
        ref={input}
        style={{ width: `${(value?.length > 49 ? 50 : value?.length) * (medium ? 0.77 : 0.92) + 0.5}ch`, ...(props.style || {}) }}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {!disabled && (focused ? (
        <ActionButton
          key="focus"
          size={size || (smaller ? 16 : medium ? 20 : 22)}
          iconSize={size ? size - 4 : (medium && 16)}
          iconColor="#909399"
          style={medium ? { background: 'transparent', border: 'none' } : (props.iconStyle || {})}
          icon={'Checkbox-check'}
        />
      ) : (
        <ActionButton
          key="blur"
          size={size || (smaller ? 16 : medium ? 20 : 22)}
          iconSize={size ? size - 4 : (medium && 16)}
          iconColor="#909399"
          style={medium ? { background: 'transparent', border: 'none' } : (props.iconStyle || {})}
          icon={'Edit-icon'}
          onClickCapture={handleFocusClick}
        />
      ))}
    </Container>
  );
};

export default EditableTitle;
