import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container =styled.div`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  overflow: hidden;

  ${styledProp('resizing', css`
    pointer-events: none;
  `, css`
    pointer-events: auto;
  `)}
`;

