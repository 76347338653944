import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid var(--colors-additional-white, #FFF);
  background: #F4F5FD;
  
  width: 180px;
  height: 186px;

  box-shadow: 0px 12px 24px 0px rgba(153, 163, 194, 0.30);
  //padding: 12px 25px 20px 25px;
  cursor: pointer;

  ${styledProp('$highlight', css`
    box-shadow: 0px 12px 24px 0px rgba(153, 163, 194, 0.30);
  `)}
`;

export const ImageContainer = styled.div`
  margin-bottom: 3px;
`;

export const Label = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  transition: color 330ms ease;
  
  ${styledProp('$darker', css`
    color: var(--text-primary-body, #1A232E);
  `)}
`;
