import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Neutral-200, #ECF0F6);
  padding: 3px;
  gap: 3px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 34px;
  width: 34px;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 320ms ease;

  ${styledProp('$selected', css`
    background: var(--Colors-Additional-White, #FFF);
  `, css`
    background: var(--Colors-Neutral-200, #ECF0F6);
  `)}
`;
