import { useSelector } from 'react-redux';

import { userSelector } from '@store/selectors';

const usePermissions = () => {
  const user = useSelector(userSelector);

  const allowedProduct = (permissions) => {
    const namedProductPermissions = (user && user.namedProductPermissions) ? [...user.namedProductPermissions] : [];
    if (Array.isArray(permissions)) {
      return permissions.some(permission => namedProductPermissions.indexOf(permission) !== -1);
    }

    return namedProductPermissions.indexOf(permissions) !== -1;
  };

  const allowed = (permissions) => {
    const namedPermissions = (user && user.namedPermissions) ? [...user.namedPermissions] : [];
    if (Array.isArray(permissions)) {
      return permissions.some(permission => namedPermissions.indexOf(permission) !== -1);
    }

    return namedPermissions.indexOf(permissions) !== -1;
  };

  const resolve = map => {
    const namedPermissions = (user && user.namedPermissions) ? user.namedPermissions : [];
    return Object.entries(map).find(([permission]) => namedPermissions.indexOf(permission) !== -1).pop();
  };

  return {
    allowed,
    allowedProduct,
    resolve,
  };
};

export default usePermissions;
