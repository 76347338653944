const IncludeCampaignStatuses = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

const IncludeCampaignStatusLabels = {
  [IncludeCampaignStatuses.SUCCESS]: 'statuses.success',
  [IncludeCampaignStatuses.FAILED]: 'statuses.failed',
};

const IncludeCampaignStatusOptions = [
  ...Object.entries(IncludeCampaignStatusLabels).map(([value, label]) => ({ value, label }))
];

export { IncludeCampaignStatuses, IncludeCampaignStatusLabels, IncludeCampaignStatusOptions };
