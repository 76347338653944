import { ReducerKeys } from '@constants';

import { createSelector } from 'reselect';

export const defaultSubscriptionGroupsSelector = store => store[ReducerKeys.SUBSCRIPTION_GROUPS].defaultGroups;

export const customSubscriptionGroupsSelector = store => store[ReducerKeys.SUBSCRIPTION_GROUPS].customGroups;

export const subscriptionGroupsCardsSelector = createSelector(
  defaultSubscriptionGroupsSelector,
  customSubscriptionGroupsSelector,
  (d, c) => {
    return {
      default: d,
      custom: c,
    };
  }
)

export const subscriptionGroupContactsSelector = store => store[ReducerKeys.SUBSCRIPTION_GROUPS].contacts;

export const groupEditSelector = store => store[ReducerKeys.SUBSCRIPTION_GROUPS].edit;

export const subscriptionGroupsInGlobalSelector = store => store[ReducerKeys.SUBSCRIPTION_GROUPS].groupsInGlobal;

export const subscriptionGroupsCustomersCountSelector = store => store[ReducerKeys.SUBSCRIPTION_GROUPS].counts;
