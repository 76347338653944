import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  //border-radius: 10px;
  //border: 1px solid #DCDFE6;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #D3DBE7;
  background: #F8FAFE;
`;

export const Left = styled.div`
  display: flex;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 14.5px 16px 14.5px 16px;
`;

export const Order = styled.div`
  padding: 0 13px 0 16px;
  font-size: 20px;
  line-height: 24px;
  color: #31C447;
  flex: 1;
  ${styledProp('$funnel', css`
    height: 100px;
  `, css`
    height: 50px;
  `)}
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Divider = styled.div`
  //height: 100%;
  //width: 1px;
  //display: flex;
  //flex: 1;
  display: none;
  //background: linear-gradient(263.76deg, #BDCBFA 4.69%, #CCD7FF 97%)
`;

export const Content = styled.div`
  min-height: 106px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-top: none;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
`;

export const AddFunnelStepButton = styled.div`
  padding: 12px 20px;
  border-top: 1px solid #DCDFE6;
`;

export const Filters = styled.div`
  background: #fff;
`;

export const HeaderCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border-left: 1px solid #CCD7FF;
  align-items: center;
  justify-content: space-between;
`;

export const CompletionPeriodContainer = styled.div`
  display: flex;
  margin-left: 16px;
`;

export const FunnelDateFilters = styled.div`
  display: flex;
  margin-right: 20px;
`;
