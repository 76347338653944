import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { ReducerKeys } from '@constants';

import { clickhouseFields2Events } from '@utils/fields';

import { createSelector } from 'reselect';

import { OPTIONS_KEYS } from '../../constants/options';

export const optionsLoadingSelector = (store) =>
  store[ReducerKeys.OPTIONS].loading;

const options = (store) => store[ReducerKeys.OPTIONS].options;
export const lastUpdateSelector = (store) => store[ReducerKeys.OPTIONS].lastUpdate;

export const workflowOptions = (store) => store[ReducerKeys.OPTIONS].workflow;

export const workflowOptionsLoadingSelector = (store) => store[ReducerKeys.OPTIONS].workflow?.loading;

export const optionsCampaignSelector = (store) => store[ReducerKeys.OPTIONS][OPTIONS_KEYS.CAMPAIGN];

export const workflowOptionsLoadingAndEmptySelector = createSelector(
  [workflowOptionsLoadingSelector, workflowOptions],
  (loading, options) => {
    return loading;
  }
);

export const optionsAggregatesSelector = createSelector(
  [options],
  (options) => options[OPTIONS_KEYS.AGGREGATES]
);

export const optionsFieldsSelector = createSelector(
  [options],
  (options) => options[OPTIONS_KEYS.FIELDS]
);

export const optionsFunnelsSelector = createSelector(
  [options],
  (options) => options[OPTIONS_KEYS.FUNNELS]
);

export const optionsQueryValuesSelector = createSelector(
  [options],
  (options) => options[OPTIONS_KEYS.QUERY_VALUES]
);

export const optionsSegmentSelector = createSelector(
  [options],
  (options) => options[OPTIONS_KEYS.SEGMENTS]
);

export const optionsEventsSelector = createSelector(
  [options],
  (options) => options[OPTIONS_KEYS.FIELDS][OPTIONS_KEYS.FIELDS_EVENTS]
);

export const workflowOptionsSelector = createSelector(
  [workflowOptions],
  (workflowOption) => workflowOption
);

export const allWorkflowOptionsSelector = createSelector(
  [options],
  options => options[OPTIONS_KEYS.ALL_WORKFLOWS]
);

export const workflowOptionWithEventsSelector = createSelector(
  workflowOptions,
  optionsEventsSelector,
  (workflowOptions, events) => {
    return {
      ...workflowOptions,
      events: events,
    };
  }
);

export const optionsWithModifiedEvents = createSelector(
  options,
  clickhouseFieldsSelector,
  (options, clickhouseFields) => {

    return {
      ...options,
      fields: {
        ...(options?.fields || {}),
        events: clickhouseFields2Events(clickhouseFields.data)?.data,
      },
    };
  }
);

export const optionsSelector = createSelector(
  [
    optionsAggregatesSelector,
    optionsFieldsSelector,
    optionsFunnelsSelector,
    optionsQueryValuesSelector,
    optionsSegmentSelector,
  ],
  (aggregates, fields, funnels, query_values, segments) => {
    return {
      aggregates,
      fields,
      funnels,
      query_values,
      segments,
    };
  }
);

export const allOptionsSelector = createSelector(
  [
    optionsAggregatesSelector,
    optionsFieldsSelector,
    optionsFunnelsSelector,
    optionsQueryValuesSelector,
    optionsSegmentSelector,
    workflowOptionsSelector,
  ],
  (aggregates, fields, funnels, query_values, segments, workflowOptions) => {
    return {
      aggregates,
      fields,
      funnels,
      query_values,
      segments,
      workflowOptions,
    };
  }
);

