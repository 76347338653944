import { Tooltip } from 'antd';

import { Container } from './styled';

const EmailTypeIcons = {
  'vce': (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6 14.5H2.5C2.23487 14.4997 1.98068 14.3943 1.7932 14.2068C1.60572 14.0193 1.50028 13.7651 1.5 13.5V10C1.50028 9.73487 1.60572 9.48068 1.7932 9.2932C1.98068 9.10572 2.23487 9.00028 2.5 9H6C6.26513 9.00028 6.51932 9.10572 6.7068 9.2932C6.89428 9.48068 6.99972 9.73487 7 10V13.5C6.99972 13.7651 6.89428 14.0193 6.7068 14.2068C6.51932 14.3943 6.26513 14.4997 6 14.5ZM2.5 10V13.5H6V10H2.5Z"
        fill="#31C447"/>
      <path
        d="M13.5 1.5H2.5C2.23478 1.5 1.98043 1.60536 1.79289 1.79289C1.60536 1.98043 1.5 2.23478 1.5 2.5V7.5H2.5V5.5H7.5V7.5H8.5V5.5H13.5V9H8.5V10H13.5V13.5H8.5V14.5H13.5C13.7652 14.5 14.0196 14.3946 14.2071 14.2071C14.3946 14.0196 14.5 13.7652 14.5 13.5V2.5C14.5 2.23478 14.3946 1.98043 14.2071 1.79289C14.0196 1.60536 13.7652 1.5 13.5 1.5ZM13.5 4.5H2.5V2.5H13.5V4.5Z"
        fill="#31C447"/>
    </svg>
  ),
  'code': (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M15.5 8.00002L12 11.5L11.295 10.795L14.085 8.00002L11.295 5.20502L12 4.50002L15.5 8.00002Z"
        fill="#31C447"/>
      <path d="M0.5 8.00002L4 4.50002L4.705 5.20502L1.915 8.00002L4.705 10.795L4 11.5L0.5 8.00002Z" fill="#31C447"/>
      <path d="M8.81944 3.00049L6.20925 12.7418L7.17518 13.0007L9.78537 3.25931L8.81944 3.00049Z" fill="#31C447"/>
    </svg>
  )
}

const EmailTypeBadge = ({ id, type }) => {

  const resolveEmailType = () => {
    return {
      'vce': 'Block',
      'code': 'Code',
    }[type];
  };

  return (
    <Tooltip title={`${resolveEmailType()} builder`} overlayInnerStyle={{ whiteSpace: 'nowrap' }}>
      <Container key={id}>
        {EmailTypeIcons[type]}
      </Container>
    </Tooltip>
  );
};

export default EmailTypeBadge;
