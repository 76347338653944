import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import { by } from '@utils';
import { createMultinodeUpdater } from '@utils/workflows/refactored/common';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const DataChangeNodeSettingsInfo = ({ node: outerNode }) => {
  const node = { data: createMultinodeUpdater(outerNode.data).values[0] };
  const { t } = useTranslation();
  const segmentOptions = useSelector(optionsSelector);
  const field = segmentOptions?.fields?.attributes?.find?.(by('field', node.data?.field_id));

  if (!field) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      <InfoRow>
        <InfoTitle>
          {t('labels.fields')}:
        </InfoTitle>
        <InfoText>
          {field.label}
        </InfoText>
      </InfoRow>
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default DataChangeNodeSettingsInfo;
