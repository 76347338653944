import { useTranslation } from '@hooks';

import { DateCell, Link } from '@components';

import { InfoContainer, InfoText, InfoTextTitle } from './styled';

import { WithPermissions } from '../../../../../../components';
import { StatusCell } from '../StatusCell';

const InfoRow = ({ attribute, value, link, date, status, permission }) => {
  const { t } = useTranslation();
  return (
    <InfoContainer>
      <InfoTextTitle>{t(attribute)}</InfoTextTitle>
      { date && <DateCell date={value} /> }
      { link && (permission ? <WithPermissions name={permission}><Link to={link}>{value}</Link></WithPermissions> : <Link to={link}>{value}</Link>) }
      { status && <StatusCell status={value} /> }
      { (!date && !link && !status) && <InfoText>{typeof value === 'string' || typeof value === 'number' ? value : JSON.stringify(value, null,2)}</InfoText> }
    </InfoContainer>
  )
}

export default InfoRow;

