import styled, { css } from 'styled-components';

import { extract, styledProp } from '@utils';

export const Container = styled.div`
  height: 100%;
  min-height: 170px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  padding: 10px 20px 12px 20px;

 ${styledProp('$s', css`
   min-height: 70px;
   max-height: 70px;
   flex-direction: row;
   min-width: 288px;
   align-items: flex-start;
   padding: 10px 16px 12px 16px;
   background: var(--cards-bg, #F8FAFE);
 `)}
`;

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex: 0;
`;

export const FooterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  ${styledProp('$s', css`
   flex-direction: column;
   justify-content: flex-start;
 `)}
`;

export const Title = styled.div`
  margin-left: 12px;
  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 21px;
  color: #303133;

  ${styledProp('$s', css`
    font-size: 18px;
    line-height: 21px;
    white-space: nowrap;
    margin-left: 0;
    color: ${extract('$c')};
 `)}
`;

export const InfoIcon = styled.div`
  position: absolute;
  top: 0;
  right: -10px;
  z-index: 10;
  
  ${styledProp('$s', css`
    right: -20px;
 `)}
`;

export const Percentage = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 36px;
  line-height: 43px;
  color: #303133;

  ${styledProp('$s', css`
    font-size: 24px;
    line-height: 28px;
 `)}
`;

export const Values = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #909399;
`;

export const CompareContainer = styled.div`
  position: absolute;
  top: -22px;
  font-family: Manrope-SemiBold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  
  ${({ diff, inverted }) => diff > 0 && !inverted ? `
    color: #24B277;
  ` : `
    color: #C8402F;
  `}
`;

export const CompareIcon = styled.div`
  margin-right: 5px;
  margin-bottom: 4px;
`;

