import { useContext, useEffect, useRef } from 'react';

import { useCurrentWorkspace } from '@hooks';

import { WorkerStatus } from "@constants/flow/workflow";

import { moment } from '@utils';

import { WebSocketContext } from '@services';

let timout = null;

export const ANALYTICS_TYPE = {
  WORKFLOW_ANALAYTICS_SUM: 'workflow_analytics_sum'
}

export const useWorkflowAnalytics = ({
  workerStatus,
  workerId,
  range,
  subscriber,
  type = ANALYTICS_TYPE.WORKFLOW_ANALAYTICS_SUM
}) => {
  const workspace = useCurrentWorkspace();
  const channel = `dashboard#${workspace?.id}/worker_${workerId}`;
  const subscribeChannel = useRef();
  const currentRange = useRef();
  currentRange.current = range;

  const webSocketService = useContext(WebSocketContext);

  const isBetweenRange = (analyticItem, rangeDates) => {
    if(!rangeDates || !Array.isArray(rangeDates)) {
      return true;
    }
    if(analyticItem?.created_at) {
      return moment(analyticItem.created_at).isBetween(rangeDates[0], rangeDates[1]);
    }

    return false;
  }

  const handleSocketData = (data) => {
    const isBetween = isBetweenRange(data, currentRange.current);
    if (workerId === data.worker_id && data.type === type && isBetween) {
      subscriber?.(data);
    }
  };

  useEffect(() => {
    return () => {
      timout = setTimeout(() => {
        subscribeChannel.current?.unsubscribe();
      }, 10000)
    }
  }, []);

  useEffect(() => {
    if (workerStatus === WorkerStatus.ACTIVE) {
      timout && clearTimeout(timout);
      subscribeChannel.current = webSocketService?.subscribe(channel, handleSocketData);
    }

  }, [workerStatus, channel]);

  return {};
};
