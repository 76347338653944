import { TimePicker } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const IconContainer = styled.div`
`;

export const StyledTimePicker = styled(TimePicker)`
  border: 1px solid #DCDFE6;
  border-radius: 7px;
  margin-left: 6px;
  height: 36px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding: 0 !important;
  box-shadow: none;
  overflow: hidden;

  & > div > input {
    min-width: 0px !important;
    width: 0px !important;
  }

  & > div {
    transition: all 300ms ease;
    height: 100%;
    padding-left: 0px;
    max-width: 35px;

        
    &:hover, &:active, &:focus {
      padding-left: 11px !important;
      max-width: 200px;
      
      & > span {
        margin-left: 4px;
        border-left: 1px solid #DCDFE6;
      }

      & > input {
        width: auto !important;
      }
    }
  }

  ${styledProp('alwaysOpened', css`
    box-shadow: none !important;
    border-color: #31C447;

    & > div {
      transition: all 300ms ease;
      padding-left: 11px !important;
      max-width: 200px !important;

      & > span {
        height: 100% !important;
        margin-left: 4px !important;
        border-left: 1px solid #DCDFE6 !important;
      }

      & > input {
        width: auto !important;
      }
    }
  `)}

  &.ant-picker-focused > div {
    transition: all 300ms ease;
    padding-left: 11px;
    max-width: 200px !important;

    & > span {
      height: 100%;
      margin-left: 4px;
      border-left: 1px solid #DCDFE6;
    }

    & > input {
      width: auto !important;
    }
  }

  & > div > span {
    width: 37px;
    border-left: 1px solid #E9ECEF;
    height: 100%;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #E9ECEF;
    color: #909399;
    margin-left: 0px;
  }

  & > div > span > span {
    justify-content: center;
  }

  &:focus {
    box-shadow: none !important;
    border-color: #31C447;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  font-size: 13px;
`;

export const Label = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  color: #909399;
`;
