import { useSelector } from 'react-redux';

import { dashboardAnalyticsEntitiesSelector } from '@store/selectors/dashboard';

import { Dropdown } from 'antd';

import { useTranslation, useNavigate } from '@hooks';

import { Paths } from '@constants';

import { by } from '@utils';

import { Title, EntityLink, EntitiesLink, Triangle, Overlay, SegmentTitle, LegendCircle, SegmentRow } from './styled';

const EntitiesTitle = ({ colors, type, ids = [] }) => {
  const { p } = useTranslation('dashboard_page');
  const analyticEntities = useSelector(dashboardAnalyticsEntitiesSelector);
  const navigate = useNavigate();

  const handleLinkClick = () => {
    if (type === 'segment') {
      navigate(`${Paths.RESOURCES_SEGMENTS}/dynamic/${ids[0]}`);
    }
    if (type === 'funnel') {
      navigate(`${Paths.FUNNEL_ANALYTICS}/${ids[0]}/preview/conversion`);
    }
  };

  if (type === 'base') {
    return (
      <Title data-testid="dashboard-title-base-activity">
        {p('contacts_in_base')}
      </Title>
    );
  }

  if (type === 'segment' && ids.length === 1) {
    return (
      <Title data-testid="dashboard-title-base-activity" onClick={handleLinkClick}>
        {p('customers_in_segment')}&nbsp;<EntityLink>{analyticEntities.segments?.find?.(by(ids[0]))?.name}</EntityLink>
      </Title>
    );
  }

  if (type === 'funnel') {
    return (
      <Title data-testid="dashboard-title-base-activity" onClick={handleLinkClick}>
        {p('customers_in_funnel')}&nbsp;<EntityLink>{analyticEntities.funnels?.find?.(by(ids[0]))?.name}</EntityLink>
      </Title>
    )
  }

  return (
    <Title data-testid="dashboard-title-base-activity">
      {p('customers_in_segment')}&nbsp;
      <Dropdown
        trigger={['hover']}
        placement={'bottom'}
        overlay={(
          <Overlay>
            <Triangle>
              <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10L9.07105 0.928954L18.1421 10H0Z" fill="#DCDFE6"/>
                <path d="M2.07129 10L9.14236 2.92893L16.2134 10H2.07129Z" fill="white"/>
              </svg>
            </Triangle>
            {ids.map(id => (
              <SegmentRow key={id}>
                <LegendCircle $color={colors[analyticEntities.segments?.find?.(by(id))?.name]} />
                <SegmentTitle>{analyticEntities.segments?.find?.(by(id))?.name}</SegmentTitle>
              </SegmentRow>
            ))}
          </Overlay>
        )}
      >
        <EntitiesLink>{analyticEntities.segments?.find?.(by(ids[0]))?.name} + {ids.length - 1}</EntitiesLink>
      </Dropdown>
    </Title>
  );
};

export default EntitiesTitle;
