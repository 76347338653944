import { Link as RouterLink } from 'react-router-dom';

import { Popover } from 'antd';
import styled, { css } from 'styled-components';

import { extract, styledProp } from '@utils';

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -16px;
  padding-left: 10px;
  
  ${styledProp('isSettings', `
    background: #1A232E;
    border-radius:  0;
  `)}
`;

export const StyledPopover = styled(Popover)`
`;

export const Link = styled(RouterLink)`
  color: #909399;
  text-decoration: none;
  font-size: 12px;
  

  ${styledProp('$active', `
    background: #10254E;
  `)}
  
  ${styledProp('$white', `
    color: #fff;
  `)}

  ${styledProp('isFirst', `
    border-top-right-radius: 8px;
    padding-top: 0 !important;
  `)}

  ${styledProp('isLast', `
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    padding-bottom: 0 !important;
  `)}
  
  ${styledProp('isSettings', `
    width: 120px !important;
    color: #7C94B1 !important;
    // padding: 10px 12px !important;
    font-size: 14px;
    background-color: #1A232E !important;
  `)}

  ${styledProp('$larger', `
    color: #fff;
    padding: 10px 12px;
    font-size: 13px;
    min-width: 120px;
  `)}

  ${styledProp('$isBeta', `
    &:after {
      content: "Beta";
      background: #31C447;
      border-radius: 4px;
      color: #FFFFFF;
      font-family: 'Manrope-Medium';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 100%;
      padding: 2px 4px 1px;
      margin-left: 6px;
    }
  `)}
  
  &:active {
    color: #909399;
    text-decoration: none;
  }
  &:hover {
    color: #FFFFFF !important;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: center;
  min-width: 180px;
  font-size: 16px;
  line-height: 100%;
  font-family: Manrope-SemiBold;
  color: #FFFFFF;
  cursor: pointer;
  transition: 280ms ease all;
  background-color: #12181F;
  
  &:hover {
    color: #31C447;
  }
  
  ${styledProp('isCurrent', `
    color: #31C447;
  `)}
`;

export const Divider = styled.div`
  background: transparent;
  height: 1px;
  width: 100%;
  margin: 4.5px 0;
`;

export const Image = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
  background: center / contain no-repeat url("${extract('image')}");
  background-size: cover;
  background-color: #fff;
`;

export const DemoActiveCaption = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #909399;
`;

export const ViewDemoButton =styled.div`
  font-family: Manrope-Medium;
  font-size: 14px;
  line-height: 17px;
  color: #7C94B1;
  transition: 280ms ease all;
  cursor: pointer;
  padding: 8px;

  & path {
    fill: #909399;
    transition: 280ms ease all;
  }

  &:hover {
    color: #31C447;

    & path {
      fill: #31C447;
    }
  }

  ${styledProp('$active', css`
    color: #31C447;
    
    & path {
      fill: #31C447;
    }
  `)}
`;

export const Language = styled.div`
  color: #7C94B1 !important;
  text-decoration: none;
  font-size: 12px;
  background-color: #1A232E;
  padding: 10px 12px;

  ${styledProp('isFirst', `
    border-top-right-radius: 8px;
    padding-top: 0 !important;
  `)}

  ${styledProp('isLast', `
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    padding-bottom: 0 !important;
  `)}
  
  &:hover {
    color: #FFFFFF !important;
    cursor: pointer;
  }
`;

export const OptionsContainer = styled.div`
  
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: #1A232E;
  padding: 8px 0 8px 0;
  
  &:hover{
    cursor: pointer;
  }
  
  & > div{
    &:hover{
      color: #FFFFFF !important;
    }
  }
`;

export const InfoItem = styled.div`
  background-color: #1A232E;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const UserImg = styled.div`
  background-color: #905BC8;
  border-radius: 7px;
  width: 24px;
  height: 24px;
  color: #FFFFFF;
  font-family: Manrope-SemiBold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoInnerRight = styled.div`
  
`;

export const Name = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
`;

export const Position = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 11px;
  color: #7C94B1;
`;

export const Icon = styled.img`
`;

export const Title = styled.div`
  color: #7C94B1;
  font-family: Manrope-SemiBold;
  font-size: 13px;
  line-height: 18px;
`;

export const IconWrapper = styled.div`
  min-width: 25px;
  text-align: center;
`;

