import { useDispatch, useSelector } from 'react-redux';

import { changeLanguage, getUser, logout } from '@store/actions/creators';
import { userSelector } from '@store/selectors';

import { Icon } from '@velitech/ui';
import { Tooltip } from 'antd';

import { useCurrentWorkspace } from '@hooks';

import { Paths } from '@constants';

import { hashAppId } from '@utils';

import documentation from '@res/images/doc-logo.svg'
import logo from '@res/images/topbar-logo.svg'
import notification from '@res/images/topbar-notification.svg'
import i18n from '@res/languages'

import { UserInfo } from '@components/layout/Topbar/components/UserInfo';

import { Breadcrumbs, LanguageSelect, TopbarSettings } from './components';
import {
  Container,
  Logo,
  InnerLeft,
  InnerRight,
  ItemsContainer,
  Img,
  DocumentationItem,
  NotificationsItem,
  NotificationsCounter
} from './styled';

import './styles.css'

const settingsPages = (appId) => [
  // {
  //   name: 'workspaces',
  //   path: Paths.WORKSPACES,
  //   // permission: UserPermissions.UPDATE_WORKSPACE,
  // },
  {
    name: 'users',
    path: `/app/${appId}${Paths.USERS_SETTINGS}`,
    // permission: UserPermissions.REVIEW_USERS,
  },
  {
    name: 'roles',
    path: `/app/${appId}${Paths.ROLES_SETTINGS}`,
    // permission: UserPermissions.REVIEW_ROLES,
  },
  {
    name: 'integrations',
    path: `/app/${appId}${Paths.INTEGRATIONS}`,
  }
];

const Topbar = ({ notificationsOpened, notifications, toggleNotifications }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const app = useCurrentWorkspace();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleDocumentationClick = () => {
    window.open('https://docs.veliedge.io/', '_blank');
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage({ ge: 'ka-GE', en: 'en-US' }[language]);
    dispatch(changeLanguage({
      language,
      onSuccess: () => {
        dispatch(getUser());
      }
    }))
  };
  const settingsRoutes = settingsPages(hashAppId(String(app?.id)));

  const unreadNotifications = notifications && notifications.filter(n => n.read_at === null || n.read_at === undefined).length;

  return ((
    <Container>
      <InnerLeft>
        <Icon name={'Switcher'} size={Icon.SIZES.S20} />
        <Logo src={logo}/>
        <Breadcrumbs />
      </InnerLeft>
      <InnerRight>
        <ItemsContainer>
          <NotificationsItem style={{ pointerEvents: notificationsOpened ? 'none' : undefined }} onClick={toggleNotifications}>
            <Img src={notification}/>
            {unreadNotifications !== 0 && (
              <NotificationsCounter>
                {unreadNotifications}
              </NotificationsCounter>
            )}
          </NotificationsItem>
          <DocumentationItem
            data-testid={'topbar-documentation-btn'}
            onClick={handleDocumentationClick}
          >
            <Tooltip top={10} title={'Documentation'}>
              <div>
                <Img src={documentation}/>
              </div>
            </Tooltip>
          </DocumentationItem>
          <TopbarSettings
            testId={'topbar-settings-btn'}
            pages={settingsRoutes}
          />
          <LanguageSelect
            testId={'topbar-language-select-btn'}
            language={user?.language}
            onChange={handleLanguageChange}
            onToggle={() => {}}
          />
        </ItemsContainer>
        <UserInfo
          testId={'topbar-user-info-btn'}
          handleLogout={handleLogout}
        />
      </InnerRight>
    </Container>
  ));
};

export default Topbar;
