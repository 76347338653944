import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeLatest, put, takeEvery } from 'redux-saga/effects';

import { Endpoints, ProgramTypes } from '@constants';

import { extract, moment } from '@utils';

import { CampaignActionTypes } from '../../actions/types';

// function* createCampaignNoteSaga(action) {
//   yield put(createRequestAction(action, {
//     method: 'post',
//     endpoint: '/api/comments/add',
//     body: {
//       type: 'program',
//       id: action.meta.id,
//       comment: action.payload.comment,
//     }
//   }));
// }
//
// function* deleteCampaignNoteSaga(action) {
//   yield put(createRequestAction(action, {
//     method: 'post',
//     endpoint: '/api/comments/delete',
//     body: {
//       id: action.payload.id,
//     },
//   }));
// }

function* editCampaignSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `${Endpoints.WORKFLOW}/${action.meta.id}/edit`,
  }));
}

function* getCampaignsContactsIdsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/${action.payload.channel_type}/customers`,
    params: {
      entity_id: action.payload.worker_id,
      sub_entity_id: action.payload.node_id,
      date_range: action.payload.range,
      status: [action.payload.status],
    },
  }));
}

function* getCampaignsContactsIdsSuccessSaga(action) {
  yield put({
    ...action,
    type: CampaignActionTypes.GET_CAMPAIGN_CONTACTS,
    payload: {
      ids: !!action.payload?.data?.length ? action.payload?.data?.map?.(extract('customer_id')) : ['noiidsshouldbefount'],
      fields: action.meta.fields,
    },
  })
}

function* getCampaignContactsSaga(action) {
  yield put(createRequestAction(action, {
    ...action,
    method: 'post',
    body: {
      fields: action.meta.fields,
    },
    endpoint: Endpoints.CONTACTS,
    disableTimeout: true,
    params: {
      page: 1,
      per_page: 10000,
      ids: action.payload.ids,
    }
  }));
}

// function* getCampaignNotesSaga(action) {
//   yield put(createRequestAction(action, {
//     method: 'get',
//     endpoint: '/your-endpoint',
//   }));
// }

function* listCampaignsCalendarSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/workflow/list',
  }));
}

function* listCampaignsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.WORKFLOW,
    params: { program_type: ProgramTypes.CAMPAIGN }
  }));
}

function* publishCampaignSaga(action) {
  const { schedule } = action.payload;
  const minStart = moment().add(2, 'minutes');

  let start_date = schedule.start_date;
  let end_date = schedule.end_date;

  if (start_date && schedule.start_date?.isBefore?.(minStart)) {
    start_date = minStart;
  }

  if (end_date && schedule.end_date?.isBefore?.(minStart)) {
    end_date = minStart;
  }

  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_PUBLISH,
    body: {
      id: action.payload.id,
      schedule: {
        ...schedule,
        start_date,
        end_date
      },
      fake: action.payload.fake,
    },
  }));
}

function* saveCampaignSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW,
    body: {
      tags: action.payload.tags,
      program: action.payload.campaign.program,
      worker: action.payload.campaign.worker,
    },
  }));
}

function* stopCampaignSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKFLOW_UN_PUBLISH,
    body: {
      id: action.payload.id,
    },
  }));
}

export default function* campaignsRootSaga() {
  yield all([
    takeLatest(CampaignActionTypes.LIST, listCampaignsSaga),
    takeLatest(CampaignActionTypes.LIST_CALENDAR, listCampaignsCalendarSaga),
    takeLatest(CampaignActionTypes.SAVE, saveCampaignSaga),
    takeLatest(CampaignActionTypes.PUBLISH, publishCampaignSaga),
    takeLatest(CampaignActionTypes.EDIT, editCampaignSaga),
    takeLatest(CampaignActionTypes.GET_CAMPAIGN_CONTACTS_IDS, getCampaignsContactsIdsSaga),
    takeEvery(CampaignActionTypes.GET_CAMPAIGN_CONTACTS_IDS_SUCCESS, getCampaignsContactsIdsSuccessSaga),
    takeEvery(CampaignActionTypes.GET_CAMPAIGN_CONTACTS, getCampaignContactsSaga),
    takeLatest(CampaignActionTypes.STOP_CAMPAIGN, stopCampaignSaga),
  ]);
}
