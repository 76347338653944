import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  gap: 129px;
  box-shadow: 0px 5px 10px 0px #99A3C24D;
  background-color: #F4F5FD;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  width: 860px;
  padding: 19px 28px;
  margin-top: 45px;
  margin-bottom: 158px;
  position: relative;
  z-index: 100;

  ${styledProp('totalDisplayed', css`
    padding-bottom: 16px;
  `)}
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 39px;
`;

export const InputContainer = styled.div`
  width: 300px;
  text-align: center;
`;

export const DefaultLimit = styled.div`
  width: 350px;
  margin-bottom: 48px;
  font-family: Manrope-SemiBold;
  font-size: 30px;
  text-align: center;
  color: #1A232E;

  ${styledProp('disabled', `
    color: #A1B3C8 !important;
  `)}
`;

export const Line = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
`;

export const Or = styled.img`
  position: absolute;
  left: 48%;
  top: 43%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TotalNumberText = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 18px;
  color: #7C94B1;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const YourLimitTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: Manrope-SemiBold;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  color: #7C94B1;
  padding-top: 4px;
`;

export const TotalNumber = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: baseline;
`;

export const EditButton = styled.img`
  width: 18px;
  height: 18px;
`;

export const ConfirmButton = styled.div`
  border-radius: 6px;
  height: 22px;
  width: 22px;
  padding: 12px;
  background-color: #31C447;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover{
    cursor: pointer;
  }
`;

export const Check = styled.img`
  width: 16px;
  min-width: 16px;
  height: 16px;
`;

export const BottomTextContainer = styled.div`
  font-size: 14px;
  color: #7C94B1;
  font-family: Manrope-SemiBold;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
`;
export const ResetLimit = styled.div`
  font-size: 14px;
  color: #31C447;
  font-family: Manrope-SemiBold;
  position: absolute;
  left: 42%;
  top: 30px;
  
  &:hover{
    cursor: pointer;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  
  &:hover{
    cursor: pointer;
  }
`;

export const BottomTextInner = styled.div`
  width: 45px;
`;
