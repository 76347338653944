import styled, { css } from "styled-components";

import { styledProp } from "@utils";

export const EmailTemplatesRow = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 1185px) {
    grid-template-columns: repeat(1,1fr);
  }
  
  ${styledProp('$empty', css`
    grid-template-columns: none;
  `)}

  grid-gap: 14px;
`;

export const Container = styled.div``;

export const TemplatesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TemplatesInner = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  padding: 15px 20px;
`;
