import { useCallback, useEffect, useState } from 'react';

import { useOrdering, usePagination, useFilters } from './index';

const useTable = ({ filtersConfig = {}, paginationConfig, startSearchAt = 2, requestDispatcher, searchFilter, metaDispatcher, metaDeps = [], deps = [], outerFilters = null, dynamicFilters = [], initialOrdering = '' } = {}) => {
  const pagination = usePagination(paginationConfig);
  const searchPagination = usePagination(paginationConfig);
  const tableFilters = useFilters(filtersConfig, dynamicFilters);
  const ordering = useOrdering(initialOrdering);

  const filters = outerFilters || tableFilters;

  const dynamicFiltersChanger = dynamicFilters.map(df => filters.filters[df] || '').map(fv => {
    if (Array.isArray(fv)) {
      return fv.join(',');
    }

    if (typeof fv === 'object' && fv !== null) {
      return Object.entries(fv).map(e => e.join(':')).join(',');
    }

    return String(fv);
  }).join(';');

  const [search, setSearch] = useState(filtersConfig[searchFilter] || '');
  const shouldUseSearch = search.length > startSearchAt;

  useEffect(() => {
    pagination.handlers.onPageChange(0);
    searchPagination.handlers.onPageChange(0);
  }, [shouldUseSearch]);

  const usedPagination = shouldUseSearch ? searchPagination : pagination;

  useEffect(() => {
    if (shouldUseSearch) {
      return;
    }

    metaDispatcher?.({ ...ordering.params, ...filters.filters, [searchFilter]: '' });
  }, [
    dynamicFiltersChanger,
    shouldUseSearch,
    ...metaDeps,
  ]);

  useEffect(() => {
    if (!shouldUseSearch) {
      return;
    }

    metaDispatcher?.({ ...ordering.params, ...filters.filters, [searchFilter]: search });
  }, [
    dynamicFiltersChanger,
    shouldUseSearch,
    search,
    ...metaDeps,
  ]);

  useEffect(() => {
    if (shouldUseSearch) {
      return;
    }
    requestDispatcher?.({
      page: isNaN(usedPagination.page) ? 0 : usedPagination.page + 1,
      perPage: isNaN(usedPagination.perPage) ? 20 : usedPagination.perPage,
      ...ordering.params,
      ...filters.filters,
      [searchFilter]: '',
    });
  }, [
    usedPagination.page,
    usedPagination.perPage,
    ordering.params.orderCol,
    shouldUseSearch,
    ordering.params.orderDir,
    dynamicFiltersChanger,
    ...deps,
  ]);

  useEffect(() => {
    if (!shouldUseSearch) {
      return;
    }

    requestDispatcher?.({
      page: isNaN(usedPagination.page) ? 0 : usedPagination.page + 1,
      perPage: isNaN(usedPagination.perPage) ? 20 : usedPagination.perPage,
      ...ordering.params,
      ...filters.filters,
      [searchFilter]: search,
    });
  }, [
    usedPagination.page,
    usedPagination.perPage,
    ordering.params.orderCol,
    shouldUseSearch,
    search,
    ordering.params.orderDir,
    dynamicFiltersChanger,
    ...deps,
  ]);

  const applyFilters = () => {
    usedPagination.handlers.onPageChange(0);
    requestDispatcher?.({
      page: 1,
      perPage: isNaN(usedPagination.perPage) ? 20 : usedPagination.perPage,
      ...ordering.params,
      ...(outerFilters || {}),
      ...(tableFilters?.filters || {}),
      ...filters.filters,
      ...(shouldUseSearch ? { [searchFilter]: search } : {}),
    });
    metaDispatcher?.({ ...(outerFilters || {}), ...(tableFilters?.filters || {}), ...filters.filters, ...(shouldUseSearch ? { [searchFilter]: search } : {}) })
  };

  const discardFilters = () => {
    filters.clear();
    setSearch('');
    usedPagination.handlers.onPageChange(0);
    requestDispatcher?.({
      page: 1,
      perPage: isNaN(usedPagination.perPage) ? 20 : usedPagination.perPage,
      ...filtersConfig,
      ...ordering.params,
    });
    metaDispatcher?.({ ...(shouldUseSearch ? { [searchFilter]: search } : {}) })
  };

  const discardWithoutRefresh = () => {
    filters.clear();
    usedPagination.handlers.onPageChange(0);
  };

  const changePage = (page) => {
    usedPagination.handlers.onPageChange(page);
    // requestDispatcher({ page, perPage: usedPagination.perPage, ...ordering.params, ...(outerFilters || {}), ...(tableFilters?.filters || {})  });
  };

  const changePerPage = (perPage) => {
    usedPagination.handlers.onPerPageChange(perPage);
    usedPagination.handlers.onPageChange(0);
  };

  const refresh = useCallback(() => {
    requestDispatcher?.({
      page: isNaN(usedPagination.page) ? 0 : usedPagination.page + 1,
      perPage: isNaN(usedPagination.perPage) ? 20 : usedPagination.perPage,
      ...ordering.params,
      ...(outerFilters || {}),
      ...(tableFilters?.filters || {})
    });
    metaDispatcher?.({ ...(outerFilters || {}), ...(tableFilters?.filters || {}) });
  }, [requestDispatcher, metaDispatcher]);

  return {
    applyFilters,
    discardFilters,
    changePage,
    changePerPage,
    discardWithoutRefresh,
    search,
    changeSearch: setSearch,
    refresh,
    ordering,
    filters,
    pagination: usedPagination,
    requestParams: {
      page: isNaN(usedPagination.page) ? 0 : usedPagination.page + 1,
      perPage: isNaN(usedPagination.perPage) ? 20 : usePagination.perPage,
      ...ordering.params,
      ...(outerFilters || {}),
      ...(tableFilters?.filters || {})
    }
  };
};

export default useTable;
