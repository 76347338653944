import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 7px;
  background: #C1CEFC;
  padding: 5px 12px;
  justify-content: center;
  gap: 6px;
  cursor: pointer;

  ${styledProp('$disabled', `
     cursor: not-allowed;
  `)}
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;

  ${styledProp('$disabled', `
    opacity: 40%;
  `)}
`;

export const Overlay = styled.div`
  display: flex;
  padding: 6px 1px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 7px;
  border: 1px solid var(--select-select-border, #D3DBE7);
  background: var(--input-input-bg, #FFF);
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
`;

export const Option = styled.div`
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  transition: all 300ms ease;
  cursor: pointer;

  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;
