import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 42px;
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  color: #1a232e;
  font-family: Manrope-SemiBold;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 4px;
  text-transform: capitalize;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  padding: 10px 12px;
  border: 1px solid #d3dbe7;
  background: #fff;
  position: relative;
  transition: all 330ms ease;
  overflow: hidden;

  ${styledProp(
    '$opened',
    css`
      max-height: 800px;
    `,
    css`
      max-height: 60px;
    `
  )}
  ${styledProp('$withoutDropdown', css`
      max-height: unset;
  `)}
  ${styledProp('$disabled', css`
    background: var(--Colors-Neutral-100, #F8FAFE);
    ${Title} {
      color: var(--text-secondary-body, #7C94B1);
  }
  `)}
`;

export const Description = styled.p`
  color: #7c94b1;

  font-family: Manrope-SemiBold;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
`;

export const ArrowContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  right: -10px;
  top: 32%;
  transform: translate(0, -32%);
  cursor: pointer;

  svg {
    pointer-events: none;
    transition: all 0.3s ease;
  }
  ${styledProp(
    '$opened',
    css`
      svg {
        transform: scale(-1);
      }
    `,
  )}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #ecf0f6;
  background: #f8fafe;
  padding: 10px 12px;


  ${switchStyle('$type',{
    'secondary': `
      background: #fff;
    `,
  })}
    
    ${styledProp('$stretch', css`
        width: 100%;
    `)}
`;

export const ItemTitle = styled.h4`
  color: #1a232e;
  font-family: Manrope-SemiBold;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin: 0 0 5px 0;
`;

export const ItemSubtitle = styled.p`
  color: #12b76a;
  font-family: Manrope-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0 0 2px 0;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const ItemContent = styled.p`
  color: #1a232e;
  font-family: Manrope-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;
