import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUser,
  setSidebarOpened,
} from '@store/actions/creators';
import {
  sidebarOpenedSelector,
  userSelector,
} from '@store/selectors';

import { Theme, SideBar } from '@velitech/ui';

import {
  useBreakpoint,
  useOutsideClickListener,
} from '@hooks';

import './styles.css';
import { SidebarHeader } from './components/SidebarHeader';
import { SidebarItemNew } from './components/SidebarItem/SidebarItemNew';

const Sidebar = ({
  routes,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const is1024 = useBreakpoint(1440);
  const opened = useSelector(sidebarOpenedSelector);
  const user = useSelector(userSelector);
  const [openedSidebar, setOpenedSidebar] = useState(opened);

  const close = useCallback(() => {
    // if (opened) {
    dispatch(setSidebarOpened({ opened: false }));
    // }
  }, []);

  useEffect(() => {
    dispatch(setSidebarOpened({ opened: openedSidebar }));
  }, [openedSidebar])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (is1024) {
          close();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, is1024]);

  const toggle = (status) => {
    window.dispatchEvent(new Event('resize'));
    dispatch(setSidebarOpened({ opened: status }));
  };

  useOutsideClickListener(
    containerRef,
    () => {
      if (is1024) {
        close();
      }
    },
    [is1024]
  );

  useEffect(() => {
    if (user?.id && window._rio?.identify) {
      return window._rio?.identify(user?.id);
    }

    dispatch(
      getUser({
        onSuccess: (user) => {
          if (!user?.data?.id || !window._rio?.identify) {
            return;
          }

          window._rio?.identify(user?.data?.id);
        },
      })
    );
  }, [user?.id, window._rio?.identify]);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Theme mode="dark" className="asdasd">
      <SideBar
        opened={openedSidebar}
        onOpenedChange={setOpenedSidebar}
        className='sidebar'
        header={<SidebarHeader opened={opened} toggle={toggle} user={user}/>}
      >
        {routes.map((props, index) => (
          <SidebarItemNew
            {...props}
            index={index}
            key={index}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        ))}
      </SideBar>
    </Theme>
  );
};

export default Sidebar;
