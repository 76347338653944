import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Modal } from '@components';

import { Container, Text } from './styled';

const MobileDisclaimerModal = ({ opened, onClose }) => {
  const { t, p } = useTranslation('dashboard_page');

  return (
    <Modal
      title="Welcome to Retainly"
      opened={opened}
      onClose={onClose}
      style={{ maxWidth: 420 }}
    >
      <Container>
        <svg width="116" height="116" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 14C11 9.02944 15.0294 5 20 5H96C100.971 5 105 9.02944 105 14V69H11V14Z" stroke="#BFCAD8" strokeWidth="2"/>
          <path d="M16 16C16 12.6863 18.6863 10 22 10H94C97.3137 10 100 12.6863 100 16V64H16V16Z" fill="#BFCAD8"/>
          <path d="M112.174 98.9614C113.486 104.605 109.202 110 103.408 110H12.5924C6.79839 110 2.51394 104.605 3.82636 98.9614L10.7941 69H105.206L112.174 98.9614Z" stroke="#BFCAD8" strokeWidth="2"/>
          <path d="M52 99H64.67" stroke="#BFCAD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <Text>
          {p('mobile_disclaimer')}
        </Text>
        <Button onClick={onClose}>
          {t('actions.ok_thanks')}
        </Button>
      </Container>
    </Modal>
  );
};

export default MobileDisclaimerModal;
