import { Menu } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 13px;
  height: 36px;
  border: 1px solid #DCDFE6;
  ${({ disabled }) => disabled === false ? `
      background: #fff;
  ` : `
    background : #f5f5f5;
  `}
  width: 162px;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  border-radius: 7px;
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6 !important;
    borderRadius: 9px !important;
  `)}

  ${styledProp('hasError', `
    color: #C8402F;
    border-color: #C8402F !important;
  `)}

  ${styledProp('highlight', `
    border-color: #ffc63047 !important;
  `)}

  ${styledProp('smaller', `
    border-radius: 7px !important;
  `)}
`;

export const Overlay = styled(Menu)`
  display: flex;
  flex-direction: column;
  background-color: #fff;

  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 10px 0 0 0;
  
  ${styledProp('padded', `
    padding-bottom: 40px;
  `)}
`;

export const Suffix = styled.div`
  display: flex;
  color: #909399;
  margin-left: 15px;
  width: 100%;
  justify-content: flex-end;
  font-size: 10px;
`;

export const CommonOptionsContainer = styled.div`
  padding: 0 14px 16px 14px;
  border-top: 1px solid #DCDFE6;
  margin-top: 10px;
`;

export const CommonOptionsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CommonOption = styled.div`
  cursor: pointer;
  font-size: 13px;
  line-height: 15.6px;
  margin-top: 16px;
`;

export const SelectOverlay = styled.div`
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  width: 160px;
  position: absolute;
  top: 0;
  right: -161px;
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px 0;
`;

export const IconContainer = styled.div`
  transition: all 280ms ease;
  margin-left: 9px;
  color: #909399;
  transform: rotateX(0deg);
  margin-bottom: 2px;
  opacity: 0;
  

  ${styledProp('visible', css`
    opacity: 1;
  `, css`
    pointer-events: none;
  `)}
  
  ${styledProp('clear', css`
    background: #fff;
    right: -2px;
    top: -3px;
    position: absolute;
  `)}

  ${styledProp('clearEnabled', css`
    opacity: 0;
  `)}
`;

export const Option = styled.div`
  display: flex;
  padding: 7px 13px;
  cursor: pointer;
  transition: 280ms ease all;
  font-family: Manrope-Medium;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  white-space: nowrap;
  max-width: 600px;
  overflow: hidden;
  min-height: 32px;
  //line-height: 18px;
  text-overflow: ellipsis;
  
  &:hover {
    background: #F0F2F6;
  }
  
  & > .prefix {
    color: #909399;
  }

  //&:hover {
    //background: rgba(208, 207, 205, 0.43);
  //}
`;

export const FooterContainer = styled.div`
  border-top: 1px solid #DCDFE6;
  display: flex;
  padding: 0 13px;
  align-items: center;
  position: sticky;
  bottom: 0;
  margin-bottom: -10px;
  right: 0;
  left: 0;
  background: #fff;
`;

export const TitleContainer = styled.div`
  line-height: 15.6px;

  ${styledProp('yellowBg', css`
    background-color: yellow;
    padding: 5px;
  `)}


  & > .prefix {
    color: #909399;
  }
  
  ${styledProp('smaller', css`
    font-size: 12px;
  `)}

  ${styledProp('isPlaceholder', css`color: #909399;`)}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${styledProp('disabled', css`
    color: rgba(0, 0, 0, 0.3);
  `)}
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
  font-size: 13px;
  padding-left: 4px;
`;

export const Label = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 100%;
  color: #909399;
  ${styledProp('transparentLabel', `opacity: 0`)}
`;

export const OptionsGroup = styled.div`
  padding: 10px 0;
  transition: all 350ms ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ visible, optionsLength }) => visible ? `
    max-height: ${optionsLength * 32 + 42}px;
    min-height: ${optionsLength * 32 + 42}px;
  ` : `
    max-height: 48px;
    min-height: 48px;
  `}
`;

export const GroupTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 13px;
  //padding-bottom: 6px;
  //padding-left: 14px;
  //padding-right: 14px;
  min-height: 32px;
  //cursor: pointer;
  
  //&:hover {
  //  background: #F0F2F6;
  //}
  
  ${styledProp('disabled', css`
    cursor: default;
  `)}
`;

export const GroupTitle = styled.div`
  font-size: 13px;
  line-height: 120%;
  //font-size: 16px;
  //line-height: 130%;
  color: #909399;
  margin-right: 6px;
  display: flex;
  justify-content: space-between;
`;

export const InfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
`;

export const GroupTitleIcon = styled.div`
  transition: all 280ms ease;
  ${styledProp('visible', `
      transform: rotate(0deg);
  `, `
      transform: rotate(180deg);
  `)}
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 5px;
  line-height: 120%;
  color: #C8402F;
`;
