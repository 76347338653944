import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

export const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NoDataContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
