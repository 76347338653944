import { Input as ANTDInput } from 'antd';
import styled from 'styled-components';

import { styledProp } from '@utils';

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const TitleInner = styled.div`
  text-align: left;
  width: 100%;
`;

export const StyledInput = styled(ANTDInput)`
  align-self: center;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 7px;
  height: 28px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding-right: 24px;
  margin-top: 13px;
  box-shadow: none !important;
  width: 390px;
  display: flex;
  flex-direction: row;
  gap: 8px;

  ${styledProp('$filter', `
    width: 450px;
  `)}
  
  ${styledProp('hasError', `
    margin-bottom: 0px;
    color: #C8402F;
    border-color: #C8402F !important;
  `)}
  
  ${styledProp('withSuffix', `
    padding-right: 5px;
  `)}
  
  ${styledProp('withPrefix', `
    padding-left: 6px;
  `)}

  ${styledProp('$highlight', `
    border-color: #f3cc50 !important;
    color: #f3cc50 !important;
  `)}

  &:focus {
    box-shadow: none !important;
    border-color: #DCDFE6 !important;
  }
`;

export const Container = styled.div`
  background: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid var(--cards-border, #D3DBE7);
`;

export const ListStyled = styled.div`


  height: 254px;
  max-height: 254px;
  overflow-y: auto;
`
export const Option = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  align-items: center;
  background: #fff;
  transition: background 290ms ease;
  cursor: pointer;
  
  &:hover {
    background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
  }
`;

export const OptionTitle = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    color: #1A232E;
    flex-direction: column;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
    padding: 14px 14px 15px 16px;
    align-items: center;
    border-bottom: 1px solid var(--border-divider, #D3DBE7);
    background: var(--cards-bg, #F8FAFE);
    border-radius: 10px 10px 0 0;
    border: 1px solid var(--cards-border, #D3DBE7);
`;

export const CloseIconcontainer = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
`

export const OptionCaption = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;