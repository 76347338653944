import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUsersList } from '@store/actions/creators';
import { usersListSelector } from '@store/selectors';

import { Multiselect, Select } from '@components';

const UserSelect = ({ isMulti, testId, ...props }) => {
  const dispatch = useDispatch();
  const users = useSelector(usersListSelector);

  const options = useMemo(
    () => users?.data?.filter?.(u => !!u.name === true)?.map?.(({ name, id, surname }) => ({ label: `${name || ''} ${surname || ''}`, value: id })),
    [users]
  );

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  if (isMulti) {
    return (
      <Multiselect testId={testId} options={options || []} {...props} />
    )
  }

  return (
    <Select options={options || []} style={{ backgroundColor: '#fff' }} {...props} />
  );
};

export default UserSelect;
