import { useTranslation } from '@hooks';

import { Container, Label } from './styled';

const ListNoData = () => {
  const { p } = useTranslation('workflow_page');

  return (
    <Container>
      <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_495_20319" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="101" height="100">
          <path d="M0.5 7.62939e-06H100.5V100H0.5V7.62939e-06Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_495_20319)">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.28647 22.834C7.72064 22.834 6.39331 21.5013 6.39331 19.9102C6.39331 18.306 7.71289 16.9863 9.31733 16.9863H10.3253C11.2918 16.9863 12.0753 16.2028 12.0753 15.2363C12.0753 11.0401 15.4768 7.63867 19.673 7.63867C22.4384 7.63867 24.8607 9.11518 26.1919 11.3315C26.5965 12.0051 27.4005 12.3265 28.1579 12.1173C28.5203 12.0173 28.9029 11.9633 29.3007 11.9633C30.879 11.9633 32.2601 12.8154 33.0061 14.093C33.4934 14.9276 34.5651 15.2091 35.3997 14.7218C36.2344 14.2345 36.5159 13.1628 36.0286 12.3281C34.6812 10.0206 32.174 8.46328 29.3007 8.46328C29.0293 8.46328 28.7609 8.47728 28.4961 8.50459C26.4703 5.85337 23.2727 4.13867 19.673 4.13867C14.1292 4.13867 9.53507 8.20333 8.70801 13.5151C5.45441 13.8238 2.89331 16.5784 2.89331 19.9102C2.89331 23.3999 5.75347 26.334 9.28647 26.334H26.9296C27.8961 26.334 28.6796 25.5505 28.6796 24.584C28.6796 23.6175 27.8961 22.834 26.9296 22.834H9.28647Z" fill="#31C447"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.28647 22.834C7.72064 22.834 6.39331 21.5013 6.39331 19.9102C6.39331 18.306 7.71289 16.9863 9.31733 16.9863H10.3253C11.2918 16.9863 12.0753 16.2028 12.0753 15.2363C12.0753 11.0401 15.4768 7.63867 19.673 7.63867C22.4384 7.63867 24.8607 9.11518 26.1919 11.3315C26.5965 12.0051 27.4005 12.3265 28.1579 12.1173C28.5203 12.0173 28.9029 11.9633 29.3007 11.9633C30.879 11.9633 32.2601 12.8154 33.0061 14.093C33.4934 14.9276 34.5651 15.2091 35.3997 14.7218C36.2344 14.2345 36.5159 13.1628 36.0286 12.3281C34.6812 10.0206 32.174 8.46328 29.3007 8.46328C29.0293 8.46328 28.7609 8.47728 28.4961 8.50459C26.4703 5.85337 23.2727 4.13867 19.673 4.13867C14.1292 4.13867 9.53507 8.20333 8.70801 13.5151C5.45441 13.8238 2.89331 16.5784 2.89331 19.9102C2.89331 23.3999 5.75347 26.334 9.28647 26.334H26.9296C27.8961 26.334 28.6796 25.5505 28.6796 24.584C28.6796 23.6175 27.8961 22.834 26.9296 22.834H9.28647Z" fill="#F8FAFE" fillOpacity="0.3"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M61.4375 74.4219C41.9091 74.4219 26.0781 58.5909 26.0781 39.0625C26.0781 19.5341 41.9091 3.70312 61.4375 3.70312C80.9659 3.70312 96.7969 19.5341 96.7969 39.0625C96.7969 46.2237 94.6694 52.8821 91.0131 58.4484C90.4825 59.2562 90.7072 60.3412 91.515 60.8719C92.3228 61.4025 93.4078 61.1778 93.9385 60.37C97.958 54.2507 100.297 46.9275 100.297 39.0625C100.297 17.6011 82.8989 0.203125 61.4375 0.203125C39.9761 0.203125 22.5781 17.6011 22.5781 39.0625C22.5781 60.5239 39.9761 77.9219 61.4375 77.9219C67.8297 77.9219 73.8656 76.3766 79.1883 73.6388C80.0478 73.1968 80.3862 72.1416 79.9441 71.2822C79.502 70.4227 78.4469 70.0843 77.5874 70.5264C72.7481 73.0156 67.2597 74.4219 61.4375 74.4219Z" fill="#D3DBE7"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M30.3906 39.0625C30.3906 56.2093 44.2907 70.1094 61.4375 70.1094C62.1692 70.1094 62.5936 70.1053 63.3543 70.0508C64.3184 69.9817 65.0439 69.1443 64.9748 68.1802C64.9058 67.2162 64.0683 66.4907 63.1043 66.5597C62.4634 66.6056 62.1332 66.6094 61.4375 66.6094C46.2237 66.6094 33.8906 54.2763 33.8906 39.0625C33.8906 34.4283 35.0353 30.0663 37.054 26.2377C37.5048 25.3828 37.1772 24.3243 36.3223 23.8735C35.4673 23.4227 34.4089 23.7503 33.9581 24.6053C31.6807 28.9243 30.3906 33.8451 30.3906 39.0625ZM84.3263 57.4338C85.1143 57.9934 86.2068 57.8083 86.7664 57.0204C90.367 51.9507 92.4844 45.7513 92.4844 39.0625C92.4844 21.9159 78.5841 8.01562 61.4375 8.01562C56.2196 8.01562 51.299 9.30616 46.9794 11.5834C46.1244 12.0341 45.7967 13.0926 46.2475 13.9475C46.6982 14.8025 47.7566 15.1302 48.6116 14.6795C52.4412 12.6606 56.803 11.5156 61.4375 11.5156C76.6511 11.5156 88.9844 23.8489 88.9844 39.0625C88.9844 45.0014 87.1068 50.4966 83.9129 54.9937C83.3532 55.7817 83.5383 56.8742 84.3263 57.4338Z" fill="#D3DBE7"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M3.12546 97.3743C6.35556 100.604 11.6306 100.604 14.8607 97.3743C14.8695 97.3655 14.8782 97.3566 14.8868 97.3477L40.7215 70.3877C41.3902 69.6899 41.3666 68.5821 40.6688 67.9134C39.971 67.2447 38.8632 67.2683 38.1945 67.9661L12.3736 94.9116C10.5092 96.7627 7.45954 96.7586 5.60033 94.8994L5.60029 94.8994C3.74117 93.0404 3.73705 89.9905 5.5882 88.1261L32.3552 62.4746C33.053 61.8058 33.0765 60.6981 32.4078 60.0003C31.7391 59.3025 30.6313 59.2789 29.9335 59.9476L3.15207 85.613C3.1431 85.6216 3.13424 85.6303 3.12546 85.6391C-0.104611 88.8691 -0.104679 94.1444 3.12546 97.3743Z" fill="#D3DBE7"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M32.5989 77.7218C33.2823 77.0384 33.2823 75.9304 32.5989 75.2469L25.2528 67.9008C24.5694 67.2174 23.4614 67.2174 22.7779 67.9008C22.0945 68.5843 22.0945 69.6923 22.7779 70.3757L30.124 77.7218C30.8075 78.4052 31.9155 78.4052 32.5989 77.7218Z" fill="#D3DBE7"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M49.9211 39.0625C49.9211 40.029 50.7046 40.8125 51.6711 40.8125H51.6725C52.639 40.8125 53.4225 40.029 53.4225 39.0625C53.4225 38.096 52.639 37.3125 51.6725 37.3125H51.6711C50.7046 37.3125 49.9211 38.096 49.9211 39.0625Z" fill="#D3DBE7"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M59.6868 39.0625C59.6868 40.029 60.4703 40.8125 61.4368 40.8125H61.4381C62.4046 40.8125 63.1881 40.029 63.1881 39.0625C63.1881 38.096 62.4046 37.3125 61.4381 37.3125H61.4368C60.4703 37.3125 59.6868 38.096 59.6868 39.0625Z" fill="#D3DBE7"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M69.4524 39.0625C69.4524 40.029 70.2359 40.8125 71.2024 40.8125H71.2038C72.1703 40.8125 72.9538 40.029 72.9538 39.0625C72.9538 38.096 72.1703 37.3125 71.2038 37.3125H71.2024C70.2359 37.3125 69.4524 38.096 69.4524 39.0625Z" fill="#D3DBE7"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M68.1183 70.3281C66.5523 70.3281 65.2251 68.9955 65.2251 67.4043C65.2251 65.8001 66.5447 64.4805 68.1489 64.4805H69.1569C70.1234 64.4805 70.9069 63.697 70.9069 62.7305C70.9069 58.5343 74.3084 55.1328 78.5046 55.1328C81.2701 55.1328 83.6925 56.6094 85.0235 58.8256C85.4281 59.4993 86.2321 59.8206 86.9895 59.6115C87.3518 59.5114 87.7347 59.4574 88.1323 59.4574C90.3905 59.4574 92.2421 61.2043 92.4073 63.4192C92.4754 64.3327 93.2364 65.0391 94.1524 65.0391C95.6029 65.0391 96.797 66.2331 96.797 67.6836C96.797 69.1341 95.6029 70.3281 94.1524 70.3281H68.1183ZM61.7251 67.4043C61.7251 70.894 64.585 73.8281 68.1183 73.8281H94.1524C97.5359 73.8281 100.297 71.0671 100.297 67.6836C100.297 64.818 98.3165 62.3989 95.6548 61.7255C94.7651 58.403 91.735 55.9574 88.1323 55.9574C87.861 55.9574 87.5925 55.9714 87.3278 55.9987C85.302 53.3475 82.1042 51.6328 78.5046 51.6328C72.9608 51.6328 68.3667 55.6975 67.5396 61.0092C64.2861 61.318 61.7251 64.0725 61.7251 67.4043Z" fill="#31C447"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M68.1183 70.3281C66.5523 70.3281 65.2251 68.9955 65.2251 67.4043C65.2251 65.8001 66.5447 64.4805 68.1489 64.4805H69.1569C70.1234 64.4805 70.9069 63.697 70.9069 62.7305C70.9069 58.5343 74.3084 55.1328 78.5046 55.1328C81.2701 55.1328 83.6925 56.6094 85.0235 58.8256C85.4281 59.4993 86.2321 59.8206 86.9895 59.6115C87.3518 59.5114 87.7347 59.4574 88.1323 59.4574C90.3905 59.4574 92.2421 61.2043 92.4073 63.4192C92.4754 64.3327 93.2364 65.0391 94.1524 65.0391C95.6029 65.0391 96.797 66.2331 96.797 67.6836C96.797 69.1341 95.6029 70.3281 94.1524 70.3281H68.1183ZM61.7251 67.4043C61.7251 70.894 64.585 73.8281 68.1183 73.8281H94.1524C97.5359 73.8281 100.297 71.0671 100.297 67.6836C100.297 64.818 98.3165 62.3989 95.6548 61.7255C94.7651 58.403 91.735 55.9574 88.1323 55.9574C87.861 55.9574 87.5925 55.9714 87.3278 55.9987C85.302 53.3475 82.1042 51.6328 78.5046 51.6328C72.9608 51.6328 68.3667 55.6975 67.5396 61.0092C64.2861 61.318 61.7251 64.0725 61.7251 67.4043Z" fill="#F8FAFE" fillOpacity="0.3"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M38.9702 18.3457C38.9702 19.3122 39.7537 20.0957 40.7202 20.0957H40.7216C41.6881 20.0957 42.4716 19.3122 42.4716 18.3457C42.4716 17.3792 41.6881 16.5957 40.7216 16.5957H40.7202C39.7537 16.5957 38.9702 17.3792 38.9702 18.3457Z" fill="#D3DBE7"/>
        </g>
      </svg>
      <Label>
        {p('no_data_found')}
      </Label>
    </Container>
  );
};

export default ListNoData;
