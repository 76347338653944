import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNotifications, markAsImportant, markAsRead, markNotImportant } from '@store/actions/creators';
import { sidebarOpenedSelector } from '@store/selectors';

import { useTransition, config } from '@react-spring/web';
import moment from 'moment';

import { useOutsideClickListener, useTranslation } from '@hooks';

import { testId } from '@utils';

import { Header } from '@components'
import { NotificationsGroup } from '@components/lib/NotificationsList/components/NotificationsGroup';

import { Container, Title, Divider, Scroll, OnlyShowUnread, Content } from './styled.js';

import Switch from '../../controls/Switch/Switch';

const getTabs = (p) => [
  {
    name: 'all',
    title: p('all'),
  },
  {
    name: 'important',
    title: p('important'),
  },
];
const NotificationsList = ({ notifications, opened, close }) => {
  const [tab, setTab] = useState('all');
  const [showUnread, setShowUnread] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState({});
  const dispatch = useDispatch();
  const sidebarOpened = useSelector(sidebarOpenedSelector);
  const ref = useRef(null);
  const transition = useTransition(opened, {
    from: { opacity: 1, x: '100%' },
    enter: { opacity: 1, x: '0%' },
    leave: { opacity: 1, x: '100%' },
    config: config.default,
  });
  const { p } = useTranslation('topbar');

  useOutsideClickListener(ref, (e) => {
    e.stopPropagation();
    close();
  });

  const handleReadAll = () => {
    const unread = notifications.filter(n => (n.read_at === null || n.read_at === undefined)).map(n => n.id);
    if (!unread || !unread.length) return;

    dispatch(markAsRead({
      ids: unread,
      onSuccess: () => dispatch(getNotifications()),
    }));
  }

  const handleReadNotification = useCallback((notification) => {
    if (notification.read_at !== null && notification.read_at !== undefined) return;

    dispatch(markAsRead({
      ids: [notification.id],
      onSuccess: () => dispatch(getNotifications()),
    }));
  }, [dispatch, markAsRead]);

  const handleMarkAsImportant = useCallback((notification) => {
    if (!notification.important){
      dispatch(markAsImportant({
        ids: [notification.id],
        onSuccess: () => {
          dispatch(getNotifications());
          const updatedGroups = { ...unreadNotifications };
          for (const key in updatedGroups) {
            updatedGroups[key] = updatedGroups[key].filter(item => item.id !== notification.id);
          }
          setUnreadNotifications(updatedGroups);
        },
      }));
    } else {
      dispatch(markNotImportant({
        ids: [notification.id],
        onSuccess: () => {
          dispatch(getNotifications());
          if (tab === 'important') {
            const updatedGroups = { ...unreadNotifications };
            for (const key in updatedGroups) {
              updatedGroups[key] = updatedGroups[key].filter(item => item.id !== notification.id);
            }
            setUnreadNotifications(updatedGroups);
          }
        },
      }));
    }
  }, [dispatch, markAsImportant, markNotImportant, unreadNotifications, tab]);

  const sortedTest = notifications.sort((a, b) => +moment(b.created_at) - +moment(a.created_at)).map(el => ({ ...el, date: +moment(el.created_at) }));

  const getDateGroups = () => {
    const groups = {};

    for (const item of sortedTest) {
      const groupCategory = moment(item.date).calendar().split(' at').shift();

      if (!groups[groupCategory]) {
        groups[groupCategory] = [item];
      } else {
        groups[groupCategory].push(item);
      }
    }

    return groups;
  }

  const groups = getDateGroups();

  const handleChangeTab = (tab) => {
    if (tab === 'important') {
      let filteredData = {};
      for (const key in groups) {
        if (Array.isArray(groups[key])) {
          filteredData[key] = groups[key].filter(item => item.important);
        }
      }
      setTab(tab);
      setUnreadNotifications(filteredData);
    } else if (tab === 'all') {
      let filteredData = showUnread ? handleFilterUnread(groups) : groups;
      setTab(tab);
      setUnreadNotifications(filteredData);
    }
  };

  const handleFilterUnread= (data) => {
    const filteredData = {};
    for (const key in data) {
      if (Array.isArray(data[key])) {
        filteredData[key] = data[key].filter(item => item.read_at === null || item.read_at === undefined);
      }
    }
    return filteredData;
  };

  const onShowUnread = () => {
    const filteredData = handleFilterUnread(groups);
    setUnreadNotifications(filteredData);
    setShowUnread(!showUnread);
  };

  const hasUnread = (groups) => {
    for (const key in groups) {
      if (Array.isArray(groups[key]) && groups[key].some(item => item.read_at === null || item.read_at === undefined)) {
        return true;
      }
    }
    return false;
  };

  return transition((style, opened) => opened && (
    <>
      <Container
        ref={ref}
        style={{
          ...style,
          transform: style.opacity.to({ range: [1.0, 0.0], output: [0, 50] }).to(x => `translate3d(${x}px,0,0)`)
        }}
        $opened={opened}
        sidebarOpened={sidebarOpened}
      >
        <Header
          notifications={true}
          title={(
            <Title>{p('notifications')}</Title>
          )}
          tabs={getTabs(p)}
          tab={tab}
          onTabChange={handleChangeTab}
          right={
            <>
              <OnlyShowUnread>
                <Switch checked={showUnread} onChange={onShowUnread} isNotifications={true} {...testId('launch-settings-modal-test-launch')()} style={{ width: '100%', justifyContent: 'space-between' }} title={p('only_show_unread')} large={true}>
                </Switch>
              </OnlyShowUnread>
            </>
          }
          // content={
          //
          // }
        />
        <Content>
          <Divider />
          <Scroll>
            {tab === 'all' && (Object.entries(showUnread ? unreadNotifications : groups).map(([name, group], index) => (
              <NotificationsGroup
                showUnread={showUnread}
                hasUnread={hasUnread(groups)}
                handleReadAll={handleReadAll}
                handleMarkAsImportant={handleMarkAsImportant}
                opened={opened}
                key={index}
                idx={index}
                onClose={close}
                name={name}
                group={group}
                onRead={handleReadNotification}
              />
            )))}
            {tab === 'important' && (Object.entries(unreadNotifications).map(([name, group], index) => (
              <NotificationsGroup
                showUnread={showUnread}
                hasUnread={hasUnread(unreadNotifications)}
                handleReadAll={handleReadAll}
                handleMarkAsImportant={handleMarkAsImportant}
                opened={opened}
                key={index}
                idx={index}
                onClose={close}
                name={name}
                group={group}
                onRead={handleReadNotification}
              />
            )))}
          </Scroll>
        </Content>
      </Container>
    </>
  ));
}

export default NotificationsList;

