import React, { useMemo, useState } from 'react';
import { useDispatch } from "react-redux";

import { archiveWebpushTemplate, getSingleWebpushTemplate, getWebpushTemplates } from "@store/actions/creators";

import { Button } from '@velitech/ui';

import { useModalState, useTranslation, useNavigate } from "@hooks";

import { Paths, UserPermissions } from "@constants";

import { notify, getDefaultLang } from "@utils";

import {
  Link,
  WithPermissions,
  LargeTemplateItem,
  TemplateStatisticsModal
} from "@components";

import { Container } from "./styled";

import { EmailTemplatesRow, TemplatesHeader, TemplatesInner } from "../../styled";

const WebpushSection = ({ webpushes = [] }) => {
  const { p, t } = useTranslation('dashboard_page');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [statisticWebpushId, setWebpushStatisticsId] = useState(null);
  const statisticsWebpushModal = useModalState();

  const shouldRender = useMemo(() => {
    return !!webpushes?.data?.filter(t => t.status !== 3)?.length;
  }, [webpushes?.data])

  const handleViewWebpush = (id) => {
    navigate(`${Paths.TEMPLATES_WEBPUSH}/${id}?view=1`);
  };

  const handleEditWebpush = (id, used) => {
    if (used === 1){
      return;
    }
    navigate(`${Paths.TEMPLATES_WEBPUSH}/${id}`);
  };

  const handleDuplicateWebpush = (id) => {
    dispatch(getSingleWebpushTemplate({ 
      id,
      onSuccess: () => {
        navigate(`${Paths.TEMPLATES_WEBPUSH}/duplicate`);
      }
    }))
  };

  const handleCreateWebpush = () => {
    navigate(`${Paths.TEMPLATES_WEBPUSH}/new`);
  };

  const handleWebpushStatisticsClick = (id) => {
    setWebpushStatisticsId(id);
    statisticsWebpushModal.open();
  };

  const handleWebpushArchive = (id, name) => {
    dispatch(archiveWebpushTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.template_archived'), name);
        dispatch(getWebpushTemplates({ page: 1, perPage: 6, all: true }));
      }
    }))
  }

  return (
    <Container data-testid="dashboard-templates-section-webpush">
      {shouldRender ? (
        <TemplatesInner style={{ marginTop: 10, paddingBottom: 0, pointerEvents: !!webpushes?.meta?.total ? 'auto' : 'none', minHeight: '246px' }}>
          <TemplatesHeader>
            <Link to={`${Paths.TEMPLATES_WEBPUSHES}/default`} icon>{p('web_push')}</Link>
            <WithPermissions name={UserPermissions.CREATE_WEBPUSHES}>
              <Button
                size={'sm'}
                style={{
                  padding: '6px 14px',
                  height: '26px',
                  fontSize: '12px',
                  lineHeight: '14px',
                  borderRadius: '5px'
                }}
                data-testid="dashboard-templates-section-webpush-create"
                width="none"
                onClick={handleCreateWebpush}
              >
                {t('actions.create')}
              </Button>
            </WithPermissions>
          </TemplatesHeader>
          <EmailTemplatesRow>
            {webpushes.data?.filter(t => t.status !== 3)?.slice(0, 3).map(({ id, name, used, created_at, workers, status, languages }, i, arr) => {
              const data = getDefaultLang(languages);
              return (
                <LargeTemplateItem
                  key={id}
                  used={used}
                  type="web_push"
                  title={name}
                  testId={`webpush-templates-item-${i}`}
                  status={status}
                  body={data.body}
                  image_url={data.image?.url || data.image_url}
                  data={arr[i]}
                  fromDashboard
                  onDeleteClick={() => {}}
                  onStatisticsClick={() => handleWebpushStatisticsClick(id)}
                  onArchive={() => handleWebpushArchive(id, name)}
                  small
                  style={{ ...(i === (arr.length - 1) ? { marginRight: '0px' } : {}) }}
                  workflows={workers}
                  date={created_at}
                  onViewClick={() => handleViewWebpush(id)}
                  onEditClick={() => handleEditWebpush(id)}
                  onDuplicateClick={() => handleDuplicateWebpush(id)}
                />
              )
            })}
          </EmailTemplatesRow>
        </TemplatesInner>
      ) : null}
      <TemplateStatisticsModal
        id={statisticWebpushId} 
        opened={statisticsWebpushModal.opened} 
        onClose={statisticsWebpushModal.close}
        type={'web_push'}
      />
    </Container>
  )
}

export default React.memo(WebpushSection);
