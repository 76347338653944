import styled from 'styled-components';

import { styledProp } from '@utils';

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;

  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
  max-height: 50vh;
  overflow-y: auto;
  padding: 16px 14px 13px 14px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 13px;
  height: 36px;

  ${styledProp('error', `
    border-color: #C8402F !important;
  `)}

  ${styledProp('onboard', `
    border: 3px solid #BDD1F6 !important;
    border-radius: 7px !important;
  `)}
  
  ${styledProp('disabled', `
     background-color: #f5f5f5;
  `, `
     background-color: #fff;
  `)}
  
  border: 1px solid #DCDFE6;
  width: 162px;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  border-radius: 7px;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  font-size: 13px;
  ${styledProp('titleMarginLeft', 'margin-left: 5px')}
`;

export const Title = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  color: #909399;
  line-height: 100%;
  padding-left: 4px;
  ${styledProp('transparentTitle', 'opacity: 0')}
`;

export const ValueContainer = styled.div`
  line-height: 15.6px;
  ${styledProp('value', `
    color: #303133;
  `, `
    color: #909399;
  `)}


  
  
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const IconContainer = styled.div`
  transition: all 280ms ease;
  margin-left: 10px;
  color: #909399;
  //transform: rotateX(0deg);
  margin-bottom: -2px;
  // ${styledProp('$opened', 'transform: rotateX(180deg); margin-bottom: 2px;')}
`;
