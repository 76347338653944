import { useRef, useState } from 'react';

import { Checkbox } from 'antd';

import { DatePicker } from '@components';

import { Container } from './styled';

const SpecificDatePicker = ({ title, value, onChange, containerStyle, ...props }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const handleCheckedChange = ({ target: { checked } }) => {
    if (!checked) {
      return setOpen(true);
    }

    if (checked) {
      onChange(null);
    }
  }

  return (
    <Container style={containerStyle}>
      <DatePicker
        ref={ref}
        value={value}
        open={open}
        onOpenChange={setOpen}
        style={{ width: '100%' }}
        containerStyle={{ width: '100%' }}
        title={title}
        onChange={onChange}
        {...props}
      />
      <Checkbox
        checked={value === null}
        onChange={handleCheckedChange}
      >
        No {title.toLowerCase()}
      </Checkbox>
    </Container>
  )
};

export default SpecificDatePicker;
