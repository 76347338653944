import { ReducerKeys } from '@constants';

import { createSelector } from 'reselect';

const getWorkspaces = store => store[ReducerKeys.WORKSPACES].workspaces?.data || [];

export const workspacesListSelector = createSelector(
  [getWorkspaces],
  (workspaces) => workspaces.filter(({ is_demo }) => !is_demo),
);

export const demoWorkspacesSelector = store => store[ReducerKeys.WORKSPACES].demo;

export const viewDemoWorkspacesModalOpenedSelector = store => store[ReducerKeys.WORKSPACES].viewDemoModalOpened;
