import { Dropdown } from 'antd';

import { Icon } from '@components';

import { IconWrapper } from './styled';

const MoreButton = ({ testId, menu, hover, color, visible, onClick, iconStyles = {}, iconSize, onVisibleChange, renderIcon, ...props }) => {
  if (!menu) {
    return (
      <IconWrapper data-testid={testId} onboard={props.onboard} style={props.style || {}} onClick={onClick || onVisibleChange}>
        <Icon data-testid={testId} name="More-info" size={iconSize || 36} style={{ width: '22px', ...iconStyles }} color={color} />
      </IconWrapper>
    );
  }

  return (
    <Dropdown
      {...(onVisibleChange ? { visible } : {})}
      overlay={menu}
      zIndex={1000}
      trigger={onVisibleChange ? [] : [hover ? 'hover' : 'click']}
      {...props}
    >
      {renderIcon ? renderIcon : (
        <IconWrapper data-testid={testId} style={props.style || {}} onClick={onClick || onVisibleChange}>
          <Icon data-testid={testId} name="More-info" size={iconSize || 36} style={{ width: '22px', ...iconStyles }} color={color} />
        </IconWrapper>
      )}
    </Dropdown>
  )
};

export default MoreButton;
