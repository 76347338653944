const SubscriptionGroupsActionTypes = {
  GET_DEFAULT: 'subscriptionGroups/GET_DEFAULT',
  GET_DEFAULT_SUCCESS: 'subscriptionGroups/GET_DEFAULT_SUCCESS',
  GET_DEFAULT_ERROR: 'subscriptionGroups/GET_DEFAULT_ERROR',

  GET_CUSTOM: 'subscriptionGroups/GET_CUSTOM',
  GET_CUSTOM_SUCCESS: 'subscriptionGroups/GET_CUSTOM_SUCCESS',
  GET_CUSTOM_ERROR: 'subscriptionGroups/GET_CUSTOM_ERROR',

  GET_GROUPS_IN_GLOBAL: 'subscriptionGroups/GET_GROUPS_IN_GLOBAL',
  GET_GROUPS_IN_GLOBAL_SUCCESS: 'subscriptionGroups/GET_GROUPS_IN_GLOBAL_SUCCESS',
  GET_GROUPS_IN_GLOBAL_ERROR: 'subscriptionGroups/GET_GROUPS_IN_GLOBAL_ERROR',

  GET: 'subscriptionGroups/GET',
  GET_SUCCESS: 'subscriptionGroups/GET_SUCCESS',
  GET_ERROR: 'subscriptionGroups/GET_ERROR',

  SAVE_GROUP: 'subscriptionGroups/SAVE',
  SAVE_GROUP_SUCCESS: 'subscriptionGroups/SAVE_SUCCESS',
  SAVE_GROUP_ERROR: 'subscriptionGroups/SAVE_ERROR',

  GET_GROUP_CONTACTS: 'subscriptionGroups/GET_CONTACTS',
  GET_GROUP_CONTACTS_SUCCESS: 'subscriptionGroups/GET_CONTACTS_SUCCESS',
  GET_GROUP_CONTACTS_ERROR: 'subscriptionGroups/GET_CONTACTS_ERROR',

  DUPLICATE: 'subscriptionGroups/DUPLICATE',
  DUPLICATE_SUCCESS: 'subscriptionGroups/DUPLICATE_SUCCESS',
  DUPLICATE_ERROR: 'subscriptionGroups/DUPLICATE_ERROR',

  DELETE: 'subscriptionGroups/DELETE',
  DELETE_SUCCESS: 'subscriptionGroups/DELETE_SUCCESS',
  DELETE_ERROR: 'subscriptionGroups/DELETE_ERROR',

  ADD_CUSTOMERS: 'subscriptionGroups/ADD_CUSTOMERS',
  ADD_CUSTOMERS_SUCCESS: 'subscriptionGroups/ADD_CUSTOMERS_SUCCESS',
  ADD_CUSTOMERS_ERROR: 'subscriptionGroups/ADD_CUSTOMERS_ERROR',

  IMPORT_CUSTOMERS: 'subscriptionGroups/IMPORT_CUSTOMERS',
  IMPORT_CUSTOMERS_SUCCESS: 'subscriptionGroups/IMPORT_CUSTOMERS_SUCCESS',
  IMPORT_CUSTOMERS_ERROR: 'subscriptionGroups/IMPORT_CUSTOMERS_ERROR',

  DELETE_CUSTOMERS: 'subscriptionGroups/DELETE_CUSTOMERS',
  DELETE_CUSTOMERS_SUCCESS: 'subscriptionGroups/DELETE_CUSTOMERS_SUCCESS',
  DELETE_CUSTOMERS_ERROR: 'subscriptionGroups/DELETE_CUSTOMERS_ERROR',

  EXPORT_CUSTOMERS: 'subscriptionGroups/EXPORT_CUSTOMERS',
  EXPORT_CUSTOMERS_SUCCESS: 'subscriptionGroups/EXPORT_CUSTOMERS_SUCCESS',
  EXPORT_CUSTOMERS_ERROR: 'subscriptionGroups/EXPORT_CUSTOMERS_ERROR',

  EXPORT_ALL: 'subscriptionGroups/EXPORT_ALL',
  EXPORT_ALL_SUCCESS: 'subscriptionGroups/EXPORT_ALL_SUCCESS',
  EXPORT_ALL_ERROR: 'subscriptionGroups/EXPORT_ALL_ERROR',

  DOWNLOAD_CUSTOMERS: 'subscriptionGroups/DOWNLOAD_CUSTOMERS',
  DOWNLOAD_CUSTOMERS_SUCCESS: 'subscriptionGroups/DOWNLOAD_CUSTOMERS_SUCCESS',
  DOWNLOAD_CUSTOMERS_ERROR: 'subscriptionGroups/DOWNLOAD_CUSTOMERS_ERROR',

  ADD_GROUP_TO_GLOBAL: 'subscriptionGroups/ADD_GROUP_TO_GLOBAL',
  ADD_GROUP_TO_GLOBAL_SUCCESS: 'subscriptionGroups/ADD_GROUP_TO_GLOBAL_SUCCESS',
  ADD_GROUP_TO_GLOBAL_ERROR: 'subscriptionGroups/ADD_GROUP_TO_GLOBAL_ERROR',

  GET_CUSTOMERS_HINT: 'subscriptionGroups/GET_CUSTOMERS_HINT',
  GET_CUSTOMERS_HINT_SUCCESS: 'subscriptionGroups/GET_CUSTOMERS_HINT_SUCCESS',
  GET_CUSTOMERS_HINT_ERROR: 'subscriptionGroups/GET_CUSTOMERS_HINT_ERROR',

  GET_GROUPS_CUSTOMER_COUNT: 'subscriptionGroups/GET_CUSTOMERS_COUNT',
  GET_GROUPS_CUSTOMER_COUNT_SUCCESS: 'subscriptionGroups/GET_CUSTOMERS_COUNT_SUCCESS',
  GET_GROUPS_CUSTOMER_COUNT_ERROR: 'subscriptionGroups/GET_CUSTOMERS_COUNT_ERROR',
};

export default SubscriptionGroupsActionTypes;
