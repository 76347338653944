import { v4 as uuidv4 } from 'uuid';

import { WorkflowNodeItems, WorkflowNodes, WorkflowNodeTypes } from '@constants';

import { by, extract, identity } from '@utils';

const nodeOptions = WorkflowNodeItems.flatMap(extract('options'));

const DEFAULT_VALID = [WorkflowNodes.CONTACT_CREATED, 'ab', 'exclude', 'include'];

const presets = {
  [WorkflowNodes.EVENT]: {
    multinode: true,
  },
  [WorkflowNodes.RESOURCE]: {
    multinode: true,
  },
  [WorkflowNodes.CONTACT_CREATED]: {
    multinode: true,
  },
  [WorkflowNodes.PAGE_VIEW]: {
    multinode: true,
  },
  [WorkflowNodes.EMAIL]: {
    multinode: true,
  },
  [WorkflowNodes.DATA_CHANGE]: {
    multinode: true,
  },
};

const createMultinodeFirstChild = (type, data) => {

  return {
    type,
    id: data.id,
    dummy: true,
    data: {
      ...data,
      name: type,
      label: 'internal',
    },
  };
};

const dataPresets = {
  [WorkflowNodes.EVENT]: d => ({
    children: [createMultinodeFirstChild(WorkflowNodes.EVENT, d)],
  }),
  [WorkflowNodes.EMAIL]: d => ({
    children: [createMultinodeFirstChild(WorkflowNodes.EMAIL, d)],
  }),
  [WorkflowNodes.ENTRY_ANOTHER_WF]: d => ({
    children: [createMultinodeFirstChild(WorkflowNodes.ENTRY_ANOTHER_WF, d)],
  }),
  [WorkflowNodes.PAGE_VIEW]: d => ({
    children: [createMultinodeFirstChild(WorkflowNodes.PAGE_VIEW, d)],
  }),
  [WorkflowNodes.CONTACT_CREATED]: d => ({
    children: [createMultinodeFirstChild(WorkflowNodes.CONTACT_CREATED, d)],
  }),
  [WorkflowNodes.DATA_CHANGE]: d => ({
    children: [createMultinodeFirstChild(WorkflowNodes.DATA_CHANGE, d)],
  }),
  [WorkflowNodes.RESOURCE]: d => ({
    children: [createMultinodeFirstChild(WorkflowNodes.RESOURCE, d)],
  }),
};

const defaultAbSegments = [{ id: uuidv4(), value: 50, label: 'Path A', name: 50 }, { id: uuidv4(), value: 50, label: 'Path B', name: 50 }];

export const createNode = ({
  position = {},
  type,
  data = {},
  actionType,
  nodeType = WorkflowNodeTypes.DEFAULT,
  temp,
  translate = identity,
  abSegments = defaultAbSegments,
}) => {
  const id = uuidv4();
  let children = [];
  let otherStyles = {};

  const style = {
    id,
    label: translate(nodeOptions.find(by('name', type))?.title),
    description: '',
    type: nodeType,
    actionType,
    temp,
    data: {
      validated: !!~DEFAULT_VALID.indexOf(type) || !!data.validated,
      validation: data.validation,
    },
    position: { x: 150, y: 150, ...position },
  };

  if (type === WorkflowNodes.BEST_CHANNEL_TO_SEND) {
    const channelID = uuidv4();
    data = {
      ...data,
      channels: [
        {
          id: channelID,
          type: WorkflowNodes.SEND_EMAIL,
          label: 'Email',
          default: false,
          data: {
            id: channelID,
            label: 'Email',
            name: WorkflowNodes.SEND_EMAIL,
          },
        },
      ]
    };
  }

  if (type === WorkflowNodes.AB_TEST) {
    const [a, b] = abSegments;
    style.data = {
      segments: [{ id: uuidv4(), value: a, label: 'Path A', name: a.name, ...a }, { id: uuidv4(), value: b, label: 'Path B', name: b.name, ...b }],
    };
    const [f, fs] = createNode({ type: 'ab', data: { value: a, name: String(a.name), label: 'Path A', ...a }, actionType: WorkflowNodes.AB_TEST, nodeType: WorkflowNodeTypes.SPLITTER, temp, translate });
    const [s, ss] = createNode({ type: 'ab', data: { value: b, name: String(b.name), label: 'Path B', ...b }, actionType: WorkflowNodes.AB_TEST, nodeType: WorkflowNodeTypes.SPLITTER, temp, translate });

    children = [f, s];
    otherStyles = { [f.id]: fs, [s.id]: ss }
  }

  if (type === WorkflowNodes.SWITCH_FILTER) {
    style.data = {
      validated: !!~DEFAULT_VALID.indexOf(type) || !!data.validated,
      validation: data.validation,
      segments: [{ id: uuidv4(), parentId: id, type: WorkflowNodes.INCLUDE, actionType: WorkflowNodes.INCLUDE, label: 'Group A', }, { id: uuidv4(), parentId: id, label: 'Everyone else', type: WorkflowNodes.EXCLUDE, actionType: WorkflowNodes.EXCLUDE }],
    };

    const [include, is] = createNode({ type: WorkflowNodes.INCLUDE, actionType: WorkflowNodes.INCLUDE, nodeType: WorkflowNodeTypes.SPLITTER, data: { label: 'Group A', includeSource: id }, position: {}, temp, translate });
    const [exclude, es] = createNode({ type: WorkflowNodes.EXCLUDE, actionType: WorkflowNodes.EXCLUDE, nodeType: WorkflowNodeTypes.SPLITTER, data: { label: 'Everyone else', excludeSource: id }, position: {}, temp, translate });

    children = [include, exclude];
    otherStyles = {
      [include.id]: {
        ...is,
        label: 'Group A',
      },
      [exclude.id]: {
        ...es,
        label: 'Everyone else'
      }
    }

  }

  const node = {
    id,
    type,
    ...(presets[type] || {}),
    data: {
      ...((dataPresets[type] || identity)({
        ...(data || {}),
        originId: uuidv4(),
        id,
      })),
    },
    children,
  };

  return [node, style, otherStyles];
};
