import { useTranslation } from '@hooks';

import { FiltersDropdown } from '@components/lib/SegmentEditor/components/FiltersDropdown';

import { Container, Description } from './styled';

import { AddFilterButton } from '../AddFilterButton';

const AddFilterPanel = ({ altButton, ...props }) => {
  const { p } = useTranslation('segments_page');

  if (altButton) {
    return (
      <FiltersDropdown
        {...props}
      >
        <AddFilterButton style={{ height: 30, width: '100%' }} disabled={props.disabled} />
      </FiltersDropdown>
    )
  }

  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" viewBox="0 0 116 116" fill="none">
        <path
          d="M58.5 102.123H18.6212C16.257 102.123 14.3485 100.215 14.3485 97.8503V18.0927C14.3485 15.7285 16.257 13.82 18.6212 13.82H54.2273V32.3351C54.2273 36.2661 57.4176 39.4564 61.3485 39.4564H79.8636V49.4261C79.8636 50.2236 80.4903 50.8503 81.2879 50.8503C82.0855 50.8503 82.7121 50.2236 82.7121 49.4261V38.0321C82.7121 38.0321 82.7121 37.9182 82.6836 37.8327C82.6836 37.7758 82.6836 37.7188 82.6552 37.6618C82.6552 37.6048 82.6552 37.5479 82.6267 37.4909C82.5697 37.32 82.4558 37.1491 82.3133 37.0351L56.6485 11.3988C56.6485 11.3988 56.3636 11.1709 56.1927 11.0855C56.1358 11.0855 56.0788 11.0855 56.0218 11.057C55.9648 11.057 55.9079 11.057 55.8509 11.0285C55.7939 11.0285 55.737 11 55.68 11H18.6212C14.6903 11 11.5 14.1903 11.5 18.1212V97.8788C11.5 101.81 14.6903 105 18.6212 105H58.5C59.2976 105 59.9242 104.373 59.9242 103.576C59.9242 102.778 59.2976 102.152 58.5 102.152V102.123ZM77.8412 36.6079H61.3485C58.9842 36.6079 57.0758 34.6994 57.0758 32.3351V15.8424L77.8412 36.6079Z"
          fill="#D3DBE7"/>
        <path
          d="M58.5 102.123H18.6212C16.257 102.123 14.3485 100.215 14.3485 97.8503V18.0927C14.3485 15.7285 16.257 13.82 18.6212 13.82H54.2273V32.3351C54.2273 36.2661 57.4176 39.4564 61.3485 39.4564H79.8636V49.4261C79.8636 50.2236 80.4903 50.8503 81.2879 50.8503C82.0855 50.8503 82.7121 50.2236 82.7121 49.4261V38.0321C82.7121 38.0321 82.7121 37.9182 82.6836 37.8327C82.6836 37.7758 82.6836 37.7188 82.6552 37.6618C82.6552 37.6048 82.6552 37.5479 82.6267 37.4909C82.5697 37.32 82.4558 37.1491 82.3133 37.0351L56.6485 11.3988C56.6485 11.3988 56.3636 11.1709 56.1927 11.0855C56.1358 11.0855 56.0788 11.0855 56.0218 11.057C55.9648 11.057 55.9079 11.057 55.8509 11.0285C55.7939 11.0285 55.737 11 55.68 11H18.6212C14.6903 11 11.5 14.1903 11.5 18.1212V97.8788C11.5 101.81 14.6903 105 18.6212 105H58.5C59.2976 105 59.9242 104.373 59.9242 103.576C59.9242 102.778 59.2976 102.152 58.5 102.152V102.123ZM77.8412 36.6079H61.3485C58.9842 36.6079 57.0758 34.6994 57.0758 32.3351V15.8424L77.8412 36.6079Z"
          fill="#D3DBE7"/>
        <path
          d="M21.8945 37.0317C21.8945 36.2341 22.5212 35.6074 23.3188 35.6074H43.2582C44.0557 35.6074 44.6824 36.2341 44.6824 37.0317C44.6824 37.8292 44.0557 38.4559 43.2582 38.4559H23.3188C22.5212 38.4559 21.8945 37.8292 21.8945 37.0317Z"
          fill="#D3DBE7"/>
        <path
          d="M23.3188 35.6074C22.5212 35.6074 21.8945 36.2341 21.8945 37.0317C21.8945 37.8292 22.5212 38.4559 23.3188 38.4559H43.2582C44.0557 38.4559 44.6824 37.8292 44.6824 37.0317C44.6824 36.2341 44.0557 35.6074 43.2582 35.6074H23.3188Z"
          fill="#D3DBE7"/>
        <path
          d="M21.5 49.4242C21.5 48.6267 22.1267 48 22.9242 48H42.8636C43.6612 48 44.2879 48.6267 44.2879 49.4242C44.2879 50.2218 43.6612 50.8485 42.8636 50.8485H22.9242C22.1267 50.8485 21.5 50.2218 21.5 49.4242Z"
          fill="#D3DBE7"/>
        <path
          d="M22.9242 48C22.1267 48 21.5 48.6267 21.5 49.4242C21.5 50.2218 22.1267 50.8485 22.9242 50.8485H42.8636C43.6612 50.8485 44.2879 50.2218 44.2879 49.4242C44.2879 48.6267 43.6612 48 42.8636 48H22.9242Z"
          fill="#D3DBE7"/>
        <path
          d="M23.3188 59.8506C22.5212 59.8506 21.8945 60.4773 21.8945 61.2748C21.8945 62.0724 22.5212 62.6991 23.3188 62.6991H51.8036C52.6012 62.6991 53.2279 62.0724 53.2279 61.2748C53.2279 60.4773 52.6012 59.8506 51.8036 59.8506H23.3188Z"
          fill="#D3DBE7"/>
        <path
          d="M82.7125 81.6832H82.2125V82.1832V89.3045C82.2125 89.8259 81.8097 90.2287 81.2883 90.2287C80.7669 90.2287 80.364 89.8259 80.364 89.3045V82.1832V81.6832H79.864H72.7428C72.2214 81.6832 71.8186 81.2804 71.8186 80.759C71.8186 80.2376 72.2214 79.8348 72.7428 79.8348H79.864H80.364V79.3348V72.2135C80.364 71.6921 80.7669 71.2893 81.2883 71.2893C81.8097 71.2893 82.2125 71.6921 82.2125 72.2135V79.3348V79.8348H82.7125H89.8337C90.3552 79.8348 90.758 80.2376 90.758 80.759C90.758 81.2804 90.3552 81.6832 89.8337 81.6832H82.7125ZM57.5762 80.759C57.5762 67.6757 68.205 57.0469 81.2883 57.0469C94.3715 57.0469 105 67.6757 105 80.759C105 93.8422 94.3715 104.471 81.2883 104.471C68.205 104.471 57.5762 93.8422 57.5762 80.759ZM59.4247 80.759C59.4247 92.8279 69.2194 102.623 81.2883 102.623C93.3572 102.623 103.152 92.8279 103.152 80.759C103.152 68.6901 93.3572 58.8954 81.2883 58.8954C69.2194 58.8954 59.4247 68.6901 59.4247 80.759Z"
          fill="#31C447" stroke="#31C447"/>
      </svg>
      <Description>
        {p('add_segment_rule_description')}
      </Description>
      <FiltersDropdown
        {...props}
      >
        <AddFilterButton />
      </FiltersDropdown>
    </Container>
  );
};

export default AddFilterPanel;
