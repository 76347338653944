import { useTranslation } from '@hooks';

import { TemplateTypes } from '@constants/templates';

import { by } from '@utils';

import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const PushDiscountNodeSettingsInfo = ({ node, options }) => {
  const promocode = options?.promo_codes?.find?.(by(node.data?.promo_code_id));
  const webhook = options?.[TemplateTypes.WEBHOOK]?.find?.(by(node.data?.api_request_id));
  const { t } = useTranslation('workflow_page');

  if (node.data?.period_type === undefined) {
    return (
      <Container>
        <InfoTitle>{t('labels.template')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  const resolveDuration = (duration, duration_unit) => {
    if (duration_unit === 'hours') {
      return duration / 60;
    }

    if (duration_unit === 'days') {
      return duration / 60 / 24;
    }

    return duration / 50;
  };

  return (
    <Container>
      <InfoRow padded condition={node.data?.type}>
        <InfoTitle>
          {t('labels.source')}:
        </InfoTitle>
        <InfoText>
          {+node.data?.type === 1 ? t('labels.webhook') : t('labels.url')}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={+node.data?.type === 0}>
        <InfoTitle>
          {t('labels.url')}:
        </InfoTitle>
        <InfoText>
          {node.data?.url}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={+node.data?.type === 0}>
        <InfoTitle>
          {t('labels.method')}:
        </InfoTitle>
        <InfoText>
          {node.data?.method}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={+node.data?.type === 1}>
        <InfoTitle>
          {t('labels.webhook')}:
        </InfoTitle>
        <InfoText>
          {webhook?.name}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={node.data?.period_type}>
        <InfoTitle>
          {t('labels.period_type')}:
        </InfoTitle>
        <InfoText>
          {+node.data?.period_type === 1 ? t('labels.custom') : t('labels.till_end_of_day')}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={+node.data?.period_type === 1}>
        <InfoTitle>
          {t('labels.duration')}:
        </InfoTitle>
        <InfoText>
          {resolveDuration(+node.data?.duration, node.data?.duration_unit)} {node.data?.duration_unit}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={node.data?.discount_type}>
        <InfoTitle>
          {t('labels.duration')}:
        </InfoTitle>
        <InfoText>
          {+node.data?.discount_type === 0 ? t('labels.percent') : t('labels.unit')}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={+node.data?.discount_type === 0}>
        <InfoTitle>
          {t('labels.discount')}:
        </InfoTitle>
        <InfoText>
          {node.data?.percentage} %
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={+node.data?.discount_type === 1}>
        <InfoTitle>
          {t('labels.discount_amount')}:
        </InfoTitle>
        <InfoText>
          {node.data?.amount} {node.data.currency}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={node.data?.priority}>
        <InfoTitle>
          {t('labels.priority')}:
        </InfoTitle>
        <InfoText>
          {node.data?.priority}
        </InfoText>
      </InfoRow>
      <InfoRow padded condition={node.data?.number_of_use}>
        <InfoTitle>
          {t('labels.number_of_use')}:
        </InfoTitle>
        <InfoText>
          {node.data?.number_of_use}
        </InfoText>
      </InfoRow>
      {promocode && (
        <InfoRow>
          <InfoTitle>
            {t('labels.promo_code')}:
          </InfoTitle>
          <InfoText>
            {promocode?.name}
          </InfoText>
        </InfoRow>
      )}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
}

export default PushDiscountNodeSettingsInfo;

