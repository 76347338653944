import styled from 'styled-components';

export const Container = styled.div`
  background: #DCDFE6;

  ${({ vertical }) => vertical ? `
    width: 1px;
    height: 100%;
  ` : `
    width: 100%;
    height: 1px;
  `}
`;
