const TextVariants = {
  HEADING_1: 'heading-1', // 20/Auto
  HEADING_2: 'heading-2', // 18/21px
  HEADING_2_AUTO: 'heading-2_a', // 18/Auto

  PARAGRAPH_1: 'p-1', // 16/130
  PARAGRAPH_2: 'p-2', // 13/120
  PARAGRAPH_3: 'p-3', // 10/100

  TITLE: 'title', // 13/100

  MENU_1: 'menu-1', // 14/100
  MENU_1_AUTO: 'menu-1-a', // 14/Auto

  BTN_1: 'btn-1', // 14/Auto
  BTN_2: 'btn-2', // 12/Auto
};

export const FontSizes = {
  [TextVariants.HEADING_1]: 20,
  [TextVariants.HEADING_2]: 18,

  [TextVariants.PARAGRAPH_1]: 16,
  [TextVariants.PARAGRAPH_2]: 13,
  [TextVariants.PARAGRAPH_3]: 10,

  [TextVariants.TITLE]: 13,

  [TextVariants.MENU_1]: 14,
  [TextVariants.MENU_1_AUTO]: 14,

  [TextVariants.BTN_1]: 14,
  [TextVariants.BTN_2]: 12,
};

export const LineHeights = {
  [TextVariants.HEADING_1]: 'auto',
  [TextVariants.HEADING_2]: '21px',
  [TextVariants.HEADING_2_AUTO]: 'auto',

  [TextVariants.PARAGRAPH_1]: '130%',
  [TextVariants.PARAGRAPH_2]: '120%',
  [TextVariants.PARAGRAPH_3]: '100%',

  [TextVariants.TITLE]: '100%',

  [TextVariants.MENU_1]: '100%',
  [TextVariants.MENU_1_AUTO]: 'Auto',

  [TextVariants.BTN_1]: 'Auto',
  [TextVariants.BTN_2]: 'Auto',
};

export default TextVariants;
