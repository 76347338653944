import { v4 as uuidv4 } from 'uuid';

import { SegmentNodeTypes } from '@constants';

export const createGroup = ({ children = [], logicalOperator }) => {
  return {
    type: SegmentNodeTypes.QUERY_GROUP,
    query: {
      children,
      logicalOperator,
      name: uuidv4(),
    },
  };
};
