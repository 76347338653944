import { useCurrentWorkspace, useNavigate } from '@hooks';

import { Paths } from '@constants';

import { useBreadcrumbs } from './breadcrumbsNavigation';
import { BreadcrumbLink, Container, Divider } from './styled';

const Breadcrumbs = () => {
  const app = useCurrentWorkspace();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs();

  const indexOption = {
    title: `${app?.name} Dashboard`,
    to: Paths.DASHBOARD,
  };

  const handleClick = (to) => () => {
    navigate(to, { replace: true });
  };

  return (
    <Container>
      {[indexOption, ...breadcrumbs].flatMap(({ title, to }, index, self) => [
        (
          <BreadcrumbLink key={index} $lighter={index === self.length - 1} onClick={handleClick(to)}>
            {title}
          </BreadcrumbLink>
        ),
        index === self.length - 1 ? void 0 : (
          <Divider key={title}>
            /
          </Divider>
        )
      ])}
    </Container>
  );
};

export default Breadcrumbs;
