import ReactApexChart from 'react-apexcharts';

import { Container } from './styled';

const state = (data, total, fullData, colors) => ({
  series: [
    {
      data: [0,0,0, ...data], //3 zero for align column by center
    },
  ],
  options: {
    chart: {
      width: '100%',
      height: 350,
      animations: {
        enabled: false,
      },
      axisBorder: {
        show: true,
        color: '#D3DBE7',
      },
      toolbar: {
        show: false,
      },
    },
    colors: [0, 0, 0, ...colors],
    dataLabels: {
      enabled: true,
      position: 'top',
      offsetY: -30,
      style: {
        fontSize: '14px',
        fontFamily: 'Manrope-Medium',
        colors: ['0','0','0', ...colors],
      },
      formatter: function (val) {

        return val === 0 ? '' : `${val} %`;
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Ensure it's a vertical bar chart
        borderRadius: '2',
        distributed: true, // Distribute the bars evenly across the x-axis
        columnWidth: '70%', // Adjust to control the width of the columns
        endingShape: 'rounded', // Optional: make the top of the columns rounded
        dataLabels: {
          position: 'top',
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter: (_seriesName, options) => {
            return fullData[options.dataPointIndex - 3]?.element;
          },
        },
        formatter: function (val) {
          return `${val} %`
        }
      },
      x: {
        show: false // Hide the x-axis tooltip header (series name)
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    xaxis: {
      data: ['', '', '',...data],
      categories: ['', '', '', ...data.map((item) => item / 100 * total), '', ''],
      type: 'category',
      width: '100%',
      tickPlacement: 'between',
      tooltip: {
        hideEmptySeries: false,
        enabled: false,
      },
      labels: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        style: {
          width: '100%',
          colors: '#7C94B1',
          cssClass: 'custom-apexcharts-xaxis-label',
        },
      },

      axisBorder: {
        show: true,
        color: '#D3DBE7',
      },
      dataLabels: {
        enabled: true,
      },
    },
    yaxis: {
      show: true,
      floating: true,
      labels: {
        
        align: 'left',
        offsetX: 30,
        offsetY: -10,
        style: {
          colors: '#7C94B1',
          cssClass: 'custom-apexcharts-yaxis-label',
        },
        formatter: function (val) {
          return `${val} %`
        },
      },
      tickAmount: 3,
    },
    grid: {
      borderColor: '#D3DBE7',
      strokeDashArray: 5,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: { left: 5, right: 5, top: 0, bottom: 0 },
    },
  },
});

export const ColumnChart = ({ data, colors }) => {
  const total = data.reduce((prev, curr) => prev + curr.count, 0);
  const series = data.map(({ count }) => (count / total * 100).toFixed(0));

  return (
    <Container>
      <ReactApexChart
        id="chart-column"
        width="100%"
        type='bar'
        options={state(series, total, data, colors).options}
        series={state(series, total, null, colors).series}
        height={350}
      />
    </Container>
  );
};
