import { useMemo } from "react";
import { useSelector } from "react-redux";

import omit from "lodash.omit";

import { WorkflowOptionsType } from "@hooks/useWorkflowOptions";

import { ReducerKeys } from "@/constants";
import { extract, identity } from "@/utils";

type FindableOption = { id: string | number };

type FilterOptions = {
  compare?: (r: any, id: string | number) => boolean;
};

const defaultOptions = {
  compare: (r: any, id: string | number) => r.id === id,
}

const useWorkflowOptionsPreview = (type: WorkflowOptionsType, ids: number | string | Array<number | string>, o: FilterOptions = defaultOptions) => {
  const optionsData = useSelector(store => store[ReducerKeys.WORKFLOW_OPTIONS].options?.[type]?.searches);
  const previewData = useSelector(store => store[ReducerKeys.WORKFLOW_OPTIONS].preview?.[type]);

  const options = {
    ...defaultOptions,
    ...o,
  };

  const idsArray = (Array.isArray(ids) ? ids : [ids]).filter(id => id !== undefined && id !== null);
  const idsHash = idsArray.join('');

  const resolvedFromOptions = useMemo(() => {
    return (Object.values(optionsData || {}) as FindableOption[])
      .flatMap(extract('data'))
      .filter((option: FindableOption) => !!~idsArray.findIndex(id => options.compare(option, id)));
  }, [optionsData, idsHash, type]);

  const unresolvedOptions = idsArray.filter(id => !~resolvedFromOptions.findIndex(option => options.compare(option, id)));
  const unresolvedOptionsHash = unresolvedOptions.join('');

  const resolvedFromPreview = useMemo(() => {
    return (Object.values(omit(previewData, ['loading']) || {}) as FindableOption[])
      .flatMap(identity)
      .filter((option: FindableOption) => !!~unresolvedOptions.findIndex(id => options.compare(option, id)));
  }, [previewData, unresolvedOptionsHash, type]);

  const result = idsArray.map(id =>
    resolvedFromPreview.find((option) => options.compare(option, id)) ||
    resolvedFromOptions.find((option) => options.compare(option, id))
  ).filter(identity);

  return Array.isArray(ids) ? result : result[0];
};

export default useWorkflowOptionsPreview;
