import laptopPreview from '@res/images/laptop.png';

import { PreviewContentFullScreen } from './PreviewContentFullScreen';
import { buttonSizeMap } from './PreviewContentFullScreen/PreviewContentFullScreen';
import { ButtonContainer } from './PreviewContentFullScreen/styled';
import { LaptopContainer, Image } from './styled';

import { Placeholder } from '../Placeholder';
import { PreviewButton } from '../PreviewButton';

export const FullScreenLaptop = ({
  format,
  title,
  message,
  layout,
  buttons,
  imageUrl,
}) => {
  const type = `fullScreenLaptop_${format}`;
  const hasButtonsName = buttons?.some((button) => button.name);

  const renderButtons = buttons?.map((button) => {
    if (!button.name) {
      return null;
    }

    return (
      <PreviewButton key={button.name} size={buttonSizeMap[type]} type={button.type}>
        {button.name}
      </PreviewButton>
    );
  });

  const descriptionConfig = {
    'fullScreenLaptop_portrait_image': '3:5 aspect ratio \n High-res 1200 x 2000 px \n Minimum 600 x 1000 px',
    'fullScreenLaptop_landscape_image': '5:3 aspect ratio \n High-res 2000 x 1200px \n Minimum 1000 x 600 px',
    'fullScreenLaptop_landscape_image-text': '10:3 aspect ratio \n High-res 2000 x 600px \n Minimum 1000 x 300 px',
    'fullScreenLaptop_portrait_image-text': '6:5 aspect ratio \n High-res 1200 x 1000 px \n Minimum 600 x 500 px',
  };

  return (
    <LaptopContainer>
      <PreviewContentFullScreen
        imageUrl={imageUrl}
        format={format}
        type={type}
        layout={layout}
        title={title}
        description={message}
        placeholder={
          <Placeholder
            type={type}
            layoutType={`${type}_${layout}`}
            title={'Image recomendations'}
            description={descriptionConfig[`${type}_${layout}`]}
          />
        }
        actionButton={
          (hasButtonsName) && (
            <ButtonContainer $hasContent={layout !== 'image'} $size={buttonSizeMap[type]}>
              {renderButtons}
            </ButtonContainer>
          )
        }
      />
      <Image src={laptopPreview} alt='mobile' />
    </LaptopContainer>
  );
};
