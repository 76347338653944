import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import { Button } from '@components/controls';

export const StyledButton = styled(Button)`
  background: transparent !important;
  border: 1px solid #303133 !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  font-family: Manrope-Medium !important;
  color: #303133 !important;
  padding: 6px 7px !important;
  height: 26px !important;
  position: relative;
  overflow: visible !important;
 
 ${styledProp('$fallback', css`
   background: #FFFAED !important;
   border: 1px solid #FFDC7B !important;
 `)}
 
 ${styledProp('$opened', css`
    background: #D3DBE7 !important;
    border-color: #DCDFE6 !important;
 `)}
 ${styledProp('$error', css`
    border-color: #C8402F !important;
 `)}
`;

export const ErrorContainer = styled.div`
 position: absolute;
 right: -20px; 
`;

export const FallbackIconContainer = styled.div`
 width: 14px;
 height: 14px;
 border-radius: 50%;
 background: #FFDC7B;
 display: flex;
 justify-content: center;
 align-items: center;
`;
