export const logicOperatortextconfig = {
  and: 'workflow_page.logical_operator_and_short',
  or: 'workflow_page.logical_operator_or_short',
};

export const formatDate = (stringDate) => {
  const parts = stringDate.split('at');

  if (parts?.length) {
    const endDate = parts[1].split(' - ');

    return `${parts[0]}  -  ${endDate[1]}`;
  } else {
    return stringDate;
  }
};
