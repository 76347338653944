import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { ReducerKeys } from '@constants';

import { clickhouseFields2Events } from '@utils/fields';

import { createSelector } from 'reselect';

export const segmentsSelector = store => store[ReducerKeys.SEGMENTS].segments;

export const segmentsLoadingSelector = store => store[ReducerKeys.SEGMENTS].loading;

//DEPRICATED:START use options reducer instead
export const segmentsOptionsSelector = store => store[ReducerKeys.SEGMENTS].options;

export const segmentsOptionsWithModifiedEvents = createSelector(
  segmentsOptionsSelector,
  clickhouseFieldsSelector,
  (options, clickhouseFields) => {
    if(!options.fields?.events) {
      return options;
    }

    return {
      ...options,
      fields: {
        ...options.fields,
        events: clickhouseFields2Events(clickhouseFields.data)?.data
      }
    }
  },
);

export const segmentOptionsAggregatesListSelector = store => store[ReducerKeys.SEGMENTS].options?.aggregates || [];
//DEPRICATED:END use options reducer instead

export const segmentEditMetadataSelector = store => store[ReducerKeys.SEGMENTS].editMetadata;

export const segmentFilterResultsSelector = store => store[ReducerKeys.SEGMENTS].filterResults;

export const segmentContactsExampleSelector = store => store[ReducerKeys.SEGMENTS].contactsExample;

export const segmentContactsExampleLoadingSelector = store => store[ReducerKeys.SEGMENTS].contactsExample.loading;

export const singleAggregateSelector = store => store[ReducerKeys.SEGMENTS].singleAggregate?.data;

export const aggregatesSelector = store => store[ReducerKeys.SEGMENTS]?.aggregates;

export const aggregatesLoadingSelector = store => store[ReducerKeys.SEGMENTS].aggregates.loading;

export const segmentFilterResultsLoadingSelector = store => store[ReducerKeys.SEGMENTS].filterResults.loading;

export const newSegmentPresetSelector = store => store[ReducerKeys.SEGMENTS].newPreset;

export const nestedAggregatesListSelector = store => store[ReducerKeys.SEGMENTS].nestedAggregates;

export const segmentCSVFilesSelector = store => store[ReducerKeys.SEGMENTS].csvFiles;
