import styled from 'styled-components';

export const Container = styled.div`
  background: #FFFAED;
  border: 1px solid #FFDC7B;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  
  & > span {
    text-decoration: underline;
    color: #31C447;
    cursor: pointer;
  }
`;
