const AnalyticsSection = () => {

  return null;

  // return (
  //   <Container>
  //     <In>{t('labels.in')} {formatNumberString(analytics.in)}</In>
  //     <Out>{t('labels.out')} {formatNumberString(analytics.out)}</Out>
  //   </Container>
  // );
}

export default AnalyticsSection;

