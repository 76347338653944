import { useContext, useRef } from 'react';
import { useSelector } from "react-redux";

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useModalState, useTranslation } from '@hooks';

import { findAttributes, replaceKeysWithValues, stringTemplate } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';

import { TemplatesGeneralAttributesDrawer , EmailSampleData, ToggleButton } from '@components';
import { SelectLanguage, MultiLanguageContext } from '@components/lib';
import { FallbackValuesModal } from "@components/modals/FallbackValuesModal";

import { Body, Button, Container, Content, Icon, Image, ImageContainer, PreviewContainer, PreviewData } from './styled.js';

import mobilePreview from '../../../res/images/mobile-preview.svg';

const ViberPreview = ({ data, onReplaceFallback, integrated, previewData, hideActions = false, icon, btn_name }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector)?.data || [];
  const fields = clickhouseFields2Customer(clickhouseFields).map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const { multiLangTabsController } = useContext(MultiLanguageContext) || {};
  const fallbackModal = useModalState();
  const exampleFields = useModalState();
  const subscriptionFields = useModalState();
  const discountFields = useModalState();
  const container = useRef(null);
  const { p } = useTranslation('templates');

  const handleToggleFieldsExample = () => {
    subscriptionFields.close();
    discountFields.close();
    exampleFields.toggle();
  };

  const handleDiscountToggle = () => {
    exampleFields.close();
    discountFields.toggle();
    subscriptionFields.close();
  };

  const formatMatches = () => {
    const cfg = {
      '\\`\\`\\`': props => <code {...props} />,
      '\\*': props => <b {...props} />,
      '_': props => <i {...props} />,
      '~': props => <del {...props} />,
    };

    return Object.entries(cfg)
      .flatMap(([matcher, render]) => (data
        ?.match(new RegExp(`(${matcher}.*${matcher})`, 'g')) || [])?.map(match => [matcher, match, render])
      ).reduce((acc, [matcher, match, render]) => ({
        ...acc,
        [match]: render({ children: match.replaceAll(new RegExp(matcher, 'g'), '') })
      }), {
        '\n': <br />
      });
  };

  const handleSubmitFallbacks = (values) => {
    const replaced = replaceKeysWithValues(values,['data', 'btn_name'], { data, btn_name });
    onReplaceFallback?.(t => ({ ...t, ...replaced }));
  }

  return (
    <Container>
      {!hideActions && (
        <PreviewContainer>
          <SelectLanguage 
            selectedLanguages={multiLangTabsController.languages} 
            onAddLang={multiLangTabsController.onAddLang} 
            handleOpenLang={multiLangTabsController.onOpenSelectLang}
          />
          {((!!data && data?.indexOf('{{customer.') !== -1) || (!!btn_name && btn_name?.indexOf('{{customer.') !== -1)) && (
            <ToggleButton
              testId="viber-fallback-toggle"
              opened={fallbackModal.opened}
              style={{ marginRight: 4 }}
              fallback
              onToggle={fallbackModal.open}
            >
              {p('fallback_values')}
            </ToggleButton>
          )}
          <ToggleButton
            testId="viber-attributes-toggle"
            opened={exampleFields.opened}
            style={{ marginRight: 12 }}
            onToggle={handleToggleFieldsExample}
          >
            {p('customer_attributes')}
          </ToggleButton>
          <ToggleButton
            testId="email-discount-toggle"
            style={{ marginRight: 10 }}
            opened={discountFields.opened}
            onToggle={handleDiscountToggle}
          >
            {p('general_attributes')}
          </ToggleButton>
        </PreviewContainer>
      )}
      <Content $integrated={integrated} ref={container}>
        <ImageContainer>
          <Image src={mobilePreview} />
          <PreviewData>
            {/*<Title>{title}</Title>*/}
            <Body>{stringTemplate(formatMatches(), (previewData?.body || data))}</Body>
            {icon && <Icon src={icon} />}
            {!!btn_name ? <Button>{previewData?.buttonName || btn_name}</Button> : null}
          </PreviewData>
        </ImageContainer>

        <EmailSampleData
          onClose={exampleFields.close}
          opened={exampleFields.opened}
          getContainer={() => container.current}
          width={'100%'}
          smaller
          onVisibilityChanged={exampleFields.setOpened}
        />
        <TemplatesGeneralAttributesDrawer
          onClose={discountFields.close}
          opened={discountFields.opened}
          getContainer={() => container.current}
          width={'100%'}
          hiddenTabs={['subscription']}
          small
          onVisibilityChanged={discountFields.setOpened}
        />

        <FallbackValuesModal
          opened={fallbackModal.opened}
          onClose={fallbackModal.close}
          testId="fallback-modal"
          onSubmit={handleSubmitFallbacks}
          fallbacks={findAttributes([data, btn_name], fields)}
        />
      </Content>
    </Container>
  );
}

export default ViberPreview;

