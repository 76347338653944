import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createEvent } from '@store/actions/creators';

import { Button } from '@velitech/ui';

import { useAppRedirects, useErrors, useTranslation } from '@hooks';

import { AppRedirects } from '@constants';

import { notify, sequence } from '@utils';

import { Input, Modal } from '@components';

import { Container } from './styled';

const CreateEventModal = ({ opened, onClose, onRefresh }) => {
  const dispatch = useDispatch();
  const { t, p } = useTranslation('events_page');
  const [name, setName] = useState('');
  const errors = useErrors();
  const redirect = useAppRedirects();

  const handleSubmit = () => {
    if (!(/^[a-zA-Z][a-zA-Z0-9_]*$/.test(name.trim()))) {
      return errors.setError('name', p('only_latin'));
    }

    errors.clearError('name');

    dispatch(createEvent({
      name,
      onSuccess: sequence(onRefresh, onClose, ({ data: { id } }) => {
        setName('');
        notify('success', p('event_created'), name);
        redirect({
          trigger: AppRedirects.EVENT_CREATION,
          data: { id },
        });
      }),
      onError: errors.handleRequestError,
    }));
  };

  const handleNameChange = ({ target: { value } }) => {
    errors.clear();
    setName(value);
  };

  const handleClose = () => {
    errors.clear();
    setName('');
    onClose();
  };

  return (
    <Modal
      opened={opened}
      style={{ maxWidth: 360 }}
      onClose={handleClose}
      title={p('create_event')}
      actions={(
        <>
          <Button data-testid={'create-events-cancel'} onClick={handleClose} variant="secondary" style={{ width: '140px' }}>{t('actions.cancel')}</Button>
          <Button data-testid={'create-events-submit'} style={{ marginLeft: 10, width: '140px' }} onClick={handleSubmit}>{t('actions.save')}</Button>
        </>
      )}
    >
      <Container data-testid={'create-events-modal'}>
        <Input
          wrapperStyles={{ marginBottom: '50px' }}
          title={t('labels.event_name')}
          data-testid="name-events-create-input"
          value={name}
          maxLength={50}
          error={errors.errors['name']}
          onChange={handleNameChange}
        />
      </Container>
    </Modal>
  );
};

export default CreateEventModal;
