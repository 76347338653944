import { WorkflowOptionsActionTypes } from '@store/actions/types';
import { asCreator, withCallbacks } from '@store/lib';

import uniq from 'lodash.uniq';

import { compose } from '@/utils';

export const getWorkflowOptions = compose(
  withCallbacks,
  asCreator(({ entity, page, perPage = 20, search }) => ({
    type: WorkflowOptionsActionTypes.GET,
    payload: { entity, page, perPage, search },
    meta: { entity, page, search },
  }))
);

export const previewWorkflowOptions = compose(
  withCallbacks,
  asCreator(({ entity, ids, resource_id }) => ({
    type: WorkflowOptionsActionTypes.PREVIEW,
    payload: { entity, ids: uniq(ids), resource_id },
    meta: { entity, ids: uniq(ids), resource_id },
  }))
)
