import styled, { css, keyframes } from 'styled-components';

import { TextVariants } from '@constants';

import { clamp, extract, styledProp } from '@utils';

import { Text } from '@components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 14px 14px 14px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-height: 160px;
  justify-content: space-between;
  
  ${styledProp('double', css`
    min-height: 320px;

    @media screen and (max-width: 1024px) {
      min-height: 252px;
    } 
  `)}

  position: relative;
`;

export const Title = styled(Text).attrs({ width: 160 })`
  font-family: Manrope-Medium;
  color: #909399;
`;

export const AnalyticsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Primary = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 26px;
  line-height: 31px;
  color: #303133;

  & > span.green {
    color: #24B277;
  }
`;

export const Secondary = styled(Text).attrs({ width: 100, variant: TextVariants.PARAGRAPH_2 })`
  text-align: right;
  color: #909399;
  
  & > span.black {
    color: #303133;
  }
  
  & > span.green {
    color: #24B277;
  }
`;

export const ChartContainer = styled.div`
  height: 60px;
  min-height: 60px;
  width: 42px;
  position: relative;
`;

const totalAnimation = keyframes`
  from {
    top: 60px;
  }
  to {
    top: 0;
  }
`;

const percentageAnimation = (percentage) => keyframes`
  from {
    top: 57px;
  }
  to {
    top: ${clamp(57 - (60 / 100 * percentage), 0, 60)}px;
  }
`;

export const TotalBar = styled.div`
  width: 28px;
  position: absolute;
  right: 0;
  z-index: 0;
  background: #DCDFE6;
  border-radius: 4px 4px 0px 0px;
  min-height: 3px;
  animation: ${totalAnimation} 1.5s ease forwards;
  bottom: 0;
`;

export const PercentageBar = styled.div`
  width: 28px;
  min-height: 3px;
  position: absolute;
  z-index: 1;
  left: 0;
  background: ${extract('color')};
  border-radius: 4px 4px 0px 0px;
  animation: ${({ percentage }) => percentageAnimation(percentage)} 1.5s ease forwards;
  bottom: 0;
`;

export const DonutContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 20px;
`;

export const SegmentsChart = styled.div`
  display: flex;
  align-items: flex-end;
  height: 185px;
  border-bottom: 1px solid #DCDFE6;
  width: 100%;
  padding: 0 20px;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
`;

export const SegmentBar = styled.div`
  width: 40px;
  margin: 0 2px;
  height: ${({ $height }) => $height * 185}px;
  background: ${extract('$color')};
  border-radius: 6px 6px 0 0;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 6px;
`;

export const SegmentPercentage = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  position: absolute;
  top: -16px;
  color: ${extract('$color')};
`;

export const SegmentNumber = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  color: #909399;
  position: absolute;
  bottom: -20px;
`;

export const ScaleIndicator = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: -10px;
  left: -10px;
  height: 0;
  overflow: visible;
  align-items: center;
  bottom: ${({ $height }) => $height * 185}px;
  border-top: 1px dashed #DCDFE6;
`;

export const ScaleIndicatorCaption = styled.div`
  background: #fff;
  font-family: Manrope-Medium;
  padding: 0 5px;
  font-size: 10px;
  line-height: 100%;
  text-align: right;
  color: #DCDFE6;
`;
