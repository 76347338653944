import styled, { css } from 'styled-components';

import { switchStyle, styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #fff;
  z-index: 10000;
  border-radius: 7px 7px 7px 7px;
  overflow: visible;

  ${styledProp('fullScreen', ``, `
    max-height: 95vh;
  `)}
`;

export const Title = styled.div`
  display: flex;
  ${styledProp('fullScreen', ``, css`
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  `)}
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 30px;
  border-radius: 7px 7px 0 0;
  position: relative;
  color: var(--text-primary-body, #1A232E);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 14px;
  line-height: 20px;
  ${switchStyle('appearance', {
    default: css`
      background: var(--Modals-modal-header-bg, #D3DBE7);
    `,
    danger: css`
      background: var(--Modals-modal-header-bg, #D3DBE7);
    `,
  })}
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 14px;
  padding-right: 12px;
`;

export const Content = styled.div`
  ${styledProp('statistics', css`
    padding: 18px 20px 0 20px;
    max-height: 440px;
    overflow-y: auto;
  `, css`
    padding: 30px 20px 0 20px;
  `)}
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const ActionsRow = styled.div`
  display: flex;  
  ${styledProp('centerFooter', css`
    justify-content: center;
  `, css`
   justify-content: space-between;
  `)}

    border-top: 1px solid var(--border-divider, #D3DBE7);
    padding: 12px 20px 12px 20px;
`;
