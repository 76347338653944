import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import { Device } from '@res';

export const IdType = styled.div`
  margin-top: 2px;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  color: #909399;
  margin-bottom: -4px;
  overflow: visible;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Overlay = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  overflow: auto;
  max-width: 600px;
  max-height: 400px;
  padding: 12px 10px 0;
`;

export const FilterActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //justify-content: flex-end;
`;

export const FiltersCol = styled.div`
  display: flex;
  padding: 16px 21px;
  flex-direction: column;
  justify-content: space-between;
  
  ${styledProp('row', css`
    flex-direction: row !important;
  `)}
`;

export const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const SearchFilter = styled.div`
  width: 345px;
  margin-right: 10px;
`;

export const DateInputsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DateFilter = styled.div`
  width: 162px;
`;

export const Container = styled.div`
    background-color: #FFF;
    padding-top: 50px;
    height: 100vh !important;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    display: flex;
    flex: 1;

    @media screen and ${Device.desktopS} {
        padding: 50px 0;
    }

    .status-select {
        width: 200px;
    }
`;

