import { SubscriptionGroupsActionTypes } from '@store/actions/types';
import { createRequestAction } from "@store/lib/utils/creators";

import { all, takeLatest, takeEvery, put } from 'redux-saga/effects';

function* addCustomersToGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/${action.payload.id}/customers`,
      body: {
        inputs: action.payload.inputs,
        fields: action.payload.fields,
      },
    })
  );
}

function* addGroupToGlobalSaga(action) {
  yield put(
    createRequestAction(action, {
      endpoint: `/api/subscribe-groups/${action.payload.entity}/set-globals`,
      method: 'post',
      body: {
        ids: action.payload.ids,
      }
    })
  );
}

function* deleteCustomersFormGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/${action.payload.id}/customers/delete`,
      body: {
        ids: action.payload.ids,
      }
    })
  );
}

function* deleteSubscriptionGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/${action.payload.id}`,
    })
  );
}

function* getGroupDownoadlHandlerSaga() {
  // yield FileDownload(action.payload, (action.meta.name + '_customers.csv'));
}

function* downloadGroupContactsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `/api/seg/customer/unsubscribe/export`,
    body: {
      isSubscribed: false,
      subscribe: false,
      subscribeId: action.payload.id,
      subscribeType: action.payload.entity,
      fields: action.payload.fields,
    }
  }))
}

function* duplicateSubscriptionGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/duplicate`,
      body: {
        id: action.payload.id,
        name: action.payload.name,
      },
    })
  );
}

function* exportAllToGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/export`,
      body: {
        import_id: action.payload.import_id,
        export_id: action.payload.export_id,
      }
    })
  );
}

function* exportCustomersToGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/${action.payload.id}/customers`,
      body: {
        fields: ['id'],
        inputs: action.payload.ids,
      }
    })
  );
}

function* getCustomersHintsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: '/api/customers/hints',
      params: { value: action.payload.value, field: action.payload.field },
    })
  );
}

function* getCustomSubscriptionGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/list`,
      params: { order: action.payload.order },
    })
  );
}

function* getDefaultSubscriptionGroupsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/default-list`,
    })
  );
}

function* getGroupsInGlobalSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/for-global`,
    })
  );
}

function* getSubscriptionGroupContactsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/seg/customer/unsubscribe/examples`,
      body: {
        aggregates: action.payload.aggregates,
        isSubscribed: false,
        subscribe: false,
        fields: action.payload.fields,
        subscribeType: action.payload.entity,
        subscribeId: +action.payload.id
      }
    })
  );
}

function* getSubscriptionGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/${action.payload.id}`,
    })
  );
}

function* importCustomersToGroupSaga(action) {
  const formData = new FormData();
  formData.append('name', action.payload.file.name);
  formData.append('file', action.payload.file);

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/subscribe-groups/${action.payload.entity}/${action.payload.id}/customers/import`,
      body: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
  );
}

function* saveSubscriptionGroupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/subscribe-groups/${action.payload.entity}`,
      body: {
        name: action.payload.name,
        id: +action.payload.id,
        description: action.payload.description,
        description_opt_out: action.payload.description_opt_out,
        in_global: action.payload.in_global,
      },
    })
  );
}

function* getSubscriptionGroupsCustomersCountSaga(action) {
  yield all(
    action.payload.ids.map(subscribeId => put(createRequestAction({ ...action, meta: { subscribeId } }, {
      method: 'post',
      endpoint: '/api/seg/customer/unsubscribe/examples/count',
      body: {
        subscribeId,
        subscribeType: 'EMAIL',
        isSubscribed: false,
        subscribe: false,
      }
    })))
  )
}

export default function* subscriptionGroupsRootSaga() {
  yield all([
    takeLatest(SubscriptionGroupsActionTypes.GET_DEFAULT, getDefaultSubscriptionGroupsSaga),
    takeLatest(SubscriptionGroupsActionTypes.GET_CUSTOM, getCustomSubscriptionGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.SAVE_GROUP, saveSubscriptionGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.GET_GROUP_CONTACTS, getSubscriptionGroupContactsSaga),
    takeLatest(SubscriptionGroupsActionTypes.DUPLICATE, duplicateSubscriptionGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.DELETE, deleteSubscriptionGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.GET, getSubscriptionGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.ADD_CUSTOMERS, addCustomersToGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.DOWNLOAD_CUSTOMERS, downloadGroupContactsSaga),
    takeLatest(SubscriptionGroupsActionTypes.DOWNLOAD_CUSTOMERS_SUCCESS, getGroupDownoadlHandlerSaga),
    takeLatest(SubscriptionGroupsActionTypes.EXPORT_CUSTOMERS, exportCustomersToGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.DELETE_CUSTOMERS, deleteCustomersFormGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.IMPORT_CUSTOMERS, importCustomersToGroupSaga),
    takeLatest(SubscriptionGroupsActionTypes.ADD_GROUP_TO_GLOBAL, addGroupToGlobalSaga),
    takeLatest(SubscriptionGroupsActionTypes.GET_GROUPS_IN_GLOBAL, getGroupsInGlobalSaga),
    takeLatest(SubscriptionGroupsActionTypes.GET_CUSTOMERS_HINT, getCustomersHintsSaga),
    takeLatest(SubscriptionGroupsActionTypes.EXPORT_ALL, exportAllToGroupSaga),
    takeEvery(SubscriptionGroupsActionTypes.GET_GROUPS_CUSTOMER_COUNT, getSubscriptionGroupsCustomersCountSaga),
  ]);
}
