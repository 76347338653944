import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const Title = styled.h2`
  text-align: center;
  color: #1A232E;

  font-family: Manrope-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 40px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  max-width: 466px;
  width: 100%;
  margin: 0 auto;
  gap: 10px;
  padding: 20px;
  margin-bottom: 20px;

  border-radius: 7px;
  border: 1px solid #D3DBE7;
  background: #FFF;
`
export const Text = styled.p`
  color: #7C94B1;
  font-family: Manrope-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
`

export const ColumnLarge = styled.div`
  display: flex;
  max-width: 70%;
  width: 100%;
`
export const ColumnSmall = styled.div`
  display: flex;
  max-width: 30%;
  width: 100%;
`

export const AddPathButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: 1px solid #1A232E;
`
