export const identity = i => i;

export const compose = (...functions) => {
  if (!functions.length) {
    return identity;
  }

  if (functions.length === 1) {
    return functions[0];
  }

  return functions.reduce((a, b) => (...args) => a(b(...args)));
};

export const pipe = (...functions) => {
  if (!functions.length) {
    return identity;
  }

  if (functions.length === 1) {
    return functions[0];
  }

  return functions.reduce((a, b) => (...args) => b(a(...args)));
};

export const call = (...args) => (f) => f?.(...args);

export const sequence = (...functions) => (...args) => functions.map(call(...args));

export const by = (...args) => {
  const argsClone = [...args];
  const value = argsClone.pop();
  const selector = argsClone.pop();

  return ({ [selector || 'id']: found }) => value === found;
};

export const display = (data, render) => render(data);

export const sum = (...terms) => terms.reduce((a, t) => a + t, 0);
export const mean = (...xs) => sum(...xs) / xs.length;

export const extract = key => ({ [key]: x }) => x;

export const update = (x, updater) => {
  if (typeof updater === 'function') {
    return updater(x);
  }

  if (typeof updater === 'object' && updater !== null && !Array.isArray(updater)) {
    return { ...x, ...updater };
  }

  return updater;
};

export const updateAt = (obj, path, updateFn) => {
  const keys = path.split('.');
  const lastIndex = keys.length - 1;

  const updatedObj = { ...obj };

  let currentObj = updatedObj;
  for (let i = 0; i < lastIndex; i++) {
    const key = keys[i];
    currentObj[key] = { ...currentObj[key] };
    currentObj = currentObj[key];
  }

  currentObj[keys[lastIndex]] = update(currentObj[keys[lastIndex]], updateFn);

  return updatedObj;
}

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const mapEntries = (o, mapFn, executor = 'map') => Object.fromEntries(Object.entries(o)[executor](mapFn));
