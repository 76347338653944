import { CampaignActionTypes, UserActionTypes, WorkspacesActionTypes } from '@store/actions/types';

const initialState = {
  campaigns: {
    loading: false,
  },
  campaignsCalendar: {
    loading: false,
  },
  editMetadata: {
    loading: false,
  },
  contacts: {
    loading: false,
  },
  cachedCampaign: {},
  notes: {
    loading: false,
  },
};

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CampaignActionTypes.LIST:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: true,
        },
      };
    case CampaignActionTypes.LIST_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...action.payload,
          loading: false,
        },
      };
    case CampaignActionTypes.LIST_ERROR:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: false,
        },
      };
    case CampaignActionTypes.LIST_CALENDAR:
      return {
        ...state,
        campaignsCalendar: {
          ...state.campaignsCalendar,
          loading: true,
        },
      };
    case CampaignActionTypes.LIST_CALENDAR_SUCCESS:
      return {
        ...state,
        campaignsCalendar: {
          ...state.campaignsCalendar,
          ...action.payload,
          loading: false,
        },
      };
    case CampaignActionTypes.LIST_CALENDAR_ERROR:
      return {
        ...state,
        campaignsCalendar: {
          ...state.campaignsCalendar,
          loading: false,
        },
      };
    case CampaignActionTypes.SET_CACHED_CAMPAIGN:
      return {
        ...state,
        cachedCampaign: {
          ...action.payload.data,
          ...action.meta,
        },
      }
    case CampaignActionTypes.CLEAR_CACHED_CAMPAIGN:
      return {
        ...state,
        cachedCampaign: {},
      }
    case CampaignActionTypes.EDIT:
      return {
        ...state,
        editMetadata: {
          loading: true,
        },
      };
    case CampaignActionTypes.EDIT_SUCCESS:
      return {
        ...state,
        editMetadata: {
          loading: false,
          ...action.payload,
        }
      };
    case CampaignActionTypes.EDIT_ERROR:
      return {
        ...state,
        editMetadata: {
          loading: false,
        },
      };
    case CampaignActionTypes.GET_CAMPAIGN_CONTACTS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
        },
      };
    case CampaignActionTypes.GET_CAMPAIGN_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          data: action.payload,
        },
      };
    case CampaignActionTypes.GET_CAMPAIGN_CONTACTS_ERROR:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
        },
      };
    case CampaignActionTypes.GET_CAMPAIGN_CONTACTS_IDS_SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          total: action.payload?.total,
        },
      };
    case CampaignActionTypes.GET_CAMPAIGN_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          loading: true,
        },
      };
    case CampaignActionTypes.GET_CAMPAIGN_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          loading: false,
          ...action.payload,
        },
      };
    case CampaignActionTypes.GET_CAMPAIGN_NOTES_ERROR:
      return {
        ...state,
        notes: {
          loading: false,
        },
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default campaignsReducer;

