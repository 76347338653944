import { useState } from 'react';

import {
  Container,
  Title,
  Description,
  Content,
  Column,
  ArrowContainer,
  ItemContainer,
  ItemTitle,
  ItemSubtitle,
  ItemContent,
} from './styled';

export const DROPDOWN_ICON_ID = 'node-dropdown-icon';
const ArrowIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M11 15.1251L4.125 8.2501L5.0875 7.2876L11 13.2001L16.9125 7.2876L17.875 8.2501L11 15.1251Z'
        fill='#7C94B1'
      />
    </svg>
  );
};
const Main = ({ key, title, description, withoutDropdown = false, children, testId, disabled = false }) => {
  const [opened, setOpened] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpened(!opened);
  };
  const hasChild = (children || (Array.isArray(children) && children?.length));
  return (
    <Container key={key} data-testid={testId} $opened={opened} $withoutDropdown={withoutDropdown} $disabled={disabled}>
      <Column id={DROPDOWN_ICON_ID} onClick={(hasChild && handleOpen) || (() => {})}>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {(children || hasChild) && <ArrowContainer $opened={opened}>
          <ArrowIcon />
        </ArrowContainer>}
      </Column>
      <Content>{children}</Content>
    </Container>
  );
};

const Item = ({ testId, type, title, subTitle, children, stretch }) => {
  return (
    <ItemContainer $stretch={stretch} $type={type} data-testid={testId}>
      {title && <ItemTitle>{title}</ItemTitle>}
      {subTitle && <ItemSubtitle>{subTitle}</ItemSubtitle>}
      <ItemContent>{children}</ItemContent>
    </ItemContainer>
  );
};

export const Dropdown = {
  Main,
  Item,
};
