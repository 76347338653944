import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { createAggregate, getAggregates } from '@store/actions/creators';
import { optionsAggregatesSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { Button } from '@velitech/ui';
import { Divider as ANTDivider, Menu, Tooltip } from 'antd';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import { v4 as uuid } from 'uuid';

import { useModalState, useTranslation, useSystemOptions } from '@hooks';
import { translateError } from '@hooks/useErrors';

import {
  FUNNEL_TYPE_ARRAY,
  FUNNEL_TYPE_EVENT,
  negationSelector, OPERATOR_EQUALS,
  SegmentsRuleTypes,
  TYPE_TEXT,
  UserPermissions
} from '@constants';

import {
  aggregateUsed,
  by, extract, generateRandomName,
  isAggregatesValid,
  isFunnel,
  resolveFilterType,
  testId,
  update,
  validateAggregates
  , isAggregate, notify, composeFilters } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';

import { MoreButton, CompletionPeriodSelect, WithPermissions, SearchSelect , ConfirmationModal } from '@components';
import { DateFilterPicker } from '@components/lib/SegmentEditor/components';
import { MenuItem } from "@components/ui/SmallTemplateItem/styled";

import { FilterRule, StrictOrderSwitch } from './components';
import {
  AddFunnelStepButton,
  Container,
  Content,
  Divider,
  Filters,
  Header,
  HeaderInfo,
  Left,
  Order,
  FunnelDateFilters,
  CompletionPeriodContainer,
  HeaderRow,
  HeaderCol, Right
} from './styled';

import { InfoIconContainer } from '../../../../inputs/Input/styled';
import { Icon } from '../../../../ui';

const FilterEditor = ({ query, onChange, root, funnels, aggregates, addFilter, onAggregatesChange, onFunnelsChange, onDelete, onDuplicate, showErrors }) => {
  const dispatch = useDispatch();
  const loadedAggregates = useSelector(optionsAggregatesSelector);
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const editAggregateModal = useModalState();
  const { p, t, e } = useTranslation('segments_page');
  const { id } = useParams();
  const [aggregateSaved, setAggregateSaved] = useState();
  const { updateOptions } = useSystemOptions();
  const isEvent = query.ruleType === SegmentsRuleTypes.EVENT;

  const handleNegationChange = (negation) => {
    onChange(q => ({ ...q, query: { ...q.query, negation: negation !== '0' } }));
  };

  const handleAggregateChange = (name, updater) => {
    onAggregatesChange(ags => ags.map(ag => ag.name !== name ? ag : update(ag, updater)));
  };

  const createFunnel = () => {
    const label = `funnel_${funnels.length + 1}`;

    onChange(q => ({
      ...q,
      query: {
        field: label,
        name: q.query.name,
        negation: q.query.negation,
        ruleType: SegmentsRuleTypes.FUNNEL,
        operator: 'equals',
        type: 'numeric',
        value: {
          type: 'scalar',
          value: 2,
        },
      },
    }));

    if (isEvent) {
      const firstStep = pick(query, ['filters', 'logicalOperator', 'field']);
      const hiddenFilter = { hidden: true, type: TYPE_TEXT, field: 'event', operator: OPERATOR_EQUALS, value: { type: 'scalar', value: query.field } };

      firstStep.filters = firstStep.filters.concat(hiddenFilter);

      return onFunnelsChange(fs => [
        ...fs,
        {
          label,
          field: '_events',
          ruleType: query.ruleType,
          type: FUNNEL_TYPE_EVENT,
          dateTimeProperty: 'created_at',
          strict: true,
          steps: [
            {
              ...firstStep,
              field: '_events',
              name: 'Funnel step 1',
              wasPerformed: !query.filters?.filter?.(({ hidden }) => !hidden)?.length
            },
            {
              field: '_events',
              logicalOperator: 'and',
              wasPerformed: true,
              filters: [hiddenFilter],
              name: 'Funnel step 2',
            }
          ],
        },
      ]);
    }

    onFunnelsChange(fs => [
      ...fs,
      {
        label,
        field: query.field,
        type: FUNNEL_TYPE_ARRAY,
        ruleType: query.ruleType,
        strict: true,
        steps: [
          {
            ...pick(query, ['filters', 'logicalOperator', 'field']),
            name: 'Funnel step 1',
            ...(query.filters?.length ? {} : {
              filters: [{ value: { type: 'scalar', value: { type: 'scalar' } }, operator: '', type: null }]
            })
          },
          {
            logicalOperator: 'and',
            filters: [{ value: { type: 'scalar', value: { type: 'scalar' } }, operator: '', type: null }],
            name: 'Funnel step 2',
          }
        ],
      },
    ]);
  };

  const handleFunnelChange = (label, updater) => {
    const updated = funnels.map(f => {
      if (f.label !== label) {
        return f;
      }
      const updated = update(f, updater);

      onChange(q => ({
        ...q,
        query: {
          ...q.query,
          value: {
            ...q.query.value,
            value: updated.steps.length,
          },
        },
      }));

      return updated;
    });

    onFunnelsChange(updated);
  };

  const handleDeleteFunnelStep = (funnel, index) => {
    if (query.value.value === 1) {
      return onDelete();
    }

    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        value: {
          ...q.query.value,
          value: q.query.value.value - 1,
        },
      },
    }));
    handleFunnelChange(funnel, f => ({
      ...f,
      dateTimeProperty: '',
      completionPeriod: undefined,
      steps: f.steps.filter((_, i) => i !== index).map(({ name, ...other }, index) => ({
        name: /^Funnel step \d+$/.test(name) ? `Funnel step ${index + 1}` : name,
        ...other,
      })),
    }));
  };

  const handleAddFunnelStep = () => {
    if (query.ruleType !== SegmentsRuleTypes.FUNNEL) {
      return createFunnel();
    }

    return handleFunnelChange(query.field, f => ({
      ...f,
      steps: [
        ...f.steps,
        {
          field: f.steps[0].field,
          logicalOperator: 'and',
          wasPerformed: f.type === FUNNEL_TYPE_EVENT,
          filters: f.type === FUNNEL_TYPE_ARRAY ?
            [{ value: { type: 'scalar', value: { type: 'scalar' } }, operator: '', type: null }]
            : [{ hidden: true, type: TYPE_TEXT, field: 'event', operator: OPERATOR_EQUALS, value: { type: 'scalar', value: f.steps[0]?.filters?.find?.(extract('hidden')).value.value } }],
          name: `Funnel step ${f.steps.length + 1}`
        }
      ],
    }));
  };

  const handleSaveAggregate = () => {
    const aggregate = aggregates.find(by('name', query.field));
    const name = generateRandomName();

    dispatch(createAggregate({
      ...aggregate,
      filters: composeFilters(aggregate.filters),
      segmentId: id === 'new' ? undefined : id,
      name,
      id: void 0,
      onSuccess: (data) => {
        notify('success', t('labels.success'), p('aggregate_created_successfully'));
        setAggregateSaved(true);
        dispatch(getAggregates());
        updateOptions();
        handleAggregateChange(query.field, ag => ({ createdId: '', ...ag, ...data }));
        onChange(q => ({ ...q, query: { ...q.query, field: name } }));
      },
      onError: (error) => {
        const label = error.response?.data?.errors?.aggregates?.[0]?.label || error.response?.data?.errors?.label?.[0];

        if (label) {
          notify('error', t('labels.error'), translateError(label, e))
          onChange(q => ({ ...q, query: { ...q.query, errors: { ...(q.query.errors || {}), label: translateError(label, e) } } }));
        }
        if (error.response?.data?.errors?.aggregate) {
          notify('error', t('labels.error'), translateError(error.response?.data?.errors?.aggregate, e));
          onChange(q => ({ ...q, query: { ...q.query, errors: { ...(q.query.errors || {}), label: translateError(error.response?.data?.errors?.aggregate, e) } } }));
        }
      }
    }))
  };

  const handleEditAggregate = () => {
    const aggregate = loadedAggregates.find(by('name', query.field));
    const id = uuid();
    const label = `Copy ${aggregate.label}`;
    setAggregateSaved(false);
    const name = generateRandomName();

    onChange(q => ({ ...q, query: { ...q.query, field: name } }))
    onAggregatesChange(ags => {
      const removeAt = (ags || []).findIndex(({ name }) => name === query.field);

      let removed = [...(ags || [])];

      const used = aggregateUsed(query.field, root, [query.name]);

      if (!used) {
        removed = [...(ags || []).slice(0, removeAt), ...(ags || []).slice(removeAt + 1)];
      }

      return [...removed, {
        logicalOperator: 'and',
        type: '',
        filters: [],
        attribute: null,
        aggregate: 'count',
        name,
        ...omit(aggregate, ['id', 'name']),
        aggregateMeta: {},
        label,
        createdId: id,
      }];
    });
    editAggregateModal.close();
  };

  const aggregateExist = !!loadedAggregates?.find?.(by('name', query.field));
  const aggregateDefault = loadedAggregates?.find?.(by('name', query.field))?.default;
  const funnel = funnels.find(by('label', query.field));
  const field = clickhouseFields2Customer(clickhouseFields.data)?.find?.(by('field', funnel?.field));
  const filterOptions = field?.payload?.map?.(({ field, label, type }) => ({ value: field, label, type })).filter(({ type }) => type === 'date' || type === 'datetime') || [];

  const handleFunnelDateChange = (date) => {
    handleFunnelChange(query.field, f => ({
      ...f,
      dateTimeProperty: f.type === FUNNEL_TYPE_ARRAY ? f.dateTimeProperty : 'created_at',
      dateRange: { value: date, type: 'scalar' },
    }));
  };

  const handleSetFunnelToLifetime = () => {
    handleFunnelChange(query.field, f => ({
      ...f,
      dateRange: void 0,
      steps: f.steps.map(s => ({
        ...s,
        filters: s.filters.filter(f => f.special !== 'funnel_date_filter')
      })),
    }));
  };

  const handleArrayFunnelDateFieldChange = (field) => {
    handleFunnelChange(query.field, f => ({
      ...f,
      dateTimeProperty: field,
      steps: f.steps.map(s => ({
        ...s,
        filters: s.filters.map(f => f.special === 'funnel_date_filter' ? {
          ...f,
          field,
        } : f),
      })),
    }));
  };

  const handleCompletionPeriodChange = (updater) => {
    handleFunnelChange(query.field, f => ({
      ...f,
      completionPeriod: update(f.completionPeriod, updater),
    }));
  };

  const tid = testId('store-segment-filter-editor-container');
  const aggregate = aggregates?.find?.(by('name', query.field));

  return (
    <Container {...tid(query.name)}>
      <Header {...tid(`header-${query.name}`)}>
        <Order
          $funnel={isFunnel(query)}
          {...tid(`order-${query.name}`)}
        >
          {query.name}
        </Order>
        <HeaderCol>
          <HeaderRow>
            <Left>
              <Divider />
              <HeaderInfo {...tid(`header-info-${query.name}`)}>
                {p('customers')}
                <SearchSelect
                  testId={tid(`negation-select-${query.name}`).toString()}
                  value={query.negation ? '1' : '0'}
                  options={negationSelector.map(ns => ({ ...ns, value: ns.value ? '1' : '0', label: p(ns.label) }))}
                  style={{ fontSize: 14 }}
                  containerStyle={{ width: '140px', display: 'flex', alignItems: 'center', fontSize: '5px', lineHeight: 5 }}
                  wrapperStyles={{ marginRight: '30px', marginLeft: '10px' }}
                  onChange={handleNegationChange}
                  smaller
                />
                {p('in_this')} {p(resolveFilterType(query)).toLowerCase()}
              </HeaderInfo>
            </Left>
            <Right>
              {isFunnel(query) && (
                <StrictOrderSwitch
                  {...tid(`strict-order-${query.name}`)}
                  checked={funnel?.strict}
                  onChange={(strict) => handleFunnelChange(funnel.label, { strict })}
                />
              )}
              {/*@TODO: refactor*/}
              {isAggregate(query) && !aggregateSaved && !aggregateExist ? (
                <WithPermissions name={UserPermissions.CREATE_AGGREGATES}>
                  <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                    <Button
                      style={{ height: 30, fontSize: 12, lineHeight: 14, overflow: 'hidden' }}
                      onClick={handleSaveAggregate}
                      fluid
                      disabled={aggregateSaved || (aggregate && !isAggregatesValid(validateAggregates({ aggregates: [aggregate] })))}
                    >
                      {p('save_aggregate')}
                    </Button>
                  </div>
                </WithPermissions>
              ) : aggregateExist && (
                <WithPermissions name={UserPermissions.UPDATE_AGGREGATES}>
                  <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                    <Button
                      style={{ width: 120, height: 30, fontSize: 12, lineHeight: 14, overflow: 'hidden' }}
                      variant="secondary"
                      outline={true} size={'sm'}
                      onClick={editAggregateModal.open}
                      disabled={aggregateDefault}
                    >
                      {p('edit_aggregate')}
                    </Button>
                  </div>
                </WithPermissions>
              )}
              <ConfirmationModal
                opened={editAggregateModal.opened}
                onClose={editAggregateModal.close}
                title={p('editing_aggregate')}
                appearance="default"
                text={p('edit_aggregate_description')}
                onCancel={editAggregateModal.close}
                onSubmitClick={handleEditAggregate}
                buttonsParams={{
                  cancelAppearance: 'secondary',
                  submitAppearance: 'default',
                  cancelLabel: t('actions.cancel'),
                  submitLabel: p('create_now'),
                }}
              />
              <MoreButton
                testId={tid(`more-icon-${query.name}`).toString()}
                placement="bottomRight"
                iconSize={26}
                style={{ alignSelf: 'center', marginRight: 12, marginLeft: 10, marginTop: 2 }}
                menu={(
                  <Menu style={{ borderRadius: '10px' }}>
                    <Menu.Item>
                      <MenuItem onClick={onDuplicate} {...tid(`menu-duplicate-${query.name}`)}>{t('actions.duplicate')}</MenuItem>
                    </Menu.Item>
                    <ANTDivider style={{ margin: 0 }} />
                    <Menu.Item>
                      <MenuItem danger onClick={onDelete} {...tid(`menu-delete-${query.name}`)}>{t('actions.delete')}</MenuItem>
                    </Menu.Item>
                  </Menu>
                )}
              />
            </Right>
          </HeaderRow>
          {isFunnel(query) && (
            <HeaderRow style={{ borderTop: '1px solid #CCD7FF' }}>
              <CompletionPeriodContainer>
                <CompletionPeriodSelect
                  disabled={funnel.type === FUNNEL_TYPE_ARRAY && !funnel.dateTimeProperty}
                  value={funnel.completionPeriod}
                  onChange={handleCompletionPeriodChange}
                />
              </CompletionPeriodContainer>
              <FunnelDateFilters>
                {funnel.type === FUNNEL_TYPE_ARRAY && (
                  <SearchSelect
                    options={filterOptions}
                    smaller
                    title={t('labels.select_date_field')}
                    withoutLabel={true}
                    style={{ height: 26, width: 140, marginLeft: 20 }}
                    onChange={handleArrayFunnelDateFieldChange}
                    value={funnel.dateTimeProperty}
                  />
                )}
                {funnel.type === FUNNEL_TYPE_ARRAY && (
                  <Tooltip title={p('filtered_by_description')}>
                    <InfoIconContainer>
                      <Icon color="#909399" name="Info-icon" size={14} />
                    </InfoIconContainer>
                  </Tooltip>
                )}
                <DateFilterPicker
                  style={{ marginLeft: 20 }}
                  disabled={funnel.type === FUNNEL_TYPE_ARRAY && !funnel?.dateTimeProperty}
                  value={funnel?.dateRange?.value}
                  onChange={handleFunnelDateChange}
                  onSetToLifetime={handleSetFunnelToLifetime}
                />
              </FunnelDateFilters>
            </HeaderRow>
          )}
        </HeaderCol>
      </Header>

      <Content {...tid(`content-${query.name}`)}>
        <Filters>
          <FilterRule
            query={query}
            aggregateSaved={aggregateSaved}
            onChange={onChange}
            funnels={funnels}
            addFilter={addFilter}
            showErrors={showErrors}
            onDeleteFilter={onDelete}
            onFunnelChange={handleFunnelChange}
            onAggregateChange={handleAggregateChange}
            aggregates={aggregates}
            onDeleteFunnelStep={handleDeleteFunnelStep}
          />
        </Filters>
        {(query.ruleType === SegmentsRuleTypes.ARRAY || query.ruleType === SegmentsRuleTypes.EVENT || query.ruleType === SegmentsRuleTypes.FUNNEL)
          && (query.logicalOperator === 'and' || query.logicalOperator === 'or' || query.ruleType === SegmentsRuleTypes.FUNNEL) && (
          <AddFunnelStepButton>
            <Button
              data-testid={tid(`add-funnel-step-button-${query.name}`).toString()}
              style={{ height: 26, fontSize: 12 }}
              size={'sm'}
              width={186}
              onClick={handleAddFunnelStep}
              variant='primary'
              outline={true}
            >
              {p('add_funnel_step')}
            </Button>
          </AddFunnelStepButton>
        )}
      </Content>
    </Container>
  );
};

export default FilterEditor;
