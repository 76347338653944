import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';

import {
  authenticatedSelector,
  demoWorkspacesSelector,
  loggedInSelector,
  sidebarOpenedSelector
} from '@store/selectors';

import { Paths } from '@constants';

import { hashAppId, isSameAppId, removeAppFromPath, removeLeadingSlash } from '@utils';

import { MainLoader } from './layouts/MainLoader';
import {
  DashboardPage,
  NotFoundPage,
  NotificationHistoryPage,
  FacebookRedirect, SwitchAppPage,
} from './pages';

window.Pusher = require('pusher-js');

const AnalyticsScene = React.lazy(() => import('./pages/AnalyticsScene'));
const TemplatesScene = React.lazy(() => import('./pages/TemplatesScene'));
const WorkflowScene = React.lazy(() => import('./pages/WorkflowScene'));
const FlowScene = React.lazy(() => import('./pages/FlowScene'));
const CampaignsScene = React.lazy(() => import('./pages/CampaignsScene'));
const SettingsScene = React.lazy(() => import('./pages/SettingsScene'));
const SegmentsScene = React.lazy(() => import('./pages/SegmentsScene'));
const CustomersScene = React.lazy(() => import('./pages/CustomersScene'));

const HomeScene = () => {
  const { appId } = useParams()
  const user = useSelector(loggedInSelector);
  const demoWorkspaces = useSelector(demoWorkspacesSelector);
  const authenticated = useSelector(authenticatedSelector);
  const opened = useSelector(sidebarOpenedSelector);

  if (!authenticated) {
    return <Navigate to={Paths.LOGIN} />;
  }

  function getAppIdFromPathname(pathname) {
    const parts = pathname.split('/');
    if (parts.length > 2 && parts[1] === 'app') {
      return parts[2];
    }

    return null;
  }

  const aid = getAppIdFromPathname(window.location.pathname) || appId;

  if (!isSameAppId(user?.app?.id, aid) && !/^\/app\/[^/]+\/switch$/.test(window.location.pathname) && user?.app?.id) {
    const id = (user?.app_options || []).find(id => isSameAppId(id, aid));
    const demoId = (demoWorkspaces?.data || []).find(({ id }) => isSameAppId(id, aid))?.id;

    if (!id && !demoId) {
      return <Navigate to={`/app/${hashAppId(user?.app?.id)}${removeAppFromPath(window.location.pathname + window.location.search)}`} />
    }

    return <Navigate to={`/app/${aid}/switch?r=${encodeURIComponent(removeAppFromPath(window.location.pathname + window.location.search))}`} />
  }

  return (
    <React.Suspense fallback={<MainLoader sidebarOpened={opened} />}>
      <Routes>
        <Route path="analytics/*" element={<AnalyticsScene />} />
        <Route path="templates/*" element={<TemplatesScene />} />
        <Route path="workflows/*" element={<WorkflowScene />} />
        <Route path="flow/*" element={<FlowScene />} />
        <Route path="instant-send/*" element={<CampaignsScene />} />
        <Route path="settings/*" element={<SettingsScene />} />
        <Route path="resources/*" element={<SegmentsScene />} />
        <Route path="players/*" element={<CustomersScene />} />

        <Route path="dashboard" element={<DashboardPage />} />

        {/*<Route path={removeLeadingSlash(Paths.PROMO_CODES)} element={<PromoCodesPage />} />*/}

        {/*<Route path={removeLeadingSlash(Paths.EVENTS)} element={<EventsPage />} />*/}

        <Route path={removeLeadingSlash(Paths.NOTIFICATIONS)} element={<NotificationHistoryPage />} />

        <Route path={removeLeadingSlash(Paths.NOT_FOUND)} element={<NotFoundPage />} />
        <Route path="switch" element={<SwitchAppPage />} />

        <Route index element={<Navigate to="dashboard" />} />

        <Route path="redirect_from_facebook" element={<FacebookRedirect />} />
      </Routes>
    </React.Suspense>
  );
};

export default HomeScene;
