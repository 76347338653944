import { Button as ANTButton } from 'antd';
import styled, { css } from 'styled-components';

import { switchStyle, styledProp } from '@utils';

import { Colors } from '@res/theme';

export const StyledButton = styled(ANTButton)`
  outline: none;
  border: none;
  border-radius: 7px;
  font-family: Manrope-SemiBold !important;
  height: 36px;
  line-height: 14px;
  font-size: 14px;
  text-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  
  & > span {
    //max-height: 36px;
    display: block;
  }
  
  ${switchStyle('appearance', {
    default: css`
      background: ${Colors.Common.BUTTON_DEFAULT};
    `,
    'default-outlined': css`
      background: transparent !important;
      border: 1px solid #31C447;
      color: #31C447 !important;
            
      &:hover {
        opacity: 0.8 !important;
        background: transparent !important;
        border: 1px solid #31C447 !important;
        color: #31C447;
      }
    `,
    removeFile: `
     background: #FFFFFF !important;
     color: #303133 !important;
     border: none !important;
    `,
    danger: css`
      background: #FFFFFF !important;
      border: 1px solid #F04438;
      color: #F04438;
      
      &:hover {
        color: #F04438;
        border: solid 1px #F04438;
      }
      
      &:focus {
        color: #F04438;
      }
    `,
    'danger-outlined': css`
      background: transparent !important;
      border: 1px solid #F04438 !important;
      color: #F04438 !important;
            
      &:hover {
        //opacity: 0.8 !important;
        background: transparent !important;
        //border: 1px solid #F04438 !important;
        //color: #F04438;
      }

      &:disabled {
        opacity: 0.6 !important;
        color: #C8402F !important;

        //&:hover {
        //  color: #C8402F !important;
        //}
      }
    `,
    secondary: css`
      background: #D3DBE7 !important;
      color: #303133;
      border: 1px solid transparent !important;
      
      &:hover {
        color: #303133;
      }
      
      &:active {
        color: #303133;
      }
      
      &:focus {
        color: #303133;
      }
    `,
    brighter: css`
      background: #ECF0F6!important;
      color: #1A232E;
      border: 1px solid transparent !important;
      
      &:hover {
        color: #303133;
      }
      
      &:active {
        color: #303133;
      }
      
      &:focus {
        color: #303133;
      }
    `,
    
    outlined: css`
      border: 1px solid #303133;
      border-radius: 7px;
      background-color: transparent !important;
      background: transparent !important;
      color: #303133;

      &:hover {
        color: #303133;
        border: 1px solid #303133 !important;
      }
      
      &:active {
        color: #303133;
        border: 1px solid #303133 !important;
      }
      
      &:focus {
        color: #303133;
        border: 1px solid #303133 !important;
      }

      &:disabled { 
        color: #303133 !important;
        &:hover {
          border: 1px solid #d9d9d9 !important;
          color: #303133 !important;
        }
      }
    `,
    discard: css`
      border: none;
      border-radius: 7px;
      background-color: #D3DBE7!important;
      background: #D3DBE7 !important;
      color: #303133;

      &:hover {
        color: #303133;
        border: none;
        
        background: var(--buttons-secondary-bg-pressed, #A1B3C8) !important; 
        &[disabled] {
          background-color: #D3DBE7!important;
        }
      }
      
      &:active {
        color: #303133;
        border: none;
      }
      
      &:focus {
        color: #303133;
        border: none;
      }

      &:disabled {
        color: var(--buttons-disabled-txt, #A1B3C8);
        background: var(--buttons-disabled-bg, #ECF0F6);
        
      }
    `,
    'discard-light': css`
          border: none;
          border-radius: 7px;
          background-color: rgb(236, 240, 246) !important;
          color: #303133;

          &:hover {
              color: #303133;
              border: none;

              background: var(--buttons-secondary-bg-pressed, #D3DBE7) !important;
              &[disabled] {
                  background-color: #D3DBE7!important;
              }
          }

          &:active {
              color: #D3DBE7;
              border: none;
          }

          &:focus {
              color: #D3DBE7;
              border: none;
          }

          &:disabled {
              color: var(--buttons-disabled-txt, #A1B3C8);
              background: var(--buttons-disabled-bg, #ECF0F6);
          }
      `,
    'add-entity': css`
      background: #D3DBE7 !important;
      color: #1A232E !important;
      
      &:hover {
        background: #31C447 !important;
        color: #FFFFFF !important;
      }
    `, 
    'neutral-small': css`
        background: #D9D9D9 !important;
        height: 28px !important;
        font-size: 12px;
        color: var(--text-primary-body, #1A232E);

        &:hover {
            background: var(--buttons-secondary-bg-pressed, #A1B3C8) !important;
            color: #FFFFFF !important;
        }
    `,
    'outlined-colored': css`
      border: 1px solid #31C447;
      border-radius: 7px;
      background-color: transparent !important;
      background: transparent !important;
      color: #31C447 !important;
      
      &[disabled] {
        color: #31C447 !important;
      }

      //&:hover {
      //  color: #e46158 !important;
      //  &[disabled] {
      //    color: #e46158 !important;
      //  }
      //  border: 1px solid #e46158 !important;
      //}
      
      &:active {
        color: #31C447 !important;
        &[disabled] {
          color: #31C447 !important;
        }
        border: 1px solid #31C447 !important;
      }
      
      &:focus {
        color: #31C447 !important;
        &[disabled] {
          color: #31C447 !important;
        }
        border: 1px solid #31C447 !important;
      }
    `,
  })}
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6 !important;
  `)}

  //&:hover, &:active {
  //  //outline: none;
  //  border: 1px solid #e46158;
  //}

  &:disabled {
    opacity: 0.5;
  }
`;
