import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import { by, extract, update } from '@utils';

import { EditableTitle } from '@components';

import { Container, Divider, StepContainer, DeleteStepButton } from './styled';

import { ArraySelect } from '../ArraySelect';
import { EventSelect } from '../EventSelect';

const FunnelSelect = ({ query, onChange, funnels, onDeleteStep, showErrors }) => {
  const { p } = useTranslation('segments_page');
  const funnel = funnels.find(by('label', query.field));

  const handleArrayStepChange = (index) => (updater) => {
    const step = funnel?.steps[index];
    const { query: { field, ...updatedQuery } } = update({ query: step }, updater);
    const shouldResetFilters = funnel.field !== field;

    onChange(query.field, funnel => ({
      ...funnel,
      field: field || funnel.field,
      steps: funnel.steps
        .map((step, i) => i !== index ? step : update(step, updatedQuery))
        .map(s => shouldResetFilters ? ({ ...s, filters: [{ field: '', operator: '', value: { type: 'scalar' } }] }) : s),
    }));
  };

  const handleEventStepChange = (index) => (updater) => {
    const step = funnel?.steps[index];
    const cs = {
      ...step,
      field: (step.filters || []).find(extract('hidden'))?.value?.value
    };
    const { query: { field, ...updatedStep } } = update({ query: cs }, updater);

    onChange(query.field, funnel => ({
      ...funnel,
      steps: funnel.steps
        .map((step, i) => i !== index ? step : update(step, {
          ...updatedStep,
          filters: (updatedStep.filters || []).filter(({ hidden }) => !hidden).concat((step.filters || []).filter(extract('hidden'))),
          field: '_events'
        }))
        .map((step, i) => i !== index ? step : {
          ...step,
          filters: step.filters.map((f) => !f.hidden ? f : {
            ...f,
            value: {
              ...f.value,
              value: field,
            }
          })
        }),
    }))
  };

  const handleStepNameChange = (index) => ({ target: { value } }) => {
    onChange(query.field, funnel => ({
      ...funnel,
      steps: funnel.steps.map((step, i) => index !== i ? step : ({ ...step, name: value })),
    }));
  };

  const handleDeleteFunnelStep = (index) => () => {
    onDeleteStep(query.field, index);
  };

  return (
    <Container>
      {funnel?.steps.map((step, index, self) => {
        if (funnel.ruleType === SegmentsRuleTypes.ARRAY) {
          return (
            <StepContainer key={index}>
              <Tooltip title={p('delete_funnel_step')}>
                <DeleteStepButton data-testid="funnel-select-delete-btn" onClick={handleDeleteFunnelStep(index)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.25 5.25H6.125V10.5H5.25V5.25Z" fill="#C8402F"/>
                    <path d="M7.875 5.25H8.75V10.5H7.875V5.25Z" fill="#C8402F"/>
                    <path d="M1.75 2.625V3.5H2.625V12.25C2.625 12.4821 2.71719 12.7046 2.88128 12.8687C3.04538 13.0328 3.26794 13.125 3.5 13.125H10.5C10.7321 13.125 10.9546 13.0328 11.1187 12.8687C11.2828 12.7046 11.375 12.4821 11.375 12.25V3.5H12.25V2.625H1.75ZM3.5 12.25V3.5H10.5V12.25H3.5Z" fill="#C8402F"/>
                    <path d="M5.25 0.875H8.75V1.75H5.25V0.875Z" fill="#C8402F"/>
                  </svg>
                </DeleteStepButton>
              </Tooltip>
              <EditableTitle
                containerStyle={{ paddingTop: 20, marginLeft: 20, marginBottom: -6 }}
                medium
                testId="funnel-select-label-input"
                value={step.name}
                onChange={handleStepNameChange(index)}
              />
              <ArraySelect
                key={index}
                funnel
                showErrors={showErrors}
                fieldDisabled={index > 0}
                query={{ ...step, field: funnel.field }}
                onChange={handleArrayStepChange(index)}
              />
              {index !== self.length - 1 && <Divider />}
            </StepContainer>
          );
        }

        if (funnel.ruleType === SegmentsRuleTypes.EVENT) {
          return (
            <StepContainer key={index}>
              <Tooltip title={p('delete_funnel_step')}>
                <DeleteStepButton onClick={handleDeleteFunnelStep(index)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.25 5.25H6.125V10.5H5.25V5.25Z" fill="#C8402F"/>
                    <path d="M7.875 5.25H8.75V10.5H7.875V5.25Z" fill="#C8402F"/>
                    <path d="M1.75 2.625V3.5H2.625V12.25C2.625 12.4821 2.71719 12.7046 2.88128 12.8687C3.04538 13.0328 3.26794 13.125 3.5 13.125H10.5C10.7321 13.125 10.9546 13.0328 11.1187 12.8687C11.2828 12.7046 11.375 12.4821 11.375 12.25V3.5H12.25V2.625H1.75ZM3.5 12.25V3.5H10.5V12.25H3.5Z" fill="#C8402F"/>
                    <path d="M5.25 0.875H8.75V1.75H5.25V0.875Z" fill="#C8402F"/>
                  </svg>
                </DeleteStepButton>
              </Tooltip>
              <EditableTitle
                containerStyle={{ paddingTop: 20, marginLeft: 20, marginBottom: -6 }}
                medium
                value={step.name}
                onChange={handleStepNameChange(index)}
              />
              <EventSelect
                key={index}
                funnel
                showErrors={showErrors}
                fieldDisabled={index > 0}
                query={{
                  ...step,
                  // filters: (step.filters || []).filter(({ hidden }) => !hidden),
                  field: (step.filters || []).find(extract('hidden'))?.value?.value
                }}
                onChange={handleEventStepChange(index)}
              />
              {index !== self.length - 1 && <Divider />}
            </StepContainer>
          );
        }

        return null;
      })}
    </Container>
  );
};

export default FunnelSelect;
