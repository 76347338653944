import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 20px;
`;

export const Header = styled.div`
  background: #F9FBFF;
  border-bottom: 1px solid #DCDFE6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 64px;
  padding: 0 20px;
`;

export const Footer = styled.div`
  border-top: 1px solid #DCDFE6;
  padding: 14px 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  ${styledProp('$d', css`
    display: flex;
  `, css`
    display: none;
  `)}
`;

export const FooterCaption = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #8B8E9F;
  margin-right: 6px;
`;

export const FooterIcon = styled.div`
  display: flex;
  flex: 0;
  transition: all 290ms ease;
  
  ${styledProp('$r', css`
    transform: rotate(180deg);
  `, css`
  `)}
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 21px;
  color: #31C447;
`;
export const TagContainer = styled.div`
  display: flex;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #FFFFFF;
`;

export const TagText = styled.div`
  //height: 24px;
  padding: 2px 6px;
  color: #303133;
  font-size: 13px;
  background-color: #E5E8ED;
  border-radius: 4px 1px 1px 4px;
`;

export const NoEntryPointTooltip = styled.div`
  color: #8B8E9F;
  margin-bottom: 10px;
`;

export const Tags = styled.div`
  display: flex;
  max-height: 142px;
  overflow-y: scroll;
  width: 100%;
  min-height: 38px;
  //border: 1px dashed #109DFB;
  //background-color: #F9FBFF;
  //border-radius: 9px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  padding: 2px 6px;
`;

export const TagClose = styled.div`
  margin-left: 1px;;
  font-size: 13px;
  padding: 2px 6px;
  color: #303133;
  background-color: #E5E8ED;
  border-radius: 1px 4px 4px 1px;
  
  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  padding: 20px;
  
  ${styledProp('$h', css`
    display: flex;
    flex-wrap: wrap;
  `)}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
