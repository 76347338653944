import omit from 'lodash.omit';

export const withCallbacks = ({ onSuccess, onError, ...args } = {}) => ({
  ...args,
  callbacks: { onSuccess, onError }
});

export const withPagination = ({ page, perPage, ...args } = {}) => ({
  ...args,
  pagination: { page, per_page: perPage },
});

export const withFilters = filters => ({ ...args } = {}) => ({
  ...omit(args, filters),
  filters: filters.reduce((a, f) => ({ ...a, [f]: args[f] }), {})
})

export const withOrdering = ({ orderCol, orderDir, ...args } = {}) => ({
  ...args,
  ordering: orderCol && orderDir ? { order_col: orderCol, order_dir: orderDir } : null
});

export const asCreator = creator => ({ ...args } = {}) => ({
  ...args,
  ...creator(args),
})
