import { Icon } from '@components';

import { Container, IconContainer, Text } from './styled';

const Tag = ({ children, onDelete, readonly, ...props }) => {
  return (
    <Container {...props}>
      <Text readonly={readonly}>
        {children}
      </Text>
      {!readonly && (
        <IconContainer onClick={readonly ? undefined : onDelete}>
          <Icon size={10} color="#fff" name="Cross-icon" />
        </IconContainer>
      )}
    </Container>
  );
};

export default Tag;
