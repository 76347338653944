const HistoryNotificationsTypes = {
  GET_EMAIL: 'notifications-history/GET_EMAIL',
  GET_EMAIL_SUCCESS: 'notifications-history/GET_EMAIL_SUCCESS',
  GET_EMAIL_ERROR: 'notifications-history/GET_EMAIL_ERROR',

  GET_SMS: 'notifications-history/GET_SMS',
  GET_SMS_SUCCESS: 'notifications-history/GET_SMS_SUCCESS',
  GET_SMS_ERROR: 'notifications-history/GET_SMS_ERROR',

  GET_VIBERS: 'notification-history/GET_VIBERS',
  GET_VIBERS_SUCCESS: 'notification-history/GET_VIBERS_SUCCESS',
  GET_VIBERS_ERROR: 'notification-history/GET_VIBERS_ERROR',

  GET_MOBILE_PUSHES: 'notification-history/GET_MOBILE_PUSHES',
  GET_MOBILE_PUSHES_SUCCESS: 'notification-history/GET_MOBILE_PUSHES_SUCCESS',
  GET_MOBILE_PUSHES_ERROR: 'notification-history/GET_MOBILE_PUSHES_ERROR',

  GET_WEBHOOK: 'notifications-history/GET_WEBHOOK',
  GET_WEBHOOK_SUCCESS: 'notifications-history/GET_WEBHOOK_SUCCESS',
  GET_WEBHOOK_ERROR: 'notifications-history/GET_WEBHOOK_ERROR',

  GET_TEMPLATES: 'notifications-history/GET_TEMPLATES',
  GET_TEMPLATES_SUCCESS: 'notifications-history/GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_ERROR: 'notifications-history/GET_TEMPLATES_ERROR',
  // UPDATE_EMAIL: 'notifications-history/UPDATE_EMAIL',
  // UPDATE_SMS: 'notifications-history/UPDATE_SMS',

  GET_WEBPUSH: 'notifications-history/GET_WEBPUSH',
  GET_WEBPUSH_SUCCESS: 'notifications-history/GET_WEBPUSH_SUCCESS',
  GET_WEBPUSH_ERROR: 'notifications-history/GET_WEBPUSH_ERROR',

  GET_FACEBOOK: 'notifications-history/GET_FACEBOOK',
  GET_FACEBOOK_SUCCESS: 'notifications-history/GET_FACEBOOK_SUCCESS',
  GET_FACEBOOK_ERROR: 'notifications-history/GET_FACEBOOK_ERROR',

  GET_PROMO_CODES: 'notifications-history/GET_PROMO_CODES',
  GET_PROMO_CODES_SUCCESS: 'notifications-history/GET_PROMO_CODES_SUCCESS',
  GET_PROMO_CODES_ERROR: 'notifications-history/GET_PROMO_CODES_ERROR',

  GET_INCLUDE_CAMPAIGN: 'notification-history/GET_INCLUDE_CAMPAIGN',
  GET_INCLUDE_CAMPAIGN_SUCCESS: 'notification-history/GET_INCLUDE_CAMPAIGN_SUCCESS',
  GET_INCLUDE_CAMPAIGN_ERROR: 'notification-history/GET_INCLUDE_CAMPAIGN_ERROR',

  GET_GIVE_REWARD: 'notification-history/GET_GIVE_REWARD',
  GET_GIVE_REWARD_SUCCESS: 'notification-history/GET_GIVE_REWARD_SUCCESS',
  GET_GIVE_REWARD_ERROR: 'notification-history/GET_GIVE_REWARD_ERROR',

  GET_INCLUDE_CAMPAIGN_TEMPLATES: 'notification-history/GET_INCLUDE_CAMPAIGN_TEMPLATES',
  GET_INCLUDE_CAMPAIGN_TEMPLATES_SUCCESS: 'notification-history/GET_INCLUDE_CAMPAIGN_TEMPLATES_SUCCESS',
  GET_INCLUDE_CAMPAIGN_TEMPLATES_ERROR: 'notification-history/GET_INCLUDE_CAMPAIGN_TEMPLATES_ERROR',

  GET_CONTENT_CARDS: 'notification-history/GET_CONTENT_CARDS',
  GET_CONTENT_CARDS_SUCCESS: 'notification-history/GET_CONTENT_CARDS_SUCCESS',
  GET_CONTENT_CARDS_ERROR: 'notification-history/GET_CONTENT_CARDS_ERROR',

  GET_WEB_POPUPS: 'notification-history/GET_WEB_POPUPS',
  GET_WEB_POPUPS_SUCCESS: 'notification-history/GET_WEB_POPUPS_SUCCESS',
  GET_WEB_POPUPS_ERROR: 'notification-history/GET_WEB_POPUPS_ERROR',

  GET_GIVE_REWARD_TEMPLATES: 'notification-history/GET_GIVE_REWARD_TEMPLATES',
  GET_GIVE_REWARD_TEMPLATES_SUCCESS: 'notification-history/GET_GIVE_REWARD_TEMPLATES_SUCCESS',
  GET_GIVE_REWARD_TEMPLATES_ERROR: 'notification-history/GET_GIVE_REWARD_TEMPLATES_ERROR',

  GET_NOTIFICATION_HISTORY_COUNT: 'notification-history/GET_COUNT',
  GET_NOTIFICATION_HISTORY_COUNT_SUCCESS: 'notification-history/GET_COUNT_SUCCESS',
  GET_NOTIFICATION_HISTORY_COUNT_ERROR: 'notification-history/GET_COUNT_ERROR',
};

export default HistoryNotificationsTypes;
