import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { archiveEmailTemplate, getEmailTemplates, setEmailDuplicate, updateOnboarding } from "@store/actions/creators";
import { hintModeSelector } from "@store/selectors";

import { Button } from '@velitech/ui';

import { useModalState, usePermissions, useTranslation, useNavigate } from "@hooks";

import { Paths, UserPermissions } from "@constants";
import { EMAIL_TEMPLATE_EXAMPLE_ID_PREFIX } from "@constants/templates";

import { notify } from "@utils";

import { Link, NoData, WithPermissions, ViewEmailTemplateModal, TemplateStatisticsModal } from "@components";

import { EmailTemplatesRow, TemplatesHeader, TemplatesInner, Container } from "./styled";

import LargeTemplateItem from "../../../../../../components/ui/LargeTemplateItem/LargeTemplateItem";
import { RioService } from "../../../../../../services";

const EmailSection = ({ emailTemplates = [] }) => {
  const hintMode = useSelector(hintModeSelector);
  const { p, t } = useTranslation('dashboard_page');

  const permissions = usePermissions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statisticsEmailModal = useModalState();

  const viewEmailModal = useModalState();

  const [emailViewItem, setEmailViewItem] = useState({});
  const [statisticEmailId, setEmailStatisticsId] = useState(null);

  const handleCreateEmailTemplateClick = (fromLastTemplates) => {
    if (hintMode && typeof fromLastTemplates !== 'boolean') {
      navigate(`${Paths.TEMPLATES_EMAIL}/list/default?fd=1`);
      dispatch(updateOnboarding('templates', {
        step: 1,
      }));
    } else {
      navigate(`${Paths.TEMPLATES_EMAIL}/list/default?fd=1`);
    }
    RioService.track('templates_dash');
  };

  const handleEmailArchive = (id, name) => {
    dispatch(archiveEmailTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.email_archived'), name);
        dispatch(getEmailTemplates({ page: 1, perPage: 6, all: true }));
      },
    }));
  }

  const handleEmailStatisticsClick = (id) => {
    setEmailStatisticsId(id);
    statisticsEmailModal.open();
  };

  const handleViewEmail = (data) => {
    setEmailViewItem(data);
    viewEmailModal.open();
  };

  const handleDuplicateEmail = (data) => {
    const defaultTemplate = data.languages?.find((langTemplateItem) => langTemplateItem.is_default) || data.languages?.[0];
    dispatch(setEmailDuplicate({ data }));

    navigate(`${Paths.TEMPLATES_EMAIL}/${defaultTemplate.template_driver}/duplicate?f=default`);
  };

  const handleEditEmail = (id, driver) => {
    navigate(`${Paths.TEMPLATES_EMAIL}/${driver}/${id}`);
  };

  const handleEmailModalAction = () => {
    const defaultTemplate = emailViewItem.languages?.find((langTemplateItem) => langTemplateItem.is_default) || emailViewItem.languages?.[0];

    if (String(emailViewItem.id).includes(EMAIL_TEMPLATE_EXAMPLE_ID_PREFIX)) {
      handleDuplicateEmail(emailViewItem);
    } else {
      handleEditEmail(emailViewItem.id, defaultTemplate.template_driver);
    }
  };

  const handleCloseViewEmailModal = () => {
    setEmailViewItem({});
    viewEmailModal.close();
  };

  const getHtml = (data) => {

    const defaultTemplate = data?.languages?.find((langTemplateItem) => langTemplateItem.is_default) || data?.languages?.[0];
    return defaultTemplate?.data
  }

  return (
    <Container data-testid="dashboard-templates-section-email">
      <TemplatesInner style={{ paddingBottom: 0, pointerEvents: !!emailTemplates?.meta?.total ? 'auto' : 'none', minHeight: '246px' }}>
        <TemplatesHeader>
          <Link to={`${Paths.TEMPLATES_EMAIL}/list/default`} icon>{p('emails')}</Link>
          <WithPermissions name={UserPermissions.CREATE_EMAILS}>
            <Button
              size={'sm'}
              style={{
                padding: '6px 14px',
                height: '26px',
                fontSize: '12px',
                lineHeight: '14px',
                borderRadius: '5px'
              }}
              data-testid="dashboard-templates-section-email-create"
              width="none"
              onClick={() => handleCreateEmailTemplateClick(true)}
            >
              {t('actions.create')}
            </Button>
          </WithPermissions>
        </TemplatesHeader>
        <EmailTemplatesRow $empty={!(!!emailTemplates?.data?.filter(t => t.status !== 3)?.length)}>
          {!(!!emailTemplates.data?.filter(t => t.status !== 3)?.length) && <NoData style={{ display: 'flex' }} />}
          {emailTemplates.data?.filter(t => t.status !== 3)?.slice(0, 4).map(({
            id,
            name,
            created_at,
            body,
            used,
            workers,
            status,
            languages,
            ...rest
          }, i, arr) => (
            <LargeTemplateItem
              key={id}
              title={name}
              type="email"
              status={status}
              used={used}
              workflows={workers}
              testId={`email-templates-item-${i}`}
              view={!permissions.allowed(UserPermissions.UPDATE_EMAILS)}
              date={created_at}
              html={body}
              data={arr[i]}
              fromDashboard
              onArchive={() => handleEmailArchive(id, name)}
              onStatisticsClick={() => handleEmailStatisticsClick(id)}
              onEditClick={() => {
                const lang = languages[0];
                if (workers.used !== 1) {
                  navigate(`${Paths.TEMPLATES_EMAIL}/${lang?.template_driver}/${id}?view=1`)
                }
              }}
              onViewClick={() => handleViewEmail({ id, name, languages })}
              onDuplicateClick={() => {

                handleDuplicateEmail({
                  id,
                  name,
                  created_at,
                  body,
                  data: body,
                  languages,
                  workflows: workers,
                  ...rest,
                })}
              }
            />
          ))}
        </EmailTemplatesRow>
      </TemplatesInner>

      <TemplateStatisticsModal
        id={statisticEmailId} 
        opened={statisticsEmailModal.opened} 
        onClose={statisticsEmailModal.close} 
        type={'email'}
      />
      <ViewEmailTemplateModal
        opened={viewEmailModal.opened}
        onClose={handleCloseViewEmailModal}
        onActionClick={handleEmailModalAction}
        name={emailViewItem.name}
        html={getHtml(emailViewItem)}
        isExample={Boolean(emailViewItem?.id?.includes?.(EMAIL_TEMPLATE_EXAMPLE_ID_PREFIX))}
      />
    </Container>
  );
}

export default React.memo(EmailSection);
