import { useCurrentWorkspace } from '@hooks';

import { hashAppId, removeAppFromPath } from '@utils';

import { Icon } from '@components';

import { Container, StyledLink } from './styled';

const Link = ({ type, underline, icon, width, variant, semiBold, ...props }) => {
  const currentApp = useCurrentWorkspace();
  const resolveTo = () => {
    if (!props.to) {
      return '#';
    }

    if (props.to?.startsWith?.('/') && currentApp?.id) {
      return `/app/${hashAppId(currentApp?.id)}${removeAppFromPath(props.to)}`;
    }

    return props.to;
  };

  return (
    <Container {...{ width, variant }}>
      <StyledLink $type={type} $semibold={semiBold} $underline={underline} {...props} to={resolveTo()} />
      {icon && (
        <Icon
          name="Back-arrow"
          size={10}
          style={{ marginLeft: '5px', transform: 'rotate(180deg)', lineHeight: 10 }}
          color="#31C447"
        />
      )}
    </Container>
  )
};

export default Link;
