import later from 'later';

import { moment } from '@utils';

import { identity } from './functions';

const formatMapStatic = {
  d: 'DD',
  D: 'ddd',
  j: 'D',
  l: 'dddd',
  N: 'E',
  w: 'd',
  W: 'W',
  F: 'MMMM',
  m: 'MM',
  M: 'MMM',
  n: 'M',
  o: 'GGGG',
  Y: 'YYYY',
  y: 'YY',
  a: 'a',
  A: 'A',
  g: 'h',
  G: 'H',
  h: 'hh',
  H: 'HH',
  i: 'mm',
  s: 'ss',
  u: '[u]',
  e: '[e]',
  O: 'ZZ',
  P: 'Z',
  T: '[T]',
  c: 'YYYY-MM-DD[T]HH:mm:ssZ',
  r: 'ddd, DD MMM YYYY HH:mm:ss ZZ',
  U: 'X'
};
const formatRegEx = /[dDjlNSwzWFmMntLoYyaABgGhHisueIOPTZcrU]/g;
export const isoDateStringRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?([+-]\d{2}:\d{2}|Z)$/;

export const parseMongoDateObject = o => moment(+o?.['$date']?.['$numberLong']);

export const tryMongoDateObject = (v, f = identity) => {
  if (v?.['$date']) {
    return f(parseMongoDateObject(v));
  }

  if (isoDateStringRegex.test(String(v))) {
    return f(moment(v));
  }

  return v;
}

export const formatFromPHP = (m, f) => {
  const formatMapDynamic = {
    z: () => m.format('DDD') - 1,
    S: () => `[${m.format('Do').replace(/\d*/g, '')}]`,
    t: m.daysInMonth,
    L: () => +m.isLeapYear(),
    B: () => {
      const mUTC = m.clone().utc();
      const swatch = ((mUTC.hours() + 1) % 24) + (mUTC.minutes() / 60) + (mUTC.seconds() / 3600);

      return Math.floor(swatch * 1000 / 24);
    },
    I: () => +m.isDST(),
    Z: () => parseInt(m.format('ZZ'), 10) * 36,
  };

  const formatMap = { ...formatMapStatic, ...formatMapDynamic };

  return m.format(f.replace(
    formatRegEx,
    s => typeof formatMap[s] === 'function' ? formatMap[s].call(m) : formatMap[s]
  ))
};

export const momentToApexChartsDate = d => new Date(d?.format?.('YYYY-MM-DD HH:mm').replace(' ', 'T')).getTime();

export const hasSeconds = cronExpression => cronExpression?.split?.(' ')?.length === 6;

export const chronToRange = (ex, [r1, r2]) => {
  return (later
    .schedule(later.parse.cron(ex, hasSeconds(ex)))
    .next(-1, new Date(r1.unix() * 1000), new Date(r2.unix() * 1000)) || [])
    .map(d => moment(d));
};

export const isSameRange = (r1, r2) => {
  return moment(r1?.[0]).isSame(moment(r2?.[0])) && moment(r1?.[1]).isSame(moment(r2?.[1]))
};

export const getNextLaunches = (ex, launch, next) => (
  later
    .schedule(later.parse.cron(ex, hasSeconds(ex)))
    .next(next, new Date(moment(launch).unix() * 1000), new Date(moment().unix() * 1000))
  || []
).map(d => moment(d));

export const getIntervalRange = (d, i, inc = 1) => [moment(d), moment(d).add(inc, i)];
