import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;

export const Input = styled.textarea`
  margin-top: 10px;
  resize: none;
  overflow: hidden;
  min-height: 40px;
  outline: none;
  padding: 11px 13px;
  border: 1px solid #DCDFE6;
  border-radius: 8px;
  width: 100%;

  color: rgba(12, 16, 21, 0.90);
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  
  &:focus {
    outline: none;
  }
  
  ${styledProp('$opened', '', css`
    height: 0px;
    min-height: 0px;
    margin-top: 0px;
    padding: 0px;
    border: none;
    max-height: 0px;
  `)}
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
  cursor: pointer;
`;

export const ButtonText = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  text-align: center;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;
