import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled(motion.div).attrs({
  initial: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '-30%' : '30%',
    scale: 0.98
  }),
  animate: { opacity: 1, x: '0%', scale: 1 },
  exit: (direction) => ({
    opacity: 0,
    x: direction === -1 ? '30%' : '-30%',
    scale: 0.98
  }),
  transition: { duration: 0.27, easing: 'ease' }
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 186px;
  padding-bottom: 108px;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 24px;
  line-height: 28px;
  position: absolute;
  top: 119px;
`;

export const Actions = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: stretch;
  width: 530px;
  
  ${styledProp('$center', css`
    justify-content: center;
  `)}
`;
