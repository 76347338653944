import styled, { css } from "styled-components";

import { styledProp } from '@utils';

export const Container = styled.div`
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: 5px;
    outline: none;
    z-index: 2;
    
    border: 2px solid transparent;
    transition: border-color 320ms ease;
    transition-property: opacity, border-color;
    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;
    
    ${styledProp('$selected', css`
        border-color: #31C447;
        pointer-events: all;
    `)}

    ${styledProp('$large', css`
        border-radius: 11px;
    `)}
`;

export const ActiveContainer = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 54px;
    border: 1px solid var(--cards-border, #D3DBE7);
    background: var(--surface-primary-bg, #FFF);
    padding: 16px 14px;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Manrope-SemiBold;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    gap: 6px;
    position: relative;
    z-index: unset !important;

    ${styledProp('$active', css`
        opacity: 1;
    `, css`
        opacity: 0;
    `)}
`;

export const Count = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    min-width: 22px;
    background: #31C447;
    border-radius: 50%;
    color: var(--White, #FFF);
    text-align: center;
    font-family: Rubik-Regular;
    font-size: 14px;
    line-height: 120%;
`;
