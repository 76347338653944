import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const Container = styled(animated.div)`
  padding: 20px;
  background-color: #fff;
  border: 1px solid #D3DBE7;
  border-radius: 7px;
`;

export const StatusesLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: max-content;
`;

export const StatusesLegendRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: lowercase;
  gap: 5px;
`;

export const StatusesLegendValue = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  text-align: right;
  color: #1A232E;
`;

export const ActionsRow = styled.div`
  display: flex;
  align-items: center;
`;
