import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeLatest, put } from 'redux-saga/effects';

import { Endpoints } from '@constants';

import { WorkspacesActionTypes } from '../../actions/types';

function* getDemoWorkspacesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/apps/demo'
  }));
}

function* listWorkspacesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: Endpoints.WORKSPACES,
  }));
}

function* saveWorkspacesSaga(action) {
  const formData = new FormData();
  formData.append('image', action.payload.workspace.image);
  formData.append('name', action.payload.workspace.name);

  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: Endpoints.WORKSPACES,
    body: action.payload.workspace,
    // headers: { "Content-Type": "multipart/form-data" }
  }));
}

function* switchToDemoWorkspaceSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `/api/auth/switch-app/demo`,
    body: {
      app_id: action.payload.app_id,
    }
  }));
}

function* switchWorkspacesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: `/api/auth/switch-app`,
    body: {
      app_id: action.payload.id
    }
  }));
}

export default function* workspacesRootSaga() {
  yield all([
    takeLatest(WorkspacesActionTypes.LIST, listWorkspacesSaga),
    takeLatest(WorkspacesActionTypes.SAVE, saveWorkspacesSaga),
    takeLatest(WorkspacesActionTypes.SWITCH, switchWorkspacesSaga),
    takeLatest(WorkspacesActionTypes.SWITCH_TO_DEMO, switchToDemoWorkspaceSaga),
    takeLatest(WorkspacesActionTypes.GET_DEMO, getDemoWorkspacesSaga),
  ]);
}
