import { RFMAnalyticsActionTypes } from '@store/actions/types';
import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from '@store/lib';

import { compose } from '@utils';

export const getRFMAnalytics = compose(
  withCallbacks,
  withFilters(['name', 'status', 'event_name', 'calculation_status', 'end_date', 'start_date', '$type']),
  withPagination,
  withOrdering,
  asCreator(() => ({
    type: RFMAnalyticsActionTypes.GET_RFM_ANALYTICS,
  }))
);

export const getRFMAnalyticsList = compose(
  asCreator(() => ({
    type: RFMAnalyticsActionTypes.GET_RFM_LIST,
  }))
);

export const createRFMAnalytics = compose(
  withCallbacks,
  asCreator(({ ...value }) => ({
    type: RFMAnalyticsActionTypes.CREATE_RFM_ANALYTICS,
    payload: { ...value },
  }))
);

export const editRFMAnalytics = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS,
    payload: { id },
    meta: { id }
  }))
);

export const editRFMAnalyticsRange = compose(
  withCallbacks,
  asCreator(({ id, range_id }) => ({
    type: RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS_RANGE,
    payload: { id, range_id },
    meta: { id, range_id }
  }))
);

export const stopRFMAnalytics = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: RFMAnalyticsActionTypes.STOP_RM_ANALYTICS,
    payload: { id },
  }))
);

export const archiveRFMAnalytics = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: RFMAnalyticsActionTypes.ARCHIVE_RFM_ANALYTICS,
    payload: { id },
  }))
);

export const unarchiveRFMAnalytics = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: RFMAnalyticsActionTypes.UNARCHIVE_RFM_ANALYTICS,
    payload: { id },
  }))
);

export const deleteRFMAnalytics = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: RFMAnalyticsActionTypes.DELETE_RFM_ANALYTICS,
    payload: { id },
  }))
);

export const renameRFMAnalytics = compose(
  withCallbacks,
  asCreator(({ id, name }) => ({
    type: RFMAnalyticsActionTypes.RENAME_RFM_ANALYTICS,
    payload: { id, name },
    meta: { id, name },
  }))
)
