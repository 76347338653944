import { useEffect, useRef, useState } from 'react';

import { useTranslation } from '@hooks';

import { COMMON_OPTIONS, SELECT_GROUPS, OPERATOR_EXISTS, OPERATOR_NOT_EXISTS } from '@constants';

import { testId } from '@utils';

import {
  Container,
  Content,
  DynamicColumn,
  Item,
} from './styled.js';

const getTabs = (p) => [
  {
    names: ['numeric'],
    title: p('numeric'),
    iconName: 'Numeric-operator',
  },
  {
    names: ['text'],
    title: p('text'),
    iconName: 'Text-operator',
  },
  {
    names: ['boolean'],
    title: p('boolean'),
    iconName: 'Boolean-operator',
  },
  {
    names: ['datetime', 'date'],
    title: p('date'),
    iconName: 'Date-operator',
  },
];

const OperatorMenu = ({ onChange, type, tab, setTab, opened, isAggregate }) => {
  const { p } = useTranslation('segments_page');
  const tabs = getTabs(p);

  const refs = useRef(Object.fromEntries(tabs.map(({ names: [name] }) => [name, null])));

  useEffect(() => {
    if (!~(tabs.find(({ names }) => names.indexOf(tab)).names || []).indexOf(type) && type) {
      setTab(type);
    }
  }, [type]);

  const handleChange = (operator, type) => {
    onChange(operator, type);
  }
  const [updater, setUpdater] = useState(false);

  useEffect(() => {
    setTimeout(() => setUpdater(u => !u), 300);
  }, [opened]);

  const CommonOptions = isAggregate ? COMMON_OPTIONS.map(o => ({ ...o, label: p(o.value) })).filter(o => o.value !== OPERATOR_NOT_EXISTS && o.value !== OPERATOR_EXISTS) : COMMON_OPTIONS;

  const tid = testId('segment-filter-operator');
  // eslint-disable-next-line no-unused-vars
  const [tabEl, setTabEl] = useState(null);

  useEffect(() => {
    if (!refs.current[tabs.find(({ names }) => !!~names.indexOf(tab))?.names?.[0]]?.getBoundingClientRect()?.width) {
      return;
    }

    setTabEl(refs.current[tabs.find(({ names }) => !!~names.indexOf(tab)).names[0]]);
  }, [updater, tab, refs.current[tabs.find(({ names }) => !!~names.indexOf(tab))?.names?.[0]], opened]);

  return (
    <Container>
      {/*<Header>*/}
      {/*  <Tabs>*/}
      {/*    {tabs.map(({ names, title, iconName }) => (*/}
      {/*      <TabContainer onClick={() => setTab(names[0])}>*/}
      {/*        <Icon name={iconName} size={16} color={!!~names.indexOf(tab) ? '#31C447' : '#303133'} />*/}
      {/*        <Tab*/}
      {/*          {...tid(`tab-${title}`)}*/}
      {/*          id={`om-tab-${names[0]}`}*/}
      {/*          ref={rr => refs.current[names[0]] = rr}*/}
      {/*          selected={!!~names.indexOf(tab)}*/}
      {/*        >*/}
      {/*          {title}*/}
      {/*        </Tab>*/}
      {/*      </TabContainer>*/}
      {/*    ))}*/}
      {/*    <TabIndicator key={String(updater)} $tab={tabEl} />*/}
      {/*  </Tabs>*/}
      {/*</Header>*/}
      <Content>
        <DynamicColumn {...tid(`operators`)}>
          {SELECT_GROUPS(p).map(({ name, types, hide, options }, idx) => (!!~types.indexOf(tab) && !hide) ? (
            <div key={idx}>
              {/*<Title>{title} {p('operators')}</Title>*/}
              {options.map(({ value, label }, idx) => (<Item key={idx} onClick={() => handleChange(value, name)}>{label}</Item>))}
            </div>
          ) : null)}
          {CommonOptions.map(({ name, value }, idx) => <Item key={idx} onClick={() => handleChange(value, name)}>{p(value)}</Item>)}
        </DynamicColumn>
        {/*<CommonColumn {...tid(`common-operators`)}>*/}
        {/*  <>*/}
        {/*    <Title>{p('common_operators')}</Title>*/}
        {/*  </>*/}
        {/*</CommonColumn>*/}
      </Content>
    </Container>
  );
}

export default OperatorMenu;

