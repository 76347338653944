import styled from 'styled-components';

import { switchStyle } from '@utils';

export const MobileContainer = styled.div`
    width: 260px;
    height: 520px;
    position: relative;
    z-index: 1;

    ${switchStyle('$format', {
    landscape: `
            width: 480px;
            height: 260px;
        `,
  })}
`;

export const LaptopContainer = styled.div`
    width: 100%;
    height: 362px;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Container = styled.div`
    width: 288px;
    height: 100%;
    min-height: 530px;
    flex-shrink: 0;
    border: 1px solid var(--cards-border, #D3DBE7);
    border-radius: 4px;
    overflow: hidden;
    
    ${switchStyle('$format', {
    landscape: `
            width: 480px;
            min-height: 256px;
        `,
  })}
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;