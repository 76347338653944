export const CONTACTS_IMPORT = `Id,First Name,Last name,Email,Phone,Currency,Language
887274,Anna,Smith,test@gmail.com,12345678,EUR,de
887345,John,Dalin,test1@gmail.com,12345679,USD,en
887483,Amily,Linsen,test2@gmail.com,12345680,CAD,en
887627,Jason,Gilbert,test3@gmail.com,12345681,EUR,fr
887403,Anna,Heath,test4@gmail.com,12345682,EUR,fi
887563,Davie,Osborne,test5@gmail.com,12345683,EUR,de
887697,Helen,Sharp,test6@gmail.com,12345684,EUR,el
887753,Jeremy,Walters,test7@gmail.com,12345685,PLN,pl
887807,Jack,Quinn,test8@gmail.com,12345686,UAH,uk
887879,Alexandra,Gibbs,test9@gmail.com,12345687,EUR,it
`;
