import { SegmentsRuleTypes } from '@constants';

import { AggregateFilter } from '../AggregateFilter';
import { ArrayFilter } from '../ArrayFilter';
import { EventFilter } from '../EventFilter';
import { LinearFilter } from '../LinearFilter';

const WorkflowFilter = ({ type, isNodeSettings, isFilterSplit, newErrorStructure, disabled, ...props }) => {

  const getError = (field) => {

    if(newErrorStructure && field === 'filters') {
      return props?.errors?.filters;
    }

    if (field === 'filters' && !props?.errors?.[field] && props?.errors?.filter_builder) {
      return props.errors.filter_builder;
    }
    return props?.errors?.[field];
  };

  switch (type) {
    case SegmentsRuleTypes.AGGREGATE:
      return <AggregateFilter disabled={disabled} {...props} />
    case SegmentsRuleTypes.EVENT:
      return <EventFilter newErrorStructure disabled={disabled} isFilterSplit={isFilterSplit} isNodeSettings={isNodeSettings} {...props} errors={getError} />
    case SegmentsRuleTypes.ARRAY:
      return <ArrayFilter disabled={disabled} {...props} />
    case SegmentsRuleTypes.LINEAR:
      return <LinearFilter disabled={disabled} {...props} />;
    default:
      console.warn('unknown filter', type);
      return null;
  }
};

export default WorkflowFilter;
