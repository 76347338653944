import { useTranslation } from '@hooks';
import useWorkflowOptionsPreview from '@hooks/useWorkflowOptionsPreview';

import { TemplateTypes } from '@constants/templates';

import { moment } from '@utils';

import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';
import {
  AiSection, AiSubtitle,
  AiTitle, Sending, SubText
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendEmailNodeSettingsInfo/styled';

import { Container } from './styled.js';

const WebpushNodeSettingsInfo = ({ node, options }) => {
  const webpush = useWorkflowOptionsPreview(TemplateTypes.WEB_PUSH, node.data?.webpush_id);
  const { p, t } = useTranslation();

  if (!webpush) {
    return (
      <Container>
        <InfoTitle>{t('labels.template')}:</InfoTitle>
        <InfoText notSelected>{t('workflow_page.not_selected')}</InfoText>
      </Container>
    );
  }

  return (
    <Container>
      <InfoTitle>
        {t('labels.template')}:
      </InfoTitle>
      <InfoText>
        {webpush.name}
      </InfoText>
      <Sending>
        <InfoTitle>{t('labels.sending')}:</InfoTitle>
        {!node.data?.best_time_enabled ? (
          <InfoText>{p('workflow_page.upon_node_entrance')}</InfoText>
        ) : (
          <AiSection>
            <AiTitle>
              {p('workflow_page.ai_best_time')}
            </AiTitle>
            <AiSubtitle>
              {p('workflow_page.fallback_option')}
            </AiSubtitle>
            <SubText>{node.data?.best_time_fallback ? `${p('workflow_page.at')} ${moment(node.data.best_time_fallback)?.hour?.()}:${moment(node.data.best_time_fallback)?.minute?.() === 0 ? moment(node.data.best_time_fallback)?.minute?.() + '0' : moment(node.data.best_time_fallback)?.minute?.()}` : p('workflow_page.upon_node_entrance')}</SubText>
          </AiSection>
        )}
      </Sending>
    </Container>
  );
}

export default WebpushNodeSettingsInfo;

