import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { dashboardAnalyticsEntitiesSelector } from '@store/selectors/dashboard';

import { Button, Theme } from '@velitech/ui';
import { Dropdown } from 'antd';

import { useTranslation } from '@hooks';

import { testId } from '@utils';

import { Icon, NoData, RadioButton, RadioGroup, SearchSelect } from '@components';

import { Container, Overlay, RadioTitle, Label } from './styled';

import { MultiselectGroup } from '../MultiselectGroup';

const baseOptions = [
  { value: 'base.t', label: 'base.t' },
  { value: 'base.n', label: 'base.n' },
  { value: 'base.a', label: 'base.a' },
];

const SettingsButton = ({ options, onOptionsChange, ...props }) => {
  const { t, p } = useTranslation('dashboard_page');
  const [type, setType] = useState(options.type);
  const [opened, setOpened] = useState(false);
  const [entities, setEntities] = useState(options.ids || []);
  const analyticEntities = useSelector(dashboardAnalyticsEntitiesSelector);

  useEffect(() => {
    if (options.type && options.type !== type) {
      setType(options.type);
    }
    if (options.ids && !(options.ids.every(id => !!~entities.indexOf(id)) || options.ids.length === entities.length)) {
      setEntities(options.ids);
    }
  }, [options]);

  const segmentOptions = useMemo(() => {
    if (!analyticEntities.segments) {
      return [];
    }

    return analyticEntities.segments.map(({ name, id }) => ({ label: name, value: id }));
  }, [analyticEntities.segments]);

  const funnelOptions = useMemo(() => {
    if (!analyticEntities.funnels) {
      return [];
    }

    return analyticEntities.funnels.map(({ name, id }) => ({ label: name, value: id }));
  }, [analyticEntities.funnels])

  const handleTypeChange = (newType) => {
    if (newType === 'base') {
      setEntities(['base.t', 'base.a', 'base.n']);
    } else {
      setEntities([]);
    }

    setType(newType);
  };

  const handleConfirm = () => {
    onOptionsChange({ type, ids: entities });
    setOpened(false);
  };

  return (

    <Dropdown
      trigger={['click']}
      open={opened}
      data-testid="dashboard-settings-button"
      onOpenChange={setOpened}
      placement="bottomLeft"
      overlay={(
        <Theme>
          <Overlay>
            <RadioTitle>{t('labels.source')}</RadioTitle>
            <RadioGroup value={type} onChange={handleTypeChange}>
              <RadioButton data-testid="dashboard-settings-all-base" name="base" title={t('labels.all_base')} style={{ marginBottom: 11 }} />
              <RadioButton data-testid="dashboard-settings-segment" name="segment" title={t('labels.segment')} style={{ marginBottom: 11 }} />
              <RadioButton data-testid="dashboard-settings-funnel" name="funnel" title={t('labels.funnel')} style={{ marginBottom: 20 }} />
            </RadioGroup>
            {type === 'base' && (
              <>
                <Label>{p('base_indicators')}</Label>
                <MultiselectGroup
                  options={baseOptions}
                  onChange={setEntities}
                  testId="dashboard-settings-base-group"
                  style={{ width: '100%', marginBottom: 14 }}
                  value={entities}
                  translate={p}
                />
              </>
            )}
            {type === 'segment' && (
              <>
                <Label>{t('labels.segments')}</Label>
                <MultiselectGroup
                  options={segmentOptions}
                  title={t('labels.segments')}
                  value={entities}
                  testId="dashboard-settings-segment-group"
                  max={5}
                  style={{ width: '100%', marginBottom: 14 }}
                  onChange={setEntities}
                />
              </>
            )}
            {type === 'funnel' && (
              <SearchSelect
                options={funnelOptions}
                title={t('labels.funnels')}
                value={entities}
                {...testId('dashboard-settings-funnel-funnels')()}
                noData={<NoData />}
                containerStyle={{ width: '100%', marginBottom: 14 }}
                style={{ width: '100%' }}
                onChange={(funnel) => setEntities([funnel])}
              />
            )}
            <Button
              onClick={handleConfirm}
              variant="secondary"
              {...testId('dashboard-settings-funnel-apply')()}
              disabled={!entities.length}
              width="auto"
              style={{ width: '100%' }}
            >
              {t('actions.apply')}
            </Button>
          </Overlay>
        </Theme>
      )}
    >
      <Container data-testid="dashboard-settings-button-container" {...props}>
        <Icon name="Dashboard-settings" size={20} />
      </Container>
    </Dropdown>
  );
};

export default SettingsButton;
