import languageList from '@res/languageList.json'

export const languageStructureByCode = () => {
  return languageList.reduce((acc, item) => {
    acc[item.languageCode] = item;
    return acc;
  }, {});
}

export const languageOptions = () => {
  return languageList.map((languageItem) => {
    return {
      value: languageItem.languageCode,
      label: languageItem.languageName,
      emoji: languageItem.emoji
    }
  })
}