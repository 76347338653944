import { Handle } from 'reactflow';

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { switchStyle } from '@utils';

import { IconButton } from '@components/controls';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

export const Container = styled.div`
  max-width: 240px;
  width: 100%;
  min-height: 104px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #9fdafb;
`;

export const NodeThumbnail = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  position: relative;
  overflow: visible;
    background: #90D5FC;
    box-shadow: 0px 0px 6px 0px #7CA9FE inset;
`;

export const NodeCardContainer = styled.div`
  width: 240px;
  min-height: 104px;
  border-radius: 10px;
  border: 2px solid #43d09d;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #0c95fa;
`;

export const NodeCardHeader = styled.div`
  width: 100%;
  min-height: 46px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #d3dbe7;
  background: #f8fafe;
  padding: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardHeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CardHeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #1a232e;
  padding: 0 2px;
  max-width: 145px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space:nowrap

`;

export const NodeBottomActions = styled.div`
  width: 100%;
  padding: 10px 10px 14px 10px;
`;

export const NodeActionLayout = styled.div`
  position: absolute;
  right: 0;
  display: inline-flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 7px;
  border: 1px solid #d3dbe7;
  background: #fff;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
`;

export const WorkflowList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const IconWithStyles = styled(IconButton)`
  width: 22px;
  height: 22px;
  min-width: 22px;
  border: 0px;
  svg path {
    transition: all 0.3s ease;
  }
  &:hover {
    svg path {
      transition: all 0.3s ease;
      fill: #1a232e;
    }
  }
`;

export const StyledHandle = styled(Handle)`
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  background: #a9a7a7;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  pointer-events: none;
  ${switchStyle('position', {
    left: css`
      transform: translate(0);
      top: 24px;
    `,
    top: css`
      left: 50%;
      transform: translate(0);
    `,
  })}
`;
