const WorkspacesActionTypes = {
  LIST: 'workspaces/LIST',
  LIST_SUCCESS: 'workspaces/LIST_SUCCESS',
  LIST_ERROR: 'workspaces/LIST_ERROR',

  SAVE: 'workspaces/SAVE',
  SAVE_SUCCESS: 'workspaces/SAVE_SUCCESS',
  SAVE_ERROR: 'workspaces/SAVE_ERROR',

  SWITCH: 'workspaces/SWITCH',
  SWITCH_SUCCESS: 'workspaces/SWITCH_SUCCESS',
  SWITCH_ERROR: 'workspaces/SWITCH_ERROR',

  GET_DEMO: 'workspaces/GET_DEMO',
  GET_DEMO_SUCCESS: 'workspaces/GET_DEMO_SUCCESS',
  GET_DEMO_ERROR: 'workspaces/GET_DEMO_ERROR',

  SWITCH_TO_DEMO: 'workspaces/SWITCH_TO_DEMO',
  SWITCH_TO_DEMO_SUCCESS: 'workspaces/SWITCH_TO_DEMO_SUCCESS',
  SWITCH_TO_DEMO_ERROR: 'workspaces/SWITCH_TO_DEMO_ERROR',

  SET_VIEW_DEMO_MODAL_OPENED: 'workspaces/SET_VIEW_DEMO_MODAL_OPENED',
};

export default WorkspacesActionTypes;
