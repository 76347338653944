import { Button } from '@velitech/ui';

import { Icon, WithPermissions } from '@components';

import { Wrapper, Text } from './styled';

const TemplatesPlaceholder = ({ text, buttonText, onButtonClick, permission, textStyle }) => (
  <Wrapper>
    <Text style={textStyle}>{text}</Text>
    {permission ? (
      <WithPermissions name={permission}>
        <Button onClick={onButtonClick} addonLeft={<Icon size={16} name="Add-new" />} width={107}>
          <div>{buttonText}</div>
        </Button>
      </WithPermissions>
    ) : (
      <Button onClick={onButtonClick} addonLeft={<Icon size={16} name="Add-new" />} width={107}>
        <div>{buttonText}</div>
      </Button>
    )}
  </Wrapper>
);

export default TemplatesPlaceholder;
