import { useTranslation } from '@hooks';

import { by } from '@utils';

import { Icon } from '@components';

import { Container, StepContainer, Circle, CircleInner, Title, CircleText, Divider } from './styled';

const Stepper = ({ steps, current }) => {
  const currIndex = steps.findIndex(by('name', current));
  const { t } = useTranslation();

  const resolveState = (index) => {
    if (index < currIndex) {
      return 'previous';
    }
    if (index === currIndex) {
      return 'current';
    }

    return 'next';
  };

  return (
    <Container>
      {steps.map(({ name, title }, index) => {
        const state = resolveState(index);

        return (
          <StepContainer key={name}>
            <Circle state={state}>
              {state === 'previous' && <Icon name="Checkbox-check" size={10} style={{ height: '6px', lineHeight: '6px' }} />}
              {state === 'current' && <CircleInner />}
              {state === 'next' && <CircleText>{index + 1}</CircleText>}
            </Circle>
            <Title>{t(title)}</Title>
            {index < steps.length - 1 && <Divider />}
          </StepContainer>
        );
      })}
    </Container>
  );
};

export default Stepper;
