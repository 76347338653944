import styled from 'styled-components';

import { switchStyle } from '@utils';

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;
  margin-top: 6px;
  ${switchStyle('align', {
    'column': 'flex-direction: column;',
  })}
`;
