import React, { useMemo } from 'react';

import { WorkflowNodes } from '@constants';

import { ABTestingNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/ABTestingNodeSettingsInfo';
import {
  AnotherWorkflowNodeSettingsInfo
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnotherWorkflowNodeSettingsInfo';
import { ApiRequestNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/ApiRequestNodeSettingsInfo';
import {
  CustomerChangeNodeSettingsInfo
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/CustomerChangeNodeSettingsInfo';
import { DataChangeNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/DataChangeNodeSettingsInfo';
import { DefaultFilterNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/DefaultFilterNodeSettingsInfo';
import { EventNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/EventNodeSettingsInfo';
import {
  GiveRewardNodeSettingsInfo
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/GiveRewardNodeSettingsInfo';
import { GroupSplitNodeInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/GroupSplitNodeInfo';
import {
  IncludeCampaignNodeSettingsInfo
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/IncludeCampaignNodeSettingsInfo';
import { MobilePushNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/MobilePushNodeSettingsInfo';
import { NewContactNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/NewContactNodeSettingsInfo';
import { PushDiscountNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/PushDiscountNodeSettingsInfo';
import { ResourceNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/ResourceNodeSettingsInfo';
import RetargetingNodeSettingsInfo
  from "@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/RetargetingNodeSettingsInfo/RetrgetingNodeSettingsInfo";
import { SendEmailNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendEmailNodeSettingsInfo';
import { SendSMSNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/SendSMSNodeSettingsInfo';
import {
  TeamMessageNodeSettingsInfo
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/TeamMessageNodeSettingsInfo';
import { WaitNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/WaitNodeSettingsInfo';
import { WebpushNodeSettingsInfo } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/WebpushNodeSettingsInfo';

import { BestChanneltoSendInfo } from '../BestChannelToSendInfo';
import SendCardNodeSettingsInfo from '../SendCardNodeSettingsInfo/SendCardNodeSettingsInfo';
import { ViberNodeSettingsInfo } from '../ViberNodeSettingsInfo';
import { WebPopupNodeSettingsInfo } from '../WebPopupNodeSettingsInfo';

const NodeSettingsInfo = ({ data, id }) => {
  const node = useMemo(() => ({ data, id }), [data, id]);

  switch (node?.data?.name) {
    case WorkflowNodes.SWITCH_FILTER:
      return <GroupSplitNodeInfo node={node} />
    case WorkflowNodes.QUICK_FILTER:
    case WorkflowNodes.EXCLUDE_FILTER:
      return <DefaultFilterNodeSettingsInfo node={node} />;
    case WorkflowNodes.WAIT:
      return <WaitNodeSettingsInfo node={node} />;
    case WorkflowNodes.PUSH_DISCOUNT:
      return <PushDiscountNodeSettingsInfo node={node} />;
    case WorkflowNodes.SEND_EMAIL:
      return <SendEmailNodeSettingsInfo node={node} />;
    case WorkflowNodes.ENTRY_ANOTHER_WF:
      return <AnotherWorkflowNodeSettingsInfo node={node} />
    case WorkflowNodes.DATA_CHANGE:
      return <DataChangeNodeSettingsInfo node={node} />;
    case WorkflowNodes.SEND_SMS:
      return <SendSMSNodeSettingsInfo node={node} />;
    case WorkflowNodes.SEND_CARD:
      return <SendCardNodeSettingsInfo node={node} />;
    case WorkflowNodes.WEBPUSH:
      return <WebpushNodeSettingsInfo node={node} />;
    case WorkflowNodes.VIBER:
      return <ViberNodeSettingsInfo node={node} />
    case WorkflowNodes.MOBILE_PUSH:
      return <MobilePushNodeSettingsInfo node={node} />
    case WorkflowNodes.API_REQUEST:
      return <ApiRequestNodeSettingsInfo node={node} />;
    case WorkflowNodes.EVENT:
      return <EventNodeSettingsInfo node={node} />;
    case WorkflowNodes.RESOURCE:
      return <ResourceNodeSettingsInfo node={node} />;
    case WorkflowNodes.CONTACT_CREATED:
      return <NewContactNodeSettingsInfo node={node} />;
    case WorkflowNodes.AB_TEST:
    case WorkflowNodes.AB_SPLITTER:
      return <ABTestingNodeSettingsInfo node={node} />;
    case WorkflowNodes.CUSTOMER_CHANGE:
      return <CustomerChangeNodeSettingsInfo node={node} />;
    case WorkflowNodes.RETARGETING:
      return <RetargetingNodeSettingsInfo node={node} />;
    case WorkflowNodes.INCLUDE_CAMPAIGN:
      return <IncludeCampaignNodeSettingsInfo node={node} />;
    case WorkflowNodes.BEST_CHANNEL_TO_SEND:
      return <BestChanneltoSendInfo node={node} />
    case WorkflowNodes.GIVE_REWARD:
      return <GiveRewardNodeSettingsInfo node={node} />;
    case WorkflowNodes.TEAM_MESSAGE:
      return <TeamMessageNodeSettingsInfo node={node} />;
    case WorkflowNodes.WEB_POPUP:
      return <WebPopupNodeSettingsInfo node={node} />;
    case WorkflowNodes.API_FILTER:
    case WorkflowNodes.END_PATH:
    case WorkflowNodes.INCLUDE:
    case WorkflowNodes.EXCLUDE:
    default:
      return null;
  }
}

export default React.memo(NodeSettingsInfo, (a, b) => a.id === b.id && a.data?.state === b.data?.state);

