import { Menu } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const DropdownInner = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  ${styledProp('tooltip', css`
    max-width: calc(100% - 28px);
  `)}
  color: #fff;
  background: transparent;
  border: 1px solid #7C8C9E;
  border-radius: 12px;
  transition: all 330ms ease;
  position: relative;
  cursor: pointer;

  &:focus-within {
    border-color: #fff;

    & > label {
      color: #fff;
    }

    ${styledProp('hasError', css`
      border-color: #DC5F4E;
      
      & > input {
        color: #DC5F4E;
      }

      & > label {
        color: #DC5F4E;
      }
    `)}
  }

  ${styledProp('hasError', css`
    border-color: #DC5F4E;

    & > input {
      color: #DC5F4E;
    }

    & > label {
      color: #DC5F4E;
    }
  `)}
`;

export const Overlay = styled(Menu)`
  max-height: 400px;
  overflow-y: auto;
  background: #313136;
  border-radius: 12px;
`;

export const Option = styled.div`
  font-family: Manrope-SemiBold;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #FFFFFF;
  padding: 14px 20px;
  user-select: none;
  cursor: pointer;
  transition: all 270ms ease;
  
  ${styledProp('$selected', css`
    color: #31C447;
  `)}
  
  &:hover {
    background: #3F3F46;
  }
`;

export const Title = styled.div`
  position: absolute;
  font-size: 11px;
  line-height: 140%;
  color: #7C8C9E;
  padding: 0 3px;
  background: #1F1F24;
  top: -7px;
  left: 13px;
  transition: all 330ms ease;
`;

export const Hint = styled.div`
  font-family: Manrope-Medium;
  font-size: 12px;
  line-height: 100%;
  color: #7C8C9E;
  position: absolute;
  bottom: -16px;
  left: 0;
`;
