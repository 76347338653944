import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${switchStyle('$status', {
    passed: css`
      color: #909399;
      cursor: pointer;
    `,
    current: css`
      color: #303133;
    `,
    next: css`
      color: #909399;
    `,
  })}
`;

export const StepIndicator = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${switchStyle('$status', {
    passed: css`
      background: #DCDFE6;
      border: 1px solid #DCDFE6;
    `,
    current: css`
      background: #FFFFFF;
      border: 2px solid #24B277;
    `,
    next: css`
      border: 2px solid #909399;
    `,
  })}
`;

export const CurrentIndicator = styled.div`
  width: 14px;
  height: 14px;
  background: #24B277;
  border-radius: 50%;
`;

export const NextIndicator = styled.div`
  font-family: Manrope-Medium;
  font-size: 10px;
  line-height: 90%;
  color: #909399;
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  ${switchStyle('$status', {
    passed: css`
      color: #909399;
    `,
    current: css`
      color: #303133;
    `,
    next: css`
      color: #909399;
    `,
  })}
  
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 14px;
  padding: 0 0 0 18px;
  ${styledProp('$last', '', css`
    border-left: 1px solid #909399;
  `)}
  margin-left: 11px;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: max-height 300ms ease;
  overflow: hidden;
  flex: 0 1 auto;
  
  ${switchStyle('$status', {
    passed: css`
      max-height: 14px;
    `,
    current: css`
      max-height: 600px;
    `,
    next: css`
      max-height: 14px;
    `,
  })}
  
`;

export const Dummy = styled.div`
  width: 100%;
  min-height: 14px;
`;

export const ActionsRow = styled.div``;
