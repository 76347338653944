import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  ${styledProp('$smaller', css`
    margin-bottom: 2px;
  `, css`
    margin-bottom: 40px;
  `)}
`;

export const DeleteButtonContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  ${styledProp('$disabled', css`
    opacity: 40%;
    cursor: not-allowed !important;
  `)}
`;

export const EventName = styled.div`
  color: var(--text-primary-body, #1A232E);
  font-family: Manrope-SemiBold;
  font-size: 21px;
  line-height: 26px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EventPanel = styled.div`
  border-radius: 10px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Neutral-100, #F8FAFE);
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.30);
  margin-bottom: 26px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 20px 18px 30px;
  position: relative;
`;

export const EventRow = styled.div`
  display: flex;
  align-items: center;
`;

export const CompletionPeriod = styled.div`
`;

export const CompletionPeriodTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--input-input-txt-lable, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 3px;
`;

export const CompletionPeriodForm = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const LogicalOperatorSelectContainer = styled.div`
  position: absolute;
  bottom: -14px;
  left: 20px;
`;

export const FiltersContainer = styled.div`
  position: relative;
`;

export const Line = styled.div`
  position: absolute;
  width: 1px;
  left: 32px;
  top: 0;
  bottom: 0;
  background-color: #C1CEFC;
`;

export const ChangeRuleWarning = styled.div`
  padding: 9px 20px;
  border: 1px solid #FFDC7B;
  background-color: #FFFAED;
  font-family: Manrope-Medium;
  font-size: 13px;
  text-align: center;
  max-width: 220px;
  margin-top: 5px;
  margin-left: 67px;
  border-radius: 7px;
`;

export const AddFilterButtonContainer = styled.div`
  
`;
