import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  width: 100%;
  background-color: #1A232E;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationsItem = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  &:hover{
    background-color: #7C94B11F;
    cursor: pointer;
  }
`;

export const NotificationsCounter = styled.div`
  position: absolute;
  top: 9px;
  right: 4px;
  padding: 0 4px;
  border-radius: 10px;
  background-color: #F04438;
  color: #FFFFFF;
  font-family: Manrope-SemiBold;
  font-size: 10px;
`;

export const InnerLeft = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding-left: 18px;
`;

export const InnerRight = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Logo = styled.img`
`;
export const Img = styled.img`
  height: 20px;
  width: 20px;
`;
export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 16px;
`;

export const DocumentationItem = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    background-color: #7C94B11F;
    cursor: pointer;
  }
`;
