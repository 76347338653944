import { SystemEvents } from '@constants/systemEvents';

export const EventPresets = [
  {
    events: [SystemEvents.VIBER, SystemEvents.EMAIL, SystemEvents.SMS, SystemEvents.MOBILE_PUSH, SystemEvents.WEBPUSH],
    presetFields: ['_te', '_s'],
  },
  {
    events: [SystemEvents.FIRST_SESSION, SystemEvents.START_SESSION, SystemEvents.END_SESSION],
    presetFields: ['_de', 'created_at', '_br', '_os'],
  },
  {
    events: [SystemEvents.PAGE_VIEW],
    presetFields: ['created_at', '_u'],
  },
  {
    events: [SystemEvents.RFM_SEGMENT_TRANSITION],
    presetFields: ['rf_rfm_id', 'rf_segment', 'rf_previous_segment'],
  },
  {
    events: ['bet'],
    presetFields: ['b_type'],
  }
];
