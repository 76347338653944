import React from 'react';

import { useTranslation } from '@hooks';

import { formatNumberString, zeroIfNaN } from '@utils';

import { Link } from '@components';

import { Container, Header, InfoContainer, InfoCount, InfoPercentage, Row, Title, Total } from './styled.js';

const SendingInfo = ({ link, testId = '', to, total, clicks, openedCount, containerStyle, disabledClicks }) => {
  const { p } = useTranslation('dashboard_page');

  return (
    <Container data-testid={testId} style={containerStyle}>
      <Header>
        <Link semiBold={true} style={{ color: '#909399' }} to={to}>{link}</Link>
      </Header>
      <Row>
        <Title>{p('total')}</Title>
        <Total data-testid={`dashboard-sendings-total-${formatNumberString(total)}`}>{formatNumberString(total)}</Total>
      </Row>
      {clicks && (
        <Row style={{ opacity: disabledClicks ? .4 : 1, marginBottom: openedCount ? 18 : 0 }}>
          <Title>{p('clicks')}</Title>
          <InfoContainer>
            <InfoPercentage>
              {clicks.percentage?.toFixed(2)}%
            </InfoPercentage>
            <InfoCount data-testid={`dashboard-sendings-click-${zeroIfNaN(clicks.count)}`}>
              {zeroIfNaN(clicks.count)}
            </InfoCount>
          </InfoContainer>
        </Row>
      )}
      {openedCount && (
        <Row style={{ opacity: disabledClicks ? .4 : 1, marginBottom: 0 }}>
          <Title>{p('opened')}</Title>
          <InfoContainer>
            <InfoPercentage>
              {openedCount.percentage?.toFixed(2)}%
            </InfoPercentage>
            <InfoCount data-testid={`dashboard-sendings-opened-${zeroIfNaN(openedCount.count)}`}>
              {zeroIfNaN(openedCount.count)}
            </InfoCount>
          </InfoContainer>
        </Row>
      )}
    </Container>
  );
}

export default React.memo(SendingInfo);

