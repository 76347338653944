import { Container } from './styled.js';

const InfoTitle = ({ children, ...props }) => {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
}

export default InfoTitle;

