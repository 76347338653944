import omit from 'lodash.omit';
import uniqBy from 'lodash.uniqby';

import { WorkflowNodes, WorkflowNodeTypes } from '@constants';

import { createEdge, extract } from '@utils';
import { getParent } from '@utils/workflows/refactored/getters';

export const presetStyle = (root, styles, id, name) => {
  const preset = { position: { x: 0, y: 0 } };

  if (name === WorkflowNodes.END_PATH) {
    const parent = getParent(root, id);
    preset.type = WorkflowNodeTypes.FINISH;
    preset.position.x = styles[parent?.id]?.position?.x + 90;
    preset.position.y = styles[parent?.id]?.position?.y;
  }
  if (name === 'array') {
    preset.type = WorkflowNodeTypes.ARRAY;
  }

  return preset;
};

const presetLabelFromSegment = (root, id, type, data) => {
  if (type === WorkflowNodeTypes.SPLITTER) {
    const parent = getParent(root, id);
    const index = parent.children.findIndex((splitterNode) => splitterNode.id === id);

    if (parent?.data?.segments === undefined) {
      return data.label;
    }

    const segment = parent?.data?.segments[index];
    return segment?.label;
  }

  return null;
};

export const mergeStyles = ({ id, type: name, data, before } = {}, styles, root) => {
  const preset = presetStyle(root, styles, id, name);
  const {
    label,
    description,
    type,
    position,
    actionType,
    style,
    temp,
    sourcePosition,
    targetPosition,
    data: stylesData,
  } = {
    ...preset,
    ...(styles[id] ||
      styles[root.data?.id] ||
      styles[root.data?.serverId] ||
      styles[root.data?.renderId] ||
      {}),
  };
  const presetLabel = !data?.label && presetLabelFromSegment(root, id, type, data);

  return {
    id,
    type,
    position,
    style,
    width: 94,
    height: 70,
    draggable: true,
    sourceHandle: actionType !== 'entry_point' ? 'left' : undefined,
    targetHandle: 'right',
    sourcePosition,
    targetPosition,
    data: {
      ...data,
      ...(stylesData || { validated: false }),
      label: data?.label || label || presetLabel,
      name,
      id,
      temp,
      before,
      actionType,
      description,
      children: data?.children,
    },
  };
};

const getNodes = (root, styles, first) => {
  if (!root) {
    return [];
  }

  return uniqBy(
    [
      mergeStyles(root, styles, first || root),
      ...(root.children || []).flatMap((child) => [
        ...(!!~(styles[child.id]?.connectorRemoved || []).indexOf(root.id)
          ? []
          : [
            createEdge({
              source: root.id,
              target: child.id,
              data: { removable: root.data?.addedConnection },
            }),
          ]),
        ...getNodes(child, styles, root),
      ]),
    ],
    extract('id'),
  );
};

export const hideNodes = (root) => {
  return {
    ...(root || {}),
    children: (root?.children || []).flatMap((child) => {
      if (child.data?.hide) {
        return (child.children || [])
          .flatMap((child) => (child.data?.hide ? child?.children || [] : [child]))
          .map(hideNodes);
      }

      return [hideNodes(child)];
    }),
  };
};

export const toReactFlowNodesArray = (root, styles, first) => {
  if (!root) {
    return [];
  }

  const renderNodes = getNodes(hideNodes(root), styles, first);

  const finish = renderNodes.filter(({ data }) => data?.name === WorkflowNodes.END_PATH);
  const include = renderNodes.filter(({ data }) => data?.name === WorkflowNodes.INCLUDE);
  const exclude = renderNodes.filter(({ data }) => data?.name === WorkflowNodes.EXCLUDE);
  const other = renderNodes.filter(
    ({ data }) =>
      data?.name !== WorkflowNodes.END_PATH &&
      data?.name !== WorkflowNodes.INCLUDE &&
      data?.name !== WorkflowNodes.EXCLUDE,
  );

  const main = [...include, ...exclude, ...other];

  return [...uniqBy(finish, ({ data }) => data.source), ...main];
};

export const iterate = (root, iterateFn) => {
  if (!root) {
    return;
  }

  iterateFn(root);
  root.children?.map?.((child) => iterate(child, iterateFn));
};

export const toArray = (root) => {
  if (!root) {
    return [];
  }

  return uniqBy(
    [omit(root, ['children']), ...(root.children || []).flatMap(toArray)],
    extract('id'),
  );
};

export const syncStyles = (styles, root) => {
  const newStyles = (root.children || []).reduce(
    (acc, child) => ({ ...acc, ...syncStyles(styles, child) }),
    { ...styles },
  );

  if (!styles[root.id]) {
    newStyles[root.id] = { id: root.id, position: { x: 150, y: 150 } };
  }

  return newStyles;
};

export const getConnectors = (root) => {
  if (!root?.children) {
    return [];
  }

  return root.children.flatMap((child) => [
    createEdge({ source: root.id, target: child.id }),
    ...getConnectors(child),
  ]);
};
