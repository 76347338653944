import { useRef, createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { accessTokenSelector } from '@store/selectors';

import { Centrifuge } from 'centrifuge';
import { v4 as uuid } from 'uuid';

const urlConfig = {
  'production': 'wss://prod.kong-proxy.retainly.app/socket/connection/websocket',
  'development': 'wss://stage.kong-proxy.retainly.app/socket/connection/websocket'
};
const isDevelopment = () => { //TODO: add env variable production and development
  if (~window.location.host?.indexOf?.('stage') || ~window.location.host?.indexOf?.('local') ||~window.location.host?.indexOf?.('127.0.0.1')) {
    return true;
  }
  return false;
}

export const WebSocketService = ({
  userId, 
  workspaceId
}) => {
  const accessToken = useSelector(accessTokenSelector);
  const location = useLocation();
  const wsInstance = useRef();
  const channelsWithSubs = useRef({});

  useEffect(() => {
    if(wsInstance.current?.state === 'connected') {

      disconnect(() => {
        init();
      });
    }
  }, [userId, workspaceId, accessToken]);

  useEffect(() => {
    if(wsInstance.current?.state === 'disconnected') {
      init();
    }
  }, [location.pathname]);

  const init = async (successCallback, failCallback) => {

    if(wsInstance.current?.state === 'connected') {
      console.log('Already initialized');
      successCallback?.();
      return;
    }
    const socketUrl = isDevelopment() ? urlConfig['development'] : urlConfig[process.env.NODE_ENV];

    wsInstance.current = new Centrifuge(socketUrl,
      {
        data: {
          auth_token: accessToken,
        },
      }
    );
 
    wsInstance.current.on('connected', (ctx) => {
      successCallback?.();
      console.log(`connected over ${ctx.transport}`);

    });

    wsInstance.current.on('disconnected', function (ctx) {
      console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
    });

    wsInstance.current.on('error', function (ctx) {
      failCallback?.();
      if(ctx?.error) {
        wsInstance.current.disconnect();
        console.error(ctx?.error?.message || 'Somthing was wrong with websocket connection')
      }
      console.log(`error: ${ctx.code}, ${ctx.reason}`);
    });

    wsInstance.current.on('publication', function(ctx) {
 
      const subscribersList = channelsWithSubs.current[ctx.channel];

      subscribersList?.forEach((subsriber) => {
        subsriber.callback(ctx.data);
      });
    });

    if (wsInstance.current?.state !== 'connected') {
      
      wsInstance.current.connect();
    }
  };

  const disconnect = (successCallback) => {
    wsInstance.current?.on('disconnected', function () {
      successCallback?.();
    });

    if (wsInstance.current?.state === 'connected') {
      wsInstance.current.disconnect();     
    } else {
      console.log('Web socket not connected');
    }
  };

  const getHistory = async (channel, limit) => {
    return await wsInstance.current.history(channel, {
      limit,
    });
  };

  const subscribeToServerSubs = (channel, subscriber) => {
    const channelId = uuid();
    const currentChannelSubscriber = channelsWithSubs.current[channel] || [];
    
    channelsWithSubs.current = {
      ...channelsWithSubs.current,
      [channel]: [...currentChannelSubscriber, {
        id: channelId,
        callback: subscriber
      }]
    }
    return channelId;
  };

  const unsubcribeFromServerSubs = (channel, channelId) => {
   
    const currentChannelSubscriber = channelsWithSubs.current[channel];
    const updatedList = currentChannelSubscriber.filter((subscribers) => subscribers.id !== channelId);

    channelsWithSubs.current = {
      ...channelsWithSubs.current,
      [channel]: updatedList
    }
  };
  
  const subscribe = (channel, subscriber) => {
    try {

      const sub = wsInstance.current.newSubscription(channel);
      sub.on('publication', (ctx) => {
        subscriber(ctx.data);
      })
        .subscribe();
      return sub;
    } catch (error) {
      //if already subscribed
      return getSubscription(channel, subscriber);
    }
   
  };

  const getSubscription = (channel, subscriber) => {
    try {
      const sub = wsInstance.current.getSubscription(channel);
      console.log(sub, 'sub', wsInstance.current);
      sub
        .on('publication', (ctx) => {
          subscriber(ctx.data);
        })
        .subscribe();
      return sub;
    } catch (error) {
      console.log(error, 'errorrr')
    }
   
  };

  return {
    init,
    status: wsInstance.current?.state,
    getHistory,
    disconnect,
    subscribe,
    subscribeToServerSubs,
    unsubcribeFromServerSubs,
    getSubscription,
  };
};

export const WebSocketContext = createContext(null);