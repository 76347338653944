import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
    width: 320px;
    margin-top: 28px;
`;

export const TitleContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    
    &::before {
        display: block;
        background: var(--border-divider, #D3DBE7);
        content: "";
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
    }
`;

export const Title = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 4px 10px;
    background: #F4F5FD;
    color: var(--text-secondary-body, #7C94B1);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px;
`;

export const CurrencyOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 4px;
`;

export const OptionRow = styled(motion.div)`
    display: flex;
    gap: 16px;
    position: relative;
`;

export const OptionCol = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const OptionLabel = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 4px;

    color: var(--input-input-txt-lable, #7C94B1);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 12px;
    line-height: 18px;
`;

export const OptionPanel = styled.div`
    border-radius: var(--input-input-radius-big, 7px);
    border: 1px solid var(--input-input-border, #D3DBE7);
    background: var(--input-input-bg, #FFF);
    padding: 7px 12px;

    color: var(--input-input-txt, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 20px;
    
    transition: all 300ms ease;
    
    ${styledProp('$disabled', css`
        background: var(--input-input-bg-disabled, #ECF0F6);
    `)}
`;

export const Error = styled.div`
    position: absolute;
    right: 0;
    white-space: nowrap;
    bottom: 0;

    color: var(--input-input-txt-danger, #F04438);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 11px;
    line-height: 16px;
`;
