import { useTranslation } from '@hooks';

import { ChartColors } from '@constants';

import {
  formatNumberByK,
  formatNumberString
} from '@utils';

import {
  PieChart,
  LegendItem
} from '@components';

import { Container, PieChartTitle, PieChartTotal, PieChartTotalCaption, StatusesContainer, StatusesLegendRow, StatusesLegendValue } from './styled';

export const StatusesTab = ({ total, summary }) => {
  const { t } = useTranslation('campaigns_page');

  const statusSegments = Object.entries(summary).map(([status, count], index) => ({
    id: status,
    color: ChartColors[index],
    value: count,
    name: t(`statuses.${status}`),
  })).filter(({ id }) => id !== 'total');

  return (
    <Container>
      <PieChart
        total={total}
        showZeroValues={false}
        tooltipPlacement='right'
        title={
          <PieChartTitle>
            <PieChartTotal>
              {formatNumberByK(total, 1, 999)}
            </PieChartTotal>
            <PieChartTotalCaption>
              {t('labels.total')}
            </PieChartTotalCaption>
          </PieChartTitle>
        }
        appearance={{
          size: 260,
          segmentRadiusInner: 67,
          segmentRadiusOuter: 130,
        }}
        segments={statusSegments || []}
      />
      <StatusesContainer>
        {statusSegments?.map(({ id, color, name, value }) => (
          <StatusesLegendRow key={id} style={{ marginBottom: 0 }}>
            <LegendItem name={name} color={color} />
            <StatusesLegendValue data-testid={`status-${name}-count`}>
              {formatNumberString(value)}
            </StatusesLegendValue>
          </StatusesLegendRow>
        ))}

      </StatusesContainer>
    </Container>
    
  )
}