import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  min-width: 380px;
  width: 50%;
  
  
  ${styledProp('$integrated', css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `, css`
    margin-bottom: 15px;
    margin-left: 5px;
    //margin-top: 59px;
  `)}
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  position: relative;
`;

export const Image = styled.img`
  width: 260px;
`;

export const PreviewData = styled.div`
  position: absolute;
  width: 223px;
  bottom: 40px;
  margin-left: 20px;
  background: #EFEFEF;
  border-radius: 0 10px 10px 10px;
  overflow: scroll;
  max-height: 80%;
  transform: translateX(-10px);
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-family: Manrope-Medium;
  margin: 3px 4px 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Body = styled.div`
  font-size: 12px;
  line-height: 14px;
  padding: 8px 16px 8px 8px;
  word-break: break-all;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //-webkit-box-orient: vertical;
  //display: -webkit-box;
  //-webkit-line-clamp: 2;
  //word-break: break-word;
  font-family: "Noto Sans Regular";
  
  & > span.bold, & > b {
    font-weight: bold;
  }
`;

export const Button = styled.div`
  background: #7062C8;
  border-radius: 58px;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 6px;
  margin: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Icon = styled.img`
  width: 236px;
  max-height: 180px;
  border-bottom-right-radius: 5px;
`;

export const ContentTitle = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const Content = styled.div`
  background: #F9FBFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px 0;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 590px;
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
  `)}

  ${styledProp('$integrated', css`
    //width: 380px;
    border: none;
    background: transparent;
    box-shadow: none;
  `)}
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;

  ${styledProp('selected', css`
    color: #31C447;
  `)}
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  border-bottom: 1px solid #DCDFE6;
  padding: 14px;
`;

export const TabOptionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 24px 38px;
  position: relative;
`;

export const TabOption = styled.div`
  cursor: pointer;
  transition: 330ms ease all;
  
  ${styledProp('selected', css`
    color: #31C447;
    text-decoration-line: underline;
  `)}
`;

export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #31C447;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ tab }) => {
    const el = document.querySelector(`#wpp-tab-${tab}`);

    return css`
      width: ${el?.getBoundingClientRect()?.width}px;
      left: ${el?.offsetLeft}px;
    `;
  }}
`;

export const PreviewContainer = styled.div`
  display: flex;
  margin-bottom: 10;
  justify-content: flex-end;

  position: absolute;
  top: -78px;
  width: 100%;
`
