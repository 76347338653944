import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 16px 20px 0px 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 2px solid transparent;
  gap: 8px;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(153, 163, 194, 0.3);
  max-width: 236px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 2px solid #31c447;
  }
  &.active {
    border: 2px solid #31c447;
  }
`;
export const Title = styled.h2`
  color: #303133;
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 0;
`;
export const Description = styled.h4`
  color: #8b8e9f;
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  max-width: 196px;
  padding-bottom: 21px;
`;
