import { useEffect } from 'react';

import { useTranslation } from '@hooks';

import { by } from '@utils';

import { AutoComplete } from '@components';

import { Body, Container, Header, Title } from './styled';

const DynamicVariablesSettings = ({ variables, value, onChange }) => {
  const { p } = useTranslation('workflow_page');

  useEffect(() => {
    onChange?.(vs => ({ ...vs, ...Object.fromEntries((variables || []).filter(({ name }) => !vs[name]).map(({ name, default_value }) => [name, default_value])) }), true);
  }, [(variables || []).join('.')]);

  if (!variables?.length) {
    return null;
  }

  const handleChange = (name) => (value) => {
    onChange(vs => ({ ...vs, [name]: value }));
  };

  const handleBlur = (name) => () => {
    if (value[name]) {
      return;
    }

    onChange(vs => ({ ...vs, [name]: void 0 }));
  };

  return (
    <Container>
      <Header>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
          <path
            d="M16.5 16.5V17.875H18.1907C17.8065 18.3085 17.3344 18.6553 16.8058 18.8924C16.2772 19.1295 15.7043 19.2514 15.125 19.25C14.0314 19.2488 12.9828 18.8138 12.2095 18.0405C11.4362 17.2672 11.0012 16.2186 11 15.125H9.625C9.62278 16.2433 9.96222 17.3357 10.5979 18.2558C11.2335 19.176 12.1351 19.88 13.1819 20.2736C14.2286 20.6673 15.3707 20.7319 16.4551 20.4586C17.5396 20.1854 18.5147 19.5874 19.25 18.7448V20.625H20.625V16.5H16.5Z"
            fill="#0C1015"/>
          <path
            d="M15.125 9.625C14.3436 9.62902 13.5719 9.79868 12.8609 10.1228C12.1498 10.4469 11.5156 10.9181 11 11.5052V9.625H9.625V13.75H13.75V12.375H12.0593C12.4435 11.9415 12.9156 11.5947 13.4442 11.3576C13.9728 11.1205 14.5457 10.9986 15.125 11C16.2186 11.0012 17.2672 11.4362 18.0405 12.2095C18.8138 12.9828 19.2488 14.0314 19.25 15.125H20.625C20.6233 13.6668 20.0433 12.2688 19.0122 11.2378C17.9812 10.2067 16.5832 9.62667 15.125 9.625Z"
            fill="#0C1015"/>
          <path
            d="M8.25 19.25H4.125V16.5H5.5V15.125H4.125V11.6875H5.5V10.3125H4.125V6.875H5.5V5.5H4.125V2.75H16.5V8.25H17.875V2.75C17.875 2.38533 17.7301 2.03559 17.4723 1.77773C17.2144 1.51987 16.8647 1.375 16.5 1.375H4.125C3.76033 1.375 3.41059 1.51987 3.15273 1.77773C2.89487 2.03559 2.75 2.38533 2.75 2.75V5.5H1.375V6.875H2.75V10.3125H1.375V11.6875H2.75V15.125H1.375V16.5H2.75V19.25C2.75 19.6147 2.89487 19.9644 3.15273 20.2223C3.41059 20.4801 3.76033 20.625 4.125 20.625H8.25V19.25Z"
            fill="#0C1015"/>
        </svg>
        <Title>{p('dynamic_attributes')}</Title>
      </Header>
      <Body>
        {variables.map(({ label, name }) => (
          <AutoComplete
            key={label}
            title={label}
            suggestionsOptions={(label.toLocaleLowerCase().startsWith('tag') || label.toLocaleLowerCase().endsWith('tag')) && {
              "event": "tag",
              "fields": ["tg_tag_name"]
            }}
            fullWidth
            wrapperStyles={{ width: '100%' }}
            style={{ width: '100%' }}
            value={(value[name] === undefined ? (variables || []).find(by('name', name))?.default_value : value[name]) || ''}
            onChange={handleChange(name)}
            onBlur={handleBlur(name)}
          />
        ))}
      </Body>
    </Container>
  );
};

export default DynamicVariablesSettings;
