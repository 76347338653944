import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listNestedAggregates } from '@store/actions/creators';
import { getClickhouseFields } from '@store/actions/creators/fields';
import { optionsAggregatesSelector , emailSampleDataSelector, eventListWithNestedAggregaesSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { Button } from '@velitech/ui';
import { Drawer } from 'antd';

import { useDateFormat, useModalState, useTranslation } from '@hooks';

import { tryMongoDateObject } from '@utils';
import { resolveEventName } from '@utils/events';
import { clickhouseFields2Customer } from '@utils/fields';

import AvatarImg from '@res/images/user-placeholder.svg';

import { Input, CopyHandle, EventDetailsModal } from '@components';

import {
  Container,
  User,
  UserInfoWrapper,
  Avatar,
  UserInfo,
  UserName,
  UserId,
  FilterForm,
  TableWrapper,
  CopyButton,
  Table,
  TableRow,
  TableCell,
  TabIndicator,
  Tab,
  Tabs,
} from './styled';

const getTabs = (p) => [
  {
    name: 'linear',
    title: p('linear'),
  },
  {
    name: 'aggregates',
    title: p('aggregates'),
  },
  { name: 'events', title: p('events') },
];

const EmailSampleData = ({ opened, onClose, smaller, onVisibilityChanged, width = null, customClassName, getContainer, bodyStyles = {} }) => {
  const sampleData = useSelector(emailSampleDataSelector);
  const clickhouseFields = useSelector(clickhouseFieldsSelector)?.data || [];
  const fields = clickhouseFields2Customer(clickhouseFields).map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const aggregates = useSelector(optionsAggregatesSelector);
  const format = useDateFormat();
  const dispatch = useDispatch();
  const [tab, setTab] = useState('linear');
  const events = useSelector(eventListWithNestedAggregaesSelector);
  const [event, setEvent] = useState({});
  const detailsModal = useModalState();
  const { p, t } = useTranslation('templates');

  const handleShowEvent = ({ name, payload, label, nested_aggregates }) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const p = !nested_aggregates || !nested_aggregates?.length ? payload : [...payload, ...nested_aggregates?.map(({ name, label }) => ({ field: name, label, type: 'aggregate' }))]
    setEvent({ name, payload: p, label });
    detailsModal.open();
  }

  useEffect(() => {
    dispatch(getClickhouseFields());
    dispatch(listNestedAggregates());
  }, []);

  const tabs = getTabs(p);

  const eventsShown = (events.data || []).filter(({ payload }) => payload && Object.keys(payload).length);

  const [drawerZIndex, setDrawerZIndex] = useState(-1);

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchableValue, setSearchableValue] = useState('');

  const tableFields = useMemo(
    () => fields
      .map(({ name, ...rest }) => ({ name, value: sampleData[name], ...rest }))
      .filter(({ name, key, label }) => [name, key, label].some(v => v && v?.toLowerCase()?.includes?.(searchableValue.toLowerCase())))
      .sort((a, b) => (a.label || a.name || a.key).localeCompare(searchableValue) - (b.label || b.name || b.key).localeCompare(searchableValue)),
    [fields, searchableValue]
  );

  const resolveCopyData = (key, name, isEvent = false) => {
    if (key === 'subscribe_url' || key === 'unsubscribe_url' || key === 'global_unsubscribe_url') {
      return `{{${key}}}`;
    }

    return isEvent ? `{{${key}}}` : `{{customer.${key}}}`;
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchableValue(searchInputValue);
  };

  const resetHandler = () => {
    setSearchableValue('');
    setSearchInputValue('');
  };

  const afterVisibleChange = (visibility) => {
    setDrawerZIndex(visibility ? 1 : -1);
    if (onVisibilityChanged) {
      onVisibilityChanged(visibility);
    }
  };

  const getEventPayload = () => {
    const fields = {};

    event.payload?.forEach(p => {
      fields[p.field] = p.label;
    })

    return fields;
  };

  const resolveName = () => {
    const fname = fields.find(({ name, value }) => name.toLowerCase().includes('first') && name.toLowerCase().includes('name') && value);
    const lname = fields.find(({ name, value }) => name.toLowerCase().includes('last') && name.toLowerCase().includes('name') && value);

    if (fname && lname) {
      return `${fname.value} ${lname.value}`;
    }
  };

  const resolvedName = sampleData.userName || resolveName();

  return (
    <Drawer
      className={customClassName}
      placement="right"
      onClose={onClose}
      visible={opened}
      width={width || (smaller ? '400px' : '617px')}
      getContainer={getContainer}
      bodyStyle={{ padding: 0, overflow: 'hidden', ...bodyStyles }}
      mask={false}
      style={{ position: 'absolute', height: '100%', top: 0, bottom: 0, left: 'auto', right: 0, width: width || (smaller ? '400px' : '617px'), pointerEvents: opened ? 'auto' : 'none' }}
      zIndex={opened ? 1000 : drawerZIndex}
      closable={false}
      afterVisibleChange={afterVisibleChange}
    >
      <Container>
        <User>
          <UserInfoWrapper>
            <Avatar src={AvatarImg} alt="avatar" />
            <UserInfo>
              <UserName>{resolvedName}</UserName>
              <UserId>{p('id', { id: sampleData.id })}</UserId>
            </UserInfo>
          </UserInfoWrapper>

          <FilterForm onSubmit={searchHandler}>
            <Input
              style={{ margin: '0px', width: '100%' }}
              wrapperStyles={{ width: '100%' }}
              placeholder={t('actions.search')}
              type="text"
              value={searchableValue}
              onChange={(e) => setSearchableValue(e.target.value)}
            />
            <Button width={82} style={{ marginLeft: '20px' }} onClick={resetHandler} variant="secondary">
              <div>{t('actions.discard')}</div>
            </Button>
          </FilterForm>
        </User>

        <TableWrapper>
          <Tabs>
            {tabs.map(({ name, title }) => (
              <Tab
                key={name}
                id={`wpp-tab-${name}`}
                selected={name === tab}
                onClick={() => setTab(name)}
              >
                {title}
              </Tab>
            ))}
            <TabIndicator key={tab} tab={tab} />
          </Tabs>
          <Table>
            {tab === 'linear' && (
              <>
                <TableRow $titles>
                  <TableCell widthPercent={39}>{t('labels.name')}</TableCell>
                  <TableCell widthPercent={39} withPadding>
                    {t('labels.default_value')}
                  </TableCell>
                  <TableCell widthPercent={22} alignEnd>
                    {t('labels.action')}
                  </TableCell>
                </TableRow>
                {tableFields.map(({ name, value, key, label }) => (
                  <TableRow key={key}>
                    <TableCell widthPercent={39}>{label}</TableCell>
                    <TableCell widthPercent={39} withPadding>
                      {(typeof value !== 'object' || value === null) && (typeof value === 'boolean' ? JSON.stringify(value) : tryMongoDateObject(value, format))}
                    </TableCell>
                    <TableCell widthPercent={22} alignEnd>
                      <CopyHandle align="flex-end" data={resolveCopyData(key, name)}>
                        <CopyButton data-testid="sample-data-copy">{p('copy')}</CopyButton>
                      </CopyHandle>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
            {tab === 'aggregates' && (
              <>
                <TableRow $titles>
                  <TableCell widthPercent={39}>{t('labels.name')}</TableCell>
                  <TableCell widthPercent={55} alignEnd>
                    {t('labels.action')}
                  </TableCell>
                </TableRow>
                {aggregates?.map(({ name, label }, index) => (
                  <TableRow key={index}>
                    <TableCell widthPercent={39}>{label}</TableCell>
                    <TableCell widthPercent={55} alignEnd>
                      <CopyHandle align="flex-end" data={resolveCopyData(name, label)}>
                        <CopyButton data-testid="sample-data-copy">{p('copy')}</CopyButton>
                      </CopyHandle>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
            {tab === 'events' && (
              <>
                <TableRow $titles>
                  <TableCell widthPercent={39}>{t('labels.label')}</TableCell>
                  <TableCell widthPercent={39} withPadding>
                    {t('labels.name')}
                  </TableCell>
                  <TableCell widthPercent={22} alignEnd>
                    {t('labels.action')}
                  </TableCell>
                </TableRow>
                {eventsShown.map(({ name, label, payload, id, nested_aggregates }) => (
                  <TableRow key={id}>
                    <TableCell widthPercent={39}>{label}</TableCell>
                    <TableCell widthPercent={39} withPadding>
                      {name}
                    </TableCell>
                    <TableCell widthPercent={22} alignEnd>
                      <CopyButton data-testid="sample-data-copy" onClick={() => handleShowEvent({ name, payload, label, nested_aggregates })}>{p('show_values')}</CopyButton>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
            <EventDetailsModal
              payload={getEventPayload()}
              resolveCopyData={(path) => resolveCopyData(`payload${path}`, event.name, true)}
              path="payload"
              label={resolveEventName(event?.label)}
              opened={detailsModal.opened}
              onClose={detailsModal.close}
            />
          </Table>
        </TableWrapper>
      </Container>
    </Drawer>
  );
};

EmailSampleData.defaultProps = {
  onVisibilityChanged: null,
  customClassName: 'EmailSampleData-drawer',
};

export default EmailSampleData;
