import styled from 'styled-components';

import { styledProp } from '@utils';

import * as Colors from '@res/theme/colors';

export const Container = styled.span`
  font-family: RIUP-icons;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => size}px;
  text-align: center;
  display: flex;
  align-items: center;
  user-select: none;
  
  ${styledProp('whiteHover', `
    &:hover {
      color: ${Colors.Sidebar.ICON_HOVER} !important;
    }
  `)}
`;
