import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 6px 42px;
  justify-content: center;
  align-items: center;
  flex: 0;
  position: fixed;
  bottom: 36px;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  gap: 10px;
  border-radius: 7px;
  border: 1px solid #FFDC7B;
  background: #FFFAED;
`;
