import { SegmentNodeTypes } from '@constants';

import { isGroup, isRule, zeroIfNaN } from '@utils';

import { resolveFilterType } from './common';

export const findRuleGroup = (query, rule) => {
  let res = null;

  if (query.query.children.some(({ query }) => query.name === rule)) {
    return query;
  }

  for (const child of query.query.children) {
    if (child.type !== SegmentNodeTypes.QUERY_RULE) {
      res = findRuleGroup(child, rule);

      if (res) {
        return res;
      }
    }
  }

  return res;
};

export const rulesCount = (query) => {
  let res = 0;

  if (isRule(query)) {
    res += 1;
  }

  if (isGroup(query)) {
    for (const child of query.query.children) {
      if (isRule(child)) {
        res += 1
      } else {
        res += rulesCount(child);
      }
    }
  }

  return res;
};

export const getNames = (query) => {
  let res = [0];

  if (isRule(query)) {
    res.push(zeroIfNaN(query.query.name));
  }

  if (isGroup(query)) {
    for (const child of query.query.children) {
      if (isRule(child)) {
        res.push(zeroIfNaN(child.query.name));
      } else {
        res.push(...getNames(child));
      }
    }
  }

  return res;
}

export const findRule = (query, rule) => {
  let res = null;

  if (rule === query.query.name) {
    return query;
  }

  if (query.type !== SegmentNodeTypes.QUERY_RULE) {
    for (const child of query.query.children) {
      if (rule === child.query.name) {
        res = child;
        break;
      }
      if (res) {
        return res;
      }

      res = findRule(child, rule);
    }
  }

  return res;
};

export const flatTree = (query, root) => {
  const items = {};

  items[query.query.name] = {
    id: query.query.name,
    children: query.query.children?.map?.(({ query }) => query.name),
    hasChildren: !!query.query.children,
    isExpanded: !!query.query.children,
    data: { name: query.query.name, type: resolveFilterType(query.query), root, query },
  }

  return {
    ...items,
    ...(query.query.children?.reduce?.((acc, q) => ({ ...acc, ...flatTree(q, query) }), {}) || {}),
  };
};

// FOR FLAT TREE

export const childLength = (items, name, group = false) => {
  return (group ? 0.22 : 0) + (
    items[name].children?.filter?.(child => !items[child].children)?.length || 0)
    + items[name].children?.reduce?.((acc, child) => acc + childLength(items, child, true), 0) || 0;
};

const ROW_HEIGHT = 60 + 22;

export const childHeight = (items, name) => {
  if (!items[name].children) {
    return ROW_HEIGHT;
  }

  return items[name].children.reduce((acc, child) => acc + childHeight(items, child), 6);
};

export const isNextGroup = (items, child) => {
  const [parent] = Object.entries(items).find(([, { children }]) => children && !!~children.indexOf(child));
  if (!parent) {
    return false;
  }

  return !!items[items[parent].children[items[parent].children.indexOf(child) + 1]]?.children;
}

export const isFirstInGroup = (items, child, _root) => {
  const [, parent] = Object.entries(items).find(([, { children }]) => children && !!~children.indexOf(child));

  return parent.children.indexOf(child) === 0;
}

export const isPrevGroup = (items, child) => {
  const [parent] = Object.entries(items).find(([, { children }]) => children && !!~children.indexOf(child));
  if (!parent) {
    return false;
  }

  return !!items[items[parent].children[items[parent].children.indexOf(child) - 1]]?.children;
};

export const aggregateUsed = (name, query, exceptions = []) => {
  let res = false;

  if (!~exceptions.indexOf(query.query.name) && query.query.field === name) {
    return true;
  }

  if (isGroup(query)) {
    for (const child of query.query.children) {
      if (!~exceptions.indexOf(query.query.name) && query.query.field === name) {
        res = true;
        break;
      }
      if (res) {
        return res;
      }

      res = aggregateUsed(name, child, exceptions);
    }
  }

  return res;
}
