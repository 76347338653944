import FieldsActionTypes from '@store/actions/types/fields';
import { createRequestAction } from '@store/lib/utils/creators';

import { all, throttle, takeLatest, put } from 'redux-saga/effects';

import { stringParams } from '@utils';

function* createDynamicVariableSaga(action) {
  yield put(createRequestAction(action, {
    method: 'post',
    endpoint: '/api/dynamic-variables',
    body: {
      name: action.payload.name,
      label: action.payload.label,
      default_value: action.payload.default_value,
    }
  }));
}

function* deleteDynamicVariableSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: `/api/dynamic-variables/${action.payload.id}`,
  }));
}

function* getAutocompleteSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/seg/autocomplete',
    params: {
      limit: action.meta.limit,
      offset: action.meta.offset,
      query: action.payload.query,
      ...(action.payload.filter ? stringParams('filter', action.payload.filter) : {}),
      path: (action.payload.path || []).join('.'),
    }
  }));
}

function* getDynamicVariablesSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: '/api/dynamic-variables',
  }));
}

function* updateDynamicVariableSaga(action) {
  yield put(createRequestAction(action, {
    method: 'put',
    endpoint: `/api/dynamic-variables/${action.payload.id}`,
    body: {
      label: action.payload.label,
      name: action.payload.name,
      default_value: action.payload.default_value,
    },
  }));
}

function* getClickhouseFieldsSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/cus/settings/fields/clickhouse/list`,
  }));
}

export default function* contactsRootSaga() {
  yield all([
    throttle(700, FieldsActionTypes.GET_AUTOCOMPLETE, getAutocompleteSaga),
    takeLatest(FieldsActionTypes.GET_DYNAMIC_VARIABLES, getDynamicVariablesSaga),
    takeLatest(FieldsActionTypes.CREATE_DYNAMIC_VARIABLE, createDynamicVariableSaga),
    takeLatest(FieldsActionTypes.UPDATE_DYNAMIC_VARIABLE, updateDynamicVariableSaga),
    takeLatest(FieldsActionTypes.DELETE_DYNAMIC_VARIABLE, deleteDynamicVariableSaga),
    takeLatest(FieldsActionTypes.GET_CLICKHOUSE_FIELDS, getClickhouseFieldsSaga),
  ]);
}
