import { Input as ANTDInput } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const PreviewContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  gap: 26px;
`;

export const CardsContainer = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  flex-wrap: wrap;
  justify-items: left;
  min-width: 740px;

  ${switchStyle('$previewType', {
    'preview-3-column': css`
      grid-template-columns: repeat(3, 1fr);
      min-width: unset;
      gap: 15px;
    `,
   
    [undefined]: `
    `
  })}

`;

export const StyledInput = styled(ANTDInput)`
  align-self: center;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 7px;
  height: 36px;
  font-size: 13px;
  line-height: 120%;
  text-overflow: ellipsis;
  padding-right: 24px;
  padding-left: 13px;
  margin-bottom: 16px;
  box-shadow: none !important;
  width: 336px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  
  ${styledProp('hasError', `
    margin-bottom: 0px;
    color: #C8402F;
    border-color: #C8402F !important;
  `)}
  
  ${styledProp('withSuffix', `
    padding-right: 5px;
  `)}
  
  ${styledProp('withPrefix', `
    padding-left: 6px;
  `)}

  ${styledProp('highlight', `
    border-color: #f3cc50 !important;
    color: #f3cc50 !important;
  `)}

  &:focus {
    box-shadow: none !important;
    border-color: #DCDFE6 !important;
  }
`;

export const Error = styled.div`
  font-size: 13px;
  height: 16px;
  margin-bottom: 16px;
  margin-top: 5px;
  line-height: 120%;
  color: #C8402F;
`;

export const Title = styled.div`
  align-self: center;
  color: #1A232E;
  font-family: Manrope-SemiBold;
  font-size: 18px;
`;

export const ExpirationlTitle = styled.div`
  font-family: 'Manrope-SemiBold';
  font-size: 16px;
  line-height: 21px;
  color: #1A232E;
`;

export const ContentTitle = styled.div`
  margin-bottom: 14px;
`;

export const ExpirationSettings = styled.div`
  
`;
export const ExpirationSettingsOption = styled.div`
  position: relative;
  margin-top: 16px;
  margin-left: 10px;
`;

export const SpecificSelects = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
  margin-left: 19px;
`;

export const PlaceHolder = styled.div`
  position: absolute;
  font-size: 13px;
  top: 30px;
  left: 60px;
  color: #bdbdbd;
`;

export const HeaderLeft = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ExpirationContent = styled.div`
  font-family: 'Manrope-SemiBold';
  font-size: 16px;
  line-height: 21px;
  color: #1A232E;
  padding: 30px 20px 24px 20px;
`;

export const SpecificInputs = styled.div`
  
`;

export const Expiration = styled.div`
  border-radius: 7px;
  background-color: #FFFFFF;
  border: solid 1px #D3DBE7;
  flex-direction: column;
  display: flex;
`;

export const ExpirationHeader = styled.div`
  background-color: #FFFFFF;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: solid 1px #D3DBE7;
  padding: 11px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const IconImg = styled.img`
  
`;

export const TabsContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const TabContainer = styled.div`
`;

export const AiSend = styled.div`
  border-radius: 7px;
  background-color: #FFFFFF;
  border: solid 1px #D3DBE7;
  flex-direction: column;
  display: flex;
`;

export const AiSendHeader = styled.div`
  background-color: #FFFFFF;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: solid 1px #D3DBE7;
  padding: 11px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const AiSendContent = styled.div`
  font-family: 'Manrope-SemiBold';
  font-size: 16px;
  line-height: 21px;
  color: #1A232E;
  padding: 30px 20px 24px 20px;
`;

export const AiContentTitle = styled.div`
  font-family: 'Manrope-SemiBold';
  font-size: 14px;
  color: #1A232E;

  ${styledProp('$disabled', css`
    color: #1a232e87;
 `)}
`;

export const AiTitle = styled.div`
  font-family: 'Manrope-SemiBold';
  font-size: 16px;
  line-height: 21px;
  color: #1A232E;
`;
