import { useDispatch, useSelector } from 'react-redux';

import { checkWebSDK } from '@store/actions/creators';
import { healthCheckWebSDKSelector } from '@store/selectors';

import { useNavigate, useTranslation } from '@hooks';

import { Paths } from '@constants';

import { Alert } from '../components/ui';

export const useHealthCheck = () => {
  const dispatch = useDispatch();
  const healthCheck = useSelector(healthCheckWebSDKSelector);
  const { p } = useTranslation('templates');

  const navigate = useNavigate();

  const healthCheckRequest = () => {
    dispatch(checkWebSDK());
  }

  const handleClickLink = (path) => {
    navigate(path, { replace: true })
  }

  const textConfig = {
    'card': 'Message feed',
    'web_popup': 'Web pop-up'
  }

  const getHealthCheckAlert = (type = 'card', options) => {
    const { withMargin } = options;

    return (
      <Alert 
        type='warning'
        withMargin={withMargin}
      >
       The system has not detected any <a onClick={() => handleClickLink(`${Paths.INTEGRATIONS}?type=category&name=api`)}>EDGE Web SDK</a> activity in the last 24 hours. {p('websdk_alert', { type: textConfig[type] })}
      </Alert>)
  }

  const getHealthCheckText = (type = 'card') => {
    return `The system has not detected any EDGE Web SDK activity in the last 24 hours. ${p('websdk_alert', { type: textConfig[type] })}`;
  };

  return {
    healthCheckRequest,
    status: healthCheck?.track_events,
    getHealthCheckAlert,
    getHealthCheckText
  }

}