import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    
    .flex {
        display: flex;
    }

    svg {
        &:hover path {
            fill: var(--text-color-hover);
        }
    }
`;

export const CustomIcon = styled.img`
    height: 16px;
    width: 16px;
`;
