import { SegmentsRuleTypes, WorkflowNodes } from '@constants';

import { by } from '@utils';

export const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export const viewSteps = {
  INITIAL: 'INITIAL',
  SHOW_LIST: 'SHOW_LIST',
  SHOW_SELECTED_FILTER: 'SHOW_SELECTED_FILTER',
  SHOW_SELECTED_SEGMENT: 'SHOW_SELECTED_SEGMENT',
  EXCLUDE: 'EXCLUDE',
  EMPTY: 'EMPTY',
};

export const SegmentType = {
  SEGMENT: 'segment',
};

export const listAttributeOptions = [
  SegmentsRuleTypes.LINEAR,
  SegmentsRuleTypes.EVENT,
  SegmentsRuleTypes.AGGREGATE,
  SegmentType.SEGMENT,
  SegmentsRuleTypes.RFM_SEGMENT,
];

export const titleListConfig = {
  [SegmentsRuleTypes.LINEAR]: 'linear_fields',
  [SegmentsRuleTypes.AGGREGATE]: 'aggregates_list',
  [SegmentsRuleTypes.EVENT]: 'events_list',
  [SegmentType.SEGMENT]: 'segments',
  [SegmentsRuleTypes.RFM_SEGMENT]: 'rfm_segments',
}

export const resolveFilterValue = (filter, options) => {
  if(!filter.query?.children?.[0]) return null;

  if (filter.query.children[0]?.query?.ruleType === SegmentsRuleTypes.EVENT) {

    return {
      ...filter.query.children[0]?.query,
      filter_builder: filter.query.children[0].query,
      event_id: (options || []).find(
        by('name', filter.query.children[0].query.field)
      ).id,
    };
  }

  return filter.query.children[0].query;
};

export const getCurentGroupData = (segments, id) => segments.find((group) => group.id === id);

export const sortGroupByType = (a, b) => {
  if (a.type === WorkflowNodes.EXCLUDE && b.type !== WorkflowNodes.EXCLUDE) {
    return 1;
  } else if (
    a.type !== WorkflowNodes.EXCLUDE &&
    b.type === WorkflowNodes.EXCLUDE
  ) {
    return -1;
  }
};