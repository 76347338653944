import React from 'react';

import { Container, ActiveContainer, Count } from './styled';

import { useTranslation } from '../../../../../hooks';

const DraggableTemplateItem = React.forwardRef(({
  selected,
  active,
  large,
  isDragging,
  selectionCount,
  templateName,
  ...props
}, ref) => {
  const { p } = useTranslation('templates');

  return (
    <Container
      $large={large}
      $selected={selected && !isDragging}
      $dragging={active}
    >
      <ActiveContainer
        $large={large}
        $active={active}
        ref={ref}
        {...props}
      >
        {selectionCount === 1 ? templateName : (
          <>
            <Count>
              {selectionCount}
            </Count>
            {p('templates_selected')}
          </>
        )}
      </ActiveContainer>
    </Container>
  );
});

export default DraggableTemplateItem;
