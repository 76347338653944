import { Container, Input, Clear } from './styled';

const SearchInput = ({ onClear, ...props }) => {
  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
          d="M16.3124 15.517L12.0643 11.2689C13.0851 10.0434 13.5941 8.47157 13.4855 6.88034C13.3769 5.28912 12.659 3.80103 11.4811 2.72564C10.3033 1.65024 8.75616 1.07035 7.16164 1.10658C5.56713 1.14282 4.04796 1.79239 2.92018 2.92018C1.79239 4.04796 1.14282 5.56713 1.10658 7.16164C1.07035 8.75616 1.65024 10.3033 2.72564 11.4811C3.80103 12.659 5.28912 13.3769 6.88034 13.4855C8.47157 13.5941 10.0434 13.0851 11.2689 12.0643L15.517 16.3124L16.3124 15.517ZM2.24989 7.31239C2.24989 6.31112 2.5468 5.33234 3.10307 4.49981C3.65935 3.66729 4.45 3.01842 5.37505 2.63525C6.3001 2.25208 7.318 2.15183 8.30003 2.34716C9.28206 2.5425 10.1841 3.02466 10.8921 3.73266C11.6001 4.44066 12.0823 5.34272 12.2776 6.32474C12.473 7.30677 12.3727 8.32467 11.9895 9.24972C11.6064 10.1748 10.9575 10.9654 10.125 11.5217C9.29244 12.078 8.31366 12.3749 7.31239 12.3749C5.97019 12.3734 4.68339 11.8396 3.73431 10.8905C2.78522 9.94139 2.25138 8.65459 2.24989 7.31239Z"
          fill="#5F728A"/>
      </svg>
      <Input data-testid="search-input" placeholder="Search" {...props} />
      {props.value && (
        <Clear data-testid="search-input-clear" onClick={onClear}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
              d="M13.5 5.2875L12.7125 4.5L9 8.2125L5.2875 4.5L4.5 5.2875L8.2125 9L4.5 12.7125L5.2875 13.5L9 9.7875L12.7125 13.5L13.5 12.7125L9.7875 9L13.5 5.2875Z"
              fill="#5F728A"/>
          </svg>
        </Clear>
      )}
    </Container>
  )
};

export default SearchInput;
