import { useTransition } from '@react-spring/web';
import { DateRange, Theme, Button } from '@velitech/ui';

import {
  Container,
  Title,
  DateContainer,
  LeftSideContainer,
  RightSideContainer, SlideOut,
} from './styled';

const FiltersPanel = ({ opened, onSubmit, onDiscard, format='yyyy/MM/dd', startDate, endDate, onChange }) => {
  const openTransition = useTransition(!!opened, {
    initial: { height: 0, opacity: 1 },
    from: { height: 0 },
    enter: { height: 110, opacity: 1 },
    leave: { height: 0, opacity: 0 },
  });

  return (
    <Theme mode='light'>
      {openTransition((style, active) => active && (
        <SlideOut style={style}>
          <Container>
            <DateContainer>
              <LeftSideContainer>
                <Title>Create date</Title>
                <DateRange
                  dateFormat={format}
                  from={{
                    selected: startDate,
                    onChange: (date) => onChange('start_date', date),
                  }}
                  to={{
                    selected: endDate,
                    onChange: (date) => onChange('end_date', date),
                  }}
                />
              </LeftSideContainer>
              <RightSideContainer>
                <Button
                  // eslint-disable-next-line react/no-children-prop
                  children='Discard'
                  variant='secondary'
                  size='lg'
                  onClick={onDiscard}
                />
                <Button
                  // eslint-disable-next-line react/no-children-prop
                  children='Search'
                  variant='primary'
                  size='lg'
                  onClick={onSubmit}
                />
              </RightSideContainer>
            </DateContainer>
          </Container>
        </SlideOut>
      ))}
    </Theme>
  );
};

export default FiltersPanel;
