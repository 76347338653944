import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Date = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #303133;
  margin-bottom: 2px;
`;

export const Time = styled.div`
  font-size: 10px;
  line-height: 100%;
  color: #909399;
`;
