import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  optionsAggregatesSelector,
  optionsSelector,
  workflowOptionWithEventsSelector
} from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { v4 as uuid } from 'uuid';

import { useTranslation } from '@hooks';
import useWorkflowOptions, { WorkflowOptionsType } from "@hooks/useWorkflowOptions";

import { SegmentsRuleTypes } from '@constants';

import { generateRandomName, validateSegment, sequence } from '@utils';
import { clickhouseFields2Customer, clickhouseFields2Events } from '@utils/fields';

import {
  viewSteps,
  titleListConfig,
  SegmentType,
  resolveFilterValue,
  getlistTypeOptions,
} from './utils';

const getTabs = (p) => [
  { name: 'dynamic', label: p('dynamic_segment') },
  { name: 'static', label: p('static_segment') },
];

export const useDefaultFilterNodeSettings = ({
  filterNode,
  onChangeFilterNode,
  onClearFilterNodeError,
  errors,
}) => {
  const { p } = useTranslation('workflow_page')
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data)
  const eventsList = clickhouseFields2Events(clickhouseFields.data);
  const aggregatesList = useSelector(optionsAggregatesSelector);
  const segmentOptions = useSelector(optionsSelector);
  const options = useSelector((workflowOptionWithEventsSelector));

  const [step, setStep] = useState();
  const segmentTabs = getTabs(p);

  const [segmentTab, setSegmentTab] = useState(segmentTabs[0].name);
  const [selectedFilterType, setSelectedFilterType] = useState();
  const [displayErrors, setDisplayErrors] = useState(false);

  const { data: optionsList } = useWorkflowOptions(WorkflowOptionsType.RFM_ANALYTICS);

  useEffect(() => { // init already saved data
    if(filterNode.filter_by === SegmentType.SEGMENT) {
      const ruleType = filterNode.ruleType;
      setSelectedFilterType(ruleType);
      setStep(viewSteps.SHOW_SELECTED_SEGMENT);
      return;
    }
    if(filterNode.filter_by === 'rule') {
      const ruleType = filterNode.ruleType;
      setSelectedFilterType(ruleType);
      setStep(viewSteps.SHOW_SELECTED_FILTER);
      return;
    }
    //start when stack was clear
    setTimeout(() => !filterNode.filter_by && onChangeFilterBy('rule'), 0);
  }, []);

  useEffect(() => {
    if (Object.values(errors).length > 0) {
      setDisplayErrors(true);
    }
  }, [errors]);

  const stepConfig = {
    rule: viewSteps.ATTRIBUTE_LIST,
    segment: viewSteps.SEGMENT_LIST,
  };

  const linearList = useMemo(() => {
    return customerFields?.filter((f) => f.type !== 'array');
  }, [clickhouseFields]);

  const arrayList = useMemo(() => {
    return customerFields
      ?.filter((f) => f.type === 'array')
      .map((a) => ({ ...a, value: a.field }));
  }, [clickhouseFields]);

  const segmentOption = segmentOptions.segments.filter((segment) => segment.type === segmentTab);
  
  const configListBySelectedAttr = {
    [SegmentsRuleTypes.LINEAR]: linearList,
    [SegmentsRuleTypes.ARRAY]: arrayList,
    [SegmentsRuleTypes.AGGREGATE]: aggregatesList,
    [SegmentsRuleTypes.EVENT]: eventsList.data,
    [SegmentType.SEGMENT]: segmentOption,
  };
  const getSegmentType = (segmentId) => {
    const currentSegment = segmentOptions.segments.find((segment) => segment.id === segmentId);
    if(!currentSegment) {
      return null;
    }

    return currentSegment.type;
  }
  
  const clearData = () => {
    onChangeFilterNode((currentFilterNode) => ({
      ...currentFilterNode,
      query: {},
      parentId: null,
      type: null,
      segment_id: undefined,
    }));
  };

  const onChangeFilterBy = (filterBy) => {
    onClearFilterNodeError('filter_by');
    onClearFilterNodeError('segment_id');
    onClearFilterNodeError('query');

    const step = stepConfig[filterBy];
    setStep(step);
    setSelectedFilterType(); //clear already selected filter type
    clearData();

    if (filterBy === 'rule') {
      onChangeFilterNode((filterNode) => ({
        ...filterNode,
        filter_by: filterBy,
        query: {},
        aggregates: [],
        funnels: [],
        expressions: [],
      }));
    } else {
      onChangeFilterNode((filterNode) => ({
        ...filterNode,
        segment_id: undefined,
        filter_by: filterBy,
      }));
    }
  };

  const onSelectFilterType = (filterType) => {
    setSelectedFilterType(filterType);
    onClearFilterNodeError('segment_id');
    setStep(viewSteps.SHOW_LIST);
  };

  const onUpdateSegmentFilter = (segmentId) => {
    onClearFilterNodeError('segment_id');
    onChangeFilterNode((currentFilterNode) => ({
      ...currentFilterNode,
      segment_id: segmentId,
    }));
  };

  const handleSelectFilter = (field) => {
    onClearFilterNodeError('query');
    setDisplayErrors(false);

    const filterType = field.special ? SegmentsRuleTypes.ARRAY : selectedFilterType;

    if (filterType === SegmentType.SEGMENT) {
      onUpdateSegmentFilter(field.id);
      setStep(viewSteps.SHOW_SELECTED_SEGMENT);
      return;
    }

    const newValue = { ...filterNode };

    const query = {
      ruleType: filterType,
      negation: false,
      name: 1,
      value: {
        type: 'scalar',
        value: '',
      },
      field:
        filterType === SegmentsRuleTypes.EVENT
          ? field.name
          : field?.field,
      filters: [],
    };

    if (filterType === SegmentsRuleTypes.ARRAY || filterType === SegmentsRuleTypes.EVENT) {
      query.logicalOperator = 'and';
      query.type = 'common';

      if (filterType === SegmentsRuleTypes.ARRAY) {
        query.filters = [];
      }
      if (filterType === SegmentsRuleTypes.EVENT) {
        query.wasPerformed = true;
      }
    }

    if (filterType === SegmentsRuleTypes.AGGREGATE && field) {
      query.aggregateId = field.id;
      query.field = field.name;
      newValue.aggregates = [...(newValue.aggregates || []), field];
    }

    if (filterType === SegmentsRuleTypes.AGGREGATE && !field) {
      const id = uuid();
      const label = `Quick aggregate`;
      const name = generateRandomName();

      query.field = name;
      query.aggregateId = id;

      newValue.aggregates = [
        ...(newValue.aggregates || []), {
          createdId: id,
          label,
          name,
          logicalOperator: 'and',
          type: '',
          filters: [],
          attribute: null,
          aggregate: 'count',
          aggregateMeta: {},
        }];
    }

    onChangeFilterNode((currentFilterNode) => ({
      ...currentFilterNode,
      ...newValue,
      query: validateSegment({
        type: 'query-builder-group',
        funnels: [],
        name: 'New segment',
        aggregates: [],
        ...newValue,
        query: {
          name: 'root',
          root: true,
          children: [{ type: 'query-builder-rule', query }],
          logicalOperator: 'and',
        },
      }).query,
    }));

    setStep(viewSteps.SHOW_SELECTED_FILTER);
  };

  const onDeleteSelectedFilter = () => {
    onClearFilterNodeError('query');
    setDisplayErrors(false);
    onChangeFilterNode((currentFilterNode) => ({
      ...currentFilterNode,
      query: {},
      parentId: null,
      type: null,
      segment_id: null,
    }));

    setSelectedFilterType();
    const step = stepConfig[filterNode.filter_by];
    setStep(step);
  };

  const onCloseList = () => {
    setSelectedFilterType();
    const step = stepConfig[filterNode.filter_by];
    setStep(step);
  }

  const updateFilterValue = (updatedQuery) => {
    onClearFilterNodeError('query');
    setDisplayErrors(false);

    const validatedQuery = validateSegment({
      ...filterNode,
      query: {
        ...filterNode.query,
        children: [{
          type: 'query-builder-rule',
          query: updatedQuery,
        }],
      }
    })
    onChangeFilterNode(() => ({ ...validatedQuery }));
  }

  const onFilterChange = (queryRuleType) => {
    switch (queryRuleType) {
      case SegmentsRuleTypes.EVENT:
        return (updater) => {
          const result = updater(resolveFilterValue(filterNode, eventsList.data));
          //removed fuilder build from result
          // eslint-disable-next-line no-unused-vars
          const { filter_builder, ...rest } = result.filter_builder;
          updateFilterValue(rest);
        };
      default:
        return (updater) => {
          const updatedQuery = updater(resolveFilterValue(filterNode, eventsList.data));
          updateFilterValue(updatedQuery);
        };
    }
  };

  const getWorkflowValue = () => {
    return resolveFilterValue(filterNode, eventsList.data)
  }

  const onSelectRFMSegment = sequence((data) => {
    onClearFilterNodeError('segment_id');
    onChangeFilterNode(currentFilterNode => ({ ...currentFilterNode,
      parentId: data.parentId,
      type: data.type,
      segment_id: data.id,
      query: data.segment_query ,
    }));
    setStep(viewSteps.SHOW_SELECTED_SEGMENT)
  });

  return {
    data: filterNode,
    step,
    viewSteps,
    listTypeOptions: getlistTypeOptions(step),
    selectedFilterType,
    listBySelectedType: configListBySelectedAttr[selectedFilterType],
    listTitle: titleListConfig[selectedFilterType],
    displayErrors,
    options,
    rfmOptions: optionsList,

    onChangeFilterBy,
    onSelectFilterType,
    onSelectAttributeField: handleSelectFilter,
    onDeleteSelectedFilter,
    onCloseList,

    onFilterChange,
    getWorkflowValue,
    onSelectRFMSegment,

    segmentTabs,
    segmentTab, 
    setSegmentTab,
    getSegmentType
  };
};
