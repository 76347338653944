import { UserActionTypes, WorkspacesActionTypes } from '@store/actions/types';

import PromoCodesActionTypes from '../actions/types/promoCodes';

const initialState = {
  promoCodes: {},
  loading: false,
};

const promoCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PromoCodesActionTypes.GET:
      return {
        ...state,
        loading: true,
      }
    case PromoCodesActionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        promoCodes: action.payload,
      }
    case PromoCodesActionTypes.GET_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default promoCodesReducer;
