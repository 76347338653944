import styled from "styled-components";

export const TabsContainer = styled.div`

.tab {
    &.error {
      --vl-tab-color: #F04438;
    }
    cursor: pointer;
    &:hover {
      .tab-item:not(.active):not(.error) {
        color: var(--tabs-text-hover);
      }
    }
    .active  {
      color: #31C447;
    }

    .error {
      color: #F04438;
    }
  }
`

export const TabItem = styled.h4`
  font-family: Manrope-SemiBold;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  transition: all 0.3s linear;
  .tooltip {
    display: inline-flex;
  }
`

export const CountryIcon = styled.span`
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  max-height: 24px;
`