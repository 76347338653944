import { Component } from 'react';

import Cookies from 'js-cookie';

import { MainLoader } from '../../../pages/HomeScene/layouts/MainLoader';
import { LoggerService } from '../../../services';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, isChunkError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, isChunkError: error?.name === "ChunkLoadError" };
  }

  componentDidCatch(error, _errorInfo) {
    const isReloadDelay = Cookies.get('reload_delay');

    LoggerService.log('error', 'BOUNDARY', error);
    if (error?.name === "ChunkLoadError" && !isReloadDelay) {
      let date = new Date();
      date.setTime(date.getTime() + (60 * 1000)); //for a 1 min
      Cookies.set('reload_delay', true , { expires: date });
      window.location.reload();
    }
  }

  render() {
    if (this.state.isChunkError) {
      return (
        <MainLoader sidebarOpened={false} />
      );
    }

    if (this.state.hasError) {
      return (//TODO: need UI Stab
        <h1>
          ...Oops, Something went wrong, please try to reload page
        </h1>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
