import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 20px;
`;

export const NextButton = styled.div`
  width: 36px;
  height: 36px;
  background: #FAFAFA;
  border-radius: 0 9px 9px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-left: 4px;
`;

export const RangeContainer = styled.div`
  display: flex;
  flex: 1;
  background: #FAFAFA;
  user-select: none;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
`;

export const PrevButton = styled.div`
  border-radius: 7px 0 0 7px;
  width: 36px;
  height: 36px;
  background: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-right: 4px;
`;
