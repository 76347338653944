import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    padding: 10px 36px 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    border-radius: 6px;
    border: 1px solid var(--Colors-Neutral-200, #ECF0F6);
    background: var(--Colors-Neutral-100, #F8FAFE);
`;

export const Title = styled.h5`
    margin: 0;
    color: var(--text-primary-body, #1A232E);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`

export const Option = styled.h5`
    margin: 0;
    color: var(--Colors-Additional-Positive-500p, #12B76A);
    /* Body 4 */
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
`

export const OptionText = styled.h5`
    color: var(--text-primary-body, #1A232E);
    /* Body 4 */
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
`