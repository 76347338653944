import styled from 'styled-components';

import { extract } from '@utils';

export const Container = styled.div`
  width: ${extract('$s')}px;
  height: ${extract('$s')}px;
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  position: absolute;
  font-family: Manrope-SemiBold;
  font-size: 28px;
  line-height: 33px;
  color: #303133;
`;

export const Svg = styled.svg`
  position: absolute;
  fill: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const TooltipContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 180px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(${extract('$x')}px, ${extract('$y')}px, 0px);
  z-index: 10;
`;

export const TooltipValue = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #303133;
`;
