import styled from 'styled-components';

export const Container = styled.div`
  
`;
export const UserImg = styled.div`
  background-color: #905BC8;
  border-radius: 7px;
  padding: 8px 13px;
  color: #FFFFFF;
  font-family: Manrope-SemiBold;
  font-size: 12px;
`;

