export const IntegrationPresets = {
  JS_INTEGRATION: 'js-integration',
  API_INTEGRATION: 'retainly_api',
  PSQL_INTEGRATION: 'pgsql-integration',
  MYSQL_INTEGRATION: 'mysql-integration',
  RETAINLY_WEBPUSH: 'retainly_webpushes',
  FACEBOOK_ADS: 'facebook_ads',
};

export const SingleConnectionIntegrationPresets = [
  IntegrationPresets.JS_INTEGRATION,
  IntegrationPresets.API_INTEGRATION,
  IntegrationPresets.RETAINLY_WEBPUSH,
];
