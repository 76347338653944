import React from 'react';

import { ReactComponent as Analytic } from '@res/images/aside/analytics.svg';
import { ReactComponent as Campaign } from '@res/images/aside/campaign.svg';
import { ReactComponent as CC } from '@res/images/aside/contentCard.svg';
import { ReactComponent as NotificationSvg } from '@res/images/aside/notification.svg';
import { ReactComponent as PlayersSvg } from '@res/images/aside/players.svg';
import { ReactComponent as Redirect } from '@res/images/aside/redirect.svg';
import { ReactComponent as Reward } from '@res/images/aside/reward.svg';
import { ReactComponent as SegmentsSvg } from '@res/images/aside/segment.svg';
import { ReactComponent as Star } from '@res/images/aside/star.svg';
import { ReactComponent as teamMessage } from '@res/images/aside/teamMessage.svg';
import { ReactComponent as TemplateSvg } from '@res/images/aside/template.svg';
import { ReactComponent as WebPopup } from '@res/images/aside/webPopup.svg';
import { ReactComponent as Workflow } from '@res/images/aside/workflow.svg';
import { ReactComponent as ControlGroup } from '@res/images/controlGroup.svg';
import { ReactComponent as CopySvg } from '@res/images/copy.svg';
import { ReactComponent as Duplicate } from '@res/images/duplicate.svg';
import { ReactComponent as EmailIconSvg } from '@res/images/email.svg';
import { ReactComponent as MessagefeedSvgIcon } from '@res/images/messageFeed.svg';
import { ReactComponent as SmsIconSvg } from '@res/images/sms.svg';
import { ReactComponent as UserFilled } from '@res/images/user-icon-filled.svg';
import { ReactComponent as ViberIconSvg } from '@res/images/viber.svg';
import { ReactComponent as WarningFilledSvg } from '@res/images/warning-filled.svg';
import { ReactComponent as CreateWorkflow } from '@res/images/workflow.svg';
import { ReactComponent as AbFilterSvg } from '@res/images/workspaces/ab-filter.svg';
import { ReactComponent as AddContactSvg } from '@res/images/workspaces/add-contact.svg';
import { ReactComponent as BestChannelToSendSvg } from '@res/images/workspaces/best-channel.svg';
import { ReactComponent as DotsSvg } from '@res/images/workspaces/dots.svg';
import { ReactComponent as EmailSvg } from '@res/images/workspaces/email.svg';
import { ReactComponent as EventTrigger } from '@res/images/workspaces/event-trigger.svg';
import { ReactComponent as ExcludeFilterSvg } from '@res/images/workspaces/exclude-filter.svg';
import { ReactComponent as ExitIconSvg } from '@res/images/workspaces/exit-icon.svg';
import { ReactComponent as GroupSettings } from '@res/images/workspaces/groupSettings.svg';
import { ReactComponent as MobilePushSvg } from '@res/images/workspaces/mobilepush.svg';
import { ReactComponent as QuickFilterSvg } from '@res/images/workspaces/quick-filter.svg';
import { ReactComponent as ResourceIconSvg } from '@res/images/workspaces/resource-icon.svg';
import { ReactComponent as SmsSvg } from '@res/images/workspaces/sms.svg';
import { ReactComponent as SplitFilterSvg } from '@res/images/workspaces/split-filter.svg';
import { ReactComponent as ViberSvg } from '@res/images/workspaces/viber.svg';
import { ReactComponent as WaitFilterSvg } from '@res/images/workspaces/wait-filter.svg';
import { ReactComponent as WebHookSvg } from '@res/images/workspaces/webhook.svg';
import { ReactComponent as WebPushSvg } from '@res/images/workspaces/webpush.svg';
import { ReactComponent as MobilePushSvgIcon } from '@res/mobilepush.svg';
import { ReactComponent as WebPushSvgIcon } from '@res/webPush.svg';

import { Icon } from '@components';

import { Container } from './styled';

const iconsConfig = {
  'B-testing': AbFilterSvg,
  'Node-Resource': ResourceIconSvg,
  'Node-Event': EventTrigger,
  'Node-Add-contact': AddContactSvg,
  'Node-Send-SMS': SmsSvg,
  'Node-Send-e-mail': EmailSvg,
  'Node-Mobile-push': MobilePushSvg,
  'Web-push': WebPushSvg,
  Viber: ViberSvg,
  'Api-request': WebHookSvg,
  'Node-Wait': WaitFilterSvg,
  'Node-Exclusion-filter': ExcludeFilterSvg,
  'Node-API-filter': QuickFilterSvg,
  'Node-Quick-filter': QuickFilterSvg,
  'Node-Switch-filter': SplitFilterSvg,
  'Nodes-Finish': ExitIconSvg,
  'group_split': SplitFilterSvg,
  'dots': DotsSvg,
  'group-settings': GroupSettings,
  'Analytic': Analytic,
  'Campaign': Campaign,
  'Sidebar-Workflow': Workflow,
  'Sidebar-Resources': SegmentsSvg,
  'Sidebar-Contacts': PlayersSvg,
  'Sidebar-Templates': TemplateSvg,
  'Sidebar-Notifications-history': NotificationSvg,
  'redirect': Redirect,
  'reward': Reward,
  'star': Star,
  'content-card': CC,
  'best-channel-to-send': BestChannelToSendSvg,
  'notification': teamMessage,
  'web-popup': WebPopup,
  'create-workflow': CreateWorkflow,
  'duplicate': Duplicate,
  'email-icon': EmailIconSvg,
  'sms-icon': SmsIconSvg,
  'message_feed': MessagefeedSvgIcon,
  'viber': ViberIconSvg,
  'mobile_push': MobilePushSvgIcon,
  'web_push': WebPushSvgIcon,
  'user_icon': UserFilled,
  'copy': CopySvg,
  'warning-filled': WarningFilledSvg,
  'control-group': ControlGroup,
};

export const IconSvg = ({ name, size = 12, onClick, containerProps, iconStyles, ...props }) => {
  const IconComponent = iconsConfig[name];

  return (
    <Container {...containerProps} onClick={onClick}>
      {IconComponent ? (
        React.createElement(IconComponent, {
          width: `${size}px`,
          height: `${size}px`,
          color: props?.color,
          ...iconStyles,
        })
      ) : (
        <Icon name={name} color={props.color ?? '#fff'} size={size} />
      )}
    </Container>
  );
};
