import { useEffect, useState } from 'react';

import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { testId } from '@utils';

import {
  Modal,
  Input,
} from '@components';

import {
  Container,
} from './styled';

const DuplicateWorkflowModal = ({ opened, onConfirm, onClose, loading, nameDuplicate = '' }) => {
  const [name, setName] = useState('Duplicate of ' + nameDuplicate);
  const { t, p } = useTranslation('workflow_page')

  useEffect(() => {
    setName('Duplicate of ' + nameDuplicate);
  }, [nameDuplicate]);

  const setHandleName = (event) => {
    setName(event.target.value)
  }
  const handleSave = () => {
    onConfirm(name);
  };

  return (
    <Modal
      title={p('duplicate_workflow')}
      opened={opened}
      contentStyles={{ paddingTop: '25px' }}
      onClose={onClose}
      actions={(
        <>
          <Button {...testId('duplicate-workflow-modal-cancel')()} onClick={onClose} variant="secondary" style={{ width: 140 }}>{t('actions.cancel')}</Button>
          <Button {...testId('duplicate-workflow-modal-save')()} onClick={handleSave} style={{ width: 140 }} loading={loading}>{t('actions.save')}</Button>
        </>
      )}
    >
      <Container>
        <Input {...testId('duplicate-workflow-modal-name')()} title={t('labels.name')} value={name} onChange={setHandleName} />
      </Container>
    </Modal>
  );
};

export default DuplicateWorkflowModal;
