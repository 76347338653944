import { useEffect, useMemo, useState } from "react";

import { useModalState, useTranslation } from '@hooks';

import languagesJSON from '@res/languagesList.json';

export const useMultiLang = ({ template, setTemplate }) => {
  const [selectedLangTab, setSelectedLangTab] = useState(null);
  const defaultLangModal = useModalState();
  const { p } = useTranslation('templates')
  const [modalType, setModalType] = useState();

  const languages = useMemo(() => {
    if(!template?.languages || !Array.isArray(template?.languages)) {
      return []
    } 
    return template?.languages?.map((lang) => {
      const countryItemInstance = languagesJSON.find(({ languageCode }) => languageCode === lang.language_code);
      if(!countryItemInstance) {
        return lang;
      }

      return {
        id: countryItemInstance.languageCode,
        value: countryItemInstance.languageName,
        icon: countryItemInstance.emoji,
        is_default: lang.is_default,
      }
    });
  }, [template.languages])

  useEffect(() => {
    if(languages?.length === 0) {
      return;
    }
    
    const hasSelected = languages?.find(({ id }) => id === selectedLangTab);
    if(!hasSelected && languages[0]?.id) {
      setSelectedLangTab(languages[0].id)
    }
  }, [languages]);

  const openModal = ({ 
    type, callbackKey, callback, data
  }) => {
    defaultLangModal.open(data);
    setModalType(type);
    callbackKey && callback && defaultLangModal.callbacks.addCallback(callbackKey, callback)
  }

  const closeModal = () => {
    defaultLangModal.close();
    setModalType('');
  }

  const onOpenSelectLang = (callback) => {
    const hasDefault = languages.some(({ is_default }) => is_default);

    if(!hasDefault) {
      openModal({
        type: 'add_default', 
        callbackKey: 'show_select',
        callback
      });
      return;
    }

    callback && callback();
  }

  const onRemoveLang = (langCode, callback) => {
    const removedLang = template.languages.find((lang) => lang.language_code === langCode);
    if(removedLang?.is_default) {
      openModal({
        type: 'remove_default',
        data: {
          callback: callback,
          removeCode: langCode
        }
      });
    } else {     
      setTemplate((prev) => {
        const updatedLangList = prev.languages.filter((lang) => lang.language_code !== langCode);

        if(removedLang.language_code === selectedLangTab) {
          setSelectedLangTab(updatedLangList[0].language_code)
        }
        return {
          ...prev,
          languages: updatedLangList
        }
      });
    }
  }

  const onSelectDefaultLang = (langCode) => {
   
    setTemplate((prev) => {
      const updatedLangList = prev.languages.map((lang) => {
        if(lang.language_code === langCode) {
          return {
            ...lang,
            is_default: true
          }
        } else {
          return {
            ...lang,
            is_default: false
          }
        }
      });

      return {
        ...prev,
        languages: updatedLangList
      }
    })
  }

  const onSelectLangTab = (langCode) => {
    const current = languages.find(({ id }) => id === langCode);
    setSelectedLangTab(current.id);
  }

  const handleAddDefaultLang = (langCode) => {

    if(!langCode) {
      return;
    }

    const countryItemInstance = languagesJSON.find(({ languageCode }) => languageCode === langCode);

    setTemplate((prev) => {
      const updatedLanguages = prev.languages.map((item, index) => {
        if(index === 0) {
          return {
            ...item,
            is_default: true,
            language_code: countryItemInstance.languageCode
          }
        }
        return item;
      });

      return {
        ...prev,
        languages: updatedLanguages
      }
    })

    setSelectedLangTab(countryItemInstance.code);
    closeModal();

    setTimeout(() => {
      defaultLangModal.callbacks.callCallbackAndRemove('show_select')
    }, 1000)
  }

  const handleCancelDefaultLang = () => {
    closeModal();
  };

  const handleChangeDefaultLang = (langCode) => {

    setTemplate((prev) => {
      const updatedLangList = prev.languages.map((lang) => {
        if(lang.language_code === langCode) {
          return {
            ...lang,
            is_default: true
          }
        } else {
          return {
            ...lang,
            is_default: false
          }
        }
      }).filter((lang) => lang.language_code !== defaultLangModal.data.removeCode)
      return {
        ...prev,
        languages: updatedLangList
      }
    })
    closeModal();
  }

  const getModalTitle = () => {
    const titleConfig = {
      'add_default': p('default_language_title'),
      'remove_default': p('remove_default_language_title')
    }

    return titleConfig[modalType];
  }

  const getModalAcceptButton = () => {
    const acceptConfigButton = {
      'add_default': handleAddDefaultLang,
      'remove_default': handleChangeDefaultLang
    }

    return acceptConfigButton[modalType];
  }

  const getModalOptions = () => {

    const options = languagesJSON.map((languageItem) => {
      return {
        label: `${languageItem.languageName}(${languageItem.languageCode})`,
        value: languageItem.languageCode,
        icon: languageItem.emoji
      }
    });

    const selectedLangOptions = Array.isArray(languages) ? languages?.map((lang) => {
      return {
        ...lang,
        label: lang.value,
        value: lang.id,
      }
    }).filter(({ is_default }) => !is_default): [];
 
    const acceptConfigButton = {
      'add_default': options,
      'remove_default': selectedLangOptions
    }

    return acceptConfigButton[modalType] || options;
  }

  return {
    languages,
    selectedLangTab,
    onSelectLangTab,
    onRemoveLang,
    onOpenSelectLang,
    onSelectDefaultLang,
    langModal: {
      languageOptions: getModalOptions(),
      opened: defaultLangModal.opened,
      title: getModalTitle(),
      onAccept: getModalAcceptButton(),
      onDecline: handleCancelDefaultLang
    }
  }
}