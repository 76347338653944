import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    height: 350px;
    max-width: 100% !important;

    /* min-width: 65px; */
    &::before {
        content: "";
        position: absolute;
        top: 3px;
        left: 4px;
        width: 1px;
        height: 89%;
        background: var(--border-divider, #D3DBE7);
    }

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        right: 5px;
        width: 1px;
        height: 89%;
        background: var(--border-divider, #D3DBE7);
    }

    #chart-column {
        position: relative;
    } 

    .custom-apexcharts-yaxis-label {
        padding: 0 20px;
        color: var(--Colors-Neutral-300, #7C94B1);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Manrope-Medium;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        background-color: #fff;
        position: absolute;

    }
    .custom-apexcharts-xaxis-label {
        color: var(--text-secondary-body, #7C94B1);
        text-align: center;
        font-family: Manrope-Medium;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* transform: translateX(15px); */
        &:first-child {
            transform: translateX(15px);
        }
    }
    .apexcharts-xaxis-label {
        font-family: Manrope-Medium;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        fill: var(--text-secondary-body, #7C94B1);
    }
    .apexcharts-datalabels-group {
        display: none;
    }
`