import styled from 'styled-components';

import { switchStyle } from '@utils';

export const Title = styled.h5`
  color: var(--text-secondary-body, #7C94B1);
  font-family: Manrope-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

export const Description = styled.p`
  color: var(--text-secondary-body, #7C94B1);
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const Container = styled.div`
  background: #ebf0f8;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 4px;
  white-space: pre-line;

  ${switchStyle('$layoutType', {
    mobile_text: `
      padding: 0px;
      height: 75px;
      gap: 0px;
    `,
    mobile_image: `
      height: 169px;
    `,
    common_text: `
      padding: 0px;
      height: 110px;
      gap: 0px;
    `,
    common_image: `
      height: 248px;
    `,
    laptop_text: `
      gap: 0px;
      height: 34px;
    `,
    laptop_image: `
      height: 77px;
    `,
    fullScreen_portrait_image: `
      height: 455px;
    `,
    ['fullScreen_portrait_image-text']: `
      height: 240px;
    `,
    ['fullScreen_landscape_image']: `
      height: 190px;
    `,
    ['fullScreenMobile_portrait_image']: `
      height: 377px;
    `,
    ['fullScreenMobile_portrait_image-text']: `
      height: 187px;
    `,
    ['fullScreenMobile_landscape_image']: `
      height: 163px;
    `,
    ['fullScreenMobile_landscape_image-text']: `
      height: 106px;
    `,
    ['fullScreenLaptop_portrait_image']: `
      height: 200px;
    `,
    ['fullScreenLaptop_portrait_image-text']: `
      height: 100px;
    `,
    ['fullScreenLaptop_landscape_image']: `
      height: 91px;
    `,
    ['fullScreenLaptop_landscape_image-text']: `
      height:  60px;
    `,
  })}

  ${switchStyle('$type', {
    fullScreenLaptop_portrait: `
        ${Title}{
          text-align: center;
         
          font-family: Manrope-SemiBold;
          font-size: 8.761px;
          font-style: normal;
          font-weight: 600;
          line-height: 11.499px;
        }
        ${Description} {
          text-align: center;
         
          font-family: Manrope-Medium;
          font-size: 6.571px;
          font-style: normal;
          font-weight: 500;
          line-height: 9.856px; /* 150% */
        }
    `,
    fullScreenLaptop_landscape: `
        ${Title}{
          text-align: center;
          font-family: Manrope-SemiBold;
          font-size: 8.761px;
          font-style: normal;
          font-weight: 600;
          line-height: 11.499px; /* 131.25% */
        }
        ${Description} {
          text-align: center;
         
          font-family: Manrope-Medium;
          font-size: 6.571px;
          font-style: normal;
          font-weight: 500;
          line-height: 9.856px; /* 150% */
        }
    `,
    fullScreen_portrait: `
        ${Title}{
          text-align: center;
          font-family: Manrope-SemiBold;
          font-size: 21.051px;
          font-style: normal;
          font-weight: 600;
          line-height: 27.629px; /* 131.25% */
        }
        ${Description} {
          text-align: center;
         
          font-family: Manrope-Medium;
          font-size: 15.788px;
          font-style: normal;
          font-weight: 500;
          line-height: 23.682px; /* 150% */
        }
    `,
    fullScreen_landscape: `
        ${Title}{
          text-align: center;
          font-family: Manrope-SemiBold;
          font-size: 21.051px;
          font-style: normal;
          font-weight: 600;
          line-height: 27.629px;
        }
        ${Description} {
          text-align: center;
         
          font-family: Manrope-Medium;
          font-size: 15.788px;
          font-style: normal;
          font-weight: 500;
          line-height: 23.682px; /* 150% */
        }
    `,
    mobile: `
        ${Description} {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
    `,
    laptop: `
        ${Title} {
            font-size: 7px;
            line-height: 8px;
        }
        ${Description} {
            font-size: 5px;
            line-height: 6px;
        }
    `,
    common: `
        ${Title} {
          font-size: 20px;
          line-height: 110%;
        }
        ${Description} {
          font-size: 14px;
          line-height: 110%;
        }
    `,
  })}
`;
