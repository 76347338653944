import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px 10px 20px;
  background: #fafbff;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Avatar = styled.img`
  display: flex;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-right: 12px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.p`
  margin: 0 0 4px 0;
  color: #000000;
  font-size: 13px;
`;

export const UserId = styled.p`
  margin: 0;
  color: #909399;
  font-size: 10px;
`;

export const FilterForm = styled.form`
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 20px;
  overflow-y: scroll;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #dcdfe6;
`;

export const HeaderItem = styled.p`
  width: ${({ widthPercent }) => `${widthPercent}%`};
  padding: ${({ withPadding }) => (withPadding ? '0 4px' : '0')};
  margin-bottom: 0;
  color: #303133;
  font-size: 13px;
  font-weight: 600;
`;

export const TableCell = styled.p`
  width: ${({ widthPercent }) => `${widthPercent}%`};
  padding: ${({ withPadding }) => (withPadding ? '0 4px' : '0')};
  margin: 0;
  color: #303133;
  font-size: 13px;
  text-align: ${({ alignEnd }) => (alignEnd ? 'right' : 'left')};
  max-width: 200px;
  max-height: 160px;
  text-overflow: ellipsis;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #dcdfe6;
  ${styledProp('$titles', css`
    & > ${TableCell} {
      font-size: 13px;
      line-height: 100%;
      color: #909399;
    }

    padding-top: 16px;
    border-bottom: none;
  `)}
`;

export const CopyButton = styled.button`
  padding: 0;
  font-size: 13px;
  color: #31C447;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Tab = styled.div`
  font-family: Manrope-SemiBold;
  cursor: pointer;
  transition: 330ms ease all;

  ${styledProp('selected', css`
    color: #31C447;
  `)}
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  border-bottom: 1px solid #C1CEFC;
  padding: 14px;
`;

export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #31C447;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ tab }) => {
    const el = document.querySelector(`#wpp-tab-${tab}`);

    return css`
      width: ${el?.getBoundingClientRect()?.width}px;
      left: ${el?.offsetLeft}px;
    `;
  }}
`;
