import PropTypes from 'prop-types';

import { Modal, Icon } from '@components';

import { Container, Box, IconWrapper, Content, Title, ErrorsList, Error } from './styled';

const ErrorListModal = ({ opened, onClose, errors }) => (
  <Modal appearance="default" title="Validate" opened={opened} onClose={onClose}>
    <Container>
      <Box>
        <IconWrapper>
          <Icon color="#FFF" size={10} name="Attention-for-mistake" />
        </IconWrapper>
        <Content>
          <Title>Errors found</Title>

          <ErrorsList>
            {errors.map((error) => (
              <Error key={error}>{error}</Error>
            ))}
          </ErrorsList>
        </Content>
      </Box>
    </Container>
  </Modal>
);

ErrorListModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default ErrorListModal;
