import { useSelector } from 'react-redux';

import { userSelector } from '@store/selectors';

import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { Modal } from '@components';

import { Container, Title } from './styled';

const CustomSubscriptionExpiredModal = ({ opened, onClose }) => {
  const { t, p } = useTranslation('dashboard_page');
  const user = useSelector(userSelector);

  return (
    <Modal title={p('subscription_expired')} {...{ opened, onClose }}>
      <Container>
        <Title>{p(user?.isOwner ? 'custom_subscription_expired_long_owner' : 'custom_subscription_expired_long')}</Title>
        <Button style={{ width: '160px' }} onClick={onClose}>{t('actions.ok_thanks')}</Button>
      </Container>
    </Modal>
  );
};

export default CustomSubscriptionExpiredModal;
