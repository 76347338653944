import styled from 'styled-components';

export const Container = styled.div``;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
`;

export const FilterContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: Manrope-Medium;
`

export const FilterRow = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`