import styled from 'styled-components';

import * as Colors from "@res/theme/colors";

export const MenuItem = styled.p`
  font-size: 13px;
  color: ${({ danger }) => (danger ? Colors.Text.DANGER : Colors.Text.PRIMARY)};
  margin: 0;
    ${({ disabled }) => disabled ? 'opacity: 0.4; pointer-events: none;' : ''}
`;
