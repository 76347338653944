import {
  COMMON_OPERATORS,
  OPERATOR_BETWEEN,
  OPERATOR_NOT_BETWEEN,
  OPERATOR_NOT_ONE_OF,
  OPERATOR_ONE_OF,
  WITHOUT_VALUE_OPERATORS,
} from '@constants';

import { isGroup, compose, identity, sequence } from '@utils';

import { SegmentsRuleTypes } from '../../constants/segments-refactored';

const validateField = (query) => {
  if (!query.query.field) {
    return {
      ...query,
      query: {
        ...query.query,
        errors: {
          ...query.query.errors,
          label: 'label_required',
        },
      },
    };
  }

  return {
    ...query,
    query: {
      ...query.query,
      errors: {
        ...query.query.errors,
        field: undefined,
      },
    },
  };
};

const validateOperator = (query) => {
  if (!query.query.operator) {
    return {
      ...query,
      query: {
        ...query.query,
        errors: {
          ...query.query.errors,
          operator: 'operator_required',
        },
      },
    };
  }

  return {
    ...query,
    query: {
      ...query.query,
      errors: {
        ...query.query.errors,
        operator: undefined,
      },
    },
  };
};

const validateValue = (query) => {
  if (WITHOUT_VALUE_OPERATORS.includes(query.query.operator)) {
    return {
      ...query,
      query: {
        ...query.query,
        errors: {
          ...query.query.errors,
          value: undefined,
        },
      },
    };
  }

  if ([OPERATOR_BETWEEN, OPERATOR_NOT_BETWEEN].includes(query.query.operator)) {
    const valueError = ['', ''];
    if (query.query.value?.value?.[0] === '' || query.query.value?.value?.[0] === undefined) {
      valueError[0] = 'from_value_required';
    }
    if (query.query.value?.value?.[1] === '' || query.query.value?.value?.[1] === undefined) {
      valueError[1] = 'to_value_required';
    }

    if (valueError.some(identity)) {
      return {
        ...query,
        query: {
          ...query.query,
          errors: {
            ...query.query.errors,
            value: valueError,
          },
        },
      };
    }
  }

  if ([OPERATOR_ONE_OF, OPERATOR_NOT_ONE_OF].includes(query.query.operator)) {
    if (!query.query.value?.value?.length) {
      return {
        ...query,
        query: {
          ...query.query,
          errors: {
            ...query.query.errors,
            value: 'value_required',
          },
        },
      };
    }
  }

  if (
    (query.query.value?.value === '' || query.query.value?.value === undefined) &&
    !COMMON_OPERATORS.includes(query.query.operator)
  ) {
    return {
      ...query,
      query: {
        ...query.query,
        errors: {
          ...query.query.errors,
          value: 'value_required',
        },
      },
    };
  }

  return {
    ...query,
    query: {
      ...query.query,
      errors: {
        ...query.query.errors,
        value: undefined,
      },
    },
  };
};

const validateEventOrArrayLogicalOperator = (query) => {
  return query;
};

const validateSubFilter = (filter) => {
  const field = !filter.field && 'field_required';

  if (filter.type === 'array') {
    return {
      ...filter,
      filters: filter?.filters ? filter.filters.map(validateSubFilter) : filter?.filters,
      errors: {
        field,
      },
    };
  }

  const operator = !filter.operator && 'operator_required';
  const valueError = ['', ''];

  if ([OPERATOR_BETWEEN, OPERATOR_NOT_BETWEEN].includes(filter.operator)) {
    if (filter.value?.value?.[0] === '' || filter.value?.value?.[0] === undefined) {
      valueError[0] = 'from_value_required';
    }
    if (filter.value?.value?.[1] === '' || filter.value?.value?.[1] === undefined) {
      valueError[1] = 'to_value_required';
    }
  }

  if ([OPERATOR_ONE_OF, OPERATOR_NOT_ONE_OF].includes(filter.operator)) {
    if (!filter.value?.value?.length) {
      return {
        ...filter,
        errors: {
          field,
          operator,
          value: 'value_required',
        },
      };
    }
  }

  const value = [OPERATOR_BETWEEN, OPERATOR_NOT_BETWEEN].includes(filter.operator)
    ? valueError.some(identity) && valueError
    : (filter.value?.value === '' || filter.value?.value === undefined) &&
      !COMMON_OPERATORS.includes(filter.operator) &&
      'value_required';

  return {
    ...filter,
    errors: {
      field,
      operator,
      value: WITHOUT_VALUE_OPERATORS.includes(filter.operator) ? undefined : value,
    },
  };
};

const validateSubFilters = (query) => {
  if (query.query.logicalOperator !== 'and' && query.query.logicalOperator !== 'or') {
    return query;
  }

  if (!query.query?.filters?.length && query.ruleType === 'array') {
    return {
      ...query,
      query: {
        ...query.query,
        errors: {
          ...query.query.errors,
          filters: 'at_least_one_filter_required',
        },
      },
    };
  }

  return {
    ...query,
    query: {
      ...query.query,
      filters: (query.query.filters || []).map(validateSubFilter),
      errors: {
        ...query.query.errors,
        filters: undefined,
      },
    },
  };
};

const validateFunnelStep = (step) => {
  if (!step.name) {
    return {
      ...step,
      errors: {
        ...step.errors,
        name: 'step_name_required',
      },
    };
  }

  return {
    ...step,
    filters: step.filters?.map?.(validateSubFilter),
  };
};

const validateFunnel = (funnel) => {
  return {
    ...funnel,
    steps: funnel.steps.map(validateFunnelStep),
  };
};

const validateAggregate = (aggregate) => {
  if (!aggregate.field) {
    return {
      ...aggregate,
      errors: {
        ...aggregate.errors,
        field: 'field_is_required',
      },
    };
  }

  if (aggregate.aggregate !== 'count' && !aggregate.attribute) {
    return {
      ...aggregate,
      errors: {
        ...aggregate.errors,
        attribute: 'field_is_required',
      },
    };
  }

  return {
    ...aggregate,
    errors: {},
    filters: aggregate.filters?.map?.(validateSubFilter),
  };
};

const FilterValidators = {
  [SegmentsRuleTypes.LINEAR]: [validateField, validateOperator, validateValue],
  [SegmentsRuleTypes.EVENT]: [
    validateField,
    validateSubFilters,
    validateEventOrArrayLogicalOperator,
  ],
  [SegmentsRuleTypes.ARRAY]: [validateField, validateOperator, validateValue],
  [SegmentsRuleTypes.LINEAR_ARRAY]: [
    validateField,
    validateSubFilters,
    validateEventOrArrayLogicalOperator,
  ],
  [SegmentsRuleTypes.FUNNEL]: [],
  [SegmentsRuleTypes.AGGREGATE]: [
    validateField,
    validateOperator,
    validateValue,
    validateAggregate,
  ],
  [SegmentsRuleTypes.RFM_SEGMENT]: [],
  [SegmentsRuleTypes.UNSUBSCRIBE_GROUP]: [validateSubFilters],
  goalFilter: [validateSubFilters],
};

export const validateFilter = (query) => compose(...FilterValidators[query.query.ruleType])(query);

export const validateGoalFilter = (query) =>
  compose(...FilterValidators['goalFilter'])({ query: query.filters });

export const validateQuery = (query) => {
  return {
    ...query,
    query: {
      ...query.query,
      children: query.query.children?.map((q) => {
        if (isGroup(q)) {
          return validateQuery(q);
        }

        return validateFilter(q);
      }),
    },
  };
};

export const validateAggregates = (query) => {
  return {
    ...query,
    aggregates: (query.aggregates || []).map(validateAggregate),
  };
};

export const validateFunnels = (query) => {
  return {
    ...query,
    funnels: (query.funnels || []).map(validateFunnel),
  };
};

export const validateSegment = compose(validateQuery, validateFunnels, validateAggregates);

const hasErrors = (entity) => {
  return Object.values(entity?.errors || {}).some(identity);
};

export const isQueryValid = (query) => {
  return query.query.children.every((child) => {
    if (isGroup(child)) {
      return isQueryValid(child);
    }

    return (
      !hasErrors(child.query) &&
      (child?.query?.filters || []).every(
        (filter) => !hasErrors(filter) && !filter?.filters?.some((ff) => hasErrors(ff)),
      )
    );
  });
};

export const isAggregatesValid = ({ aggregates }) => {
  return aggregates.filter(({ default: d }) => !d).every(
    (aggregate) =>
      !hasErrors(aggregate) &&
      (
        aggregate.filters?.flatMap((filter) =>
          filter.type === 'array' ? (filter.filters || []).map((f) => f) : filter,
        ) || []
      ).every((filter) => !hasErrors(filter)),
  );
};

export const isFunnelsValid = ({ funnels }) => {
  return funnels.every(
    (funnel) =>
      !hasErrors(funnel) &&
      funnel.steps.every(
        (step) => !hasErrors(step) && step.filters.every((filter) => !hasErrors(filter)),
      ),
  );
};

export const isSegmentValid = compose(
  (res) => res.every(identity),
  sequence(isQueryValid, isFunnelsValid, isAggregatesValid),
);
