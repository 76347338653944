import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from '@store/lib';

import { compose } from '@utils';

import { UserActionTypes } from '../types';

export const login = compose(
  withCallbacks,
  asCreator(({ email, password, type = 'edge' }) => ({
    type: UserActionTypes.LOGIN,
    payload: { email, password, type },
  }))
);

export const register = compose(
  withCallbacks,
  asCreator(({ name, surname, email, password, token, step }) => ({
    type: UserActionTypes.REGISTER,
    payload: { name, surname, email, password, token, step }
  }))
);

export const deleteAppUser = compose(
  withCallbacks,
  asCreator(({ id, app_id }) => ({
    type: UserActionTypes.DELETE_APP_USER,
    payload: { id, app_id },
  }))
)

export const updateAppOnboarding = compose(
  withCallbacks,
  asCreator(({ id, params }) => ({
    type: UserActionTypes.UPDATE_APP_ONBOARDING,
    payload: { id, params },
  }))
);

export const reInviteUser = compose(
  withCallbacks,
  asCreator(({ email }) => ({
    type: UserActionTypes.RE_INVITE_USER,
    payload: { email },
  }))
)

export const resendRegistrationEmail = compose(
  withCallbacks,
  asCreator(({ email }) => ({
    type: UserActionTypes.RESEND_REGISTRATION_EMAIL,
    payload: { email },
  }))
)

export const startTrial = compose(
  withCallbacks,
  asCreator(({ number, app_name, timezone, token }) => ({
    type: UserActionTypes.START_TRIAL,
    payload: { number, app_name, timezone, token },
  }))
);

export const submitQuestionnaire = compose(
  withCallbacks,
  asCreator(({ questionnaire, token }) => ({
    type: UserActionTypes.SUBMIT_QUESTIONNAIRE,
    payload: { questionnaire, token },
  }))
);

export const resetPassword = compose(
  withCallbacks,
  asCreator(({ email, type }) => ({
    type: UserActionTypes.RESET_PASSWORD,
    payload: { email, type },
  }))
);

export const updateInviteUser = compose(
  withCallbacks,
  asCreator(({ hash, password, email, first_name, last_name }) => ({
    type: UserActionTypes.UPDATE_INVITE_USER,
    payload: { hash, password, email, first_name, last_name },
  }))
);

export const changePasswordAfterReset = compose(
  withCallbacks,
  asCreator(({ hash, password, email, type }) => ({
    type: UserActionTypes.CHANGE_PASSWORD_AFTER_RESET,
    payload: { hash, password, email, type },
  }))
);

export const getUser = compose(
  withCallbacks,
  asCreator(() => ({
    type: UserActionTypes.GET_USER,
  })),
);

export const getUsers = compose(
  withCallbacks,
  withPagination,
  withFilters(['input_val', 'roles', 'status']),
  withOrdering,
  asCreator(() => ({
    type: UserActionTypes.GET_USERS,
  }))
);

export const getUsersList = compose(
  asCreator(() => ({
    type: UserActionTypes.GET_USERS_LIST,
  }))
)

export const getAllUsers = compose(
  withCallbacks,
  asCreator(() => ({
    type: UserActionTypes.GET_ALL_USERS,
  }))
);

export const getSingleUser = asCreator(({ id }) => ({
  type: UserActionTypes.GET_SINGLE_USER,
  payload: { id },
}));

export const createUser = compose(
  withCallbacks,
  asCreator(({ users, roles }) => ({
    type: UserActionTypes.CREATE_USER,
    payload: { users, roles },
  }))
);

export const editUser = compose(
  withCallbacks,
  asCreator(({ id, email, firstName, lastName, roles }) => ({
    type: UserActionTypes.EDIT_USER,
    payload: { id, email, firstName, lastName, roles },
  }))
);

export const changeUserStatus = compose(
  withCallbacks,
  asCreator(({ id, status }) => ({
    type: UserActionTypes.CHANGE_USER_STATUS,
    payload: { id, status },
  }))
);

export const changeUserPassword = compose(
  withCallbacks,
  asCreator(({ currentPassword, password }) => ({
    type: UserActionTypes.CHANGE_PASSWORD_USER,
    payload: { currentPassword, password },
  }))
);

export const changeUserEmail = compose(
  withCallbacks,
  asCreator(({ email, password }) => ({
    type: UserActionTypes.CHANGE_EMAIL_USER,
    payload: { email, password },
  }))
);

export const twoFAConfirmPassword = compose(
  withCallbacks,
  asCreator(({ password }) => ({
    type: UserActionTypes.TWO_FA_CONFIRM_PASSWORD,
    payload: { password },
  }))
);

export const twoFAEnable = compose(
  withCallbacks,
  asCreator(() => ({ type: UserActionTypes.TWO_FA_ENABLE }))
);

export const twoFAConfirmationCode = compose(
  withCallbacks,
  asCreator(({ code }) => ({
    type: UserActionTypes.TWO_FA_CONFIRMATION_CODE,
    payload: { code },
  }))
);

export const twoFAQRCode = asCreator(() => ({ type: UserActionTypes.TWO_FA_QR_CODE }));

export const twoFARecoveryCodes = asCreator(() => ({ type: UserActionTypes.TWO_FA_RECOVERY_CODES }));

export const twoFAVerifyCode = compose(
  withCallbacks,
  asCreator(({ code, recoveryCode }) => ({ type: UserActionTypes.TWO_FA_VERIFY_CODE, payload: { code, recoveryCode } }))
);

export const twoFARemove = compose(
  withCallbacks,
  asCreator(() => ({ type: UserActionTypes.TWO_FA_REMOVE }))
);

export const logout = () => ({
  type: UserActionTypes.LOGOUT,
});

export const setCachedUsersToInvite = compose(
  asCreator(({ cachedUsers }) => ({
    type: UserActionTypes.SET_CACHED_USERS_TO_INVITE,
    payload: { cachedUsers },
  }))
);

export const submitTrialQuestionnaire = compose(
  withCallbacks,
  asCreator(({ answers, type }) => ({
    type: UserActionTypes.SUBMIT_TRIAL_QUESTIONNAIRE,
    payload: { answers, type },
  }))
);

export const verifyEmail = compose(
  withCallbacks,
  asCreator(({ hash, email }) => ({
    type: UserActionTypes.VERIFY_EMAIL,
    payload: { hash, email },
  }))
);

export const changeLanguage = compose(
  withCallbacks,
  asCreator(({ language }) => ({
    type: UserActionTypes.CHANGE_LANGUAGE,
    payload: { language },
  }))
);

export const updateDashboardAnalyticsOptions = compose(
  withCallbacks,
  asCreator(({ options }) => ({
    type: UserActionTypes.UPDATE_DASHBOARD_ANALYTICS_OPTIONS,
    payload: { options }
  }))
);

export const getDashboardAnalyticsOptions = compose(
  withCallbacks,
  asCreator(({ app_id }) => ({
    type: UserActionTypes.GET_DASHBOARD_ANALYTICS_OPTIONS,
    payload: { app_id },
  }))
);
