import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--Colors-Additional-White, #FFF);
  border: 1px solid var(--cards-border, #D3DBE7);
  border-radius: 8px;
  overflow: hidden;
  
  ${styledProp('vertical', css`
    flex-direction: column;
  `, css`
    flex-direction: row;
    align-items: center;
  `)}
`;

export const ZoomButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Colors-Additional-White, #FFF);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
