import mobilePreview from '@res/images/Mobile_Message_Preview.png';

import { MobileContainer, Image } from './styled';

import { Placeholder } from '../Placeholder';
import { PreviewButton } from '../PreviewButton';
import { PreviewContent } from '../PreviewContent';
import { buttonSizeMap } from '../PreviewContent/PreviewContent';
import { ButtonContainer } from '../PreviewContent/styled';

export const ModalPhone = ({
  title,
  message,
  shouldShowTextContent,
  layout,
  buttons,
  imageOnly,
  imageUrl
}) => {

  const hasButtonsName = buttons?.some((button) => button.name);

  const renderButtons = buttons?.map((button) => {
    if (!button.name) {
      return null;
    }

    return (
      <PreviewButton key={button.name} size={buttonSizeMap['mobile']} type={button.type}>
        {button.name}
      </PreviewButton>
    );
  });
  
  const descriptionConfig = {
    'text': `29:10 aspect ratio \n Hi-Res Max 1450x500px \n PNG, JPEG, GIF`,
    'image': "Any aspect ratio Message resizes to fit images of most sizes Hi-Res Max 1200x1200px Min. 600x600px PNG,  JPEG, GIF"
  };

  return (
    <MobileContainer>
      <PreviewContent
        shouldShowTextContent={shouldShowTextContent}
        title={title}
        description={message}
        type={'mobile'}
        imageOnly={imageOnly}
        imageUrl={imageUrl}
        layout={layout}
        placeholder={
          <Placeholder
            layoutType={`mobile_${layout}`}
            title={"Image recomendations"}
            description={descriptionConfig[layout]}
          />
        }
        actionButton={
          (hasButtonsName) && (
            <ButtonContainer $size={buttonSizeMap['mobile']}>
              {renderButtons}
            </ButtonContainer>
          )
        }
      />
      <Image src={mobilePreview} alt='mobile' />
    </MobileContainer>
  );
};
