import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

import { switchStyle, styledProp } from '@utils';

export const BuilderWrapper = styled.div`
  padding: 0px 20px 10px;
  height: 100%;

  ${styledProp('preview', css`
    padding: 0px;
  `)}
  
`

export const Container = styled.div`
  margin-top: 1px;
  display: flex;
  position: relative;

  ${switchStyle('workerStatus', {
    0: css`
      height: calc(100vh - 185px);
    `,
    1: css`
    height: calc(100vh - 200px);
      `,
    2: css`
    height: calc(100vh - 200px);
      `,
  })}

  ${switchStyle('switchStyle', {
    hidden: css`
      height: calc(100vh - 234px);
    `,
    preview: css`
         margin-top: 0;
    `
  })}
  flex: 1;
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Editor = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  background: ${props => (props.background > 0 ? `var(--cards-bg, #F8FAFE);` : `#FFFFFF`)};
  overflow: visible;
  height: 100%;
  position: relative;

  ${styledProp('preview', css`
    border-radius: 10px;
    border:  1px solid  #fff;
    background: ${props => (props.background > 0 ? `var(--cards-bg, #F8FAFE);` : `#FFFFFF`)};
    overflow: hidden;
  `)}
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  pointer-events: none;
`;

export const NodeAnalyticsContainer = styled(animated.div)`
  position: absolute;
  background: #fff;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 675px;
  overflow: hidden;
  border-left: 1px solid var(--cards-border, #D3DBE7);
`;

export const AnalyticsBackground = styled(animated.div)`
  height: 20px;
`;

export const WithAnalyticsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  border-radius: 7px;
  border: 1px solid var(--cards-border, #D3DBE7);
  overflow: hidden;
  position: relative;
`;
