import React, { useEffect } from 'react';

import { Icon } from '@velitech/ui';
import { Tooltip } from 'antd';

import { useModalState, useTabElement, useTranslation, useNavigate } from '@hooks';

import { by } from '@utils';

import {
  Container,
  HeaderContainer,
  ContentContainer,
  BackButton,
  Left,
  Right,
  TitleContainer,
  TabsContainer,
  TabIndicator,
  Tab,
  InfoContainer,
  Shadow,
} from './styled';

const Header = ({
  testId,
  title,
  backButton,
  right,
  left,
  opened,
  disableShadow,
  content,
  onBackClick,
  tabs,
  tabOnboardParams = {},
  tab,
  info,
  onTabChange,
  tabTestIdPrefix = 'store-segment',
  withoutBottomRounded = false,
  lightTabs = false,
  wrapperStyles = null,
  containerStyles = null,
  notifications,
}) => {
  const { t } = useTranslation();
  const currentTabIndex = tabs?.findIndex(by('name', tab));
  const updater = useModalState();

  const navigate = useNavigate();
  const { tabEl, refChanger } = useTabElement(tab, tabs || [], !!tabs?.length);

  useEffect(() => {
    setTimeout(updater.toggle, 0);
  }, []);

  const handleTabClick = (name) => () => {
    onTabChange(name);
  };

  const handleGoBack = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  };

  const resolveTitle = (title) => {
    return typeof title === 'string' ? t(title) : title;
  };

  return (
    <Container notifications={notifications} withoutBottomRounded={withoutBottomRounded} $disableShadow={disableShadow} data-testid={testId}>
      <Shadow style={wrapperStyles} $opened={opened} $disableShadow={disableShadow}>
        <HeaderContainer notifications={notifications} bottomBordered={!(!!tabs)} backButton={backButton} $disableShadow={disableShadow} style={containerStyles} withoutBottomRounded={withoutBottomRounded} tabs={tabs} $opened={opened}>
          <Left>
            {backButton && (
              <BackButton data-testid={'back-button'} onClick={handleGoBack}>
                <Icon name={Icon.NAMES.ChevronLeft} size={Icon.SIZES.S24} />
              </BackButton>
            )}
            {typeof title === 'string' ?
              <TitleContainer>
                {title}
              </TitleContainer> : title}
            {left}
          </Left>
          <Right>{right}</Right>
        </HeaderContainer>
        {info && (
          <InfoContainer>
            {info}
          </InfoContainer>
        )}
        {tabs && (
          <TabsContainer notifications={notifications} withoutBottomRounded={withoutBottomRounded} lightTabs={lightTabs} backButton={backButton}>
            {tabs.map(({ title, name, disabled, tooltip }, index) => tabOnboardParams?.key ? (
              <Tab
                disabled={disabled}
                data-testid={`${tabTestIdPrefix}-${name}-tab`}
                key={name}
                active={currentTabIndex === index}
                onClick={handleTabClick(name)}
                id={`header-tab-${index}`}
                ref={refChanger(name)}
              >
                <div style={{ padding: '8px 0' }}>
                  {resolveTitle(title)}
                </div>
              </Tab>
            ) : (tooltip && disabled) ? (
              <Tooltip title={tooltip} >
                <Tab
                  disabled={disabled}
                  data-testid={'store-segment-'+name+'-tab'}
                  active={currentTabIndex === index}
                  key={name}
                  id={`header-tab-${index}`}
                  ref={refChanger(name)}
                >
                  <div style={{ padding: '8px 0' }}>
                    {resolveTitle(title)}
                  </div>
                </Tab>
              </Tooltip>
            ) : (
              <Tab
                data-testid={'store-segment-'+name+'-tab'}
                active={currentTabIndex === index}
                key={name}
                onClick={handleTabClick(name)}
                id={`header-tab-${index}`}
                ref={refChanger(name)}
              >
                <div style={{ padding: '8px 0' }}>
                  {resolveTitle(title)}
                </div>
              </Tab>
            ))}
            <TabIndicator key={updater.opened} $tab={tabEl} />
          </TabsContainer>
        )}
      </Shadow>
      <ContentContainer withoutBottomRounded={withoutBottomRounded && opened} $opened={opened}>{content}</ContentContainer>
    </Container>
  );
};

export default Header;
