import styled, { css } from 'styled-components';

import { SegmentsRuleTypes } from '@constants';

import { switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Image = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  padding: 18.75px;
  justify-content: center;
  align-items: center;
  
  ${switchStyle('$type', {
    [SegmentsRuleTypes.LINEAR]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #109DFB 5.92%, #0C74EF 94.84%);
      box-shadow: 0px 6.25px 14.063px 0px rgba(16, 157, 251, 0.40);
    `,
    [SegmentsRuleTypes.ARRAY]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #945DBD 5.92%, #7C44A6 94.84%);
      box-shadow: 0px 6.25px 14.062px 0px rgba(124, 82, 156, 0.40);
    `,
    [SegmentsRuleTypes.AGGREGATE]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #F4B53C 5.92%, #E3A328 94.84%);
      box-shadow: 0px 6.25px 14.063px 0px rgba(244, 165, 60, 0.40);
    `,
    [SegmentsRuleTypes.EVENT]: css`
      border-radius: 22px;
      background: linear-gradient(224deg, #2DE0E0 5.92%, #0BBABA 94.84%);
      box-shadow: 0px 6.25px 14.063px 0px rgba(56, 206, 206, 0.40);
    `,
  })}
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 14px;
  line-height: 20px;
`;
