import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const Text = styled.p`
  color: #303133;
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  max-width: 446px;
  margin: 16px auto;
`;
