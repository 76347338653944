/* eslint-disable indent */
import styled from 'styled-components';

import { switchStyle } from '@utils';

export const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

export const Image = styled.img`
  width: 100%;

  object-fit: cover;
  ${switchStyle('$layoutType', {
    mobile_image: `
      height: 169px;
    `,
    mobile_text: `
      height: 75px;
    `,
    common_image: `
      height: 248px;
    `,
    common_text: `
       height: 110px;
    `,
    laptop_image: `
      height: 77px;
    `,
    laptop_text: `
       height: 34px;
    `,
  })}
`;

export const IconClose = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  ${switchStyle('$size', {
    small: `
        top: 10px;
    `,
    medium: `
        top: 10px;
       
    `,
  })}
`;

export const ModalContainer = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  min-height: 218px;
  top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
`;

export const CommonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

export const FullScreenMobile = styled.div`
  position: absolute;
  top: 40px;
  left: 17px;
  right: 17px;
  bottom: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Image} {
    border-radius: 0px;
  }
`;

export const LeptopContainer = styled.div`
  position: absolute;
  width: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $hasContent }) => $hasContent ? '10px' : '0px'};
`;

export const Title = styled.h4`
  color: var(--text-primary-body, #1a232e);
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 5px;
  white-space: pre-line;
  word-wrap: break-word;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const Description = styled.p`
  color: var(--text-primary-body, #1a232e);
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  white-space: pre-line;
  word-wrap: break-word;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.$lineClamp || 7};
  overflow: hidden;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${switchStyle('$buttonLength', {
    1: `
        button: {
          width: 100%
        }
    `,
    2: `
    button: {
          width: 50%
        }
    `,
  })}
  ${switchStyle('$size', {
    small: `
        gap: 4px;
    `,
    medium: `
        gap: 10px;
    `,
    [`fs-medium`]: `
      gap: 10px;
    `,
    large: `
        gap: 12px;
    `,
  })}
`;

export const Content = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: ${props => props.$hasContent ? 'space-between' : 'center'};
  flex-grow: 1;
  padding: ${props => props.$hasContent ? ' 14px 16px' : '0'};

  ${switchStyle('$layoutType', {
     mobile_text: `
      min-height: 149px;
    `,
    mobile_image: `
      min-height: 66px;
    `,
    laptop_image: `
      min-height: 34px;
    `,
    laptop_text: `
      min-height: 65px;
    `
  })}

  ${switchStyle('$type', {
    mobile: `                  
    
    `,
    laptop: `
        padding: 7px;
        ${TextContainer} {
            margin-bottom: 0;
        }
        ${Title} {
            font-size: 7px;
            line-height: 8px;
        }
        ${Description} {
            font-size: 5px;
            line-height: 6px;
        }
    `,
    common: `
        padding: 15px 24px;
        min-height: 80px;
        ${Title} {
          font-size: 24px;
          line-height: 110%;
        }
        ${Description} {
          font-size: 18px;
          line-height: 110%;
        
        }
    `,
  })}
`;
