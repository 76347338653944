import { Theme } from '@velitech/ui';
import { Modal as ANTDModal } from 'antd';

import { Icon } from '@components';

import { ActionsRow, CloseIconContainer, Content, Title, Container } from './styled';

const Modal = ({
  title,
  fullScreen,
  appearance = 'default',
  opened,
  onClose,
  children,
  actions,
  centerFooter,
  containerRef,
  right,
  additional,
  wide,
  containerStyle,
  bigger = false,
  closable = true,
  contentStyles = null,
  containerProps = {},
  statistics,
  actionsStyle,
  bodyStyle = {},
  renderBefore,
  ...props
}) => {
  const handleClose = (event) => {
    event.stopPropagation();
    onClose(event);
  }

  return (

    <ANTDModal
      onCancel={handleClose}
      maskClosable={closable}
      footer={null}
      closeIcon={null}
      destroyOnClose
      wrapClassName={right ? 'right' : ''}
      className={wide ? 'wide' : ''}
      style={{ borderRadius: '12px', maxWidth: (!right && !bigger) ? '360px' : '500px', ...(props.style || {}) }}
      bodyStyle={{
        padding: '0px',
        height: fullScreen ? '100vh' : undefined,
        minWidth: wide ? '900px' : undefined,
        right: right ? '0px' : undefined,
        margin: '0px',
        ...bodyStyle,
      }}
      open={opened}
      {...props}
    >
      {renderBefore}
      <Theme mode="light">
        <Container
          style={containerStyle}
          fullScreen={fullScreen}
          {...containerProps}
        >
          {title && (
            <Title fullScreen={fullScreen} appearance={appearance}>
              <div style={{ maxWidth: '100%' }}>{title}</div>
              {closable && (
                <CloseIconContainer onClick={onClose}>
                  <Icon name="Cross-icon" size={12} color="#5F728A"/>
                </CloseIconContainer>
              )}
            </Title>
          )}
          <Content ref={containerRef} statistics={statistics} style={contentStyles}>{children}</Content>
          {additional && additional}
          {actions ? <ActionsRow style={actionsStyle} centerFooter={centerFooter === undefined ? false : centerFooter}>{actions}</ActionsRow> : null}
        </Container>
      </Theme>
    </ANTDModal>
  );
};

export default Modal;
