import styled from 'styled-components';

export const Container = styled.div`

`;

export const SelectsContainer = styled.div`
  display: flex;
  margin-top: 14px;
`;

export const FiltersContainer = styled.div`
  margin-left: 190px;
`;
