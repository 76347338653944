import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const FooterOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 14px;
  cursor: pointer;
  border-top: 1px solid #DCDFE6;;
`;

export const FooterOptionLabel = styled.div`
    color: var(--select-select-txt, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 18px;
    margin-left: 8px;
`;

export const NodeSettingsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 28px;
`;

export const Title = styled.div`
  color: #1A232E;
  font-family: Manrope-SemiBold;
  font-size: 18px;
`;
