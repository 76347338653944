import styled from 'styled-components';

import { TextVariants } from '@constants';

import { Text } from '@components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 250px;
`;

export const Title = styled(Text).attrs({ width: 160, variant: TextVariants.HEADING_2 })`
  color: #303133;
  margin-bottom: 14px;
  font-family: Manrope-SemiBold !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
