import laptopPreview from '@res/images/laptop.png';

import { LaptopContainer, Image } from './styled';

import { Placeholder } from '../Placeholder';
import { PreviewButton } from '../PreviewButton';
import { PreviewContent } from '../PreviewContent';
import { buttonSizeMap } from '../PreviewContent/PreviewContent';
import { ButtonContainer } from '../PreviewContent/styled';

export const ModalLaptop = ({
  title,
  message,
  shouldShowTextContent,
  buttons,
  layout,
  imageOnly,
  imageUrl
}) => {

  const hasButtonsName = buttons?.some((button) => button.name);

  const renderButtons = buttons?.map((button) => {
    if (!button.name) {
      return null;
    }

    return (
      <PreviewButton key={button.name} size={buttonSizeMap['laptop']} type={button.type}>
        {button.name}
      </PreviewButton>
    );
  });

  const descriptionConfig = {
    'text': `29:10 aspect ratio \n Hi-Res Max 1450x500px \n PNG, JPEG, GIF`,
    'image': "Any aspect ratio Message resizes to fit images of most sizes Hi-Res Max 1200x1200px Min. 600x600px PNG,  JPEG, GIF"
  };

  return (
    <LaptopContainer>
      <PreviewContent 
        type={'laptop'} 
        layout={layout}
        shouldShowTextContent={shouldShowTextContent}
        title={title}
        description={message}
        imageOnly={imageOnly}
        imageUrl={imageUrl}
        placeholder={
          <Placeholder
            type={'laptop'}
            layoutType={`laptop_${layout}`}
            title={"Image recomendations"}
            description={descriptionConfig[layout]}
          />
        }
        actionButton={
          (hasButtonsName) && (
            <ButtonContainer $size={buttonSizeMap['laptop']}>
              {renderButtons}
            </ButtonContainer>
          )
        }

      />
      <Image src={laptopPreview} alt='mobile' />
    </LaptopContainer>
  );
};