export const LinearIconSvg = () => {
  return (
    <svg
      width='76'
      height='85'
      viewBox='0 0 76 85'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_60_988)'>
        <path
          d='M20.1434 69.25H61.6613C63.3872 69.25 64.7863 67.8509 64.7863 66.125V64.7857C64.7863 52.4777 54.7729 42.4643 42.4649 42.4643H33.5363C21.2282 42.4643 11.2148 52.4777 11.2148 64.7857V66.125C11.2148 67.8509 12.614 69.25 14.3398 69.25H20.1434ZM38.0006 38C46.9068 38 53.6256 31.2812 53.6256 22.375C53.6256 13.4687 46.9068 6.75 38.0006 6.75C29.0943 6.75 22.3756 13.4687 22.3756 22.375C22.3756 31.2812 29.0943 38 38.0006 38Z'
          fill='url(#paint0_linear_60_988)'
          shapeRendering='crispEdges'
        />
        <path
          d='M61.6613 68.8594H20.1434H14.3398C12.8297 68.8594 11.6055 67.6352 11.6055 66.125V64.7857C11.6055 52.6934 21.444 42.8549 33.5363 42.8549H42.4649C54.5572 42.8549 64.3957 52.6934 64.3957 64.7857V66.125C64.3957 67.6352 63.1714 68.8594 61.6613 68.8594ZM53.2349 22.375C53.2349 31.0655 46.6911 37.6094 38.0006 37.6094C29.31 37.6094 22.7662 31.0655 22.7662 22.375C22.7662 13.6845 29.31 7.14062 38.0006 7.14062C46.6911 7.14062 53.2349 13.6845 53.2349 22.375Z'
          stroke='white'
          strokeOpacity='0.3'
          strokeWidth='0.78125'
          shapeRendering='crispEdges'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_60_988'
          x='0.277344'
          y='0.5'
          width='75.4473'
          height='84.375'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4.6875' />
          <feGaussianBlur stdDeviation='5.46875' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.046375 0 0 0 0 0.516821 0 0 0 0 0.830453 0 0 0 0.7 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_60_988'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_60_988'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_60_988'
          x1='64.5626'
          y1='6.75'
          x2='11.4376'
          y2='69.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.62' />
          <stop offset='1' stopColor='white' stopOpacity='0.48' />
        </linearGradient>
      </defs>
    </svg>
  );
};
