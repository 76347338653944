const AceEditorConfig = {
  theme: 'solarized_dark',
  name: 'code-editor',
  height: '100%',
  width: '100%',
  fontSize: 12,
  showPrintMargin: false,
  tabSize: 4,
  showGutter: true,
  highlightActiveLine: true,
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  enableSnippets: true,
  showLineNumbers: true,
  setOptions: {
    enableBasicAutocompletion: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2,
  }
};

export default AceEditorConfig;
