import { useState } from 'react';

import { Checkbox, Button } from '@velitech/ui';

import { useTranslation } from '@hooks';
import useWorkflowOptions, { WorkflowOptionsType } from '@hooks/useWorkflowOptions';

import { RFMColorsConfig, SegmentsRuleTypes } from '@constants';

import { by, tryParse } from '@utils';

import { NoData } from '@components';

import {
  Container, Option, ColorIndicator,
  OptionCaption,
  OptionHeader,
  OptionTitle, SubOption, SubOptions,
  ApplySelected
} from './styled';

const sortSub = (a, b) => (a.rank = b.rank);
const sortOption = (a, b) => a.cfg?.order - b.cfg?.order;

const RFMSegmentsFilterPanel = ({ onSelect }) => {
  const { t, p } = useTranslation();
  const [openedAnalytics, setOpenedAnalytics] = useState();
  const [selected, setSelected] = useState([]);

  const rfmOptions = useWorkflowOptions(WorkflowOptionsType.RFM_ANALYTICS);

  const existFilter = (subOption) => {
    return selected.find((subOptionItem) => subOptionItem.analytics === subOption.analytics && subOptionItem.id === subOption.id);
  }
  const handleSelectFilter = (value) => {
    const existFilterOption = existFilter(value);
    if(existFilterOption) {
      const newFilter = selected.filter((selectedFilter) => selectedFilter.id !== existFilterOption.id)
      setSelected(newFilter)
    } else {
      setSelected([...selected, value])
    }
  };

  const handleApply = () => {
    onSelect(selected, SegmentsRuleTypes.RFM_SEGMENT);
  }

  const rfmAnalyticsOptions = (rfmOptions.data || []).map(
    ({ id, name, resources }) => ({
      value: id,
      label: name,
      options: (resources || [])
        .filter(({ parent_segment_id }) => !parent_segment_id)
        .flatMap(({ segment_id, name, monetary_aggregate, segment_query }) => [
          {
            value: { id: segment_id, query: segment_query, analytics: id },
            label: t(
              `rfm_analytics.segment_title_${
                RFMColorsConfig.find(by('name', name))?.title
              }`
            ),
            cfg: RFMColorsConfig.find(by('name', name)),
            segment_query,
          },
          ...(resources || [])
            .filter(by('parent_segment_id', segment_id))
            .map(({ segment_id, name: subName, segment_query }) => {
              const monetaryValues = (tryParse(monetary_aggregate) || []).find(
                by('monetary_ranks', +subName[subName.length - 1])
              );

              return {
                value: { id: segment_id, query: segment_query, analytics: id },
                label: `${t(
                  `rfm_analytics.segment_title_${
                    RFMColorsConfig.find(by('name', name))?.title
                  }`
                )}.${(+monetaryValues.min).toFixed(
                  2
                )} - ${(+monetaryValues.max).toFixed(2)} revenue`,
                cfg: RFMColorsConfig.find(by('name', name)),
                rank: +subName[subName.length - 1],
                segment_query,
              };
            })
            .sort(sortSub),
        ])
        .sort(sortOption),
    })
  );

  const isChecked = (subOption) => {
    const exist = existFilter(subOption);
    return exist;
  }

  if (!rfmAnalyticsOptions?.length) {
    return (
      <Container>
        <NoData />
      </Container>
    )
  }

  return (
    <Container onScrollToEnd={rfmOptions.loadMore}>
      {[...rfmAnalyticsOptions].map(({ value, label, options }, index) => (
        <Option
          data-testid={'segment-trigger-settings-add-rfm-segment-option-' + index}
          key={value}
          onClick={() => openedAnalytics === value ? setOpenedAnalytics(-1) : setOpenedAnalytics(value)}
        >
          <OptionHeader>
            <OptionTitle>
              {label}
            </OptionTitle>
            <OptionCaption>
              {/*@TODO: customers count */}
            </OptionCaption>
            {openedAnalytics === value ? (
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5.625L14.625 11.25L13.8375 12.0375L9 7.2L4.1625 12.0375L3.375 11.25L9 5.625Z" fill="#5F728A"/>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="8" viewBox="0 0 12 8" fill="none">
                <path d="M6 7.37539L0.375 1.75039L1.1625 0.962891L6 5.80039L10.8375 0.962891L11.625 1.75039L6 7.37539Z" fill="#5F728A"/>
              </svg>
            )}
          </OptionHeader>
          <SubOptions $opn={openedAnalytics === value}>
            {options.map(({ value: sub, label, cfg }, idx) => {
              const isSelected = isChecked(sub);
              return (
                <SubOption $selected={isSelected} key={idx} onClick={(e) => {
                  e.stopPropagation();
                  handleSelectFilter(sub)
                }}>
                  <Checkbox
                    onLabelClick={(e) => e.preventDefault()}
                    checked={isSelected}
                  />
                  <ColorIndicator $color={cfg?.color?.ui} />
                  <OptionTitle>{label}</OptionTitle>
                </SubOption>
              )
            })
            }
          </SubOptions>

        </Option>
      ))}
      {selected.length > 0 && (<ApplySelected>
        <Button size='lg' onClick={handleApply} className='vl-w-[130px]'>{p('actions.apply')}</Button>
      </ApplySelected>)}
    </Container>
  );
};

export default RFMSegmentsFilterPanel;
