import { useEffect, useRef } from 'react';

import { Container, Title } from './styled.js';

const Button = ({ children, appearance = 'primary', disabled, ...props }) => {
  const ref = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    const btn = ref.current;

    const handleMouseMove = ({ type }) => {

      // container.current.style.transition = 'all 230ms ease';
      // container.current.style.transform = `translate3d(${xMove}px, ${yMove}px, 0px)`;

      if (type === 'mouseleave') {
        container.current.style.transform = '';
        container.current.style.transition = 'all 230ms ease';
      }
    };

    btn.addEventListener('mousemove', handleMouseMove);
    btn.addEventListener('mouseleave', handleMouseMove);

    return () => {
      btn.removeEventListener('mousemove', handleMouseMove);
      btn.removeEventListener('mouseleave', handleMouseMove);
    };
  }, []);

  return (
    <Container $disabled={disabled} disabled={disabled} $appearance={appearance} ref={container} {...props}>
      <Title>{children}</Title>
      <div ref={ref} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: 'transparent', zIndex: 1001 }} />
    </Container>
  );
}

export default Button;

