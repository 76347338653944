import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { OrderDirections } from '@constants';

const useOrdering = (initialColumn) => {
  const [column, setColumn] = useQueryParam('oc', withDefault(StringParam, initialColumn));
  const [direction, setDirection] = useQueryParam(
    'od',
    withDefault(StringParam, initialColumn ? OrderDirections.DESC : OrderDirections.UNDETERMINED)
  );

  const set = (col, dir) => {
    if (dir === OrderDirections.UNDETERMINED) {
      setDirection(OrderDirections.ASC);
      setColumn(col);
    } else if (dir === OrderDirections.ASC) {
      setDirection(OrderDirections.DESC);
      setColumn(col);
    } else {
      setDirection(OrderDirections.UNDETERMINED);
      setColumn('');
    }
  };

  const hardSet = (col, dir) => {
    setColumn(col);
    setDirection(dir);
  }

  const get = (col) => {
    if (col === column) {
      return direction;
    }

    return OrderDirections.UNDETERMINED;
  }

  return {
    set,
    get,
    hardSet,
    params: {
      orderCol: column,
      orderDir: column ? direction : '',
    },
  };
};

export default useOrdering;
