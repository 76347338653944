import { motion } from 'framer-motion';
import styled from "styled-components";

export const Container = styled(motion.div).attrs({
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
  transition: { duration: 0.27, easing: 'ease' }
})`
    border-radius: var(--buttons-small-radius, 5px);
    border: 1px solid var(--Colors-Edge-500p, #31C447);
    background: var(--Colors-Additional-White, #FFF);
    display: flex;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    padding: var(--buttons-small-padding-TB, 5px);
    justify-content: center;
    align-items: center;
    gap: var(--buttons-small-space, 4px);
    flex-shrink: 0;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 123;
`;

export const SecondContainer = styled(motion.div).attrs({
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
  transition: { duration: 0.27, easing: 'ease' }
})`
    border-radius: var(--buttons-small-radius, 5px);
    border: 1px solid var(--Colors-Edge-500p, #31C447);
    background: var(--Colors-Additional-White, #FFF);
    display: flex;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    padding: var(--buttons-small-padding-TB, 5px);
    justify-content: center;
    align-items: center;
    gap: var(--buttons-small-space, 4px);
    flex-shrink: 0;
    position: absolute;
    top: 12px;
    right: 50px;
    z-index: 123;
`;

