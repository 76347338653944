import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUsersList } from '@store/actions/creators';
import { usersListSelector } from '@store/selectors';

export const useUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(usersListSelector);

  const options = useMemo(
    () =>
      users?.data
        ?.filter?.((u) => !!u.name === true)
        ?.map?.(({ name, id, surname }) => ({
          label: `${name || ''} ${surname || ''}`,
          value: id,
        })),
    [users]
  );

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  return {
    userOptions: options,
  };
};

