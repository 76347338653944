import React from 'react';
import { useSelector } from 'react-redux';

import { optionsSelector } from '@store/selectors';

import { useTranslation } from '@hooks';

import {
  AnalyticsSection
} from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoRow } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoRow';
import { InfoText } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoText';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const resolveValue = v => {
  if (typeof v === 'boolean') {
    return String(v);
  }

  return v;
};

const CustomerChangeNodeSettingsInfo = ({ node }) => {
  const { t } = useTranslation();
  const segmentOptions = useSelector(optionsSelector);
  const fieldsOptions = segmentOptions.fields?.attributes?.map(({ label, field }) => ({ value: field, label }));

  if (!node.data?.field_values || (Array.isArray(node.data?.field_values) && !node.data?.field_values?.length)) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  return (
    <Container>
      {node.data.field_values.map(({ field, value }, index) => (
        <React.Fragment key={index}>
          <InfoRow>
            <InfoTitle>
              {t('labels.field')}:
            </InfoTitle>
            <InfoText>
              {fieldsOptions?.find(f => f.value === field)?.label || field}
            </InfoText>
          </InfoRow>
          <InfoRow padded>
            <InfoTitle>
              {t('labels.value')}:
            </InfoTitle>
            <InfoText>
              {resolveValue(value)}
            </InfoText>
          </InfoRow>
        </React.Fragment>
      ))}
      {node.data.analytics && <AnalyticsSection analytics={node.data.analytics}/>}
    </Container>
  );
};

export default CustomerChangeNodeSettingsInfo;
