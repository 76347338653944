import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const StepContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid #7C8C9E;
  transition: 300ms ease all;
  margin: 0 1.5px;
  
  ${styledProp('current', css`
    border-color: #31C447;
  `)}

  ${styledProp('completed', css`
    border-color: #7C8C9E !important;
  `)}
  
  ${styledProp('whiteTheme', css`
    border-color: #303133;
  `)}
`;

export const Label = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  transition: 300ms ease all;
  white-space: nowrap;
  margin-bottom: 5px;

  ${styledProp('current', css`
    color: #31C447;
  `)}

  ${styledProp('completed', css`
    color: #fff;
    margin-left: 6px;
  `)}
  
  ${styledProp('whiteTheme', css`
    color: #303133 !important;
  `)}
`;

