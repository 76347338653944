import { useEffect, useState } from 'react';

import { AnswerButton } from './components';
import { Container, Title, VariantsContainer, TextArea, TitleHint, TitleContainer } from './styled.js';

const Question = ({ config, value, onChange, step, style }) => {
  const [customVariantSelected, setCustomVariantSelected] = useState(false);
  const hasCustom = value?.['custom'] !== undefined;

  useEffect(() => {
    setCustomVariantSelected(false);
  }, [config]);

  const handleChange = v => {
    if (customVariantSelected) {
      setCustomVariantSelected(false);
    }

    return onChange(v);
  };

  const handleCustomVariantSelect = () => {
    if (hasCustom) {
      onChange(undefined, true);
      return setCustomVariantSelected(false);
    }

    onChange('', true);
    setCustomVariantSelected(true);
  };

  const handleCustomVariantChange = ({ target: { value } }) => {
    onChange(value, true);
  };

  return (
    <Container style={style}>
      <TitleContainer>
        <Title>{config.question}</Title>
        {step === 'goals' && <TitleHint>(You can select multiple options)</TitleHint>}
      </TitleContainer>
      <VariantsContainer>
        {config.variants.map(v => (
          <AnswerButton
            key={v}
            label={v}
            selected={value?.[v]}
            onSelect={handleChange}
          />
        ))}
      </VariantsContainer>
      {config.customVariant && (
        <AnswerButton
          label={typeof config.customVariant === 'string' ? config.customVariant : 'Other'}
          selected={customVariantSelected || hasCustom}
          onSelect={handleCustomVariantSelect}
        />
      )}
      {(customVariantSelected || hasCustom) && (
        <TextArea value={value?.['custom']} onChange={handleCustomVariantChange} />
      )}
    </Container>
  );
}

export default Question;

