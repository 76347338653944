import styled from 'styled-components';

export const DisabledContainer = styled.div`
  opacity: .6;
  pointer-events: none;
  cursor: not-allowed;
`;

export const NoPermission = styled.div`
  margin: auto;
`;
