import { Handle } from 'reactflow';

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { WorkflowDiffTypes } from '@constants';

import { styledProp, switchStyle } from '@utils';

import { IconButton } from '../../../../controls';

export const Container = styled(motion.div).attrs({ animate: { opacity: 1, x: 0 } })`
  opacity: 0;
  width: 240px;
  transform: translateX(-255px);
  background: transparent;
  display: flex;
  flex-direction: column;
  flex: 0;
  max-width: 240px;
  margin-right: 0;
  align-items: center;
  border-radius: 6px;
  cursor: pointer !important;
  
  ${switchStyle('highlight', {
    [WorkflowDiffTypes.CREATED]: css`
      background: #c2e7cb7d;
    `,
    [WorkflowDiffTypes.UPDATED]: css`
      background: #ffc63047;
    `,
    [WorkflowDiffTypes.DELETED]: css`
      background: #fb694c4f;
    `,
  })}
  
  ${styledProp('disabled', css`
    opacity: 0.4;
  `)}
`;

export const NodeThumbnail = styled.div`
  width: 26px;
  height: 26px;
  min-width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  position: relative;
  overflow: visible;

  ${switchStyle('type', {
    'entry_point': css`
      background: linear-gradient(208deg, #5BE3CE 10.05%, #55D58D 93.88%);
      box-shadow: 0 0 10px 0 #3FCC8E inset;
        
        & > span {
            color: #C5F9E8 !important;
        }
    `,
    'connection_channel': css`
        background: linear-gradient(208deg, #FEBB64 10.05%, #FF7153 93.88%);
        box-shadow: 0 0 10px 0 #F3C215 inset;

        & > span {
            color: #fde6d5 !important;
        }
    `,
    'actions': css`
      background: linear-gradient(208deg, #32D5FE 10.05%, #46BCFE 51.09%, #757FFE 93.88%);
      box-shadow: 0 0 10px 0 #508AF8 inset;
        & > span {
            color: #BCE8FF !important;
        }
    `,
    'control_group': css`
        background: var(--Colors-Neutral-500p, #7C94B1);
        & > span {
            color: #BCE8FF !important;
        }
    `,
    'end': css`
        background: #90D5FC;
        box-shadow: 0 0 6px 0 #7CA9FE inset;

        & > span {
            color: #e4f3ff !important;
        }
    `
  })}
`;

export const Title = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #1A232E;
`;

export const Overlay = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  padding: 9px 0;
`;

export const Option = styled.div`
  padding: 6px 14px;
`;

export const NodeAnalyticsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  position: absolute;
  top: -31px;

  .container {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
`;

export const StyledHandle = styled(Handle)`
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  background: #A9A7A7;
  cursor: copy !important;
  border-radius: 50%;
  transition: border-width 280ms ease, transform 280ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: none !important;

  ${styledProp('disabled', css`
    pointer-events: none;
  `)}

  ${switchStyle('position', {
    'left': css`
      top: 24px;
      transform: translate(0);
    `,
    'right': css`
      top: 24px;
      transform: translate(0);
    `,
    'top': css`
      transform: translate(0);
    `,
    'bottom': css`
      transform: translate(0);
    `,
  })}

  & > * {
    opacity: 0;
    transition: all 280ms ease;
    pointer-events: none;
  }

  :hover {
    transform: scale(1.5) translateY(-1.5px);
    border-width: 0px;
    & > * {
      opacity: 1;
      //transform: scale(1.5) translateY(-0.1px);
      //transform: translateY(1.5px);
    }
  }
`;

export const NodeCardContainer = styled.div`
  width: 240px;
  border-radius: 10px;
  border: 2px solid #43D09D;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 330ms ease;

  ${switchStyle('type', {
    'vertical': css`
      border: 2px solid #43D09D;
    `,
    'connection_channel': css`
      border: 2px solid #FEA05A;
    `,
    'actions': css`
      border: 2px solid #0C95FA;
    `,
  })}
  
  ${styledProp('$float', css`
    box-shadow: 2px 6px 10px 0px rgba(153, 163, 194, 0.55);
  `)}
`

export const NodeCardHeader = styled.div`
  width: 100%;
  min-height: 46px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #D3DBE7;
  background: #F8FAFE;
  padding: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
`

export const CardHeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const CardHeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const NodeCardInfo = styled.div`
  width: 100%;
  min-height: 50px;
  padding: 10px;
  background: #FFF;
`

export const NodeBottomActions = styled.div`
  width: 100%;
  padding: 0 10px 14px 10px;
`

export const IconButtonWithStyles = styled(IconButton)`
  background: #fff;
  border: 0;
  width: 22px;
  height: 22px;
  min-width: 22px;

  svg path {
    transition: all 0.3s ease;
  }

  ${switchStyle('type', {
    'common': css`
      &:hover {
        svg path {
          fill: #1A232E;
        }
      }
    `,
  })}

`
