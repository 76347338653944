import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 0;
  overflow-y: scroll;
  
  ${styledProp('hidePreview', css`
    width: 100%;
  `)}
`;

export const Field = styled.div`
    font-family: Manrope-Medium;
  display: flex;
  padding: 8px 20px;
  font-size: 13px;
  line-height: 120%;
    background: #fff;
    transition: background 290ms ease;
    cursor: pointer;

    &:hover {
        background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
    }
    user-select: none;
`;

export const ExtraFieldsList = styled.div`
    transition: max-height 330ms ease;
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;

    ${styledProp('$opn', css`
        overflow-y: auto;
        height: auto;
        max-height: 1000px;
    `)}
`;

export const ExtraFieldsTitle = styled.div`
    font-family: Manrope-Medium;
    font-size: 13px;
    line-height: 120%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    background: #fff;
    transition: background 290ms ease;

    &:hover {
        background: var(--select-select-item-hover, rgba(124, 148, 177, 0.12));
    }
    user-select: none;
`;

export const DDIcon = styled.div`
    transition: all 330ms ease;

    ${styledProp('$opn', css`
    `, css`
        transform: rotateX(180deg);
    `)}
`;

export const ExtraFieldsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    transition: max-height 0.3s ease;

    ${styledProp('$opn', css`
        overflow-y: auto;
        max-height: 1000px;
    `)}
`;

