import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { TextVariants } from '@constants';

import { extract, styledProp } from '@utils';

import { Colors } from '@res';

import { Text } from '@components';

export const Container = styled(animated.div)`
  //transition: 280ms all ease;
  white-space: nowrap;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  height: 100vh;
  z-index: 10000;
  width: 48px;
  min-width: 48px;
`;

export const InnerContainer = styled(animated.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${Colors.Sidebar.BG};
`;

export const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  border-bottom: 1px solid #0C1015;
  position: relative;
  height: 48px;
  align-items: center;
  background-color: #1A232E;
`;

export const HeaderInner = styled.div`
  display: flex;
  height: 48px;
  flex-flow: row nowrap;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  background-color: #1A232E;
  border-bottom: 1px solid #0C1015;
`;

export const Logo = styled.div`
  min-width: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

export const Name = styled.div`
  color: ${Colors.Sidebar.TEXT_CONTRAST};
  white-space: nowrap;
  font-size: 16px;
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
`;

export const HintContainer = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  ${styledProp('hovered', `
    background-color: #12181F;
  `)}
`;

export const ArrowButton = styled(animated.div)`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  transition: opacity 280ms ease;
  ${styledProp('$visible', ``, `opacity: 0;`)}
  z-index: 10000;
  background-color: ${Colors.Sidebar.TEXT_CONTRAST};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100% - 48px);
  overflow-y: scroll;
`;

export const Avatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: #DABB1F;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Sidebar.TEXT_CONTRAST};
  background: center / contain no-repeat url("${extract('image')}");
  background-size: cover;
  background-color: #fff;
  border: 1px solid #183979;
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 0;
`;

export const NotificationsContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  height: 36px;
  border-left-color: red;
  &:hover {
    background: ${Colors.Sidebar.HOVERED_BG};
    & > * {
      color: ${Colors.Sidebar.TEXT_CONTRAST};
    }
  }
  
  ${styledProp('hovered', `
    background-color: ${Colors.Sidebar.HOVERED_BG}
    background: ${Colors.Sidebar.HOVERED_BG};
    & > * {
      color: ${Colors.Sidebar.TEXT_CONTRAST};
    }
  `)}
`;

export const DocumentationContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  border-left-color: red;
  height: 36px;
  margin-bottom: 36px;

  &:hover {
    background: ${Colors.Sidebar.HOVERED_BG};
    & > * {
      color: ${Colors.Sidebar.TEXT_CONTRAST};
    }
  }

  ${styledProp('hovered', `
    background-color: ${Colors.Sidebar.HOVERED_BG}
  `)}
`;

export const SettingsContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    background: ${Colors.Sidebar.HOVERED_BG};
    & > * {
      color: ${Colors.Sidebar.TEXT_CONTRAST};
    }
  }

  ${styledProp('hovered', `
    background-color: ${Colors.Sidebar.HOVERED_BG}
  `)}
`;

export const Counter = styled.div`
  color: white;
  background-color: #31C447;
  height: 16px;
  padding: 1px 3px;
  width: auto;
  min-width: 16px;
  line-height: 14px;
  max-width: 100px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  font-size: 10px;
  margin-top: 19px;
  margin-left: 5px;
`;

export const Circle = styled.div`
  position: absolute;
  left: 25px;
  color: white;
  background-color: ${Colors.Sidebar.ICON_ACTIVE};
  height: 6px;
  padding: 1px;
  width: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: 8px;
  top: 10px;
`;

export const LogOutRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 36px;
  margin-bottom: 15px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${Colors.Sidebar.HOVERED_BG};
    & > * {
      color: ${Colors.Sidebar.TEXT_CONTRAST};
    }
  }
`;

export const SidebarButton = styled(Text).attrs({ width: 140, variant: TextVariants.MENU_1 })`
  color: ${({ active }) => active ? Colors.Sidebar.TEXT_CONTRAST : Colors.Sidebar.TEXT_PRIMARY};
  &:hover {
    color: ${Colors.Sidebar.TEXT_CONTRAST};
  }
  transition: 230ms all ease;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 14px;
  height: 26px;
  
  ${styledProp('hovered', `
    color: ${Colors.Sidebar.ICON_HOVER}
  `)}
`;

export const Overlay = styled.div`
  background: #1E386A;
  position: relative;
  width: 220px;
  padding: 15px 25px;
  z-index: 100001;
  display: flex;
  flex-direction: column;
`;

export const SettingsLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 10px 0;
  line-height: 100%;
  color: #FFFFFF;
`;

export const LinkIcon = styled.div`
  margin-left: 10px;
  display: flex;
  flex: 0;
`;
