import { createContext, useEffect, useMemo, useState } from "react";

import { useMultiLang } from "./useMultiLang";

export const useMultiLangTemplate = ({ id, templateInstance, updateInstance, subscription, getInitialTemplateValues, initialData, enableLanguage = true }) => {
  const isNew = id === 'new';
  const isDuplicate = id === 'duplicate';

  const [template, setTemplate] = useState({});
  const {
    beforeChangeTabCallback
  } = subscription || {};
  
  const { langModal, ...multiLangTabsController } = useMultiLang({ template, setTemplate });
  const selectedLangTab = multiLangTabsController.selectedLangTab;

  useEffect(() => {
    const { name, coreData, ...rest } = getInitialTemplateValues() || {};

    if(isNew) {
      const initialTemplate = {
        name,
        ...coreData,
        languages: [{
          language_code: null,
          ...rest
        }]
      };
      setTemplate(initialTemplate);
    }

    if(isDuplicate) {
      const initialDuplicate = { name, ...rest }
      const templateInstanceModifed = updateInstance?.(initialDuplicate) || initialDuplicate;
      setTemplate(templateInstanceModifed);
    }
  }, []);

  useEffect(() => {
    if(isNew || isDuplicate) {
      return;
    }

    if(templateInstance?.id) {
      const templateInstanceModifed = updateInstance?.(templateInstance) || templateInstance;
      setTemplate(templateInstanceModifed);
    }
  }, [templateInstance?.id]);

  const selectedLangTemplate = useMemo(() => {
    if(!template?.languages) return {};
    const currentTemplate = Array.isArray(template?.languages) && template?.languages?.find(({ language_code }) => language_code === multiLangTabsController.selectedLangTab);
    const withoutLangTemplate = Array.isArray(template?.languages) && template?.languages?.find(Boolean);
    const selectedTemplate = currentTemplate || withoutLangTemplate;
    return {
      ...selectedTemplate,
    };
  }, [multiLangTabsController.selectedLangTab, template]);

  const onChangeTemplateName = (templateName) => {
    setTemplate({
      ...template,
      name: templateName,
    });
  };

  const onChangeTempalate = (updatedTemplateValues) => {
    setTemplate(updatedTemplateValues)
  }

  const onChangeSubTemplate = (updater) => {
    const updatedLanguages = template.languages.map((language, index) => {
      
      if(language.language_code === null && index === 0) {
        const updatedData = updater(language);
        return updatedData || language;
      }

      if(language.language_code === selectedLangTab) {
        const updatedData = updater(language);
        return updatedData || language;
      }
      return language;
    });

    const updatedTemplate = {
      ...template,
      languages: updatedLanguages
    }
    
    setTemplate(updatedTemplate);
    return updatedTemplate;
  }

  const addLanguages = (language_code) => {
    setTemplate({
      ...template,
      languages: [...template.languages, {
        language_code,
        ...initialData
      }]
    })
  }

  const handleSelectTab = async (langName) => {

    if(langName === selectedLangTab) return;

    await beforeChangeTabCallback?.();
    multiLangTabsController.onSelectLangTab(langName);
  }

  const handleAddLang = (langCode) => {
    addLanguages(langCode)
  };

  const handleRemoveLang = (langCode) => {
    multiLangTabsController.onRemoveLang(langCode);
  }

  return {
    template: template, 
    selectedLangTemplate,
    onChangeTemplateName,
    onChangeTempalate,
    onChangeSubTemplate,
    langModal,
    multiLangTabsController: {
      ...multiLangTabsController,
      onAddLang: handleAddLang,
      onRemoveLang: handleRemoveLang,
      onSelectLangTab: handleSelectTab,
      enableLanguage
    },
  }
}

export const MultiLanguageContext = createContext(null);