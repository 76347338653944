import { useSelector } from 'react-redux';

import {
  promoCodesHistoryLoadingSelector,
  promoCodesHistorySelector,
} from '@store/selectors';

import { Dropdown, Tooltip } from 'antd';
import * as clipboard from 'clipboard-polyfill';

import { useTranslation } from '@hooks';

import { Paths, UserPermissions, WebhookStatuses, WebhookStatusLabels } from '@constants';

import { extract } from '@utils';

import { DateCell, Link, SortableTitle, WithPermissions } from '@components';
import { DetailsTree } from '@components/lib/EventDetailsModal/components';
import { IdType } from '@components/lib/styled';
import { FlexibleText } from '@components/ui/Table/components/FlexibleText';

import { WebhookPreview, Overlay, Url, UrlOverlay, UrlTooltip, IconContainer } from './styled';

import { StatusCell } from '../StatusCell';
const resolveStatus = status => WebhookStatusLabels[status];

const resolveColor = status => ({
  [WebhookStatuses.SENT]: '#24993E',
  [WebhookStatuses.DELIVERED]: '#24993E',
  [WebhookStatuses.DROPPED]: '#C8402F',
})[status];

const tryParse = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

const handleCopy = (u) => () => {
  new clipboard.writeText(u);
}

const PromoCodesTable = ({ keyExtractor, ordering }) => {
  const promoCodesNotificationHistory = useSelector(promoCodesHistorySelector);
  const loading = useSelector(promoCodesHistoryLoadingSelector);
  const { t } = useTranslation('notification_history')

  const createPromoCodesTableColumns = (ordering) => [
    {
      title: () => <SortableTitle>{t('labels.customer_id')}</SortableTitle>,
      dataIndex: 'customer_id',
      key: 'customer_id',
      className: `notification-history-cell-id`,
      render: (id, d) => (
        <div>
          <FlexibleText text={id} maxWidth={130}>
            <WithPermissions name={UserPermissions.REVIEW_CONTACTS}>
              <Link to={`${Paths.CONTACT}/${id}`}>{id}</Link>
            </WithPermissions>
          </FlexibleText>
          <IdType>{d?._s ? 'Soft ID' : 'Unique ID'}</IdType>
        </div>
      )
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'request_name'}>{t('labels.destination')}</SortableTitle>,
      dataIndex: 'request_name',
      key: 'request_name',
      className: `notification-history-cell-destination`,
      render: (name, { url, body, headers, method }) => (
        <WithPermissions name={UserPermissions.REVIEW_API_REQUESTS}>
          {name ? (
            <Dropdown
              overlay={(
                <Overlay>
                  <Url style={{ marginBottom: 10 }}>Url: <span>{url}</span></Url>
                  <Url>Method: <span>{method}</span></Url>
                  {tryParse(headers) && <DetailsTree label="Headers" value={tryParse(headers)}/>}
                  {tryParse(body) && <DetailsTree label="Body" value={tryParse(body)}/>}
                </Overlay>
              )}
              trigger={['hover']}
            >
              <div style={{ display: 'flex' }}>
                <WebhookPreview>
                  {name}
                </WebhookPreview>
              </div>
            </Dropdown>
          ) : (
            <Tooltip overlayClassName="nopad" title={(
              <UrlOverlay onClick={handleCopy(url)}>
                <UrlTooltip>
                  {url}
                </UrlTooltip>
                <IconContainer>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_16858_515383)">
                      <path d="M8.92461 2.77539H0.974609C0.749609 2.77539 0.599609 2.92539 0.599609 3.07539V11.0254C0.599609 11.2504 0.749609 11.4004 0.974609 11.4004H8.92461C9.14961 11.4004 9.29961 11.2504 9.29961 11.0254V3.07539C9.29961 2.92539 9.14961 2.77539 8.92461 2.77539ZM8.54961 10.6504H1.34961V3.45039H8.62461V10.6504H8.54961Z" fill="white"/>
                      <path d="M10.7254 0.900391H2.77539C2.55039 0.900391 2.40039 1.05039 2.40039 1.27539V2.17539C2.40039 2.40039 2.55039 2.55039 2.77539 2.55039C3.00039 2.55039 3.15039 2.40039 3.15039 2.17539V1.65039H10.4254V8.85039H9.90039C9.67539 8.85039 9.52539 9.00039 9.52539 9.22539C9.52539 9.45039 9.67539 9.60039 9.90039 9.60039H10.7254C10.9504 9.60039 11.1004 9.45039 11.1004 9.22539V1.27539C11.1004 1.05039 10.9504 0.900391 10.7254 0.900391Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_16858_515383">
                        <rect width="12" height="12" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </IconContainer>
              </UrlOverlay>
            )}>
              <WebhookPreview style={{ maxWidth: 130 }}>
                <div style={{ maxWidth: 130, overflow: 'hidden', textOverflow: 'ellipsis' }}>{url}</div>
              </WebhookPreview>
            </Tooltip>
          )}
        </WithPermissions>
      )
    },
    {
      title: () => <SortableTitle ordering={ordering} name="value">{t('labels.value')}</SortableTitle>,
      dataIndex: 'value',
      className: `notification-history-cell-value`,
      key: 'value',
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'start_date'}>{t('labels.date')}</SortableTitle>,
      dataIndex: 'start_date',
      key: 'created_at',
      className: `notification-history-cell-date`,
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'ed'}>{t('labels.active_to')}</SortableTitle>,
      dataIndex: 'end_date',
      key: 'end_date',
      className: `notification-history-cell-active_to`,
      render: (date) => <DateCell date={date}/>
    },
    {
      title: () => <SortableTitle ordering={ordering} name={'discount_tries'}>{t('labels.number_of_use')}</SortableTitle>,
      dataIndex: 'discount_tries',
      className: `notification-history-cell-status`,
      key: 'discount_tries',
    },
    {
      title: () => <SortableTitle name="priority" ordering={ordering}>{t('labels.priority')}</SortableTitle>,
      dataIndex: 'priority',
      className: `notification-history-cell-priority`,
      key: 'priority',
    },
    {
      title: () => <SortableTitle name="code" ordering={ordering}>{t('labels.promo_code')}</SortableTitle>,
      dataIndex: 'code',
      className: `notification-history-cell-promo_code`,
      key: 'code',
    },
    {
      title: () => <SortableTitle name="status" ordering={ordering}>{t('labels.status')}</SortableTitle>,
      dataIndex: 'status',
      key: 'status',
      className: `notification-history-cell-status`,
      render: (status) => {
        return <StatusCell status={t(resolveStatus(status))} color={resolveColor(status)} />
      }
    },
  ];

  const columns = createPromoCodesTableColumns(ordering);

  return (
    <>
      <WithPermissions name={UserPermissions.REVIEW_NH_DISCOUNT} hidden>
        {/*<Table*/}
        {/*  loading={loading}*/}
        {/*  scroll={{ x: true }}*/}
        {/*  key={promoCodesNotificationHistory.data?.map?.(extract('id')).join()}*/}
        {/*  rowClassName={(record) => `notification-history-row-${record.id}`}*/}
        {/*  columns={columns}*/}
        {/*  dataSource={Array.from(promoCodesNotificationHistory.data || [])}*/}
        {/*  rowKey={keyExtractor}*/}
        {/*/>*/}
      </WithPermissions>
    </>
  )
}

export default PromoCodesTable;

