const WebpushStatuses = {
  SENT: 'sent',
  OPENED: 'open',
  DISMISS: 'dismiss',
  CLICK: 'click',
  FAILED: 'failed',
};

const WebpushStatusLabels = {
  [WebpushStatuses.SENT]: 'statuses.sent',
  [WebpushStatuses.OPENED]: 'statuses.opened',
  [WebpushStatuses.DISMISS]: 'statuses.dismiss',
  [WebpushStatuses.CLICK]: 'statuses.click',
  [WebpushStatuses.FAILED]: 'statuses.failed',
};

const WebpushStatusOptions = [
  ...Object.entries(WebpushStatusLabels).map(([value, label]) => ({ value, label }))
];

export { WebpushStatuses, WebpushStatusOptions, WebpushStatusLabels };
