import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  width: 94px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  padding-top: 5px;


  ${styledProp('selected', `
    border: 1px dashed #31C447;
  `, `
    border: 1px solid transparent;
  `)}
`;

export const LeftBackground = styled.div`
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: calc(50% + 1px);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: linear-gradient(213.29deg, #17A8FD 11.15%, #0890F8 90.5%);
`;

export const RightBackground = styled.div`
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -1px;
  left: calc(50% + 1px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(213.29deg, #F4A53C 11.15%, #ED992A 90.5%);
`;

export const CountContainer = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

export const Count = styled.div`
  font-size: 13px;
  text-align: center;
  color: #909399;
`;

export const NodeThumbnail = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  margin: 6px 0px;
  color: #041638;
`;
