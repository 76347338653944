import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeLatest, put } from 'redux-saga/effects';

import { Endpoints } from '@constants';

import { EventsActionTypes } from '../../actions/types';

function* createEventSaga(action) {
  const body = {
    name: action.payload.name,
  };

  if (action.payload.id) {
    body.id = action.payload.id;
    body.payload = action.payload.payload;
    body.label = action.payload.label;
    body.description = action.payload.description;
  }

  yield put(createRequestAction(action, {
    endpoint: Endpoints.EVENTS,
    method: 'post',
    body,
  }));
}

function* deleteEventSaga(action) {
  yield put(createRequestAction(action, {
    method: 'delete',
    endpoint: Endpoints.DELETE_EVENT,
    body: {
      id: action.payload.id,
    },
  }));
}

export default function* contactsRootSaga() {
  yield all([
    takeLatest(EventsActionTypes.CREATE, createEventSaga),
    takeLatest(EventsActionTypes.DELETE, deleteEventSaga),
  ]);
}
