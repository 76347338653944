import styled, { css } from 'styled-components';

import { switchStyle , extract } from '@utils';

export const Container = styled.div`
  border-radius: 6px;
  border: 1px solid var(--cards-border, #D3DBE7);
  width: 320px;
  overflow: hidden;

  ${switchStyle('$previewType', {
    'preview-3-column': css`
       width: 270px;
    `,
   
    [undefined]: `
    `
  })}

`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  background: #fff;
  padding: 16px;
`;

export const ImageContainer = styled.div`
  height: 212px;
  width: 320px;
  background: #fff url(${extract('$image')}) center / cover no-repeat;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: #fff url(${extract('$image')}) center / cover no-repeat;
`;

export const ContentContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  overflow: hidden;
`;

export const Title = styled.div`
  color: var(--text-primary-body, #1A232E);

  font-family: Manrope-SemiBold;
  font-size: 21px;
  line-height: 26px;
`;

export const Message = styled.div`
  color: var(--text-secondary-body, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
`;

export const Link = styled.div`
  color: #4286F5;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
`;

export const Button = styled.div`
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Colors-Additional-System-400, #6D85FF);
  color: var(--Colors-Additional-White, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-SemiBold;
  font-size: 12px;
  font-style: normal;
  text-transform: uppercase;
`;

export const Footer = styled.div`
  padding: 0 18px 21px;
  background: #fff;
  display: flex;
  gap: 10px;
`;

export const ButtonPreview = styled.button`
  padding: 8px 10px;
  font-family: Manrope-SemiBold;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 4px;
  text-transform: uppercase;
  overflow: hidden;

  ${switchStyle('$type', {
    1: css`
       background-color: #6D85FF;
       color: #fff;
       flex-shrink: 1;
       max-width: 50%;
       text-overflow: ellipsis;
    `,
    2: css`
       background-color: #ECF0F6;
       color: #1A232E;
       flex-shrink: 1;
       max-width: 50%;
       text-overflow: ellipsis;
    `,
  })}
`;

