import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Divider = styled.div`
  color: var(--Colors-Neutral-700, #415062);
  font-family: Manrope-Medium;
  font-size: 12px;
  line-height: normal;
`;

export const BreadcrumbLink = styled.div`
  cursor: pointer;
  color: var(--text-secondary-body, #7C94B1);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Manrope-Medium;
  font-size: 12px;
  line-height: 18px;
  
  transition: color 200ms ease;
  
  ${styledProp('$lighter', css`
    color: var(--Colors-Additional-White, #FFF);
  `)}
  
  &:hover {
    color: var(--Colors-Additional-White, #FFF);
  }
`;
