import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  padding: 8px 0;
  // margin-left: ${({ level }) => (level) * 24}px;
  position: relative;
`;

export const Line = styled.div`
  position: absolute;
  border-bottom-left-radius: 12px;
  border: 1px solid #C1CEFC;
  border-top-width: 0;
  border-right-width: 0;
  width: 20px;
  ${({ level, nesting }) => (level > 1 || (!!nesting && level !== 1)) && css`
    border-color: #303133;
  `}
  ${styledProp('isFirst', `
    top: 0;
  `, `
    top: -32px;
  `)};
  bottom: calc(100% - 16px);
  ${styledProp('isArray', css`
    width: 30px;
    bottom: calc(50% + 5px);
  `)};
  ${styledProp('invert', css`
    transform: rotateX(180deg) translateY(-100%);
  `)};
  ${styledProp('shorter', css`
    top: -10px;
  `)};
  ${styledProp('straight', css`
    border-bottom-left-radius: 0;
  `)};
  left: 8px;
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #303133;
  margin-right: 12px;
  padding-left: 4px;
  word-break: keep-all;
  white-space: nowrap;
`;

export const Value = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #909399;
`;

export const InfoContainer = styled.div`
  display: flex;
  padding: 0 0 6px 0px;
  ${({ level }) => level !== 0 && css`
    margin-left: 30px;
  `}
  ${({ level, nesting }) => level !== 0 && !nesting && css`
    //border-bottom: 1px solid #dcdfe6;
  `}
  ${styledProp('nesting', `
    flex-direction: column;
  `)}
`;

export const AdditionalLine = styled.div`
  width: 1px;
  background: #C1CEFC;
  ${({ level, nesting }) => (level > 1 || (!!nesting && level !== 1)) && css`
    background: #303133;
  `}
  height: calc(100% - 8px);
  bottom: 20px;
  top: 0;
  position: absolute;
  left: 8px;
`;

export const ValueContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CopyButton = styled.button`
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  color: #31C447;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
