import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 320px;
    margin-top: 16px;
    position: relative;
`;
