const ChartColors = [
  '#6DD47E',
  '#B398EF',
  '#ED96C7',
  '#E3BC56',
  '#4DC1D2',
  '#A1B3C8',

  '#94DFA2',
  '#C7B4F3',
  '#F0B2D6',
  '#E9CD86',
  '#7ED1DE',
  '#D3DBE7',

  '#C6EDD0',
  '#DFD7F9',
  '#F4D6EA',
  '#F0E4C2',
  '#BBE6EE',
  '#ECF0F6',

  '#E4F5EC',
  '#EEECFC',
  '#F6ECF6',
  '#F5F1E6',
  '#E0F2F8',
  '#F8FAFE',
];

export default ChartColors;
