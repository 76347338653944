import { useState, useMemo } from 'react';

import { useTranslation } from '@hooks';

import { CloseIconSvg } from './CloseIconSvg';
import {
  Container,
  Option,
  OptionTitle,
  TitleContainer,
  Row,
  CloseIconcontainer,
  StyledInput,
  TitleInner,
  OptionCaption,
  ListStyled
} from './styled';

import { ListNoData } from '../../../../../../../../pages/HomeScene/pages/WorkflowScene/pages/WorkflowSetupPage/components/ListNoData';

const searchIcon =
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3124 15.5171L12.0643 11.269C13.0851 10.0435 13.5941 8.47169 13.4855 6.88047C13.3769 5.28924 12.659 3.80115 11.4811 2.72576C10.3033 1.65037 8.75616 1.07047 7.16164 1.1067C5.56713 1.14294 4.04796 1.79251 2.92018 2.9203C1.79239 4.04808 1.14282 5.56725 1.10658 7.16177C1.07035 8.75628 1.65024 10.3034 2.72564 11.4812C3.80103 12.6591 5.28912 13.377 6.88034 13.4856C8.47157 13.5943 10.0434 13.0852 11.2689 12.0645L15.517 16.3125L16.3124 15.5171ZM2.24989 7.31251C2.24989 6.31124 2.5468 5.33246 3.10307 4.49994C3.65935 3.66741 4.45 3.01854 5.37505 2.63537C6.30011 2.2522 7.31801 2.15195 8.30003 2.34729C9.28206 2.54262 10.1841 3.02478 10.8921 3.73278C11.6001 4.44079 12.0823 5.34284 12.2776 6.32487C12.473 7.30689 12.3727 8.32479 11.9895 9.24985C11.6064 10.1749 10.9575 10.9656 10.125 11.5218C9.29244 12.0781 8.31366 12.375 7.31239 12.375C5.97019 12.3735 4.68339 11.8397 3.73431 10.8906C2.78522 9.94151 2.25138 8.65471 2.24989 7.31251Z" fill="#5F728A"/>
  </svg>;

export const List = ({ title, options, renderLabel, onSelect, onClose, testId, filterType, filter, headerComponent }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { p } = useTranslation('workflow_page');

  const handleSelect = (optionItemData) => {
    onSelect(optionItemData);
  };
  const filteredOptions = useMemo(() => {
    return options.filter(option => {
      const label = option.label || option.name;
      return label && label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [options, searchTerm]);
  
  return (
    <Row
      data-testid={`${testId}-node-settings-${title}-list`}
      style={{
        flexDirection: 'column',
        gap: 0,
        position: 'relative'
      }}
    >
      {title && (
        <TitleContainer>
          <TitleInner>{title}</TitleInner>
          <StyledInput $filter={filter} data-testid={`${testId}-node-settings-${title}-search-input`} prefix={searchIcon} placeholder={'Search'} onChange={(e) => setSearchTerm(e.target.value)} />
        </TitleContainer>
      )}
      {onClose && (
        <CloseIconcontainer onClick={onClose}>
          <CloseIconSvg />
        </CloseIconcontainer>
      )}
      <Container>
        {headerComponent}
        <ListStyled>
          {!options?.length && <ListNoData />}
          {filterType === 'linear' && (
            <Option
              key={'tags'}
              onClick={() => handleSelect({ special: true, field: 'tags' })}
              data-testid={`option-tags`}
            >
              <OptionTitle>Tags</OptionTitle>
            </Option>
          )}
          {filteredOptions?.map((optionItem, index) => (
            <Option
              key={optionItem?.id}
              onClick={() => handleSelect(optionItem)}
              data-testid={`${optionItem?.id} + ${index}`}
            >
              <OptionTitle>{renderLabel?.(optionItem) || optionItem?.label}</OptionTitle>
              {typeof optionItem.customers === 'number' && <OptionCaption>
              ({p('customers_count', { count: optionItem.customers })})
              </OptionCaption>
              }
            </Option>
          ))}
        </ListStyled>
      </Container>
     
    </Row>
  );
};
