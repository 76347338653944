import { TableLoader } from '@components';

import { Container, LoaderContainer } from './styled';

export const MainLoader = ({ sidebarOpened }) => {
  return (
    <Container sidebarOpened={sidebarOpened}>
      <LoaderContainer sidebarOpened={sidebarOpened}>
        <TableLoader />
      </LoaderContainer>
    </Container>
  );

}
