import Chart from 'react-apexcharts';

import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';

import { calculateRangeForVersion } from '@constants';

import { moment } from '@utils';

import { combineArrys, combineData, generateMonthlyData, getLinechartOptions } from './helpers';
import { Container } from './styled';

export const LineChart = ({ series, worker }) => {
  const [range] = useQueryParam('vr', withDefault(ArrayParam, calculateRangeForVersion(worker, moment)));

  const minDateUnix = new Date(
    moment.unix(+range[0])?.format?.('YYYY-MM-DD HH:mm').replace(' ', 'T')
  ).getTime();

  const maxDateUnix = new Date(
    moment.unix(+range[1])?.format?.('YYYY-MM-DD HH:mm').replace(' ', 'T')
  ).getTime();

  const generatedDataForFullLine = generateMonthlyData(
    minDateUnix,
    maxDateUnix
  );

  const start = moment(minDateUnix);
  const end = moment(maxDateUnix);
  const isOneDay = start.diff(end, 'days')

  const dataForTrend = series.map((line) => {
    if(isOneDay === 0) {

      const combinedData = [
        { ...line.data[0] },
        {
          date: end.toISOString(),
          x: new Date(end.toISOString()),
          y: 0,
        }];

      return {
        ...line,
        data: combinedData,
      }
    }

    if (line?.data?.length === 1) {
      const combinedData = combineData(line?.data[0], generatedDataForFullLine);
      return {
        ...line,
        data: combinedData,
      };
    } else {
      const combinedData = combineArrys(line?.data, generatedDataForFullLine);
      return {
        ...line,
        data: combinedData,
      };
    }
  });

  const options = getLinechartOptions({
    range,
    series,
    isDay: isOneDay === 0,
  });

  return (
    <Container>
      <Chart id='line-chart' height={250} options={options} series={dataForTrend} type={series.length > 1 ? 'line' : 'area'} />
    </Container>
  );
};
