import { Tooltip } from "antd";

import { Container } from './styled.js';

const UnderlinedText = ({ style, text, isTitle = false, onClick, tooltip = null }) => {
  return tooltip ? (
    <Tooltip title={tooltip}>
      <Container onClick={onClick} isTitle={isTitle} style={{ ...style }}>
        {text}
      </Container>
    </Tooltip>
  ) : (
    <Container onClick={onClick} isTitle={isTitle} style={{ ...style }}>
      {text}
    </Container>
  );
}

export default UnderlinedText;

