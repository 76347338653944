import styled, { css } from 'styled-components';

import { TextVariants } from '@constants';

import { extract, styledProp, zeroIfNaN } from '@utils';

import Text from '@components/text/Text/Text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 24px 20px 5px 20px;
`;

export const Title = styled(Text).attrs({ variant: TextVariants.PARAGRAPH_2, width: 75 })`
  color: #909399;
  margin-right: 5px;
`;

export const LegendRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #DCDFE6;
  border-top-color: transparent;
  padding-top: 12px;
`;

export const Chart = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const YIndicatorLine = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: visible;
  border-top: 1px dashed #DCDFE6;
  bottom: calc(${({ percentage }) => zeroIfNaN(percentage * 100)}%);
  padding: 0 10px;
  align-items: center;
`;

export const YIndicator = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #DCDFE6;
  position: absolute;
  background: #fff;
  padding: 3px;
  
  ${styledProp('right', css`
    right: 10px;
  `, css`
    left: 10px;
  `)}
`;

export const Caption = styled(Text).attrs({ variant: TextVariants.PARAGRAPH_3, width: 40 })`
  color: #909399;
  margin-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendCircle = styled.div`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 5px;
  background: ${extract('color')};
  margin-right: 6px;
`;

export const LegendName = styled(Text).attrs({ variant: TextVariants.PARAGRAPH_3, width: 40 })`
  color: #303133;
`;

export const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1px;
  height: 100%;
  justify-content: flex-end;
  z-index: 1;
`;

export const BarTitle = styled.div`
  font-size: 10.9605px;
  line-height: 100%;
  text-align: center;
  margin-bottom: 3px;
  color: ${extract('color')};
  background: #fff;
  position: absolute;
  top: -16px;
`;

export const Bar = styled.div`
  width: 50px;
  min-height: 3px;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 4px 4px 0 0;
  background: ${extract('color')};
  height: ${({ $value, $max }) => zeroIfNaN(zeroIfNaN($value / $max * 100))}%;
`;
