import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  min-width: 36px;
  height: 36px;
  cursor: pointer;

  border: 1px solid #303133;
  box-sizing: border-box;
  border-radius: 7px;
  
  transition: 280ms ease all;
  
  ${({ active }) => active ? css`
    border-color: #DCDFE6;
    background: #D3DBE7 !important;
  ` : ''}
  
  
  ${switchStyle('appearance', {
    filled: css`
      background-color: #31C447;
      border-color: #31C447;
      & > span {
        color: #fff !important;
      }
    `,
    danger: css`
      background: linear-gradient(180deg, #EFD5D1 13.54%, #F2C0B9 100%);
      border-color: #EFD5D1 !important;
      & > span {
        color: #C8402F !important;
      }
    `,
    'danger-outlined': css`
      background: #fff;
      border-color: #C8402F !important;
      & > span {
        color: #C8402F !important;
      }
    `,
    delete: css`
      background-color: #FFFFFF;
      border-color: #AF2B1B !important;
      & > span {
        color: #AF2B1B !important;
      }
    `,
    common: css`
      background-color: #FFFFFF;
      border-color: #FFF;
      & > span {
        color: #7C94B1 !important;
      }
    `,
    disabled: css`
      background-color: #FFFFFF;
      border-color: #DCDFE6 !important;
      & > span {
        color: #DCDFE6 !important;
      }
    `,

    secondary: `
      background-color: #F0F2F6;
      border-color: #F0F2F6 !important;
    `,
    success: `
      background-color: #FFFFFF;
      border-color: #24B277 !important;
      & > span {
        color: #24B277 !important;
      }
    `,
    [undefined]: `
    `
  })}
  
  ${styledProp('disabled', `
    opacity: 0.7;
  `)}
`;
