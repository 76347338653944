import { useEffect, useState } from 'react';

import { Button } from '@velitech/ui';
import { Checkbox } from 'antd';

import { useTranslation } from '@hooks';

import { ProgramTypes, UserPermissions } from '@constants';

import { moment, sequence, testId } from '@utils';

import {
  Input,
  Modal,
  Multiselect,
  RadioButton,
  RadioGroup,
  TimePicker,
  Switch, DatePicker, WithPermissions
} from '@components';

import {
  Container,
  TabsContainer,
  Tab,
  TabIndicator,
  Row,
  Col,
  RepeatSettingsContainer,
  RepeatSettingsTitle,
  InputCaption,
  DisabledCaption,
  DisabledContainer,
  Title, FakeContainer
} from './styled';

const getDaysOptions = () => [
  {
    value: '0',
    label: 'labels.sunday',
  },
  {
    value: '1',
    label: 'labels.monday',
  },
  {
    value: '2',
    label: 'labels.tuesday',
  },
  {
    value: '3',
    label: 'labels.wednesday',
  },
  {
    value: '4',
    label: 'labels.thursday',
  },
  {
    value: '5',
    label: 'labels.friday',
  },
  {
    value: '6',
    label: 'labels.saturday',
  },
];

const resolveEntityName = (type) => {
  switch (type) {
    case ProgramTypes.CAMPAIGN:
      return 'campaign';
    case ProgramTypes.WORKFLOW:
    default:
      return 'workflow';
  }
};

const LaunchSettingsModal = ({
  loading,
  opened,
  onConfirm,
  onClose,
  disabledTypes,
  schedule = null,
  hideTypes = [],
  fake = false,
  readonly,
  programType,
}) => {
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [tab, setTab] = useState([0, 1, 2].find(t => !~disabledTypes.indexOf(t)) || 0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [repeatInterval, setRepeatInterval] = useState('daily');
  const [day, setDay] = useState(1);
  const [weekDay, setWeekDay] = useState();
  const [mountDay, setMountDay] = useState(null);
  const [cronTime, setCronTime] = useState(null);
  const [fakeRun, setFakeRun] = useState(false);
  const [launchInstantly, setLaunchInstantly] = useState(true);
  const [noEndDate, setNotEndDate] = useState(true);
  const [cronTimeDescription, setCronTimeDescription] = useState('');
  const [repeatTime, setRepeatTime] = useState(null);
  const [error, setError] = useState('');
  const [cronError, setCronError] = useState('');
  const { p, t, e } = useTranslation('workflow_page');
  const [repeatError, setRepeatError] = useState('');

  useEffect(() => {
    if (disabledTypes.length && !!~disabledTypes.indexOf(tab)) {
      setTab([0, 1, 2].find(t => !~disabledTypes.indexOf(t)));
    }
  }, [disabledTypes.join('.')])

  useEffect(() => {
    if (schedule && opened) {
      setDisabledEdit(readonly);
      setTab(schedule.type);
      setStartDate(schedule.start_date ? moment(schedule.start_date) : null)
      setEndDate(schedule.end_date ? moment(schedule.end_date) : null)
      setLaunchInstantly(!schedule.start_date)
      setNotEndDate(!schedule.end_date)
      setRepeatInterval(schedule.active_tab);
      setRepeatTime(schedule.repeat_time ? moment(schedule.repeat_time) : null);
      setDay(schedule.day);
      setWeekDay(schedule.week_day);
      setMountDay(schedule.month_day);
      setCronTime(schedule.cron_time);
    } else {
      setDisabledEdit(readonly);
      setTab([0, 1, 2].find(t => !~disabledTypes.indexOf(t)) || 0);
      setStartDate(null);
      setEndDate(null);
      setRepeatTime(null);
      setLaunchInstantly(true);
      setNotEndDate(true);
      setRepeatInterval('daily');
      setDay(1);
      setWeekDay([]);
      setMountDay(null);
      setCronTime(null);
      setCronTimeDescription('');
    }
  }, [schedule, opened, readonly]);

  useEffect(() => {
    setFakeRun(fake);
  }, [fake]);

  const handleTabChange = (tab) => () => {
    if (!disabledEdit) {
      setTab(tab);
    }
  };

  const handleStartDateChange = (date) => {
    setLaunchInstantly(false);

    if (endDate?.isBefore?.(date)) {
      setEndDate(null);
    }

    if (date?.isBefore?.(moment())) {
      return setStartDate(moment().add(2, 'minutes'));
    }

    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setNotEndDate(false);

    if (date?.isBefore?.(moment())) {
      return setStartDate(moment().add(2, 'minutes'));
    }

    if (startDate && date?.isBefore?.(startDate)) {
      return setStartDate(startDate);
    }

    setEndDate(date);
  };

  const handleRepeatTimeChange = (time) => {
    if (error) {
      setError('');
    }

    if(!time) {
      return setRepeatTime('')
    }
    setRepeatTime(moment(time));
  };

  const handleMonthDayChange = (event) => {
    setMountDay(event.target.value);
    setRepeatError('');
  };

  const handleLaunchInstantly = (event) => {
    setLaunchInstantly(event.target.checked)
    setStartDate(null)
  };

  const handleNotEndDate = (event) => {
    setNotEndDate(event.target.checked)
    setEndDate(null)
  };

  const handleChronTimeDescriptionChange = ({ target: { value } }) => {
    setCronTimeDescription(value);
  };

  const handleChronTimeChange = ({ target: { value } }) => {
    setCronTime(value);
  }

  const handleSave = () => {
    if (['daily'].indexOf(repeatInterval) !== -1 && tab === 1 && !day) {
      return setRepeatError(e('field_is_required'));
    }

    if (['weekly'].indexOf(repeatInterval) !== -1 && tab === 1 && !weekDay?.length) {
      return setRepeatError(e('field_is_required'));
    }

    if (['monthly'].indexOf(repeatInterval) !== -1 && tab === 1 && !mountDay) {
      return setRepeatError(e('field_is_required'));
    }

    if (['daily', 'weekly', 'monthly'].indexOf(repeatInterval) !== -1 && tab === 1 && !repeatTime) {
      return setError(p('launch_time_is_required'));
    } else if (!cronTime && repeatInterval === 'custom'){
      return setCronError(p('cron_expression_is_required'));
    }

    setError('');

    onConfirm({
      type: tab,
      start_date: startDate,
      end_date: endDate,
      active_tab: repeatInterval,
      repeat_time: repeatTime,
      minute: repeatTime ? repeatTime.utc().minutes() : null,
      hour: repeatTime ? repeatTime.utc().hours() : null,
      day: day,
      launch_date_if_no_start_date: moment(),
      week_day: weekDay?.map(d => +d),
      month_day: mountDay,
      cron_time: cronTime,
      cron_time_description: cronTimeDescription,
    }, fakeRun);
  };

  const daysOptions = getDaysOptions(p);

  const disabled = disabledTypes.indexOf(tab) !== -1;
  const handleClose = () => {
    onClose()
    setError('')
    setCronError('')
  }

  return (
    <Modal
      title={p('launch_settings')}
      opened={opened}
      bodyStyle={{ padding: 0, margin: 0 }}
      contentStyles={{ paddingTop: '25px' }}
      bigger
      onClose={handleClose}
      centerFooter={disabledEdit}
      actions={disabledEdit ? (
        <>
          <Title>{p('to_change_the_settings_and_startup', { name: resolveEntityName(programType) })}</Title>
        </>
      ) : (
        <>
          <Button {...testId('launch-settings-modal-close')()} onClick={onClose} variant="secondary" style={{ width: '160px' }}>{t('actions.cancel')}</Button>
          <WithPermissions name={UserPermissions.UPDATE_WORKFLOWS}>
            <Button
              {...testId('launch-settings-modal-run')()}
              style={{ flex: 0, minWidth: 160 }}
              loading={loading}
              onClick={handleSave}
              disabled={disabledTypes.indexOf(tab) !== -1}
            >
              {programType === ProgramTypes.CAMPAIGN ? t('actions.launch_campaign') : t('actions.launch_workflow')}
            </Button>
          </WithPermissions>
        </>
      )}
    >
      <Container>
        <TabsContainer>
          {!~hideTypes.indexOf(0) && (
            <Tab {...testId('launch-settings-modal-tab-one-off')()} disabled={disabledEdit && tab !== 0} onClick={handleTabChange(0)}>
              {p('one_off')}
            </Tab>
          )}
          {!~hideTypes.indexOf(1) && (
            <Tab {...testId('launch-settings-modal-tab-repeating')()} disabled={disabledEdit && tab !== 1} onClick={handleTabChange(1)}>
              {p('repeating')}
            </Tab>
          )}
          {!~hideTypes.indexOf(2) && (
            <Tab {...testId('launch-settings-modal-tab-realtime')()} disabled={disabledEdit && tab !== 2} onClick={handleTabChange(2)}>
              {p('realtime')}
            </Tab>
          )}
          <TabIndicator tab={tab} disabled={disabledEdit} style={{ width: `${100 / (3 - hideTypes.length)}%` }} />
        </TabsContainer>
        <Row>
          {disabled && (
            <DisabledContainer>
              <DisabledCaption>
                {p('you_cant_configure_this_settings')}
              </DisabledCaption>
            </DisabledContainer>
          )}
          {!disabled && (
            <Col style={{ position: 'relative', width: '100%', marginRight: 8 }}>
              <DatePicker
                {...testId('launch-settings-modal-tab-one-off-select-date-time')()}
                showTime={{ format: 'HH:mm' }}
                min={moment().add(2, 'minutes')}
                disabled={disabledEdit}
                getPopupContainer={() => document.body}
                format="YYYY-MM-DD HH:mm"
                oldCalendar
                style={{ marginBottom: 0, width: '100%' }}
                containerStyle={{ width: '100%' }}
                showNow={false}
                title={p('start_date_and_time')}
                value={!startDate ? startDate : moment(startDate).set('seconds', 0)}
                onChange={handleStartDateChange}
              />
              <Checkbox data-testid={'launch-settings-modal-run-instantly'} disabled={disabledEdit} checked={launchInstantly} onChange={handleLaunchInstantly} style={{ marginTop: '12px' }} >{p('launch_instantly')}</Checkbox>
            </Col>
          )}
          {(tab > 0 && !disabled) && (
            <Col style={{ marginLeft: 8 }}>
              <DatePicker
                {...testId('launch-settings-modal-tab-one-off-select-date-time')()}
                showTime={{ format: 'HH:mm' }}
                min={startDate || moment().add(2, 'minutes')}
                disabled={disabledEdit}
                showNow={false}
                getPopupContainer={() => document.body}
                format="YYYY-MM-DD HH:mm"
                oldCalendar
                style={{ marginBottom: 0, width: '100%' }}
                containerStyle={{ width: '100%' }}
                title={p('end_date_and_time')}
                value={endDate}
                onChange={handleEndDateChange}
              />
              <Checkbox {...testId('launch-settings-modal-no-end-date')()} checked={noEndDate} onChange={handleNotEndDate} disabled={disabledEdit} style={{ marginTop: '12px', flex: 0 }}>{p('no_end_date')}</Checkbox>
            </Col>
          )}
        </Row>
        {(tab === 1 && !disabled) && (
          <RepeatSettingsContainer>
            <RepeatSettingsTitle>
              {p('repeat_settings')}
            </RepeatSettingsTitle>
            <Row style={{ alignItems: 'flex-start' }}>
              <Col>
                <RadioGroup
                  disabled={disabledEdit}
                  value={repeatInterval}
                  onChange={sequence(setRepeatInterval, () => {
                    setRepeatError('');
                    setDay(1);
                    setWeekDay([]);
                    setMountDay(null);
                    setCronTime(null);
                  })
                  }>
                  <RadioButton {...testId('launch-settings-modal-repeat-settings-daily')()} style={{ marginBottom: '18px' }} name="daily" title={p('daily')} />
                  <RadioButton {...testId('launch-settings-modal-repeat-settings-weekly')()} style={{ marginBottom: '18px' }} name="weekly" title={p('weekly')} />
                  <RadioButton {...testId('launch-settings-modal-repeat-settings-monthly')()} style={{ marginBottom: '18px' }} name="monthly" title={p('monthly')} />
                  <RadioButton {...testId('launch-settings-modal-repeat-settings-custom')()} name="custom" title={p('custom_days')} />
                </RadioGroup>
              </Col>
              <Col>
                {repeatInterval === 'daily' && (
                  <>
                    <Row>
                      <Input
                        {...testId('launch-settings-modal-repeat-settings-daily-day')()}
                        disabled={disabledEdit}
                        value={day}
                        onChange={({ target: { value } }) => { setDay(value); setRepeatError(''); }}
                        style={{ marginBottom: '0px' }}
                        title={p('every')}
                        error={repeatError}
                      />
                      <InputCaption>{p('day')}</InputCaption>
                    </Row>
                  </>
                )}
                {repeatInterval === 'weekly' && (
                  <>
                    <Multiselect
                      {...testId('launch-settings-modal-repeat-settings-weekly-choose-days')()}
                      disabled={disabledEdit}
                      value={weekDay}
                      title={t('labels.days')}
                      options={daysOptions}
                      containerStyle={{ width: '100%' }}
                      style={{ width: '100%' }}
                      onChange={(v) => {
                        setWeekDay(v);
                        setRepeatError('');
                      }}
                      error={repeatError}
                    />
                  </>
                )}
                {repeatInterval === 'monthly' && (
                  <>
                    <Row>
                      <Input
                        {...testId('launch-settings-modal-repeat-settings-monthly-month-day')()}
                        disabled={disabledEdit}
                        value={mountDay}
                        max={31}
                        min={9}
                        type="number"
                        onChange={handleMonthDayChange}
                        style={{ marginBottom: '0px', width: '100%' }}
                        wrapperStyles={{ width: '100%' }}
                        title={p('every')}
                        error={repeatError}
                      />
                      <InputCaption disabled={disabledEdit} style={{ whiteSpace: 'nowrap' }}>{p('month_day')}</InputCaption>
                    </Row>
                  </>
                )}
                {['daily', 'weekly', 'monthly'].indexOf(repeatInterval) !== -1 && (
                  <>
                    <Row>
                      <TimePicker
                        {...testId('launch-settings-modal-repeat-settings-launch-time')()}
                        disabled={disabledEdit}
                        value={repeatTime}
                        error={error}
                        format={'HH:mm'}
                        onChange={handleRepeatTimeChange}
                        style={{ marginTop: '16px', width: '100%' }}
                        title={p('launch_time')}
                      />
                    </Row>
                  </>
                )}
                {repeatInterval === 'custom' && (
                  <>
                    <Input {...testId('launch-settings-modal-repeat-settings-custom-cron-expression')()} error={cronError} disabled={disabledEdit} value={cronTime} title={p('chron_expression')} onChange={handleChronTimeChange}/>
                    <Input {...testId('launch-settings-modal-repeat-settings-custom-description')()} disabled={disabledEdit} style={{ marginBottom: 0 }} value={cronTimeDescription} title={t('labels.description')} onChange={handleChronTimeDescriptionChange} />
                  </>
                )}
              </Col>
            </Row>
          </RepeatSettingsContainer>
        )}
        <Row>
          <FakeContainer style={{ width: '100%' }}>
            <Row style={{ alignItems: 'flex-end', width: '100%' }}>
              <Switch testId={'launch-settings-modal-test-launch'} disabled={disabledEdit} style={{ width: '100%', justifyContent: 'space-between' }} title={p('test_launch')} onChange={setFakeRun} checked={fakeRun}>
              </Switch>
            </Row>
            {fakeRun === true && (
              <Title style={{ width: '100%' }}>
                {p('allow_you_to_test', { name: resolveEntityName(programType) })}
              </Title>
            )}
          </FakeContainer>
        </Row>
      </Container>
    </Modal>
  );
};

export default LaunchSettingsModal;
