import pick from 'lodash.pick';

import { useTranslation } from '@hooks';

import { ConditionFilterSelect, ScheduleSlider, SpecificDatePicker } from './components';
import { Container, FilterButton, FiltersContainer, FiltersMenu, FiltersTitle, FiltersTitleContainer } from './styled';

const specificFilters = [
  { name: 'years', label: 'Year' },
  { name: 'months', label: 'Month' },
  { name: 'days', label: 'Month day' },
  { name: 'week_days', label: 'Week day' },
  { name: 'hours', label: 'Hours' },
  { name: 'minutes', label: 'Minutes' },
];

const filterUnits = () => {
  return {};
};

const SpecificRangeSettings = ({ value, onChange }) => {
  const { p } = useTranslation('date_picker');

  const handleLeftChange = (left) => {
    onChange(s => ({
      ...s,
      specific: {
        ...s.specific,
        unit: filterUnits(s.specific.unit, [left, s.specific.range[1]]),
        range: [left, s.specific.range[1]],
      },
    }));
  };

  const handleRightChange = (right) => {
    onChange(s => ({
      ...s,
      specific: {
        ...s.specific,
        unit: filterUnits(s.specific.unit, [s.specific.range[0], right]),
        range: [s.specific.range[0], right],
      },
    }));
  };

  const handleAddFilter = (name) => {
    if (value.specific?.unit?.[name]) {
      return;
    }

    onChange(s => ({
      ...s,
      specific: {
        ...s.specific,
        unit: {
          ...s.specific.unit,
          [name]: [],
        }
      },
    }));
  };

  const handleDeleteFilter = (name) => () => {
    onChange(s => ({
      ...s,
      specific: {
        ...s.specific,
        unit: {
          ...s.specific.unit,
          [name]: undefined,
        }
      },
    }));
  };

  const handleFilterChange = (name) => (value) => {
    onChange(s => ({
      ...s,
      specific: {
        ...s.specific,
        unit: {
          ...s.specific.unit,
          [name]: value,
        }
      },
    }));
  };

  const filters = Object.entries(pick(value.specific.unit, ['years', 'months', 'days', 'week_days', 'hours', 'minutes'])).filter(([_, v]) => !!v);

  return (
    <>
      <Container>
        <SpecificDatePicker
          title={p('start_date')}
          containerStyle={{ marginRight: 15 }}
          value={value.specific.range[0]}
          onChange={handleLeftChange}
        />
        <SpecificDatePicker
          title={p('end_date')}
          containerStyle={{ marginLeft: 15 }}
          value={value.specific.range[1]}
          onChange={handleRightChange}
        />
      </Container>
      <FiltersContainer>
        {filters.map(([type, v]) => (
          <ConditionFilterSelect
            type={type}
            key={type}
            onDelete={handleDeleteFilter(type)}
            value={v}
            range={value.specific.range}
            onChange={handleFilterChange(type)}
          />
        ))}
      </FiltersContainer>
      {(!!filters.length && filters.some(([_, v]) => v?.length)) && <ScheduleSlider value={value}/>}
      <FiltersMenu>
        <FiltersTitleContainer>
          <FiltersTitle>{p('conditions')}</FiltersTitle>
        </FiltersTitleContainer>
        {specificFilters.map(({ name, label }) => (
          <FilterButton key={label} onClick={() => handleAddFilter(name)}>
            {label}
          </FilterButton>
        ))}
      </FiltersMenu>
    </>
  );
};

export default SpecificRangeSettings;
