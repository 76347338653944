import styled from 'styled-components';

import { extract, styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  display: flex;
  background: transparent;
  flex: 1;
  align-items: flex-start;
  padding: 72px 52px 0 52px;
  max-width: 380px;
  align-self: flex-end;
  min-width: 380px;
  width: 50%;
  margin-right: 22%;
  
  ${styledProp('integrated', `
    align-self: center;
  `)}
`;

export const PhoneImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 25px;
  left: 45px;
  top: 50px;
  z-index: 1;
  background: url(${extract('image')}) top / cover no-repeat;
`;

export const Blur = styled.div`
  position: absolute;
  top: 48px;
  bottom: 0;
  left: 43px;
  z-index: 2;
  right: 43px;
  backdrop-filter: blur(3px);
`;
