import { WITHOUT_VALUE_OPERATORS, WorkflowNodes, WorkflowNodeTypes } from '@constants';

import { extract as extractField, isSegmentValid, mapEntries, validateSegment, zeroIfNaN } from '@utils';
import { fullPercentage, isRequired, isValidUrl, min, isValidBuilder, minLength, testArr } from '@utils/validators';

const extract = (name, validator) => data => validator(data[name]);

const validateChannelsLength = (channels) => {
  if (channels?.length <= 1) {
    return {
      valid: false,
      errors: {
        channel: 'at_least_two_channels_required',
        notification_message: 'at_least_two_channels_required'
      },
    };
  }
  return {
    valid: true,
  };
};

const validateDefaultchannel = (channels) => {
  const hasDefaultchannel = channels?.some((channel) => channel?.default);
  if(hasDefaultchannel) {
    return {
      valid: true,
    };
  } else {
    return {
      valid: false,
      errors: {
        default_channel: 'default_field_is_required',
        notification_message: 'default_field_is_required_notify'
      },
    };
  }
};

export const NodeValidators = {
  [WorkflowNodes.RESOURCE]: {
    all: [
      // ['resource_type', extract('resource_type', isRequired)],
      ['segment_ids', extract('segment_ids', isRequired)]
    ],
  },
  [WorkflowNodes.WAIT]: {
    all: [
      ['type', extract('type', isRequired)]
    ],
    oneOf: ({ type, filter_builder }) => {

      if (type === 0) {
        return [
          ['day', extract('day', isRequired)]
        ];
      } else if (type === 1) {
        return [
          ['duration', extract('duration', v => !v && 'at_least_one_field_required')],
          ['duration', extract('duration', min(1))],
        ];
      } else if (type === 2) {

        if (filter_builder?.filters?.length) {
          return [
            ['filter_builder', extract('filter_builder', isValidBuilder)],
            ['expiration_action', extract('expiration_action', isRequired)],
            ['duration', extract('duration', v => !v && 'at_least_one_field_required')],
            ['duration', extract('duration', min(1))],
          ];
        } else {
          return [
            ['expiration_action', extract('expiration_action', isRequired)],
            ['duration', extract('duration', v => !v && 'at_least_one_field_required')],
            ['duration', extract('duration', min(1))],
          ];
        }

      }

      return [];
    },
  },
  [WorkflowNodes.RETARGETING]: {
    all: [
      ['action', extract('action', isRequired)],
      ['integration_id', extract('integration_id', isRequired)],
      ['need_create', extract('need_create', isRequired)],
      ['audience_id', extract('audience_id', isRequired)],
      ['audience_name', extract('audience_name', isRequired)],
      ['fields', extract('fields', isRequired)],
      ['fields', extract('fields', fields => fields?.some?.(({ field, mapTo }) => !field || !mapTo) && 'field_mapping_filled')],
    ],
  },
  [WorkflowNodes.CONTACT_CREATED]: {
    all: [],
  },
  [WorkflowNodes.EVENT]: {
    all: [['event_id', extract('event_id', isRequired)]],
    oneOf: ({ filter_builder }) => {
      if (filter_builder?.filters?.length) {
        return [['filter_builder', extract('filter_builder', ({ filters }) => {
          const errors = filters.map(({ field, value, operator }) => ({
            field: isRequired(field),
            operator: isRequired(operator),
            value: (!~WITHOUT_VALUE_OPERATORS.indexOf(operator)) && isRequired(value.value),
          }));

          return !!errors.some(({ field, value }) => field || value) && errors;
        })]];
      }

      return [];
    }
  },
  [WorkflowNodes.SEND_SMS]: {
    all: [['sms_id', extract('sms_id', isRequired)]]
  },
  [WorkflowNodes.WEBPUSH]: {
    all: [['webpush_id', extract('webpush_id', isRequired)]]
  },
  [WorkflowNodes.MOBILE_PUSH]: {
    all: [['mobile_push_id', extract('mobile_push_id', isRequired)]]
  },
  [WorkflowNodes.VIBER]: {
    all: [['viber_id', extract('viber_id', isRequired)]]
  },
  [WorkflowNodes.API_REQUEST]: {
    all: [['api_request_id', extract('api_request_id', isRequired)]]
  },
  [WorkflowNodes.QUICK_FILTER]: {
    all: [
      ['filter_by', extract('filter_by', isRequired)],
    ],
    oneOf: ({ filter_by }) => {
      if (filter_by === 'segment') {
        return [
          ['segment_id', extract('segment_id', isRequired)],
        ];
      }
      if (filter_by === 'rule') {
        return [
          ['query', (query) => {
            if (!query?.query?.children) {
              return { filters: 'At least one attribute is required' };
            }
            const isValid = isSegmentValid(validateSegment(query));

            if (isValid) {
              return '';
            }

            return { query: 'Invalid query' };
          }],
        ];
      }

      return [];
    }
  },
  [WorkflowNodes.DATA_CHANGE]: {
    all: [['field_id', extract('field_id', isRequired)]],
  },
  [WorkflowNodes.SEND_EMAIL]: {
    all: [['email_id', extract('email_id', isRequired)]],
  },
  [WorkflowNodes.SEND_CARD]: {
    all: [
      ['content_card_id', extract('content_card_id', isRequired)],
    ],
    oneOf: ({ expiration }) => {
      if (expiration?.type === 'duration') {
        return [
          ['expiration_duration', extract('expiration', ({ duration }) => isRequired(duration))]
        ];
      }

      return [
        ['expiration_date', extract('expiration', ({ date }) => isRequired(date))]
      ];
    }
  },
  [WorkflowNodes.EXCLUDE_FILTER]: {
    all: [
      ['filter_by', extract('filter_by', isRequired)],
    ],
    oneOf: ({ filter_by }) => {
      if (filter_by === 'segment') {
        return [
          ['segment_id', extract('segment_id', isRequired)],
        ];
      }
      if (filter_by === 'rule') {
        return [
          ['query', (query) => {
            if (!query?.query?.children) {
              return { filters: 'At least one attribute is required' };
            }

            const isValid = isSegmentValid(validateSegment(query));

            if (isValid) {
              return '';
            }

            return { query: 'Invalid query' };
          }],
        ];
      }

      return [];
    }
  },
  [WorkflowNodes.PUSH_DISCOUNT]: {
    all: [
      ['type', extract('type', isRequired)],
      ['period_type', extract('period_type', isRequired)],
      ['discount_type', extract('discount_type', isRequired)],
      ['number_of_use', extract('number_of_use', isRequired)],
    ],
    oneOf: ({ type, period_type, discount_type }) => {
      let validators = [];

      if (+type === 1) {
        validators.push(['api_request_id', extract('api_request_id', isRequired)]);
      } else {
        validators.push(['url', extract('url', isRequired)]);
        validators.push(['method', extract('method', isRequired)]);
      }

      if (+period_type === 1) {
        validators.push(['duration', extract('duration', isRequired)]);
        validators.push(['duration', extract('duration', min(1))]);
        validators.push(['duration_unit', extract('duration_unit', isRequired)]);
        validators.push(['duration', extract('duration', isRequired)]);
      }

      if (discount_type === 0) {
        validators.push(['percentage', extract('percentage', isRequired)]);
      } else {
        validators.push(['amount', extract('amount', isRequired)]);
        validators.push(['currency', extract('currency', isRequired)]);
      }

      return validators;
    }
  },
  [WorkflowNodes.SWITCH_FILTER]: {
    all: [
      ['filter_by', extract('filter_by', isRequired)],
    ],
    oneOf: ({ filter_by }) => {
      if (filter_by === 'segment') {
        return [
          ['segment_id', extract('segment_id', isRequired)],
        ];
      }
      if (filter_by === 'rule') {
        return [
          ['query', (query) => {
            if (!query?.query?.children) {
              return { filters: 'At least one attribute is required' };
            }

            const isValid = isSegmentValid(validateSegment(query));

            if (isValid) {
              return '';
            }

            return { query: 'Invalid query' };
          }],
        ];
      }

      return [];
    }
  },
  [WorkflowNodes.AB_SPLITTER]: {
    all: [['segments', extract('segments', fullPercentage)]],
  },
  [WorkflowNodes.API_FILTER]: {
    all: []
  },
  [WorkflowNodes.TEAM_MESSAGE]: {
    all: [
      ['email_id', extract('email_id', isRequired)],
      ['recipients', extract('recipients', minLength(1, 'Enter at least one recipient'))],
      ['recipients_valid', extract('recipients', (v) => {
        return testArr('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', 'Some of the recipients are not valid emails')(v);
      })],
    ]
  },
  [WorkflowNodes.INCLUDE_CAMPAIGN]: {
    all: [
      ['campaign_id', extract('campaign_id', isRequired)],
      ['action', extract('campaign_id', isRequired)]
    ]
  },
  [WorkflowNodes.WEB_POPUP]: {
    all: [['web_popup_id', extract('web_popup_id', isRequired)]]
  },
  [WorkflowNodes.GIVE_REWARD]: {
    all: [
      ['reward_type', extract('reward_type', isRequired)],
      ['reward_id', extract('reward_id', isRequired)],
    ],
    oneOf: ({ reward_type, reward_money_mapping }) => {
      if (reward_type === 'money') {
        if (!reward_money_mapping) {
          return [['reward_money_mapping', () => 'At least one currency is required']];
        }

        return reward_money_mapping.map(({ currency, amount }) => [`reward_money_mapping_${currency}`, () => {
          if (+amount > 0) {
            return false;
          }

          return 'Amount should be greater than 0'
        }]);
      }

      if (reward_type === 'tag-reward') {
        return [
          ['tags_action', extract('tags_action', isRequired)],
          ['reward_tags', extract('reward_tags', tags => (!tags || tags?.length < 1) && 'At least one tag required')],
        ]
      }

      if (reward_type === 'loyalty-point') {
        return [
          ['reward_amount', extract('reward_amount', (reward_amount) => zeroIfNaN(+reward_amount) <= 0 && 'Amount should be positive number')],
        ]
      }

      return [];
    },
  },
  [WorkflowNodes.ENTRY_ANOTHER_WF]: {
    all: [
      ['trigger_workflow_node_ids', extract('trigger_workflow_node_ids', isRequired)],
    ],
  },
  [WorkflowNodes.EMAIL]: {
    all: [
      ['email_id', extract('email_id', isRequired)],
      ['value', extract('value', isRequired)],
    ],
    oneOf: ({ value, link_filter }) => {
      if (~['click'].indexOf(value)) {
        if (link_filter) {
          return [
            ['url', extract('url', isRequired)],
          ];
        }

        return [];
      }

      return [];
    }
  },
  [WorkflowNodes.CUSTOMER_CHANGE]: {
    all: [],
    oneOf: ({ field_values }) => {
      if (!field_values?.length) {
        return [['field_values', () => 'At least one field is required']];
      }

      return field_values.map(({ field, _value }, index) => [`field_values_${index}`, () => {
        if (!field) {
          return 'Field is required';
        }

        // if (!value) {
        //   return 'Value is required';
        // }

        return false;
      }])
    }
  },
  [WorkflowNodes.PAGE_VIEW]: {
    all: [
      ['link_type', extract('linkType', isRequired)],
    ],
    oneOf: ({ linkType, filter_builder }) => {
      const cond = [];

      if (filter_builder?.filters?.length) {
        cond.push(['filter_builder', extract('filter_builder', ({ filters }) => {
          const errors = filters.map(({ field, value, operator }) => ({
            field: isRequired(field),
            operator: isRequired(operator),
            value: (!~WITHOUT_VALUE_OPERATORS.indexOf(operator)) && isRequired(value),
          }));

          return !!errors.some(({ field, value }) => field || value) && errors;
        })]);
      }

      if (linkType === 'matching') {
        return [
          ...cond,
          ['url', extract('url', isRequired)],
          ['url', extract('url', isValidUrl)],
        ]
      }

      return cond;
    }
  },
  [WorkflowNodes.BEST_CHANNEL_TO_SEND]: {
    oneOf: (data) => {
      if(data?.best_time_enabled && data?.send_settigs_type === 'specific_time' && !data?.best_time_fallback) {
        return [[
          'best_time_fallback', () => 'field_is_required',
        ]];
      }

      return [];
    }
  }
};

const resolveValidation = (type, data) => {

  const config = NodeValidators[type];

  const validators = [
    ...(config?.all || []),
    ...(config?.oneOf?.(data) || []),
    ['label', extract('label', isRequired)]
  ];

  const errors = validators
    .filter(extractField('length'))
    .map(([field, validator]) => [field, validator(data)])
    .filter(([, e]) => e);

  return {
    valid: !Object.keys(errors).length,
    errors: errors.reduce((acc, [field, error]) => ({ ...acc, [field]: error }), {}),
  }
};

export const validateNode = (node) => {

  if (node.type === WorkflowNodeTypes.ARRAY) {
    const allValidated = node?.nodes?.map(n => validateNode(n));

    return {
      ...node,
      nodes: allValidated,
      validated: true,
      validation: {
        valid: allValidated?.every(({ validation }) => validation?.valid),
        errors: {},
      }
    };
  }

  if (node?.children?.length) {
    const { label, description } = node;

    const validated = node.children.map(n => ({
      ...n,
      data: {
        ...validateNode({ ...n.data, label, description }),
      },
    }));

    return {
      ...node,
      children: validated,
      validated: true,
      validation: {
        valid: validated.every(({ data: { validation } }) => validation?.valid),
        errors: validated
          .reduce((acc, { data: { validation: { errors } } }, index) => ({
            ...acc,
            ...mapEntries(errors || {}, ([k, v]) => [`mn_${index}_${k}`, v])
          }), {}),
      }
    };
  }

  if(node.name === WorkflowNodes.SWITCH_FILTER) {

    const validatedSegment = node.segments
      .filter((segment) => segment.actionType !== WorkflowNodes.EXCLUDE)
      .map((segment) => {
        return {
          ...resolveValidation(node.name, segment),
          id: segment.id
        }
      });

    const result = {
      ...node,
      validated: true,
      validation: {
        valid: validatedSegment.every((segment) => segment.valid),
        errors: validatedSegment,
      }
    }

    return {
      ...result
    }
  }

  if(node.name === WorkflowNodes.BEST_CHANNEL_TO_SEND) {

    const validatedChannels = node.channels
      .map((channel, index) => {
        return {
          ...resolveValidation(channel.type, channel.data),
          index: index
        }
      });

    const validatedChannelsLength = validateChannelsLength(node.channels);
    const validateDefaultChannel = validateDefaultchannel(node.channels);
    const validateBestTimeToSendNode = resolveValidation(node.name, node);

    const result = {
      ...node,
      validated: true,
      validation: {
        valid: validatedChannels.every((channel) => channel.valid) && validatedChannelsLength.valid && validateDefaultChannel.valid && validateBestTimeToSendNode.valid,
        errors: [...validatedChannels, validatedChannelsLength, validateDefaultChannel, validateBestTimeToSendNode],
      }
    }

    return {
      ...result
    }
  }

  return {
    ...node,
    validated: true,
    validation: resolveValidation(node.name, node),
  }
};
