import { useState, useCallback, useEffect } from 'react';

const THRESHOLD = 10;

const useSelection = (containerRef, itemsRefs) => {
  const [start, setStart] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleMouseDown = useCallback((event) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setStart({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top
      });
    }
  }, [containerRef]);

  const handleWindowKeyDown = (event) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleWindowKeyDown);

    return () => {
      window.removeEventListener('keydown', handleWindowKeyDown);
    }
  }, []);

  const handleMouseMove = useCallback((event) => {
    if (!start || !containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const dx = event.clientX - start.x - rect.left;
    const dy = event.clientY - start.y - rect.top;
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance < THRESHOLD) {
      return;
    }

    const currentX = event.clientX - rect.left;
    const currentY = event.clientY - rect.top;

    const minX = Math.min(start.x, currentX);
    const maxX = Math.max(start.x, currentX);
    const minY = Math.min(start.y, currentY);
    const maxY = Math.max(start.y, currentY);

    const newSelectedItems = Object.entries(itemsRefs.current).filter(([_, ref]) => !!ref).filter(([_, ref]) => {
      const itemRect = ref.getBoundingClientRect();

      return itemRect.left < rect.left + maxX &&
        itemRect.right > rect.left + minX &&
        itemRect.top < rect.top + maxY &&
        itemRect.bottom > rect.top + minY;
    }).map(([id]) => id);

    if (newSelectedItems.join('') !== selectedItems.join('')) {
      setSelectedItems(newSelectedItems);
    }
  }, [start, itemsRefs.current, containerRef, selectedItems, setSelectedItems]);

  const handleMouseUp = useCallback((event) => {
    event.preventDefault();
    if (start) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const dx = event.clientX - start.x - containerRect.left;
      const dy = event.clientY - start.y - containerRect.top;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < THRESHOLD) {
        setSelectedItems([]);
      }
    }

    setStart(null);
  }, [start]);

  return {
    isSelecting: !!start,
    selectedItems,
    setSelectedItems,
    start,
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
  };
};

export default useSelection;
