import { WorkspacesActionTypes } from '@store/actions/types';

import { UserActionTypes } from '../actions/types';

const initialState = {
  authenticated: false,
  user: null,
  users: {
    loading: false,
  },
  loading: false,
  allUsers: [],
  usersList: [],
  cachedUsersToInvite: null,
  dashboard_analytics_options: {
    loading: false,
  },
  singleUser: {
    data: {},
    loading: false,
  },
  twoFA: {
    loading: false,
    qrCodeSvg: null,
    secretCode: null,
    recoveryCodes: [],
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case UserActionTypes.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case UserActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          loading: false,
          ...action.payload,
        },
      };
    case UserActionTypes.GET_USERS_ERROR:
      return {
        ...state,
        users: {
          loading: false,
          ...state.users,
        },
      };
    case UserActionTypes.GET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case UserActionTypes.GET_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserActionTypes.GET_USER_SUCCESS:
      if (!state.authenticated) {
        return state;
      }

      return {
        ...state,
        loading: false,
        user: {
          data: action.payload,
        },
      };
    case UserActionTypes.GET_USER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.START_TRIAL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        user: {
          ...state.user,
          data: action.payload.user,
        },
        authenticated: true,
      };
    case UserActionTypes.TWO_FA_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        user: {
          ...state.user,
          data: action.payload.user,
          loading: false,
        },
        authenticated: true,
      };
    case UserActionTypes.SET_CACHED_USERS_TO_INVITE:
      return {
        ...state,
        cachedUsersToInvite: action.payload.cachedUsers,
      };
    case UserActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        user: {
          ...state.user,
          access_token: action.payload.access_token,
          data: action.payload.user,
          loading: false,
        },
        authenticated: !action.payload?.two_factor,
      };
    case 'clear-token':
      return {
        ...state,
        access_token: '123',
      }
    case UserActionTypes.REFRESH_TOKEN:
      return {
        ...state,
        refreshing: true,
      };
    case UserActionTypes.START_REFRESHING:
      return {
        ...state,
        refreshing: true,
      };
    case UserActionTypes.STOP_REFRESHING:
      return {
        ...state,
        refreshing: false,
      };
    case UserActionTypes.REFRESH_TOKEN_SUCCESS:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case UserActionTypes.REGISTER_SUCCESS:
      if (action.payload.user.id) {
        return {
          ...state,
          ...action.payload,
          user: {
            ...state.user,
            ...(action.payload.user || {}),
            data: action.payload.user,
          },
        }
      }

      return {
        ...state,
        ...action.payload,
      };
    case UserActionTypes.REFRESH_TOKEN_ERROR:
      return {
        ...state,
      };
    case UserActionTypes.GET_SINGLE_USER:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          loading: true,
        },
      };
    case UserActionTypes.GET_SINGLE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const [user] = action.payload.data;
      return {
        ...state,
        singleUser: {
          data: user,
          loading: false,
        },
      };
    case UserActionTypes.GET_SINGLE_ERROR:
      return {
        ...state,
        singleUser: {
          data: null,
          loading: false,
        },
      };
    case UserActionTypes.CLEAR_SINGLE_USER:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          data: null,
          loading: false,
        },
      };
    case UserActionTypes.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: {
          data: action.payload
        },
      };
    case UserActionTypes.TWO_FA_QR_CODE:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          loading: true,
        },
      };
    case UserActionTypes.TWO_FA_QR_CODE_SUCCESS:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          qrCodeSvg: action.payload.svg,
          secretCode: action.payload.secret_code,
          loading: false,
        },
      };
    case UserActionTypes.TWO_FA_QR_CODE_ERROR:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          qrCodeSvg: null,
          secretCode: null,
          loading: false,
        },
      };
    case UserActionTypes.TWO_FA_RECOVERY_CODES_SUCCESS:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          recoveryCodes: action.payload,
        },
      };
    case UserActionTypes.TWO_FA_RECOVERY_CODES_ERROR:
      return {
        ...state,
        twoFA: {
          ...state.twoFA,
          recoveryCodes: [],
        },
      };
    case UserActionTypes.GET_DASHBOARD_ANALYTICS_OPTIONS:
      return {
        ...state,
        dashboard_analytics_options: {
          ...state.dashboard_analytics_options,
          loading: true,
        },
      };
    case UserActionTypes.GET_DASHBOARD_ANALYTICS_OPTIONS_SUCCESS:
      return {
        ...state,
        dashboard_analytics_options: {
          ...state.dashboard_analytics_options,
          data: action.payload,
          loading: false,
        },
      };
    case UserActionTypes.GET_DASHBOARD_ANALYTICS_OPTIONS_ERROR:
      return {
        ...state,
        dashboard_analytics_options: {
          ...state.dashboard_analytics_options,
          loading: false,
        },
      };
    case UserActionTypes.UPDATE_DASHBOARD_ANALYTICS_OPTIONS:
      return {
        ...state,
        dashboard_analytics_options: {
          ...state.dashboard_analytics_options,
          ...action.payload.options,
        }
      };
    case UserActionTypes.LOGOUT_SUCCESS:
    case UserActionTypes.LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state;
  }
}
