import { Container } from './styled';

import { Placeholder } from '../Placeholder';
import { PreviewButton } from '../PreviewButton';
import { PreviewContent } from '../PreviewContent';
import { buttonSizeMap } from '../PreviewContent/PreviewContent';
import { ButtonContainer } from '../PreviewContent/styled';

export const Modal = ({
  title,
  message,
  shouldShowTextContent,
  buttons,
  layout,
  imageOnly,
  imageUrl,
}) => {
  const hasButtonsName = buttons?.some((button) => button.name);

  const renderButtons = buttons?.map((button) => {
    if (!button.name) {
      return null;
    }

    return (
      <PreviewButton key={button.name} size={buttonSizeMap['common']} type={button.type}>
        {button.name}
      </PreviewButton>
    );
  });

  const descriptionConfig = {
    'text': `29:10 aspect ratio \n Hi-Res Max 1450x500px \n PNG, JPEG, GIF`,
    'image': "Any aspect ratio Message resizes to fit images of most sizes Hi-Res Max 1200x1200px Min. 600x600px PNG,  JPEG, GIF"
  };
  
  return (
    <Container>
      <PreviewContent
        type={'common'}
        shouldShowTextContent={shouldShowTextContent}
        title={title}
        description={message}
        imageOnly={imageOnly}
        imageUrl={imageUrl}
        layout={layout}
        placeholder={
          <Placeholder
            type={'common'}
            layoutType={`common_${layout}`}
            title={'Image recomendations'}
            description={descriptionConfig[layout]}
          />
        }
        actionButton={
          hasButtonsName && (
            <ButtonContainer $size={buttonSizeMap['common']}>
              {renderButtons}
            </ButtonContainer>
          )
        }
      />
    </Container>
  );
};
