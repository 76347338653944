import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  > div:first-of-type {
    width: 100%;
  }
  .popover-sidebar {
    top: 0 !important;
    border-radius: 0 0 5px 0 !important;
  }
`;

export const WorkspaceAvatar = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

export const Row = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
