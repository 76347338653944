import { AutoComplete as ANTDAutoComplete } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  ${styledProp('fullWidth', `
    width: 100% !important;
  `)}
  ${styledProp('disabled', `
    pointer-events: none;
  `)}
`;

export const PlusContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 21px;
  right: 1px;
  width: 35px;
  height: 34px;
  border-radius: 0 6px 6px 0;
  border-left: 1px solid #DCDFE6;
  display: flex;
  justify-content: center;
  align-items: center;
    background: #FFFFFF;
    
    ${styledProp('$labeled', css`
        top: 21px;
    `, css`
        top: 1px;
    `)}
  
  ${styledProp('$sm', css`
    height: 24px;
  `)}
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;
`;

export const Overlay = styled.div`
  background: #FFFFFF;
  max-width: 186px;
  overflow-y: scroll;
  //width: 186px;
  max-height: 191px;
  border: 1px solid #DCDFE6;
  padding: 3px 14px 16px 14px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
`;

export const DropdownItem = styled.div`
  cursor: pointer;
  margin-top: 13px;
`;

export const StyledAutoComplete = styled(ANTDAutoComplete)`
    border-radius: 6px;

   ${styledProp('fullWidth', `
        width: 100% !important;
  `)}

   ${styledProp('eventSubFilter', `
        width: 250px !important;
  `)}

   ${styledProp('twoValues', `
        width: 90px !important;
  `)}

   ${styledProp('isNodeSettings', `
        width: 220px !important;
  `)}

   ${styledProp('$isFilterSplit', `
        width: 100px !important;
  `)}

   ${styledProp('$isStepper', `
        width: 210px !important;
  `)}
   
   ${styledProp('isTwoValues', `
        width: 100px !important;
  `)}
   
   .ant-select-selection-placeholder {
     color: #909399;
   }

  & > div {
    border: 1px solid #DCDFE6 !important;
    box-sizing: border-box !important;
    border-radius: 7px !important;
    height: 36px !important;
    font-size: 13px !important;
    line-height: 120% !important;
    text-overflow: ellipsis !important;
    padding-right: 24px !important;
    padding-left: 13px !important;
    min-width: 186px !important;
    box-shadow: none !important;
    display: flex;
    align-items: center;

    ${styledProp('twoValues', `
        min-width: 90px !important;
  `)}
    
    ${styledProp('disabled', `
      background : #f5f5f5;
    `)}
    ${styledProp('large', `
      width: 345px !important;
    `)}
    
    ${styledProp('fullWidth', `
      width: 100% !important;
    `)}

    ${styledProp('tiny', `
      min-width: 100px !important;
      width: 100px !important;
    `)}

    ${styledProp('$smaller', css`
      height: 26px !important;
      & input {
        height: 26px !important;
      }
    `)}
    
    ${styledProp('error', css`
      border-color: #C8402F !important;
      color: #C8402F !important;
    `)}

    &:focus {
      box-shadow: none !important;
      border-color: #31C447 !important;
    }
  }
`;

export const Error = styled.div`
  position: absolute;
  right: 9px;
  bottom: ${props => (props.smaller ? `6px` : `10px`)};

  ${styledProp('smaller', css`
    /* bottom: 0; */
  `)}

  ${styledProp('left', css`
    right: 44px;
  `)}
  
  ${styledProp('$ll', css`
    right: 32px;
  `)}
`;

export const Title = styled.div`
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 100%;
  color: #909399;
  padding-left: 4px;
  margin-bottom: 7px;
`;

export const Loader = styled.div`
  position: absolute;
  right: 9px;
  bottom: 8px;

  ${styledProp('smaller', css`
    bottom: 2px;
  `)}

  ${styledProp('left', css`
    right: 44px;

    ${styledProp('$error', css`
      right: 58px;
    `)}
  `)}
  
  ${styledProp('$error', css`
    right: 25px;
  `)}
`;

export const ArrowContainer = styled.div`
  position: absolute;
    bottom: 10px;
    right: 10px;
`;
