import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;

  ${styledProp('$appearance', css`
    width: 100%;
    padding: 16px 10px 10px 10px;
  `)}
`;

export const FooterOptionLabel = styled.div`
  margin-left: 5px;
`;

export const FooterOption = styled.div`
  display: flex;
  margin-left: -12px;
  padding: 10px 14px;
`;

export const Row = styled.div`
  display: flex;
  ${styledProp('$appearance', css`
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
  `)}
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 14px;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  ${styledProp('disabled', css`
    color: #909399;
  `)}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #DCDFE6;
  margin: 20px 0;
`;

export const DefaultContainer = styled.div`
  border-top: 1px solid #D3DBE7;
    margin: 20px -20px -50px;
    
`;

export const DefaultSettingsContainer = styled.div`
    padding: 0 20px 20px;
`;

export const DefaultAnimatedContainer = styled(animated.div)`
`;

export const DefaultHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
`;

export const DefaultTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    color: var(--text-primary-body, #1A232E);
    font-feature-settings: 'liga' off, 'clig' off;
    text-overflow: ellipsis;

    font-family: Manrope-SemiBold;
    font-size: 16px;
    line-height: 21px; 
`;

export const InfoIcon = styled.div`
  cursor: help;
`;

export const ToggleButton = styled.div`
    display: flex;
    align-items: center;
    gap: var(--buttons-big-space, 6px);
    color: var(--buttons-primary-bg, #31C447);
    text-align: center;

    font-family: Manrope-SemiBold;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
`;
