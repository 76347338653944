import { Container, Title, Description } from './styled';

export const Placeholder = ({ type, layoutType, title, description }) => {

  return (
    <Container $type={type} $layoutType={layoutType}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};
