import {
  getDefaultLang
} from '@utils';

import { ViberButton, ViberImage, ViberPreviewComponent } from "./styled";

export const ViberPreview = ({ data }) => {
  const defaultTemplate = getDefaultLang(data.languages);
  const imageUrl = defaultTemplate?.image_url || defaultTemplate.image?.url;

  return (
    <ViberPreviewComponent>
      <div style={{ padding: '0 14px' }}>{defaultTemplate.data}</div>
      {imageUrl && <ViberImage src={imageUrl} />}
      {!!defaultTemplate.btn_name && defaultTemplate.btn_name !== 'null' ? (
        <ViberButton>{defaultTemplate.btn_name}</ViberButton>
      ) : null}
    </ViberPreviewComponent>
  );
};
