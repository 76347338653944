import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import { updateOnboarding } from "@store/actions/creators";
import { hintModeSelector, workflowListSelector } from "@store/selectors";

import { useTranslation, useNavigate } from "@hooks";

import { Paths, UserPermissions } from "@constants";

import { moment } from "@utils";

import { LaunchCell, StatusCell } from '@components';

import { ActivityRow, Container, DateCell, WorkflowName } from "./styled";

import { RioService } from "../../../../../../services";
import { OptionalItem } from "../OptionalItem";
import { RecentActivityTable } from "../RecentActivityTable";

const RecentWorkflowTable = () => {
  const workflows = useSelector(workflowListSelector);
  const hintMode = useSelector(hintModeSelector);

  const { p } = useTranslation('dashboard_page');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleWorkflowLinkClick = () => {
    navigate(Paths.WORKFLOWS);
  };

  const handleCreateWorkflowClick = (fromLastTemplates) => {
    RioService.track('workflow_dash');
    if (hintMode && typeof fromLastTemplates !== 'boolean') {
      navigate(Paths.WORKFLOW_TEMPLATES);
      dispatch(updateOnboarding('workflows', {
        step: 2,
      }));
    } else {
      navigate(Paths.WORKFLOW_TEMPLATES);
    }
  };

  const renderWorkflowRow = ({ name, status, schedule, updated_at, id }, index, arr) => {
    return (
      <ActivityRow data-testid={`recent-workflow-row-${name}`} key={id} bordered={index < arr.length - 1}>
        <div style={{ width: '40%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <WorkflowName data-testid={`recent-workflow-item-name-${name}`} to={`${Paths.EDIT_WORKFLOW}/${id}/editor`}>{name}</WorkflowName>
        </div>
        <div style={{ flex: 1 }}>
          <StatusCell data-testid={`recent-workflow-item-status-${status}`} status={status} />
        </div>
        <div style={{ flex: 1 }}>
          <LaunchCell schedule={schedule} />
        </div>
        <div style={{ flex: 1 }}>
          <DateCell>{moment(updated_at).format('YYYY-MM-DD')}</DateCell>
        </div>
      </ActivityRow>
    );
  };

  return (
    <Container data-testid="dashboard-recent-workflow">
      <OptionalItem
        shouldRender={!!workflows.meta?.total}
        tooltip={p('tab_after_workflow')}
        fullWidth
        content={(
          <RecentActivityTable
            viewPermission={UserPermissions.REVIEW_WORKFLOWS}
            linkPath={Paths.WORKFLOWS}
            createPermission={UserPermissions.CREATE_WORKFLOWS}
            onLinkClick={handleWorkflowLinkClick}
            onCreateClick={() => handleCreateWorkflowClick(true)}
            data={workflows?.data || []}
            total={!!workflows.meta?.total}
            renderItem={renderWorkflowRow}
            link={p('all_workflows')}
            title={p('last_workflows')}
            containerStyle={{ marginRight: '10px', pointerEvents: !!workflows.meta?.total ? 'auto' : 'none' }}
          />
        )}
      />
    </Container>
  )
}

export default React.memo(RecentWorkflowTable);
