export const CommentIconSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M15.125 18.6861L13.3444 16.9056L12.375 17.875L15.125 20.625L20.625 15.125L19.6556 14.1556L15.125 18.6861Z'
        fill='#7C94B1'
      />
      <path d='M11 11.6875H6.1875V13.0625H11V11.6875Z' fill='#7C94B1' />
      <path d='M14.4375 8.25H6.1875V9.625H14.4375V8.25Z' fill='#7C94B1' />
      <path d='M14.4375 4.8125H6.1875V6.1875H14.4375V4.8125Z' fill='#7C94B1' />
      <path
        d='M11 20.625H4.125C3.36669 20.625 2.75 20.0082 2.75 19.25V2.75C2.75 1.99182 3.36669 1.375 4.125 1.375H16.5C17.2583 1.375 17.875 1.99182 17.875 2.75V13.0625H16.5V2.75H4.125V19.25H11V20.625Z'
        fill='#7C94B1'
      />
    </svg>
  );
};
