import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getHorizonBonuses,
  getHorizonFreeBets,
  getHorizonFreeSpins, getHorizonTags
} from '@store/actions/creators';
import { WITHOUT_SEARCH } from '@store/reducers/workflow';
import {
  horizonBonusesSelector, horizonCurrenciesSelector,
  horizonFreeBetsSelector,
  horizonFreeSpinsSelector, horizonTagsSelector
} from '@store/selectors';

import { Checkbox } from 'antd';
import uniqBy from 'lodash.uniqby';

import { useTranslation } from '@hooks';

import { by, update } from '@utils';

import { QuerySelect, SearchSelect } from '@components';
import { NodeSettingsAccordion } from '@components/lib/WorkflowEditor/components';
import {
  RewardTemplatePreview
} from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/GiveRewardNodeSettings/components/RewardTemplatePreview';
import RewardTypes
  from '@components/lib/WorkflowEditor/components/NodeSettingsModal/components/GiveRewardNodeSettings/rewardTypes';

import { LoyaltyPointsSettings, MoneyRewardSettings, TagRewardSettings } from './components';
import { Container, Content, Title, Description, ClaimableOption } from './styled';

const RewardTypeOptions = [
  {
    value: RewardTypes.BONUS,
    label: 'Bonus'
  },
  {
    value: RewardTypes.FREE_SPIN,
    label: 'Free spin'
  },
  {
    value: RewardTypes.FREE_BET,
    label: 'Free bet'
  },
  {
    value: RewardTypes.CASHBACK,
    label: 'Cashback'
  },
  {
    value: RewardTypes.MONEY_REWARD,
    label: 'Money reward'
  },
  {
    value: RewardTypes.LOYALTY_POINTS,
    label: 'Loyalty points reward',
  },
  {
    value: RewardTypes.TAG,
    label: 'Tag reward',
  }
];

const SkipTemplateSettings = [RewardTypes.CASHBACK, RewardTypes.MONEY_REWARD, RewardTypes.LOYALTY_POINTS, RewardTypes.TAG];

const GiveRewardNodeSettings = ({
  value,
  onChange,
  errors,
  onClearError,
}) => {
  const { p } = useTranslation('workflow_page');
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const bonuses = useSelector(horizonBonusesSelector);
  const currentBonusOptions = bonuses[search || WITHOUT_SEARCH];
  const bonusOptions = (currentBonusOptions?.data || []).map(({ name, id }) => ({ value: id, label: name }));
  const freeSpins = useSelector(horizonFreeSpinsSelector);
  const currentFreeSpinOptions = freeSpins[search || WITHOUT_SEARCH];
  const freeSpinOptions = (currentFreeSpinOptions?.data || []).map(({ name, id }) => ({ value: id, label: name }));
  const freeBets = useSelector(horizonFreeBetsSelector);
  const currentFreeBetOptions = freeBets[search || WITHOUT_SEARCH];
  const freeBetOptions = (currentFreeBetOptions?.data || []).map(({ name, id }) => ({ value: id, label: name }));
  const currencies = useSelector(horizonCurrenciesSelector);
  const tags = useSelector(horizonTagsSelector);
  const currentTagsOptions = tags[search || WITHOUT_SEARCH];
  const tagsOptions = (currentTagsOptions?.data || []).map(({ name, id }) => ({ value: name, label: name }));

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  const handleRewardTypeChange = (reward_type) => {
    onChange(s => ({
      ...s,
      reward_type,
      reward_id: null,
      reward_data: null,
      ...(reward_type === RewardTypes.LOYALTY_POINTS ? {
        reward_action: 'ADD',
      } : {}),
      ...(reward_type === RewardTypes.TAG ? {
        tags_rank: 0,
        tags: [{
          name: '',
          rank: 0,
        }]
      } : {})
    }));
  };

  useEffect(() => {
    if (!value.reward_type) {
      return;
    }

    if (value.reward_type === RewardTypes.TAG) {
      dispatch(getHorizonTags({ search, page: 0 }));
      return;
    }

    dispatch(getHorizonBonuses({ search, page: 0 }));
    dispatch(getHorizonFreeSpins({ search, page: 0 }));
    dispatch(getHorizonFreeBets({ search, page: 0 }));
  }, [search, value.reward_type]);

  const onScrollToEnd = () => {
    if (value.reward_type === RewardTypes.BONUS) {
      dispatch(getHorizonBonuses({ search, page: (isNaN(currentBonusOptions.page) ? 0 : currentBonusOptions.page) + 1 }));
    }
    if (value.reward_type === RewardTypes.FREE_SPIN) {
      dispatch(getHorizonFreeSpins({ search, page: (isNaN(currentFreeSpinOptions.page) ? 0 : currentFreeSpinOptions.page) + 1 }));
    }
    if (value.reward_type === RewardTypes.FREE_BET) {
      dispatch(getHorizonFreeBets({ search, page: (isNaN(currentFreeBetOptions.page) ? 0 : currentFreeBetOptions.page) + 1 }));
    }
    if (value.reward_type === RewardTypes.TAG) {
      dispatch(getHorizonTags({ search, page: (isNaN(currentTagsOptions.page) ? 0 : currentTagsOptions.page) + 1 }));
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight) {
      onScrollToEnd();
    }
  };

  const resolveOptions = () => {
    if (value.reward_type === RewardTypes.BONUS) {
      return bonusOptions;
    }
    if (value.reward_type === RewardTypes.FREE_SPIN) {
      return freeSpinOptions;
    }
    if (value.reward_type === RewardTypes.FREE_BET) {
      return freeBetOptions;
    }
  };

  const handleRewardChange = (reward_id) => {
    let reward = null;

    if (value.reward_type === RewardTypes.BONUS) {
      reward = (currentBonusOptions?.data || []).find(by(reward_id))
    }
    if (value.reward_type === RewardTypes.FREE_SPIN) {
      reward = (currentFreeSpinOptions?.data || []).find(by(reward_id))
    }
    if (value.reward_type === RewardTypes.FREE_BET) {
      reward = (currentFreeBetOptions?.data || []).find(by(reward_id))
    }

    onChange(s => ({
      ...s,
      reward_id,
      reward_data: reward,
    }));
  };

  const handleMoneyRewardChange = (updater) => {
    onClearError('reward_money_mapping');
    return onChange(s => ({ ...s, reward_money_mapping: update(s.reward_money_mapping || [], updater) }));
  };

  const handleClaimableChange = ({ target: { checked } }) => {
    onChange(s => ({ ...s, reward_claimable: checked }));
  };

  const handleAmountChange = ({ target: { value } }) => {
    onClearError('reward_amount');
    onChange(s => ({ ...s, reward_amount: value }))
  };

  const handleActionChange = (action) => {
    onClearError('reward_action');
    onChange(s => ({ ...s, reward_action: action }))
  };

  const handleTagsActionChange = (action) => {
    onClearError('tags_action');
    onChange(s => ({ ...s, tags_action: action }))
  };

  const handleTagsChange = (tags) => {
    onClearError('reward_tags');
    onChange(s => ({ ...s, reward_tags: tags }));
  };

  const handleRankChange = ({ target: { value: rank } }) => {
    onClearError('tags_rank');
    onChange(s => ({ ...s, tags_rank: rank }));
  };

  return (
    <Container>
      <NodeSettingsAccordion
        requiredSettings={(
          <Content>
            <Title>{p('give_reward_settings_title')}</Title>
            <Description>{p('give_reward_settings_description')}</Description>
            <SearchSelect
              onChange={handleRewardTypeChange}
              value={value.reward_type}
              placeholder="Select type"
              title="Reward type"
              options={RewardTypeOptions}
              renderRightTitle={value.reward_type === RewardTypes.MONEY_REWARD && (
                <Checkbox style={{ marginBottom: 3 }} checked={!!value.reward_claimable} value={!!value.reward_claimable} onChange={handleClaimableChange}>
                  <ClaimableOption>
                    Claimable
                  </ClaimableOption>
                </Checkbox>
              )}
              wrapperStyles={{ width: 320 }}
              style={{ width: 320 }}
              containerStyle={{ width: 320 }}
            />
            {value.reward_type && !SkipTemplateSettings.includes(value.reward_type) && (
              <QuerySelect
                onChange={handleRewardChange}
                value={value.reward_id}
                onSearch={handleSearch}
                placeholder="Select reward"
                title="Specific reward"
                options={resolveOptions()}
                wrapperStyles={{ width: 320 }}
                style={{ width: 320 }}
                containerStyle={{ width: 320, marginTop: 16 }}
                onPopupScroll={handleScroll}
                footerOption={(
                  <div />
                )}
              />
            )}
            {!!value.reward_data && <RewardTemplatePreview type={value.reward_type} data={value.reward_data} />}
            {value.reward_type === RewardTypes.MONEY_REWARD && (
              <MoneyRewardSettings
                value={value.reward_money_mapping || []}
                errors={errors}
                onClearError={onClearError}
                onChange={handleMoneyRewardChange}
                currencyOptions={currencies.data || []}
              />
            )}
            {value.reward_type === RewardTypes.LOYALTY_POINTS && (
              <LoyaltyPointsSettings
                errors={errors}
                amount={value.reward_amount}
                onAmountChange={handleAmountChange}
                action={value.reward_action}
                onActionChange={handleActionChange}
              />
            )}
            {value.reward_type === RewardTypes.TAG && (
              <TagRewardSettings
                errors={errors}
                action={value.tags_action}
                tags={value.reward_tags || []}
                onActionChange={handleTagsActionChange}
                onTagsChange={handleTagsChange}
                onLoadMore={onScrollToEnd}
                onSearch={handleSearch}
                rank={value.tags_rank}
                onRankChange={handleRankChange}
                loading={tags.loading}
                tagsOptions={uniqBy((tagsOptions || []).concat((value.reward_tags || []).map(name => ({ value: name, label: name }))), ({ value }) => value)}
              />
            )}
          </Content>
        )}
      />
    </Container>
  );
};

export default GiveRewardNodeSettings;
