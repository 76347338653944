import React from 'react';

import { Tooltip } from 'antd';

import { Container } from './styled';

const OptionalItem = ({ content, shouldRender, tooltip, fullWidth }) => {
  if (shouldRender) {
    return content;
  }

  return (
    <Tooltip title={tooltip} getPopupContainer={(t) => t.parentElement}>
      <Container $fullWidth={fullWidth} $shouldRender={shouldRender}>
        {content}
      </Container>
    </Tooltip>
  )
};

export default React.memo(OptionalItem);
