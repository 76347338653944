import { Menu } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import { Button } from '@components/controls';

export const StyledButton = styled(Button)`
  &:hover {
    color: #fff;
    background: #31c447 !important;
  }
`;

export const MenuStyled = styled(Menu)`
  padding: 6px 1px;
  border-radius: 7px;
  border: 1px solid #d3dbe7;
  background: #fff;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
`;

export const ButtonStyles = styled;
export const IconContainer = styled.span`
  display: inline-flex;
`;
export const IconHoverContainer = styled.div`
  position: absolute;
  left: -11px;
  top: -14px;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 0;
  &:hover {
    transition: all 0.3s ease;
    opacity: 1;
  }
  svg {
    width: 215px;
    height: 70px;
  }
`;

export const MenuItemStyled = styled(Menu.Item)`
  color: #1a232e;
  font-family: Manrope-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  ${styledProp(
    '$type',
    css`
      color: #f04438;
    `
  )}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 10px;
`;

export const Title = styled.h3`
  color: #7c94b1;
  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 30px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Error = styled.span`
  color: var(--input-input-txt-danger, #F04438);
  font-feature-settings: 'clig' off, 'liga' off;
/* Body 5 */
  font-family: Manrope;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 145.455% */
`

export const Item = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 12px 15px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.3);
  cursor: pointer;
  position: relative;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  &:hover {
    border: 2px solid #31c447;
  }

  ${styledProp(
    '$active',
    css`
      border: 2px solid #31c447;
    `
  )}
  ${styledProp(
    '$error',
    css`
      border: 2px solid #f04438;
      &:hover {
        border: 2px solid #f04438;
      }
    `
  )}
`;
