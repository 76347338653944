import { useSelector } from 'react-redux';

import { nestedAggregatesListSelector } from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { SegmentEventOperatorOptions } from '@constants';

import { by, extract, update } from '@utils';
import { clickhouseFields2Events } from '@utils/fields';

import { SearchSelect } from '@components';

import { Container, Row } from './styled';

import { SubFilterRule } from '../SubFilterRule';

const OverrideLabels = {
  "nf_notification_id": 'Template',
  "nf_entity_id": 'Workflow version',
  "nf_sub_entity_id": 'Workflow node',
  "nf_event": 'Status',
};

const EventSelect = ({ query, onChange, showErrors, appearance }) => {
  const { p, t } = useTranslation('segments_page');
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const events = clickhouseFields2Events(clickhouseFields.data);
  const nestedAggregatesList = useSelector(nestedAggregatesListSelector);

  const options = events?.data?.map?.(e => ({ value: e.name, label: e.label }));

  const field = events?.data?.find?.(by('name', query.field));
  const nestedAggregates = field?.nested_aggregates?.map((nestedAggregatesId) => nestedAggregatesList?.data.find(({ id }) => nestedAggregatesId === id));

  const subOptions = field?.payload?.flatMap?.(({ field, label, type, payload }) =>
    [({ value: field, label: OverrideLabels[field] || label, type })]
      .concat(type === 'array' ? payload.map(({ field: innerField, label: innerLabel, type }) =>
        ({ value: innerField, label: innerLabel, type, prefix: label, nested: field, key: `${field}.${innerField}` })) : [])
  ).concat(nestedAggregates?.length ? nestedAggregates.map(({ label, name, id }) => ({ label, value: name, id, type: 'aggregate' })) : []) || [];
  const filters = query.filters?.flatMap((f) => f.type === 'array' ? f.filters.map(ff => ({ ...ff, nested: f.field })) : f);

  const handleFiltersChange = (changeFn) => {
    const changed = update({ query: { ...query, filters } }, changeFn);

    onChange(q => ({
      ...q,
      query: {
        ...changed.query,
        filters: changed.query.filters.reduce((acc, { nested, ...filter }) => {
          if (!nested) {
            return [...acc, filter];
          }

          const nestedFilterAt = acc.findIndex(({ field, filters }) => filters && field === nested);

          if (!!~nestedFilterAt) {
            return acc.map((f, i) => i === nestedFilterAt ? { ...f, filters: [...f.filters, filter] } : f);
          }

          return [
            ...acc,
            {
              type: 'array',
              field: nested,
              filters: [filter],
            }
          ]
        }, []),
      }
    }));
  };

  const handleFieldChange = (field) => {
    onChange(q => ({ ...q, query: { ...q.query, field, filters: [] } }));
  };

  const handleLogicalOperatorChange = (logicalOperator) => {
    if (logicalOperator === 'and' || logicalOperator === 'or' || logicalOperator === 'was_performed') {
      return onChange(q => ({
        ...q,
        query: {
          ...q.query,
          logicalOperator: logicalOperator === 'was_performed' ? 'and' : logicalOperator,
          wasPerformed: logicalOperator === 'was_performed',
          operator: undefined,
          filters: ((query.logicalOperator !== 'and' && query.logicalOperator !== 'or') || logicalOperator === 'was_performed') ?
            (q.query.filters || []).filter(extract('hidden'))
            : q.query.filters?.filter?.(({ hidden }) => !hidden)?.length ? q.query.filters : (q.query.filters || []).concat([{ field: '', operator: '', value: { type: 'scalar' } }]),
        }
      }));
    }

    onChange(q => ({
      ...q,
      query: {
        ...q.query,
        logicalOperator: undefined,
        operator: logicalOperator,
        filters: undefined,
      }
    }));
  };

  return (
    <Container $appearance={appearance} data-testid={`store-segment-field-event-container-${query.name}`}>
      <Row $appearance={appearance}>
        <SearchSelect
          testId={`store-segment-field-event-select-${query.name}`}
          style={{ width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
          options={options || []}
          label={p('event')}
          tooltipError={showErrors && query?.errors?.['field']}
          value={query?.field}
          onChange={handleFieldChange}
          getPopupContainer={t => t.parentElement.parentElement.parentElement}
        />
        <SearchSelect
          testId={`store-segment-field-event-operator-select-${query.name}`}
          wrapperStyles={{ marginLeft: appearance !== 'column' ? 6 : 0 }}
          label={t('labels.event_operator')}
          style={{ width: appearance !== 'column' ? 186 : 'auto', background: '#F9FBFF' }}
          options={SegmentEventOperatorOptions(p)}
          // eslint-disable-next-line no-mixed-operators
          tooltipError={showErrors && query?.errors?.['logicalOperator'] || query?.errors?.['operator']}
          value={query.wasPerformed ? 'was_performed' : query.logicalOperator || query.operator}
          onChange={handleLogicalOperatorChange}
          getPopupContainer={t => t.parentElement.parentElement.parentElement}
        />
      </Row>
      {((query.logicalOperator === 'and' || query.logicalOperator === 'or') && query.filters) && (
        <SubFilterRule
          testId={`store-segment-field-event-container-${query.name}-sub-filter`}
          options={subOptions}
          strictPayload
          appearance={appearance}
          validateDate
          nestedAggregates={nestedAggregates}
          showErrors={showErrors}
          disableFirstDelete={(query.logicalOperator === 'and' || query.logicalOperator === 'or') && !query.wasPerformed}
          value={filters}
          query={query}
          type="event"
          onChange={handleFiltersChange}
          getPopupContainer={t => t.parentElement}
        />
      )}
    </Container>
  );
};

export default EventSelect;
