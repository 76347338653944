import { CodeEditor, HTMLEditor, EmailMetaForm } from './components';
import { Container } from './styled';

const EmailTemplateEditor = ({ type, value, templateSettings, onChangeTemplateSettings, onChangeSubject, onChangeFallback, onChange, previewData, disabled, loaded, errors, id, isOTP }) => {
  const template = { ...value, previewBody: previewData?.data };
  
  return (
    <Container>
      <EmailMetaForm isOTP={isOTP} disabled={disabled} languageCode={value.language_code} subject={value?.subject} value={templateSettings} onChangeSubject={onChangeSubject} onChange={onChangeTemplateSettings} errors={errors} />
      {type === 'code' && (
        <CodeEditor templateType="email" value={template} onChange={onChange} errors={errors.errors} isOTP={isOTP} clearError={errors.clearError}/>
      )}
      {type === 'vce' && (
        <HTMLEditor loaded={loaded} onChangeFallback={onChangeFallback} value={template} id={id} errors={errors.errors} clearError={errors.clearError} isOTP={isOTP} />
      )}
    </Container>
  );
};

export default EmailTemplateEditor;
