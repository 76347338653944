const CampaignsActionTypes = {
  LIST: 'campaigns/LIST',
  LIST_SUCCESS: 'campaigns/LIST_SUCCESS',
  LIST_ERROR: 'campaigns/LIST_ERROR',

  LIST_CALENDAR: 'campaigns/LIST_CALENDAR',
  LIST_CALENDAR_SUCCESS: 'campaigns/LIST_CALENDAR_SUCCESS',
  LIST_CALENDAR_ERROR: 'campaigns/LIST_CALENDAR_ERROR',

  SAVE: 'campaigns/SAVE',
  SAVE_SUCCESS: 'campaigns/SAVE_SUCCESS',
  SAVE_ERROR: 'campaigns/SAVE_ERROR',

  EDIT: 'campaigns/EDIT',
  EDIT_SUCCESS: 'campaigns/EDIT_SUCCESS',
  EDIT_ERROR: 'campaigns/EDIT_ERROR',

  PUBLISH: 'campaigns/PUBLISH',
  PUBLISH_SUCCESS: 'campaigns/PUBLISH_SUCCESS',
  PUBLISH_ERROR: 'campaigns/PUBLISH_ERROR',

  SET_CACHED_CAMPAIGN: 'campaigns/SET_CACHED_CAMPAIGN',
  SET_CACHED_CAMPAIGN_SUCCESS: 'campaigns/SET_CACHED_CAMPAIGN_SUCCESS',
  SET_CACHED_CAMPAIGN_ERROR: 'campaigns/SET_CACHED_CAMPAIGN_ERROR',

  CLEAR_CACHED_CAMPAIGN: 'campaigns/CLEAR_CACHED',

  SET_EXTRA_CACHE: 'campaigns/SET_EXTRA_CACHE',
  SET_EXTRA_CACHE_ERROR: 'campaigns/SET_EXTRA_CACHE_ERROR',
  SET_EXTRA_CACHE_SUCCESS: 'campaigns/SET_EXTRA_CACHE_SUCCESS',

  GET_CAMPAIGN_CONTACTS_IDS: 'campaigns/GET_CONTACTS_IDS',
  GET_CAMPAIGN_CONTACTS_IDS_SUCCESS: 'campaigns/GET_CONTACTS_IDS_SUCCESS',
  GET_CAMPAIGN_CONTACTS_IDS_ERROR: 'campaigns/GET_CONTACTS_IDS_ERROR',

  GET_CAMPAIGN_CONTACTS: 'campaigns/GET_CAMPAIGN_CONTACTS',
  GET_CAMPAIGN_CONTACTS_SUCCESS: 'campaigns/GET_CAMPAIGN_CONTACTS_SUCCESS',
  GET_CAMPAIGN_CONTACTS_ERROR: 'campaigns/GET_CAMPAIGN_CONTACTS_ERROR',

  GET_CAMPAIGN_NOTES: 'campaigns/GET_NOTES',
  GET_CAMPAIGN_NOTES_SUCCESS: 'campaigns/GET_NOTES_SUCCESS',
  GET_CAMPAIGN_NOTES_ERROR: 'campaigns/GET_NOTES_ERROR',

  CREATE_CAMPAIGN_NOTE: 'campaign/CREATE_NOTE',
  CREATE_CAMPAIGN_NOTE_SUCCESS: 'campaign/CREATE_NOTE_SUCCESS',
  CREATE_CAMPAIGN_NOTE_ERROR: 'campaign/CREATE_NOTE_ERROR',

  DELETE_CAMPAIGN_NOTE: 'campaigns/DELETE_NOTE',
  DELETE_CAMPAIGN_NOTE_SUCCESS: 'campaigns/DELETE_NOTE_SUCCESS',
  DELETE_CAMPAIGN_NOTE_ERROR: 'campaigns/DELETE_NOTE_ERROR',

  STOP_CAMPAIGN: 'campaigns/STOP',
  STOP_CAMPAIGN_SUCCESS: 'campaigns/STOP_SUCCESS',
  STOP_CAMPAIGN_ERROR: 'campaigns/STOP_ERROR',

};

export default CampaignsActionTypes;
