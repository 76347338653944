import { ReducerKeys } from '@constants';

export const contactsSelector = (store) => store[ReducerKeys.CONTACTS].contacts;

export const contactsLoadingSelector = (store) => store[ReducerKeys.CONTACTS].contacts.loading;

export const importHistoryLoadingSelector = (store) => store[ReducerKeys.CONTACTS].importHistory.loading;

export const contactEventsSelector = (store) => store[ReducerKeys.CONTACTS].contactEvents;

export const contactEventsLoadingSelector = (store) => store[ReducerKeys.CONTACTS].contactEvents?.loading;

export const contactEventsTotalSelector = (store) => store[ReducerKeys.CONTACTS].contactEventsTotal;

export const importHistorySelector = (state) => state[ReducerKeys.CONTACTS].importHistory;

export const importMappingSelector = (store) => store[ReducerKeys.CONTACTS].importMapping;

export const importProgressSelector = (store) => store[ReducerKeys.CONTACTS].importProgress;

export const contactsImportStepSelector = (store) => store[ReducerKeys.CONTACTS].importMetadata.step;

export const currentContactInfoSelector = (store) => store[ReducerKeys.CONTACTS].currentContactInfo;

export const smsSummarySelector = store => store[ReducerKeys.CONTACTS].currentContactInfo.summary?.sms;

export const emailSummarySelector = store => store[ReducerKeys.CONTACTS].currentContactInfo.summary?.email;
export const webhookSummarySelector = store => store[ReducerKeys.CONTACTS].currentContactInfo.summary?.webhook;
export const webpushSummarySelector = store => store[ReducerKeys.CONTACTS].currentContactInfo.summary?.push;

export const colsSelector = store => store[ReducerKeys.CONTACTS].cols;

export const currentFieldSelector = store => store[ReducerKeys.CONTACTS].currentFieldInfo;

export const currentFieldLoadingSelector = store => store[ReducerKeys.CONTACTS].currentFieldInfo.loading;

export const colsOptionsSelector = store => store[ReducerKeys.CONTACTS].colsOptions;

export const createCustomersCountSelector = type => store => store[ReducerKeys.CONTACTS].count[type] || {};

export const aggregatesListSelector = store => store[ReducerKeys.CONTACTS].aggregates;
