/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getEmails,
  getSms,
  getWebhook,
  getTemplates,
  getWebpush,
  getPromoCodesNotificationHistory,
  getMobilePushes,
  getFacebookNotificationHistory,
  getNotificationHistoryCount,
  getIncludeCampaignNotificationHistory,
  getGiveRewardNotificationHistory,
  getContentCardsNotificationHistory, getWebPopupsNotificationHistory
  , getVibers } from '@store/actions/creators';
import {
  notificationHistoryCountsSelector,
  templateSelector,
} from '@store/selectors';

import { Button } from '@velitech/ui';
import { StringParam, useQueryParam } from 'use-query-params';

import { useFilters, useTable, useTranslation } from '@hooks';

import {
  EmailStatusOptions,
  FacebookAudiencesStatusOptions, GiveRewardStatusOptions, IncludeCampaignStatusOptions,
  MobilePushStatusOptions,
  SmsStatusOptions,
  WebhookStatusOptions, WebPopupStatusOptions,
  WebpushStatusOptions
} from '@constants';
import { ContentCardStatusOptions } from '@constants/contentCardsStatuses';
import { TemplateTypes } from '@constants/templates';
import { ViberStatusOptions } from '@constants/viberStatuses';

import { moment , identity } from '@utils';

import {
  DatePicker,
  Header,
  Input,
  HeaderPagination,
  IconButton,
  Multiselect,
  SearchSelect,
  ContainerLayout,
  WrapperContentStyled
} from '@components';
import {
  Container,
  DateFilter,
  DateInputsRow,
  FilterActions,
  FiltersCol,
  FiltersRow,
  SearchFilter,
} from '@components/lib/styled';

import { ContentCardTable } from './components/ContentCardTable';
import { EmailTable } from './components/EmailTable';
import { GiveRewardTable } from './components/GiveRewardTable';
import { IncludeCampaignTable } from './components/IncludeCampaignTable';
import { MobilePushTable } from './components/MobilePushTable';
import PromoCodesTable from './components/PromoCodesTable/PromoCodesTable';
import { SmsTable } from './components/SmsTable';
import { ViberTable } from './components/ViberTable';
import { WebhookTable } from "./components/WebhookTable";
import WebPopupsTable from './components/WebPopupsTable/WebPopupsTable';
import { WebpushTable } from './components/WebpushTable';
import { TABS } from './constants';
import {
  EMAIL_STATIC_COLS_OPTIONS,
  FACEBOOK_STATIC_COLS_OPTIONS,
  MOBILE_PUSH_STATIC_COLS_OPTIONS,
  SMS_STATIC_COLS_OPTIONS,
  tabs, VIBER_STATIC_COLS_OPTIONS,
  WEBHOOK_STATIC_COLS_OPTIONS,
  WEBPUSH_STATIC_COLS_OPTIONS,
} from './options';
import { FilterField } from './styled';

import { WithPermissions } from '../../../../components';
import { UserPermissions } from '../../../../constants';

const NotificationHistory = () => {
  const dispatch = useDispatch();
  const [queryTab, setQueryTab] = useQueryParam('tab', StringParam);
  const [tab, setTab] = useState(TABS.INITIAL);
  const { t, p } = useTranslation('notification_history');
  const counts = useSelector(notificationHistoryCountsSelector);

  useEffect(() => {
    if (queryTab) {
      setTab(queryTab);
    }
  }, []);

  const loadData = (params) => {
    if (tab === TABS.EMAIL) {
      dispatch(getEmails({ ...params }));
    } else if (tab === TABS.SMS) {
      dispatch(getSms({ ...params }));
    } else if (tab === TABS.WEBHOOK) {
      dispatch(getWebhook({ ...params }));
    } else if (tab === TABS.WEBPUSH) {
      dispatch(getWebpush({ ...params }));
    } else if (tab === TABS.PROMO_CODES) {
      dispatch(getPromoCodesNotificationHistory({ ...params }));
    } else if (tab === TABS.VIBER) {
      dispatch(getVibers({ ...params }))
    } else if (tab === TABS.MOBILE_PUSH) {
      dispatch(getMobilePushes({ ...params }))
    } else if (tab === TABS.FACEBOOK) {
      dispatch(getFacebookNotificationHistory({ ...params }))
    } else if (tab === TABS.INCLUDE_CAMPAIGN) {
      dispatch(getIncludeCampaignNotificationHistory({ ...params }));
    } else if (tab === TABS.GIVE_REWARD) {
      dispatch(getGiveRewardNotificationHistory({ ...params }));
    } else if (tab === TABS.CONTENT_CARDS) {
      dispatch(getContentCardsNotificationHistory({ ...params }));
    } else if (tab === TABS.WEB_POPUPS) {
      dispatch(getWebPopupsNotificationHistory({ ...params }));
    }
  }

  const emailFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const smsFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const webpushFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const mobilePushFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const webhookFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const promoCodesFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    date_type: '',
    '$type': [],

  });

  const facebookFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
  });

  const viberFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const giveRewardFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const includeCampaignFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const contentCardsFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const webPopupsFilters = useFilters({
    input_value: '',
    start_date: '',
    end_date: '',
    status: [],
    template_id: [],
    language_code: '',
  });

  const getFilters = () => {
    switch (tab) {
      case TABS.EMAIL:
        return emailFilters;
      case TABS.SMS:
        return smsFilters;
      case TABS.VIBER:
        return viberFilters;
      case TABS.WEBHOOK:
        return webhookFilters;
      case TABS.WEBPUSH:
        return webpushFilters;
      case TABS.PROMO_CODES:
        return promoCodesFilters;
      case TABS.MOBILE_PUSH:
        return mobilePushFilters;
      case TABS.FACEBOOK:
        return facebookFilters;
      case TABS.GIVE_REWARD:
        return giveRewardFilters;
      case TABS.INCLUDE_CAMPAIGN:
        return includeCampaignFilters;
      case TABS.CONTENT_CARDS:
        return contentCardsFilters;
      case TABS.WEB_POPUPS:
        return webPopupsFilters;
      default:
    }
  }

  const filters = getFilters();

  const entity = {
    [TABS.EMAIL]: TemplateTypes.EMAIL,
    [TABS.SMS]: TemplateTypes.SMS,
    [TABS.VIBER]: TemplateTypes.VIBER,
    [TABS.WEBHOOK]: TemplateTypes.WEBHOOK,
    [TABS.WEBPUSH]: TemplateTypes.WEB_PUSH,
    [TABS.PROMO_CODES]: 'discount',
    [TABS.MOBILE_PUSH]: TemplateTypes.MOBILE_PUSH,
    [TABS.FACEBOOK]: 'facebook',
    [TABS.GIVE_REWARD]: 'rewards',
    [TABS.INCLUDE_CAMPAIGN]: 'include-campaigns',
    [TABS.CONTENT_CARDS]: TemplateTypes.MESSAGE_FEED,
    [TABS.WEB_POPUPS]: TemplateTypes.WEB_POPUP,
  }[tab];

  const table = useTable({
    filtersConfig: filters,
    initialOrdering: 'created_at',
    outerFilters: filters,
    startSearchAt: 0,
    searchFilter: 'input_value',
    deps: [tab],
    metaDeps: [tab],
    metaDispatcher: (params) => {
      dispatch(getNotificationHistoryCount({ entity, ...params }));
    },
    requestDispatcher: (params) => {
      loadData({ ...params });
    },
  });

  const [cols, setCols] = useState(tab === TABS.EMAIL ? EMAIL_STATIC_COLS_OPTIONS : SMS_STATIC_COLS_OPTIONS);
  const [colsOptions, setColsOptions] = useState(tab === TABS.EMAIL ? EMAIL_STATIC_COLS_OPTIONS : SMS_STATIC_COLS_OPTIONS);

  const handleChangeTab = (newTab) => {
    if (newTab === tab) {
      return;
    }

    setTab(newTab);
    setQueryTab(newTab);
    if (newTab === TABS.EMAIL) {
      setColsOptions(EMAIL_STATIC_COLS_OPTIONS);
    } else if (newTab === TABS.SMS) {
      setColsOptions(SMS_STATIC_COLS_OPTIONS)
    } else if (newTab === TABS.WEBHOOK) {
      setColsOptions(WEBHOOK_STATIC_COLS_OPTIONS)
    } else if (newTab === TABS.WEBPUSH) {
      setColsOptions(WEBPUSH_STATIC_COLS_OPTIONS);
    } else if (newTab === TABS.VIBER) {
      setColsOptions(VIBER_STATIC_COLS_OPTIONS);
    } else if (newTab === TABS.MOBILE_PUSH) {
      setColsOptions(MOBILE_PUSH_STATIC_COLS_OPTIONS);
    } else if (newTab === TABS.FACEBOOK) {
      setColsOptions(FACEBOOK_STATIC_COLS_OPTIONS);
    }

    table.pagination.handlers.onPerPageChange((20));
    table.pagination.handlers.onPageChange(0);
    table.changeSearch('');
    table.discardWithoutRefresh();
  }

  const getStatusOptions = () => ({
    [TABS.SMS]: SmsStatusOptions,
    [TABS.EMAIL]: EmailStatusOptions,
    [TABS.WEBHOOK]: WebhookStatusOptions,
    [TABS.WEBPUSH]: WebpushStatusOptions,
    [TABS.VIBER]: ViberStatusOptions,
    [TABS.PROMO_CODES]: WebhookStatusOptions,
    [TABS.FACEBOOK]: FacebookAudiencesStatusOptions,
    [TABS.MOBILE_PUSH]: MobilePushStatusOptions,
    [TABS.GIVE_REWARD]: GiveRewardStatusOptions,
    [TABS.INCLUDE_CAMPAIGN]: IncludeCampaignStatusOptions,
    [TABS.CONTENT_CARDS]: ContentCardStatusOptions,
    [TABS.WEB_POPUPS]: WebPopupStatusOptions,
  })[tab];

  const keyExtractor = ({ id }) => id;

  const count = counts[entity];

  return (
    <Container style={{ padding: '50px 0 0 0' }}>
      <Header
        opened={table.filters.opened}
        tabs={tabs}
        tab={tab}
        tabTestIdPrefix="notification-history"
        onTabChange={handleChangeTab}
        title={p('title')}
      />
      { tab === TABS.EMAIL ? <EmailTable total={count?.total} ordering={table.ordering} filter={table.filters.filters['input_value']} keyExtractor={keyExtractor} cols={cols} table={table} statusOptions={getStatusOptions(tab)} /> : null }
      { tab === TABS.SMS ? <SmsTable total={count?.total} ordering={table.ordering} keyExtractor={keyExtractor} filter={table.filters.filters['input_value']} table={table} statusOptions={getStatusOptions(tab)} cols={cols} /> : null }
      { tab === TABS.WEBHOOK ? <WebhookTable total={count?.total} ordering={table.ordering} filter={table.filters.filters['input_value']} table={table} statusOptions={getStatusOptions(tab)} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.WEBPUSH ? <WebpushTable total={count?.total} ordering={table.ordering} filter={table.filters.filters['input_value']} table={table} statusOptions={getStatusOptions(tab)} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.PROMO_CODES ? <PromoCodesTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.VIBER ? <ViberTable filter={table.filters.filters['input_value']} table={table} statusOptions={getStatusOptions(tab)} total={count?.total} ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.MOBILE_PUSH ? <MobilePushTable total={count?.total} filter={table.filters.filters['input_value']} table={table} statusOptions={getStatusOptions(tab)} cols={cols} ordering={table.ordering} keyExtractor={keyExtractor} /> : null }
      {/*{ tab === TABS.FACEBOOK ? <FacebookTable ordering={table.ordering} keyExtractor={keyExtractor} cols={cols} /> : null }*/}
      { tab === TABS.GIVE_REWARD ? <GiveRewardTable total={count?.total} ordering={table.ordering} table={table} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.INCLUDE_CAMPAIGN ? <IncludeCampaignTable total={count?.total} ordering={table.ordering} table={table} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.CONTENT_CARDS ? <ContentCardTable total={count?.total} ordering={table.ordering} table={table} keyExtractor={keyExtractor} cols={cols} /> : null }
      { tab === TABS.WEB_POPUPS ? <WebPopupsTable total={count?.total} ordering={table.ordering} table={table} keyExtractor={keyExtractor} cols={cols} /> : null }
    </Container>
  )
};

export default NotificationHistory;
