const UserActionTypes = {
  LOGIN: 'user/LOGIN',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
  LOGIN_ERROR: 'user/LOGIN_ERROR',

  START_REFRESHING: 'user/START_REFRESHING',
  STOP_REFRESHING: 'user/STOP_REFRESHING',

  REFRESH_TOKEN: 'user/REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'user/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'user/REFRESH_TOKEN_ERROR',

  SUSPEND_API_CALLS: 'user_SUSPEND_API_CALLS',
  RESUME_API_CALLS: 'user_SUSPEND_API_CALLS',

  REGISTER: 'user/REGISTER',
  REGISTER_SUCCESS: 'user/REGISTER_SUCCESS',
  REGISTER_ERROR: 'user/REGISTER_ERROR',

  RESEND_REGISTRATION_EMAIL: 'user/RESEND_REGISTRATION_EMAIL',
  RESEND_REGISTRATION_EMAIL_SUCCESS: 'user/RESEND_REGISTRATION_EMAIL_SUCCESS',
  RESEND_REGISTRATION_EMAIL_ERROR: 'user/RESEND_REGISTRATION_EMAIL_ERROR',

  SUBMIT_QUESTIONNAIRE: 'user/SUBMIT_QUESTIONNAIRE',
  SUBMIT_QUESTIONNAIRE_SUCCESS: 'user/SUBMIT_QUESTIONNAIRE_SUCCESS',
  SUBMIT_QUESTIONNAIRE_ERROR: 'user/SUBMIT_QUESTIONNAIRE_ERROR',

  SUBMIT_TRIAL_QUESTIONNAIRE: 'user/SUBMIT_TRIAL_QUESTIONNAIRE',
  SUBMIT_TRIAL_QUESTIONNAIRE_SUCCESS: 'user/SUBMIT_TRIAL_QUESTIONNAIRE_SUCCESS',
  SUBMIT_TRIAL_QUESTIONNAIRE_ERROR: 'user/SUBMIT_TRIAL_QUESTIONNAIRE_ERROR',

  START_TRIAL: 'user/START_TRIAL',
  START_TRIAL_SUCCESS: 'user/START_TRIAL_SUCCESS',
  START_TRIAL_ERROR: 'user/START_TRIAL_ERROR',

  RESET_PASSWORD: 'user/RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'user/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'user/RESET_PASSWORD_ERROR',

  CHANGE_PASSWORD_AFTER_RESET: 'user/CHANGE_PASSWORD_AFTER_RESET',
  CHANGE_PASSWORD_AFTER_RESET_SUCCESS: 'user/CHANGE_PASSWORD_AFTER_RESET_SUCCESS',
  CHANGE_PASSWORD_AFTER_RESET_ERROR: 'user/CHANGE_PASSWORD_AFTER_RESET_ERROR',

  UPDATE_INVITE_USER: 'user/UPDATE_INVITE_USER',
  UPDATE_INVITE_USER_SUCCESS: 'user/UPDATE_INVITE_USER_SUCCESS',
  UPDATE_INVITE_USER_ERROR: 'user/UPDATE_INVITE_USER_ERROR',

  GET_USER: 'user/GET',
  GET_USER_SUCCESS: 'user/GET_SUCCESS',
  GET_USER_ERROR: 'user/GET_ERROR',

  GET_USERS: 'user/GET_USERS',
  GET_USERS_SUCCESS: 'user/GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'user/GET_USERS_ERROR',

  GET_ALL_USERS: 'user/GET_ALL_USERS',
  GET_ALL_USERS_SUCCESS: 'user/GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_ERROR: 'user/GET_ALL_USERS_ERROR',

  GET_SINGLE_USER: 'user/GET_SINGLE',
  GET_SINGLE_SUCCESS: 'user/GET_SINGLE_SUCCESS',
  GET_SINGLE_ERROR: 'user/GET_SINGLE_ERROR',
  CLEAR_SINGLE_USER: 'user/CLEAR_SINGLE_USER',

  CREATE_USER: 'user/CREATE',
  CREATE_USER_SUCCESS: 'user/CREATE_SUCCESS',
  CREATE_USER_ERROR: 'user/CREATE_ERROR',

  DELETE_USER: 'user/DELETE',
  DELETE_USER_SUCCESS: 'user/DELETE_SUCCESS',
  DELETE_USER_ERROR: 'user/DELETE_ERROR',

  DELETE_APP_USER: 'user/DELETE_APP_USER',
  DELETE_APP_USER_SUCCESS: 'user/DELETE_APP_USER_SUCCESS',
  DELETE_APP_USER_ERROR: 'user/DELETE_APP_USER_ERROR',

  RE_INVITE_USER: 'user/RE_INVITE',
  RE_INVITE_USER_SUCCESS: 'user/RE_INVITE_SUCCESS',
  RE_INVITE_USER_ERROR: 'user/RE_INVITE_ERROR',

  EDIT_USER: 'user/EDIT',
  EDIT_USER_SUCCESS: 'user/EDIT_SUCCESS',
  EDIT_USER_ERROR: 'user/EDIT_ERROR',

  CHANGE_PASSWORD_USER: 'user/CHANGE_PASSWORD_USER',
  CHANGE_PASSWORD_USER_SUCCESS: 'user/CHANGE_PASSWORD_USER_SUCCESS',
  CHANGE_PASSWORD_USER_ERROR: 'user/CHANGE_PASSWORD_USER_ERROR',

  CHANGE_EMAIL_USER: 'user/CHANGE_EMAIL',
  CHANGE_EMAIL_USER_SUCCESS: 'user/CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_USER_ERROR: 'user/CHANGE_EMAIL_ERROR',

  TWO_FA_CONFIRM_PASSWORD: 'user/TWO_FA_CONFIRM_PASSWORD',
  TWO_FA_CONFIRM_PASSWORD_SUCCESS: 'user/TWO_FA_CONFIRM_PASSWORD_SUCCESS',
  TWO_FA_CONFIRM_PASSWORD_ERROR: 'user/TWO_FA_CONFIRM_PASSWORD_ERROR',

  TWO_FA_ENABLE: 'user/TWO_FA_ENABLE',
  TWO_FA_ENABLE_SUCCESS: 'user/TWO_FA_ENABLE_SUCCESS',
  TWO_FA_ENABLE_ERROR: 'user/TWO_FA_ENABLE_ERROR',

  TWO_FA_CONFIRMATION_CODE: 'user/TWO_FA_CONFIRMATION_CODE',
  TWO_FA_CONFIRMATION_CODE_SUCCESS: 'user/TWO_FA_CONFIRMATION_CODE_SUCCESS',
  TWO_FA_CONFIRMATION_CODE_ERROR: 'user/TWO_FA_CONFIRMATION_CODE_ERROR',

  TWO_FA_QR_CODE: 'user/TWO_FA_QR_CODE',
  TWO_FA_QR_CODE_SUCCESS: 'user/TWO_FA_QR_CODE_SUCCESS',
  TWO_FA_QR_CODE_ERROR: 'user/TWO_FA_QR_CODE_ERROR',

  TWO_FA_VERIFY_CODE: 'user/TWO_FA_VERIFY_CODE',
  TWO_FA_VERIFY_CODE_SUCCESS: 'user/TWO_FA_VERIFY_CODE_SUCCESS',
  TWO_FA_VERIFY_CODE_ERROR: 'user/TWO_FA_VERIFY_CODE_ERROR',

  UPDATE_APP_ONBOARDING: 'user/UPDATE_APP_ONBOARDING',
  UPDATE_APP_ONBOARDING_SUCCESS: 'user/UPDATE_APP_ONBOARDING_SUCCESS',
  UPDATE_APP_ONBOARDING_ERROR: 'user/UPDATE_APP_ONBOARDING_ERROR',

  TWO_FA_RECOVERY_CODES: 'user/TWO_FA_RECOVERY_CODES',
  TWO_FA_RECOVERY_CODES_SUCCESS: 'user/TWO_FA_RECOVERY_CODES_SUCCESS',
  TWO_FA_RECOVERY_CODES_ERROR: 'user/TWO_FA_RECOVERY_CODES_ERROR',

  TWO_FA_REMOVE: 'user/TWO_FA_REMOVE',
  TWO_FA_REMOVE_SUCCESS: 'user/TWO_FA_REMOVE_SUCCESS',
  TWO_FA_REMOVE_ERROR: 'user/TWO_FA_REMOVE_ERROR',

  CHANGE_USER_STATUS: 'user/CHANGE_STATUS',
  CHANGE_USER_STATUS_SUCCESS: 'user/CHANGE_STATUS_SUCCESS',
  CHANGE_USER_STATUS_ERROR: 'user/CHANGE_STATUS_ERROR',

  LOGOUT: 'user/LOGOUT',
  LOGOUT_SUCCESS: 'user/LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'user/LOGOUT_ERROR',

  VERIFY_EMAIL: 'user/VERIFY_EMAIL',
  VERIFY_EMAIL_SUCCESS: 'user/VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_ERROR: 'user/VERIFY_EMAIL_ERROR',

  GET_USERS_LIST: 'user/GET_USERS_LIST',
  GET_USERS_LIST_SUCCESS: 'user/GET_USERS_LIST_SUCCESS',
  GET_USERS_LIST_ERROR: 'user/GET_USERS_LIST_ERROR',

  CHANGE_LANGUAGE: 'user/CHANGE_LANGUAGE',
  CHANGE_LANGUAGE_SUCCESS: 'user/CHANGE_LANGUAGE_SUCCESS',
  CHANGE_LANGUAGE_ERROR: 'user/CHANGE_LANGUAGE_ERROR',

  UPDATE_DASHBOARD_ANALYTICS_OPTIONS: 'user/UPDATE_DASHBOARD_ANALYTICS_OPTIONS',
  UPDATE_DASHBOARD_ANALYTICS_OPTIONS_SUCCESS: 'user/UPDATE_DASHBOARD_ANALYTICS_OPTIONS_SUCCESS',
  UPDATE_DASHBOARD_ANALYTICS_OPTIONS_ERROR: 'user/UPDATE_DASHBOARD_ANALYTICS_OPTIONS_ERROR',

  GET_DASHBOARD_ANALYTICS_OPTIONS: 'user/GET_DASHBOARD_ANALYTICS_OPTIONS',
  GET_DASHBOARD_ANALYTICS_OPTIONS_SUCCESS: 'user/GET_DASHBOARD_ANALYTICS_OPTIONS_SUCCESS',
  GET_DASHBOARD_ANALYTICS_OPTIONS_ERROR: 'user/GET_DASHBOARD_ANALYTICS_OPTIONS_ERROR',

  SET_CACHED_USERS_TO_INVITE: 'user/SET_CACHED_USERS_TO_INVITE',
};

export default UserActionTypes;

