import uniqBy from 'lodash.uniqby';

import { useTranslation } from '@hooks';
import { WorkflowOptionsType } from '@hooks/useWorkflowOptions';
import useWorkflowOptionsPreview from '@hooks/useWorkflowOptionsPreview';

import { by, extract } from '@utils';

import { Dropdown } from '@components';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

const AnotherWorkflowNodeSettingsInfo = ({ node }) => {
  const { t } = useTranslation();

  const workflows = useWorkflowOptionsPreview(WorkflowOptionsType.WORKFLOW, (node.data.workers || []).map(w => w.trigger_workflow_id));

  if (!node.data.workers?.length || !node.data.workers?.[0]?.trigger_workflow_id) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  const renderWorker = (worker) => {
    if (!worker?.trigger_workflow_id) {
      return null;
    }

    const workflow = workflows?.find?.(by(worker?.trigger_workflow_id));
    const finishNodesList = workflows?.reduce((acc, currentWF) => {
      currentWF?.flow?.nodes?.forEach((node) => {
        if (worker?.trigger_workflow_node_ids.includes(node.id)) {
          acc[node.id] = node;
        }
      });
      return acc;
    }, {});

    const exitPoints = worker?.trigger_workflow_node_ids?.map(id => finishNodesList[id]?.data?.label || 'Not found')?.join(', ');

    return (
      <>
        <Dropdown.Main withoutDropdown title={`${workflow?.name} (${workflow?.version})`} description={exitPoints}/>
      </>
    );
  }

  return (
    <Container>
      {node.data.workers.map(renderWorker)}
    </Container>
  );
}

export default AnotherWorkflowNodeSettingsInfo;

