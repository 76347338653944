import { EventsActionTypes, UserActionTypes, WorkspacesActionTypes } from '@store/actions/types';

const initialState = {
  loading: false,
  events: {},
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EventsActionTypes.LIST:
      return {
        ...state,
        loading: true,
      };
    case EventsActionTypes.LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload,
      };
    case EventsActionTypes.LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

export default eventsReducer;
