import { Button } from '@velitech/ui';

import { useTranslation } from '@hooks';

import { UserPermissions } from '@constants';

import { Icon, WithPermissions } from '@components';

const AddFilterButton = ({ disabled }) => {
  const { p } = useTranslation('segments_page');

  return (
    <WithPermissions name={UserPermissions.CREATE_SEGMENTS}>
      <Button
        onClick={e => e?.stopImmediatePropagation?.()}
        data-testid="store-segment-add-filter-button"
        addonLeft={<Icon size={16} name="Add-new" />}
        style={{ width: 'auto', marginTop: 20 }}
        disabled={disabled}
      >
        <div>{p('add_filter')}</div>
      </Button>
    </WithPermissions>
  );
};

export default AddFilterButton;
