import styled from 'styled-components';

export const MobilePushImage = styled.img`
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 300px;
`;

export const MobpushPreview = styled.div`
  position: absolute;
  //padding: 14px 0px 4px 0px;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  overflow: hidden;
  line-height: 120%;
  height: 100%;
  background: #EFEFEF;
  border-radius: 8px 8px 0 0;
`;
