import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { by } from '@utils';
import { clickhouseFields2Events } from '@utils/fields';

import { Dropdown } from '@components';
import { resolveTitle } from '@components/lib/SegmentEditor/components/DateFilterPicker/DateFilterPicker';
import { AnalyticsSection } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/AnalyticsSection';
import { InfoTitle } from '@components/lib/WorkflowEditor/components/NodeSettingsDropdown/components/InfoTitle';

import { Container } from './styled.js';

import { formatDate, logicOperatortextconfig } from '../../utils.js';

const EventNodeSettingsInfo = ({ node: outerNode }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const eventsList = clickhouseFields2Events(clickhouseFields.data);

  const nodes = outerNode.data.events?.map(
    (v) => ({ data: v })
  );
  const { t, p } = useTranslation();

  if (!nodes?.length || !nodes?.[0]?.data?.event_id) {
    return <InfoTitle>{t('labels.no_settings')}</InfoTitle>;
  }

  const resolveValue = (value, node) => {
    if (Array.isArray(value)) {
      return value.join(
        !!~node.data?.query?.children?.[0]?.query?.operator?.indexOf?.(
          'between'
        )
          ? ' - '
          : ', '
      );
    }

    if (typeof value === 'object' && value !== null) {
      const title = resolveTitle(value, false, p);
      const isDate = title.includes('at');

      if (isDate) return formatDate(title);
      return title;
    }

    return value;
  };

  const renderNode = (node) => {
    if (!node.data?.event_id) {
      return null;
    }

    const event = eventsList.data?.find?.(by(node.data?.event_id));

    const getLabel = (event, field) => {
      const source = event?.payload?.find?.((source) => source.field === field);
      return source?.label;
    };

    const getFilterOperationText = (node) => {
      const hasFilters = node.data?.filter_builder.filters?.length > 0;
      const logicalOperator = node.data?.filter_builder.logicalOperator;

      const text = hasFilters
        ? logicOperatortextconfig[logicalOperator]
        : 'workflow_page.logical_operator_was_performed';
      return p(text);
    };

    const analytics = outerNode.data?.analytics?.clones?.find?.(
      (data) => data?.node === node.data?.id
    );

    const filters = node.data?.filter_builder?.filters;

    return (
      <Container>
        <Dropdown.Main
          title={event?.label}
          description={getFilterOperationText(node)}
        >
          {filters?.length > 0 ? filters.map((filter, index) => {
            return (
              <Dropdown.Item
                key={index}
                title={getLabel(event, filter.field)}
                subTitle={`${p(`segments_page.${filter.operator}`)}:`}
              >
                {resolveValue(filter.value.value, node)}
              </Dropdown.Item>
            );
          }): null}
        </Dropdown.Main>
        {analytics && <AnalyticsSection analytics={analytics} />}
      </Container>
    );
  };

  return <Container>{nodes.map(renderNode)}</Container>;
};

export default EventNodeSettingsInfo;
