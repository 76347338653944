export const getEventOptions = (events) => events?.data?.flatMap?.(({ name, label, type, payload, ...rest }) =>
  [({ value: name, label, type, key: name, ...rest })]
    .concat((payload || []).map(({ field: innerField, label: innerLabel, type }) =>
      ({ value: innerField, label: innerLabel, type, prefix: label, key: `${name}.${innerField}`, nested: name, ...rest }))))
  .filter(e => (!e.nested && e.value) || (e.nested && e.type === 'array')) || [];

export const getArrayOptions = (fieldSettings) => fieldSettings?.filter(f => f.type === 'array' || f.type === 'event')?.flatMap?.(({ field, label, type, payload, ...rest }) =>
  [({ value: field, label, type, key: field, ...rest })]
    .concat(type === 'array' ? (payload || []).map(({ field: innerField, label: innerLabel, type }) =>
      ({ value: innerField, label: innerLabel, type, prefix: label, nested: field, key: `${field}.${innerField}`, ...rest })) : [])
).filter(i => !i.nested || (i.nested && i.type === 'array')) || [];

export const getFilterOptions = (payload) => payload?.flatMap?.(({ field, label, type, payload }) =>
  [({ value: field, label, type, key: field })]
    .concat(type === 'array' ? (payload || []).map(({ field: innerField, label: innerLabel, type }) =>
      ({ value: innerField, label: innerLabel, type, prefix: label, nested: field, key: `${field}.${innerField}` })) : [])
) || [];

export const getFieldOptions = (payload) => payload
  ?.flatMap?.(({ field, label, type, payload = [] }) =>
    [({ value: field, label, type })]
      .concat((payload || []).map(({ field: innerField, label: innerLabel, type }) =>
        ({ value: innerField, label: innerLabel, type, prefix: label, nested: field })))
  ) || [];

export const getNestedOptions = (fieldSettings, events) => [
  ...getArrayOptions(fieldSettings),
  ...getEventOptions(events),
]
