import { Menu, Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { UserPermissions } from '@constants';

import { MoreButton, WithPermissions } from '@components';

import { MenuItem } from './styled';

export const MoreButtonAction = ({
  testId,
  data,
  handleClick,
  handleDuplicate,
  templatePermissions,
  onStatisticsClick,
  isExample,
  fromDashboard,
  handleStatistics,
  handleMoveToFolder,
  folder,
  foldersList,
  handleUnarchive,
  deleteDisabled,
  archiveDisabled,
  handleDelete,
  handleArchive
}) => {
  const { t, p } = useTranslation('templates')

  return (
    <MoreButton
      iconSize={26}
      color="#7C94B1"
      testId={`${testId}-more`}
      onClick={(e) => e.stopPropagation()}
      style={{ marginRight: '-10px' }}
      iconStyles={{ justifyContent: 'center' }}
      getPopupContainer={(t) => t.parentElement}
      menu={
        <Menu
          style={{
            borderRadius: '10px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.22)',
            overflow: 'hidden',
          }}
        >
          {+data.status !== 3 && (
            <>
              <Menu.Item key="d" onClick={(e) => e.domEvent.stopPropagation()}>
                <WithPermissions name={templatePermissions.CREATE}>
                  <MenuItem
                    data-testid={`${testId}-duplicate`}
                    onClick={handleClick(handleDuplicate)}
                  >
                    {t('actions.duplicate')}
                  </MenuItem>
                </WithPermissions>
              </Menu.Item>
              {onStatisticsClick && (
                <Menu.Item key="3" onClick={(e) => e.domEvent.stopPropagation()}>
                  <MenuItem data-testid={`${testId}-stats`} onClick={handleClick(handleStatistics)}>
                    {t('actions.statistics')}
                  </MenuItem>
                </Menu.Item>
              )}
            </>
          )}
          {!isExample && !fromDashboard && +data.status !== 3 && (
            <WithPermissions name={UserPermissions.UPDATE_FOLDER}>
              <div onClick={(e) => e.stopPropagation()}>
                <Menu.SubMenu
                  style={{ whiteSpace: 'nowrap' }}
                  key="sa"
                  disabled={
                    folder === 'default' &&
                    foldersList.data?.filter?.(({ id }) => String(id) !== String(folder)).length ===
                      0
                  }
                  popupClassName="submenu"
                  title={t('actions.move_to_folder')}
                >
                  {folder !== 'default' && (
                    <Menu.Item onClick={handleClick(handleMoveToFolder(null))}>
                      <MenuItem>{p('default_folder')}</MenuItem>
                    </Menu.Item>
                  )}
                  {foldersList.data
                    ?.filter?.(({ id }) => String(id) !== String(folder))
                    ?.map?.(({ name, id }) => (
                      <Menu.Item key={id} onClick={handleClick(handleMoveToFolder(id))}>
                        <MenuItem>{name}</MenuItem>
                      </Menu.Item>
                    ))}
                </Menu.SubMenu>
              </div>
            </WithPermissions>
          )}
          {!isExample && !fromDashboard && +data.status === 3 && (
            <Menu.Item key="4" onClick={(e) => e.domEvent.stopPropagation()}>
              <WithPermissions name={templatePermissions.UNARCHIVE}>
                <MenuItem
                  data-testid={`${testId}-unarchive`}
                  onClick={handleClick(handleUnarchive)}
                >
                  {t('actions.unarchive')}
                </MenuItem>
              </WithPermissions>
            </Menu.Item>
          )}
          {!isExample && (
            <Menu.Item
              onClick={(e) => {
                e.domEvent.stopPropagation();
              }}
              key="a"
            >
              <WithPermissions
                onClick={(e) => {
                  e.stopPropagation();
                }}
                name={+data.status === 3 ? templatePermissions.DELETE : templatePermissions.ARCHIVE}
              >
                {(+data.status === 3 ? deleteDisabled : archiveDisabled) ? (
                  <Tooltip
                    placement="right"
                    title={+data.status === 3 ? 'Template used' : 'Template has active workers'}
                  >
                    <div>
                      <MenuItem
                        data-testid={`${testId}-${+data.status === 3 ? 'delete' : 'archive'}`}
                        disabled={+data.status === 3 ? deleteDisabled : archiveDisabled}
                        onClick={handleClick(+data.status === 3 ? handleDelete : handleArchive)}
                        danger
                      >
                        {+data.status === 3 ? t('actions.delete') : t('actions.archive')}
                      </MenuItem>
                    </div>
                  </Tooltip>
                ) : (
                  <MenuItem
                    data-testid={`${testId}-${+data.status === 3 ? 'delete' : 'archive'}`}
                    disabled={+data.status === 3 ? deleteDisabled : archiveDisabled}
                    onClick={handleClick(+data.status === 3 ? handleDelete : handleArchive)}
                    danger
                  >
                    {+data.status === 3 ? t('actions.delete') : t('actions.archive')}
                  </MenuItem>
                )}
              </WithPermissions>
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};
