import React from 'react';

import { InputField, MultiSelectField } from '@velitech/ui';

import { Select } from '@components';

import { Container, Row } from './styled';

const ActionOptions = [
  {
    label: 'Add',
    value: 'TAG',
  },
  {
    label: 'Subtract',
    value: 'UNTAG',
  }
];

const TagRewardSettings = ({ tags, action, onTagsChange, onActionChange, errors, rank, onRankChange, tagsOptions, onSearch, loading, onLoadMore }) => {
  return (
    <Container>
      <Select
        options={ActionOptions}
        value={action}
        label="Action"
        wrapperStyles={{ width: '100%', marginBottom: 16, marginTop: 16 }}
        style={{ width: '100%' }}
        onChange={onActionChange}
        error={errors['tags_action']}
      />
      <Row>
        <MultiSelectField
          value={tags}
          label="Tags"
          onChange={onTagsChange}
          onSearch={onSearch}
          onLoadMore={onLoadMore}
          hasNextPage={true}
          error={errors['reward_tags']}
          options={tagsOptions}
          selectedOptionsLabel="Selected options"
          availableOptionsLabel="Available options"
          loading={loading}
        />
        <InputField
          value={rank}
          onChange={onRankChange}
          label="Rank"
        />
      </Row>
    </Container>
  );
};

export default TagRewardSettings;
