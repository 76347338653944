import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { SegmentsRuleTypes } from '@constants';

import { getArrayOptions, resolveDropdownFieldType } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';

import { NoData } from '@components';
import { Suffix } from '@components/inputs/Select/styled';

import { Container, Value } from './styled.js';

const ArrayFilterPanel = ({ onSelect, hidePreview, hideArrays, nested }) => {
  const { p } = useTranslation('segments_page');
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const arrayFields = nested ? getArrayOptions(customerFields) : customerFields?.filter(f => f.type === 'array').map(a => ({ ...a, value: a.field }));

  if (!arrayFields?.length) {
    return (
      <Container hidePreview={hidePreview}>
        <NoData />
      </Container>
    )
  }

  return (
    <Container hidePreview={hidePreview} data-testid={'store-segment-filter-array-tab-content'}>
      {arrayFields?.filter(({ type }) => !hideArrays || type !== 'array')?.map((af, index) => (
        <div key={index} style={{ display: 'flex' }}>
          <Value data-testid={`store-segment-filter-panel-array-field-${af.field}`} onClick={() => onSelect(af, SegmentsRuleTypes.ARRAY)}>{af.prefix ? <div style={{ color: '#909399' }}>{af.prefix}.</div> : ''}{af.label}</Value>
          {nested && <Suffix>{resolveDropdownFieldType(af.prefix, af.type, p)}</Suffix>}
        </div>
      ))}
    </Container>
  );
}

export default ArrayFilterPanel;

