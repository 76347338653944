import styled from 'styled-components';

import { OrderDirections } from '@constants';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  ${styledProp('right', `
    justify-content: flex-end;
    padding-right: 6px;
  `)}
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ sortable }) => sortable ? `
    cursor: pointer;
  ` : ''}
  
  font-family: Manrope-Medium;
  font-size: 13px;
  color: #909399;

  ${switchStyle('order', {
    [OrderDirections.ASC]: `
        color: #303133;
        & rect {
          fill: #303133;
        }
    `,
    [OrderDirections.DESC]: `
        color: #303133;
        & rect {
          fill: #303133;
        }
    `,
    [OrderDirections.UNDETERMINED]: '',
  })}
`;

export const IconContainer = styled.div`
  margin-left: 5px;
  transition: 280ms ease all;
  ${({ order }) => order === OrderDirections.ASC ? `
    transform: rotateX(180deg);
    margin-top: -4px;
  ` : ''}
  
  ${switchStyle('order', {
    [OrderDirections.ASC]: `
      transform: rotateX(180deg);
    `,
    [OrderDirections.DESC]: '',
    [OrderDirections.UNDETERMINED]: '',
  })}
`;
