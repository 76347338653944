import { RFMAnalyticsActionTypes } from '@store/actions/types';

const initialState = {
  rfmAnalytics: {
    data: [],
    loading: false,
  },
  rfmList: {
    data: [],
  },
  rfmAnalyticsItems: {

  },
  rfmAnalyticsRanges: {

  },
};

const rfmAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RFMAnalyticsActionTypes.GET_RFM_ANALYTICS:
      return {
        ...state,
        rfmAnalytics: {
          ...state.rfmAnalytics,
          loading: true,
        },
      };
    case RFMAnalyticsActionTypes.GET_RFM_ANALYTICS_SUCCESS:
      return {
        ...state,
        rfmAnalytics: {
          ...state.rfmAnalytics,
          ...action.payload,
          loading: false,
        },
      };
    case RFMAnalyticsActionTypes.GET_RFM_ANALYTICS_ERROR:
      return {
        ...state,
        rfmAnalytics: {
          ...state.rfmAnalytics,
          loading: false,
        },
      };
    case RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS:
      return {
        ...state,
        rfmAnalyticsItems: {
          ...state.rfmAnalyticsItems,
          [action.meta.id]: {
            ...(state.rfmAnalyticsItems?.[action.meta.id] || {}),
            loading: true,
          },
        },
      };
    case RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS_SUCCESS:
      return {
        ...state,
        rfmAnalyticsItems: {
          ...state.rfmAnalyticsItems,
          [action.meta.id]: {
            ...(state.rfmAnalyticsItems?.[action.meta.id] || {}),
            loading: false,
            ...action.payload,
          },
        }
      };
    case RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS_ERROR:
      return {
        ...state,
        rfmAnalyticsItems: {
          ...state.rfmAnalyticsItems,
          [action.meta.id]: {
            ...(state.rfmAnalyticsItems?.[action.meta.id] || {}),
            loading: false,
          },
        },
      };
    case RFMAnalyticsActionTypes.GET_RFM_LIST:
      return {
        ...state,
        rfmList: {
          ...state.rfmList,
          // data: [],
        },
      };
    case RFMAnalyticsActionTypes.GET_RFM_LIST_SUCCESS:
      return {
        ...state,
        rfmList: {
          ...state.rfmList,
          data: action.payload.data,
        },
      };
    case RFMAnalyticsActionTypes.GET_RFM_LIST_ERROR:
      return {
        ...state,
        rfmList: {
          ...state.rfmList,
          data: [],
        },
      };
    case RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS_RANGE:
      return {
        ...state,
        rfmAnalyticsRanges: {
          ...state.rfmAnalyticsRanges,
          [action.meta.id]: {
            ...(state.rfmAnalyticsRanges?.[action.meta.id] || {}),
            [action.meta.range_id]: {
              ...(state.rfmAnalyticsRanges?.[action.meta.id]?.[action.meta.range_id] || {}),
              loading: true,
            },
          },
        },
      };
    case RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS_RANGE_SUCCESS:
      return {
        ...state,
        rfmAnalyticsRanges: {
          ...state.rfmAnalyticsRanges,
          [action.meta.id]: {
            ...(state.rfmAnalyticsRanges?.[action.meta.id] || {}),
            [action.meta.range_id]: {
              ...(state.rfmAnalyticsRanges?.[action.meta.id]?.[action.meta.range_id] || {}),
              ...action.payload,
              loading: false,
            },
          },
        },
      };
    case RFMAnalyticsActionTypes.EDIT_RFM_ANALYTICS_RANGE_ERROR:
      return {
        ...state,
        rfmAnalyticsRanges: {
          ...state.rfmAnalyticsRanges,
          [action.meta.id]: {
            ...(state.rfmAnalyticsRanges?.[action.meta.id] || {}),
            [action.meta.range_id]: {
              ...(state.rfmAnalyticsRanges?.[action.meta.id]?.[action.meta.range_id] || {}),
              loading: false,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default rfmAnalyticsReducer;
