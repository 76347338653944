import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  margin-bottom: 2px;
`;

export const DeleteButtonContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  ${styledProp('$disabled', `
    opacity: 40%;
    cursor: not-allowed !important;
  `)}
`;

export const EventName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EventPanel = styled.div`
  border-radius: 10px;
  border: 1px solid var(--cards-border, #D3DBE7);
  background: var(--Colors-Neutral-100, #F8FAFE);
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.30);
  position: relative;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

export const FiltersContainer = styled.div`
  position: relative;
  background-color: #ECF0F6;
  padding: 2px 2px 2px 60px;
  font-family: Manrope-Medium;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #7C94B1;

`;

export const SvgContainer = styled.div`
  min-width: 32px;
`

export const SegmentTitle = styled.div`
    color: var(--text-primary-body, #1A232E);
    font-family: Manrope-SemiBold;
    font-size: 21px;
    line-height: 26px;
    max-width: 89%;
`
