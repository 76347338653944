import { Checkbox } from 'antd';

import { useTranslation } from '@hooks';

import {
  Container,
  AdditionalLine,
  InfoContainer,
  Line
} from './styled';

const CheckboxNode = ({
  value,
  tree,
  onChange,
  level = 0,
  roleDisabled = false,
  isFirst = false,
  isLast = false,
}) => {
  const nesting = !!tree.children.length;
  const checked = value.indexOf(tree.id) !== -1;
  const { p } = useTranslation('roles_page');

  const handleChange = () => {
    onChange(tree);
  };

  return (
    <Container level={level}>
      {level > 0 && <Line isFirst={isFirst} level={level}/>}
      <InfoContainer nesting={nesting}>
        {(level > 0 && nesting && !isLast) && <AdditionalLine />}
        {tree.name && (
          <div style={roleDisabled ? { cursor: 'not-allowed' } : {}}>
            <Checkbox checked={checked} className={roleDisabled ? 'disabled-role-checkbox' : ''} onChange={handleChange}>
              {p(tree.name)}
            </Checkbox>
          </div>
        )}
        {nesting && (
          tree.children.map((child, index, arr) => (
            <CheckboxNode
              isFirst={index === 0}
              key={child.id}
              value={value}
              roleDisabled={roleDisabled}
              tree={child}
              isLast={index === arr.length - 1}
              onChange={onChange}
              level={level + 1}
            />
          ))
        )}
      </InfoContainer>
    </Container>
  );
}

export default CheckboxNode;

