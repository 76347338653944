import doc from '@res/images/document.svg';

import { Container } from './styled.js';

import { Body, Icon } from '../NotificationItem/styled';

const SystemNotificationBody = ({ body, style = {}, tid, type }) => {

  const handleShortenName = (text, maxLength) => {
    if (typeof text === 'string' && text.length <= maxLength) {
      return text;
    }
    if (typeof text === 'string'){
      return text.slice(0, maxLength - 3) + " ... " + text.slice(-7);
    }
  }

  const shortenedFileName = handleShortenName(body[1]?.props?.children, 30);
  const onFileClick = body[1]?.props?.onClick || (() => {});

  return (
    <Container style={style}>
      {type === 'segment_export_success' && <Icon onClick={onFileClick} src={doc}/>}
      <Body onClick={type === 'segment_export_success' ? onFileClick : () => {}} {...tid('body')}>
        {type === 'segment_export_success' ? shortenedFileName : body}
      </Body>
    </Container>
  );
}

export default SystemNotificationBody;

