import styled from 'styled-components';

export const Container = styled.div`
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 20px;
  overflow-y: scroll;
`;

export const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 120%;
  cursor: pointer;
`;
