import { useEffect, useRef } from 'react';

import { useModalState, useTranslation } from '@hooks';

import { Container, Input, ButtonText, ButtonRow } from './styled';

const DescriptionInput = ({ value, onChange }) => {
  const state = useModalState();
  const { p } = useTranslation('workflow_page');
  const textareaRef = useRef(null);

  useEffect(() => {
    if (value) {
      state.open();
    }
  }, []);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  }, [value, textareaRef.current]);

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  const handleAddComment = () => {
    state.open();
    textareaRef.current?.focus?.();
  };

  const handleDeleteComment = () => {
    state.close();
    onChange('');
  };

  return (
    <Container>
      <Input
        $opened={state.opened}
        value={value}
        onChange={handleChange}
        maxLength={320}
        ref={textareaRef}
      />
      {state.opened && (
        <ButtonRow onClick={handleDeleteComment}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path d="M8.375 6.75H7.25V13.5H8.375V6.75Z" fill="#7C94B1"/>
            <path d="M11.75 6.75H10.625V13.5H11.75V6.75Z" fill="#7C94B1"/>
            <path d="M2.75 3.375V4.5H3.875V15.75C3.875 16.0484 3.99353 16.3345 4.2045 16.5455C4.41548 16.7565 4.70163 16.875 5 16.875H14C14.2984 16.875 14.5845 16.7565 14.7955 16.5455C15.0065 16.3345 15.125 16.0484 15.125 15.75V4.5H16.25V3.375H2.75ZM5 15.75V4.5H14V15.75H5Z" fill="#7C94B1"/>
            <path d="M11.75 1.125H7.25V2.25H11.75V1.125Z" fill="#7C94B1"/>
          </svg>
          <ButtonText>
            {p('delete_comment')}
          </ButtonText>
        </ButtonRow>
      )}
      {!state.opened && (
        <ButtonRow onClick={handleAddComment}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M17.4375 13.5H15.1875V11.25H14.0625V13.5H11.8125V14.625H14.0625V16.875H15.1875V14.625H17.4375V13.5Z" fill="#909399"/>
            <path d="M14.0625 2.8125H12.375V2.25C12.3741 1.9519 12.2553 1.66625 12.0445 1.45546C11.8338 1.24466 11.5481 1.12586 11.25 1.125H6.75C6.4519 1.12586 6.16625 1.24466 5.95546 1.45546C5.74466 1.66625 5.62586 1.9519 5.625 2.25V2.8125H3.9375C3.6394 2.81336 3.35375 2.93216 3.14296 3.14296C2.93216 3.35375 2.81336 3.6394 2.8125 3.9375V15.75C2.81336 16.0481 2.93216 16.3338 3.14296 16.5445C3.35375 16.7553 3.6394 16.8741 3.9375 16.875H9.5625V15.75H3.9375V3.9375H5.625V5.625H12.375V3.9375H14.0625V9H15.1875V3.9375C15.1866 3.6394 15.0678 3.35375 14.857 3.14296C14.6463 2.93216 14.3606 2.81336 14.0625 2.8125ZM11.25 4.5H6.75V2.25H11.25V4.5Z" fill="#909399"/>
          </svg>
          <ButtonText>
            {p('add_comment')}
          </ButtonText>
        </ButtonRow>
      )}
    </Container>
  );
};

export default DescriptionInput;
