import { useCallback, useState } from 'react';

const useModalState = (initialState = false) => {
  const [opened, setOpened] = useState(initialState);
  const [callbacks, setCallbacks] = useState({});
  const [data, setData] = useState();

  const addCallback = useCallback((key, callback) => {
    setCallbacks(prev => ({ ...prev, [key]: callback }));
  }, []);

  const removeCallback = useCallback((key) => {
    setCallbacks(prev => {
      // eslint-disable-next-line no-unused-vars
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const callCallbackAndRemove = (key) => {
    callbacks[key]?.();
    removeCallback(key);
  }

  const handleOpen = (data) => {
    setOpened(true);

    if(data) {
      setData(data);
    }
  };

  const handleClose = () => {
    setOpened(false);
    setData(null)
  };

  return {
    opened,
    setOpened,
    open: (data) => handleOpen(data),
    close: () => handleClose(),
    toggle: () => setOpened(o => !o),
    callbacks: {
      callCallbackAndRemove,
      addCallback,
      removeCallback
    },
    data
  };
};

export default useModalState;
