import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
`;

export const Overlay = styled.div`
`;

export const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  overflow: hidden;
`;

