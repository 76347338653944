import { useLocation } from 'react-router';

import { useBreadcrumbsOptions } from '@hooks';

import { Paths } from '@constants';

import { by } from '@utils';

const getIdFromPath = (pattern, path) => {
  const regex = new RegExp(pattern.replace(/:[^\s/]+/g, '([\\w-]+)'));
  const match = path.match(regex);

  return match ? match[1] : null;
};

const navigationConfig = {
  skip: [Paths.DASHBOARD],
  resolve: {
    [Paths.WORKFLOWS]: {
      title: () => 'Workflows',
      to: () => Paths.WORKFLOWS,
      resolve: {
        '/edit': {
          title: ({ name }) => name || 'New workflow',
          to: ({ id }) => `${Paths.WORKFLOWS}/edit/${id}`,
          entity: 'workflows',
          identifier: '/edit/:id',
        },
      },
    },
    [Paths.CAMPAIGNS]: {
      title: () => 'Instant Send',
      to: () => Paths.CAMPAIGNS,
      resolve: {
        '/*': {
          title: ({ name }) => name || 'New Instant Send',
          to: ({ id }) => `${Paths.CAMPAIGNS}/${id}`,
          entity: 'campaigns',
          identifier: '/instant-send/:id',
        },
      },
    },
    [`${Paths.RESOURCES_SEGMENTS}/static`]: {
      title: () => 'Static segments',
      to: () => Paths.RESOURCES_SEGMENTS,
      resolve: {
        '/*': {
          entity: 'segments',
          title: ({ name }) => name || 'New Segment',
          to: ({ id }) => `${Paths.RESOURCES_SEGMENTS}/static/${id}`,
          identifier: '/resources/segments/static/:id',
        },
      },
    },
    [`${Paths.RESOURCES_SEGMENTS}/dynamic`]: {
      title: () => 'Segments',
      to: () => Paths.RESOURCES_SEGMENTS,
      resolve: {
        '/*': {
          entity: 'segments',
          title: ({ name }) => name || 'New Segment',
          to: ({ id }) => `${Paths.RESOURCES_SEGMENTS}/dynamic/${id}`,
          identifier: '/resources/segments/dynamic/:id',
        },
      },
    },
    [Paths.FUNNEL_ANALYTICS]: {
      title: () => 'Funnel Analytics',
      to: () => Paths.FUNNEL_ANALYTICS,
      resolve: {
        '/*': {
          entity: 'funnels',
          title: ({ name }) => name || 'New Funnel',
          to: ({ id }) => `${Paths.FUNNEL_ANALYTICS}/${id}/funnel`,
          identifier: '/analytics/funnel/:id',
        }
      },
    },
    [Paths.RFM_ANALYTICS]: {
      title: () => 'RFM Analytics',
      to: () => Paths.RFM_ANALYTICS,
      resolve: {
        '/*': {
          entity: 'rfm',
          title: ({ name }) => name || 'New RFM Analytics',
          to: ({ id }) => `${Paths.RFM_ANALYTICS}/${id}`,
          identifier: '/analytics/rfm/:id',
        }
      },
    },
    [Paths.CONTACTS_ALL]: {
      title: () => 'Players',
      to: () => Paths.CONTACTS_ALL,
      resolve: {
        '/player': {
          entity: 'contact',
          title: ({ first_name, last_name }) => `${first_name} ${last_name}` || 'Player',
          to: ({ id }) => `${Paths.CONTACTS_ALL}/player/${id}`,
          identifier: '/player/:id',
        },
        '/import': {
          title: () => 'Import',
          to: () => `${Paths.CONTACT}/import`,
        },
        '/fields': {
          title: () => 'Fields',
          to: () => `${Paths.CONTACT}/fields`,
        },
        '/unsubscribe-groups': {
          title: () => 'Unsubscribe groups',
          to: () => `${Paths.CONTACT}/unsubscribe-groups`,
        }
      },
    },
    '/templates/emails': {
      title: () => 'Email templates',
      to: () => `${Paths.TEMPLATES_EMAIL}/list/default`,
      resolve: {
        '/vce': {
          entity: 'emailTemplates',
          title: ({ name }) => name || 'New Email',
          to: ({ id }) => `${Paths.TEMPLATES_EMAIL}/vce/${id}`,
          identifier: '/vce/:id',
        },
        '/code': {
          entity: 'emailTemplates',
          title: ({ name }) => name || 'New Email',
          to: ({ id }) => `${Paths.TEMPLATES_EMAIL}/code/${id}`,
          identifier: '/code/:id',
        },
      },
    },
    '/templates/sms': {
      title: () => 'SMS templates',
      to: () => `${Paths.TEMPLATES_SMSES}/default`,
      resolve: {
        '/*': {
          skip: ['/list'],
          entity: 'smsTemplates',
          title: ({ name }) => name || 'New Sms',
          to: ({ id }) => `${Paths.TEMPLATES_SMS}/${id}`,
          identifier: '/sms/:id',
        },
      },
    },
    '/templates/viber': {
      title: () => 'Viber templates',
      to: () => `${Paths.TEMPLATES_VIBER}/list/default`,
      resolve: {
        '/*': {
          skip: ['/list'],
          entity: 'viberTemplates',
          title: ({ name }) => name || 'New Viber',
          to: ({ id }) => `${Paths.TEMPLATES_SMS}/${id}`,
          identifier: '/viber/:id',
        },
      },
    },
    '/templates/web-push': {
      title: () => 'Web-push templates',
      to: () => `${Paths.TEMPLATES_WEBPUSH}/list/default`,
      resolve: {
        '/*': {
          skip: ['/list'],
          entity: 'webPushTemplates',
          title: ({ name }) => name || 'New Web-push',
          to: ({ id }) => `${Paths.TEMPLATES_WEBPUSH}/${id}`,
          identifier: '/web-push/:id',
        },
      },
    },
    '/templates/mobile-push': {
      title: () => 'Mobile push templates',
      to: () => `${Paths.TEMPLATES_MOBILE_PUSH}/list/default`,
      resolve: {
        '/*': {
          skip: ['/list'],
          entity: 'mobilePushTemplates',
          title: ({ name }) => name || 'New Mobile push',
          to: ({ id }) => `${Paths.TEMPLATES_MOBILE_PUSH}/${id}`,
          identifier: '/mobile-push/:id',
        },
      },
    },
    '/templates/webhook': {
      title: () => 'Webhook templates',
      to: () => `${Paths.TEMPLATES_MOBILE_PUSH}/list/default`,
      resolve: {
        '/*': {
          skip: ['/list'],
          entity: 'apiRequestTemplates',
          title: ({ name }) => name || 'New Webhook',
          to: ({ id }) => `${Paths.TEMPLATES_API_REQUEST}/${id}`,
          identifier: '/webhook/:id',
        },
      },
    },
    '/templates/content-card': {
      title: () => 'Message feed templates',
      to: () => `${Paths.TEMPLATES_CONTENT_CARDS}/list/default`,
      resolve: {
        '/*': {
          skip: ['/list'],
          entity: 'contentCardTemplates',
          title: ({ name }) => name || 'New message',
          to: ({ id }) => `${Paths.TEMPLATES_CONTENT_CARDS}/${id}`,
          identifier: '/content-card/:id',
        },
      },
    },
    '/templates/web-popups': {
      title: () => 'Web popups templates',
      to: () => `${Paths.TEMPLATES_WEB_POPUP}/list/default`,
      resolve: {
        '/*': {
          skip: ['/list'],
          entity: 'webPopupTemplates',
          title: ({ name }) => name || 'New popup',
          to: ({ id }) => `${Paths.TEMPLATES_WEB_POPUP}/${id}`,
          identifier: '/web-popups/:id',
        },
      },
    },
    '/settings/users': {
      title: () => 'Users',
      to: () => Paths.USERS_SETTINGS,
      resolve: {
        '/*': {
          entity: 'users',
          title: ({ name, surname }) => `${name} ${surname}`,
          to: ({ id }) => `${Paths.USERS_SETTINGS}/${id}`,
          identifier: '/users/:id',
        },
      },
    },
    '/settings/roles': {
      title: () => 'Roles',
      to: () => Paths.ROLES_SETTINGS,
      resolve: {
        '/edit': {
          entity: 'roles',
          title: ({ label }) => label,
          to: ({ id }) => `${Paths.ROLES_SETTINGS}/${id}`,
          identifier: '/roles/edit/:id',
        },
        '/create': {
          title: () => 'New Role',
          to: () => `${Paths.ROLES_SETTINGS}/create`,
        },
      },
    },
    '/settings/integrations': {
      title: () => 'Integrations',
      to: () => Paths.INTEGRATIONS,
      resolve: {
        '/*': {
          entity: 'smsTemplates',
          title: ({ name }) => name || 'New Sms',
          to: ({ id }) => `${Paths.INTEGRATIONS}/otp-template/${id}`,
          identifier: '/otp-template/:id',
        },
        '/id': {
          entity: 'integrations',
          title: ({ name }) => name?.en,
          to: ({ key }) => `${Paths.INTEGRATIONS}/${key}`,
          identifierResolver: 'key',
          identifier: '/integrations/:id',
          resolve: {
            '/*': {
              entity: 'myIntegrations',
              title: ({ name }) => name || 'New connection',
              to: ({ integration_key, id }) => `${Paths.INTEGRATIONS}/${integration_key}/${id}`,
              identifier: '/integrations/[\\w-]+/:id',
            }
          },
        },
      },
    },
    [Paths.NOTIFICATIONS]: {
      title: () => 'Notification History',
      to: () => Paths.NOTIFICATIONS,
    },
  },
};

export const createBreadcrumbs = (location, options) => {
  const breadcrumbs = [];
  const path = location.pathname.split('/').filter(Boolean).slice(2);

  let currentPath = '';
  let config = navigationConfig.resolve;

  for (let segment of path) {
    currentPath += '/' + segment;
    const cfg = config[currentPath] || config['/*'];

    if (cfg) {
      if (cfg.skip?.some?.(part => location.pathname.includes(part))) {
        currentPath = '';
        continue;
      }

      let entity = {};

      if (cfg.entity) {
        const id = getIdFromPath(cfg.identifier, location.pathname);
        entity = options[cfg.entity].find(cfg.identifierResolver ? by(cfg.identifierResolver, id) : by(id)) || {};
      }

      breadcrumbs.push({
        title: cfg.title(entity),
        to: cfg.to(entity),
      });
      currentPath = '';

      if (cfg.resolve) {
        config = cfg.resolve;
      } else {
        break;
      }
    }
  }

  return breadcrumbs;
}

export const useBreadcrumbs = () => {
  const location = useLocation();
  const options = useBreadcrumbsOptions();

  return createBreadcrumbs(location, options);
};
