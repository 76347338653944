import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 31px;
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
    border-radius: 7px;
  `)}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #DCDFE6;
  margin-right: 10px;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 100%;
  white-space: nowrap;
  margin-right: 10px;
  color: #303133;
`;

export const IconContainer = styled.div`
  transition: 280ms ease all;
  cursor: pointer;
  
  ${styledProp('$opened', `
    transform: rotate(180deg);
  `, `
    transform: rotate(0deg);
  `)}
`;

export const Content = styled.div`
  transition: 280ms ease all;
  padding-top: 25px;

  ${styledProp('$opened', `
    max-height: 1000px;
  `, `
    max-height: 0px;
  `)}
`;
