import { Container, CountryIcon, CountryName } from "./styled"

export const LanguageItem = ({ icon, code, name }) => {
  return (
    <Container>
      <CountryIcon>
        {icon}
      </CountryIcon>
      <CountryName>
        {name} {code && `(${code})`}
      </CountryName>

    </Container>
  )
}