import { v4 as uuidv4 } from 'uuid';

export const createEdge = (edge) => {
  return {
    sourceHandle: 'b',
    targetHandle: 'a',
    style: { stroke: '#909399', zIndex: 10 },
    type: 'default_edge',
    data: { ...(edge.data || {}), displayIndicator: false },
    id: uuidv4(),
    ...edge,
  }
};
