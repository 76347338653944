export const ArrayIconSvg = () => {
  return (
    <svg
      width='84'
      height='70'
      viewBox='0 0 84 70'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_60_993)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.5312 14.1429C11.5312 11.9239 13.3106 10.125 15.5054 10.125H15.5349C17.7298 10.125 19.5091 11.9239 19.5091 14.1429C19.5091 16.3619 17.7298 18.1607 15.5349 18.1607H15.5054C13.3106 18.1607 11.5312 16.3619 11.5312 14.1429ZM26.2505 14.1429C26.2505 11.9239 28.0298 10.125 30.2246 10.125H68.4946C70.6894 10.125 72.4688 11.9239 72.4688 14.1429C72.4688 16.3619 70.6894 18.1607 68.4946 18.1607H30.2246C28.0298 18.1607 26.2505 16.3619 26.2505 14.1429ZM11.5312 32C11.5312 29.781 13.3106 27.9821 15.5054 27.9821H15.5349C17.7298 27.9821 19.5091 29.781 19.5091 32C19.5091 34.219 17.7298 36.0179 15.5349 36.0179H15.5054C13.3106 36.0179 11.5312 34.219 11.5312 32ZM26.2505 32C26.2505 29.781 28.0298 27.9821 30.2246 27.9821H68.4946C70.6894 27.9821 72.4688 29.781 72.4688 32C72.4688 34.219 70.6894 36.0179 68.4946 36.0179H30.2246C28.0298 36.0179 26.2505 34.219 26.2505 32ZM11.5312 49.8571C11.5312 47.6381 13.3106 45.8393 15.5054 45.8393H15.5349C17.7298 45.8393 19.5091 47.6381 19.5091 49.8571C19.5091 52.0761 17.7298 53.875 15.5349 53.875H15.5054C13.3106 53.875 11.5312 52.0761 11.5312 49.8571ZM26.2505 49.8571C26.2505 47.6381 28.0298 45.8393 30.2246 45.8393H68.4946C70.6894 45.8393 72.4688 47.6381 72.4688 49.8571C72.4688 52.0761 70.6894 53.875 68.4946 53.875H30.2246C28.0298 53.875 26.2505 52.0761 26.2505 49.8571Z'
          fill='url(#paint0_linear_60_993)'
          shapeRendering='crispEdges'
        />
        <path
          d='M11.9219 14.1429C11.9219 12.1356 13.5303 10.5156 15.5054 10.5156H15.5349C17.51 10.5156 19.1184 12.1356 19.1184 14.1429C19.1184 16.1502 17.51 17.7701 15.5349 17.7701H15.5054C13.5303 17.7701 11.9219 16.1502 11.9219 14.1429ZM26.6411 14.1429C26.6411 12.1356 28.2495 10.5156 30.2246 10.5156H68.4946C70.4697 10.5156 72.0781 12.1356 72.0781 14.1429C72.0781 16.1502 70.4697 17.7701 68.4946 17.7701H30.2246C28.2495 17.7701 26.6411 16.1502 26.6411 14.1429ZM11.9219 32C11.9219 29.9927 13.5303 28.3728 15.5054 28.3728H15.5349C17.51 28.3728 19.1184 29.9927 19.1184 32C19.1184 34.0073 17.51 35.6272 15.5349 35.6272H15.5054C13.5303 35.6272 11.9219 34.0073 11.9219 32ZM26.6411 32C26.6411 29.9927 28.2495 28.3728 30.2246 28.3728H68.4946C70.4697 28.3728 72.0781 29.9927 72.0781 32C72.0781 34.0073 70.4697 35.6272 68.4946 35.6272H30.2246C28.2495 35.6272 26.6411 34.0073 26.6411 32ZM11.9219 49.8571C11.9219 47.8498 13.5303 46.2299 15.5054 46.2299H15.5349C17.51 46.2299 19.1184 47.8498 19.1184 49.8571C19.1184 51.8644 17.51 53.4844 15.5349 53.4844H15.5054C13.5303 53.4844 11.9219 51.8644 11.9219 49.8571ZM26.6411 49.8571C26.6411 47.8498 28.2495 46.2299 30.2246 46.2299H68.4946C70.4697 46.2299 72.0781 47.8498 72.0781 49.8571C72.0781 51.8644 70.4697 53.4844 68.4946 53.4844H30.2246C28.2495 53.4844 26.6411 51.8644 26.6411 49.8571Z'
          stroke='white'
          strokeOpacity='0.3'
          strokeWidth='0.78125'
          shapeRendering='crispEdges'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_60_993'
          x='0.593751'
          y='3.875'
          width='82.8125'
          height='65.625'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4.6875' />
          <feGaussianBlur stdDeviation='5.46875' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.394999 0 0 0 0 0.209974 0 0 0 0 0.53597 0 0 0 0.7 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_60_993'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_60_993'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_60_993'
          x1='72.2143'
          y1='10.125'
          x2='41.2639'
          y2='69.2949'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.62' />
          <stop offset='1' stopColor='white' stopOpacity='0.48' />
        </linearGradient>
      </defs>
    </svg>
  );
};
