import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;
  border-bottom: 1px solid #DCDFE6;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;
`;
