import { DescriptionInput } from './components';
import NodeIcons from './res';
import { Container, NodeIconContainer, Title, NodeIcon } from './styled';

const NodeSettingsPreview = ({ actionType, name, title, description, onDescriptionChange }) => {
  return (
    <Container>
      <NodeIconContainer $actionType={actionType}>
        <NodeIcon
          alt="node"
          src={NodeIcons[name]}
          width={40}
          height={40}
          $actionType={actionType}
        />
      </NodeIconContainer>
      <Title>
        {title}
      </Title>
      <DescriptionInput
        value={description}
        onChange={onDescriptionChange}
      />
    </Container>
  );
};

export default NodeSettingsPreview;
