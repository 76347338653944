import { extendMoment } from 'moment-range';
import momentTimezone from 'moment-timezone';
import 'moment/locale/en-gb';

const moment = extendMoment(momentTimezone);

moment.locale('en-gb');

export const setTimezone = moment.tz.setDefault;

moment.fn.toJSON = function() {
  return this.toISOString(false);
};

export default moment;
