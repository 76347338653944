import { useState } from 'react';

import { useTranslation } from '@hooks';

import { testId } from '@utils';

import { NotificationItem } from '@components/lib/NotificationsList/components/NotificationItem';
import { Items, ReadAll, Row, ScrollContainer, Period } from '@components/lib/NotificationsList/styled';

const tid = testId('sidebar-notifications')

const NotificationsGroup = ({ group, name, onRead, onClose, handleReadAll, idx, hasUnread, handleMarkAsImportant }) => {
  const { p } = useTranslation('topbar');
  const [isImportant] = useState(false);

  return (
    <ScrollContainer>
      <Row>
        {group.length !== 0 && (
          <Period>
            {name === 'Today' ? p('latest') : name}
          </Period>
        )}
        {idx === 0 && hasUnread && (
          <ReadAll onClick={handleReadAll} {...tid('read-all')} >
            {p('read_all')}
          </ReadAll>
        )}
      </Row>
      <Items {...tid('container')}>
        {group.map((item, i) => (
          <NotificationItem
            isImportant={isImportant}
            // style={style}
            item={item}
            key={i}
            tid={tid(`item-${i}`)}
            onClose={onClose}
            date={item.created_at}
            isLast={i === group.length - 1}
            readNotification={onRead}
            markAsImportant={handleMarkAsImportant}
          />
        ))}
      </Items>
    </ScrollContainer>
  );
};

export default NotificationsGroup;
