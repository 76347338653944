import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;
  max-width: 460px;
  margin: 0 auto;
  ${styledProp(
    '$s',
    css`
      align-items: center;
    `
  )}
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
  justify-content: center;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 2px;
  z-index: 1;
`;

export const Description = styled.div`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  margin-top: 40px;
  min-height: 42px;
  font-family: Manrope-SemiBold;
  font-size: 16px;
  line-height: 21px;
  color: var(--text-secondary-body, #7c94b1);
  /* transition: all 0.3s ease-out; */
  ${styledProp(
    '$lighter',
    css`
      color: var(--Colors-Neutral-300, #d3dbe7);
    `
  )}
`;
