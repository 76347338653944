import styled from 'styled-components';

import { extract } from '@utils';

import { Device } from '@res';

import { Link } from '@components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  transform: translateX(0);
  height: 100vh;
  width: 100%;
  width: 100vw;
  margin-right: 0;
  overflow: visible;
`;

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  min-height: 550px;
  min-width: 320px;
  flex-direction: column;
  justify-content: center;
  background: #E6F8FC;
  align-items: center;

  @media screen and (max-width: 1920px) {
    max-width: 720px;
  }

  @media screen and (max-width: 1440px) {
    max-width: 600px;
  }

  @media screen and (max-width: 769px) {
    //max-width: 100%;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: none;
  flex: 0;
  position: relative;

  @media screen and ${Device.tablet} {
    display: flex;
    flex: 1;
  }
`

export const Image = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: center / cover no-repeat url(${extract('url')});
`;

export const Hint = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  font-family: Manrope-SemiBold;
  color: #7C8C9E;
  font-weight: 400;
`;

export const Title = styled.div`
  font-size: 34px;
  line-height: 130%;
  color: #1A232E;
  font-family: Manrope-SemiBold !important;
`;

export const LoginHint = styled.div`
  font-family: Manrope-Medium;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 130%;
  display: flex;
  margin-top: 38px;
  color: #7C8C9E;
`;

export const LoginLink = styled(Link)`
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 130%;
  text-transform: uppercase;
`;
