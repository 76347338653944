const ViberStatuses = {
  SENT: 'sent',
  QUEUED: 'queued',
  DELIVERED: 'delivered',
  SENDING: 'sending',
  UNDELIVERED: 'undelivered',
  CLICK: 'click',
  FAILED: 'failed',
};

const ViberStatusLabels = {
  [ViberStatuses.SENT]: 'statuses.sent',
  [ViberStatuses.QUEUED]: 'statuses.queued',
  [ViberStatuses.DELIVERED]: 'statuses.delivered',
  [ViberStatuses.SENDING]: 'statuses.sending',
  [ViberStatuses.UNDELIVERED]: 'statuses.undelivered',
  [ViberStatuses.CLICK]: 'statuses.click',
  [ViberStatuses.FAILED]: 'statuses.failed',
};

const ViberStatusOptions = [
  ...Object.entries(ViberStatusLabels).map(([value, label]) => ({ value, label })),
];

export { ViberStatuses, ViberStatusLabels, ViberStatusOptions };
