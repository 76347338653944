import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 14px 14px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
  font-size: 14px;
  line-height: 100%;
  color: #909399;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 100%;
  color: #303133;
  margin-bottom: 4px;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 6px;
  
  ${styledProp('green', css`
    color: #24993E;
  `)}

  font-size: 10px;
  line-height: 100%;
`;
