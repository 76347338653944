import { useSelector } from 'react-redux';

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useTranslation } from '@hooks';

import { by } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';

import { OperatorSelect, SearchSelect } from '@components';

import { Container, Row } from './styled';

const ArraySelect = ({ query, onChange, fieldDisabled, funnel, showErrors, appearance }) => {
  const { p } = useTranslation('segments_page');
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const options = customerFields?.filter?.(f => f.type === 'array')?.map?.(f => ({ value: f.field, label: f.label }));
  const field = customerFields?.find?.(by('field', query.field));

  const handleFieldChange = (field) => {
    onChange(q => ({ ...q, query: { ...q.query, field, filters: [{ field: '', operator: '', value: { type: 'scalar' } }] } }));
  };

  const handleTypeChange = (type) => {
    onChange(q => ({ ...q, query: { ...q.query, type: type === 'common' ? field.type : type } }));
  };

  const handleValueChange = (value) => {
    onChange(q => ({ ...q, query: { ...q.query, value: { ...q.query.value, value } } }));
  };

  const handleValueTypeChange = (type) => {
    onChange(q => ({ ...q, query: { ...q.query, value: { ...q.query.value, value: [], type } } }));
  };

  const handleOperatorChange = (operator, type) => {
    onChange(q => ({ ...q, query: { ...q.query, operator, type: type === 'common' ? field.type : type, value: { ...q.query.value, value: [], type: 'scalar' } } }));
  };

  return (
    <Container $appearance={appearance} data-testid={`store-segment-field-array-container-${query.name}`}>
      <Row $appearance={appearance}>
        <SearchSelect
          testId={`store-segment-field-array-select-${query.name}`}
          style={{ width: appearance !== 'column' ? 186 : 'auto', marginBottom: appearance === 'column' ? 16 : 0 }}
          options={options || []}
          label={p('array')}
          tooltip={(funnel && fieldDisabled) ? p('array_select_description') : ''}
          tooltipError={showErrors && query.errors?.['field']}
          showInfoIcon={funnel && fieldDisabled}
          value={query?.field}
          disabled={fieldDisabled}
          onChange={handleFieldChange}
        />
        <OperatorSelect
          testId={`store-segment-field-linear-operator-select-${query.name}`}
          disabled={false}
          wrapperStyle={{ marginLeft: appearance !== 'column' ? 6 : 0 }}
          style={{ width: appearance !== 'column' ? 186 : 'auto' }}
          value={{ ...query, valueType: query.value.type, value: query.value.value, errors: showErrors && query.errors }}
          type={query.type}
          autocomplete={query.field === "tags" && {
            "event": "tag",
            "fields": ["tg_tag_name"]
          }}
          field={query.field}
          // additionalOptions={resolveAdditionalOptions()}
          // additionalOptionsOptions={ValueTypeOptions}
          initialType={field?.type}
          initialIsField={query.value.type === 'variable'}
          appearance={appearance}
          fullWidth={appearance === 'column'}
          onTypeChange={handleTypeChange}
          onValueChange={handleValueChange}
          onValueTypeChange={handleValueTypeChange}
          onOperatorChange={handleOperatorChange}
          autoComplete={field?.autocomplete || []}
          labeled
        />
      </Row>
    </Container>
  );
};

export default ArraySelect;
