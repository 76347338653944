import { useState } from 'react';

import { Dropdown } from 'antd';

import { useTranslation } from '@hooks';

import { Container, Title, Overlay, Option } from './styled';

const options = [
  {
    value: 'and',
    label: 'logical_operator_and',
  },
  {
    value: 'or',
    label: 'logical_operator_or',
  },
];

const resolveTitle = (value, wasPerformed, exists) => {
  if (wasPerformed) {
    return 'logical_operator_was_performed';
  }

  if (exists) {
    return 'logical_operator_exists';
  }

  switch (value) {
    case 'and':
      return 'logical_operator_and';
    case 'or':
      return 'logical_operator_or';
    default:
      return ''
  }
};

const LogicalOperatorSelect = ({ value, onChange, wasPerformed, exists, disabled }) => {
  const { p } = useTranslation('workflow_page');
  const [opened, setOpened] = useState(false);

  return (
    <Dropdown
      trigger={["click"]}
      open={opened}
      disabled={wasPerformed || exists || disabled}
      onOpenChange={setOpened}
      overlay={(
        <Overlay>
          {options.map(({ value: v, label }) => (
            // eslint-disable-next-line no-sequences
            <Option data-testid={'logical-operator-select-option-' + v} key={v} onClick={() => (onChange(v), setOpened(false))}>{p(label)}</Option>
          ))}
        </Overlay>
      )}
    >
      <Container $disabled={disabled}>
        <Title $disabled={disabled} data-testid={'logical-operator-select-title'}>
          {p(resolveTitle(value, wasPerformed, exists))}
        </Title>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 12.3754L3.375 6.75039L4.1625 5.96289L9 10.8004L13.8375 5.96289L14.625 6.75039L9 12.3754Z" fill={disabled ? '#7C94B1' : '#1A232E'}/>
        </svg>
      </Container>
    </Dropdown>
  );
};

export default LogicalOperatorSelect;
